import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

type Props = {
  img: string;
  heading?: string;
  errorText: string[];
};

const ErrorBody = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '*': {
    paddingBottom: token('space.150'),
  },
});

export default ({ img, heading, errorText }: Props) => {
  return (
    <div>
      <img src={img} />
      <ErrorBody>
        {heading && <h1>{heading}</h1>}
        {errorText.map((item, index) => (
          <p key={'errText_' + index}>{item}</p>
        ))}
      </ErrorBody>
    </div>
  );
};
