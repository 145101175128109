import Loadable from 'react-loadable';

import { Props } from './types';

export const Renderer = Loadable<Props, any>({
  loader: () => import(/* webpackChunkName: "renderer" */ './Renderer'),
  loading: () => null,
});

export { type Props };
