/**
 * @generated SignedSource<<3b6159442642150edf5c018a68735728>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SavedViewUpdatesAsideQuery$variables = {
  isNavRefreshEnabled: boolean;
  viewUuid: string;
  workspaceUuid: string;
};
export type SavedViewUpdatesAsideQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SavedViewSidebarColumn">;
};
export type SavedViewUpdatesAsideQuery = {
  response: SavedViewUpdatesAsideQuery$data;
  variables: SavedViewUpdatesAsideQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNavRefreshEnabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewUuid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v3 = {
  "kind": "Variable",
  "name": "workspaceUuid",
  "variableName": "workspaceUuid"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "SavedViewUpdatesAsideQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "viewUuid",
                "variableName": "viewUuid"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "SavedViewSidebarColumn"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SavedViewUpdatesAsideQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "uuid",
                "variableName": "viewUuid"
              },
              (v3/*: any*/)
            ],
            "concreteType": "DirectoryView",
            "kind": "LinkedField",
            "name": "directoryViewByUuid",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "uuid"
              },
              {
                "kind": "ScalarField",
                "name": "entityType"
              },
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0cb2a13f28a20f749606c19828486e0a",
    "id": null,
    "metadata": {},
    "name": "SavedViewUpdatesAsideQuery",
    "operationKind": "query",
    "text": "query SavedViewUpdatesAsideQuery(\n  $workspaceUuid: UUID!\n  $viewUuid: UUID!\n  $isNavRefreshEnabled: Boolean!\n) {\n  ...SavedViewSidebarColumn_3Na6m3 @include(if: $isNavRefreshEnabled)\n}\n\nfragment SavedViewSidebarColumn_3Na6m3 on Query {\n  directoryViewByUuid(workspaceUuid: $workspaceUuid, uuid: $viewUuid) {\n    uuid\n    entityType\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "05ced31b01eaf1559b075b5334c2eb6b";

export default node;
