import { IntlShape } from 'react-intl-next';

import { equalsAndOrFilterComparators } from '../filter-comparators';

import type { UserTeamOptions } from './resolve';
import { teamResolver } from './user-team';

export const peopleUserTeamResolver = (intl: IntlShape, options: UserTeamOptions) => {
  return teamResolver(intl, {
    cloudId: options.cloudId,
    orgId: options.orgId,
    workspaceId: options.workspaceId,
    filterComparators: equalsAndOrFilterComparators,
    cpusScope: options.cpusScope,
  });
};
