import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { Link } from '@townsquare/router/primitives';

export type Size = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyWrapper = styled.div({
  display: 'flex',
  flex: 'auto',
  overflow: 'hidden',
  marginRight: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const GoalNameLink = styled(Link)<{ isBold?: boolean; shrink?: boolean }>(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  flex: props.shrink ? '0 1 auto' : '1 1 0',
  // Trick to get ellipsis to work in a flexbox
  minWidth: 0,
  color: token('color.text'),
  '&:hover': {
    color: token('color.text'),
  },
  '&:focus': {
    color: token('color.text'),
    textDecoration: 'underline',
  },
  // Add margin if there's more element after this parent container
  '&:not(:last-child)': {
    marginRight: token('space.100'),
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const GoalNameWrapper = styled.div<{ shrink?: boolean }>(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  flex: props.shrink ? '0 1 auto' : '1 1 0',
  // Trick to get ellipsis to work in a flexbox
  minWidth: 0,
  color: token('color.text'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const GoalName = styled.div<{ isBold?: boolean; spacing?: string; topLeftAppearance?: boolean }>`
  display: inline-flex;
  align-items: ${props => (props.topLeftAppearance ? 'start' : 'center')};
  flex: 1 1 0;
  padding-block: ${token('space.025')};
  // Trick to get ellipsis to work in a flexbox
  min-width: 0;
  max-height: ${props => (props.topLeftAppearance ? 'auto' : '24px')};

  p {
    font-weight: ${props => props.isBold && token('font.weight.semibold')};
  }

  // Add margin left to subsequent sibling elements for spacing
  > * + * {
    margin-left: ${props => props.spacing || token('space.150')};
  }
`;
