import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { EllipsisTooltip } from '@townsquare/ellipsis';

import { LocationCard$key } from './__generated__/LocationCard.graphql';
import { usePeopleFieldCardClickHandler } from './hooks';
import {
  LocationCardNameText,
  LocationDetails,
  LocationImage,
  LocationImageBoundingBox,
  StyledLocationCard,
} from './styles';

const GENERIC_LOCATION_FALLBACK_IMAGE_PATH = '/Images/StaffDirectory/locations/generic_location/illustration.svg';
const GENERIC_LOCATION_FALLBACK_IMAGE_PATH_MOBILE =
  '/Images/StaffDirectory/locations/generic_location/ambient_mobile.svg';

export interface PeopleLocationCardProps {
  data: LocationCard$key;
}

export const PeopleLocationCard = ({ data }: PeopleLocationCardProps) => {
  const cardData = useFragment(
    graphql`
      fragment LocationCard on PeopleFieldAggregate {
        name
        imageDirectoryUrl
      }
    `,
    data,
  );

  const { name } = cardData;

  const onClick = usePeopleFieldCardClickHandler(name, 'LOCATION');
  const imageDirectoryUrl = cardData.imageDirectoryUrl
    ? `/Images/StaffDirectory${cardData.imageDirectoryUrl}`
    : undefined;

  return (
    <StyledLocationCard onClick={onClick} hasGenericImage={!imageDirectoryUrl}>
      <LocationImageBoundingBox>
        <LocationImage
          desktopImageUrl={imageDirectoryUrl?.concat('.jpg') ?? GENERIC_LOCATION_FALLBACK_IMAGE_PATH}
          mobileImageUrl={imageDirectoryUrl?.concat('.jpg') ?? GENERIC_LOCATION_FALLBACK_IMAGE_PATH_MOBILE}
        />
      </LocationImageBoundingBox>
      <LocationDetails>
        <EllipsisTooltip content={name}>
          <LocationCardNameText>{name}</LocationCardNameText>
        </EllipsisTooltip>
        {/*
        TODO: TC-2884 hide comment count while we don't have real aggregate data
        <CountText>
          {count}
          {count === 1 ? ' person' : ' people'}
        </CountText> */}
      </LocationDetails>
    </StyledLocationCard>
  );
};
