import { token } from '@atlaskit/tokens';
// Keep the base link in for dummy links, ideally this should be refactored
// eslint-disable-next-line no-restricted-imports
import { Link as RRRLink } from 'react-resource-router';
import styled from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';
import { hideOnDesktop, hideOnMobile, onMobile, onTabletDown } from '@townsquare/responsive-grid';
import { Link } from '@townsquare/router/primitives';
import { UserAvatarStyle } from '@townsquare/user-avatar';

import { ViewIconSize } from '../types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryHeaderMeta = styled.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: token('space.300'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onTabletDown(`
    flex-direction: column;
    align-items: start;
    gap: ${token('space.100')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryHeaderMetaActions = styled.div({
  display: 'flex',
  gap: token('space.100'),
  flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PageHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: token('space.500'),
  marginBottom: token('space.300'),
  maxWidth: '100%',
  minWidth: 0,
  flexWrap: 'wrap',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  h1: {
    font: token('font.heading.large'),
    color: token('color.text'),
    marginTop: 0,
    textTransform: 'capitalize',
    marginRight: token('space.100'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterContainer = styled.div(
  {
    display: 'contents',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  hideOnMobile(),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const FilterWrapper = styled.div<{ width?: number; minWidth?: number; maxWidth?: number }>(props => ({
  marginLeft: token('space.250'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: props.width && `${props.width}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  maxWidth: props.maxWidth && `${props.maxWidth}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  minWidth: props.minWidth && `${props.minWidth}px`,
  div: {
    flexWrap: 'nowrap',
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchInputWrapper = styled.div({
  marginBottom: token('space.400'),
  overflowX: 'hidden',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '[data-ds--text-field--container]': {
    marginBottom: token('space.200'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SplitButtons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  columnGap: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const LeftButton = styled.div<{ borderRadius?: number }>(({ borderRadius = 6 }) => ({
  button: {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderRadius: `${borderRadius}px 0 0 ${borderRadius}px`,
    minWidth: '32px',
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RightButton = styled.div<{ isSplitButton: boolean; borderRadius?: number }>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({ isSplitButton, borderRadius = 6 }) => ({
    button: {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      borderRadius: isSplitButton ? `0 ${borderRadius}px ${borderRadius}px 0` : `${borderRadius}px`,
      margin: 0,
    },
  }),
);

export const FilterButtonInlineStyles = {
  borderRadius: '36px',
  transition: 'background 0.3s ease-out 0ms, color 0.3s ease-out 0ms',
};

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewsDialogButtonWrapper = styled.div`
  button {
    ${onMobile(`
      padding: 0;
      border-radius: 0;
      font: ${token('font.heading.medium')};
      align-items: center;
      background-color: unset;

      &:hover {
        background-color: unset;
      }
    `)};
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavoriteViewButton = styled.div<{ isDisabled?: boolean }>(
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '> button, a': {
      position: 'absolute',
      inset: 0,
    },
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({ isDisabled }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    button,
    a {
      pointer-events: none !important;
    }
  `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavoriteViewButtonContent = styled.div({
  padding: `0 ${token('space.100')}`,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: token('space.050'),
  pointerEvents: 'none',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  button: {
    pointerEvents: 'all',
    height: 'auto',
    padding: 0,
    background: 'none',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '> span': {
      margin: 0,
    },
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavoriteViewButtonContentName = styled.div<{ isSelected: boolean }>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({ isSelected }) => ({
    maxWidth: '163px',
    fontWeight: token('font.weight.medium'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: isSelected ? token('color.text.selected') : undefined,
  }),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewsContentWrapper = styled.div({
  padding: `${token('space.200')} ${token('space.200')} 0 ${token('space.200')}`,
  width: '380px',
  boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ViewRowAvatar = styled.div`
  display: flex;
  margin-left: auto;

  ${UserAvatarStyle.UserAvatar} {
    margin: 0;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewRowDefaultLink = styled(RRRLink)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: token('space.100'),
  padding: `${token('space.100')} ${token('space.200')} ${token('space.100')} ${token('space.200')}`,
  margin: `0 ${token('space.negative.200')} 0 ${token('space.negative.200')}`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  color: `${token('color.text')} !important`,
  '&:hover, &:focus-visible': {
    textDecoration: 'none',
    outline: 'none',
    backgroundColor: token('color.background.neutral.subtle.hovered'),
    boxShadow: `inset 2px 0 0 ${token('color.border.selected')}`,
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },
});

export const ViewRowLink = ViewRowDefaultLink.withComponent(Link);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CreateNewViewButton = styled.button({
  display: 'flex',
  flexDirection: 'row',
  padding: `${token('space.150')} ${token('space.200')}`,
  margin: `0 ${token('space.negative.200')}`,
  width: 'calc(100% + 32px)',
  backgroundColor: token('color.background.neutral.subtle'),
  border: 0,
  borderTop: `2px solid ${token('color.border')}`,
  '&:hover, &:focus-visible': {
    textDecoration: 'none',
    backgroundColor: token('color.background.neutral.subtle.hovered'),
    cursor: 'pointer',
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: token('space.150'),
  margin: `${token('space.100')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateContainer = styled.div({
  width: '348px',
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateMessage = styled.div({
  color: token('color.text.subtle'),
  textAlign: 'center',
  padding: `0 ${token('space.300')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateButtonWrapper = styled.div({
  textAlign: 'center',
  marginBottom: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryMoreViewsSearchEmptyWrapper = styled.div({
  display: 'flex',
  paddingTop: token('space.300'),
  flexDirection: 'column',
  alignItems: 'center',
  gap: token('space.250'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryMoreViewsSearchEmptyMessage = styled.span({
  width: '224px',
  textAlign: 'center',
  color: token('color.text.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ManageViewsAuthorsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: token('space.150'),
  alignItems: 'center',
  padding: `${token('space.100')} ${token('space.200')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ManageViewsAuthorsByline = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.050'),
  color: token('color.text.subtlest'),
  font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryManageViewsDialogWrapper = styled.div({
  minWidth: '275px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonWrapper = styled.div({
  paddingBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const DirectoryViewActionsWrapper = styled.div<{ isSelected: boolean }>(props => ({
  overflow: 'hidden',
  transition: '0.3s width ease',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: props.isSelected ? '52px' : '0',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryViewActions = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: token('space.050'),
  width: '52px',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryMoreViewsDefaultsWrapper = styled.div(
  {
    marginBottom: token('space.200'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  hideOnDesktop(),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeadingWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: `${token('space.300')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleWrapper = styled.div({
  marginLeft: token('space.150'),
});

const ICON_WRAPPER_STYLES: { [key in ViewIconSize]: string } = {
  xsmall: `
    width: 12px;
    height: 12px;
  `,
  small: `
    width: 20px;
    height: 20px;
  `,
  medium: `
    width: 28px;
    height: 28px;
  `,
  large: `
    width: 56px;
    height: 56px;
  `,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const DirectoryMoreViewsIconWrapper = styled.div<{
  foregroundColor: string;
  backgroundColor: string;
  size: ViewIconSize;
}>`
  display: flex;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  background-color: ${props => props.backgroundColor};

  svg {
    color: ${props => props.foregroundColor} !important;
    fill: ${props => props.foregroundColor} !important;
    ${props => ICON_WRAPPER_STYLES[props.size]}
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryMobileSaveViewButtonWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    gap: token('space.100'),
    marginLeft: 'auto',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    svg: {
      height: '32px',
      width: '32px',
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    button: {
      pointerEvents: 'all',
      height: 'auto',
      padding: 0,
      background: 'none',
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
      '> span': {
        margin: 0,
      },
    },
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  hideOnDesktop(),
);
