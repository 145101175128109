import React from 'react';
import { RouteComponent } from 'react-resource-router';

import { AkAnalyticsListeners } from '@townsquare/analytics/ak-listener';
import { TownsquareIntlProvider } from '@townsquare/i18n';
import { PageTitle } from '@townsquare/page-title';
import { RouteListener } from '@townsquare/performance';
import { SiteBannerProvider } from '@townsquare/site-banner';

import { WebVitals } from '../WebVitals';

import { EmbedApplicationProps } from './types';

export const EmbedApplication = ({ analyticsClient }: EmbedApplicationProps) => {
  return (
    <TownsquareIntlProvider>
      <AkAnalyticsListeners client={analyticsClient}>
        <RouteListener />
        <WebVitals />
        <SiteBannerProvider>
          <PageTitle />
          <RouteComponent />
        </SiteBannerProvider>
      </AkAnalyticsListeners>
    </TownsquareIntlProvider>
  );
};
