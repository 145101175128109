import { TargetTabs } from '@atlassiansox/cross-flow-extensions';
import { Editions } from '@atlassiansox/cross-flow-extensions/start';
import { TargetType } from '@atlassiansox/cross-flow-support';

import { getParams } from '@townsquare/routing/utils';

interface CrossFlowWACInitParams {
  billingMode?: 'legacy';
  edition?: Editions;
  migrationSourceUuid?: string;
  sourceComponent?: string;
  sourceContext?: string;
  startTrialCloudId?: string;
  startTrialProduct?: TargetType;
  targetTab?: TargetTabs;
  templateId?: string;
}

export function getCrossFlowParams(): CrossFlowWACInitParams {
  return getParams(
    'startTrialCloudId',
    'startTrialProduct',
    'edition',
    'migrationSourceUuid',
    'billingMode',
    'sourceContext',
    'targetTab',
    'sourceComponent',
    'templateId',
  );
}

export function isCrossFlowing(): boolean {
  const crossFlowParams = getCrossFlowParams();
  return Object.entries(crossFlowParams).length > 0;
}
