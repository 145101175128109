/**
 * @generated SignedSource<<625736a258a79561a6d5ffbfe61b38a5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type DirectorySortEnum = "NAME_ASC" | "NAME_DESC";
export type ProfilePictureSize = "LARGE" | "MAXIMUM" | "MEDIUM";
export type SearchPreviewUserSearchQuery$variables = {
  after?: string | null;
  cloudId: string;
  first: number;
  organisationId: string;
  profilePicSize: ProfilePictureSize;
  query: string;
  sort: ReadonlyArray<DirectorySortEnum | null>;
  workspaceId: string;
};
export type SearchPreviewUserSearchQuery$data = {
  readonly peopleTql: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly account_id: string | null;
        readonly highResolutionProfilePicture: {
          readonly url: string | null;
        } | null;
        readonly job_title: string | null;
        readonly location: string | null;
        readonly name: string | null;
        readonly teams: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly name: string | null;
              readonly teamId: string | null;
            } | null;
          } | null> | null;
        } | null;
        readonly zoneinfo: string | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};
export type SearchPreviewUserSearchQuery = {
  response: SearchPreviewUserSearchQuery$data;
  variables: SearchPreviewUserSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "profilePicSize"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceId"
},
v8 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v10 = {
  "kind": "ScalarField",
  "name": "name"
},
v11 = {
  "kind": "ScalarField",
  "name": "account_id"
},
v12 = {
  "kind": "ScalarField",
  "name": "job_title"
},
v13 = {
  "kind": "ScalarField",
  "name": "location"
},
v14 = {
  "args": [
    {
      "kind": "Variable",
      "name": "size",
      "variableName": "profilePicSize"
    }
  ],
  "concreteType": "HighResolutionProfilePicture",
  "kind": "LinkedField",
  "name": "highResolutionProfilePicture",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "name": "zoneinfo"
},
v16 = [
  (v8/*: any*/),
  {
    "kind": "Variable",
    "name": "workspaceId",
    "variableName": "workspaceId"
  }
],
v17 = {
  "kind": "ScalarField",
  "name": "teamId"
},
v18 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "startCursor"
    },
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "SearchPreviewUserSearchQuery",
    "selections": [
      {
        "args": (v9/*: any*/),
        "concreteType": "CpusSearchUserConnection",
        "kind": "LinkedField",
        "name": "peopleTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "CpusSearchUserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "CpusSearchUser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "args": (v16/*: any*/),
                    "concreteType": "TeamConnection",
                    "kind": "LinkedField",
                    "name": "teams",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "TeamEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "Team",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v17/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v18/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchPreviewUserSearchQuery",
    "selections": [
      {
        "args": (v9/*: any*/),
        "concreteType": "CpusSearchUserConnection",
        "kind": "LinkedField",
        "name": "peopleTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "CpusSearchUserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "CpusSearchUser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "args": (v16/*: any*/),
                    "concreteType": "TeamConnection",
                    "kind": "LinkedField",
                    "name": "teams",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "TeamEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "Team",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v17/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "id"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v18/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "86788cf671e102be59302d77d5137173",
    "id": null,
    "metadata": {},
    "name": "SearchPreviewUserSearchQuery",
    "operationKind": "query",
    "text": "query SearchPreviewUserSearchQuery(\n  $query: String!\n  $first: Int!\n  $sort: [DirectorySortEnum]!\n  $profilePicSize: ProfilePictureSize!\n  $workspaceId: ID!\n  $organisationId: String!\n  $cloudId: String!\n  $after: String\n) {\n  peopleTql(q: $query, first: $first, after: $after, sort: $sort, organisationId: $organisationId, cloudId: $cloudId) {\n    edges {\n      node {\n        name\n        account_id\n        job_title\n        location\n        highResolutionProfilePicture(size: $profilePicSize) {\n          url\n        }\n        zoneinfo\n        teams(workspaceId: $workspaceId, organisationId: $organisationId) {\n          edges {\n            node {\n              name\n              teamId\n              id\n            }\n          }\n        }\n      }\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ee2cfaa27df2f9908b7d7d64f894fa4";

export default node;
