import { token } from '@atlaskit/tokens';
import React from 'react';

import { OnlyMainColumnMaxWidth } from '@townsquare/home-layout/constants';
import { AsideColumn, MainColumn } from '@townsquare/responsive-grid';
import { SidebarSectionSkeleton } from '@townsquare/sidebar-section';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import {
  StatusSummaryWithHeaderSkeleton,
  TimespanNavigatorSkeleton,
  UpdateListSkeleton,
} from '../components/Skeletons';

import { ProjectUpdatesContainer } from './ProjectUpdatesContainer';
import * as styled from './styles';

export const ProjectUpdatesViewSkeleton = () => {
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  return (
    <>
      {isNavRefreshEnabled ? (
        <ProjectUpdatesContainer>
          <TimespanNavigatorSkeleton />
          <StatusSummaryWithHeaderSkeleton />
          <UpdateListSkeleton />
        </ProjectUpdatesContainer>
      ) : (
        <MainColumn maxWidthPx={OnlyMainColumnMaxWidth}>
          <ProjectUpdatesContainer>
            <TimespanNavigatorSkeleton />
            <StatusSummaryWithHeaderSkeleton />
            <UpdateListSkeleton />
          </ProjectUpdatesContainer>
        </MainColumn>
      )}
      {!isNavRefreshEnabled && (
        <AsideColumn>
          <styled.SidebarContainer>
            <SidebarSectionSkeleton rows={3} />
          </styled.SidebarContainer>
        </AsideColumn>
      )}
    </>
  );
};

export const SkeletonProjectBox = () => (
  <styled.PopularProjectSkeletonBox>
    <styled.SkeletonProjectIcon />
    {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
    <div style={{ marginTop: token('space.200'), width: '100%' }}>
      <styled.SkeletonBarLong />
      <styled.SkeletonBarShort />
    </div>
  </styled.PopularProjectSkeletonBox>
);
