export enum SupportedEntityType {
  PROJECT = 'project',
  GOAL = 'goal',
  UPDATE = 'update',
}

export enum ProjectOrGoalStatusPhase {
  ON_TRACK = 'on_track',
  OFF_TRACK = 'off_track',
  AT_RISK = 'at_risk',
  DONE = 'done',
  PAUSED_OR_PENDING = 'paused,pending',
  CANCELLED = 'cancelled',
  ALL = 'all',
}

export type SummaryItem = {
  title: string;
  subTitle?: string;
  color: string;
  value: number;
  filter: string;
  filterMessage: string;
  shouldConfetti?: boolean;
};

export type EntityStatusSummary = {
  active: number;
  atRisk: number;
  cancelled: number;
  done: number;
  missed: number;
  offTrack: number;
  onTrack: number;
};

export type StatusSummaryProps = {
  summaryItems: SummaryItem[];
  entityType: SupportedEntityType;
  defaultFilter: string;
  existingFilter: string;
  setStatus: (status?: string) => void;
  testId?: string;
};
