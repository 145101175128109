import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { Link } from '@townsquare/router/primitives';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RelatedLearningContainer = styled(Link)({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  padding: token('space.150'),
  background: token('elevation.surface'),
  border: `1px solid ${token('color.border.disabled')}`,
  borderRadius: '4px',
  cursor: 'pointer',
  textDecoration: 'none',
  transitionProperty: 'box-shadow',
  transition: '150ms ease-in-out',
  overflow: 'hidden',
  '&:hover': {
    textDecoration: 'none',
    boxShadow: token('elevation.shadow.overlay'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: token('space.100'),
  gap: token('space.100'),
  overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Project = styled.div({
  font: token('font.body.small'),
  color: token('color.text.subtlest'),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RelatedLearningsContainer = styled.div({
  margin: `${token('space.600')} 0`,
  padding: token('space.150'),
  gap: token('space.150'),
  background: token('elevation.surface.sunken'),
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'space-between',
  overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const RelatedLearningsItems = styled.div`
  display: flex;
  align-content: space-between;

  > ${RelatedLearningContainer}:not(:last-child) {
    margin-right: ${token('space.100')};
  }
`;
