import { EditorNextProps } from '@atlaskit/editor-core/dist/types/types/editor-props';
import React from 'react';
import Loadable from 'react-loadable';

export const ComposableEditor = Loadable<EditorNextProps, any>({
  loader: () => import(/* webpackChunkName: "ak-composable-editor" */ '@atlaskit/editor-core/composable-editor'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ComposableEditor;
    return <Component {...props} />;
  },
});
