import { createRelayResource } from '@townsquare/relay-utils';

import LookupRedirectActionGoalQuery, {
  LookupRedirectActionGoalQuery as LookupRedirectActionGoalQueryType,
} from './__generated__/LookupRedirectActionGoalQuery.graphql';
import LookupRedirectActionProjectQuery, {
  LookupRedirectActionProjectQuery as LookupRedirectActionProjectQueryType,
} from './__generated__/LookupRedirectActionProjectQuery.graphql';

export const lookupActionProjectResource = createRelayResource<LookupRedirectActionProjectQueryType>({
  getQuery: routerContext => {
    const { key } = routerContext?.match?.params || {};
    return {
      query: LookupRedirectActionProjectQuery,
      variables: {
        entityKey: key || '',
      },
    };
  },
});

export const lookupActionGoalResource = createRelayResource<LookupRedirectActionGoalQueryType>({
  getQuery: routerContext => {
    const { key } = routerContext?.match?.params || {};
    return {
      query: LookupRedirectActionGoalQuery,
      variables: {
        entityKey: key || '',
      },
    };
  },
});
