import React from 'react';

import { LoadingState } from '@townsquare/loading-state';

import { SpinnerContainer, StyledLoadingOverlay } from './styles';

export const LoadingOverlay = () => {
  return (
    <>
      <SpinnerContainer>
        <LoadingState />
      </SpinnerContainer>
      <StyledLoadingOverlay />
    </>
  );
};
