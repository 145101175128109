import { getLogInUrl, getNoAccessLink } from '@townsquare/auth-utils';
import { getConfig } from '@townsquare/config';
import { POPUP_AUTH_COMPLETE_PATH } from '@townsquare/config/routes';
import { redirect } from '@townsquare/facade';
import { identityClient } from '@townsquare/identity-client';

const POPUP_LOGIN_POLL_TIMER_INTERVAL_IN_MILLIS = 200;

export const loginViaPopup = () => {
  const pop = window.open(
    getLogInUrl(`${getConfig().fullUrl}${POPUP_AUTH_COMPLETE_PATH}`),
    '_blank',
    'toolbar=0,location=0,menubar=0,width=800,height=600',
  );
  const pollTimer = window.setInterval(() => {
    if (!pop || pop.closed !== false) {
      // !== is required for compatibility with Opera
      window.clearInterval(pollTimer);
      identityClient.getUserIsAuthenticated({ forceRefresh: true }).then(
        ({ isAuthenticated }) => {
          if (isAuthenticated) {
            window.location.reload();
          } else {
            redirect(getNoAccessLink());
          }
        },
        () => {
          redirect(getNoAccessLink());
        },
      );
    }
  }, POPUP_LOGIN_POLL_TIMER_INTERVAL_IN_MILLIS);
};
