import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { WarningIcon } from '@townsquare/icons';

export interface PaginationErrorStateProps extends FallbackProps {
  resultType: 'people' | 'teams' | 'people or teams' | 'kudos';
}

const containerStyles = xcss({
  marginBlockStart: 'space.400',
});

export const PaginationErrorState = (props: PaginationErrorStateProps) => {
  const intl = useIntl();

  return (
    <Stack space="space.250" alignInline="center" xcss={containerStyles}>
      <Stack space="space.075" alignInline="center">
        <WarningIcon label="" secondaryColor={token('color.icon.accent.gray')} />
        <Heading size="xsmall" as="div">
          {intl.formatMessage(
            {
              id: 'townsquare.web.pagination-error-state.error-message',
              description: 'Error message for pagination error state',
              defaultMessage:
                'Failed to load {resultType, select, people {people} teams {teams} other {people or teams}}',
            },
            {
              resultType: props.resultType,
            },
          )}
        </Heading>
      </Stack>
      <Button
        spacing="compact"
        onClick={() => {
          props.resetErrorBoundary();
        }}
      >
        <FormattedMessage
          id="townsquare.web.pagination-error-state.try-again-button-label"
          description="Try again button label for pagination error state"
          defaultMessage="Try again"
        />
      </Button>
    </Stack>
  );
};
