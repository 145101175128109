import { hasOptedOutNavRefresh } from '@townsquare/nav-refresh-opt-out/has-opted-out-nav-refresh';
import { ResourceContext } from '@townsquare/router/types';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';
import { useWorkspaceType } from '@townsquare/workspace-store/hooks';
import { WorkspaceDetails } from '@townsquare/workspace-store/types';

import { isGateEnabled } from './Gate';

type ExpectedResourceContext = Pick<ResourceContext, 'workspace'>;

const GRIFFIN_NAV_OPT_OUT = 'nav4-optout';

const isQueryParamOptOut = (): boolean => {
  const param = createSearchParamsFromCurrentUrl().get(GRIFFIN_NAV_OPT_OUT);

  try {
    if (param && JSON.parse(param) === true) {
      return true;
    }
  } catch {
    // Don't let parsing blow up the app.
  }
  return false;
};

const hasOptedOut = hasOptedOutNavRefresh() || isQueryParamOptOut();
const isAtlassianHome = (workspaceType: WorkspaceDetails['type'] | undefined) => workspaceType !== 'PRODUCT';

export const useIsNavRefreshEnabled = ({ considerOptOut = true } = {}) => {
  const workspaceType = useWorkspaceType();
  const optedOut = considerOptOut && hasOptedOut;

  return shouldEnableNavRefresh(workspaceType, optedOut);
};

export const isNavRefreshEnabled = (resourceContext: ExpectedResourceContext, { considerOptOut = true } = {}) => {
  const optedOut = considerOptOut && hasOptedOut;

  return shouldEnableNavRefresh(resourceContext.workspace?.type, optedOut);
};

const shouldEnableNavRefresh = (workspaceType: WorkspaceDetails['type'] | undefined, optedOut: boolean) => {
  const enableNavRefreshMasterSwitch = isGateEnabled('jira_nav4');
  const enableNavRefreshDogfooding = isGateEnabled('jira_nav4_dogfooding');
  const enableNavRefreshEAP = isGateEnabled('jira_nav4_eap');

  // Only enable new nav in home and for users who have not opted out
  return (
    isAtlassianHome(workspaceType) &&
    !optedOut &&
    enableNavRefreshMasterSwitch && // If master switch is off, everything is off
    (enableNavRefreshDogfooding || enableNavRefreshEAP) // Get nav4 as long as user is enrolled into either dogfood or EAP
  );
};

export const useIsHomeVisualRefreshEnabled = () => {
  const workspaceType = useWorkspaceType();
  return shouldHomeEnableVisualRefresh(workspaceType);
};

export const shouldHomeEnableVisualRefresh = (workspaceType: WorkspaceDetails['type'] | undefined) => {
  const enableHomeVisualRefresh = isGateEnabled('atlassian_home_visual_refresh');

  // Visual refresh is only enabled in Atlassian Home + when Nav4 is enabled
  return (
    isAtlassianHome(workspaceType) && enableHomeVisualRefresh && shouldEnableNavRefresh(workspaceType, hasOptedOut)
  );
};

export const shouldEnablePlatformVisualRefresh = (workspaceType: WorkspaceDetails['type'] | undefined) => {
  const enablePlatformVisualRefresh = isGateEnabled('platform-component-visual-refresh');

  // Platform component visual refresh should only be enabled in Home with Nav4
  return (
    isAtlassianHome(workspaceType) && enablePlatformVisualRefresh && shouldEnableNavRefresh(workspaceType, hasOptedOut)
  );
};
