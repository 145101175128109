import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { profileRoute } from '@townsquare/ptc-directory-view';
import { Link } from '@townsquare/router/primitives';

import { UserAvatarLinkBehaviour, UserNameProps } from '../types';

import * as styled from './styles';

export const BaseUserNameWithoutProfileCard = (props: Omit<UserNameProps, 'profileCardPosition'>) => {
  const { aaid, name, size, linkToProfile = UserAvatarLinkBehaviour.DEFAULT, isDeactivatedUser } = props;

  const analytics = useAnalytics();

  const onUserAvatarLinkClick = () => analytics.ui('userAvatarLink', 'clicked');

  const userNameMessage = isDeactivatedUser ? (
    <FormattedMessage
      id="townsquare.user-avatar.title.profile-card"
      description="Signals that a user has been deactivated"
      defaultMessage="{name} (Deactivated)"
      values={{
        name,
      }}
    />
  ) : (
    name
  );

  return (
    <>
      {linkToProfile !== UserAvatarLinkBehaviour.DISABLED ? (
        <Link
          to={profileRoute}
          params={{ id: aaid }}
          target={UserAvatarLinkBehaviour.FORCE_NEW_TAB ? '_blank' : undefined}
          rel={UserAvatarLinkBehaviour.FORCE_NEW_TAB ? 'noopener noreferrer' : undefined}
          onClick={onUserAvatarLinkClick}
        >
          <styled.Name size={size}>{userNameMessage}</styled.Name>
        </Link>
      ) : (
        <styled.Name size={size}>{userNameMessage}</styled.Name>
      )}
    </>
  );
};
