/**
 * @generated SignedSource<<1ac12ff5b9a441edb97d65ef21d7b082>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type UserOnboardedQuery$variables = Record<PropertyKey, never>;
export type UserOnboardedQuery$data = {
  readonly currentUser: {
    readonly onboarded: boolean;
  } | null;
};
export type UserOnboardedQuery = {
  response: UserOnboardedQuery$data;
  variables: UserOnboardedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "onboarded"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "UserOnboardedQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserOnboardedQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "41c503c96ddbc08b5a0d879674d6b45f",
    "id": null,
    "metadata": {},
    "name": "UserOnboardedQuery",
    "operationKind": "query",
    "text": "query UserOnboardedQuery {\n  currentUser {\n    onboarded\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bedc610abeb5de3a289d59a9baa7b934";

export default node;
