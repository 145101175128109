import { location, history } from '@townsquare/facade';

// Strip the parameters from the URL, uses replaceState
export function stripParams(...paramNames: string[]): { [key: string]: string | undefined } {
  return getAndStripParams(true, paramNames);
}

// Get the parameters from the URL, leaving the location alone.
export function getParams(...paramNames: string[]): { [key: string]: string | undefined } {
  return getAndStripParams(false, paramNames);
}

export function isSyntheticTraffic() {
  return getParams('synthetic').synthetic === 'true';
}

function getAndStripParams(removeParams: boolean, paramNames: string[]) {
  const currentUrl = new URL(location().href);
  const searchParams = currentUrl.searchParams;

  let paramWasRemoved = false;
  const requestedParams = paramNames.reduce((acc: { [paramName: string]: string | undefined }, paramName) => {
    if (searchParams.has(paramName)) {
      acc[paramName] = searchParams.get(paramName) || undefined;
      if (removeParams) {
        searchParams.delete(paramName);
        paramWasRemoved = true;
      }
    }
    return acc;
  }, {});

  if (removeParams && paramWasRemoved) {
    const search = searchParams.toString();
    history().replaceState({}, '', `${currentUrl.pathname}${search.length > 0 ? `?${search}` : ''}`);
  }

  return requestedParams;
}
