import styled from 'styled-components';

import { RESPONSIVE_CONTENT_WIDTH_THRESHOLD } from '@townsquare/responsive-grid';

import { responsiveMobileWidthThreshold } from '../styles';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const WorkspaceButton = styled.div`
  max-width: 160px;
  @media (max-width: ${RESPONSIVE_CONTENT_WIDTH_THRESHOLD}px) {
    max-width: 60px;
  }
  @media (max-width: ${responsiveMobileWidthThreshold}px) {
    display: none !important;
  }
`;
