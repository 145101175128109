import { token } from '@atlaskit/tokens';
import { css } from 'styled-components';

import { type KudosBgThemeType } from './__generated__/KudosContent.graphql';

const backgroundProperties = {
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  green: {
    deg: '116.95deg',
    from: `${token('color.background.accent.yellow.subtler')} 15.01%`,
    to: `${token('color.background.accent.green.subtler')} 108.58%`,
    opacity: 0.2,
  },
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  blue: {
    deg: '114.5deg',
    from: `${token('color.background.accent.teal.subtler')} -3.07%`,
    to: `${token('color.background.accent.blue.subtler')} 101.73%`,
    opacity: 0.2,
  },
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  pink: {
    deg: '115.58deg',
    from: `${token('color.background.accent.magenta.subtler')} -1.31%`,
    to: `${token('color.background.accent.orange.subtler')} 103.21%`,
    opacity: 0.2,
  },
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  purple: {
    deg: '140.56deg',
    from: `${token('color.background.accent.purple.subtler')} -11.97%`,
    to: `${token('color.background.accent.red.subtler')} 81.48%`,
    opacity: 0.2,
  },
};
const backgroundPropsFor = (theme: KudosBgThemeType) => {
  return backgroundProperties[theme];
};

export const BackgroundGradient = (theme: KudosBgThemeType) =>
  css({
    '&::before': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      background: `linear-gradient( ${backgroundPropsFor(theme).deg}, ${backgroundPropsFor(theme).from}, ${
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        backgroundPropsFor(theme).to
      } )`,
      content: "''",
      position: 'fixed',
      insetBlockStart: 0,
      insetInlineStart: 0,
      width: '100vw',
      height: '100vh',
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      opacity: backgroundPropsFor(theme).opacity,
      zIndex: -1,
    },
  });
