/* eslint-disable @atlaskit/design-system/no-css-tagged-template-expression -- Ignoring for now due to wide usage and nested media queries */
import { css, InterpolationValue } from 'styled-components';

import {
  MOBILE_MODE_WIDTH_THRESHOLD,
  RESPONSIVE_THRESHOLD,
  SMALL_DESKTOP_WIDTH_THRESHOLD,
  SMALL_MOBILE_WIDTH_THRESHOLD,
} from './constants';

export const onSmallDesktopDown = (cssRules: string | InterpolationValue[]) => `
  @media (max-width: ${SMALL_DESKTOP_WIDTH_THRESHOLD}px) {
    ${cssRules}
  }
`;

export const onSmallDesktop = (cssRules: string | InterpolationValue[]) => `
  @media (min-width: ${SMALL_DESKTOP_WIDTH_THRESHOLD + 1}px) {
    ${cssRules}
  }
`;

export const onSmallDesktopDownPortraitOrientation = (cssRules: string | InterpolationValue[]) => css`
  @media (orientation: portrait) and (max-width: ${SMALL_DESKTOP_WIDTH_THRESHOLD}px) {
    ${cssRules}
  }
`;

export const onTabletDown = (cssRules: string | InterpolationValue[]) => css`
  @media (max-width: ${RESPONSIVE_THRESHOLD}px) {
    ${cssRules}
  }
`;

export const onDesktop = (cssRules: string | InterpolationValue[]) => css`
  @media (min-width: ${MOBILE_MODE_WIDTH_THRESHOLD + 1}px) {
    ${cssRules}
  }
`;

export const onMobile = (cssRules: string | InterpolationValue[]) => css`
  @media (max-width: ${MOBILE_MODE_WIDTH_THRESHOLD}px) {
    ${cssRules}
  }
`;

export const onSmallMobile = (cssRules: string | InterpolationValue[]) => css`
  @media (max-width: ${SMALL_MOBILE_WIDTH_THRESHOLD}px) {
    ${cssRules}
  }
`;

export const hideOnMobile = () => onMobile('display: none;');

export const hideOnDesktop = () => onDesktop('display: none;');

export const showOnMobile = () => onMobile('display: unset;');
