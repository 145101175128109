import React from 'react';

import { UpdateCardHeaderSkeleton, UpdateCardSkeletonWithReply } from '@townsquare/card';
import { LoadingSkeleton } from '@townsquare/loading-skeleton';
import { StatusSummarySkeleton } from '@townsquare/status-summary';

import {
  ExternalLinkWrapper,
  HeaderWrapper,
  MetaActionsWrapper,
  StatusSummarySkeletonWrapper,
  TimespanNavigatorSkeletonContainer,
} from './styles';

export const TimespanNavigatorSkeleton = () => {
  return (
    <TimespanNavigatorSkeletonContainer>
      <LoadingSkeleton width="40px" height="32px" />
      <LoadingSkeleton width="200px" height="28px" />
      <LoadingSkeleton width="40px" height="32px" />
    </TimespanNavigatorSkeletonContainer>
  );
};

export const StatusSummaryWithHeaderSkeleton = () => {
  return (
    <>
      <StatusSummarySkeletonWrapper>
        <LoadingSkeleton width="180px" height="20px" />
      </StatusSummarySkeletonWrapper>
      <StatusSummarySkeleton />
    </>
  );
};

export const UpdateListSkeleton = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, i) => (
        <div key={`update-skeleton-${i}`}>
          <UpdateCardHeaderSkeleton />
          <UpdateCardSkeletonWithReply />
        </div>
      ))}
    </>
  );
};

export const MetaActionsSkeleton = () => (
  <MetaActionsWrapper>
    <LoadingSkeleton width="90px" height="32px" />
    <LoadingSkeleton width="100px" height="20px" />
  </MetaActionsWrapper>
);

export const FeedTitleSkeleton = () => (
  <>
    <HeaderWrapper>
      <LoadingSkeleton width="32px" height="32px" />
      <LoadingSkeleton width="240px" height="28px" />
      <ExternalLinkWrapper>
        <LoadingSkeleton width="180px" height="20px" />
      </ExternalLinkWrapper>
    </HeaderWrapper>
    <LoadingSkeleton width="100px" height="16px" marginBottomPx={20} />
  </>
);
