import { Grid, xcss } from '@atlaskit/primitives';
import React from 'react';

import { ResponsiveLoadingState } from '@townsquare/loading-state';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { BrowsePersonCardLoadingState } from '../Cards/Browse/Person/loadingState';

const cardRowStyles = xcss({
  gridTemplateColumns: 'repeat(auto-fill, 200px)',
});

const smartRowStyles = xcss({
  gridTemplateColumns: 'repeat(auto-fill, minmax(168px, 1fr))',
});

export const PeopleRowLoadingSkeleton = () => {
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  return (
    <ResponsiveLoadingState>
      <Grid gap="space.250" xcss={enableHomeVisualRefresh ? smartRowStyles : cardRowStyles}>
        {[0, 1, 2, 3, 4, 5].map(i => (
          <BrowsePersonCardLoadingState key={`loading-state-${i}`} />
        ))}
      </Grid>
    </ResponsiveLoadingState>
  );
};
