import { ErrorMessage } from '@atlaskit/form';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl-next';
import { ConnectionHandler, graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { isEmptyDoc } from '@townsquare/adf';
import { useAnalytics } from '@townsquare/analytics';
import { Context } from '@townsquare/context-identifier';
import { filterUndefined } from '@townsquare/filter-type';
import {
  createLearning,
  CreateLearningDescriptionEditor,
  formatExpandedEmptyState,
  LearningPanel,
  LearningTitle,
  useErrors,
  type LearningType,
} from '@townsquare/learnings';

import { NewLearningsPanel$key } from './__generated__/NewLearningsPanel.graphql';

type Props = {
  editorContext: Context;
  onClose: (arg: boolean) => void;
  canEdit: boolean | undefined;
  projectId: string;
  projectState?: string;
  connectionId: string;
  data: NewLearningsPanel$key;
  learningType: LearningType;
};

export const NewLearningPanel = (props: Props) => {
  const environment = useRelayEnvironment();
  const analytics = useAnalytics();
  const intl = useIntl();
  const [routerState] = useRouter();
  const { editorContext, onClose, canEdit: canEditLearningsPage, projectState, projectId, connectionId } = props;

  const [title, setTitle] = useState('');
  const { errors, setErrors } = useErrors(props.learningType);

  const data = useFragment(
    graphql`
      fragment NewLearningsPanel on Project @argumentDefinitions(tagCount: { type: "Int" }) {
        watcherCount
        tags(first: $tagCount) {
          edges {
            node {
              watcherCount
              name
            }
          }
        }
      }
    `,
    props.data,
  );

  const tagsTotalWatchers = data.tags?.edges?.reduce((acc, current) => acc + (current?.node?.watcherCount ?? 0), 0);
  const tagNames = data.tags?.edges?.map(edge => edge?.node?.name ?? undefined).filter(filterUndefined);
  const placeholder = formatExpandedEmptyState(
    intl,
    props.learningType,
    'PROJECT',
    data.watcherCount,
    tagsTotalWatchers,
    tagNames,
  );

  const onSaveNewLearning = useCallback(
    (adf: string) => {
      if (title && !isEmptyDoc(adf)) {
        createLearning(
          environment,
          { description: adf, summary: title, entityId: projectId, type: props.learningType },
          [
            connectionId,
            ConnectionHandler.getConnectionID(projectId, `ProjectTabs__${props.learningType.toLowerCase()}sCount`, {
              type: props.learningType,
            }),
          ],
          {
            onCompleted: () => {
              void analytics.track('learning', 'created', {
                screen: `${routerState.route.name}Screen`,
                entityType: 'project',
                projectPhase: projectState,
                type: props.learningType,
              });
              if (window.Intercom) {
                window.Intercom('trackEvent', 'learningsCreated', analytics.getTopLevelAttributes());
              }
            },
            onError: () => {
              void analytics.operational('learning', 'create-failed', {
                screen: `${routerState.route.name}Screen`,
                entityType: 'project',
                projectPhase: projectState,
                type: props.learningType,
              });
            },
          },
        );
        onClose(false);
        return true;
      } else {
        setErrors({
          summary: !title.trim(),
          description: isEmptyDoc(adf),
        });
        return false;
      }
    },
    [
      title,
      environment,
      projectId,
      props.learningType,
      connectionId,
      onClose,
      analytics,
      routerState.route.name,
      projectState,
      setErrors,
    ],
  );

  return (
    <LearningPanel
      isOpen={true}
      isEditing={true}
      isInvalid={!!errors.description}
      learningType={props.learningType}
      title={
        <LearningTitle
          entityType="PROJECT"
          title=""
          isEditing
          canEdit={props.canEdit}
          isInvalid={!!errors.summary}
          isExpanded={true}
          learningType={props.learningType}
          onChange={setTitle}
        />
      }
    >
      <CreateLearningDescriptionEditor
        editorContext={editorContext}
        onClose={onClose}
        canEditLearningsPage={canEditLearningsPage}
        onSave={onSaveNewLearning}
        title={title}
        placeholder={placeholder}
      />
      {Object.values(errors).map(error => {
        if (!error) {
          return null;
        }
        return <ErrorMessage>{error}</ErrorMessage>;
      })}
    </LearningPanel>
  );
};
