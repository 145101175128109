import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
  jiraInfoBoxTitle: {
    id: 'townsquare.product-overview.new.jira-info-box-title',
    defaultMessage: 'Projects link best with epics',
    description: 'Title explaining why one should sync Atlas projects and Jira epics',
  },
  jiraSectionTextFirstParagraph: {
    id: 'townsquare.product-overview.new.jira-section-text-first-paragraph',
    defaultMessage: 'Projects are where you go to share and read weekly status updates on large streams of work.',
    description: 'First paragraph of text explaining why one should sync Atlas projects and Jira epic',
  },
  jiraSectionTextSecondParagraph: {
    id: 'townsquare.product-overview.new.jira-section-text-second-paragraph-exp',
    defaultMessage:
      '{jiraProductDisplayName} epics and projects are more similar in scope, and should be linked together to keep the work and updates in sync.',
    description: 'Second paragraph of text explaining why one should sync Atlas projects and Jira epic',
  },
});
