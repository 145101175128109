import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { hideOnMobile } from '@townsquare/responsive-grid';
import { Link } from '@townsquare/router/primitives';
import { StyledTeamIcon } from '@townsquare/team-icon';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledPreviewRow = styled(Link)<{ isSelected: boolean }>`
  border-radius: 5px;
  padding: ${token('space.050')};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: ${token('space.050')};
  margin-bottom: ${token('space.150')};

  &:hover ${({ isSelected }) => (isSelected ? ', &' : '')} {
    background-color: ${token('color.background.input.hovered')};
    text-decoration: none;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const PreviewRowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-width: 0;

  ${StyledTeamIcon} {
    margin-left: ${token('space.025')};
    margin-right: ${token('space.025')};
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateRow = styled.div({
  padding: token('space.050'),
  color: token('color.text.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResultPrimaryText = styled.div({
  maxWidth: '60%',
  marginLeft: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResultSecondaryText = styled.div({
  marginLeft: token('space.100'),
  minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchAllText = styled.div({
  color: token('color.text'),
  marginBlock: token('space.050'),
  marginInlineStart: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResultHeading = styled.div({
  font: token('font.heading.xxsmall'),
  color: token('color.text.subtlest'),
  marginLeft: token('space.100'),
  marginBottom: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReturnIconContainer = styled.div(
  {
    alignSelf: 'center',
    height: '16px',
    width: '24px',
    marginRight: token('space.050'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  hideOnMobile(),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchIconContainer = styled.div({
  margin: token('space.050'),
  color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const SearchResultsPreviewContainer = styled.div`
  position: absolute;
  z-index: 300;
  box-sizing: border-box;

  width: 100%;
  margin-top: ${token('space.negative.100')};
  padding: ${token('space.200')} ${token('space.150')};

  box-shadow: ${token('elevation.shadow.overlay')};
  border-radius: 3px;
  background-color: ${token('elevation.surface.overlay')};
  overflow: auto;

  & > ${ResultHeading}:first-of-type {
    margin-top: 0;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Label = styled.span({
  margin: `0 0 0 ${token('space.075')}`,
  textTransform: 'lowercase',
  fontWeight: token('font.weight.bold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Value = styled.span({
  fontWeight: token('font.weight.bold'),
  margin: `0 0 0 ${token('space.075')}`,
  textTransform: 'none',
  ':before': {
    content: "'\"'",
  },
  ':after': {
    content: "'\"'",
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Operator = styled.span({
  margin: `0 0 0 ${token('space.075')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const RecentSearchPreview = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  text-transform: lowercase;

  > span:first-of-type {
    ${Label}:first-of-type {
      text-transform: none;
      margin: 0;
    }
  }
`;
