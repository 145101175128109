import { createRelayResource } from '@townsquare/relay-utils';

import ProjectEmbedQuery, {
  type ProjectEmbedQuery as ProjectEmbedQueryType,
} from '../components/Project/__generated__/ProjectEmbedQuery.graphql';

export const projectEmbedResource = createRelayResource<ProjectEmbedQueryType>({
  getQuery: routerContext => {
    const tabName = routerContext.match.params.projectKey;
    const updateId = routerContext.query.updateId;

    return {
      query: ProjectEmbedQuery,
      variables: {
        key: tabName || '',
        id: updateId || '',
        isSpecificUpdate: !!updateId,
      },
    };
  },
});
