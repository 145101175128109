import { createRelayResource } from '@townsquare/relay-utils';

import GoalHighlightsTabQuery, {
  type GoalHighlightsTabQuery as GoalHighlightsTabQueryType,
} from '../components/GoalHighlightsTab/__generated__/GoalHighlightsTabQuery.graphql';
import { LEARNINGS_SORT, TAGS_COUNT } from '../components/GoalHighlightsTab/constants';

export const goalHighlightsTabResource = createRelayResource<GoalHighlightsTabQueryType>({
  getQuery: routerContext => {
    return {
      query: GoalHighlightsTabQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        learningsSort: LEARNINGS_SORT,
        tagCount: TAGS_COUNT,
        isLearnings: routerContext.match.params.tabName === 'learnings',
        isRisks: routerContext.match.params.tabName === 'risks',
        isDecisions: routerContext.match.params.tabName === 'decisions',
      },
    };
  },
});
