import { createRelayResource } from '@townsquare/relay-utils';

import ProjectLearningsTabQuery, {
  type ProjectLearningsTabQuery as ProjectLearningsTabQueryType,
} from '../components/ProjectLearningsTab/__generated__/ProjectLearningsTabQuery.graphql';
import { LEARNINGS_SORT, TAGS_COUNT } from '../components/ProjectLearningsTab/constants';

export const projectLearningsTabResource = createRelayResource<ProjectLearningsTabQueryType>({
  getQuery: routerContext => {
    return {
      query: ProjectLearningsTabQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        learningsSort: LEARNINGS_SORT,
        tagCount: TAGS_COUNT,
        type: 'LEARNING',
      },
    };
  },
});

export const projectRisksTabResource = createRelayResource<ProjectLearningsTabQueryType>({
  getQuery: routerContext => {
    return {
      query: ProjectLearningsTabQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        learningsSort: LEARNINGS_SORT,
        tagCount: TAGS_COUNT,
        type: 'RISK',
      },
    };
  },
});

export const projectDecisionsTabResource = createRelayResource<ProjectLearningsTabQueryType>({
  getQuery: routerContext => {
    return {
      query: ProjectLearningsTabQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        learningsSort: LEARNINGS_SORT,
        tagCount: TAGS_COUNT,
        type: 'DECISION',
      },
    };
  },
});
