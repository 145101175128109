/**
 * @generated SignedSource<<2d8e0c25aba3e705274041eacbc666d0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TeamSearchResultCard$data = {
  readonly description: string | null;
  readonly displayName: string | null;
  readonly id: string | null;
  readonly members: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly aaid: string | null;
        readonly pii: {
          readonly name: string | null;
          readonly picture: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "TeamSearchResultCard";
};
export type TeamSearchResultCard$key = {
  readonly " $data"?: TeamSearchResultCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamSearchResultCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "TeamSearchResultCard",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "displayName"
    },
    {
      "concreteType": "TeamMemberConnection",
      "kind": "LinkedField",
      "name": "members",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "concreteType": "TeamMemberEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "TeamMember",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "UserPII",
                  "kind": "LinkedField",
                  "name": "pii",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "name"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "picture"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "aaid"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "TeamSearchResponse"
};

(node as any).hash = "6f683b28dce133f1216c9c1fbb7504d4";

export default node;
