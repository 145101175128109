import { token } from '@atlaskit/tokens';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import styled from 'styled-components';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';
import { ResponsivePage } from '@townsquare/responsive-grid';
import { useWorkspaceUUID } from '@townsquare/workspace-store';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'calc(100vh - 120px)',
  minHeight: '400px',
  textAlign: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  img: {
    maxWidth: '400px',
    display: 'block',
    margin: `0 auto ${token('space.500')} auto`,
    width: '100%',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  p: {
    margin: `${token('space.050')} 0`,
  },
});

const ErrorBody = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '*': {
    paddingBottom: token('space.150'),
  },
});

export const ForbiddenContentError = (props: React.PropsWithChildren<Record<string, never>>) => {
  const analytics = useAnalytics();
  const [workspaceUUID] = useWorkspaceUUID();

  useOnMount(() => {
    void analytics.page('restrictedContentScreen', { workspaceUuid: workspaceUUID });
  });

  return (
    <ResponsivePage>
      <Container>
        <img src="/Images/restrictedContent.svg" />
        <ErrorBody>
          <h1>
            <FormattedMessage
              id="townsquare.error-state.forbidden-content-error.title"
              description="Forbidden content error title"
              defaultMessage="You've stumbled on restricted content"
            />
          </h1>
          <p>
            <FormattedMessage
              id="townsquare.error-state.forbidden-content-error.body"
              description="Forbidden content error body"
              defaultMessage="Ask the person who shared this link with you to check you have access, or <a>learn about how sharing works</a>"
              values={{
                a: (chunks: string) => (
                  <a href="https://intercom.help/atlas-by-atlassian/en/articles/6403749-restrict-the-viewing-and-editing-of-projects">
                    {chunks}
                  </a>
                ),
              }}
            />
          </p>
          {props.children}
        </ErrorBody>
      </Container>
    </ResponsivePage>
  );
};
