import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import ReturnToJiraIntegrationAdminQuery from '../ui/ReturnToJiraIntegration/__generated__/ReturnToJiraIntegrationAdminQuery.graphql';
import { ReturnToJiraIntegrationAdminQuery as ReturnToJiraIntegrationAdminQueryType } from '../ui/ReturnToJiraIntegration/__generated__/ReturnToJiraIntegrationAdminQuery.graphql';

export const jiraIntegrationReadyResource = createRelayResource<ReturnToJiraIntegrationAdminQueryType>({
  getQuery: (_routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    return {
      query: ReturnToJiraIntegrationAdminQuery,
      variables: {
        id: context.workspace?.globalId ?? '',
      },
    };
  },
});
