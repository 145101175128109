import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import HowToStepsQuery, { type HowToStepsQuery as HowToStepsQueryType } from '../__generated__/HowToStepsQuery.graphql';

export const howToResource = createRelayResource<HowToStepsQueryType>({
  getQuery: (_, resourceContext) => {
    const context = resourceContext as ResourceContext;

    return {
      query: HowToStepsQuery,
      variables: {
        workspaceId: context.workspace?.globalId ?? '',
        includeArchived: false,
      },
    };
  },
});
