import Loadable from 'react-loadable';

import { Props, WithTitleProps } from './types';

export const DescriptionEditor = Loadable<Props, any>({
  loader: () => import(/* webpackChunkName: "description-editor" */ './ui/DescriptionEditor'),
  loading: () => null,
});

export const DescriptionEditorWithTitle = Loadable<WithTitleProps, any>({
  loader: () => import(/* webpackChunkName: "description-editor-with-title" */ './ui/DescriptionEditorWithTitle'),
  loading: () => null,
});

export * as DescriptionEditorStyles from './ui/styles';
