import mousetrap from 'mousetrap';
import { useEffect } from 'react';

import { MODIFIER_PREFIX } from './constants';
import { useKeyboardShortcutStore } from './store';
import { UserShortcutType } from './types';

export const useKeyboardShortcut = (
  handlerKey: string,
  handlerCallback: (event: KeyboardEvent, combo?: string) => void,
  deps?: any[],
) => {
  const [{ type }] = useKeyboardShortcutStore();

  useEffect(() => {
    const bindKey = getModifiedShortcut(type, handlerKey);
    mousetrap.bind(bindKey, handlerCallback);
    return () => {
      mousetrap.unbind(bindKey);
    };
    // FIXME: TC-4035
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlerKey, handlerCallback, type, ...(deps ?? [])]);
};

export const getModifiedShortcut = (type: UserShortcutType, handlerKey: string) => {
  if (type === 'MULTI_KEY') {
    return handlerKey
      .split(' ')
      .map(key => `${MODIFIER_PREFIX}${key}`)
      .join(' ');
  } else {
    return handlerKey;
  }
};
