import { token } from '@atlaskit/tokens';

const yellowToken = () => token('color.background.accent.yellow.subtlest');
const redToken = () => token('color.background.accent.red.subtlest');
const magentaToken = () => token('color.background.accent.magenta.subtlest');
const purpleToken = () => token('color.background.accent.purple.subtlest');
const tealToken = () => token('color.background.accent.teal.subtlest');
const blueToken = () => token('color.background.accent.blue.subtlest');
const greenToken = () => token('color.background.accent.green.subtlest');

const ILLUSTRATION_HOVER_TOKEN_FN_FOR_IMAGE_DIRECTORY: { [key: string]: () => string } = {
  '/assets/client_engagement': yellowToken,
  '/assets/design': redToken,
  '/assets/finance': yellowToken,
  '/assets/generic': magentaToken,
  '/assets/human_resources': purpleToken,
  '/assets/information_technology': tealToken,
  '/assets/legal': redToken,
  '/assets/management': magentaToken,
  '/assets/marketing': yellowToken,
  '/assets/operations': tealToken,
  '/assets/product_management': blueToken,
  '/assets/program_management': tealToken,
  '/assets/projects': blueToken,
  '/assets/quality_assurance': purpleToken,
  '/assets/research': magentaToken,
  '/assets/sales': greenToken,
  '/assets/software_development': blueToken,
  '/assets/support': greenToken,
};

export const getIllustrationHoverColourFromImageDirectory = (imageDirectoryUrl: string | null): string | undefined => {
  return imageDirectoryUrl ? ILLUSTRATION_HOVER_TOKEN_FN_FOR_IMAGE_DIRECTORY[imageDirectoryUrl]() : undefined;
};
