import { EmojiResource, EmojiResourceConfig } from '@atlaskit/emoji/resource';

import { getConfig } from '@townsquare/config';

import { withSelectedToneEmitter } from './withSelectedToneEmitter';

const config = getConfig();

const DEFAULT_EMOJI_CONFIG: EmojiResourceConfig = {
  providers: [{ url: `${config.emojiUrl}/standard` }],
};

export const emojiResource = new EmojiResource(DEFAULT_EMOJI_CONFIG);
export const emojiProvider = withSelectedToneEmitter(emojiResource.getEmojiProvider());

const resources = new Map<string, EmojiResource>();

export const getEmojiProviderForCloudId = (cloudId: string, userId: string, disableUpload?: boolean) => {
  const resourceKey = `${cloudId}::${userId}::${disableUpload ? 'no-upload' : 'with-upload'}`;

  let resource = resources.get(resourceKey);
  if (resource) {
    return withSelectedToneEmitter(resource.getEmojiProvider());
  }

  const emojiConfig: EmojiResourceConfig = {
    providers: [
      ...DEFAULT_EMOJI_CONFIG.providers,
      {
        url: `${config.emojiUrl}/atlassian`,
      },
      {
        url: `${config.emojiUrl}/${cloudId}/site`,
      },
    ],
    allowUpload: disableUpload ? false : true,
    currentUser: {
      id: userId,
    },
  };

  resource = new EmojiResource(emojiConfig);
  resources.set(resourceKey, resource);
  return withSelectedToneEmitter(resource.getEmojiProvider());
};
