import { SelectResolverOptions } from '../types';

export function promoteExactTagLabelMatch(
  data: SelectResolverOptions,
  query: string | undefined,
): SelectResolverOptions {
  if (!query) {
    return data;
  }

  return [...data].sort((a, b) => {
    if (a.label === query || a.label === `#${query}`) {
      return -1;
    } else if (b.label === query || b.label === `#${query}`) {
      return 1;
    } else {
      return 0;
    }
  });
}
