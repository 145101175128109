import { checkThirdParty } from '@atlassian/browser-storage-controls';
import React, { Suspense, useState } from 'react';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { NAME_EMBED_SUFFIX, NAME_REDIRECT_SUFFIX, ONBOARDING_PRODUCT_OVERVIEW } from '@townsquare/config/routes';
import { useOnMount } from '@townsquare/hooks';
import { FullPageViewErrorBoundary } from '@townsquare/monitoring';
import { isGateEnabled } from '@townsquare/stat-sig/gate';
import { useUserStore } from '@townsquare/user-store';
import { useNoWorkspaceExperience, useWorkspaceStore } from '@townsquare/workspace-store';

import { AtlasIntercom } from './AtlasIntercom';
import { ROVO_ROUTE_NAMES, RovoIntercom } from './RovoIntercom';

export const Intercom = () => {
  const [{ route }] = useRouter();
  const [user] = useUserStore();
  const [workspace] = useWorkspaceStore();
  const noWorkspaceExperience = useNoWorkspaceExperience();
  const { enableIntercom } = getConfig();
  const [intercomAllowed, setIntercomAllowed] = useState(false);
  const analytics = useAnalytics();

  useOnMount(() => {
    if (isGateEnabled('enable_browser_storage_controls')) {
      checkThirdParty(
        'intercom',
        // Intercom access allowed
        async () => {
          setIntercomAllowed(true);
        },
        // Intercom access denied
        async () => {
          setIntercomAllowed(false);
          void analytics.operational('intercomBoot', 'denied');
        },
      );
    } else {
      setIntercomAllowed(true);
    }
  });

  if (
    route.name === 'onboarding' ||
    route.name === 'noaccess' ||
    route.name === ONBOARDING_PRODUCT_OVERVIEW ||
    route.name.endsWith(NAME_REDIRECT_SUFFIX) ||
    route.name.endsWith(NAME_EMBED_SUFFIX) ||
    !user.accountId ||
    noWorkspaceExperience ||
    !enableIntercom
  ) {
    return null;
  }

  if (!intercomAllowed) {
    return null;
  }

  return (
    <FullPageViewErrorBoundary FallbackComponent={() => null}>
      <Suspense fallback={false}>
        {ROVO_ROUTE_NAMES.includes(route.name) ? (
          <RovoIntercom user={user} />
        ) : (
          <AtlasIntercom user={user} workspace={workspace} />
        )}
      </Suspense>
    </FullPageViewErrorBoundary>
  );
};
