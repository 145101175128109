import { createActionRoute, createSiteRoute } from '@townsquare/router/create-route';

import { LookupRedirectAction } from './loadable';
import { lookupActionProjectResource, lookupActionGoalResource } from './resource';

export const lookupRedirectActionRoute = createSiteRoute(
  createActionRoute({
    component: LookupRedirectAction,
    exact: true,
    name: 'lookupRedirectAction',
    path: '/lookup/:key',
    resources: [lookupActionProjectResource, lookupActionGoalResource],
  }),
);
