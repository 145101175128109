import { graphql, readInlineData } from 'react-relay';
import { createActionsHook, createHook, createStore } from 'react-sweet-state';

import { storeKeyboardShortcut$key } from './__generated__/storeKeyboardShortcut.graphql';
import { fetchKeyboardPreference } from './fetchKeyboardPreference';
import { UserShortcutType } from './types';

const keyboardShortcutFragment = graphql`
  fragment storeKeyboardShortcut on User @inline {
    shortcutsType
  }
`;

const initialState: { type: UserShortcutType } = {
  type: 'SINGLE_KEY',
};

export const keyboardShortcutStore = createStore({
  initialState,
  actions: {
    setKeyboardShortcutType:
      (type: UserShortcutType) =>
      ({ setState }) => {
        const newState = {
          type,
        };

        setState(newState);
        return newState;
      },
    initStore:
      () =>
      async ({ setState }) => {
        const ref = await fetchKeyboardPreference();

        if (!ref) return;

        const data = readInlineData(keyboardShortcutFragment, ref.currentUser as storeKeyboardShortcut$key);

        const newState = {
          type: data?.shortcutsType ?? 'SINGLE_KEY',
        };

        setState(newState);
        return newState;
      },
  },
  name: 'keyboard-shortcut-store',
});

export const useKeyboardShortcutStore = createHook(keyboardShortcutStore);
export const useKeyboardShortcutActions = createActionsHook(keyboardShortcutStore);
