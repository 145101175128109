import React from 'react';
import Loadable from 'react-loadable';

import { GoalViewSkeleton } from './components/GoalViewSkeleton';

export const GoalView = Loadable({
  loader: () => import(/* webpackChunkName: "RelayGoalView" */ './components/GoalView'),
  loading: () => <GoalViewSkeleton />,
  delay: 0,
});

export { goalViewResource } from './resources/GoalView';
export { goalHighlightsTabResource } from './resources/GoalHighlightsTab';
