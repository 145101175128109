import type { WithContextProps } from '@atlaskit/analytics-next';
import type { InvitePeopleProps } from '@atlassian/invite-people/types';
import Loadable from 'react-loadable';

const AkInvitePeople = Loadable<InvitePeopleProps & WithContextProps, any>({
  loader: () => import(/* webpackChunkName: "ak-invite-people" */ '@atlassian/invite-people'),
  loading: () => null,
});

export default AkInvitePeople;
