import { graphql, readInlineData } from 'react-relay';

import { type User } from '@townsquare/user-store';

import { utils_isUserInList$key } from './__generated__/utils_isUserInList.graphql';

export const isUserInList = (user: User, data: utils_isUserInList$key | null = null) => {
  const users = readInlineData(
    graphql`
      fragment utils_isUserInList on UserConnection @inline {
        edges {
          node {
            aaid
          }
        }
      }
    `,
    data,
  );

  return users?.edges?.some(edge => edge?.node?.aaid === user.accountId) ?? false;
};

export const userCanEditProject = (
  user: User,
  {
    members = null,
    isPrivate,
  }: {
    members?: utils_isUserInList$key | null;
    isPrivate: boolean;
  },
) => {
  if (isPrivate) {
    return isUserInList(user, members);
  }

  return true;
};

export const userCanPostProjectUpdate = (
  user: User,
  {
    members = null,
    isArchived,
  }: {
    members?: utils_isUserInList$key | null;
    isArchived: boolean;
  },
) => {
  if (isArchived) {
    return false;
  }
  return isUserInList(user, members);
};
