import Badge from '@atlaskit/badge';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import React from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import styled from 'styled-components';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { ProfilePicture } from '@townsquare/profile-picture';
import { profileRoute } from '@townsquare/ptc-directory-view';
import { Link } from '@townsquare/router/primitives';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { UserCurrentTime } from './UserCurrentTime';
import {
  InfoRow,
  LocationText,
  NameText,
  ProfileFieldsContainer,
  ProfilePictureContainer,
  StyledPersonSearchResultCard,
} from './styles';
import { PersonSearchResultCardProps } from './types';

const displayName = (name: string | null) =>
  name ? (
    <EllipsisTooltip content={name}>
      <NameText>{name}</NameText>
    </EllipsisTooltip>
  ) : null;

const displayJobTitle = (jobTitle: string | null) =>
  jobTitle ? (
    <EllipsisTooltip content={jobTitle}>
      <Text as="p" color="color.text.subtle" maxLines={1}>
        {jobTitle}
      </Text>
    </EllipsisTooltip>
  ) : null;

const displayLocation = (location: string | null, maxWidth: string) =>
  location ? (
    <EllipsisTooltip content={location}>
      <LocationText maxWidth={maxWidth}>
        <Text as="p" color="color.text.subtle" maxLines={1}>
          {location}
        </Text>
      </LocationText>
    </EllipsisTooltip>
  ) : null;

const getLocationTextMaxWidth = (zoneinfo: string | null): string => {
  if (!zoneinfo) {
    return '100%';
  }

  return '130px';
};

const cardStyles = xcss({
  height: '354px',
  width: '100%',
  gap: 'space.200',
  borderColor: 'color.border',
  borderStyle: 'solid',
  borderWidth: 'border.width',
  borderRadius: 'border.radius',
  padding: 'space.250',
});

const detailsStyles = xcss({
  gap: 'space.050',
});

const NoUnderlineLink = styled(Link)({
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'none',
  },
});

export const PersonSearchResultCard = (props: PersonSearchResultCardProps) => {
  const analytics = useAnalytics();
  const intl = useIntl();
  const data = useFragment(
    graphql`
      fragment PersonSearchResultCard on CpusSearchUser {
        account_id
        name
        highResolutionProfilePicture(size: MEDIUM) {
          url
        }
        job_title
        reportCounts {
          directReports
        }
        location
        zoneinfo
        ...CardTeamsSection
      }
    `,
    props.data,
  );
  const { account_id, name, zoneinfo, highResolutionProfilePicture, location, reportCounts, job_title } = data;
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  //Name and account_id SHOULD exist, but if they don't, we don't show a card. This also sets their types as non-null
  if (name === null || account_id === null) {
    return null;
  }

  const onClick = () => {
    void analytics.ui('searchResultPersonCard', 'clicked');
  };

  const reportsCount = reportCounts?.directReports ? (
    <Tooltip
      content={intl.formatMessage(
        {
          id: 'townsquare.web.person.direct-reports-tooltip',
          description: 'Direct reports tooltip',
          defaultMessage: '{directReports} direct reports',
        },
        {
          directReports: reportCounts.directReports,
        },
      )}
    >
      <Badge>{reportCounts.directReports}</Badge>
    </Tooltip>
  ) : null;

  if (enableHomeVisualRefresh) {
    return (
      <NoUnderlineLink
        data-testid={`user-search-card-${account_id}`}
        onClick={onClick}
        to={profileRoute}
        params={{ id: account_id }}
        key={account_id}
      >
        <Stack xcss={cardStyles}>
          <ProfilePicture
            name={name}
            src={highResolutionProfilePicture?.url ?? undefined}
            height="230px"
            width="100%"
          />
          <Stack xcss={detailsStyles}>
            <Inline space="space.050" spread="space-between">
              <Box>{displayName(name)}</Box>
              {reportsCount}
            </Inline>
            {displayJobTitle(job_title)}
            <Inline space="space.050">
              {displayLocation(location, getLocationTextMaxWidth(zoneinfo))}
              {zoneinfo && <UserCurrentTime timezone={zoneinfo} />}
            </Inline>
          </Stack>
        </Stack>
      </NoUnderlineLink>
    );
  }

  return (
    <StyledPersonSearchResultCard
      data-testid={`user-search-card-${account_id}`}
      widthPx={242}
      heightPx={360}
      onClick={onClick}
      to={profileRoute}
      params={{ id: account_id }}
      key={account_id}
    >
      <ProfilePictureContainer>
        <ProfilePicture name={name} src={highResolutionProfilePicture?.url ?? undefined} height="230px" width="100%" />
      </ProfilePictureContainer>
      <ProfileFieldsContainer>
        <Stack space="space.025">
          <InfoRow>
            <Box xcss={xcss({ width: '100%' })}>{displayName(name)}</Box>
            {reportsCount}
          </InfoRow>
          {displayJobTitle(job_title)}
          <InfoRow>
            {displayLocation(location, getLocationTextMaxWidth(zoneinfo))}
            {zoneinfo && <UserCurrentTime timezone={zoneinfo} />}
          </InfoRow>
        </Stack>
      </ProfileFieldsContainer>
    </StyledPersonSearchResultCard>
  );
};
