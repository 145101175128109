import { Identifiers } from '@atlaskit/feature-gate-js-client';
import { ConfigClient, ConfigCollection, ConfigResult } from '@atlassian/js-config-client-beta';

import { getConfig } from '@townsquare/config';
import { featureFlagApiKey } from '@townsquare/feature-flags/client-key';
import { logMessage } from '@townsquare/logging';

import { isGateEnabled } from './Gate';
import { ConfigNames } from './config-keys';

let config: ConfigCollection | undefined;

// https://bitbucket.org/atlassian/feature-gate-clients/src/main/packages/js-config-client/
export const initConfigClient = (identifiers: Identifiers) => {
  return ConfigClient.fetch({
    ffsBaseUrl: getConfig().ffsBaseUrl,
    ffsApiKey: featureFlagApiKey,
    context: {
      identifiers,
      namespace: 'atlas_web',
      metadata: {},
    },
  }).then(configCollection => {
    config = configCollection;
    return configCollection;
  });
};

const getConfigFlagValue = <T>(getter: () => ConfigResult<T> | undefined, defaultValue: T, configName: ConfigNames) => {
  if (!isGateEnabled('atlas_enable_config_gate')) {
    return defaultValue;
  }

  const flagValue = getter();

  if (flagValue === undefined) {
    return defaultValue;
  }

  if ('error' in flagValue) {
    logMessage(`getConfigFlagValue for flag ${configName}`, 'warning', {
      source: 'getConfigFlagValue',
      error: flagValue.error,
    });
    return defaultValue;
  }
  return flagValue.value;
};

export const getBooleanConfig = (configName: ConfigNames, defaultValue = false) => {
  return getConfigFlagValue(() => config?.getBoolean(configName), defaultValue, configName);
};

export const getStringConfig = (configName: ConfigNames, defaultValue = '') => {
  return getConfigFlagValue(() => config?.getString(configName), defaultValue, configName);
};

export const getNumberConfig = (configName: ConfigNames, defaultValue = 0) => {
  return getConfigFlagValue(() => config?.getNumber(configName), defaultValue, configName);
};

export const getStringListConfig = (configName: ConfigNames, defaultValue = []) => {
  return getConfigFlagValue(() => config?.getStringList(configName), defaultValue, configName);
};

export const getNumberListConfig = (configName: ConfigNames, defaultValue = []) => {
  return getConfigFlagValue(() => config?.getNumberList(configName), defaultValue, configName);
};
