import React, { useEffect, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { filterNull } from '@townsquare/filter-type';
import { useOnMount } from '@townsquare/hooks';
import { TeamPopup } from '@townsquare/onboarding';

import { appendEmptyTeamSearchToTql } from '../../resources/utils';
import { TeamSearchResultCard } from '../Cards/SearchResult/Team';
import { EmptySearchState } from '../EmptySearchState/SearchEmptyState';
import { PaginationErrorState } from '../PaginationErrorState/PaginationErrorState';
import { ClearSearchQueryFn } from '../types';

import { ScrollingResultsScreen } from './ScrollingResultsScreen';
import { DirectoryTeamSortEnum, SearchTeamsPaginationQuery } from './__generated__/SearchTeamsPaginationQuery.graphql';
import { SearchTeamsScreen_data$key } from './__generated__/SearchTeamsScreen_data.graphql';

export interface SearchTeamsScreenProps {
  tql?: string;
  data: SearchTeamsScreen_data$key;
  clearSearchFn: ClearSearchQueryFn;
  teamSort?: DirectoryTeamSortEnum[];
  setHideTeamSort: (hideSort: boolean) => void;
}

const PAGE_SIZE = 20;

export const SearchTeamsScreen = (props: SearchTeamsScreenProps) => {
  const hasMounted = useRef(false);
  const analytics = useAnalytics();
  useOnMount(() => {
    hasMounted.current = true;
    void analytics.ui('staffDirectorySearchTeamsScreen', 'viewed');
  });

  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    SearchTeamsPaginationQuery,
    SearchTeamsScreen_data$key
  >(
    graphql`
      fragment SearchTeamsScreen_data on Query
      @refetchable(queryName: "SearchTeamsPaginationQuery")
      @argumentDefinitions(
        query: { type: "String!" }
        first: { type: "Int", defaultValue: 20 }
        after: { type: "String" }
        sort: { type: "[DirectoryTeamSortEnum]" }
        organisationId: { type: "String!" }
        cloudId: { type: "String!" }
        isSearchTeamsScreen: { type: "Boolean!" }
      ) {
        searchTeams: teamsTql(
          q: $query
          organisationId: $organisationId
          cloudId: $cloudId
          sort: $sort
          first: $first
          after: $after
        ) @include(if: $isSearchTeamsScreen) @connection(key: "SearchTeamsScreen_searchTeams") {
          edges {
            node {
              id
              ...TeamSearchResultCard
            }
          }
        }
      }
    `,
    props.data,
  );

  useEffect(() => {
    if (!hasMounted.current) {
      return;
    }
    refetch({ query: appendEmptyTeamSearchToTql(props.tql ?? ''), sort: props.teamSort ?? null });
  }, [props.tql, props.teamSort, refetch]);

  useEffect(() => {
    if (data.searchTeams?.edges?.length && data.searchTeams?.edges?.length > 0) {
      props.setHideTeamSort(false);
    } else {
      props.setHideTeamSort(true);
    }
  }, [data.searchTeams?.edges?.length, props.setHideTeamSort, props]);

  const teamCards =
    data.searchTeams?.edges
      ?.map(edge => {
        if (!edge || !edge.node || !edge.node.id) {
          return null;
        }
        const teamId = edge.node.id.replace('ari:cloud:identity::team/', '');
        return <TeamSearchResultCard key={`search-teams-result-${teamId}`} data={edge.node} />;
      })
      .filter(filterNull) ?? [];

  return teamCards.length ? (
    <>
      <ScrollingResultsScreen
        resultCount={data.searchTeams?.edges?.length ?? 0}
        resultName="teams"
        resultList={teamCards}
        renderFallback={fallbackProps => (
          <PaginationErrorState
            error={fallbackProps.error}
            resetErrorBoundary={fallbackProps.resetErrorBoundary}
            resultType="teams"
          />
        )}
        isLoading={isLoadingNext}
        loadMore={loadNext}
        hasMore={hasNext}
        pageSize={PAGE_SIZE}
      />
      <TeamPopup />
    </>
  ) : (
    <EmptySearchState resultType="teams" clearSearchQuery={props.clearSearchFn} />
  );
};
