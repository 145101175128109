import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';
import { Match } from 'react-resource-router';
import { validate } from 'uuid';

import { HOME_PATH, YOUR_WORK_PATH } from '@townsquare/config/routes';
import {
  useWorkspaceStore,
  type WorkspaceDetails,
  WORKSPACE_CLOUDID_SESSION_STORAGE_KEY,
} from '@townsquare/workspace-store';

import { client as apolloClient } from '../clients/apolloClient';
import { UserWorkspaceByContext } from '../graphql/__apollo__/UserWorkspaceByContext';
import { WorkspaceContextInput, WorkspaceContextType } from '../graphql/generated/graphql';
import { GET_USER_WORKSPACE_DATA_BY_CONTEXT } from '../graphql/queries/workspace';

export const USER_NOT_LOGGED_IN = 'user-not-logged-in';
export const USER_HASNT_JOINED = 'user-hasnt-joined';
export const UNABLE_TO_RESOLVE_WORKSPACE = 'unable-to-resolve-workspace';

const BAD_REQUEST = '400';
const UNAUTHORISED_ERROR = '401';

type ResponseWithStatus = { status: number };
type Response = ApolloQueryResult<UserWorkspaceByContext> | ResponseWithStatus;

function hasStatus(response: Response): response is ResponseWithStatus {
  return 'status' in response;
}

const processErrors = (response: Response) => {
  let error;

  if (hasStatus(response)) {
    // Request error (early exit before being interpreted by graphql)
    if (response.status >= 400) {
      // Some minor fudging to maintain same data shape below
      error = {
        message: String(response.status),
      };
    }
  }
  // Graphql errors
  else if (response.errors && response.errors.length > 0) {
    error = response.errors[0];
  }

  if (error) {
    const message = String(error?.message || '');

    // First check extension codes, then fallback to old string checking for backwards compatibility
    if (error instanceof GraphQLError && error.extensions?.statusCode === Number(UNAUTHORISED_ERROR)) {
      return {
        error: USER_HASNT_JOINED,
      };
    } else if (error instanceof GraphQLError && error.extensions?.statusCode === Number(BAD_REQUEST)) {
      return {
        error: USER_NOT_LOGGED_IN,
      };
    } else if (message.includes(UNAUTHORISED_ERROR)) {
      // This could either mean a workspace hasn't been created yet
      // or workspace has already been created, but this user hasn't been added as a member yet
      return {
        error: USER_HASNT_JOINED,
      };
    } else if (message.includes(BAD_REQUEST)) {
      // Shouldn't happen, but just covering all bases
      return {
        error: USER_NOT_LOGGED_IN,
      };
    } else {
      // Fallback for everything else
      return {
        error: UNABLE_TO_RESOLVE_WORKSPACE,
      };
    }
  }
};

const handleErrors = (response: any) => {
  return (
    processErrors(response) || {
      error: 'unable-to-determine-error',
    }
  );
};

export const getActiveWorkspaceByContext = async (
  workspaceContext?: WorkspaceContextInput,
): Promise<
  | {
      error: string;
    }
  | WorkspaceDetails
> => {
  // Coming from Switcher or expand flow expecting a specific cloudId/workspace
  // Navigating to a specific goal / project page fetch its workspace
  // Otherwise fetch the user preferred workspace
  return apolloClient
    .query<UserWorkspaceByContext>({
      query: GET_USER_WORKSPACE_DATA_BY_CONTEXT,
      variables: { workspaceContext },
    })
    .then(
      response => {
        const errors = processErrors(response);
        if (errors) {
          return errors;
        }
        const workspace = response?.data?.userWorkspaceByContext?.workspace;
        if (workspace) {
          return {
            type: workspace.type,
            globalId: workspace.id,
            UUID: workspace.uuid,
            name: workspace.name,
            keyPrefix: workspace.keyPrefix,
            timezone: workspace.timezone,
            cloudId: workspace.cloudId,
            cloudUrl: workspace.cloudUrl ?? undefined,
            organisationId: workspace.organisationId,
            goalScoringMode: workspace.goalScoringMode,
            numOfActiveWorkspaces: response?.data?.workspaceCount?.recordCount || 0,
            featureContext: workspace.featureContext ?? undefined,
          };
        } else {
          // Return a user hasn't joined error, this will redirect the user to the Onboarding page
          // which will check if that user has write perms on that site and if they do, add them as a member of that workspace
          return {
            error: USER_HASNT_JOINED,
          };
        }
      },
      response => handleErrors(response),
    );
};

const getPreferredWorkspaceCloudId = (userId: string) => {
  try {
    return sessionStorage?.getItem(`${WORKSPACE_CLOUDID_SESSION_STORAGE_KEY}-${userId}`);
  } catch {
    return null;
  }
};

/**
 * Returns the page context type needed to retrieve the relevant workspace.
 * This takes the path being navigated to and any cloudId query param and determines the ids and keys to send to BE.
 * Where the destination is a tag, project, goal, or question page. the params returned will be used to switch into that workspace.
 * Where a cloudId is given in the path, it gets priority over what's stored in session.
 * @param routePath
 * @param match
 * @param cloudIdQueryParam
 */
export const getWorkspaceContext = (routePath: string, userId: string, match: Match, cloudIdQueryParam?: string) => {
  // WORKSPACE IDENTIFIER CONTEXT
  if (match.params.workspaceIdentifier) {
    const workspaceIdentifier = match.params.workspaceIdentifier;
    return workspaceIdentifier
      ? { contextType: WorkspaceContextType.WorkspaceIdentifier, identifier: workspaceIdentifier }
      : undefined;
  }

  // PROJECT PAGE CONTEXT
  if (routePath.includes('/project/:id/')) {
    const projectKey = match.params.id;
    return projectKey ? { contextType: WorkspaceContextType.ProjectKey, identifier: projectKey } : undefined;
  }

  // GOAL PAGE CONTEXT
  if (routePath.includes('/goal/:id/')) {
    const goalKey = match.params.id;
    return goalKey ? { contextType: WorkspaceContextType.GoalKey, identifier: goalKey } : undefined;
  }

  // TAG FEED CONTEXT
  if (routePath.includes('/updates/tag/:id')) {
    const tagId = match.params.id;
    if (tagId && validate(tagId)) {
      return { contextType: WorkspaceContextType.TagUuid, identifier: tagId };
    }
    return tagId ? { contextType: WorkspaceContextType.TagId, identifier: tagId } : undefined;
  }

  // SAVED VIEW FEED CONTEXT
  if (routePath.includes('/updates/view/:uuid')) {
    const directoryViewUuid = match.params.uuid;
    return directoryViewUuid
      ? { contextType: WorkspaceContextType.DirectoryViewUuid, identifier: directoryViewUuid }
      : undefined;
  }

  // TOPIC PAGE CONTEXT
  if (routePath.includes('/tag/:uuid')) {
    const tagUuid = match.params.uuid;
    return tagUuid ? { contextType: WorkspaceContextType.TagUuid, identifier: tagUuid } : undefined;
  }

  if (routePath.includes('/give-kudos') && cloudIdQueryParam) {
    return { contextType: WorkspaceContextType.WorkspaceInOrgByCloudId, identifier: cloudIdQueryParam };
  }

  // REQUESTED CLOUD ID CONTEXT
  if (cloudIdQueryParam) {
    return { contextType: WorkspaceContextType.WorkspaceCloudId, identifier: cloudIdQueryParam };
  }

  // PREFERRED CLOUD ID CONTEXT
  const expectedCloudId = getPreferredWorkspaceCloudId(userId);
  if (expectedCloudId) {
    return { contextType: WorkspaceContextType.WorkspaceCloudId, identifier: expectedCloudId };
  }

  return undefined;
};

export const useNoWorkspaceExperience = (): boolean => {
  const [workspace] = useWorkspaceStore();
  return !workspace.globalId;
};

export const useDefaultHomePath = (): string => {
  const noWorkspaceExperience = useNoWorkspaceExperience();

  return noWorkspaceExperience ? YOUR_WORK_PATH : HOME_PATH;
};
