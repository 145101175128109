import Loadable from 'react-loadable';

import { TAGS_DIRECTORY, TAGS_PATH } from '@townsquare/config/routes';
import { onboardingResource } from '@townsquare/onboarding/resource';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { tqlExplainerResource } from '@townsquare/tql/resource';

import { exploreResource } from './resources/explore';

export const tagsDirectoryRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "tags-directory" */ './ui/Explore'),
    loading: () => null,
  }),
  exact: true,
  name: TAGS_DIRECTORY,
  path: TAGS_PATH,
  resources: [exploreResource, onboardingResource, tqlExplainerResource],
  subproduct: 'townsquare-tags',
});
