import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useDrawerActions } from '@townsquare/drawers/actions';
import { type QueryParameterPair } from '@townsquare/drawers/types';
import { useUserStore } from '@townsquare/user-store';

import { getWritingModeSettingsFromLocalStorage } from './Settings/hooks';
import { CONTRIBUTING_CONTEXT_QP, GOALS_CONTEXT_QP, PENDING_AND_PAUSED_CONTEXT_QP } from './constants';

export const useOpenPendingUpdatesDrawer = (
  analyticsSource:
    | 'create-dropdown'
    | 'home-project-sidebar'
    | 'home-goal-sidebar'
    | 'home-project-pending-update-filter'
    | 'home-goal-pending-update-filter'
    | 'home-project-no-updates'
    | 'home-goal-no-updates',
  forceGoals?: boolean,
) => {
  const analytics = useAnalytics();
  const [{ route }] = useRouter();
  const { openDrawer, getHref } = useDrawerActions('pending-updates');
  const [{ accountId }] = useUserStore();
  const writingModeSettings = getWritingModeSettingsFromLocalStorage(accountId);

  const extraUrlParameters = useMemo<QueryParameterPair[]>(() => {
    const extraUrlParams = [];
    if (forceGoals || writingModeSettings.showGoals !== 'off') {
      extraUrlParams.push({ param: GOALS_CONTEXT_QP, value: forceGoals ? 'force' : writingModeSettings.showGoals });
    }
    if (writingModeSettings.showContributing !== 'off') {
      extraUrlParams.push({ param: CONTRIBUTING_CONTEXT_QP, value: writingModeSettings.showContributing });
    }
    if (writingModeSettings.showPendingAndPaused !== 'off') {
      extraUrlParams.push({ param: PENDING_AND_PAUSED_CONTEXT_QP, value: writingModeSettings.showPendingAndPaused });
    }
    return extraUrlParams;
  }, [
    forceGoals,
    writingModeSettings.showContributing,
    writingModeSettings.showGoals,
    writingModeSettings.showPendingAndPaused,
  ]);

  const href = useMemo(() => getHref(extraUrlParameters), [extraUrlParameters, getHref]);

  const onClick = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      event.preventDefault();
      void analytics.ui('activateWritingMode', 'clicked', {
        route: route.name,
        source: analyticsSource,
      });
      openDrawer(extraUrlParameters);
    },
    [analytics, analyticsSource, extraUrlParameters, openDrawer, route.name],
  );

  return { onClick, href };
};
