import { PATH_PREFIX, TEAM_HEALTH_MONITOR_PAGE, TEAM_HEALTH_PAGE } from '@townsquare/config/routes';
import { createSiteRoute } from '@townsquare/router/create-route';

import { TeamHealthEmbed, teamHealthResource } from '.';

export const teamHealthRoute = createSiteRoute({
  component: TeamHealthEmbed,
  exact: true,
  name: TEAM_HEALTH_PAGE,
  path: '/team/health/:teamId?',
  resources: [teamHealthResource],
});

export const teamHealthMonitorRoute = createSiteRoute({
  component: TeamHealthEmbed,
  exact: true,
  name: TEAM_HEALTH_MONITOR_PAGE,
  path: '/team/health/:teamId/monitor/:teamHealthUuid?',
  resources: [teamHealthResource],
});

export const teamHealthMonitorEmbedRoute = createSiteRoute({
  component: TeamHealthEmbed,
  exact: true,
  name: TEAM_HEALTH_MONITOR_PAGE,
  path: `${PATH_PREFIX.EMBEDS}/team-health/:teamId?/:teamHealthUuid?`,
  resources: [teamHealthResource],
});
