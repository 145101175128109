import { createRelayResource } from '@townsquare/relay-utils';

import OnboardingViewQuery, {
  OnboardingViewQuery as OnboardingViewQueryType,
} from '../components/__generated__/OnboardingViewQuery.graphql';

export const onboardingViewResource = createRelayResource<OnboardingViewQueryType>({
  getQuery: () => ({
    query: OnboardingViewQuery,
  }),
});
