import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { profileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';
import { generatePath, useRouteDependencies, useRouterActions } from '@townsquare/router/primitives';
import type { Resolvers } from '@townsquare/tql/types';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { mapRecent, useRecentlySearched, useRecentlyViewed } from '../../../hooks/recent';
import { RecentlySearchedItem, RecentlyViewedItem } from '../../../hooks/types';
import { staffDirectoryRoute } from '../../../route';
import { ScreenType } from '../../ScreenType';

import { SearchPreviewProps } from '.';
import { UserPreviewRow } from './PeopleResults';
import { RecentSearchRow } from './RecentSearchRow';
import { TeamPreviewRow } from './TeamResults';
import { RecentlyViewedQuery } from './__generated__/RecentlyViewedQuery.graphql';
import { ResultHeading, SearchResultsPreviewContainer } from './styles';
import { ResultNavigationOptions } from './types';

const getRouteForSelectedItem = (
  selectedId: string,
  recentlySearched: RecentlySearchedItem[],
  recentlyViewed: RecentlyViewedItem[],
  cloudId: string,
  routeAttributes: ReturnType<typeof useRouteDependencies>,
) => {
  const [viewed] = recentlyViewed.filter(item => item.id === selectedId);
  if (viewed) {
    if (viewed.type === 'user') {
      const path = generatePath(profileRoute, { baseParams: { id: selectedId }, ...routeAttributes });
      return { route: profileRoute, pathWithQuery: path, params: { id: selectedId } };
    } else {
      const path = generatePath(teamProfileRoute, { baseParams: { id: selectedId }, ...routeAttributes });
      return { route: teamProfileRoute, pathWithQuery: path, params: { id: selectedId } };
    }
  } else {
    const [searched] = recentlySearched.filter(item => item.id === selectedId);
    if (searched) {
      const screen = searched.screen === ScreenType.BROWSE_ALL ? ScreenType.SEARCH_ALL : searched.screen;
      const path = generatePath(staffDirectoryRoute, { ...routeAttributes });
      const queryString = `?screen=${ScreenType[screen]}&cloudId=${cloudId}&tql=${searched.tql}`;
      return {
        route: staffDirectoryRoute,
        pathWithQuery: `${path}${queryString}`,
        query: { screen: ScreenType[screen], cloudId, tql: searched.tql },
      };
    }
  }

  return undefined;
};

export type RecentProps = Pick<
  SearchPreviewProps,
  'selectedIndex' | 'setSelectedIndex' | 'shouldNavigateToResult' | 'setShouldNavigateToResult' | 'closeSearchPreview'
> & {
  resolvers: Resolvers;
};

export const Recent = ({
  selectedIndex,
  setSelectedIndex,
  shouldNavigateToResult,
  setShouldNavigateToResult,
  closeSearchPreview,
  resolvers,
}: RecentProps) => {
  const [{ cloudId }] = useWorkspaceStore();
  const { searched } = useRecentlySearched();
  const { viewed } = useRecentlyViewed();
  const { pushTo } = useRouterActions();
  const routeAttributes = useRouteDependencies();

  const data = useLazyLoadQuery<RecentlyViewedQuery>(
    graphql`
      query RecentlyViewedQuery($aaids: [String]!, $teamIds: [String]!, $cloudId: String) {
        userProfilesByAaids(aaids: $aaids) {
          # eslint-disable-next-line relay/unused-fields
          edges {
            node {
              name
              picture
              accountId
            }
          }
        }

        teamsByTeamIds(teamIds: $teamIds, cloudId: $cloudId) {
          # eslint-disable-next-line relay/unused-fields
          edges {
            node {
              name
              teamId
              members {
                count
              }
            }
          }
        }
      }
    `,
    {
      aaids: viewed.filter(i => i.type === 'user').map(i => i.entityId),
      teamIds: viewed.filter(i => i.type === 'team').map(i => i.entityId),
      cloudId,
    },
  );

  const recentViewed = mapRecent(viewed, data);
  const recentSearchedIds = searched.map(i => i.id);
  const recentViewedIds = recentViewed.map(i => i.id);
  const idList = [...recentSearchedIds, ...recentViewedIds];
  const selectedId = idList[((selectedIndex % idList.length) + idList.length) % idList.length];

  useEffect(() => {
    setSelectedIndex(0);
  }, [setSelectedIndex]);

  useEffect(() => {
    if (shouldNavigateToResult === ResultNavigationOptions.NOT_NAVIGATING) {
      return;
    } else {
      setShouldNavigateToResult(ResultNavigationOptions.NOT_NAVIGATING);

      const route = getRouteForSelectedItem(selectedId, searched, recentViewed, cloudId, routeAttributes);

      if (route) {
        if (shouldNavigateToResult === ResultNavigationOptions.NAVIGATE_SAME_TAB) {
          void pushTo(route.route, { params: route.params, query: route.query });
          closeSearchPreview();
        } else if (shouldNavigateToResult === ResultNavigationOptions.NAVIGATE_NEW_TAB) {
          open(route.pathWithQuery, '_blank', 'noreferrer noopener');
        }
      }
    }
  }, [
    closeSearchPreview,
    cloudId,
    pushTo,
    recentViewed,
    routeAttributes,
    searched,
    selectedId,
    setShouldNavigateToResult,
    shouldNavigateToResult,
  ]);

  if (searched.length === 0 && recentViewed.length === 0) {
    // Prevents empty box if you've never used the experience before
    return null;
  }

  return (
    <SearchResultsPreviewContainer>
      {searched.length > 0 ? (
        <>
          <ResultHeading>
            <FormattedMessage
              id="townsquare.web.recent.searched-heading"
              description="Recent searches heading"
              defaultMessage="Recent searches"
            />
          </ResultHeading>
          {searched.map(item => (
            <RecentSearchRow key={item.id} item={item} resolvers={resolvers} isSelected={item.id === selectedId} />
          ))}
        </>
      ) : null}
      {recentViewed.length > 0 ? (
        <>
          <ResultHeading>
            <FormattedMessage
              id="townsquare.web.recent.viewed-heading"
              description="Recently viewed heading"
              defaultMessage="Recently viewed"
            />
          </ResultHeading>
          {recentViewed.map(item =>
            item.type === 'user' ? (
              <UserPreviewRow key={item.id} user={item.data} isSelected={item.id === selectedId} />
            ) : (
              <TeamPreviewRow key={item.id} team={item.data} isSelected={item.id === selectedId} />
            ),
          )}
        </>
      ) : null}
    </SearchResultsPreviewContainer>
  );
};
