import { browserMetrics } from '@atlassian/browser-metrics';
import AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import { FeatureFlagClientInterface } from '@atlassiansox/feature-flag-web-client';
import { version } from 'react';

import { getReleaseVersion } from '@townsquare/config';
import { ANALYTICS_PRODUCT_IDENTIFIER, PRODUCT_IDENTIFIER } from '@townsquare/config/constants';

const getBaseName = (assetPathOrURL: string) => assetPathOrURL.split('#')[0].split('?')[0].split('/').pop();

const sanitiseAssetName = (isDevelopment: boolean) => (assetURL: string) => {
  const baseName = getBaseName(assetURL);
  if (!baseName) {
    return '';
  }

  // report "[name.with.dots].[ext]"
  // in debug we expect "[name.with.dots].[ext]"
  // in production we expect "[name.with.dots].[hash].[ext]"
  const split = baseName.split('.');
  return isDevelopment || split.length < 3 ? baseName : [...split.slice(0, -2), ...split.slice(-1)].join('.');
};

const sanitiseEndpoints = (resourceURL: string) => {
  try {
    const url = new URL(resourceURL);
    const operationName = url.searchParams.get('operationName');
    if (operationName) {
      return `${url.pathname}?operationName=${operationName}`;
    }
    return url.pathname;
  } catch (e) {
    return '';
  }
};

export const initBM3 = (
  awc: AnalyticsWebClient,
  featureFlagClient: FeatureFlagClientInterface,
  product: `${typeof ANALYTICS_PRODUCT_IDENTIFIER}` | `${typeof PRODUCT_IDENTIFIER}` = ANALYTICS_PRODUCT_IDENTIFIER,
) => {
  const info = {
    product,
    app: {
      version: { web: getReleaseVersion() },
      framework: { name: 'react', version },
    },
    region: 'unknown',
  };

  const endpoints = {
    eventPipelineClient: Promise.resolve(awc),
  };

  const plugins = {
    featureFlags: {
      client: Promise.resolve({
        getValue: (featureFlagName: string) => {
          return featureFlagClient.getFlagValue(featureFlagName, false);
        },
      }),
    },
    resourceTimings: {
      sanitiseEndpoints,
      mapResources: sanitiseAssetName(getReleaseVersion() === 'local'),
    },
  };

  browserMetrics.init({
    info,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore AWC client definition problems
    endpoints,
    plugins,
    events: {},
  });

  browserMetrics.startPageLoad({ isInitial: true });
};
