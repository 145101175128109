import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

export { JiraPickerInfoBox } from './New/JiraPickerInfoBox';
export { projectDialogProjectOverviewResource } from './resources/projectDialogProductOverviewResource';
export { type ExperimentCohortFF } from './types';
export const ProductOverview = Loadable<RouteContext, any>({
  loader: () => import(/* webpackChunkName: "ProductOverview" */ './ProductOverview'),
  loading: () => null,
});
