import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ModalDialog from '@atlaskit/modal-dialog';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { FREE_TIER_SIZE } from '@townsquare/config/constants';
import { useOnMount } from '@townsquare/hooks';

import { AddPeopleBenefitsModalProps } from '../../types';

import welcomeImage from './header.png';
import { AddPeopleBenefitsModalWrapper } from './styles';

export const AddPeopleBenefitsModal = (props: AddPeopleBenefitsModalProps) => {
  const intl = useIntl();
  const analytics = useAnalytics();

  useOnMount(() => {
    void analytics.ui(props.analyticsSubjectId, 'clicked');
  });

  return (
    <ModalDialog
      onClose={props.onClose}
      width={460}
      autoFocus={false}
      shouldCloseOnEscapePress={true}
      shouldCloseOnOverlayClick={true}
    >
      <AddPeopleBenefitsModalWrapper>
        <img
          alt={intl.formatMessage({
            id: 'townsquare.invitation.add-people-benefits-modal.image-alt',
            description: 'Alt text for image in Add People Benefits Modal',
            defaultMessage: 'Graphic showing users working on a project',
          })}
          src={welcomeImage}
        />
        <div>
          <Heading size="xsmall">
            <FormattedMessage
              id="townsquare.invitation.add-people-benefits-modal.title"
              description="Title for Add People Benefits Modal"
              defaultMessage="Atlas is for communication across teams - that’s why we made it free for up to {feeTierSize} users!"
              values={{
                feeTierSize: new Intl.NumberFormat().format(FREE_TIER_SIZE),
              }}
            />
          </Heading>
          <FormattedMessage
            id="townsquare.invitation.add-people-benefits-modal.description"
            description="Description for Add People Benefits Modal"
            defaultMessage="<ul><li>Add your teammates as <b>contributors</b> to your work</li><li>Add <b>followers</b> to loop them into weekly updates</li><li>Create <b>teams</b> to understand who’s working on what</li></ul>"
            values={{
              ul: (chunk: string) => <ul>{chunk}</ul>,
              li: (chunk: string) => <li>{chunk}</li>,
              b: (chunk: string) => <b>{chunk}</b>,
            }}
          />
          <Button
            onClick={() => {
              props.onClose();
              props.onConfirm();
            }}
            appearance="primary"
            shouldFitContainer
          >
            <FormattedMessage
              description="Button text for Add People button in Add People Benefits Modal"
              defaultMessage="Add people"
              id="townsquare.invitation.add-people-benefits-modal.add-people-button"
            />
          </Button>
        </div>
      </AddPeopleBenefitsModalWrapper>
    </ModalDialog>
  );
};
