export const FOLLOWERS_ON_PROJECT_EXPERIMENT = 'followers-visible-on-project';

export const ATLAS_INVITING_EXPERIMENT_A = 'atlas_inviting_experiment_a';
export const ATLAS_INVITING_EXPERIMENT_B = 'atlas_inviting_experiment_b';
export const ATLAS_INVITING_EXPERIMENT_C = 'atlas_inviting_experiment_c';
export const ATLAS_JIRA_GET_ATLAS_FREE = 'atlas_jira_get_atlas_free';
export const ATLAS_JIRA_SEARCH_JIRA_ISSSUES = 'atlas_jira_search_jira_issues';

// https://switcheroo.atlassian.com/ui/gates/1705872e-b4eb-4f1d-9c1f-9ac0aca67f3d
export const ENABLE_CREATE_PROJECT_MENU_VISUAL_REFRESH = 'enable_create_project_menu_visual_refresh';

// For experiment details visit go/trailblazerexperiments
export const HIGHLIGHT_JIRA_VALUE_PROPOSITION_ON_ATLASSIAN_HOMEPAGE = 'highlight_value_prop_atlassian_home_page_exp';
export const JIRA_CROSSFLOW_EMPTY_ATLASSIAN_HOMEPAGE_EXP = 'jira_crossflow_empty_atlassian_homepage_exp';

// Make sure you add your experiment to the list of valid experiments
export const experiments = {
  ATLAS_INVITING_EXPERIMENT_A,
  ATLAS_INVITING_EXPERIMENT_B,
  ATLAS_INVITING_EXPERIMENT_C,
  ATLAS_JIRA_GET_ATLAS_FREE,
  ATLAS_JIRA_SEARCH_JIRA_ISSSUES,
  ENABLE_CREATE_PROJECT_MENU_VISUAL_REFRESH,
  FOLLOWERS_ON_PROJECT_EXPERIMENT,
  HIGHLIGHT_JIRA_VALUE_PROPOSITION_ON_ATLASSIAN_HOMEPAGE,
  JIRA_CROSSFLOW_EMPTY_ATLASSIAN_HOMEPAGE_EXP,
} as const;

export type ExperimentKeys = keyof typeof experiments;
export type ExperimentNames = (typeof experiments)[ExperimentKeys];
