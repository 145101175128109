/**
 * @generated SignedSource<<e6f9f3aa64fe79e62157f8f942bdb787>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ShortcutsTypeEnum = "MULTI_KEY" | "SINGLE_KEY";
import type { FragmentRefs } from "relay-runtime";
export type storeKeyboardShortcut$data = {
  readonly shortcutsType: ShortcutsTypeEnum;
  readonly " $fragmentType": "storeKeyboardShortcut";
};
export type storeKeyboardShortcut$key = {
  readonly " $data"?: storeKeyboardShortcut$data;
  readonly " $fragmentSpreads": FragmentRefs<"storeKeyboardShortcut">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "storeKeyboardShortcut"
};

(node as any).hash = "f07c4af6a09eb952b690b0a7e0d18bb8";

export default node;
