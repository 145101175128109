import { commitMutation, ConnectionHandler, graphql, type Environment, type MutationConfig } from 'relay-runtime';
import { v4 as uuidv4 } from 'uuid';

import { createLearningInput, CreateLearningMutation } from './__generated__/CreateLearningMutation.graphql';

let tempID = 0;

export const createLearning = (
  environment: Environment,
  input: createLearningInput,
  connectionIds: string[],
  events: Pick<MutationConfig<CreateLearningMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<CreateLearningMutation>(environment, {
    mutation: graphql`
      mutation CreateLearningMutation($input: createLearningInput!, $connections: [ID!]!) {
        createLearning(input: $input) {
          learning @appendNode(connections: $connections, edgeTypeName: "LearningEdge") {
            id
            ari
            summary
            description
            uuid
            creator {
              pii {
                name
                picture
              }
            }
            creationDate
            goal {
              key
            }
            project {
              key
              latestUserUpdate {
                ...ProjectUpdateCard
                ...EditableProjectUpdateCard
              }
            }
            type
          }
        }
      }
    `,
    variables: { input, connections: connectionIds },
    optimisticUpdater: store => {
      const node = store.create(`client:newLearning${tempID++}`, 'Learning');
      node.setValue(input.summary, 'summary');
      node.setValue(input.description, 'description');
      node.setValue(uuidv4(), 'uuid');
      node.setValue(true, 'isRecentlyAdded');
      node.setValue(input.type, 'type');

      connectionIds.forEach(connId => {
        const learningConnection = store.get(connId);
        if (learningConnection) {
          const edge = ConnectionHandler.createEdge(store, learningConnection, node, 'LearningEdge');
          ConnectionHandler.insertEdgeAfter(learningConnection, edge);
        }
      });
    },
    updater: (store, data) => {
      if (data?.createLearning?.learning?.id) {
        const node = store.get(data.createLearning.learning.id);
        node?.setValue(true, 'isRecentlyAdded');
      }
    },
    onCompleted: events.onCompleted,
    onError: events.onError,
  });
};
