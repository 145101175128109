import { token } from '@atlaskit/tokens';
import React from 'react';

import { isFilterComparatorMultiValued } from '../../tql';
import { FilterComparators, Operators } from '../../types';
import { OperatorLabel } from '../style';

import OperatorPickerPopup from './OperatorPickerPopup';

interface OperatorPickerProps {
  selected: Operators;
  onChange: (operator: Operators) => void;
  onClose: () => void;
  filterComparator?: FilterComparators;
}

export const OperatorPicker = (props: OperatorPickerProps) => {
  const { filterComparator } = props;
  const options = [];

  const allowedOperators = props.filterComparator?.allowedOperators.map(item => item.operator) || [];
  for (const operator of Object.values(Operators)) {
    if (allowedOperators.includes(operator)) {
      options.push({
        label:
          props.filterComparator?.allowedOperators.find(item => item.operator === operator)?.operatorText ||
          operator.toString(),
        value: operator,
      });
    }
  }

  if (options.length < 2) {
    let operatorLabel: string;
    if (filterComparator && isFilterComparatorMultiValued(filterComparator)) {
      operatorLabel = filterComparator.multiValueSeparator;
    } else {
      operatorLabel = props.filterComparator?.allowedOperators[0]?.operatorText || props.selected;
    }

    return <OperatorLabel>{operatorLabel}</OperatorLabel>;
  }

  return (
    <OperatorPickerPopup
      isRootOperator={false}
      onClose={props.onClose}
      selected={props.selected}
      onChange={props.onChange}
      options={options}
      buttonStyle={{
        padding: `0 ${token('space.025')} 0 ${token('space.100')}`,
        height: 'auto',
        margin: '0',
      }}
    />
  );
};
