import React from 'react';
import { IntlShape } from 'react-intl-next';

import { PersonIcon } from '../components/icons/Person';
import { TeamIcon } from '../components/icons/Team';
import { OptionTypes, Resolver, SupportedFiltersTypes } from '../types';

import { equalsOrFilterComparators } from './filter-comparators';
import {
  teamResolveLabels,
  teamResolveOptions,
  userResolveLabels,
  userResolveOptions,
  UserTeamOptions,
} from './user-team/resolve';

export const kudosGiverResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.tql.resolvers.kudos-giver-title',
    defaultMessage: 'Giver',
    description: 'Kudos giver title',
  }),
  type: SupportedFiltersTypes.GIVER,
  optionType: OptionTypes.SELECT,
  resolveLabels: userResolveLabels,
  filterComparators: equalsOrFilterComparators,
  resolveOptions: userResolveOptions({ cloudId, workspaceId, orgId, cpusScope }),
  icon: <PersonIcon label="" />,
});

export const kudosRecipientResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.tql.resolvers.kudos-recipient-title',
    defaultMessage: 'Recipient',
    description: 'Kudos recipient title',
  }),
  type: SupportedFiltersTypes.RECIPIENT,
  optionType: OptionTypes.SELECT,
  resolveLabels: userResolveLabels,
  filterComparators: equalsOrFilterComparators,
  resolveOptions: userResolveOptions({ cloudId, workspaceId, orgId, cpusScope }),
  icon: <PersonIcon label="" />,
});

export const kudosRecipientTeamResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.tql.resolvers.kudos-recipient-team-title',
    defaultMessage: 'Recipient team',
    description: 'Kudos recipient team title',
  }),
  type: SupportedFiltersTypes.RECIPIENT_TEAM,
  optionType: OptionTypes.SELECT,
  filterComparators: equalsOrFilterComparators,
  resolveOptions: teamResolveOptions({ cloudId, orgId, workspaceId, cpusScope }),
  resolveLabels: content => teamResolveLabels(content, cloudId),
  icon: <TeamIcon label="" />,
});
