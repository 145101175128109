/**
 * @generated SignedSource<<bfcea416428a7ea19f1fc34ac6d1896e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type GoalSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "HIERARCHY_ASC" | "HIERARCHY_DESC" | "HIERARCHY_LEVEL_ASC" | "HIERARCHY_LEVEL_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "PHASE_ID_ASC" | "PHASE_ID_DESC" | "PROJECT_COUNT_ASC" | "PROJECT_COUNT_DESC" | "SCORE_ASC" | "SCORE_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type GoalsDirectoryEmbedQuery$variables = {
  directoryViewUuid?: string | null;
  q?: string | null;
  sort?: ReadonlyArray<GoalSortEnum | null> | null;
  workspaceUuid: string;
};
export type GoalsDirectoryEmbedQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExpandableGoalsList">;
};
export type GoalsDirectoryEmbedQuery = {
  response: GoalsDirectoryEmbedQuery$data;
  variables: GoalsDirectoryEmbedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewUuid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v4 = {
  "kind": "Variable",
  "name": "directoryViewUuid",
  "variableName": "directoryViewUuid"
},
v5 = {
  "kind": "Variable",
  "name": "q",
  "variableName": "q"
},
v6 = {
  "kind": "Variable",
  "name": "workspaceUuid",
  "variableName": "workspaceUuid"
},
v7 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 40
  },
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "sorts",
    "variableName": "sort"
  },
  (v6/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "score"
},
v10 = {
  "kind": "ScalarField",
  "name": "value"
},
v11 = {
  "args": [
    {
      "kind": "Literal",
      "name": "includeScore",
      "value": false
    }
  ],
  "kind": "ScalarField",
  "name": "label",
  "storageKey": "label(includeScore:false)"
},
v12 = [
  {
    "kind": "ScalarField",
    "name": "messageId"
  }
],
v13 = {
  "concreteType": "LocalizationField",
  "kind": "LinkedField",
  "name": "localizedLabel",
  "plural": false,
  "selections": (v12/*: any*/)
},
v14 = {
  "kind": "ScalarField",
  "name": "name"
},
v15 = [
  {
    "kind": "Literal",
    "name": "longFormat",
    "value": true
  }
],
v16 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "GoalsDirectoryEmbedQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "sort",
            "variableName": "sort"
          },
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ExpandableGoalsList"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GoalsDirectoryEmbedQuery",
    "selections": [
      {
        "args": (v7/*: any*/),
        "concreteType": "GoalConnection",
        "kind": "LinkedField",
        "name": "goalTqlFullHierarchy",
        "plural": false,
        "selections": [
          {
            "concreteType": "GoalEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "uuid"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "concreteType": "GoalState",
                    "kind": "LinkedField",
                    "name": "state",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": "goalStateValue",
                        "kind": "ScalarField",
                        "name": "value"
                      },
                      {
                        "concreteType": "CompletionGoalState",
                        "kind": "LinkedField",
                        "name": "atCompletionState",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v13/*: any*/),
                          (v10/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v14/*: any*/),
                  {
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "aaid"
                      },
                      {
                        "concreteType": "UserPII",
                        "kind": "LinkedField",
                        "name": "pii",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          (v14/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "accountStatus"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          }
                        ]
                      },
                      (v8/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "TargetDate",
                    "kind": "LinkedField",
                    "name": "dueDate",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "confidence"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "label"
                      },
                      (v13/*: any*/),
                      {
                        "alias": "tooltip",
                        "args": (v15/*: any*/),
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": "label(longFormat:true)"
                      },
                      {
                        "alias": "localizedTooltip",
                        "args": (v15/*: any*/),
                        "concreteType": "LocalizationField",
                        "kind": "LinkedField",
                        "name": "localizedLabel",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": "localizedLabel(longFormat:true)"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "overdue"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasSubgoalMatches"
                  },
                  {
                    "concreteType": "GoalConnection",
                    "kind": "LinkedField",
                    "name": "subGoals",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "GoalEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v16/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v16/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "name": "cursor"
              }
            ]
          },
          {
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "endCursor"
              },
              {
                "kind": "ScalarField",
                "name": "hasNextPage"
              }
            ]
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__id"
              }
            ]
          }
        ]
      },
      {
        "args": (v7/*: any*/),
        "filters": [
          "q",
          "sorts",
          "directoryViewUuid",
          "workspaceUuid"
        ],
        "handle": "connection",
        "key": "ExpandableGoalsList_goalTqlFullHierarchy",
        "kind": "LinkedHandle",
        "name": "goalTqlFullHierarchy"
      }
    ]
  },
  "params": {
    "cacheID": "b81572d061b4811debc3b9285cd550ba",
    "id": null,
    "metadata": {},
    "name": "GoalsDirectoryEmbedQuery",
    "operationKind": "query",
    "text": "query GoalsDirectoryEmbedQuery(\n  $q: String\n  $directoryViewUuid: UUID\n  $workspaceUuid: UUID!\n  $sort: [GoalSortEnum]\n) {\n  ...ExpandableGoalsList_47e4g9\n}\n\nfragment ExpandableGoalsList_47e4g9 on Query {\n  goalTqlFullHierarchy(first: 40, q: $q, sorts: $sort, directoryViewUuid: $directoryViewUuid, workspaceUuid: $workspaceUuid) {\n    edges {\n      node {\n        ...RootGoalEmbedRow\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment GoalEmbedRow on Goal {\n  ...GoalName\n  id\n  owner {\n    ...UserAvatar_1cDMtx\n    id\n  }\n  state {\n    ...GoalState\n  }\n  dueDate {\n    ...TargetDate\n  }\n  hasSubgoalMatches\n  subGoals {\n    edges {\n      __typename\n    }\n  }\n}\n\nfragment GoalIcon on Goal {\n  state {\n    score\n    value\n  }\n}\n\nfragment GoalName on Goal {\n  uuid\n  id\n  key\n  ...GoalIcon\n  name\n}\n\nfragment GoalState on GoalState {\n  label(includeScore: false)\n  localizedLabel {\n    messageId\n  }\n  score\n  goalStateValue: value\n  atCompletionState {\n    label(includeScore: false)\n    localizedLabel {\n      messageId\n    }\n    value\n    score\n  }\n}\n\nfragment RootGoalEmbedRow on Goal {\n  id\n  ...GoalEmbedRow\n}\n\nfragment TargetDate on TargetDate {\n  confidence\n  label\n  localizedLabel {\n    messageId\n  }\n  tooltip: label(longFormat: true)\n  localizedTooltip: localizedLabel(longFormat: true) {\n    messageId\n  }\n  overdue\n}\n\nfragment UserAvatar_1cDMtx on User {\n  aaid\n  pii {\n    picture\n    name\n    accountStatus\n    accountId\n  }\n}\n"
  }
};
})();

(node as any).hash = "13be4eca65d3d704f1171b01e9ab3342";

export default node;
