import Loadable from 'react-loadable';

import { TOPIC_PAGE, TOPIC_PAGE_PATH_PREFIX } from '@townsquare/config/routes';
import { createSiteRoute } from '@townsquare/router/create-route';

import { topicResource } from './resources/topic';
import { topicAsideResource } from './resources/topic-aside';

export const topicRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "topic-view" */ './ui/TopicPage'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () => import(/* webpackChunkName: "topic-view-aside" */ './ui/TopicPageAside'),
    loading: () => null,
  }),
  exact: true,
  name: TOPIC_PAGE,
  path: `${TOPIC_PAGE_PATH_PREFIX}/:uuid/:tabName?/:viewMode?`,
  resources: [topicResource, topicAsideResource],
  subproduct: 'townsquare-topics',
});
