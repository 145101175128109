import Loadable from 'react-loadable';

import { YOUR_WORK_PAGE, YOUR_WORK_PATH } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

// This route definition is only used in nav for matching the current route
export const yourWorkRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "YourWorkViewNoWorkspace" */ './YourWorkViewNoWorkspace'),
    loading: () => null,
  }),
  exact: true,
  name: YOUR_WORK_PAGE,
  path: YOUR_WORK_PATH,
  layout: 'home',
});
