import Button from '@atlaskit/button';
import { IconButton, ButtonProps } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/utility/cross';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import React, { PropsWithChildren, useState } from 'react';

import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { RemoveIcon } from './icons/Remove';
import { FilterTagText, FilterTagWrapper } from './style';

interface Props {
  removeButtonLabel: string;
  onRemove: () => void;
  appearance: Extract<NonNullable<ButtonProps['appearance']>, 'default' | 'danger'>;
  labelForTestId?: string;
}

const filterTagStyles = xcss({
  backgroundColor: 'color.background.selected',
  borderColor: 'color.border.selected',
  borderWidth: '1px',
  borderStyle: 'solid',
  display: 'inline-flex',
  alignItems: 'center',
  paddingInlineStart: 'space.100',
  borderRadius: '4px',
  gap: 'space.050',
});

const filterTagDangerStyles = xcss({
  backgroundColor: 'color.background.danger',
  borderColor: 'color.border.danger',
});

export const FilterTag = (props: PropsWithChildren<Props>) => {
  const [appearance, setAppearance] = useState<Props['appearance']>(props.appearance);
  const isHomeVisualRefreshEnabled = useIsHomeVisualRefreshEnabled();

  return isHomeVisualRefreshEnabled ? (
    <Box xcss={[filterTagStyles, appearance === 'danger' && filterTagDangerStyles]}>
      <Text color={appearance === 'danger' ? 'color.text.danger' : 'color.text.selected'}>{props.children}</Text>
      <IconButton
        label=""
        spacing="compact"
        appearance="subtle"
        onClick={props.onRemove}
        onMouseEnter={() => setAppearance('danger')}
        onMouseLeave={() => setAppearance(props.appearance)}
        icon={iconProps => (
          <CrossIcon
            {...iconProps}
            color={appearance === 'danger' ? 'var(--ds-icon-danger)' : 'var(--ds-icon-selected)'}
            LEGACY_size="small"
          />
        )}
      />
    </Box>
  ) : (
    <FilterTagWrapper data-testid="metafilter-filter-tag" appearance={appearance}>
      <Tooltip content={props.children}>
        <FilterTagText appearance={appearance}>{props.children}</FilterTagText>
      </Tooltip>
      <Button
        iconBefore={
          <RemoveIcon
            size="medium"
            label={props.removeButtonLabel}
            primaryColor={appearance === 'danger' ? token('color.icon.danger') : token('color.icon.subtle')}
          />
        }
        onClick={props.onRemove}
        appearance="subtle-link"
        spacing="none"
        onMouseEnter={() => setAppearance('danger')}
        onMouseLeave={() => setAppearance(props.appearance ?? 'default')}
        style={{
          // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
          height: '1rem',
        }}
        testId={'metafilter-remove-filter-tag-' + props.labelForTestId?.replace(' ', '_').toLowerCase()}
      />
    </FilterTagWrapper>
  );
};
