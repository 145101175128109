import { ThemeProps } from '@atlaskit/button/types';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const InfoBox = styled.div<{ fullWidth: boolean; padding?: string; margin?: string }>(props => ({
  display: 'flex',
  flexDirection: 'column',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: !props.fullWidth ? '33%' : undefined,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  flexGrow: props.fullWidth ? 1 : undefined,
  alignItems: 'flex-start',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  padding: props.padding ?? '24px',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  margin: props.margin ?? '0px',
  border: `1px solid ${token('color.border.disabled')}`,
  borderRadius: '4px',
  backgroundColor: token('color.background.neutral.subtle'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ExpandableInfoBoxHeadingContainer = styled.div<{ canClick: boolean }>(({ canClick }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  cursor: canClick ? 'pointer' : undefined,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExpandableInfoBoxHeadingContainerInner = styled.div({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExpandableInfoBoxHeading = styled.div<{
  hideControlOnOpen: boolean;
  isOpen: boolean;
  useSubtleTitle: boolean;
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(props => ({
  font: token('font.heading.xsmall'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: props.useSubtleTitle ? token('color.text.subtle') : undefined,
  marginTop: 0,
  marginLeft: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginRight: props.hideControlOnOpen && props.isOpen ? 0 : token('space.100'),
  width: '100%',
}));

export const fixedButtonStyle = (current: (themeProps: ThemeProps) => any, themeProps: ThemeProps) => {
  const theme = current(themeProps);
  return {
    ...theme,
    buttonStyles: {
      ...theme.buttonStyles,
      flexShrink: 0,
      alignSelf: 'flex-start',
    },
  };
};
