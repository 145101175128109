/**
 * @generated SignedSource<<59d0de472188b6740f789d264021df2e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ProjectSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "STATUS_ASC" | "STATUS_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type ProjectsDirectoryEmbedQuery$variables = {
  directoryViewUuid?: string | null;
  q?: string | null;
  sort?: ReadonlyArray<ProjectSortEnum | null> | null;
  workspaceUuid: string;
};
export type ProjectsDirectoryEmbedQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsEmbedList">;
};
export type ProjectsDirectoryEmbedQuery = {
  response: ProjectsDirectoryEmbedQuery$data;
  variables: ProjectsDirectoryEmbedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewUuid"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v4 = [
  {
    "kind": "Variable",
    "name": "directoryViewUuid",
    "variableName": "directoryViewUuid"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  },
  {
    "kind": "Variable",
    "name": "workspaceUuid",
    "variableName": "workspaceUuid"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "label"
},
v8 = [
  {
    "kind": "ScalarField",
    "name": "messageId"
  }
],
v9 = {
  "concreteType": "LocalizationField",
  "kind": "LinkedField",
  "name": "localizedLabel",
  "plural": false,
  "selections": (v8/*: any*/)
},
v10 = [
  {
    "kind": "Literal",
    "name": "longFormat",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ProjectsDirectoryEmbedQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "ProjectsEmbedList"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectsDirectoryEmbedQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "uuid"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "private"
                  },
                  {
                    "concreteType": "IconURIs",
                    "kind": "LinkedField",
                    "name": "iconUrl",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "ThemeURIs",
                        "kind": "LinkedField",
                        "name": "square",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "light"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "dark"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "aaid"
                      },
                      {
                        "concreteType": "UserPII",
                        "kind": "LinkedField",
                        "name": "pii",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "accountStatus"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          }
                        ]
                      },
                      (v5/*: any*/)
                    ]
                  },
                  {
                    "concreteType": "ProjectState",
                    "kind": "LinkedField",
                    "name": "state",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": "projectStateValue",
                        "kind": "ScalarField",
                        "name": "value"
                      },
                      {
                        "concreteType": "CompletionProjectState",
                        "kind": "LinkedField",
                        "name": "atCompletionState",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "value"
                          },
                          (v7/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "TargetDate",
                    "kind": "LinkedField",
                    "name": "dueDate",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "confidence"
                      },
                      (v7/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": "tooltip",
                        "args": (v10/*: any*/),
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": "label(longFormat:true)"
                      },
                      {
                        "alias": "localizedTooltip",
                        "args": (v10/*: any*/),
                        "concreteType": "LocalizationField",
                        "kind": "LinkedField",
                        "name": "localizedLabel",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": "localizedLabel(longFormat:true)"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "overdue"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "cursor"
              }
            ]
          },
          {
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "endCursor"
              },
              {
                "kind": "ScalarField",
                "name": "hasNextPage"
              }
            ]
          }
        ]
      },
      {
        "args": (v4/*: any*/),
        "filters": [
          "workspaceUuid",
          "q",
          "directoryViewUuid",
          "sort"
        ],
        "handle": "connection",
        "key": "ProjectsEmbedList_projectTql",
        "kind": "LinkedHandle",
        "name": "projectTql"
      }
    ]
  },
  "params": {
    "cacheID": "18f03c3933b8a5c251c9d5b39dc2c3c8",
    "id": null,
    "metadata": {},
    "name": "ProjectsDirectoryEmbedQuery",
    "operationKind": "query",
    "text": "query ProjectsDirectoryEmbedQuery(\n  $q: String\n  $directoryViewUuid: UUID\n  $workspaceUuid: UUID!\n  $sort: [ProjectSortEnum]\n) {\n  ...ProjectsEmbedList_2jhaCZ\n}\n\nfragment ProjectEmbedRow on Project {\n  ...ProjectName_data\n  owner {\n    ...UserAvatar_1cDMtx\n    id\n  }\n  state {\n    ...ProjectState\n  }\n  dueDate {\n    ...TargetDate\n  }\n}\n\nfragment ProjectIcon on Project {\n  private\n  iconUrl {\n    square {\n      light\n      dark\n    }\n  }\n}\n\nfragment ProjectName_data on Project {\n  id\n  key\n  name\n  uuid\n  ...ProjectIcon\n}\n\nfragment ProjectState on ProjectState {\n  label\n  localizedLabel {\n    messageId\n  }\n  projectStateValue: value\n  atCompletionState {\n    value\n    label\n    localizedLabel {\n      messageId\n    }\n  }\n}\n\nfragment ProjectsEmbedList_2jhaCZ on Query {\n  projectTql(first: 15, workspaceUuid: $workspaceUuid, q: $q, directoryViewUuid: $directoryViewUuid, sort: $sort) {\n    edges {\n      node {\n        ...ProjectEmbedRow\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TargetDate on TargetDate {\n  confidence\n  label\n  localizedLabel {\n    messageId\n  }\n  tooltip: label(longFormat: true)\n  localizedTooltip: localizedLabel(longFormat: true) {\n    messageId\n  }\n  overdue\n}\n\nfragment UserAvatar_1cDMtx on User {\n  aaid\n  pii {\n    picture\n    name\n    accountStatus\n    accountId\n  }\n}\n"
  }
};
})();

(node as any).hash = "5accab12f3967c745112cb7245b4e39d";

export default node;
