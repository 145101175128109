import { type AppSwitcherProps, IconButton } from '@atlaskit/atlassian-navigation';
import AppSwitcherIcon from '@atlaskit/icon/core/app-switcher';
import { token } from '@atlaskit/tokens';
import React, { forwardRef, type Ref } from 'react';

export const AppSwitcherRefreshed = forwardRef((props: AppSwitcherProps, ref: Ref<HTMLElement>) => {
  return (
    <IconButton
      icon={<AppSwitcherIcon label="" spacing="spacious" color={token('color.icon')} />}
      ref={ref}
      {...props}
    />
  );
});
