/**
 * @generated SignedSource<<4fe2c69baa8b2d06f54f602203ef47fd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type HomeSidebarNavigationQuery$variables = {
  cloudId?: string | null;
  hasWorkspace: boolean;
  savedViewTql?: string | null;
  workspaceId?: string | null;
  workspaceUuid: string;
};
export type HomeSidebarNavigationQuery$data = {
  readonly currentUser?: {
    readonly id: string;
    readonly tagsWatching: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly uuid: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly teamsWatching?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly teamId: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly directoryViewTql?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null;
        readonly uuid: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type HomeSidebarNavigationQuery = {
  response: HomeSidebarNavigationQuery$data;
  variables: HomeSidebarNavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasWorkspace"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "savedViewTql"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "Variable",
  "name": "workspaceId",
  "variableName": "workspaceId"
},
v7 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v11 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v12 = [
  {
    "concreteType": "TagEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ]
      },
      (v10/*: any*/)
    ]
  },
  (v11/*: any*/)
],
v13 = {
  "condition": "hasWorkspace",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "TeamConnection",
      "kind": "LinkedField",
      "name": "teamsWatching",
      "plural": false,
      "selections": [
        {
          "concreteType": "TeamEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "teamId"
                },
                (v8/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
},
v14 = {
  "kind": "Variable",
  "name": "workspaceUuid",
  "variableName": "workspaceUuid"
},
v15 = {
  "kind": "Literal",
  "name": "first",
  "value": 200
},
v16 = [
  (v15/*: any*/),
  (v6/*: any*/)
],
v17 = [
  (v15/*: any*/),
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "savedViewTql"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      "FAVOURITED_DATE_DESC"
    ]
  },
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "HomeSidebarNavigationQuery",
    "selections": [
      {
        "condition": "hasWorkspace",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": "tagsWatching",
                "args": [
                  (v6/*: any*/)
                ],
                "concreteType": "TagConnection",
                "kind": "LinkedField",
                "name": "__CurrentUser__tagsWatching_connection",
                "plural": false,
                "selections": (v12/*: any*/)
              },
              (v13/*: any*/)
            ]
          },
          {
            "alias": "directoryViewTql",
            "args": [
              (v14/*: any*/)
            ],
            "concreteType": "DirectoryViewConnection",
            "kind": "LinkedField",
            "name": "__HomeSidebarNavigationQuery_directoryViewTql_connection",
            "plural": false,
            "selections": [
              {
                "concreteType": "DirectoryViewEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "DirectoryView",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "HomeSidebarNavigationQuery",
    "selections": [
      {
        "condition": "hasWorkspace",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "args": (v16/*: any*/),
                "concreteType": "TagConnection",
                "kind": "LinkedField",
                "name": "tagsWatching",
                "plural": false,
                "selections": (v12/*: any*/)
              },
              {
                "args": (v16/*: any*/),
                "filters": [
                  "workspaceId"
                ],
                "handle": "connection",
                "key": "CurrentUser__tagsWatching",
                "kind": "LinkedHandle",
                "name": "tagsWatching"
              },
              (v13/*: any*/)
            ]
          },
          {
            "args": (v17/*: any*/),
            "concreteType": "DirectoryViewConnection",
            "kind": "LinkedField",
            "name": "directoryViewTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "DirectoryViewEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "DirectoryView",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v5/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          },
          {
            "args": (v17/*: any*/),
            "filters": [
              "workspaceUuid"
            ],
            "handle": "connection",
            "key": "HomeSidebarNavigationQuery_directoryViewTql",
            "kind": "LinkedHandle",
            "name": "directoryViewTql"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "43b3c9bc68f3701e0b6ac051325bb115",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "currentUser",
            "tagsWatching"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "directoryViewTql"
          ]
        }
      ]
    },
    "name": "HomeSidebarNavigationQuery",
    "operationKind": "query",
    "text": "query HomeSidebarNavigationQuery(\n  $workspaceId: ID\n  $workspaceUuid: UUID!\n  $savedViewTql: String\n  $cloudId: String\n  $hasWorkspace: Boolean!\n) {\n  currentUser @include(if: $hasWorkspace) {\n    id\n    tagsWatching(workspaceId: $workspaceId, first: 200) {\n      edges {\n        node {\n          id\n          uuid\n          name\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    teamsWatching(cloudId: $cloudId) @include(if: $hasWorkspace) {\n      edges {\n        node {\n          id\n          teamId\n          name\n        }\n      }\n    }\n  }\n  directoryViewTql(q: $savedViewTql, first: 200, workspaceUuid: $workspaceUuid, sort: [FAVOURITED_DATE_DESC]) @include(if: $hasWorkspace) {\n    edges {\n      node {\n        uuid\n        name\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f361375e59df4f353f53e8bfb27f55b";

export default node;
