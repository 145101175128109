export const isUserAgentFirefox = () => {
  return navigator.userAgent.toLowerCase().includes('firefox');
};

export const isUserAgentChrome = () => {
  return navigator.userAgent.toLowerCase().includes('chrome');
};

export const isUserAgentSafari = () => {
  return navigator.userAgent.toLowerCase().includes('safari') && !isUserAgentChrome();
};
