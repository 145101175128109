/**
 * @generated SignedSource<<5585670515bcf87ab5bde6426c86abb4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SearchKudosScreenPaginationQuery$variables = {
  after?: string | null;
  first?: number | null;
  isSearchKudosScreen?: boolean | null;
  query: string;
  workspaceId?: string | null;
};
export type SearchKudosScreenPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchKudosScreen_data">;
};
export type SearchKudosScreenPaginationQuery = {
  response: SearchKudosScreenPaginationQuery$data;
  variables: SearchKudosScreenPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 12,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "isSearchKudosScreen"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "workspaceId",
  "variableName": "workspaceId"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "query"
  },
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "picture"
},
v7 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "SearchKudosScreenPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "isSearchKudosScreen",
            "variableName": "isSearchKudosScreen"
          },
          {
            "kind": "Variable",
            "name": "query",
            "variableName": "query"
          },
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SearchKudosScreen_data"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchKudosScreenPaginationQuery",
    "selections": [
      {
        "condition": "isSearchKudosScreen",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "KudosConnection",
            "kind": "LinkedField",
            "name": "kudosTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "KudosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Kudos",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "uuid"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "ari"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "description"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "imageUrl"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "stillImageUrl"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "type"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "bgTheme"
                      },
                      {
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "giver",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "UserPII",
                            "kind": "LinkedField",
                            "name": "pii",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ]
                          },
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "KudosRecipientConnection",
                        "kind": "LinkedField",
                        "name": "recipients",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "KudosRecipientEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "KudosRecipient",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "recipient",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "UserPII",
                                        "kind": "LinkedField",
                                        "name": "pii",
                                        "plural": false,
                                        "selections": [
                                          (v7/*: any*/),
                                          (v5/*: any*/),
                                          (v6/*: any*/)
                                        ]
                                      },
                                      (v8/*: any*/)
                                    ]
                                  },
                                  {
                                    "concreteType": "Team",
                                    "kind": "LinkedField",
                                    "name": "team",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "teamId"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "avatarUrl"
                                      },
                                      (v8/*: any*/)
                                    ]
                                  },
                                  (v8/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "recipients(first:1)"
                      },
                      (v8/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "args": (v4/*: any*/),
            "filters": [
              "q",
              "workspaceId"
            ],
            "handle": "connection",
            "key": "SearchKudosScreen_kudosTql",
            "kind": "LinkedHandle",
            "name": "kudosTql"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "afdb41138858be6c83dbb3d4608c05e2",
    "id": null,
    "metadata": {},
    "name": "SearchKudosScreenPaginationQuery",
    "operationKind": "query",
    "text": "query SearchKudosScreenPaginationQuery(\n  $after: String\n  $first: Int = 12\n  $isSearchKudosScreen: Boolean = false\n  $query: String!\n  $workspaceId: ID\n) {\n  ...SearchKudosScreen_data_48E3U5\n}\n\nfragment KudosContent on Kudos {\n  uuid\n  ari\n  description\n  imageUrl\n  stillImageUrl\n  type\n  bgTheme\n  giver {\n    pii {\n      name\n      picture\n      accountId\n    }\n    id\n  }\n  recipients(first: 1) {\n    edges {\n      node {\n        recipient {\n          pii {\n            accountId\n            name\n            picture\n          }\n          id\n        }\n        team {\n          name\n          teamId\n          avatarUrl\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment SearchKudosScreen_data_48E3U5 on Query {\n  kudosTql(q: $query, workspaceId: $workspaceId, first: $first, after: $after) @include(if: $isSearchKudosScreen) {\n    edges {\n      node {\n        ...KudosContent\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "83f7040b196764c859dd75a9237775ce";

export default node;
