import { createActionsHook, createStateHook, createStore } from 'react-sweet-state';

export type CoveringKind = 'project' | 'goal' | 'topic' | 'team';

export const coverStore = createStore({
  initialState: {
    shown: false,
    removed: [] as CoveringKind[],
  },
  actions: {
    setShown:
      (shown: boolean) =>
      ({ setState }) => {
        setState({ shown });
      },
    hideOnboarding:
      (kind: CoveringKind) =>
      ({ getState, setState }) => {
        if (getState().removed.includes(kind)) {
          return;
        }

        setState({
          removed: [...getState().removed, kind],
        });
      },
  },
});

export const useOnboardingCover = createActionsHook(coverStore);
export const useCovering = createStateHook(coverStore, {
  selector: (state, arg: CoveringKind) => {
    const removed = state.removed.includes(arg);

    return {
      shouldHide: state.shown || removed,
      isRemoved: removed,
    };
  },
});
