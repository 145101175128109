/**
 * @generated SignedSource<<f9c1d4f95fa98750237f5bb7b4af06f0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ProjectStateValue = "archived" | "at_risk" | "cancelled" | "done" | "off_track" | "on_track" | "paused" | "pending";
export type ProjectMenuButtonQuery$variables = {
  followingLimit?: number | null;
  followingTql: string;
  workspaceUuid: string;
  yourProjectsLimit?: number | null;
  yourProjectsTql: string;
};
export type ProjectMenuButtonQuery$data = {
  readonly followingProjects: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly state: {
          readonly value: ProjectStateValue | null;
        } | null;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProjectLinkItem">;
      } | null;
    } | null> | null;
  } | null;
  readonly yourProjects: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly state: {
          readonly value: ProjectStateValue | null;
        } | null;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProjectLinkItem">;
      } | null;
    } | null> | null;
  } | null;
};
export type ProjectMenuButtonQuery = {
  response: ProjectMenuButtonQuery$data;
  variables: ProjectMenuButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "followingLimit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "followingTql"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yourProjectsLimit"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yourProjectsTql"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceUuid",
  "variableName": "workspaceUuid"
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "yourProjectsLimit"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "yourProjectsTql"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": "LATEST_UPDATE_DATE_ASC"
  },
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "count"
},
v8 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v9 = {
  "concreteType": "ProjectState",
  "kind": "LinkedField",
  "name": "state",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "value"
    }
  ]
},
v10 = [
  (v7/*: any*/),
  {
    "concreteType": "ProjectEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "ProjectLinkItem"
          }
        ]
      }
    ]
  }
],
v11 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "followingLimit"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "followingTql"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": "LATEST_UPDATE_DATE_DESC"
  },
  (v5/*: any*/)
],
v12 = [
  (v7/*: any*/),
  {
    "concreteType": "ProjectEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "private"
          },
          {
            "concreteType": "IconURIs",
            "kind": "LinkedField",
            "name": "iconUrl",
            "plural": false,
            "selections": [
              {
                "concreteType": "ThemeURIs",
                "kind": "LinkedField",
                "name": "square",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "light"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "dark"
                  }
                ]
              }
            ]
          },
          {
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "name": "watcherCount"
          },
          {
            "kind": "ScalarField",
            "name": "key"
          },
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ProjectMenuButtonQuery",
    "selections": [
      {
        "alias": "yourProjects",
        "args": (v6/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": (v10/*: any*/)
      },
      {
        "alias": "followingProjects",
        "args": (v11/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": (v10/*: any*/)
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectMenuButtonQuery",
    "selections": [
      {
        "alias": "yourProjects",
        "args": (v6/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": (v12/*: any*/)
      },
      {
        "alias": "followingProjects",
        "args": (v11/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": (v12/*: any*/)
      }
    ]
  },
  "params": {
    "cacheID": "11ee4023e136034fde68c362ae110de0",
    "id": null,
    "metadata": {},
    "name": "ProjectMenuButtonQuery",
    "operationKind": "query",
    "text": "query ProjectMenuButtonQuery(\n  $workspaceUuid: UUID!\n  $yourProjectsTql: String!\n  $followingTql: String!\n  $yourProjectsLimit: Int\n  $followingLimit: Int\n) {\n  yourProjects: projectTql(q: $yourProjectsTql, workspaceUuid: $workspaceUuid, sort: LATEST_UPDATE_DATE_ASC, first: $yourProjectsLimit) {\n    count\n    edges {\n      node {\n        uuid\n        state {\n          value\n        }\n        ...ProjectLinkItem\n        id\n      }\n    }\n  }\n  followingProjects: projectTql(q: $followingTql, workspaceUuid: $workspaceUuid, sort: LATEST_UPDATE_DATE_DESC, first: $followingLimit) {\n    count\n    edges {\n      node {\n        uuid\n        state {\n          value\n        }\n        ...ProjectLinkItem\n        id\n      }\n    }\n  }\n}\n\nfragment ProjectIcon on Project {\n  private\n  iconUrl {\n    square {\n      light\n      dark\n    }\n  }\n}\n\nfragment ProjectLinkItem on Project {\n  uuid\n  name\n  ...ProjectIcon\n  members {\n    count\n  }\n  watcherCount\n  key\n}\n"
  }
};
})();

(node as any).hash = "e80dfb566f95fab91b54dcfb22cf5c98";

export default node;
