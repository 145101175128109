/**
 * DO NOT MANUALLY CHANGE THIS FILE AS IT IS AUTOGENERATED.
 */
import { Icon } from '@atlaskit/icon/base';
import { type GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function FileIconGlyph() {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        d="M12.586 4a2 2 0 0 1 1.284.467l.13.119L17.414 8a2 2 0 0 1 .578 1.238l.008.176V18a2 2 0 0 1-1.85 1.994L16 20H8a2 2 0 0 1-1.995-1.85L6 18V6a2 2 0 0 1 1.85-1.995L8 4h4.586ZM11 6H8v12h8v-7h-3a2 2 0 0 1-1.995-1.85L11 9V6Zm3 7.5a1 1 0 0 1 .117 1.993L14 15.5h-4a1 1 0 0 1-.117-1.993L10 13.5h4Zm-1-7.085V9h2.586L13 6.415Z"
        fill="currentColor"
      />
    </svg>
  );
}

function FileIcon(props: GlyphProps) {
  return <Icon glyph={FileIconGlyph} {...props} />;
}

export default FileIcon;
