import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import TopicPageAsideQuery, {
  type TopicPageAsideQuery as TopicPageAsideQueryQueryType,
} from '../ui/__generated__/TopicPageAsideQuery.graphql';

export const topicAsideResource = createRelayResource<TopicPageAsideQueryQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const uuid = routerContext.match.params.uuid ?? '';

    return {
      query: TopicPageAsideQuery,
      variables: {
        tagUuid: uuid,
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
    };
  },
});
