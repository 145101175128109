import { usePathParam } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { ComparatorOperator, SupportedFiltersTypes } from '@townsquare/tql/types';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';

import { PeopleFieldType } from '../../../../hooks/types';
import { staffDirectoryRoute } from '../../../../route';
import { ScreenType } from '../../../ScreenType';

const getFilterTypeForFieldType = (fieldType: PeopleFieldType) => {
  switch (fieldType) {
    case 'JOB_TITLE':
      return SupportedFiltersTypes.JOB_TITLE;
    case 'LOCATION':
      return SupportedFiltersTypes.LOCATION;
    case 'DEPARTMENT':
    default:
      return SupportedFiltersTypes.DEPARTMENT;
  }
};

export const usePeopleFieldCardClickHandler = (searchTerm: string, fieldType: PeopleFieldType) => {
  const analytics = useAnalytics();
  const [search] = usePathParam('search');

  const queryString = createSearchParamsFromCurrentUrl({
    screen: ScreenType[ScreenType.SEARCH_PEOPLE],
    tql: `((${getFilterTypeForFieldType(fieldType)} ${ComparatorOperator.LIKE} '${searchTerm}'))`,
  });

  const { handler } = useMetaClickHandler(
    staffDirectoryRoute,
    { params: { search }, query: Object.fromEntries(queryString) },
    () => {
      void analytics.ui('staffDirectoryPeopleFieldCard', 'clicked', { fieldType });
    },
  );

  return handler;
};
