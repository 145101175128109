export const isMac = /Mac/.test(window.navigator.userAgent);
export const isWindows = /Win/.test(window.navigator.userAgent);

export const getShortcutHelpModifier = () => {
  return isMac ? 'opt' : isWindows ? 'alt' : 'alt/opt';
};

export const getShortcutModifier = () => {
  return isMac ? 'option+' : 'alt+';
};

export const getOsSpecificMetaKey = () => {
  return isMac ? 'cmd' : isWindows ? 'window' : 'cmd/window';
};
