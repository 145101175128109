import AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import React from 'react';

import { noop, promiseNoop } from '@townsquare/noop';

import { AnalyticsClient, SubproductAnalyticsAttribute } from './types';

export default React.createContext<AnalyticsClient>({
  getDefaultSource: () => '',
  // Seems dodgy, but I don't think we want to return the real client here?
  // If things fail, I would rather this fail spectacularly so we can fix it
  getAnalyticsWebClient: () => ({} as unknown as AnalyticsWebClient),
  getTopLevelAttributes: promiseNoop,
  page: promiseNoop,
  startUIViewedEvent: promiseNoop,
  track: promiseNoop,
  ui: promiseNoop,
  operational: promiseNoop,
  setCurrentUserAndTenantInfo: noop,
  setTopLevelAttributes: noop,
  getHasTenantInfo: () => false,
  setSubproduct: noop,
});

const cache = new WeakMap();

export const wrapAnalyticsClientWithExtraAttributes = (
  client: AnalyticsClient,
  extraAttributes: object,
): AnalyticsClient => {
  const withExtraAttributes = (current: object = {}): object => ({ ...current, ...extraAttributes });

  if (cache.has(extraAttributes)) {
    return cache.get(extraAttributes);
  }

  class WrappedAnalyticsClient implements AnalyticsClient {
    getAnalyticsWebClient() {
      return client.getAnalyticsWebClient();
    }
    getTopLevelAttributes() {
      return client.getTopLevelAttributes();
    }
    page(name: string, properties?: object): Promise<void> {
      return client.page(name, withExtraAttributes(properties));
    }
    setCurrentUserAndTenantInfo(accountId: string, workspaceUuid: string, cloudId?: string): void {
      return client.setCurrentUserAndTenantInfo(accountId, workspaceUuid, cloudId);
    }
    startUIViewedEvent(): Promise<void> {
      return client.startUIViewedEvent();
    }
    track(subject: string, action: string, attributes?: object, actionSubjectId?: string): Promise<void> {
      return client.track(subject, action, withExtraAttributes(attributes), actionSubjectId);
    }
    ui(subject: string, action: string, attributes?: object, actionSubjectId?: string): Promise<void> {
      return client.ui(subject, action, withExtraAttributes(attributes), actionSubjectId);
    }
    operational(subject: string, action: string, attributes?: object): Promise<void> {
      return client.operational(subject, action, withExtraAttributes(attributes));
    }
    getDefaultSource(): string {
      return client.getDefaultSource();
    }
    setTopLevelAttributes(newAttributes: object): void {
      return client.setTopLevelAttributes(newAttributes);
    }
    getHasTenantInfo(): boolean {
      return client.getHasTenantInfo();
    }

    setSubproduct(subproduct: SubproductAnalyticsAttribute): void {
      return client.setSubproduct(subproduct);
    }
  }

  const newClient = new WrappedAnalyticsClient();
  cache.set(extraAttributes, newClient);
  return newClient;
};
