import React from 'react';

export const EllipsisTooltipTag = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>(
  (props, ref: React.Ref<HTMLElement>) => {
    const { children, ...rest } = props;
    return (
      <div
        {...rest}
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
        className="ellipsis-tooltip-container"
        ref={ref as React.Ref<HTMLDivElement>}
        style={{
          // This lineHeight: 1 may cause issues when this element wraps text nodes. Possibly not needed?
          // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
          lineHeight: 1,
          // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
          overflow: 'hidden',
          // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
          textOverflow: 'ellipsis',
          // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </div>
    );
  },
);
