import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';
import { stylesForNestedProfilePicture } from '@townsquare/profile-picture';
import { onMobile } from '@townsquare/responsive-grid';
import { Link } from '@townsquare/router/primitives';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfilePicBoundingBox = styled.div(
  {
    boxSizing: 'border-box',
    height: '228px',
    width: '100%',
    borderRadius: '6px',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      margin: 0,
      padding: 0,
      height: '180px',
      width: '100%',
    }),
  ),
  {
    transition: 'padding 0.1s',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  stylesForNestedProfilePicture(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      transition: 'box-shadow 0.1s ease-in-out',
    }),
  ),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NameText = styled.h4(
  {
    font: token('font.heading.small'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      font: token('font.body'),
      fontWeight: token('font.weight.medium'),
    }),
  ),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UserDetails = styled.div({
  paddingTop: token('space.150'),
});

const cardStyles = css({
  boxSizing: 'border-box',
  width: '212px',
  display: 'flex',
  flexDirection: 'column',
  padding: token('space.100'),
  borderRadius: '5px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledPersonBrowseCard = styled(Link)`
  ${cardStyles}

  ${onMobile(
    css({
      width: 'calc(50% - 5px)',
      padding: 0,
    }),
  )}

  &:hover {
    ${ProfilePicBoundingBox} {
      padding: 0;

      ${stylesForNestedProfilePicture(
        // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored due to `onMobile` usage.
        css`
          box-shadow: ${token('elevation.shadow.overlay')};

          ${onMobile(
            css({
              boxShadow: 'none',
            }),
          )}
        `,
      )}
    }

    ${NameText} {
      color: ${token('color.text.subtlest')};
    }

    text-decoration: none;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LoadingCard = styled.div`
  box-sizing: border-box;
`;
