import { token } from '@atlaskit/tokens';

import { GoalPhase, ScoreStatus, GoalStatus, LozengeStyle } from './types';

export const fixScore = (score: number | null | undefined) =>
  score === null || score === undefined ? null : score > 1 ? score / 100 : score;

export const GOAL_STATUSES: { [key in ScoreStatus]: GoalStatus & { name: ScoreStatus } } = {
  // There's no such thing as 'pending' status. This is kept here for legacy / backward-compatibility reason
  pending: {
    id: 'pending',
    displayName: 'Pending',
    name: 'pending',
  },
  on_track: {
    id: 'on_track',
    displayName: 'On track',
    name: 'on_track',
  },
  at_risk: {
    id: 'at_risk',
    displayName: 'At risk',
    name: 'at_risk',
  },
  off_track: {
    id: 'off_track',
    displayName: 'Off track',
    name: 'off_track',
  },
};

// Convert a goal score to a status object
export const goalScoreToStatusEntity = (score?: number | null, phase?: GoalPhase | null) => {
  if ((phase && ['done', 'in_progress'].includes(phase) === false) || score === null || score === undefined) {
    return GOAL_STATUSES.pending;
  }

  if (score > 1) {
    score = fixScore(score) ?? 0;
  }

  if (score < 0.4) {
    return GOAL_STATUSES.off_track;
  }

  if (score < 0.7) {
    return GOAL_STATUSES.at_risk;
  }

  return GOAL_STATUSES.on_track;
};

const legacyOnTrackStatusLozengeStyle: LozengeStyle = {
  backgroundColor: `${token('color.background.accent.green.subtlest')}`,
  color: `${token('color.text.accent.green')}`,
};
const legacyAtRiskStatusLozengeStyle: LozengeStyle = {
  backgroundColor: `${token('color.background.accent.yellow.subtlest')}`,
  color: `${token('color.text.accent.orange')}`,
};
const legacyOffTrackStatusLozengeStyle: LozengeStyle = {
  backgroundColor: `${token('color.background.accent.red.subtlest')}`,
  color: `${token('color.text.accent.red')}`,
};
const legacyDefaultStatusLozengeStyle: LozengeStyle = {
  backgroundColor: `${token('color.background.accent.gray.subtlest')}`,
  color: `${token('color.text.accent.gray')}`,
};

export const LegacyStatusLozengeStyles = {
  ON_TRACK: legacyOnTrackStatusLozengeStyle,
  AT_RISK: legacyAtRiskStatusLozengeStyle,
  OFF_TRACK: legacyOffTrackStatusLozengeStyle,
  DEFAULT: legacyDefaultStatusLozengeStyle,
};

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/* Colour tokens are explicitly disabled here because these are meant to represent
   goal status and should not be changed based on themes
   (colour tokens will change based on themes)
 */
const onTrackStatusLozengeStyle: LozengeStyle = {
  backgroundColor: '#B3DF72',
  color: '#292A2E',
};
const atRiskStatusLozengeStyle: LozengeStyle = {
  backgroundColor: '#FBC828',
  color: '#292A2E',
};
const offTrackStatusLozengeStyle: LozengeStyle = {
  backgroundColor: '#FD9891',
  color: '#292A2E',
};
const defaultStatusLozengeStyle: LozengeStyle = {
  backgroundColor: `${token('color.background.neutral')}`,
  color: `${token('color.text')}`,
};
/* eslint-enable @atlaskit/design-system/ensure-design-token-usage */

export const AtlassianHomeStatusLozengeStyles = {
  ON_TRACK: onTrackStatusLozengeStyle,
  AT_RISK: atRiskStatusLozengeStyle,
  OFF_TRACK: offTrackStatusLozengeStyle,
  DEFAULT: defaultStatusLozengeStyle,
};
