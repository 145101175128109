import React, { useMemo } from 'react';

import { LoadingSkeleton, SquareLoadingSkeleton } from '@townsquare/loading-skeleton';

import { AvatarGroup, AVATAR_SQUARE_SIDE_LENGTH_PX, LoadingBrowseTeamCard, TeamDetails } from './styles';

export const BrowseTeamCardLoadingState = () => {
  const loadingAvatars = useMemo(
    () => [...Array(4)].map((_, i) => <SquareLoadingSkeleton sideLengthPx={AVATAR_SQUARE_SIDE_LENGTH_PX} key={i} />),
    [],
  );

  return (
    <LoadingBrowseTeamCard>
      <AvatarGroup>{loadingAvatars}</AvatarGroup>
      <TeamDetails>
        <LoadingSkeleton width="190px" height="20px" marginBottomPx={4} />
        <LoadingSkeleton width="60px" height="14px" />
        <LoadingSkeleton width="190px" height="14px" marginTopPx={10} />
        <LoadingSkeleton width="190px" height="14px" marginTopPx={2} />
      </TeamDetails>
    </LoadingBrowseTeamCard>
  );
};
