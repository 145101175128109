import { createRelayResource } from '@townsquare/relay-utils';
import type { ResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import CapabilityProviderQuery, {
  type CapabilityProviderQuery as CapabilityProviderQueryType,
} from './components/__generated__/CapabilityProviderQuery.graphql';

export const capabilityProviderResource = createRelayResource<CapabilityProviderQueryType>({
  getQuery: (_, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const isValidWorkspace = isGateEnabled('atlas-enable-capability-router') && Boolean(context.workspace?.cloudId);

    return {
      query: CapabilityProviderQuery,
      variables: {
        isValidWorkspace,
        workspaceContext: {
          contextType: 'WorkspaceCloudId',
          identifier: context.workspace?.cloudId,
        },
      },
      maxAge: 60_000 * 30, // 30 minutes cache during the SPA session
    };
  },
});
