import OfficeBuildingIcon from '@atlaskit/icon/core/migration/office-building';
import { LinkItem } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { EllipsisTooltip } from '@townsquare/ellipsis';

import { WorkspaceDetailsForSwitcher } from './types';

interface Props {
  onItemClick: (e: MouseEvent | KeyboardEvent, workspace: WorkspaceDetailsForSwitcher) => void;
  workspace: WorkspaceDetailsForSwitcher;
  isSelected: boolean;
  href: string;
}

// Menu Group max width of 480 - 56 (icon width + padding + margin)
const MAX_WIDTH_OF_TEXT_BEFORE_TRUNCATING = 424;

export const SwitchWorkspaceItem = ({ onItemClick, workspace, href, isSelected }: Props) => {
  const intl = useIntl();

  const [includeTooltip, setIncludeTooltip] = useState(false);
  const handleOnClick = (e: MouseEvent | KeyboardEvent) => onItemClick(e, workspace);
  const WorkspaceLinkItem = (
    <div>
      <LinkItem
        href={href}
        iconBefore={
          <OfficeBuildingIcon
            color="currentColor"
            spacing="spacious"
            label={intl.formatMessage({
              id: 'townsquare.navigation.switch-workspace.icon',
              description: 'Icon for the switch workspace option',
              defaultMessage: 'Workspace option',
            })}
          />
        }
        key={`workspace-switcher-option-${workspace.cloudId}`}
        testId={`workspace-switcher-option-${workspace.cloudId}`}
        onClick={handleOnClick}
        isSelected={isSelected}
      >
        <span
          ref={el => {
            if ((el?.offsetWidth ?? 0) >= MAX_WIDTH_OF_TEXT_BEFORE_TRUNCATING) {
              setIncludeTooltip(true);
            }
          }}
        >
          {workspace.name}
        </span>
      </LinkItem>
    </div>
  );
  return includeTooltip ? (
    <EllipsisTooltip key={workspace.cloudId} content={workspace.name}>
      {WorkspaceLinkItem}
    </EllipsisTooltip>
  ) : (
    WorkspaceLinkItem
  );
};
