import { AnalyticsContext } from '@atlaskit/analytics-next';
import { Provider } from '@atlaskit/smart-card';
import { HoverCard } from '@atlaskit/smart-card/hover-card';
import React, { ReactElement } from 'react';
import { useRouter } from 'react-resource-router';
import styled from 'styled-components';

import { useSmartCardBaseUrl } from '@townsquare/workspace-store/hooks';

import { smartCardClient } from './smartCardClient';

/**
 * Specific CSS workaround to ensure ellipsis still works on elements
 * wrapped by smart card hover
 */
const HoverCardWrapper = styled.div({
  minWidth: 0,
  width: '100%',
});

const ShrinkWrapper = styled.div({
  minWidth: 0,
});

const NameWrapper = styled.div({
  display: 'flex',
});

type WrapperProps = {
  id: string;
  children: ReactElement;
  url: string;
  shrink?: boolean;
  useCustomUrl?: boolean;
};

export const WrappedWithHoverSmartCard = (props: WrapperProps) => {
  const [{ route }] = useRouter();
  const baseUrl = useSmartCardBaseUrl();

  const Outer = props.shrink ? ShrinkWrapper : HoverCardWrapper;

  return (
    <AnalyticsContext data={{ attributes: { location: route.name } }}>
      <Provider client={smartCardClient}>
        <Outer>
          <HoverCard
            url={props.useCustomUrl === true ? props.url : baseUrl + props.url}
            closeOnChildClick={false}
            canOpen={true}
            id={props.id}
          >
            <NameWrapper>{props.children}</NameWrapper>
          </HoverCard>
        </Outer>
      </Provider>
    </AnalyticsContext>
  );
};
