import React, { useState } from 'react';

import { getAvatarPlaceholderColor } from '@townsquare/theme';

import { PlaceholderPersonIcon } from './PlaceholderPersonIcon';
import { AvatarPlaceholder, UserImage } from './styles';

interface ProfilePictureProps {
  name: string;
  src?: string;
  height?: string;
  width?: string;
}

export const ProfilePicture = ({ name, src, height = '256px', width = '256px' }: ProfilePictureProps) => {
  const [hideBackground, setHideBackground] = useState(false);
  const [usePlaceholder, setUsePlaceholder] = useState(false);

  if (!src || usePlaceholder) {
    return (
      <AvatarPlaceholder placeholderColor={getAvatarPlaceholderColor(name)} height={height} width={width}>
        <PlaceholderPersonIcon />
      </AvatarPlaceholder>
    );
  }

  return (
    <UserImage
      src={src}
      height={height}
      width={width}
      hideBackground={hideBackground}
      onLoad={() => setHideBackground(true)}
      onError={() => setUsePlaceholder(true)}
    />
  );
};
