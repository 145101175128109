import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const FeedHeader = styled.div<{ marginBottom?: string }>(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.200'),
  alignItems: 'flex-start',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginBottom: props.marginBottom ?? token('space.300'),
  '& > section': {
    width: '100%',
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  font: token('font.heading.large'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const HeaderName = styled.div`
  ${ellipsis}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderNameWrapper = styled.div(
  {
    paddingLeft: token('space.150'),
    overflow: 'hidden',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: token('space.200'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  h5: {
    flexGrow: 1,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> span': {
    alignSelf: 'flex-end',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionTitle = styled.div({
  marginBottom: token('space.050'),
  font: token('font.body'),
  fontWeight: token('font.weight.semibold'),
  color: token('color.text'),
});

// Sidebar
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarContainer = styled.div({
  marginTop: token('space.500'),
});

// Empty state
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NoUpdatesEmptyStateContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  img: {
    width: '100%',
    height: 'auto',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NoUpdatesText = styled.p({
  color: token('color.text.subtlest'),
  marginBottom: token('space.150'),
});
