import { SmartMentionResource } from '@atlassian/smart-mention-resource';

import {
  ANALYTICS_PRODUCT_IDENTIFIER as SMARTS_PRODUCT_IDENTIFIER,
  PEOPLE_PRODUCT_IDENTIFIER,
} from '@townsquare/config/constants';
import { WorkspaceType } from '@townsquare/workspace-store/types';

import { ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER } from './constants';

let mentionProvider: SmartMentionResource;

export const initMentionProvider = ({
  cloudId,
  orgId,
  userId,
  workspaceType,
}: {
  cloudId: string;
  userId: string;
  orgId?: string;
  workspaceType: WorkspaceType;
}) => {
  mentionProvider = new SmartMentionResource({
    env: 'prod',
    principalId: 'Context',
    includeTeams: true,
    searchQueryFilter: ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER,
    siteId: cloudId,
    orgId,
    productKey: workspaceType === 'GLOBAL_EXPERIENCE' ? PEOPLE_PRODUCT_IDENTIFIER : SMARTS_PRODUCT_IDENTIFIER,
    shouldHighlightMention: mention => mention.id === userId,
  });
};

export const getOrInstantiateMentionProvider = ({
  cloudId,
  orgId,
  userId,
  workspaceType,
}: {
  cloudId: string;
  orgId?: string;
  userId: string;
  workspaceType: WorkspaceType;
}) => {
  if (!mentionProvider) {
    initMentionProvider({ cloudId, orgId, userId, workspaceType });
  }

  return mentionProvider;
};
