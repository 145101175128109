import { SpotlightTarget } from '@atlaskit/onboarding';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { dashboardRoute, dashboardDirectoryRoute } from '@townsquare/dashboards-view/routes';
import { filterUndefined } from '@townsquare/filter-type';
import {
  goalUpdateViewRoute,
  projectUpdateViewRoute,
  tagUUIDRedirectRoute,
  teamUpdatesViewRoute,
} from '@townsquare/home-view/routes';
import { homeTabRoute } from '@townsquare/hometab-view';
import { GoalButton } from '@townsquare/navigation-goals';
import { ProjectButton } from '@townsquare/navigation-projects';
import { TeamButton } from '@townsquare/navigation-teams';
import { useFeatureGate } from '@townsquare/stat-sig/gate';
import { tagsDirectoryRoute } from '@townsquare/tags-directory-view/route';
import { topicRoute } from '@townsquare/topic-view/route';
import { useNoWorkspaceExperience, useWorkspaceType } from '@townsquare/workspace-store/hooks';
import { yourWorkRoute } from '@townsquare/your-work-view/route';

import { ForgeAppsButton } from '../ForgeAppsButton';
import { NoWorkspaceNavItems } from '../NoWorkspace/NoWorkspaceNavigationItems';

import { NavItem } from './NavItem';

type PrimaryNavItemsProps = {
  triggers: {
    createTeamModalTrigger: () => void;
    inviteUserModalTrigger: () => void;
  };
  isHome: boolean;
};

export const usePrimaryNavItems = ({
  triggers: { createTeamModalTrigger, inviteUserModalTrigger },
  isHome,
}: PrimaryNavItemsProps) => {
  const noWorkspaceExperience = useNoWorkspaceExperience();
  const workspaceType = useWorkspaceType();
  const isDashboardsEnabled = useFeatureGate('atlas_enable_dashboard_route') && workspaceType === 'GLOBAL_EXPERIENCE';

  const workspaceRelatedItems = useMemo(
    () =>
      noWorkspaceExperience
        ? NoWorkspaceNavItems
        : [
            <ProjectButton />,
            <GoalButton />,
            <TeamButton
              createTeamModalTrigger={createTeamModalTrigger}
              inviteUserModalTrigger={inviteUserModalTrigger}
            />,
            <NavItem name="tags" to={tagsDirectoryRoute} matches={[topicRoute]}>
              <FormattedMessage
                id="townsquare.navigation.items.tags"
                defaultMessage="Tags"
                description="Button on navigation bar which takes you to tags directory when clicked"
              />
            </NavItem>,
            isDashboardsEnabled ? (
              <NavItem name="dashboards" to={dashboardDirectoryRoute} matches={[dashboardRoute]}>
                <FormattedMessage
                  id="townsquare.navigation.items.dashboards"
                  defaultMessage="Dashboards"
                  description="Button on navigation bar which takes you to dashboards page when clicked"
                />
              </NavItem>
            ) : undefined,
            <ForgeAppsButton />,
          ].filter(filterUndefined),
    [createTeamModalTrigger, inviteUserModalTrigger, isDashboardsEnabled, noWorkspaceExperience],
  );

  const alwaysVisibleItems = [
    <NavItem
      name="home"
      to={isHome ? homeTabRoute : yourWorkRoute}
      matches={[yourWorkRoute, projectUpdateViewRoute, goalUpdateViewRoute, tagUUIDRedirectRoute, teamUpdatesViewRoute]}
    >
      <SpotlightTarget name="see-feed">
        <div id="home-nav-button">
          <FormattedMessage
            id="uchi.nav.homeButton"
            defaultMessage="Home"
            description="Button on navigation bar which takes you to home page when clicked"
          />
        </div>
      </SpotlightTarget>
    </NavItem>,
  ];

  // Only one of workspace or noWorkspace items will be populated
  return [...alwaysVisibleItems, ...workspaceRelatedItems];
};
