import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { multilineEllipsis } from '@townsquare/ellipsis';
import { RESPONSIVE_CONTENT_WIDTH_THRESHOLD } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const NewDropdown = styled(PrimaryDropdownButton)`
  padding: 0px ${token('space.100')} !important;
  background-color: ${token('color.background.brand.bold')} !important;
  color: ${token('color.text.inverse')} !important;

  > span:first-of-type {
    margin-right: ${token('space.100')} !important;
  }

  &:hover {
    background-color: ${token('color.background.brand.bold.hovered')} !important;
  }

  &:active {
    background-color: ${token('color.background.brand.bold.pressed')} !important;
  }

  &:focus {
    background-color: ${token('color.background.brand.bold')} !important;
  }

  &[aria-expanded='true'] {
    background-color: ${token('color.background.selected')} !important;
    color: ${token('color.text.selected')} !important;

    &:hover {
      background-color: ${token('color.background.selected.hovered')} !important;
    }
    &:active {
      background-color: ${token('color.background.selected.pressed')} !important;
    }
  }

  @media (max-width: ${RESPONSIVE_CONTENT_WIDTH_THRESHOLD}px) {
    display: none !important;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const NewTruncatedButton = styled(Button)`
  display: none !important;

  @media (max-width: ${RESPONSIVE_CONTENT_WIDTH_THRESHOLD}px) {
    display: flex !important;
  }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NewButtonItemDescription = styled.p`
  ${multilineEllipsis(2)}
`;
