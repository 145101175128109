import { createRelayResource } from '@townsquare/relay-utils';

import ProductOverviewQuery, {
  ProductOverviewQuery as ProductOverviewQueryType,
} from '../New/__generated__/ProductOverviewQuery.graphql';

export const projectDialogProjectOverviewResource = createRelayResource<ProductOverviewQueryType>({
  getQuery() {
    return {
      query: ProductOverviewQuery,
    };
  },
});
