import { validate as isUuid } from 'uuid';

import { createRelayResource } from '@townsquare/relay-utils';

import TagUUIDRedirectQuery, {
  TagUUIDRedirectQuery as TagUUIDRedirectQueryType,
} from '../TagUpdatesView/__generated__/TagUUIDRedirectQuery.graphql';

export const tagUUIDRedirectResource = createRelayResource<TagUUIDRedirectQueryType>({
  getQuery: routerContext => {
    const { id: idIfPresent } = routerContext?.match?.params || {};
    const id: string = idIfPresent ?? '';

    return {
      query: TagUUIDRedirectQuery,
      variables: {
        id: id,
        isGlobalId: !isUuid(id),
      },
    };
  },
});
