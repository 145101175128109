import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ErrorContainer = styled.div<{ isFullPage: boolean; imageMaxWidthPx?: string }>(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: props.isFullPage ? 'calc(100vh - 120px)' : undefined,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  minHeight: props.isFullPage ? '400px' : undefined,
  width: '100%',
  textAlign: 'center',
  img: {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    maxWidth: props.imageMaxWidthPx ?? '164px',
    width: '100%',
  },
  p: {
    margin: `${token('space.050')} 0`,
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContainerRetryErrorWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContainerRetryErrorMessage = styled.p({
  margin: 0,
  color: token('color.text.subtle'),
  marginBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContainerRetryErrorImage = styled.img({
  width: '30%',
  maxWidth: '190px',
  marginBottom: token('space.250'),
});
