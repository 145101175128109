import { borderRadius } from '@atlaskit/theme';
import React from 'react';
import styled from 'styled-components';

interface AnimateOnHoverProps {
  animated: JSX.Element;
  still: JSX.Element;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Still = styled.div({
  position: 'absolute',
  display: 'flex',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  zIndex: 2,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${borderRadius() * 2}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  img: {
    flexShrink: 0,
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'cover',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Animated = styled.div({
  zIndex: 1,
  opacity: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const MediaWrapper = styled.div`
  position: relative;

  &:hover ${Still} {
    opacity: 0;
  }

  &:hover ${Animated} {
    opacity: 1;
  }
`;

// Renders a still version of the media and animates on hover
const AnimateOnHover = (props: AnimateOnHoverProps) => (
  <MediaWrapper>
    <Still>{props.still}</Still>
    <Animated>{props.animated}</Animated>
  </MediaWrapper>
);

export default AnimateOnHover;
