import LabelIcon from '@atlaskit/icon/core/migration/tag--label';
import { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import React from 'react';

import { Resolver } from '../types';

export const ResolverIcon = ({ resolver, ...glyphProps }: { resolver: Resolver } & Partial<GlyphProps>) => {
  if (resolver.icon) {
    return React.cloneElement(resolver.icon, { primaryColor: token('color.icon.subtle'), ...glyphProps });
  }
  return <LabelIcon color="currentColor" spacing="spacious" label={resolver.title} {...glyphProps} />;
};
