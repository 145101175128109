import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import GoalDirectoryQuery, {
  GoalDirectoryQuery as GoalDirectoryQueryType,
} from '../components/__generated__/GoalDirectoryQuery.graphql';

export const goalDirectoryResource = createRelayResource<GoalDirectoryQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;

    return {
      query: GoalDirectoryQuery,
      variables: {
        aaid: context.user.accountId,
        workspaceId: context.workspace?.globalId,
        workspaceUuid: context.workspace?.UUID ?? '',
      },
    };
  },
});
