/**
 * @generated SignedSource<<2acc66206a2082b0dbf569a8f440d3c6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SearchKudosScreen_data$data = {
  readonly kudosTql?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"KudosContent">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SearchKudosScreen_data";
};
export type SearchKudosScreen_data$key = {
  readonly " $data"?: SearchKudosScreen_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchKudosScreen_data">;
};

import SearchKudosScreenPaginationQuery_graphql from './SearchKudosScreenPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "kudosTql"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 12,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isSearchKudosScreen"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspaceId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": SearchKudosScreenPaginationQuery_graphql
    }
  },
  "name": "SearchKudosScreen_data",
  "selections": [
    {
      "condition": "isSearchKudosScreen",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "kudosTql",
          "args": [
            {
              "kind": "Variable",
              "name": "q",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "workspaceId",
              "variableName": "workspaceId"
            }
          ],
          "concreteType": "KudosConnection",
          "kind": "LinkedField",
          "name": "__SearchKudosScreen_kudosTql_connection",
          "plural": false,
          "selections": [
            {
              "concreteType": "KudosEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "Kudos",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "KudosContent"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "cursor"
                }
              ]
            },
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "endCursor"
                },
                {
                  "kind": "ScalarField",
                  "name": "hasNextPage"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "83f7040b196764c859dd75a9237775ce";

export default node;
