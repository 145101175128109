import AnalyticsWebClient, { envType, originType, tenantType, userType } from '@atlassiansox/analytics-web-client';

import { getConfig, getEnv } from '@townsquare/config';
import {
  ANALYTICS_PRODUCT_IDENTIFIER,
  PEOPLE_PRODUCT_IDENTIFIER,
  PRODUCT_IDENTIFIER,
} from '@townsquare/config/constants';

import { AnalyticsClient, SubproductAnalyticsAttribute } from './types';

const { LOCAL, DEV, STAGING, PROD } = envType;
const ENV_MAP: { [key: string]: string } = {
  ddev: DEV,
  local: LOCAL,
  'stg-east': STAGING,
  test: LOCAL,
};
const getEnvType = () => ENV_MAP[getEnv()] || PROD;

type ProductIdentifier =
  | `${typeof ANALYTICS_PRODUCT_IDENTIFIER}`
  | `${typeof PRODUCT_IDENTIFIER}`
  | `${typeof PEOPLE_PRODUCT_IDENTIFIER}`
  | 'jira';

const createAnalyticsWebClient = (subproduct = 'web', product: ProductIdentifier) =>
  new AnalyticsWebClient(
    {
      env: getEnvType(),
      origin: originType.WEB,
      product,
      subproduct,
    },
    {
      apiHost: getConfig().gasApiHost,
      // https://hello.atlassian.net/wiki/spaces/policy/pages/3303839825/Onboarding+a+Product+to+the+Cookies+Library+atlassian+browser-storage-controls#Upgrade-Analytics-Client-version-and-include-disableCookiePersistence-variable
      disableCookiePersistence: true,
    },
  );

export class AnalyticsClientImpl implements AnalyticsClient {
  private readonly analyticsWebClient: AnalyticsWebClient;
  private readonly readyPromise: Promise<void>;
  private hasTenantInfo = false;
  private resolveReadyPromise!: () => void;
  private topLevelAttributes: Record<string, any>;

  constructor(subproduct = 'web', product: ProductIdentifier = ANALYTICS_PRODUCT_IDENTIFIER) {
    this.analyticsWebClient = createAnalyticsWebClient(subproduct, product);
    this.readyPromise = new Promise(resolve => {
      this.resolveReadyPromise = resolve;
    });
    this.topLevelAttributes = {};
  }

  getAnalyticsWebClient() {
    return this.analyticsWebClient;
  }

  getTopLevelAttributes() {
    return this.topLevelAttributes;
  }

  setTopLevelAttributes(newAttributes = {}) {
    this.topLevelAttributes = {
      ...this.topLevelAttributes,
      ...newAttributes,
    };
  }

  setSubproduct(subproduct: SubproductAnalyticsAttribute) {
    this.analyticsWebClient.setSubproduct(subproduct);
  }

  async page(name: string, properties = {}) {
    await this.readyPromise;
    await this.analyticsWebClient.sendScreenEvent({
      attributes: {
        ...properties,
        ...this.topLevelAttributes,
      },
      name,
    });
  }

  /**
   * NOTE: at least one of accountId and cloudId must be provided in order for analytics
   * to be sent successfully.
   */
  setCurrentUserAndTenantInfo(
    accountId = 'anonymous',
    workspaceUuid = 'unable-to-resolve-workspace',
    cloudId?: string,
  ): void {
    if (cloudId) {
      this.analyticsWebClient.setTenantInfo(tenantType.CLOUD_ID, cloudId);
      this.hasTenantInfo = true;
    } else {
      this.analyticsWebClient.setTenantInfo(tenantType.NONE, cloudId);
    }
    this.setTopLevelAttributes({ workspaceUuid });
    this.analyticsWebClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, accountId);
    this.resolveReadyPromise();
  }

  getHasTenantInfo(): boolean {
    return this.hasTenantInfo;
  }

  /**
   *  Start up E-MAU events in the new analytics pipeline (GAS v3)
   *  See: https://hello.atlassian.net/wiki/spaces/MAU/pages/127745766/DACI+E-MAU+Definition
   */
  async startUIViewedEvent() {
    await this.readyPromise;
    this.analyticsWebClient.setUIViewedAttributes(this.topLevelAttributes);
    this.analyticsWebClient.startUIViewedEvent();
  }

  async track(subject: string, action: string, attributes = {}, actionSubjectId?: string) {
    await this.readyPromise;
    await this.analyticsWebClient.sendTrackEvent({
      action,
      actionSubject: subject,
      ...(actionSubjectId && { actionSubjectId }),
      attributes: {
        ...attributes,
        ...this.topLevelAttributes,
      },
      source: this.getDefaultSource(),
    });
  }

  async ui(subject: string, action: string, attributes = {}, actionSubjectId?: string, tags?: string[]) {
    await this.readyPromise;
    await this.analyticsWebClient.sendUIEvent({
      action,
      actionSubject: subject,
      ...(actionSubjectId && { actionSubjectId }),
      attributes: {
        ...attributes,
        ...this.topLevelAttributes,
      },
      tags,
      source: this.getDefaultSource(),
    });
  }

  async operational(subject: string, action: string, attributes = {}) {
    await this.readyPromise;
    await this.analyticsWebClient.sendOperationalEvent({
      action,
      actionSubject: subject,
      attributes: {
        ...attributes,
        ...this.topLevelAttributes,
      },
      source: this.getDefaultSource(),
    });
  }

  getDefaultSource(): string {
    return 'teamcentral';
  }
}
