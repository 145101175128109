import { createRelayResource } from '@townsquare/relay-utils';

import GoalEmbedQuery, {
  type GoalEmbedQuery as GoalEmbedQueryType,
} from '../components/Goal/__generated__/GoalEmbedQuery.graphql';

export const goalEmbedResource = createRelayResource<GoalEmbedQueryType>({
  getQuery: routerContext => {
    const tabName = routerContext.match.params.goalKey;
    const updateId = routerContext.query.updateId;

    return {
      query: GoalEmbedQuery,
      variables: {
        key: tabName || '',
        id: updateId || '',
        isSpecificUpdate: !!updateId,
      },
    };
  },
});
