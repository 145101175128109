import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator as CO } from '@townsquare/tql/types';
import { User } from '@townsquare/user-store';

import StatusUpdatesNavigationQuery, {
  type SavedTabs_savedQuery as StatusUpdatesNavigationQueryType,
} from '../StatusUpdatesNavigation/__generated__/SavedTabs_savedQuery.graphql';

const favoriteViewsTqlQuery = (user: User) =>
  tqlQueryFromInput({
    input: [{ fieldName: 'favouritedBy', fieldValue: user.accountId, comparator: CO.EQUALS }],
  });

export const statusUpdatesNavigationResource = createRelayResource<StatusUpdatesNavigationQueryType>({
  getQuery: (_, resourceContext) => {
    const { workspace, user } = resourceContext as ResourceContext;
    const savedViewTql = favoriteViewsTqlQuery(user);

    return {
      query: StatusUpdatesNavigationQuery,
      variables: {
        workspaceId: workspace?.globalId,
        workspaceUuid: workspace?.UUID ?? '',
        savedViewTql,
        cloudId: workspace?.cloudId,
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
    };
  },
});
