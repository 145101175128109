import Loadable from 'react-loadable';

export const ConfluenceUrlRedirect = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "ConfluenceUrlRedirect" */ './ConfluenceUrlRedirect'),
  loading: () => null,
});

export const JiraUrlRedirect = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "JiraUrlRedirect" */ './JiraUrlRedirect'),
  loading: () => null,
});

export const ManageAccessAdminHubRedirect = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "AdminHubRedirect" */ './ManageAccessAdminHubRedirect'),
  loading: () => null,
});
