import { Query } from 'react-resource-router';

import { location } from '@townsquare/facade';

/**
 * Creates a new URLSearchParams object from the current URL's search params.
 * Named in contrast to createSearchParamsFromScratch to avoid devs accidentally
 * stripping the current URL's search params when they don't mean to.
 *
 * For each param in newParams, either:
 * - Append new param
 * - Replace existing param
 * - Do nothing if the param is undefined or otherwise falsy
 *
 * Does not touch other existing params.
 *
 * @see createSearchParamsFromScratch
 */
export const createSearchParamsFromCurrentUrl = (
  newParams: Record<string, string | undefined> = {},
): URLSearchParams => {
  const searchParams = new URLSearchParams(location().search);

  Object.entries(newParams).forEach(([key, value]) => {
    if (!value) {
      if (searchParams.has(key)) {
        searchParams.delete(key);
      }
      return;
    } else if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  });

  return searchParams;
};

/**
 * Create a new URLSearchParams object from scratch, using the given params but
 * without seeding it with the current URL's search params.
 *
 * Drop-in replacement for new URLSearchParams(newParams), but explicitly named
 * in contrast to createSearchParamsFromCurrentUrl to avoid devs accidentally stripping
 * the current URL's search params when they don't mean to.
 *
 * @see createSearchParamsFromCurrentUrl
 */
export const createSearchParamsFromScratch = (newParams: Record<string, string> = {}): URLSearchParams => {
  const validParams: Record<string, string> = {};

  for (const key in newParams) {
    const value = newParams[key];
    if (value !== undefined && value !== '') {
      validParams[key] = value;
    }
  }
  return new URLSearchParams(validParams);
};

export const getQueryParam = (key: string) => {
  const url = location();
  const searchParams = new URLSearchParams(url.search);
  return searchParams.get(key);
};

export const newSearchString = (params: object) => {
  return appendToSearchString('', params);
};

export const appendToSearchString = (search: string, newParams: object) => {
  const searchParams = new URLSearchParams(search);
  Object.entries(newParams).forEach(([key, value]) => {
    if (key && value) {
      searchParams.append(key, value);
    }
  });
  return searchParams.toString();
};

export const appendSearchStringToPath = (path: string, search?: string) => (search ? `${path}?${search}` : path);

// Iterates over the newParams and replaces the value when finds the same in the query or appends otherwise.
export const searchWithParams = (searchParams: URLSearchParams, newParams: object) => {
  Object.entries(newParams).forEach(([key, value]) => {
    if (key && value) {
      if (searchParams.has(key)) {
        searchParams.set(key, value);
      } else {
        searchParams.append(key, value);
      }
    }
  });
  return searchParams.toString();
};

export const queryWithParams = (query: Query, newParams: object): string => {
  const searchParams = new URLSearchParams({ ...query });
  return searchWithParams(searchParams, newParams);
};

export const searchStringWithParams = (search: string, newParams: object): string => {
  const searchParams = new URLSearchParams(search);
  return searchWithParams(searchParams, newParams);
};
