import { initializeControls } from '@atlassian/browser-storage-controls';
import { useEffect } from 'react';

import { PRODUCT_IDENTIFIER } from '@townsquare/config/constants';

/**
 * https://hello.atlassian.net/wiki/spaces/policy/pages/3303839825/Onboarding+a+Product+to+the+Cookies+Library+atlassian+browser-storage-controls#Initialize-the-Library-Controls
 * Products are responsible for invoking the exported initializeControls function of browser-storage-controls on every page load.
 */
export const useBrowserStorageControls = () => {
  useEffect(() => {
    initializeControls({ product: PRODUCT_IDENTIFIER });
  }, []);

  return null;
};
