import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import GoalViewQuery, {
  type GoalViewQuery as GoalViewQueryType,
} from '../components/__generated__/GoalViewQuery.graphql';

export const goalViewResource = createRelayResource<GoalViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    return {
      query: GoalViewQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        teamTql: routerContext.match.params.id && `goal = ${routerContext.match.params.id}`,
        trackViewEvent: 'DIRECT',
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
    };
  },
});
