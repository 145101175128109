import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { hideOnMobile, showOnMobile } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MobileRestrictedLoadingState = styled.div(
  {
    display: 'none',
    width: '100%',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  showOnMobile(),
);

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DesktopRestrictedLoadingState = styled.div`
  ${hideOnMobile()}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const SpinnerWrapper = styled.div<{ size: 'small' | 'medium' }>(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  padding: props.size === 'medium' ? token('space.400') : token('space.100'),
}));
