/**
 * @generated SignedSource<<d7ead2bf1f42c726476937b3ed8292e0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Learning_data$data = {
  readonly __typename: string;
  readonly ari: string;
  readonly description: string | null;
  readonly goal: {
    readonly ari: string;
    readonly id: string;
    readonly key: string;
    readonly name: string;
    readonly state: {
      readonly label: string | null;
    } | null;
    readonly uuid: string;
    readonly workspace: {
      readonly uuid: string;
    };
  } | null;
  readonly id: string;
  readonly isRecentlyAdded: boolean | null;
  readonly project: {
    readonly ari: string;
    readonly id: string;
    readonly key: string;
    readonly name: string;
    readonly state: {
      readonly label: string | null;
    } | null;
    readonly uuid: string;
    readonly workspace: {
      readonly uuid: string;
    };
  } | null;
  readonly summary: string | null;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"LearningModifierSignatures_data">;
  readonly " $fragmentType": "Learning_data";
};
export type Learning_data$key = {
  readonly " $data"?: Learning_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Learning_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v2 = {
  "kind": "ScalarField",
  "name": "ari"
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
},
v5 = {
  "concreteType": "Workspace",
  "kind": "LinkedField",
  "name": "workspace",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ]
},
v6 = [
  {
    "kind": "ScalarField",
    "name": "label"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Learning_data",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "summary"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/),
        {
          "concreteType": "ProjectState",
          "kind": "LinkedField",
          "name": "state",
          "plural": false,
          "selections": (v6/*: any*/)
        }
      ]
    },
    {
      "concreteType": "Goal",
      "kind": "LinkedField",
      "name": "goal",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/),
        {
          "concreteType": "GoalState",
          "kind": "LinkedField",
          "name": "state",
          "plural": false,
          "selections": (v6/*: any*/)
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "LearningModifierSignatures_data"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isRecentlyAdded"
        }
      ]
    }
  ],
  "type": "Highlight",
  "abstractKey": "__isHighlight"
};
})();

(node as any).hash = "afdcdbcb65d12ed6a2cabeb73b82bcfe";

export default node;
