/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import Icon from '@atlaskit/icon';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

import { StyledIcon } from './styles';

export const DecisionGlyph = (props: CustomGlyphProps) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2929 0.29325C10.6839 -0.09775 11.3159 -0.09775 11.7069 0.29325C12.0979 0.68425 12.0979 1.31625 11.7069 1.70725L9.2069 4.20725C9.0119 4.40225 8.7559 4.50025 8.4999 4.50025C8.2439 4.50025 7.9879 4.40225 7.7929 4.20725C7.4019 3.81625 7.4019 3.18425 7.7929 2.79325L10.2929 0.29325ZM4.5 2.00025H3.414L6.707 5.29325C6.894 5.48025 7 5.73525 7 6.00025V11.0002C7 11.5522 6.552 12.0002 6 12.0002C5.448 12.0002 5 11.5522 5 11.0002V6.41425L2 3.41425V4.50025C2 5.05225 1.552 5.50025 1 5.50025C0.448 5.50025 0 5.05225 0 4.50025V1.00025C0 0.44825 0.448 0.000250007 1 0.000250007H4.5C5.052 0.000250007 5.5 0.44825 5.5 1.00025C5.5 1.55225 5.052 2.00025 4.5 2.00025Z"
      fill="#36B37E"
    />
  </svg>
);

export const DecisionIcon = (props: GlyphProps) => (
  <StyledIcon>
    <Icon glyph={DecisionGlyph} size="small" {...props} />
  </StyledIcon>
);
