import React from 'react';

import { OnlyMainColumnMaxWidth } from '@townsquare/home-layout/constants';
import { MainColumn, AsideColumn } from '@townsquare/responsive-grid';
import { SidebarSectionSkeleton } from '@townsquare/sidebar-section';

import { UpdateListSkeleton, FeedTitleSkeleton } from '../components/Skeletons';
import { SidebarContainer } from '../components/UpdatesFeeds/styles';

export const SavedViewUpdatesViewSkeleton = () => {
  return (
    <>
      <MainColumn maxWidthPx={OnlyMainColumnMaxWidth}>
        <FeedTitleSkeleton />
        <UpdateListSkeleton />
      </MainColumn>
      <AsideColumn>
        <SidebarContainer>
          <SidebarSectionSkeleton rows={3} />
        </SidebarContainer>
      </AsideColumn>
    </>
  );
};
