import Loadable from 'react-loadable';

import { ReactionsProps } from './type';

const Reactions = Loadable<ReactionsProps, ReactionsProps>({
  loader: () => import(/* webpackChunkName: "reactions" */ './Reactions'),
  loading: () => null,
});

export default Reactions;
