import { IntlShape } from 'react-intl-next';

import { ScreenType } from '../ScreenType';

export const getSearchPlaceholderText = (screen: ScreenType, intl: IntlShape) => {
  switch (screen) {
    case ScreenType.SEARCH_PEOPLE:
      return intl.formatMessage({
        id: 'townsquare.ui.directory.search-placeholder',
        description: 'Placeholder text for the search bar in the directory page.',
        defaultMessage: 'Search by name',
      });
    case ScreenType.SEARCH_TEAMS:
      return intl.formatMessage({
        id: 'townsquare.ui.directory.search-team-placeholder',
        description: 'Placeholder text for the search bar in the directory page.',
        defaultMessage: 'Search by team name',
      });
    default:
      return intl.formatMessage({
        id: 'townsquare.ui.directory.search-placeholder-people-and-teams',
        description: 'Placeholder text for the search bar in the directory page.',
        defaultMessage: 'Search for people and teams',
      });
  }
};
