import { ErrorMessage } from '@atlaskit/form';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { isEmptyDoc } from '@townsquare/adf';
import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { Context } from '@townsquare/context-identifier';
import { CopyLinkIcon } from '@townsquare/copy-link-icon';
import { filterUndefined } from '@townsquare/filter-type';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { projectRoute } from '@townsquare/project-view/route';
import { generatePath, useRouteDependencies } from '@townsquare/router/primitives';

import { EditLearningDescriptionEditor } from './LearningDescriptionEditor';
import { LearningModifierSignatures } from './LearningModifierSignatures';
import { LearningPanel } from './LearningPanel';
import { LearningProjectNameByLine } from './LearningProjectNameByLine';
import { LearningTitle } from './LearningTitle';
import { LearningTags_data$key } from './__generated__/LearningTags_data.graphql';
import { Learning_data$key } from './__generated__/Learning_data.graphql';
import { useErrors } from './hooks/useErrors';
import { updateDecision } from './mutations/UpdateDecisionMutation';
import { updateLearning } from './mutations/UpdateLearningMutation';
import { updateRisk } from './mutations/UpdateRiskMutation';
import { CopyLinkWrapper, LearningSummaryContainer, LearningSummaryWrapper, LearningWrapper } from './styles';
import { LearningType, formatExpandedEmptyState } from './util';

export interface LearningProps {
  // Core data
  data: Learning_data$key;
  watcherCount: number;
  tags: LearningTags_data$key | null;
  connectionId: string;
  // For analytics purposes
  // Customisation options
  canDelete?: boolean;
  canEdit: boolean;
  isInitiallyOpen?: boolean;
  showProjectNameAsByline?: boolean;
  learningType?: LearningType;
}

interface AnalyticProps {
  screen: string;
  type: string;
  entityType: string;
  projectPhase?: string;
  goalState?: string;
}

const highlightUpdateFunc: {
  [key in LearningType]: typeof updateLearning | typeof updateRisk | typeof updateDecision;
} = {
  ['LEARNING']: updateLearning,
  ['RISK']: updateRisk,
  ['DECISION']: updateDecision,
};

export const Learning = ({
  data,
  watcherCount,
  tags,
  canDelete,
  canEdit,
  isInitiallyOpen,
  connectionId,
  showProjectNameAsByline,
  learningType,
}: LearningProps) => {
  const intl = useIntl();
  const environment = useRelayEnvironment();
  const analytics = useAnalytics();
  const [routerState] = useRouter();
  const highlight = useFragment(
    graphql`
      fragment Learning_data on Highlight {
        __typename
        id
        summary
        description
        isRecentlyAdded
        uuid
        ari
        project {
          id
          key
          name
          uuid
          ari
          workspace {
            uuid
          }
          state {
            label
          }
        }
        goal {
          id
          key
          name
          uuid
          ari
          workspace {
            uuid
          }
          state {
            label
          }
        }
        ...LearningModifierSignatures_data
      }
    `,
    data,
  );

  const tagsData = useFragment(
    graphql`
      fragment LearningTags_data on TagConnection {
        edges {
          node {
            id
            name
            watcherCount
          }
        }
      }
    `,
    tags,
  );

  const project = highlight.project;
  const goal = highlight.goal;
  const entityType = project ? 'PROJECT' : 'GOAL';
  const entityId = entityType === 'GOAL' ? goal?.id : project?.id;
  const entityKey = entityType === 'GOAL' ? goal?.key : project?.key;
  const entityAri = entityType === 'PROJECT' ? project?.ari || '' : goal?.ari || '';
  const entityState = entityType === 'PROJECT' ? project?.state?.label || '' : goal?.state?.label || '';
  const objectUUID = entityType === 'PROJECT' ? project?.uuid || '' : goal?.uuid || '';
  const workspaceUUID = entityType === 'PROJECT' ? project?.workspace?.uuid || '' : goal?.workspace?.uuid || '';

  const highlightType: LearningType = learningType
    ? learningType
    : highlight.__typename === 'Risk'
    ? 'RISK'
    : highlight.__typename === 'Decision'
    ? 'DECISION'
    : 'LEARNING';

  const editorContext: Context = useMemo(
    () => ({
      objectUUID,
      workspaceUUID,
    }),
    [objectUUID, workspaceUUID],
  );

  const tagsTotalWatchers = tagsData?.edges?.reduce((acc, current) => acc + (current?.node?.watcherCount ?? 0), 0);
  const tagNames = tagsData?.edges?.map(edge => edge?.node?.name ?? undefined).filter(filterUndefined);

  const [isEditing, setEditing] = useState(false);
  const [expandOpen, setExpandOpen] = useState(isInitiallyOpen ?? highlight.isRecentlyAdded ?? false);
  const { errors, setErrors, resetErrors } = useErrors(highlightType);
  const [summary, setSummary] = useState(highlight.summary);

  const placeholder = formatExpandedEmptyState(
    intl,
    highlightType,
    entityType,
    watcherCount,
    tagsTotalWatchers,
    tagNames,
  );

  const setExpand = useCallback(
    (expandOpen: boolean, type?: LearningType | null) => {
      if (expandOpen) {
        void analytics.ui('learning', 'expanded', {
          screen: routerState.route.name,
          entityType,
          type: type ?? 'LEARNING',
        });
      }
      setExpandOpen(expandOpen);
    },
    [analytics, routerState.route.name, entityType],
  );

  const onLearningSave = useCallback(
    (adf: string) => {
      setErrors({
        summary: !summary?.trim(),
        description: isEmptyDoc(adf),
      });

      if (!summary?.trim() || isEmptyDoc(adf)) {
        return false;
      }

      setEditing(false);
      setExpand(true, highlightType);
      if (adf !== highlight.description || summary !== highlight.summary) {
        const analyticProps: AnalyticProps = {
          screen: `${routerState.route.name}Screen`,
          type: highlightType,
          entityType,
        };
        if (entityType === 'PROJECT') {
          analyticProps.projectPhase = entityState;
        } else {
          analyticProps.goalState = entityState;
        }

        const updateFunc = entityType === 'PROJECT' ? updateLearning : highlightUpdateFunc[highlightType];

        const input: {
          id: string;
          summary: string;
          description: string;
          type?: LearningType;
        } = {
          id: highlight.id,
          summary,
          description: adf,
          type: undefined,
        };

        if (highlightType === 'LEARNING' || entityType === 'PROJECT') {
          input.type = highlightType;
        }

        const highlightName = highlightType.toLowerCase();

        updateFunc(environment, input, {
          onCompleted: () => {
            void analytics.track(highlightName, 'updated', analyticProps);
          },
          onError: () => {
            void analytics.operational(highlightName, 'update-failed', analyticProps);
          },
        });
      }
    },
    [
      setErrors,
      summary,
      setExpand,
      highlight.description,
      highlight.summary,
      highlight.id,
      highlightType,
      environment,
      analytics,
      routerState.route.name,
      entityState,
      entityType,
    ],
  );

  const isExpanded = expandOpen || isEditing;

  const routeDependencies = useRouteDependencies();
  const linkEntityRoute = entityType === 'GOAL' ? goalViewRoute : projectRoute;
  let copyLinkPath = '';
  if (entityKey) {
    copyLinkPath = generatePath(linkEntityRoute, {
      baseParams: {
        id: entityKey,
        tabName: `${highlightType.toLowerCase()}s`,
        resourceId: highlight.uuid,
      },
      ...routeDependencies,
    });
  }
  return (
    <LearningWrapper expanded={expandOpen} data-testid={`${highlightType.toLowerCase()}-id-${highlight.uuid}`}>
      <LearningPanel
        key={`${highlightType.toLowerCase()}-panel-${highlight?.id}`}
        isOpen={expandOpen || isEditing}
        isEditing={isEditing}
        isInvalid={!!errors.description}
        onToggle={setExpand}
        learningType={highlightType}
        title={
          canEdit || canDelete ? (
            <LearningTitle
              entityType={entityType}
              entityId={entityId}
              entityKey={entityKey}
              entityAri={entityAri}
              learningAri={highlight.ari}
              learningUuid={highlight.uuid}
              connectionId={connectionId}
              entityState={entityState}
              learningId={highlight.id}
              title={
                highlight.summary ??
                intl.formatMessage({
                  id: 'townsquare.packages.learnings.learning-title',
                  defaultMessage: 'Learning title',
                  description: 'Fallback title for the learning',
                })
              }
              isEditing={isEditing}
              canEdit={canEdit}
              canDelete={canDelete}
              isInvalid={!!errors.summary}
              isExpanded={isExpanded}
              learningType={highlightType}
              onEdit={() => {
                setEditing(true);
                setExpand(true, highlightType);
              }}
              onChange={setSummary}
            />
          ) : (
            <LearningSummaryContainer>
              <LearningSummaryWrapper>
                {highlight.summary}
                {copyLinkPath && (
                  <CopyLinkWrapper>
                    <CopyLinkIcon link={`${getConfig().fullUrl}${copyLinkPath}`} entity={highlightType} />
                  </CopyLinkWrapper>
                )}
              </LearningSummaryWrapper>
              {showProjectNameAsByline && <LearningProjectNameByLine data={highlight} type="LEARNING" />}
            </LearningSummaryContainer>
          )
        }
      >
        {editorContext && (
          <EditLearningDescriptionEditor
            description={highlight?.description}
            editorContext={editorContext}
            canEditLearningsPage={canEdit}
            isEditing={isEditing}
            placeholder={placeholder}
            onCancel={() => {
              setEditing(false);
              setExpand(true, highlightType);
              resetErrors();
            }}
            onSave={onLearningSave}
          />
        )}
        {Object.values(errors).map(error => {
          if (!error) {
            return null;
          }
          return <ErrorMessage>{error}</ErrorMessage>;
        })}
        {!isEditing && <LearningModifierSignatures data={highlight} type={highlightType} />}
      </LearningPanel>
    </LearningWrapper>
  );
};
