import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KudosReactions = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: token('space.negative.150'),
  minHeight: '25px',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  'button:first-of-type': {
    background: token('color.icon.inverse'),
    '&:hover': {
      background: token('elevation.surface.hovered'),
    },
  },
});
