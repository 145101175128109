import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator as CO } from '@townsquare/tql/types';
import { User } from '@townsquare/user-store';

import HomeSidebarNavigationQuery, {
  type HomeSidebarNavigationQuery as HomeSidebarNavigationQueryType,
} from '../components/__generated__/HomeSidebarNavigationQuery.graphql';

const favoriteViewsTqlQuery = (user: User) =>
  tqlQueryFromInput({
    input: [{ fieldName: 'favouritedBy', fieldValue: user.accountId, comparator: CO.EQUALS }],
  });

export const homebarSideNavigationResource = createRelayResource<HomeSidebarNavigationQueryType>({
  getQuery: (_, resourceContext) => {
    const { workspace, user } = resourceContext as ResourceContext;
    const savedViewTql = favoriteViewsTqlQuery(user);

    return {
      query: HomeSidebarNavigationQuery,
      variables: {
        workspaceId: workspace?.globalId,
        workspaceUuid: workspace?.UUID ?? '',
        savedViewTql,
        cloudId: workspace?.cloudId,
        hasWorkspace: !!workspace,
      },
    };
  },
});
