import { useCallback } from 'react';
import { useQueryParam } from 'react-resource-router';

import { useDirectoryViewStoredTql } from '@townsquare/directory-view-store';

export function useViewTql() {
  const [directoryViewStoredTql] = useDirectoryViewStoredTql();
  const [qsTql, setQsTql] = useQueryParam('tql');

  const setTql = useCallback(
    (tql: string | undefined) => {
      const newTql = directoryViewStoredTql === tql ? undefined : tql;
      setQsTql(newTql);
    },
    [directoryViewStoredTql, setQsTql],
  );

  return [qsTql ?? directoryViewStoredTql, setTql] as const;
}
