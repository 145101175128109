import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

import { useSiteBanner } from '@townsquare/site-banner';

import { NavColumnInner } from './NavColumn';
import { AsideColumn, MainColumn, ResponsiveSection, responsiveGridSizeTimes } from './ResponsiveGrid';
import { RESPONSIVE_THRESHOLD, SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD } from './constants';

export function ResponsivePageWithSidebar(props: { customWidth?: number; children: React.ReactNode }) {
  const { height } = useSiteBanner();

  return <ResponsivePageWithSidebarInner {...props} topBannerHeight={height} />;
}

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const ResponsivePageWithSidebarInner = styled.div<{ customWidth?: number; topBannerHeight: number }>`
  ${ResponsiveSection} {
    justify-content: space-between;
  }

  ${MainColumn} {
    flex: 0 1 ${props => (props.customWidth ? props.customWidth : responsiveGridSizeTimes(11))}px;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - ${props => props.topBannerHeight}px);
    padding: 0 ${token('space.600')} ${token('space.600')};
  }

  ${AsideColumn} {
    background-color: ${token('elevation.surface.sunken')};
    min-height: calc(100vh - ${props => props.topBannerHeight}px);
    flex: 0 0 440px;
    padding: 0 ${token('space.600')} ${token('space.600')};
    box-sizing: border-box;
  }

  @media (max-width: ${SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD}px) {
    ${MainColumn} {
      padding: 0 28px 28px;
    }

    ${AsideColumn} {
      padding: 0 28px 28px;
      flex: 0 0 ${responsiveGridSizeTimes(4)}px;
    }
  }

  @media (max-width: ${RESPONSIVE_THRESHOLD}px) {
    ${MainColumn} {
      padding: 0 ${token('space.250')} ${token('space.250')};

      margin-left: 0;
      margin-right: 0;
      min-height: 0;
      flex: auto;
    }

    ${NavColumnInner} {
      box-sizing: border-box;
      min-height: unset;
      height: -webkit-fit-content;
      position: relative;
      top: 0;
    }

    ${AsideColumn} {
      padding: 0 ${token('space.250')} ${token('space.250')};
      min-height: 0;
      flex: auto;
    }
  }
`;
