import { Text } from '@atlaskit/primitives';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { HighlightIcon } from '@townsquare/icons';
import { LearningType } from '@townsquare/learnings';
import { WrappedWithHoverSmartCard } from '@townsquare/smart-card-provider';

import { projectRoute } from '../../../route';

import { RelatedLearning$key } from './__generated__/RelatedLearning.graphql';
import { Content, RelatedLearningContainer } from './styles';

type Props = {
  data: RelatedLearning$key;
  learningType: LearningType;
};

export const RelatedLearning = (props: Props) => {
  const analytics = useAnalytics();
  const relatedLearning = useFragment(
    graphql`
      fragment RelatedLearning on Learning {
        uuid
        summary
        project {
          id
          name
          key
        }
      }
    `,
    props.data,
  );

  const handleRelatedLearningClick = () => {
    void analytics.ui('relatedLearning', 'clicked');
  };
  const projectUrl = `/project/${relatedLearning.project?.key}`;

  return (
    <RelatedLearningContainer
      to={projectRoute}
      params={{
        id: relatedLearning.project?.key,
        tabName: `${props.learningType.toLowerCase()}s`,
        resourceId: relatedLearning.uuid,
      }}
      onClick={handleRelatedLearningClick}
    >
      <HighlightIcon type={props.learningType} />
      <Content>
        <EllipsisTooltip content={relatedLearning.summary}>
          <Text as="p" maxLines={3} color="color.text">
            {relatedLearning.summary}
          </Text>
        </EllipsisTooltip>
        <WrappedWithHoverSmartCard id={relatedLearning.project?.id || relatedLearning.uuid} url={projectUrl}>
          <Text as="p" size="small" color="color.text.subtlest" maxLines={1}>
            {relatedLearning.project?.name}
          </Text>
        </WrappedWithHoverSmartCard>
      </Content>
    </RelatedLearningContainer>
  );
};
