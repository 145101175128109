import { createActionRoute, createGlobalRoute } from '@townsquare/router/create-route';

import { SearchRedirectAction } from './loadable';

export const searchRedirectActionRoute = createGlobalRoute(
  createActionRoute({
    component: SearchRedirectAction,
    exact: true,
    name: 'searchAction',
    path: '/search/:entity',
  }),
);
