import { Grid, media, xcss } from '@atlaskit/primitives';
import React from 'react';

import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { BrowseUser, PersonBrowseCard } from '../Cards/Browse/Person';
import { CardRow } from '../styles';

import { BROWSE_PEOPLE_CARD_LIMIT, FLEX_GAP_PX } from './constants';

interface BrowseAllPeopleRowProps {
  users: BrowseUser[];
}

const cardRowStyles = xcss({
  gap: 'space.150',
  [media.above.md]: {
    gap: 'space.200',
  },
  gridTemplateColumns: `repeat(auto-fill, minmax(168px, 1fr))`,
});

export const BrowseAllPeopleRow = ({ users }: BrowseAllPeopleRowProps) => {
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  if (enableHomeVisualRefresh) {
    return (
      <Grid xcss={cardRowStyles}>
        {users.slice(0, BROWSE_PEOPLE_CARD_LIMIT).map(user => (
          <PersonBrowseCard user={user} key={user.aaid} />
        ))}
      </Grid>
    );
  }

  return (
    <CardRow desktopFlexGapPx={FLEX_GAP_PX}>
      {users.slice(0, BROWSE_PEOPLE_CARD_LIMIT).map(user => (
        <PersonBrowseCard user={user} key={user.aaid} />
      ))}
    </CardRow>
  );
};
