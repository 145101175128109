import { IntlShape } from 'react-intl-next';

import { messages } from './messages';
import { EntityType } from './types';

export const getCopyLinkIconText = (entityType: EntityType, intl: IntlShape) => {
  switch (entityType) {
    case 'PROJECT':
      return intl.formatMessage(messages.projectCopyText);
    case 'LEARNING':
      return intl.formatMessage(messages.learningCopyText);
    case 'RISK':
      return intl.formatMessage(messages.riskCopyText);
    case 'DECISION':
      return intl.formatMessage(messages.decisionCopyText);
  }
};
