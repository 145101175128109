import { useQueryParam } from 'react-resource-router';

import { useDirectoryViewSort } from '@townsquare/directory-view-store';
import type { Asc, Desc, SortTypes } from '@townsquare/sort-button';

import { resolveSorts, resolveRawSort, transformSortKeyToLegacyKey } from '../sort-util';

export function useSort<T extends SortTypes>(defaultSort: T[]): readonly [T[], (sort?: Asc<T> | Desc<T>) => void] {
  const [qsSort, setQsSort] = useQueryParam('sort');
  const [directoryViewSort] = useDirectoryViewSort();

  const setSort = (sort?: Asc<T> | Desc<T>) => {
    const resolvedSort = sort ? resolveSorts(sort) : undefined;
    setQsSort(
      directoryViewSort?.[0].toString() === resolvedSort?.[0].toString()
        ? undefined
        : resolvedSort?.map(transformSortKeyToLegacyKey).join(','),
    );
  };

  return [resolveRawSort(qsSort) ?? (directoryViewSort as unknown as T[]) ?? defaultSort, setSort] as const;
}
