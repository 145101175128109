import { localStorageFacade } from '@townsquare/facade';
import { getStringColorHash } from '@townsquare/theme';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { ScreenType } from '../components/ScreenType';
import { RecentlyViewedQuery$data } from '../components/SearchBar/SearchPreview/__generated__/RecentlyViewedQuery.graphql';
import { SearchQuery } from '../components/SearchBar/types';

import { RecentlySearchedItem, RecentlyViewedItem, ViewedItem } from './types';

const RECENTLY_VIEWED_KEY_PREFIX = 'recently-viewed';
const RECENTLY_SEARCHED_KEY_PREFIX = 'recently-searched';

const limit = (items: (ViewedItem | RecentlySearchedItem)[]) => items.slice(0, 4);

export const mapRecent = (items: ViewedItem[], data?: RecentlyViewedQuery$data): RecentlyViewedItem[] => {
  return items.reduce((acc, item) => {
    if (item.type === 'user') {
      const [user] = data?.userProfilesByAaids?.edges?.filter(edge => edge?.node?.accountId === item.entityId) ?? [];
      if (user && user.node?.accountId && user.node.name) {
        acc.push({
          type: 'user',
          id: item.entityId,
          data: {
            name: user.node.name,
            aaid: user.node.accountId,
            picture: user.node?.picture,
          },
        });
      }
    } else if (item.type === 'team') {
      const [team] = data?.teamsByTeamIds?.edges?.filter(edge => edge?.node?.teamId === item.entityId) ?? [];
      if (team && team.node?.name) {
        acc.push({
          type: 'team',
          id: item.entityId,
          data: {
            name: team.node.name,
            teamId: item.entityId,
            memberCount: team.node?.members?.count || 0,
          },
        });
      }
    }

    return acc;
  }, [] as RecentlyViewedItem[]);
};

export const useRecentlyViewed = () => {
  const [{ accountId }] = useUserStore();
  const [{ organisationId }] = useWorkspaceStore();
  const recentlyViewedKey = `${RECENTLY_VIEWED_KEY_PREFIX}-${accountId}-${organisationId}`;

  const getItems = (): ViewedItem[] => {
    const items = localStorageFacade.getItem(recentlyViewedKey);
    if (!items) {
      return [];
    }
    return JSON.parse(items);
  };

  const visit = (type: 'team' | 'user', entityId: string) => {
    const items = getItems();
    const idx = items.findIndex(i => i.entityId === entityId);

    if (idx !== -1) {
      items.splice(idx, 1);
    }

    const newItems = [{ entityId, type }, ...items];
    localStorageFacade.setItem(recentlyViewedKey, JSON.stringify(limit(newItems)));
  };

  return {
    viewed: getItems(),
    visit,
  };
};

export const useRecentlySearched = () => {
  const [{ accountId }] = useUserStore();
  const [{ organisationId }] = useWorkspaceStore();
  const recentlySearchedKey = `${RECENTLY_SEARCHED_KEY_PREFIX}-${accountId}-${organisationId}`;

  const getItems = (): RecentlySearchedItem[] => {
    const items = localStorageFacade.getItem(recentlySearchedKey);
    if (!items) {
      return [];
    }
    return (JSON.parse(items) as RecentlySearchedItem[]).filter(i => i.filterDoc.model.length > 0 || !!i.searchText);
  };

  const recordSearch = (sq: SearchQuery, screen: ScreenType) => {
    const items = getItems();
    const tql = tqlQueryFromInput({
      doc: sq.filterDoc,
      input: [
        {
          fieldName: 'name',
          fieldValue: sq.searchText || undefined,
          comparator: ComparatorOperator.LIKE,
        },
      ],
    });

    const id = getStringColorHash(`${tql}-${screen}`).toString();
    const idx = items.findIndex(i => i.id === id);

    if (idx !== -1) {
      items.splice(idx, 1);
    }

    const newItems: RecentlySearchedItem[] = [{ ...sq, id, screen, tql }, ...items];

    if (sq.searchText || sq.filterDoc.model.length > 0) {
      localStorageFacade.setItem(recentlySearchedKey, JSON.stringify(limit(newItems)));
    }
  };

  return {
    searched: getItems(),
    recordSearch,
  };
};
