import { useCallback, useEffect, useState } from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { useOnMount } from '@townsquare/hooks';
import { type User } from '@townsquare/user-store';
import { type WorkspaceDetails } from '@townsquare/workspace-store';

import { AtlasIntercomQuery } from './__generated__/AtlasIntercomQuery.graphql';
import { hideIntercom } from './utils';

const INTERCOM_ATLAS_WORKSPACE_APP_ID = 'vpok4ozp';

export const AtlasIntercom = (props: { user: User; workspace: WorkspaceDetails }) => {
  const analytics = useAnalytics();
  const [intercomDataLoaded, setIntercomDataLoaded] = useState(false);

  const environment = useRelayEnvironment();

  const { user, workspace } = props;

  const fetchIntercomData = useCallback(async () => {
    const intercomData = await fetchQuery<AtlasIntercomQuery>(
      environment,
      graphql`
        query AtlasIntercomQuery($aaidOrHash: String!, $workspaceId: ID!) {
          reportingLines(aaidOrHash: $aaidOrHash) {
            __typename
          }
          currentUser(workspaceId: $workspaceId) {
            slackTeams {
              __typename
            }
            msteamsTenants {
              __typename
            }
          }
          workspace(id: $workspaceId) {
            userWorkspacePermissions {
              admin
            }
          }
        }
      `,
      {
        aaidOrHash: user.accountId ?? '',
        workspaceId: workspace.globalId,
      },
      { fetchPolicy: 'store-or-network' },
    ).toPromise();

    window.Intercom('update', {
      site_admin: !!intercomData?.workspace?.userWorkspacePermissions?.admin,
      company: {
        company_id: workspace.UUID,
        name: workspace.name,
        website: workspace.cloudId,
        atlas_pricing_edition: workspace.edition,
        slack_app_installed: !!(
          intercomData?.currentUser?.slackTeams?.length && intercomData?.currentUser?.slackTeams?.length > 0
        ),
        ms_teams_app_installed: !!(
          intercomData?.currentUser?.msteamsTenants?.length && intercomData?.currentUser?.msteamsTenants.length > 0
        ),
        reporting_lines_enabled: !!intercomData?.reportingLines,
      },
    });
  }, [
    environment,
    user.accountId,
    workspace.globalId,
    workspace.name,
    workspace.cloudId,
    workspace.edition,
    workspace.UUID,
  ]);

  useOnMount(() => {
    const bootIntercom = async () => {
      try {
        if (window.Intercom) {
          // Intercom requires the userHash to be preloaded
          const userCapabilitiesPromise = await fetch(getConfig().userCapabilitiesUrl);
          const { userHash } = await userCapabilitiesPromise.json();

          // Prevents strange behaviour due to Intercom caching, no-ops if Intercom is not booted
          window.Intercom('shutdown');

          window.Intercom('boot', {
            app_id: INTERCOM_ATLAS_WORKSPACE_APP_ID,
            name: user.name,
            email: user.email,
            user_id: user.accountId,
            user_hash: userHash,
            company: {
              company_id: workspace.UUID,
              name: workspace.name,
              website: workspace.cloudId,
              atlas_pricing_edition: workspace.edition,
            },
          });

          hideIntercom();

          // Don't fetch extra info until Intercom is opened
          window.Intercom('onShow', async () => {
            if (!intercomDataLoaded) {
              await fetchIntercomData();
              setIntercomDataLoaded(true);
            }
          });
        }
        void analytics.operational('intercomBoot', 'succeeded');
      } catch (error) {
        void analytics.operational('intercomBoot', 'failed');
      }
    };

    bootIntercom();
  });

  useEffect(() => {
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, []);

  return null;
};
