import { PrimaryButton, PrimaryDropdownButton, useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { ButtonProps } from '@atlaskit/button';
import { CustomItem } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { PeopleAndTeamsConfigurationProvider } from '@atlassian/people-teams-configuration-client';
import React, { forwardRef, KeyboardEvent, MouseEvent, MutableRefObject, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import Loadable from 'react-loadable';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { ErrorState } from '@townsquare/error-state';
import { NavIconMarginFix } from '@townsquare/icons/margin-fix';
import { LoadingState } from '@townsquare/loading-state';
import {
  CapabilitySubject,
  FullPageViewErrorBoundary,
  ExperienceConfigPreset,
  useExperienceTracker,
} from '@townsquare/monitoring';
import { profileRoute, kudosProfileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';
import { Link, matchRoute, useGeneratePath } from '@townsquare/router/primitives';
import { staffDirectoryRoute } from '@townsquare/staff-directory-view/route';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import type { TeamMenuProps } from './TeamMenuPTC';
import TeamMenuWrapper from './TeamMenuWrapper';
import { IsHighlightedBar, TeamButtonsContainer } from './styles';
import type { onItemClickContextOptions } from './types';

const TeamMenuLoading = () => (
  <TeamMenuWrapper>
    <LoadingState />
  </TeamMenuWrapper>
);

const TeamMenuError = () => (
  <TeamMenuWrapper>
    <ErrorState isFullPage={false} />
  </TeamMenuWrapper>
);

const TeamMenuLazy = Loadable<TeamMenuProps, any>({
  loader: () => import(/* webpackChunkName: "TeamMenu" */ './TeamMenuPTC'),
  loading: TeamMenuLoading,
});

interface Props {
  createTeamModalTrigger: () => void;
  inviteUserModalTrigger: () => void;
}

export const TeamButton = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const analytics = useAnalytics();
  const [routerState] = useRouter();
  const { locale } = useIntl();
  const { isVisible: isHorizontalVisible, closeOverflowMenu } = useOverflowStatus();
  const [{ cloudId, type: workspaceType }] = useWorkspaceStore();
  const experience = useExperienceTracker(
    CapabilitySubject.PeopleMenuViewed,
    ExperienceConfigPreset.LoadPageSegmentLoad,
  );
  const path = useGeneratePath(staffDirectoryRoute, { search: 'search' });

  const { createTeamModalTrigger, inviteUserModalTrigger } = props;

  const onItemClick = useCallback(
    (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => {
      void analytics.ui('teamsNavMenuItem', 'clicked', {
        ctx,
      });

      if (!e?.metaKey) {
        setIsOpen(false);
        closeOverflowMenu();
      }

      if (ctx === 'invitePeopleToTeamCentral') {
        inviteUserModalTrigger();
      } else if (ctx === 'createTeam') {
        createTeamModalTrigger();
      }
    },
    [analytics, closeOverflowMenu, createTeamModalTrigger, inviteUserModalTrigger],
  );

  // TODO remove when org scoped
  // Only enabled when workspace is associated to a site
  if (!cloudId) {
    return null;
  }

  const onClose = () => {
    setIsOpen(false);
  };

  const onClick = () => {
    void analytics.ui('teamsNavButton', 'clicked');
    onClose();
  };

  const createOnDropdownClick =
    (preventBubbling = false) =>
    (e?: MouseEvent) => {
      if (e && preventBubbling) {
        e.stopPropagation();
        e.preventDefault();
      }

      void analytics.ui('teamsNavDropdown', 'clicked');
      setIsOpen(prevState => !prevState);
    };

  const RouterLink = forwardRef<HTMLAnchorElement | null, ButtonProps>(function Component({ href = '', ...rest }, ref) {
    return (
      <Link
        {...rest}
        key={href.substr(1)}
        prefetch="hover"
        to={staffDirectoryRoute}
        params={{ search: 'search' }}
        // @ts-expect-error - ref is not a public prop
        ref={ref}
      />
    );
  });

  const onDropdownClick = createOnDropdownClick();
  const onDropdownClickNoBubbling = createOnDropdownClick(true);

  const match = matchRoute(
    [profileRoute, staffDirectoryRoute, teamProfileRoute, kudosProfileRoute],
    routerState.location.pathname,
    undefined,
  );
  const isSelected = Boolean(match);
  const product = workspaceType === 'GLOBAL_EXPERIENCE' ? 'home' : 'atlas';

  return (
    <PeopleAndTeamsConfigurationProvider product={product} locale={locale}>
      <Popup
        placement="bottom-start"
        offset={[0, isHorizontalVisible ? -8 : 0]}
        content={() => (
          <FullPageViewErrorBoundary experience={experience} FallbackComponent={TeamMenuError}>
            <TeamMenuLazy experience={experience} onItemClick={onItemClick} />
          </FullPageViewErrorBoundary>
        )}
        isOpen={isOpen}
        onClose={onClose}
        trigger={({ ref, ...otherTriggerProps }) => {
          return isHorizontalVisible ? (
            <TeamButtonsContainer
              innerRef={ref as MutableRefObject<HTMLDivElement>}
              isOpen={isOpen}
              isSelected={isSelected}
              onMouseOver={() => TeamMenuLazy.preload()}
            >
              <PrimaryButton
                href={path}
                onClick={onClick}
                component={RouterLink}
                id="teams-button"
                testId="teams-button"
              >
                <FormattedMessage
                  id="townsquare.navigation-teams.team-button.primary"
                  description="Teams navigation primary button"
                  defaultMessage="Teams"
                />
              </PrimaryButton>
              <NavIconMarginFix>
                <PrimaryDropdownButton
                  {...otherTriggerProps}
                  id="teams-dropdown-button"
                  testId="teams-dropdown-button"
                  onClick={onDropdownClick}
                />
              </NavIconMarginFix>
              {isSelected && <IsHighlightedBar />}
            </TeamButtonsContainer>
          ) : (
            <CustomItem
              component={RouterLink}
              href={path}
              onClick={() => closeOverflowMenu()}
              iconAfter={
                <NavIconMarginFix>
                  <PrimaryDropdownButton
                    {...otherTriggerProps}
                    id="teams-dropdown-button"
                    testId="teams-dropdown-button"
                    onClick={onDropdownClickNoBubbling}
                    ref={ref}
                  />
                </NavIconMarginFix>
              }
              // eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
              cssFn={() => {
                return {
                  padding: `${token('space.050')} ${token('space.200')}`,
                };
              }}
            >
              <FormattedMessage
                id="townsquare.navigation-teams.team-button.custom"
                description="Teams navigation custom button"
                defaultMessage="Teams"
              />
            </CustomItem>
          );
        }}
        testId="teams-menu"
      />
    </PeopleAndTeamsConfigurationProvider>
  );
};
