import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionWrapper = styled.div({
  margin: `${token('space.050')} 0 ${token('space.600')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionTitle = styled.div({
  marginBottom: token('space.050'),
  font: token('font.heading.xsmall'),
  color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionHeader = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  marginBottom: token('space.150'),
  justifyContent: 'space-between',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  h5: {
    flexGrow: 1,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> span': {
    alignSelf: 'flex-end',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptySidebarSectionContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: token('space.100'),
  justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptySidebarSectionInnerContainer = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  flex: '1 1 0%',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  p: {
    marginLeft: token('space.150'),
    marginBottom: '0px',
  },
  '&:not(:last-child)': {
    marginRight: token('space.100'),
  },
});
