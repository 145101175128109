import Icon from '@atlaskit/icon/base';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function PersonGlyph(props: CustomGlyphProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M13.5 11C15.821 11 17.7448 12.7615 17.9776 15.0636L17.9945 15.281L18 15.5V18C18 18.9644 17.3144 19.7798 16.3393 19.9664L16.1742 19.9916L16 20H8C7.03563 20 6.22018 19.3145 6.03358 18.3394L6.00839 18.1742L6 18V15.5C6 13.179 7.76154 11.2552 10.0636 11.0223L10.281 11.0055L10.5 11H13.5ZM13.5 13L10.527 12.9996L10.362 13.0037C9.09605 13.0721 8.08724 14.081 8.00537 15.3351L8 15.5L7.99834 17.9424L8 18L15.9424 18.0017L16 18L16.0004 15.527L15.9963 15.3619C15.9278 14.096 14.919 13.0872 13.6649 13.0054L13.5 13ZM12 3C13.933 3 15.5 4.567 15.5 6.5C15.5 8.433 13.933 10 12 10C10.067 10 8.5 8.433 8.5 6.5C8.5 4.567 10.067 3 12 3ZM12 5C11.1716 5 10.5 5.67157 10.5 6.5C10.5 7.32843 11.1716 8 12 8C12.8284 8 13.5 7.32843 13.5 6.5C13.5 5.67157 12.8284 5 12 5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PersonIcon(props: GlyphProps) {
  return <Icon glyph={PersonGlyph} {...props} />;
}
