import Loadable from 'react-loadable';

import { APPS_PATH, HOME_PATH } from '@townsquare/config/routes';
import { WAC_CROSSFLOW_PARAMS } from '@townsquare/crossflow';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';

export const homeTabRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "hometab-view" */ './components/HomeTabView'),
    loading: () => null,
  }),
  exact: true,
  name: 'hometab',
  path: HOME_PATH,
  layout: 'home',
  resources: [titanEapCrossFlowQueryResource],
  query: WAC_CROSSFLOW_PARAMS.map(param => `${param}?`),
});

export const appsRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "apps-view" */ './components/AppsView'),
    loading: () => null,
  }),
  exact: true,
  name: 'apps',
  path: APPS_PATH,
  layout: 'home',
  resources: [],
});
