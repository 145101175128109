import { token } from '@atlaskit/tokens';

import { getStringColorHash } from './string-hash';
import { AvatarPlaceholderColor } from './types';

export const AVATAR_PLACEHOLDER_COLORS: AvatarPlaceholderColor[] = [
  // Purple
  {
    icon: token('color.icon.accent.purple'),
    background: token('color.background.accent.purple.subtler'),
  },
  // Teal
  {
    icon: token('color.icon.accent.teal'),
    background: token('color.background.accent.teal.subtler'),
  },
  // Green
  {
    icon: token('color.icon.accent.green'),
    background: token('color.background.accent.green.subtler'),
  },
  // Orange
  {
    icon: token('color.icon.accent.orange'),
    background: token('color.background.accent.orange.subtler'),
  },
  // Yellow
  {
    icon: token('color.icon.accent.yellow'),
    background: token('color.background.accent.yellow.subtler'),
  },
  // Red
  {
    icon: token('color.icon.accent.red'),
    background: token('color.background.accent.red.subtler'),
  },
  // Magenta
  {
    icon: token('color.icon.accent.magenta'),
    background: token('color.background.accent.magenta.subtler'),
  },
  // Blue
  {
    icon: token('color.icon.accent.blue'),
    background: token('color.background.accent.blue.subtler'),
  },
];

export const getAvatarPlaceholderColor = (name: string): AvatarPlaceholderColor =>
  AVATAR_PLACEHOLDER_COLORS[Math.abs(getStringColorHash(name)) % AVATAR_PLACEHOLDER_COLORS.length];
