import { addWeeks, startOfDay, subDays } from 'date-fns';

import {
  formatIsoLocalDate,
  formatIsoLocalDateTime,
  getUtcEndOfWeekDate,
  parseProjectUpdatesFeedStartDate,
  tzDate,
} from '@townsquare/date-utils';
import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator, FilterDoc, Operators, SupportedFiltersTypes } from '@townsquare/tql/types';

import ProjectUpdatesViewQuery, {
  type ProjectUpdatesViewQuery as ProjectUpdatesViewQueryType,
} from '../ProjectUpdatesView/__generated__/ProjectUpdatesViewQuery.graphql';
import { getUpdateStateFilterFromQueryParams } from '../components/UpdateStateFilter';

export const projectUpdatesResource = createRelayResource<ProjectUpdatesViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;

    const utcStartDate = parseProjectUpdatesFeedStartDate(routerContext.query.start);
    const utcPrevDate = addWeeks(utcStartDate, -1);
    const utcEndDate = getUtcEndOfWeekDate(utcStartDate);

    const sidebarStartDate = formatIsoLocalDate(subDays(startOfDay(tzDate()), 7));
    const sidebarEndDate = formatIsoLocalDate(tzDate());

    const doc: FilterDoc = {
      model: [
        {
          model: ['pending', 'in_progress', 'paused'],
          comparator: ComparatorOperator.EQUALS,
          operator: Operators.OR,
          type: SupportedFiltersTypes.PROJECT_STATUS,
        },
      ],
      operator: Operators.AND,
    };

    const ownProjectsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'owner', fieldValue: context.user.accountId, comparator: ComparatorOperator.EQUALS },
        { join: Operators.OR },
        {
          fieldName: 'contributor',
          fieldValue: context.user.accountId,
          comparator: ComparatorOperator.EQUALS,
        },
        { join: Operators.AND },
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      ],
      doc,
    });

    const newProjectsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'creationDate', fieldValue: sidebarStartDate, comparator: ComparatorOperator.GTE },
      ],
    });

    const completedProjectsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'phase', fieldValue: 'done', comparator: ComparatorOperator.EQUALS },
        { fieldName: 'targetDate', fieldValue: sidebarStartDate, comparator: ComparatorOperator.GTE },
        { fieldName: 'targetDate', fieldValue: sidebarEndDate, comparator: ComparatorOperator.LTE },
      ],
    });

    return {
      query: ProjectUpdatesViewQuery,
      variables: {
        workspaceId: context.workspace?.globalId,
        workspaceUUID: context.workspace?.UUID || '',
        prevDate: formatIsoLocalDateTime(utcPrevDate),
        startDate: formatIsoLocalDateTime(utcStartDate),
        endDate: formatIsoLocalDateTime(utcEndDate),
        stateFilter: getUpdateStateFilterFromQueryParams(routerContext.query.filter),
        ownProjectsTqlQuery: ownProjectsTqlQuery,
        newProjectsTqlQuery: newProjectsTqlQuery,
        completedProjectsTqlQuery: completedProjectsTqlQuery,
        isNavRefreshEnabled: isNavRefreshEnabled(context),
      },
      cacheKey: 'projectUpdates',
      options: {
        fetchPolicy: 'store-and-network' as const,
      },
    };
  },
});
