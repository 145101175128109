import enUS from 'date-fns/locale/en-US';

import { browserLocales } from '@townsquare/facade';

export const defaultDateLocale = enUS;

export const DEFAULT_LOCALE = 'en';
export const INSTRUMENTED_LOCALE = 'en-ZZ';
export const PSEUDO_LOCALE_XA = 'en-XA';
export const PSEUDO_LOCALE_XB = 'en-XB';

import compiledEnMessages from '../compiled-lang/en.json';
export const defaultMessages = compiledEnMessages;

export type IntlMessages = typeof defaultMessages;

function getFormattedLocale(locale?: string): string | null {
  if (locale) {
    return locale.replace('_', '-');
  }

  return null;
}

// Returns either a locale code ('en-US') or two letter language code ('en')
export function determineLocale(userLocale?: string): string {
  for (const locale of [userLocale, ...browserLocales()]) {
    const formattedLocale = getFormattedLocale(locale);
    if (formattedLocale) {
      return formattedLocale;
    }
  }

  // Default to English
  return DEFAULT_LOCALE;
}

export async function loadSmartCardMessages(locale: string): Promise<IntlMessages> {
  try {
    switch (locale) {
      case INSTRUMENTED_LOCALE:
        return (await import('@atlaskit/smart-card/dist/esm/i18n/en_ZZ.js'))
          .default as unknown as Promise<IntlMessages>;
      case 'cs':
      case 'cs-CZ':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/cs.js')).default as unknown as Promise<IntlMessages>;
      case 'da':
      case 'da-DK':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/da.js')).default as unknown as Promise<IntlMessages>;
      case 'de':
      case 'de-DE':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/de.js')).default as unknown as Promise<IntlMessages>;
      case 'en-GB':
      case 'en-AU':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/en_GB.js'))
          .default as unknown as Promise<IntlMessages>;
      case 'en':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/en.js')).default as unknown as Promise<IntlMessages>;
      case 'es':
      case 'es-ES':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/es.js')).default as unknown as Promise<IntlMessages>;
      case 'et':
      case 'et-EE':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/et.js')).default as unknown as Promise<IntlMessages>;
      case 'fi':
      case 'fi-FI':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/fi.js')).default as unknown as Promise<IntlMessages>;
      case 'fr':
      case 'fr-FR':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/fr.js')).default as unknown as Promise<IntlMessages>;
      case 'hu':
      case 'hu-HU':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/hu.js')).default as unknown as Promise<IntlMessages>;
      case 'is':
      case 'is-IS':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/is.js')).default as unknown as Promise<IntlMessages>;
      case 'it':
      case 'it-IT':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/it.js')).default as unknown as Promise<IntlMessages>;
      case 'ja':
      case 'ja-JP':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/ja.js')).default as unknown as Promise<IntlMessages>;
      case 'ko':
      case 'ko-KR':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/ko.js')).default as unknown as Promise<IntlMessages>;
      case 'nb':
      case 'nb-NO':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/nb.js')).default as unknown as Promise<IntlMessages>;
      case 'nl':
      case 'nl-NL':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/nl.js')).default as unknown as Promise<IntlMessages>;
      case 'pl':
      case 'pl-PL':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/pl.js')).default as unknown as Promise<IntlMessages>;
      case 'pt':
      case 'pt-BR':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/pt_BR.js'))
          .default as unknown as Promise<IntlMessages>;
      case 'pt-PT':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/pt_PT.js'))
          .default as unknown as Promise<IntlMessages>;
      case 'ro':
      case 'ro-RO':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/ro.js')).default as unknown as Promise<IntlMessages>;
      case 'ru':
      case 'ru-RU':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/ru.js')).default as unknown as Promise<IntlMessages>;
      case 'sk':
      case 'sk-SK':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/sk.js')).default as unknown as Promise<IntlMessages>;
      case 'sv':
      case 'sv-SE':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/sv.js')).default as unknown as Promise<IntlMessages>;
      case 'th':
      case 'th-TH':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/th.js')).default as unknown as Promise<IntlMessages>;
      case 'tr':
      case 'tr-TR':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/tr.js')).default as unknown as Promise<IntlMessages>;
      case 'uk':
      case 'uk-UA':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/uk.js')).default as unknown as Promise<IntlMessages>;
      case 'vi':
      case 'vi-VN':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/vi.js')).default as unknown as Promise<IntlMessages>;
      case 'zh':
      case 'zh-CN':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/zh.js')).default as unknown as Promise<IntlMessages>;
      case 'zh-TW':
        return (await import('@atlaskit/smart-card/dist/esm/i18n/zh_TW.js'))
          .default as unknown as Promise<IntlMessages>;
      default:
        return (await import('@atlaskit/smart-card/dist/esm/i18n/en.js')).default as unknown as Promise<IntlMessages>;
    }
  } catch (e) {
    return (await import(`@atlaskit/smart-card/dist/esm/i18n/en.js`)).default as unknown as Promise<IntlMessages>;
  }
}

/**
 * TODO: Once we begin to turn on languages we need to decide if we want to seed useState<IntlMessages>(defaultMessages)
 * with the default language or leave it empty
 *
 * @param locale
 * @param availableLocalesEnabled
 */
export async function loadLocaleData(locale: string): Promise<IntlMessages> {
  switch (locale) {
    case 'cs':
    case 'cs-CZ':
      return (await import('../compiled-lang/cs_CZ.json')).default as unknown as Promise<IntlMessages>;
    case 'da':
    case 'da-DK':
      return (await import('../compiled-lang/da_DK.json')).default as unknown as Promise<IntlMessages>;
    case 'de':
    case 'de-DE':
      return (await import('../compiled-lang/de_DE.json')).default as unknown as Promise<IntlMessages>;
    case INSTRUMENTED_LOCALE:
      return (await import('../compiled-lang/en_ZZ.json')).default as unknown as Promise<IntlMessages>;
    case PSEUDO_LOCALE_XA:
      return (await import('../compiled-lang/en_XA.json')).default as unknown as Promise<IntlMessages>;
    case PSEUDO_LOCALE_XB:
      return (await import('../compiled-lang/en_XB.json')).default as unknown as Promise<IntlMessages>;
    case 'en-GB':
    case 'en-AU':
      return (await import('../compiled-lang/en_GB.json')).default as unknown as Promise<IntlMessages>;
    case 'en-US':
      return compiledEnMessages;
    case 'es':
    case 'es-ES':
      return (await import('../compiled-lang/es_ES.json')).default as unknown as Promise<IntlMessages>;
    case 'fi':
    case 'fi-FI':
      return (await import('../compiled-lang/fi_FI.json')).default as unknown as Promise<IntlMessages>;
    case 'fr':
    case 'fr-FR':
      return (await import('../compiled-lang/fr_FR.json')).default as unknown as Promise<IntlMessages>;
    case 'hu':
    case 'hu-HU':
      return (await import('../compiled-lang/hu_HU.json')).default as unknown as Promise<IntlMessages>;
    case 'it':
    case 'it-IT':
      return (await import('../compiled-lang/it_IT.json')).default as unknown as Promise<IntlMessages>;
    case 'ja':
    case 'ja-JP':
      return (await import('../compiled-lang/ja_JP.json')).default as unknown as Promise<IntlMessages>;
    case 'ko':
    case 'ko-KR':
      return (await import('../compiled-lang/ko_KR.json')).default as unknown as Promise<IntlMessages>;
    case 'nb':
    case 'nb-NO':
      return (await import('../compiled-lang/nb_NO.json')).default as unknown as Promise<IntlMessages>;
    case 'nl':
    case 'nl-NL':
      return (await import('../compiled-lang/nl_NL.json')).default as unknown as Promise<IntlMessages>;
    case 'pl':
    case 'pl-PL':
      return (await import('../compiled-lang/pl_PL.json')).default as unknown as Promise<IntlMessages>;
    case 'pt':
    case 'pt-BR':
    case 'pt-PT':
      return (await import('../compiled-lang/pt_BR.json')).default as unknown as Promise<IntlMessages>;
    case 'ru':
    case 'ru-RU':
      return (await import('../compiled-lang/ru_RU.json')).default as unknown as Promise<IntlMessages>;
    case 'sv':
    case 'sv-SE':
      return (await import('../compiled-lang/sv_SE.json')).default as unknown as Promise<IntlMessages>;
    case 'th':
    case 'th-TH':
      return (await import('../compiled-lang/th_TH.json')).default as unknown as Promise<IntlMessages>;
    case 'tr':
    case 'tr-TR':
      return (await import('../compiled-lang/tr_TR.json')).default as unknown as Promise<IntlMessages>;
    case 'uk':
    case 'uk-UA':
      return (await import('../compiled-lang/uk_UA.json')).default as unknown as Promise<IntlMessages>;
    case 'vi':
    case 'vi-VN':
      return (await import('../compiled-lang/vi_VN.json')).default as unknown as Promise<IntlMessages>;
    case 'zh':
    case 'zh-CN':
      return (await import('../compiled-lang/zh_CN.json')).default as unknown as Promise<IntlMessages>;
    case 'zh-TW':
      return (await import('../compiled-lang/zh_TW.json')).default as unknown as Promise<IntlMessages>;

    default:
      return compiledEnMessages;
  }
}

/**
 * Maps the Intl locale to the date-fns locale
 *
 * @param locale
 */
export async function getDateFnsLocaleFromIntlLocale(locale: string): Promise<Locale> {
  switch (locale) {
    case 'cs':
    case 'cs-CZ':
      return (await import('date-fns/locale/cs')).default;
    case 'da':
    case 'da-DK':
      return (await import('date-fns/locale/da')).default;
    case 'de':
    case 'de-DE':
      return (await import('date-fns/locale/de')).default;
    case 'en-AU':
      return (await import('date-fns/locale/en-AU')).default;
    case 'en-GB':
      return (await import('date-fns/locale/en-GB')).default;
    case 'en-US':
      return defaultDateLocale;
    case 'es':
    case 'es-ES':
      return (await import('date-fns/locale/es')).default;
    case 'fi':
    case 'fi-FI':
      return (await import('date-fns/locale/fi')).default;
    case 'fr':
    case 'fr-FR':
      return (await import('date-fns/locale/fr')).default;
    case 'hu':
    case 'hu-HU':
      return (await import('date-fns/locale/hu')).default;
    case 'it':
    case 'it-IT':
      return (await import('date-fns/locale/it')).default;
    case 'ja':
    case 'ja-JP':
      return (await import('date-fns/locale/ja')).default;
    case 'ko':
    case 'ko-KR':
      return (await import('date-fns/locale/ko')).default;
    case 'nb':
    case 'nb-NO':
      return (await import('date-fns/locale/nb')).default;
    case 'nl':
    case 'nl-NL':
      return (await import('date-fns/locale/nl')).default;
    case 'pl':
    case 'pl-PL':
      return (await import('date-fns/locale/pl')).default;
    case 'pt':
    case 'pt-BR':
    case 'pt-PT':
      return (await import('date-fns/locale/pt')).default;
    case 'ru':
    case 'ru-RU':
      return (await import('date-fns/locale/ru')).default;
    case 'sv':
    case 'sv-SE':
      return (await import('date-fns/locale/sv')).default;
    case 'th':
    case 'th-TH':
      return (await import('date-fns/locale/th')).default;
    case 'tr':
    case 'tr-TR':
      return (await import('date-fns/locale/tr')).default;
    case 'uk':
    case 'uk-UA':
      return (await import('date-fns/locale/uk')).default;
    case 'vi':
    case 'vi-VN':
      return (await import('date-fns/locale/vi')).default;
    case 'zh':
    case 'zh-CN':
      return (await import('date-fns/locale/zh-CN')).default;
    case 'zh-TW':
      return (await import('date-fns/locale/zh-TW')).default;

    default:
      return defaultDateLocale;
  }
}
