/**
 * @generated SignedSource<<653776c597e73ff832deb65cad6916b6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BrowseJobTitleSection$data = {
  readonly jobTitle: {
    readonly " $fragmentSpreads": FragmentRefs<"BrowsePeopleFieldSection">;
  } | null;
  readonly " $fragmentType": "BrowseJobTitleSection";
};
export type BrowseJobTitleSection$key = {
  readonly " $data"?: BrowseJobTitleSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowseJobTitleSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspaceId"
    }
  ],
  "kind": "Fragment",
  "name": "BrowseJobTitleSection",
  "selections": [
    {
      "alias": "jobTitle",
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Literal",
          "name": "peopleFieldType",
          "value": "JOB_TITLE"
        },
        {
          "kind": "Variable",
          "name": "workspaceId",
          "variableName": "workspaceId"
        }
      ],
      "concreteType": "PeopleFieldAggregateResponse",
      "kind": "LinkedField",
      "name": "peopleFieldAggregates",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "BrowsePeopleFieldSection"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "e2a95a5ae5034498d5bd6f1cfde6f10d";

export default node;
