/**
 * @generated SignedSource<<61424915695ebe0a899bc9fb2d5958a2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type FollowGoalActionQuery$variables = {
  key: string;
};
export type FollowGoalActionQuery$data = {
  readonly goal: {
    readonly id: string;
    readonly key: string;
    readonly watching: boolean;
  } | null;
};
export type FollowGoalActionQuery = {
  response: FollowGoalActionQuery$data;
  variables: FollowGoalActionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": "goal",
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "concreteType": "Goal",
    "kind": "LinkedField",
    "name": "goalByKey",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "id"
      },
      {
        "kind": "ScalarField",
        "name": "key"
      },
      {
        "kind": "ScalarField",
        "name": "watching"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "FollowGoalActionQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FollowGoalActionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2401fbfe7313006dd793930886179eb",
    "id": null,
    "metadata": {},
    "name": "FollowGoalActionQuery",
    "operationKind": "query",
    "text": "query FollowGoalActionQuery(\n  $key: String!\n) {\n  goal: goalByKey(key: $key) {\n    id\n    key\n    watching\n  }\n}\n"
  }
};
})();

(node as any).hash = "de24f28b0d8f55332de6d893e9ab475d";

export default node;
