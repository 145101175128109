import { ProductHome } from '@atlaskit/atlassian-navigation';
import { AtlassianIcon, AtlassianLogo } from '@atlaskit/logo';
import React from 'react';
import { useIntl } from 'react-intl-next';

import { TeamCentralLogo } from '@townsquare/logo';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { useNoWorkspaceExperience } from '@townsquare/workspace-store';
import { yourWorkRoute } from '@townsquare/your-work-view/route';

import AtlasLogo from './images/atlas-logo.svg';

export const Home = () => {
  const noWorkspaceExperience = useNoWorkspaceExperience();
  const { formatMessage } = useIntl();
  const { handler, path } = useMetaClickHandler(yourWorkRoute);
  return (
    <ProductHome
      href={path}
      icon={() =>
        noWorkspaceExperience ? (
          <AtlassianIcon appearance="brand" />
        ) : (
          <img
            alt={formatMessage({
              id: 'townsquare.navigation.home.image-alt',
              description: 'Alt text for Atlassian icon in Home navigation',
              defaultMessage: 'Atlassian icon',
            })}
            src={AtlasLogo}
          />
        )
      }
      logo={() => (noWorkspaceExperience ? <AtlassianLogo appearance="brand" /> : <TeamCentralLogo size="medium" />)}
      onClick={handler}
    />
  );
};
