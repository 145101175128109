import Icon from '@atlaskit/icon/base';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import { borderRadius } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import React from 'react';

function WarningGlyph(props: CustomGlyphProps) {
  return (
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    <svg width="24" height="24" viewBox="0 0 24 24" {...props} style={{ borderRadius: `${borderRadius()}px` }}>
      <rect width="24" height="24" fill={token('color.background.accent.gray.subtler')} />
      <path
        d="M12.1017 3C12.9601 3 13.7538 3.43989 14.2103 4.15692L14.3029 4.31475L20.348 15.5415C20.8347 16.4452 20.8511 17.5292 20.3921 18.4472C19.9442 19.3429 19.0563 19.9281 18.0663 19.9938L17.8796 20H6.11422C5.05028 20 4.07775 19.3986 3.6024 18.4468C3.17451 17.59 3.1605 16.5889 3.55604 15.7247L3.64707 15.5417L9.69209 4.31479C10.0991 3.55898 10.8627 3.06869 11.7104 3.00667L11.8933 3H12.1017ZM12.1017 5H11.8933C11.74 5 11.597 5.07014 11.5032 5.18724L11.4531 5.26296L5.40803 16.4899C5.22997 16.8206 5.22388 17.2172 5.39168 17.5532C5.51132 17.7928 5.74047 17.9552 6.00091 17.992L6.11422 18H17.8796C18.186 18 18.4662 17.8269 18.6032 17.5528C18.7501 17.2589 18.7639 16.9185 18.6458 16.6165L18.5871 16.4897L12.5419 5.26295C12.4693 5.12799 12.3397 5.03536 12.1921 5.00823L12.1017 5ZM12 14.4861C12.6904 14.4861 13.25 15.0458 13.25 15.7361C13.25 16.4265 12.6904 16.9861 12 16.9861C11.3097 16.9861 10.75 16.4265 10.75 15.7361C10.75 15.0458 11.3097 14.4861 12 14.4861ZM12 6.98614C12.5129 6.98614 12.9355 7.37218 12.9933 7.86952L13 7.98614V11.9861C13 12.5384 12.5523 12.9861 12 12.9861C11.4872 12.9861 11.0645 12.6001 11.0067 12.1028L11 11.9861V7.98614C11 7.43386 11.4477 6.98614 12 6.98614Z"
        fill={token('color.icon.accent.gray')}
      />
    </svg>
  );
}

export function WarningIcon(props: GlyphProps) {
  return <Icon glyph={WarningGlyph} {...props} />;
}
