import React from 'react';
import { IntlShape } from 'react-intl-next';

import { ExamplesModalOptions, OnboardingExample } from '../../types';

import LearningExampleImage from './LearningExampleImage';

const COLLAPSE_BELOW_WIDTH = 937;

const getProjectLearningsExamples = (intl: IntlShape): OnboardingExample[] => [
  {
    name: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-customer-insights',
      defaultMessage: 'Customer insights',
      description: 'Example learning name for customer insights',
    }),
    Content: () => <LearningExampleImage imageName="CustInsights" />,
    description: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-customer-insights-description',
      defaultMessage:
        'Share what you’ve learned from customers and how it builds your domain knowledge from this project.',
      description: 'Description for customer insights example',
    }),
  },
  {
    name: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-data-driven-insights',
      defaultMessage: 'Data-driven insights',
      description: 'Example learning name for data-driven insights',
    }),
    Content: () => <LearningExampleImage imageName="DataInsights" />,
    description: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-data-driven-insights-description',
      defaultMessage: 'Share what you’ve learned from product or operational analytics.',
      description: 'Description for data-driven insights example',
    }),
  },
  {
    name: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-competitive-analysis',
      defaultMessage: 'Competitive analysis',
      description: 'Example learning name for competitive analysis',
    }),
    Content: () => <LearningExampleImage imageName="CompetitiveAnalysis" />,
    description: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-competitive-analysis-description',
      defaultMessage: 'Share what you’ve learned from the market, its environment and competitors.',
      description: 'Description for competitive analysis example',
    }),
  },
  {
    name: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-ways-of-working',
      defaultMessage: 'Ways of working',
      description: 'Example learning name for ways of working',
    }),
    Content: () => <LearningExampleImage imageName="WaysOfWorking" />,
    description: intl.formatMessage({
      id: 'townsquare.project.examples-modal.learning-example-ways-of-working-description',
      defaultMessage:
        'Share what you’ve learned from how the team operates and the practices you would recommend to others.',
      description: 'Description for ways of working example',
    }),
  },
];

export const getProjectLearningsExamplesOptions = (intl: IntlShape): ExamplesModalOptions => {
  return {
    title: intl.formatMessage({
      id: 'townsquare.project.examples-modal.project-learnings-examples-title',
      defaultMessage: 'Learnings examples',
      description: 'Title for project learnings examples modal',
    }),
    examples: getProjectLearningsExamples(intl),
    collapseNavBelowPixelWidth: COLLAPSE_BELOW_WIDTH,
    learnMoreLink: 'https://intercom.help/atlas-by-atlassian/en/articles/6305348-share-what-you-know-with-learnings',
  };
};

const getGoalLearningsExamples = (intl: IntlShape): OnboardingExample[] => [
  {
    name: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-customer-insights',
      defaultMessage: 'Customer insights',
      description: 'Example learning name for customer insights',
    }),
    Content: () => <LearningExampleImage imageName="CustInsights" />,
    description: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-customer-insights-description',
      defaultMessage:
        'Share what you’ve learned from customers and how it builds your domain knowledge from this goal.',
      description: 'Description for customer insights example',
    }),
  },
  {
    name: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-data-driven-insights',
      defaultMessage: 'Data-driven insights',
      description: 'Example learning name for data-driven insights',
    }),
    Content: () => <LearningExampleImage imageName="DataInsights" />,
    description: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-data-driven-insights-description',
      defaultMessage: 'Share what you’ve learned from product or operational analytics.',
      description: 'Description for data-driven insights example',
    }),
  },
  {
    name: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-competitive-analysis',
      defaultMessage: 'Competitive analysis',
      description: 'Example learning name for competitive analysis',
    }),
    Content: () => <LearningExampleImage imageName="CompetitiveAnalysis" />,
    description: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-competitive-analysis-description',
      defaultMessage: 'Share what you’ve learned from the market, its environment and competitors.',
      description: 'Description for competitive analysis example',
    }),
  },
  {
    name: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-ways-of-working',
      defaultMessage: 'Ways of working',
      description: 'Example learning name for ways of working',
    }),
    Content: () => <LearningExampleImage imageName="WaysOfWorking" />,
    description: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.learning-example-ways-of-working-description',
      defaultMessage:
        'Share what you’ve learned from how the team operates and the practices you would recommend to others.',
      description: 'Description for ways of working example',
    }),
  },
];

export const getGoalLearningsExamplesOptions = (intl: IntlShape): ExamplesModalOptions => {
  return {
    title: intl.formatMessage({
      id: 'townsquare.goal.examples-modal.goal-learnings-examples-title',
      defaultMessage: 'Learnings examples',
      description: 'Title for goal learnings examples modal',
    }),
    examples: getGoalLearningsExamples(intl),
    collapseNavBelowPixelWidth: COLLAPSE_BELOW_WIDTH,
    learnMoreLink: 'https://intercom.help/atlas-by-atlassian/en/articles/6305348-share-what-you-know-with-learnings',
  };
};
