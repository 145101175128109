/**
 * @generated SignedSource<<add17415bcdd164a9d330f5873b6dd14>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type GoalStateValue = "archived" | "at_risk" | "cancelled" | "done" | "off_track" | "on_track" | "paused" | "pending";
import type { FragmentRefs } from "relay-runtime";
export type GoalIcon$data = {
  readonly state: {
    readonly score: number | null;
    readonly value: GoalStateValue | null;
  } | null;
  readonly " $fragmentType": "GoalIcon";
};
export type GoalIcon$key = {
  readonly " $data"?: GoalIcon$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalIcon">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "GoalIcon",
  "selections": [
    {
      "concreteType": "GoalState",
      "kind": "LinkedField",
      "name": "state",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "score"
        },
        {
          "kind": "ScalarField",
          "name": "value"
        }
      ]
    }
  ],
  "type": "Goal"
};

(node as any).hash = "74eff26ee973b086a2719fd6a7985b3f";

export default node;
