import { useRovoWorkspace } from '@townsquare/collab-context';
import { useOrgContextOrgId } from '@townsquare/org-context';
import { useCloudId, useWorkspaceType } from '@townsquare/workspace-store/hooks';

export function useRouteDependencies() {
  const orgId = useOrgContextOrgId();
  const cloudId = useCloudId();
  const rovoWorkspaceCloudId = useRovoWorkspace();
  const workspaceType = useWorkspaceType();

  return {
    orgId,
    cloudId: cloudId ?? rovoWorkspaceCloudId,
    workspaceType,
  };
}
