import React from 'react';

import { WrappedWithProfileCard } from '@townsquare/profile-card-provider';

import { UserAvatarProps } from '../types';

import { BaseUserAvatarWithoutProfileCard } from './BaseUserAvatarWithoutProfileCard';

export const BaseUserAvatar = (props: UserAvatarProps) => {
  return (
    <WrappedWithProfileCard userId={props.aaid!} position={props.profileCardPosition}>
      <BaseUserAvatarWithoutProfileCard {...props} />
    </WrappedWithProfileCard>
  );
};
