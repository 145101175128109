import {
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  endOfDay,
  setDate,
  startOfDay,
  startOfWeek,
} from 'date-fns';

import { offsetUserDateToWorkspaceDate } from './utils';

export const convertToUtcDate = (dateString: string) => {
  if (!dateString.endsWith('z') && !dateString.endsWith('Z')) {
    return `${dateString}Z`;
  }
  return dateString;
};

export const getDefaultWeekStartDate = () =>
  offsetUserDateToWorkspaceDate(startOfWeek(addWeeks(Date.now(), -1), { weekStartsOn: 1 }));

export const getUtcEndOfWeekDate = (utcStartDate: Date) => addSeconds(addWeeks(utcStartDate, 1), -1);

export const parseProjectUpdatesFeedStartDate = (utcStartDateString?: string) =>
  utcStartDateString ? new Date(convertToUtcDate(utcStartDateString)) : getDefaultWeekStartDate();

/**
 * Goal updates are captured from the 8th of the month until the 7th of the following month.
 * e.g. 08/02/2021 -> 07/03/2021
 * Take the current month and add 7 days to both start and end
 * giving us the correct frame of dates for updates.
 * To get the missed updates to line up correctly we need to shift by an extra hour
 * taking us to 1am UTC (this is when missed updates are generated)
 */
export const GOALS_MONTH_START_DATE = 8;
export const GOALS_MONTH_END_DATE = 7;

const addMissedUpdatesOffset = (date: Date) => addHours(addMinutes(date, 15), 1);
export const getDefaultStartOfGoalMonth = () =>
  offsetUserDateToWorkspaceDate(
    addMissedUpdatesOffset(startOfDay(setDate(addMonths(Date.now(), -1), GOALS_MONTH_START_DATE))),
  );

export const getUtcEndOfGoalMonth = (utcStartDate: Date) => {
  return addMissedUpdatesOffset(endOfDay(setDate(addMonths(utcStartDate, 1), GOALS_MONTH_END_DATE)));
};

export const parseGoalUpdatesFeedStartDate = (utcStartDateString?: string) =>
  utcStartDateString ? new Date(convertToUtcDate(utcStartDateString)) : getDefaultStartOfGoalMonth();
