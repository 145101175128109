/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import Icon from '@atlaskit/icon';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon';
import React from 'react';

import { StyledIcon } from './styles';

export const LightbulbGlyph = (props: CustomGlyphProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.3332 7.37333C19.3332 11.666 15.9998 12.3327 15.9998 16.3333C15.9998 18.3987 13.9178 18.5727 12.3332 18.5727C10.9645 18.5727 7.9425 18.0533 7.9425 16.332C7.9425 12.3333 4.6665 11.6667 4.6665 7.37333C4.6665 3.35266 8.18984 0.0933304 12.0552 0.0933304C15.9218 0.0933304 19.3332 3.35266 19.3332 7.37333Z"
      fill="#FFD983"
    />
    <path
      d="M14.7782 21.6667C14.7782 22.2187 13.2888 23.3333 12.0002 23.3333C10.7115 23.3333 9.22217 22.2187 9.22217 21.6667C9.22217 21.1147 10.7108 21.3333 12.0002 21.3333C13.2888 21.3333 14.7782 21.1147 14.7782 21.6667Z"
      fill="#CCD6DD"
    />
    <path
      d="M15.138 6.862C14.8773 6.60134 14.456 6.60134 14.1953 6.862L12 9.05734L9.80467 6.862C9.544 6.60134 9.12267 6.60134 8.862 6.862C8.60134 7.12267 8.60134 7.544 8.862 7.80467L11.3333 10.276V17.3333C11.3333 17.702 11.632 18 12 18C12.368 18 12.6667 17.702 12.6667 17.3333V10.276L15.138 7.80467C15.3987 7.544 15.3987 7.12267 15.138 6.862Z"
      fill="#FFCC4D"
    />
    <path
      d="M16 20.6667C16 21.4027 15.4027 22 14.6667 22H9.33333C8.59733 22 8 21.4027 8 20.6667V16.6667H16V20.6667Z"
      fill="#99AAB5"
    />
    <path
      d="M7.99938 21.3333C7.67938 21.3333 7.39671 21.102 7.34271 20.776C7.28204 20.4133 7.52738 20.0693 7.89071 20.0093L15.8907 18.676C16.2534 18.6107 16.5974 18.8607 16.6574 19.224C16.718 19.5867 16.4727 19.9307 16.1094 19.9907L8.10938 21.324C8.07271 21.3307 8.03538 21.3333 7.99938 21.3333ZM7.99938 18.6667C7.67938 18.6667 7.39671 18.4353 7.34271 18.1093C7.28204 17.7467 7.52738 17.4027 7.89071 17.3427L15.8907 16.0093C16.2534 15.9447 16.5974 16.194 16.6574 16.5573C16.718 16.92 16.4727 17.264 16.1094 17.324L8.10938 18.6573C8.07271 18.664 8.03538 18.6667 7.99938 18.6667Z"
      fill="#CCD6DD"
    />
  </svg>
);

export const LightbulbIcon = (props: GlyphProps) => (
  <StyledIcon>
    <Icon glyph={LightbulbGlyph} size="small" {...props} />
  </StyledIcon>
);
