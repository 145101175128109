/* eslint-disable formatjs/no-literal-string-in-jsx */
// This file is dev tooling only.
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import CheckMarkIcon from '@atlaskit/icon/core/check-mark';
import CloseIcon from '@atlaskit/icon/core/close';
import InformationIcon from '@atlaskit/icon/core/information';
import { Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { Box, Inline, Pressable, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React, { useEffect, useMemo, useState } from 'react';

import { useAnalytics } from '@townsquare/analytics';
import { isGateEnabled, useFeatureGate } from '@townsquare/stat-sig/gate';
import { Tooltip } from '@townsquare/tooltip';

type FlagKey = Parameters<typeof isGateEnabled>[0];

interface ConfigInfo {
  title: string;
  flagKey: FlagKey;
  extraDetail?: string;
}

const CONFIG: ConfigInfo[] = [
  {
    title: 'Nav V4',
    flagKey: 'jira_nav4',
    extraDetail: 'This feature is also controlled by an opt out mechanism under Personal Settings.',
  },
  { title: 'Platform Visual refresh', flagKey: 'platform-component-visual-refresh' },
  { title: 'Home Visual refresh', flagKey: 'atlassian_home_visual_refresh' },
];

const CRITICAL_KEYS = new Set(CONFIG.map(k => k.flagKey));

type LocalOverrides = ReturnType<(typeof FeatureGates)['getOverrides']>;
type OverrideGates = LocalOverrides['gates'];

let overrides: OverrideGates | undefined;

const minWidthStyles = xcss({
  minWidth: '16px',
});

const BasicIndicator = (props: { set: boolean | undefined }): JSX.Element => {
  if (props.set === undefined) {
    return (
      <Tooltip content="Not set">
        <Box xcss={minWidthStyles}>-</Box>
      </Tooltip>
    );
  } else if (props.set) {
    return (
      <Tooltip content="Turned on">
        <CheckMarkIcon label="Turned on" color={token('color.icon')} />
      </Tooltip>
    );
  }
  return (
    <Tooltip content="Turned off">
      <CloseIcon label="Turned off" color={token('color.icon')} />
    </Tooltip>
  );
};

const pressableStyles = xcss({
  padding: 'space.100',
  borderRadius: 'border.radius',
  backgroundColor: 'color.background.neutral',
  ':hover': {
    backgroundColor: 'color.background.neutral.hovered',
  },
});

const IndicatorSetter = (props: { current: boolean | undefined; onSet: (next: boolean | undefined) => void }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      placement="bottom"
      trigger={triggerProps => (
        <Pressable
          xcss={pressableStyles}
          {...triggerProps}
          onClick={event => {
            event.stopPropagation();
            setOpen(isOpen => !isOpen);
          }}
        >
          <BasicIndicator set={props.current} />
        </Pressable>
      )}
      content={() => (
        <Box paddingInline="space.300" paddingBlock="space.200">
          <Inline space="space.100">
            <Pressable
              xcss={pressableStyles}
              onClick={() => {
                props.onSet(undefined);
                setOpen(false);
              }}
            >
              <BasicIndicator set={undefined} />
            </Pressable>
            <Pressable
              xcss={pressableStyles}
              onClick={() => {
                props.onSet(true);
                setOpen(false);
              }}
            >
              <BasicIndicator set={true} />
            </Pressable>
            <Pressable
              xcss={pressableStyles}
              onClick={() => {
                props.onSet(false);
                setOpen(false);
              }}
            >
              <BasicIndicator set={false} />
            </Pressable>
          </Inline>
        </Box>
      )}
    />
  );
};

export const FlagControlPanel = () => {
  const [nextTabOverrides, setNextTabOverrides] = useState<OverrideGates>();
  const initializeCompleted = FeatureGates.initializeCompleted();

  const analytics = useAnalytics();

  useEffect(() => {
    if (initializeCompleted) {
      if (!overrides) {
        overrides = { ...FeatureGates.getOverrides().gates };
      }
      setNextTabOverrides(FeatureGates.getOverrides().gates);
    }
  }, [initializeCompleted]);

  const rows = useMemo(
    () =>
      CONFIG.map(entry => {
        return {
          cells: [
            {
              content: (
                <Inline space="space.100" alignBlock="center">
                  <Tooltip content={entry.flagKey}>
                    <span>{entry.title}</span>
                  </Tooltip>
                  {entry.extraDetail && (
                    <Tooltip content={entry.extraDetail}>
                      <InformationIcon label="" color={token('color.icon')} />
                    </Tooltip>
                  )}
                </Inline>
              ),
            },
            { content: isGateEnabled(entry.flagKey) ? 'true' : 'false' },
            { content: <BasicIndicator set={overrides?.[entry.flagKey]} /> },
            {
              content: (
                <IndicatorSetter
                  current={nextTabOverrides?.[entry.flagKey]}
                  onSet={(state: boolean | undefined) => {
                    analytics.ui('flagControlPanelOption', 'clicked', {
                      isFixedFlag: true,
                      flagKey: entry.flagKey,
                      setting: state === undefined ? 'clear' : state ? 'on' : 'off',
                    });

                    if (state === undefined) {
                      FeatureGates.clearGateOverride(entry.flagKey);
                    } else {
                      FeatureGates.overrideGate(entry.flagKey, state);
                    }
                    const nextState = FeatureGates.getOverrides()?.gates;
                    setNextTabOverrides({ ...nextState });
                  }}
                />
              ),
            },
          ],
        };
      }),
    [analytics, nextTabOverrides],
  );

  const extraRows = useMemo(() => {
    const allKeys = new Set([...Object.keys(overrides ?? {}), ...Object.keys(nextTabOverrides ?? {})]);
    const keysToConsider = (Array.from(allKeys) as FlagKey[]).filter(item => !CRITICAL_KEYS.has(item as FlagKey));

    return keysToConsider.map(flagKey => {
      const keyName = flagKey.length > 15 ? `...${flagKey.slice(flagKey.length - 15)}` : flagKey;

      return {
        cells: [
          {
            content: (
              <Tooltip content={flagKey}>
                <span>{keyName}</span>
              </Tooltip>
            ),
          },
          { content: isGateEnabled(flagKey) ? 'true' : 'false' },
          { content: <BasicIndicator set={overrides?.[flagKey]} /> },
          {
            content: (
              <IndicatorSetter
                current={nextTabOverrides?.[flagKey]}
                onSet={(state: boolean | undefined) => {
                  analytics.ui('flagControlPanelOption', 'clicked', {
                    isFixedFlag: false,
                    flagKey,
                    setting: state === undefined ? 'clear' : state ? 'on' : 'off',
                  });

                  if (state === undefined) {
                    FeatureGates.clearGateOverride(flagKey);
                  } else {
                    FeatureGates.overrideGate(flagKey, state);
                  }
                  const nextState = FeatureGates.getOverrides()?.gates;
                  setNextTabOverrides({ ...nextState });
                }}
              />
            ),
          },
        ],
      };
    });
  }, [analytics, nextTabOverrides]);

  if (!useFeatureGate('atlas_enable_split_brain_controls')) {
    return null;
  }

  return (
    <Section title="Dev control panel">
      <Box
        paddingBlockEnd="space.200"
        paddingInline="space.200"
        onClick={(event: React.MouseEvent | React.KeyboardEvent) => {
          event.stopPropagation();
        }}
      >
        <Stack>
          <DynamicTable
            head={{
              cells: [
                { content: 'Flag' },
                { content: 'Resolving to' },
                { content: 'Override' },
                { content: 'After refresh' },
              ],
            }}
            rows={rows}
          />
          {extraRows.length > 0 && (
            <DynamicTable
              head={{
                cells: [
                  { content: 'Other overrides' },
                  { content: 'Resolving to' },
                  { content: 'Override' },
                  { content: 'After refresh' },
                ],
              }}
              rows={extraRows}
            />
          )}
          <Stack space="space.100">
            <Button
              appearance="danger"
              onClick={() => {
                analytics.ui('flagControlPanelClear', 'clicked');
                FeatureGates.clearAllOverrides();
                setNextTabOverrides({});
              }}
              shouldFitContainer
            >
              Clear all overrides
            </Button>
            <Button
              appearance="primary"
              onClick={() => {
                analytics.ui('flagControlPanelRefresh', 'clicked');
                window.location.reload();
              }}
              shouldFitContainer
            >
              Reload
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Section>
  );
};
