import Heading from '@atlaskit/heading';
import React, { Suspense, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { LearningType } from '@townsquare/learnings';

import { RelatedLearning } from './RelatedLearning';
import { RelatedLearnings$key } from './__generated__/RelatedLearnings.graphql';
import { RelatedLearningsContainer, RelatedLearningsItems } from './styles';

export type RelatedLearningsProps = {
  data: RelatedLearnings$key;
  learningType: LearningType;
};

const shuffleAndLimit = (items?: ReadonlyArray<any> | null, limit = 3) =>
  [...(items ?? [])].sort(() => Math.random() - 0.5).slice(0, limit);

const RelatedLearnings = (props: RelatedLearningsProps) => {
  const data = useFragment(
    graphql`
      fragment RelatedLearnings on Project
      @argumentDefinitions(
        sort: { type: "[LearningSortEnum]", defaultValue: [SUMMARY_ASC] }
        type: { type: "LearningType" }
      ) {
        relatedLearnings(sort: $sort, first: 6, type: $type) {
          count
          edges {
            node {
              id
              ...RelatedLearning
            }
          }
        }
      }
    `,
    props.data,
  );

  const shuffledLearnings = useMemo(() => {
    return shuffleAndLimit(data.relatedLearnings?.edges, 3);
  }, [data.relatedLearnings?.edges]);

  if (!data.relatedLearnings || data.relatedLearnings.count < 1) {
    return null;
  }

  return (
    <RelatedLearningsContainer>
      <Heading size="xsmall" as="h3">
        <FormattedMessage
          id="townsquare.web.related-learnings.from-related-projects"
          description="Related learnings from related projects"
          defaultMessage="{learningType, select, risk {Risks} decision {Decisions} other {Learnings}} from related projects"
          values={{
            learningType: props.learningType.toLowerCase(),
          }}
        />
      </Heading>
      <RelatedLearningsItems>
        {shuffledLearnings.map(learning => {
          if (!learning || !learning.node) {
            return null;
          }
          return <RelatedLearning key={learning.node.id} data={learning.node} learningType={props.learningType} />;
        })}
      </RelatedLearningsItems>
    </RelatedLearningsContainer>
  );
};

const RelatedLearningsWrapper = (props: RelatedLearningsProps) => {
  return (
    <ErrorBoundary FallbackComponent={() => null}>
      <Suspense fallback={false}>
        <RelatedLearnings {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export { RelatedLearningsWrapper as RelatedLearnings };
