import { createActionsHook, createHook, createStore, defaultRegistry } from 'react-sweet-state';

export type FlagType = 'error' | 'warning' | 'success' | 'info';

export interface FlagAction {
  content: string | JSX.Element;
  onClick: () => void;
}

export interface Flag {
  actions?: FlagAction[];
  description?: string | JSX.Element;
  id?: string | number;
  shouldAutoDismiss?: boolean;
  title: string | JSX.Element;
  type: FlagType;
  isBold?: boolean;
}

export type InternalFlag = Flag & {
  id: string | number;
  shouldAutoDismiss: boolean;
};

const initialState: Record<string, InternalFlag[]> = {
  flags: [],
};

const flagsStore = createStore({
  initialState,
  actions: {
    addFlag:
      (flag: Flag) =>
      ({ setState, getState }) => {
        const { id = Date.now().toString(), shouldAutoDismiss = true } = flag;
        const newFlag: InternalFlag = { id, shouldAutoDismiss, ...flag };

        setState({
          flags: [newFlag, ...getState().flags.filter(x => x.id !== newFlag.id)],
        });
      },
    removeFlag:
      (id: string) =>
      ({ setState, getState }) => {
        setState({
          flags: getState().flags.filter(x => x.id !== id),
        });
      },
  },
  name: 'flags-store',
});

export const useFlagsStore = createHook(flagsStore, { selector: s => s.flags });
export const useFlagActions = createActionsHook(flagsStore);

// This method is used to reset the flags store in tests to avoid state leaking between tests
export const resetFlagsStore = () => defaultRegistry.getStore(flagsStore).storeState.resetState();
