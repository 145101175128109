import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { EmptyStateRow } from './styles';

export const EmptyState = ({ searchText }: { searchText?: string }) => (
  <EmptyStateRow>
    {searchText ? (
      <FormattedMessage
        id="townsquare.web.search-preview.empty-state-search-text"
        description="Empty state text for search preview with search text"
        defaultMessage='No results found for "{searchText}"'
        values={{ searchText }}
      />
    ) : (
      <FormattedMessage
        id="townsquare.web.search-preview.empty-state"
        description="Empty state text for search preview"
        defaultMessage="No results found"
      />
    )}
  </EmptyStateRow>
);
