import { createRouterSelector } from 'react-resource-router';

export {
  type HomeResourceContext,
  type ResourceContext,
  type RouteWithOutlet,
  type RoutesWithOutlet,
  type RouteWithSubproduct,
} from './types';
export { useSubproductListener } from './SubproductListener';

export const useQueryParams = createRouterSelector(o => o.query);
