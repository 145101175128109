import {
  differenceInCalendarWeeks as differenceInCalendarWeeksReal,
  endOfWeek as endOfWeekReal,
  format as formatReal,
  getWeek as getWeekReal,
  isSameWeek as isSameWeekReal,
  parse as parseReal,
  setDay as setDayReal,
  startOfWeek as startOfWeekReal,
} from 'date-fns';

const DEFAULT_DATE_FNS_OPTIONS: {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
} = {
  weekStartsOn: 1, // Start on monday. Week is monday -> sunday.
};

// Wrap a date-fns method with default options param
// eslint-disable-next-line no-unused-vars
const mixinDefaultOptions = <p extends (...args: any[]) => any>(fn: p) => {
  return function (this: any, ...theArgs: any[]) {
    const lastIndex = fn.length - 1;
    if (fn.length === theArgs.length) {
      theArgs[lastIndex] = {
        ...DEFAULT_DATE_FNS_OPTIONS,
        ...theArgs[lastIndex],
      };
    } else {
      theArgs[lastIndex] = DEFAULT_DATE_FNS_OPTIONS;
    }
    return fn.apply(this, theArgs);
  } as p;
};

export const differenceInCalendarWeeks = mixinDefaultOptions(differenceInCalendarWeeksReal);
export const endOfWeek = mixinDefaultOptions(endOfWeekReal);
export const format = mixinDefaultOptions(formatReal);
export const getWeek = mixinDefaultOptions(getWeekReal);
export const isSameWeek = mixinDefaultOptions(isSameWeekReal);
export const parse = mixinDefaultOptions(parseReal);
export const setDay = mixinDefaultOptions(setDayReal);
export const startOfWeek = mixinDefaultOptions(startOfWeekReal);
