import { ConsentBanner } from '@atlassian/browser-storage-controls';
import React from 'react';
import { useRouter } from 'react-resource-router';

import { isEmbedOrIntegration } from '@townsquare/config';
import { PRODUCT_IDENTIFIER } from '@townsquare/config/constants';

export const CookiesConsentBanner = () => {
  const [routerState] = useRouter();

  if (isEmbedOrIntegration() || ('embed' in routerState.route && routerState.route.embed)) {
    return null;
  }

  return <ConsentBanner product={PRODUCT_IDENTIFIER} />;
};
