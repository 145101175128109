/**
 * @generated SignedSource<<60b703a33bc800fc2763cb1fcc702e6f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TagSortEnum = "ENTITY_COUNT_DESC" | "ID_ASC" | "ID_DESC" | "NAME_ASC" | "NAME_DESC" | "USAGE_ASC" | "USAGE_DESC" | "WATCHER_ASC" | "WATCHER_DESC";
export type ExploreQuery$variables = {
  allTagsQuery: string;
  first?: number | null;
  tagSort?: TagSortEnum | null;
  tagsTrendingLimit: number;
  tagsWatchingLimit: number;
  workspaceId?: string | null;
  workspaceUUID?: string | null;
};
export type ExploreQuery$data = {
  readonly currentUser: {
    readonly tagsWatching: {
      readonly " $fragmentSpreads": FragmentRefs<"TagCardList_tags">;
    } | null;
  } | null;
  readonly tagTql: {
    readonly count: number;
  } | null;
  readonly trendingTags: {
    readonly " $fragmentSpreads": FragmentRefs<"TagCardList_tags">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"TagList_tags">;
};
export type ExploreQuery = {
  response: ExploreQuery$data;
  variables: ExploreQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allTagsQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagSort"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagsTrendingLimit"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagsWatchingLimit"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUUID"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "q",
  "variableName": "allTagsQuery"
},
v9 = {
  "kind": "Variable",
  "name": "workspaceUUID",
  "variableName": "workspaceUUID"
},
v10 = [
  (v7/*: any*/),
  (v8/*: any*/),
  {
    "items": [
      {
        "kind": "Variable",
        "name": "sort.0",
        "variableName": "tagSort"
      }
    ],
    "kind": "ListValue",
    "name": "sort"
  },
  (v9/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "name": "count"
},
v12 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "tagsWatchingLimit"
  },
  {
    "kind": "Variable",
    "name": "workspaceId",
    "variableName": "workspaceId"
  }
],
v13 = [
  {
    "kind": "FragmentSpread",
    "name": "TagCardList_tags"
  }
],
v14 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "tagsTrendingLimit"
  },
  (v9/*: any*/)
],
v15 = {
  "kind": "ScalarField",
  "name": "id"
},
v16 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v17 = {
  "kind": "ScalarField",
  "name": "name"
},
v18 = {
  "kind": "ScalarField",
  "name": "watcherCount"
},
v19 = {
  "kind": "ScalarField",
  "name": "usageCount"
},
v20 = {
  "kind": "ScalarField",
  "name": "projectUsageCount"
},
v21 = {
  "kind": "ScalarField",
  "name": "goalUsageCount"
},
v22 = {
  "kind": "ScalarField",
  "name": "helpPointerUsageCount"
},
v23 = {
  "kind": "ScalarField",
  "name": "watching"
},
v24 = [
  {
    "concreteType": "TagEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v17/*: any*/),
          (v16/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v18/*: any*/),
          (v23/*: any*/)
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ExploreQuery",
    "selections": [
      {
        "args": (v10/*: any*/),
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "tagTql",
        "plural": false,
        "selections": [
          (v11/*: any*/)
        ]
      },
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": (v12/*: any*/),
            "concreteType": "TagConnection",
            "kind": "LinkedField",
            "name": "tagsWatching",
            "plural": false,
            "selections": (v13/*: any*/)
          }
        ]
      },
      {
        "args": (v14/*: any*/),
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "trendingTags",
        "plural": false,
        "selections": (v13/*: any*/)
      },
      {
        "args": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "Variable",
            "name": "sort",
            "variableName": "tagSort"
          },
          (v9/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "TagList_tags"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExploreQuery",
    "selections": [
      {
        "args": (v10/*: any*/),
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "tagTql",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "concreteType": "TagEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "cursor"
              }
            ]
          },
          {
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "endCursor"
              },
              {
                "kind": "ScalarField",
                "name": "hasNextPage"
              }
            ]
          }
        ]
      },
      {
        "args": (v10/*: any*/),
        "filters": [
          "q",
          "workspaceUUID",
          "sort"
        ],
        "handle": "connection",
        "key": "TagList__tagTql",
        "kind": "LinkedHandle",
        "name": "tagTql"
      },
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": (v12/*: any*/),
            "concreteType": "TagConnection",
            "kind": "LinkedField",
            "name": "tagsWatching",
            "plural": false,
            "selections": (v24/*: any*/)
          },
          (v15/*: any*/)
        ]
      },
      {
        "args": (v14/*: any*/),
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "trendingTags",
        "plural": false,
        "selections": (v24/*: any*/)
      }
    ]
  },
  "params": {
    "cacheID": "e3994387b444601eedb249f2a35f5885",
    "id": null,
    "metadata": {},
    "name": "ExploreQuery",
    "operationKind": "query",
    "text": "query ExploreQuery(\n  $workspaceUUID: UUID\n  $tagsWatchingLimit: Int!\n  $tagsTrendingLimit: Int!\n  $workspaceId: ID\n  $allTagsQuery: String!\n  $tagSort: TagSortEnum\n  $first: Int\n) {\n  tagTql(q: $allTagsQuery, workspaceUUID: $workspaceUUID, first: $first, sort: [$tagSort]) {\n    count\n  }\n  currentUser {\n    tagsWatching(first: $tagsWatchingLimit, workspaceId: $workspaceId) {\n      ...TagCardList_tags\n    }\n    id\n  }\n  trendingTags(workspaceUUID: $workspaceUUID, first: $tagsTrendingLimit) {\n    ...TagCardList_tags\n  }\n  ...TagList_tags_31MiGb\n}\n\nfragment TagCardList_tags on TagConnection {\n  edges {\n    node {\n      id\n      ...TagCard_tag\n    }\n  }\n}\n\nfragment TagCard_tag on Tag {\n  name\n  uuid\n  usageCount\n  projectUsageCount\n  goalUsageCount\n  helpPointerUsageCount\n  watcherCount\n  ...TagFollowButton\n}\n\nfragment TagFollowButton on Tag {\n  id\n  uuid\n  name\n  watching\n}\n\nfragment TagList_tags_31MiGb on Query {\n  tagTql(q: $allTagsQuery, workspaceUUID: $workspaceUUID, first: $first, sort: [$tagSort]) {\n    count\n    edges {\n      node {\n        id\n        uuid\n        name\n        watcherCount\n        usageCount\n        projectUsageCount\n        goalUsageCount\n        helpPointerUsageCount\n        ...TagFollowButton\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ac660c88377adae7191009f00e226c3";

export default node;
