import WithEditorActions from '@atlaskit/editor-core/dist/esm/ui/WithEditorActions';
import { EditorContext } from '@atlaskit/editor-core/editor-context';

import { ComposableEditor } from './ComposableEditor/ComposableEditor';

export { EditorContext, WithEditorActions };

export const preloadEditor = () => {
  ComposableEditor.preload();
};
