import { createHook, createStore } from 'react-sweet-state';

import { ColumnInfo } from '../types';

interface CustomColumnsState {
  columns: ColumnInfo[];
}

const initialState: CustomColumnsState = {
  columns: [],
};

const customColumnsStore = createStore({
  initialState,
  actions: {
    setColumns:
      (columns?: ColumnInfo[]) =>
      ({ setState }) => {
        setState({ columns: columns ?? [] });
      },
  },
  name: 'custom-columns-store',
});

export const useCustomColumns = createHook(customColumnsStore);
