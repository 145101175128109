/**
 * @generated SignedSource<<7c570f1c4d7bcc6eb2d630ace592330f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type RecentlyViewedQuery$variables = {
  aaids: ReadonlyArray<string | null>;
  cloudId?: string | null;
  teamIds: ReadonlyArray<string | null>;
};
export type RecentlyViewedQuery$data = {
  readonly teamsByTeamIds: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly members: {
          readonly count: number;
        } | null;
        readonly name: string | null;
        readonly teamId: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly userProfilesByAaids: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string | null;
        readonly name: string | null;
        readonly picture: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type RecentlyViewedQuery = {
  response: RecentlyViewedQuery$data;
  variables: RecentlyViewedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "aaids"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamIds"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "args": [
    {
      "kind": "Variable",
      "name": "aaids",
      "variableName": "aaids"
    }
  ],
  "concreteType": "UserPIIConnection",
  "kind": "LinkedField",
  "name": "userProfilesByAaids",
  "plural": false,
  "selections": [
    {
      "concreteType": "UserPIIEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "UserPII",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "name": "picture"
            },
            {
              "kind": "ScalarField",
              "name": "accountId"
            }
          ]
        }
      ]
    }
  ]
},
v5 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "teamIds",
    "variableName": "teamIds"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "teamId"
},
v7 = {
  "concreteType": "TeamMemberConnection",
  "kind": "LinkedField",
  "name": "members",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "count"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "RecentlyViewedQuery",
    "selections": [
      (v4/*: any*/),
      {
        "args": (v5/*: any*/),
        "concreteType": "TeamConnection",
        "kind": "LinkedField",
        "name": "teamsByTeamIds",
        "plural": false,
        "selections": [
          {
            "concreteType": "TeamEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecentlyViewedQuery",
    "selections": [
      (v4/*: any*/),
      {
        "args": (v5/*: any*/),
        "concreteType": "TeamConnection",
        "kind": "LinkedField",
        "name": "teamsByTeamIds",
        "plural": false,
        "selections": [
          {
            "concreteType": "TeamEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "941861d3b09207f2d82f3ca08f146f75",
    "id": null,
    "metadata": {},
    "name": "RecentlyViewedQuery",
    "operationKind": "query",
    "text": "query RecentlyViewedQuery(\n  $aaids: [String]!\n  $teamIds: [String]!\n  $cloudId: String\n) {\n  userProfilesByAaids(aaids: $aaids) {\n    edges {\n      node {\n        name\n        picture\n        accountId\n      }\n    }\n  }\n  teamsByTeamIds(teamIds: $teamIds, cloudId: $cloudId) {\n    edges {\n      node {\n        name\n        teamId\n        members {\n          count\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e556cd028e6632237a59816098ab2e1";

export default node;
