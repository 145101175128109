import React from 'react';
import Loadable from 'react-loadable';

import { ProjectViewSkeleton } from './components/ProjectViewSkeleton';

export const ProjectView = Loadable({
  loader: () => import(/* webpackChunkName: "RelayProjectView" */ './components/ProjectView'),
  loading: () => <ProjectViewSkeleton />,
  delay: 0,
});

export const ProjectLearningsTabLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "ProjectLearningsTab" */ './components/ProjectLearningsTab'),
  loading: () => null,
});

export { ProjectLearningsTab } from './components/ProjectLearningsTab';

export { projectViewResource, projectUpdatesResource } from './resources/ProjectView';
export { jiraIntegrationLinkResource } from './resources/JiraIntegrationLink';
export {
  projectLearningsTabResource,
  projectRisksTabResource,
  projectDecisionsTabResource,
} from './resources/ProjectLearningsTab';
export { isUserInList, userCanEditProject, userCanPostProjectUpdate } from './utils';
export { deleteProjectFusionV2Mutation } from './mutations/DeleteProjectFusionV2Mutation';
export { LearningsList } from './components/ProjectLearningsTab/LearningsList';
export { LearningsListWrapper } from './components/ProjectLearningsTab/styles';
