/**
 * Design tokens are intentionally left out of this component pending design of a darkmode experience.
 * It has been decided to prioritise finalising the lightmode experience for this flow to get in front of customers first.
 */
import { borderRadius, layers } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { hideOnMobile, onMobile, onSmallDesktopDown, onTabletDown, showOnMobile } from '@townsquare/responsive-grid';

const ANIMATION_HEIGHT = 600;
const ANIMATION_WIDTH = 800;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PageOverNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  z-index: ${layers.layer()};
  overflow: hidden;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ImageBackground = styled.div<{ backgroundImage: string }>(props => ({
  width: '100%',
  height: '100%',
  position: 'inherit',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundImage: `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IntroPageContentFlexWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown(`
    flex-direction: column-reverse;
    width: 100%;
    height: calc(100% - 20px);
    margin: ${token('space.250')} ${token('space.250')} 0 ${token('space.250')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextColumn = styled.div(
  {
    height: `${ANIMATION_HEIGHT}px`,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '395px',
    // eslint-disable-next-line @atlaskit/design-system/use-tokens-space
    marginRight: '50px',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown(`
    flex: 1 auto;
    height: fit-content;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
    max-width: unset;
    align-content: center;
  `),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onTabletDown(`
    margin: 10px 0 10px 0;
    justify-content: start;
    gap: ${token('space.100')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextSections = styled.div(
  {
    flexGrow: 1,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onTabletDown(`
    flex-grow: 0;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionStyle = styled.section<{ isHighlighted: boolean }>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  props => ({
    boxSizing: 'border-box',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: props.isHighlighted ? token('color.text.discovery') : token('color.text.subtlest'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundColor: props.isHighlighted
      ? token('color.background.discovery')
      : token('color.background.neutral.subtle'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    borderRadius: '4px',
    cursor: 'pointer',
    padding: `${token('space.100')} ${token('space.150')}`,
    margin: `0 0 ${token('space.500')} ${token('space.negative.100')}`,
    transition: 'background-color 0.2s ease-out 0ms, color 0.2s ease-out 0ms',
    '&:hover': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundColor: props.isHighlighted
        ? token('color.background.discovery')
        : token('color.background.neutral.subtle.hovered'),
    },
  }),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown(`
    width: 100%;
    margin-left: 0;
  `),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onTabletDown(`
    margin-bottom: ${token('space.150')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IntroPageNumbers = styled.div(
  {
    font: token('font.body.small'),
    color: token('color.text.subtlest'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown(`
    text-align: center;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const WelcomeTextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  margin: 'auto',
  width: '400px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DismissButtonWrapper = styled.div(
  {
    position: 'fixed',
    right: token('space.500'),
    top: token('space.500'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    zIndex: layers.layer(),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown(`
    right: 2px;
    top: 2px;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ImagePlaceholder = styled.div<{ fallback?: string }>`
  aspect-ratio: ${ANIMATION_WIDTH} / ${ANIMATION_HEIGHT};
  width: ${ANIMATION_WIDTH}px;
  background-color: ${token('color.background.neutral.subtle')};
  background-size: contain;
  background-image: ${({ fallback }) => (fallback ? `url(${fallback})` : 'none')};
  overflow-y: hidden;
  border-radius: ${borderRadius() * 2}px;

  ${onSmallDesktopDown(`
    width: 100%;
    max-width: ${ANIMATION_WIDTH}px;
  `)}

  ${onSmallDesktopDown(`
    margin: 20px auto 0 auto;
  `)}
  ${onTabletDown(`
    margin: 0 auto
  `)}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionButtonRow = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: token('space.075'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
    padding-right: 0;
    flex-direction: row-reverse;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionButtons = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: token('space.100'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown(`
    align-self: flex-end;
    margin-bottom: 2px;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SampleDigestInnerButton = styled.button({
  columnGap: token('space.050'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  alignItems: 'center !important',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  span: {
    color: token('color.text.discovery'),
    cursor: 'pointer',
    margin: 0,
    fontWeight: token('font.weight.regular'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SampleDigestMobileText = styled.span(
  {
    display: 'none',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  showOnMobile,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SampleDigestText = styled.span(
  {
    display: 'inline',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  hideOnMobile,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NextButton = styled.button({
  padding: `${token('space.075')} ${token('space.150')}`,
  color: token('color.text.inverse'),
  height: '32px',
  background: token('color.background.discovery.bold'),
  borderWidth: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  borderRadius: '4px',
  width: 'fit-content',
  cursor: 'pointer',
  '&:hover': {
    background: token('color.background.discovery.bold.hovered'),
  },
});
