import { LinkItem, Section } from '@atlaskit/menu';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import FileIcon from '@townsquare/ds-icon/glyph/file';
import { useOpenPendingUpdatesDrawer } from '@townsquare/pending-updates-drawer/hooks';
import { ProjectIcon } from '@townsquare/project-icon';
import { projectRoute } from '@townsquare/project-view/route';
import { useGeneratePath } from '@townsquare/router/primitives';

import {
  WriteUpdateForSectionQuery,
  WriteUpdateForSectionQuery$data,
} from './__generated__/WriteUpdateForSectionQuery.graphql';

type UpdatesSectionProps = {
  onCloseNewMenu: () => void;
  queryReference: PreloadedQuery<WriteUpdateForSectionQuery>;
};

export const WriteUpdateForQuery = graphql`
  query WriteUpdateForSectionQuery($workspaceUuid: UUID!, $q: String!) {
    projectTql(first: 5, q: $q, workspaceUuid: $workspaceUuid, sort: LATEST_UPDATE_DATE_ASC) {
      edges {
        node {
          key
          uuid
          name
          ...ProjectIcon
        }
      }
    }
  }
`;

export const WriteUpdateForSection = (props: UpdatesSectionProps) => {
  const openPendingUpdatesDrawer = useOpenPendingUpdatesDrawer('create-dropdown');

  const intl = useIntl();
  const data = usePreloadedQuery<WriteUpdateForSectionQuery>(WriteUpdateForQuery, props.queryReference);

  if (!data?.projectTql?.edges) {
    return null;
  }

  const projectNodes = data?.projectTql.edges;

  return (
    <>
      {projectNodes.length > 0 && (
        <Section
          title={intl.formatMessage({
            id: 'townsquare.navigation.new-button-menu-updates.section-title',
            description: 'Section title for the updates section of the new button menu',
            defaultMessage: 'Write an update for…',
          })}
        >
          {projectNodes.map(project => (
            <ProjectLinkItem project={project} onCloseNewMenu={props.onCloseNewMenu} />
          ))}
          <LinkItem
            key="pending-updates-drawer-link"
            iconBefore={
              <FileIcon
                label={intl.formatMessage({
                  id: 'townsquare.navigation.new-button-menu-updates.pending-updates-drawer-link-icon',
                  description: 'Icon before the pending updates drawer link in the new button menu',
                  defaultMessage: 'Writing mode',
                })}
                size="medium"
              />
            }
            href={openPendingUpdatesDrawer.href}
            onClick={openPendingUpdatesDrawer.onClick}
          >
            <FormattedMessage
              id="townsquare.navigation.new-button-menu-updates.pending-updates-drawer-link"
              description="Link to open the pending updates drawer in the new button menu"
              defaultMessage="Write all due updates"
            />
          </LinkItem>
        </Section>
      )}
    </>
  );
};

type ProjectNodeType = NonNullable<NonNullable<NonNullable<WriteUpdateForSectionQuery$data['projectTql']>['edges']>[0]>;

const ProjectLinkItem = (props: { project: ProjectNodeType | null } & Pick<UpdatesSectionProps, 'onCloseNewMenu'>) => {
  const { project, onCloseNewMenu } = props;
  const [{ route }, { push }] = useRouter();
  const analytics = useAnalytics();

  const projectPath = useGeneratePath(projectRoute, {
    id: project?.node?.key,
    tabName: 'updates',
    resourceId: 'new',
  });

  if (!project?.node) {
    return null;
  }

  return (
    <LinkItem
      key={project?.node.uuid ?? ''}
      href={projectPath}
      iconBefore={<ProjectIcon data={project.node} size="small" />}
      onClick={event => {
        event.preventDefault();
        void analytics.ui('newButtonMenuNewUpdate', 'clicked', {
          route: route.name,
          projectId: project?.node?.uuid,
        });
        onCloseNewMenu();
        void push(projectPath);
      }}
    >
      {project?.node?.name ?? ''}
    </LinkItem>
  );
};
