/**
 * @generated SignedSource<<e7ecf1f276defed6b1e2501e7dc09693>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LearningType = "DECISION" | "LEARNING" | "RISK";
export type updateLearningInput = {
  clientMutationId?: string | null;
  description?: string | null;
  id: string;
  isResolved?: boolean | null;
  summary?: string | null;
  type?: LearningType | null;
};
export type UpdateLearningMutation$variables = {
  input: updateLearningInput;
};
export type UpdateLearningMutation$data = {
  readonly updateLearning: {
    readonly learning: {
      readonly description: string | null;
      readonly id: string;
      readonly lastEditedBy: {
        readonly pii: {
          readonly name: string | null;
          readonly picture: string | null;
        } | null;
      } | null;
      readonly lastEditedDate: string | null;
      readonly summary: string | null;
      readonly type: LearningType | null;
    } | null;
  } | null;
};
export type UpdateLearningMutation = {
  response: UpdateLearningMutation$data;
  variables: UpdateLearningMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "summary"
},
v4 = {
  "kind": "ScalarField",
  "name": "description"
},
v5 = {
  "concreteType": "UserPII",
  "kind": "LinkedField",
  "name": "pii",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "picture"
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "lastEditedDate"
},
v7 = {
  "kind": "ScalarField",
  "name": "type"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "UpdateLearningMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "updateLearningPayload",
        "kind": "LinkedField",
        "name": "updateLearning",
        "plural": false,
        "selections": [
          {
            "concreteType": "Learning",
            "kind": "LinkedField",
            "name": "learning",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateLearningMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "updateLearningPayload",
        "kind": "LinkedField",
        "name": "updateLearning",
        "plural": false,
        "selections": [
          {
            "concreteType": "Learning",
            "kind": "LinkedField",
            "name": "learning",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "d4a095e3a04a8dc422aa4763dad1fe75",
    "id": null,
    "metadata": {},
    "name": "UpdateLearningMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateLearningMutation(\n  $input: updateLearningInput!\n) {\n  updateLearning(input: $input) {\n    learning {\n      id\n      summary\n      description\n      lastEditedBy {\n        pii {\n          name\n          picture\n        }\n        id\n      }\n      lastEditedDate\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81d8fb44fcd9bc1d521bad8aecc80ac1";

export default node;
