import Textfield, { TextFieldProps } from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import React, { Ref, useCallback } from 'react';
import styled from 'styled-components';

import { useAnalytics } from '@townsquare/analytics';
import { SEARCH_DIRECTORY_SHORTCUT, useKeyboardShortcut } from '@townsquare/keyboard-shortcuts';
import { useKeyboardShortcutStore } from '@townsquare/keyboard-shortcuts';

export interface Props extends TextFieldProps {
  onTextChange: (q: string) => void;
  trimSpaces?: boolean;
  css?: any;
  innerRef?: Ref<HTMLInputElement>;
}

export const textFilterContainerStyle = {
  borderRadius: '6px',
  padding: `${token('space.050')} ${token('space.100')}`,
};

const TextFilterOverrides = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  '[data-ds--text-field--container]': textFilterContainerStyle,
});

export const TextFilter = ({ onTextChange, innerRef, isDisabled = false, ...props }: Props) => {
  const analytics = useAnalytics();
  const [{ type }] = useKeyboardShortcutStore();

  const handleSearchbarKeypress = useCallback(
    (e: KeyboardEvent) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      void analytics.ui('keyboardShortcut', 'pressed', { shortcutType: type }, 'searchInDirectoryShortcut');
      document.getElementById('directory-searchbar')?.focus();
    },
    [analytics, type],
  );

  useKeyboardShortcut(SEARCH_DIRECTORY_SHORTCUT, handleSearchbarKeypress);

  return (
    <TextFilterOverrides>
      <Textfield
        id="directory-searchbar"
        isDisabled={isDisabled}
        testId="directory-searchbar"
        {...props}
        ref={innerRef}
        onChange={event => {
          const inputValue = (event.target as HTMLInputElement).value;
          onTextChange(props.trimSpaces ? inputValue.trim() : inputValue);
        }}
      />
    </TextFilterOverrides>
  );
};
