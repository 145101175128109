import Button from '@atlaskit/button';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import styled, { css, injectGlobal } from 'styled-components';

import { getLogInUrl } from '@townsquare/auth-utils';
import { isUserAgentFirefox, isUserAgentSafari } from '@townsquare/browser-utils';
import { isEmbed as isPathEmbed, isIntegration } from '@townsquare/config';
import { identityClient } from '@townsquare/identity-client';

// When this screen is viewed inside an embed (e.g. in a Confluence page),
// #root will have a min-height of 900px.
// This results in a huge unnecessary scroll when the embed has a low height (as most of our embeds do).
// The code below is a hack to remove that min-height.
// eslint-disable-next-line no-unused-expressions
injectGlobal`
  #root {
    min-height: auto !important;
  }
`;

const Container = styled.div<{ isEmbed: boolean }>(
  {
    display: 'flex',
    alignContent: 'center',
    textAlign: 'center',
    width: '100vw',
    background: token('color.background.neutral'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  props =>
    props.isEmbed &&
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      // For embeds, this ensures that the grey background always takes up 100% of the iframe's viewport.
      // However, we're not using this in the Jira integration, because it interacts weirdly with Connect's resizing logic
      // it causes the iframe to start short and expand to its final height after ~1 second.
      height: '100vh',
    }),
);

const TopLevelFlexContainer = styled.div<{ isEmbed: boolean }>(
  {
    display: 'flex',
    boxSizing: 'border-box',
    flex: 1, // Makes this fill the entire width of its parent element
    padding: token('space.200'),
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  props =>
    props.isEmbed &&
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      // For embeds with shorter height, flex-direction: column will cause
      // most of this screen (including the button) to be below the fold.
      // We want to make sure the button is always visible, so in this case we'll
      // change the flex-direction to row
      '@media (max-height: 300px)': {
        flexDirection: 'row',
      },
    }),
);

const textAndButtonFlexColumnStyles = xcss({
  padding: 'space.150',
});

const Title = styled.span({
  fontWeight: token('font.weight.bold'),
});

const SpotImageContainer = styled.div({
  height: '120px',
  width: '120px',
  boxSizing: 'border-box',
  padding: token('space.200'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
});

type RequestStorageAccessViewProps = {
  isEmbed: boolean;
  spotImgSrc: string;
  title: JSX.Element;
  subtitle?: JSX.Element;
  buttonText: JSX.Element;
  buttonOnClick: () => void | Promise<void>;
  buttonDisabled?: boolean;
};

const RequestStorageAccessBaseComponent = ({
  isEmbed,
  spotImgSrc,
  title,
  subtitle,
  buttonText,
  buttonOnClick,
}: RequestStorageAccessViewProps) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const wrappedOnClick = async () => {
    // Prevent triggering buttonOnClick again while it's still running
    setButtonDisabled(true);
    try {
      await buttonOnClick();
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <Container isEmbed={isEmbed}>
      <TopLevelFlexContainer isEmbed={isEmbed}>
        <SpotImageContainer>
          <img src={spotImgSrc} key={spotImgSrc} alt="" />
        </SpotImageContainer>
        <Flex direction="column" alignItems="center" gap="space.200" xcss={textAndButtonFlexColumnStyles}>
          <Flex direction="column" alignItems="center" gap="space.150">
            <Title>{title}</Title>
            {subtitle}
          </Flex>
          <Button appearance="primary" onClick={wrappedOnClick} isDisabled={buttonDisabled}>
            {buttonText}
          </Button>
        </Flex>
      </TopLevelFlexContainer>
    </Container>
  );
};

type Props = {
  requiresAuthCheck?: boolean;
};

const reload = () => {
  if (isIntegration() && (isUserAgentFirefox() || isUserAgentSafari())) {
    window.AP.navigator.reload();
  } else {
    location.reload();
  }
};

const RequestStorageAccess = ({ requiresAuthCheck = true }: Props) => {
  const [noAccess, setNoAccess] = useState(false);
  const [needAuth, setNeedAuth] = useState(false);
  const isEmbed = useMemo(() => isPathEmbed(), []);

  const checkAuth = async () => {
    const { isAuthenticated } = await identityClient.getUserIsAuthenticated();
    return isAuthenticated;
  };

  const onClick = useCallback(async () => {
    setNoAccess(false);
    try {
      await document.requestStorageAccess();
      if (!requiresAuthCheck) {
        reload();
        return;
      }

      const isAuthenticated = await checkAuth();

      if (isAuthenticated) {
        reload();
        return;
      } else {
        setNeedAuth(true);
      }
    } catch (e) {
      setNoAccess(true);
    }
  }, [requiresAuthCheck]);

  const onSignInClick = useCallback(() => {
    const pop = window.open(getLogInUrl(), '_blank');
    pop?.addEventListener('unload', () => {
      location.reload();
    });
  }, []);

  const openThirdPartyCookiesHelpArticle = useCallback(() => {
    window.open(
      'https://intercom.help/atlas-by-atlassian/en/articles/8593719-troubleshooting-the-jira-software-integration',
      '_blank',
    );
  }, []);

  if (needAuth)
    return (
      <RequestStorageAccessBaseComponent
        isEmbed={isEmbed}
        spotImgSrc="/Images/FirewallKey@2x.png"
        title={
          <FormattedMessage
            id="townsquare.web.request-storage-access.need-auth"
            defaultMessage="Looks like you haven't signed in yet"
            description="Text for when a user needs to sign in after requesting storage access."
          />
        }
        buttonText={
          <FormattedMessage
            id="townsquare.web.request-storage-access.sign-in"
            defaultMessage="Sign in"
            description="Button text for when a user needs to sign in after requesting storage access."
          />
        }
        buttonOnClick={onSignInClick}
      />
    );

  if (noAccess)
    return (
      <RequestStorageAccessBaseComponent
        isEmbed={isEmbed}
        spotImgSrc="/Images/states/generic-error.svg"
        title={
          <FormattedMessage
            id="townsquare.web.request-storage-access.no-access"
            defaultMessage="Browser access could not be requested"
            description="Text for when a user is unable to request storage access."
          />
        }
        subtitle={
          <FormattedMessage
            id="townsquare.web.request-storage-access.enable-third-party-cookies"
            defaultMessage="Enable third-party cookies in your browser and try again."
            description="Prompts users to enable third-party cookies and retry requesting storage access."
          />
        }
        buttonText={
          <FormattedMessage
            id="townsquare.web.request-storage-access.find-out-how-button"
            defaultMessage="Find out how"
            description="Button text for linking out to the documentation on enabling third-party cookies."
          />
        }
        buttonOnClick={openThirdPartyCookiesHelpArticle}
      />
    );

  return (
    <RequestStorageAccessBaseComponent
      isEmbed={isEmbed}
      spotImgSrc="/Images/RequestStorageAccess/Gears.svg"
      title={
        isEmbed ? (
          <FormattedMessage
            id="townsquare.web.request-storage-access.embed-text"
            defaultMessage="Atlas needs additional browser permissions"
            description="Text shown in embed for when a user needs to request storage access."
          />
        ) : (
          <FormattedMessage
            id="townsquare.web.request-storage-access.jira-integration-text"
            defaultMessage="Atlas for Jira Cloud needs additional browser permissions"
            description="Text shown in Jira integration for when a user needs to request storage access."
          />
        )
      }
      buttonText={
        <FormattedMessage
          id="townsquare.web.request-storage-access.authorize"
          defaultMessage="Allow access"
          description="Button text for when a user needs to request storage access."
        />
      }
      buttonOnClick={onClick}
    />
  );
};

export default RequestStorageAccess;
