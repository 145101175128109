export const PRODUCT_DISPLAY_NAME = 'Atlas';
export const GLOBAL_EXPERIENCE_DISPLAY_NAME = 'Atlassian';
export const GLOBAL_EXPERIENCE_DISPLAY_NAME_FULL = 'Atlassian Home';
export const PRODUCT_IDENTIFIER = 'townsquare';
export const ANALYTICS_PRODUCT_IDENTIFIER = 'watermelon';
// use PEOPLE_PRODUCT_IDENTIFIER if you need all users of a site eg. in the Smart User Picker
// using ANALYTICS_PRODUCT_IDENTIFIER will only include users with Atlas access
export const PEOPLE_PRODUCT_IDENTIFIER = 'people';
export const KUDOS_EMBED_TYPE_TEAM = 'TEAM';
export const KUDOS_EMBED_TYPE_INDIVIDUAL = 'INDIVIDUAL';

export const FREE_TIER_SIZE = 35_000;

export enum ATLASSIAN_PRODUCT_NAMES {
  JIRA = 'Jira',
  JIRA_SOFTWARE = 'Jira Software',
  JIRA_WORK_MANAGEMENT = 'Jira Work Management',
  TRELLO = 'Trello',
}

export enum THIRD_PARTY_PRODUCT_NAMES {
  GOOGLE_SHEETS = 'Google Sheets',
  MONDAY = 'monday.com',
  ASANA = 'Asana',
}

export type ANALYTICS_PRODUCT_TYPE =
  | 'bitbucket'
  | 'confluence'
  | 'jira'
  | 'jira_addon'
  | 'jira_core'
  | 'jira_platform'
  | 'jira_portfolio'
  | 'jira_serviceDesk'
  | 'jira_productDiscovery'
  | 'jira_software'
  | 'jira_unknown'
  | 'opsgenie'
  | 'sourcetree'
  | 'statuspage'
  | 'trello'
  | 'watermelon'
  | 'avocado'
  | 'compass';

export type SMART_USER_PICKER_SCOPE = typeof PEOPLE_PRODUCT_IDENTIFIER | typeof ANALYTICS_PRODUCT_IDENTIFIER;
