import Button from '@atlaskit/button/new';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

export const ResetButton = (props: { onClick: () => void; textOverride?: string }) => {
  return (
    <Button appearance="subtle" testId="clear-filters-button" onClick={props.onClick}>
      {props.textOverride ?? (
        <FormattedMessage
          id="townsquare.directory-actions.reset.button"
          description="Reset button label"
          defaultMessage="Reset"
        />
      )}
    </Button>
  );
};
