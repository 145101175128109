import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { RESPONSIVE_THRESHOLD } from './constants';
import { hideOnDesktop, hideOnMobile } from './utils';

export const responsiveGridSizeTimes = (n: number) => 80 * n;

/* Minimum width columns are allowed to be */
export const minColumnWidth: number = responsiveGridSizeTimes(3);

/**
 * Contains styles common to all columns
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Ignored via go/DSP-18766
export const baseColumnStyles = css({
  padding: `0 ${token('space.600')} ${token('space.600')}`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  minWidth: `${minColumnWidth}px`,

  /**
   * Responsive behaviour:
   * - Fit available space
   * - Remove padding to fully utilise narrow screen real estate
   */
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  [`@media (max-width: ${RESPONSIVE_THRESHOLD}px)`]: {
    flex: '1 1',
    padding: 0,
  },
});

/**
 * Contains and arranges a set of columns.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Section = styled.div<{ marginTop?: number }>(props => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginTop: `${props.marginTop !== undefined && props.marginTop}px`,
}));

/**
 * Same as Section but stacks its contained columns vertically when resized below
 * a threshold width.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ResponsiveSection = styled(Section)`
  @media (max-width: ${RESPONSIVE_THRESHOLD}px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
  }
`;

/**
 * A standard column.
 * 8 grid units wide by default but shrinks to fit available space.
 */
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MainColumn = styled.main<{ marginTop?: number; maxWidthPx?: number }>`
  ${baseColumnStyles};
  flex: 0 1 ${responsiveGridSizeTimes(8)}px;
  margin-top: ${props => (props.marginTop !== undefined ? `${props.marginTop}px` : token('space.400'))};

  margin-bottom: ${token('space.400')};

  max-width: ${props => (props.maxWidthPx !== undefined ? `${props.maxWidthPx}px` : undefined)};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${RESPONSIVE_THRESHOLD}px) {
    &:last-of-type {
      margin-bottom: ${token('space.400')};
    }
  }
`;

/**
 * A narrow column that does not grow or shrink in width except on narrow
 * screens where it fills the available width.
 */
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AsideColumn = styled.aside`
  ${baseColumnStyles};
  flex: 0 0 ${responsiveGridSizeTimes(4)}px;
  margin-bottom: ${token('space.400')};
  z-index: 1 !important;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

/**
 * A column that uses the full available width.
 */
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FullWidthColumn = styled.main`
  ${baseColumnStyles};
  flex: 1 1 ${responsiveGridSizeTimes(12)}px;
  max-width: 1000px;

  /* Children should be 32px apart */
  > *:not(:last-child) {
    margin-bottom: ${token('space.400')};
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResponsivePage = styled.div({
  padding: `0 ${token('space.250')} ${token('space.250')}`,
});

/**
 * Styled component that wraps a div and applies a style to hide it on mobile devices.
 */
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DesktopOnly = styled.div`
  ${hideOnMobile()}
`;

/**
 * Styled component that wraps a div and applies a style to hide it on desktop devices.
 */
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MobileOnly = styled.div`
  ${hideOnDesktop()}
`;
