import Loadable from 'react-loadable';

export const YourWorkView = Loadable({
  loader: () => import(/* webpackChunkName: "YourWorkView" */ './YourWorkView'),
  loading: () => null,
});

export const YourWorkViewNoWorkspace = Loadable({
  loader: () => import(/* webpackChunkName: "YourWorkViewNoWorkspace" */ './YourWorkViewNoWorkspace'),
  loading: () => null,
});
