import { filterUndefined } from '@townsquare/filter-type';

import {
  ComparatorOperator,
  Content,
  Entity,
  FilterComparators,
  FilterDoc,
  FilterOperator,
  Operators,
  Resolver,
  SupportedFilters,
} from './types';

/**
 * Doc helpers
 */
const FALLBACK_OPERATOR = Operators.AND;
const FALLBACK_COMPARATOR = ComparatorOperator.EQUALS;

export const EMPTY_FILTER_DOC: FilterDoc = {
  operator: FALLBACK_OPERATOR,
  model: [],
};

export const createBaseDocEntity = (
  type: SupportedFilters,
  value: Content | number[],
  defaultResolverOperator?: Operators,
  comparator?: ComparatorOperator,
): Entity => {
  return {
    type,
    operator: defaultResolverOperator ?? FALLBACK_OPERATOR,
    model: Array.isArray(value) ? value : [value],
    comparator: comparator ?? FALLBACK_COMPARATOR,
  };
};

export const hasContentEntry = (doc: FilterDoc, type: SupportedFilters, entityIndex: number): boolean => {
  return !!doc.model.length && !!doc.model.find((entity, idx) => type === entity.type && idx === entityIndex);
};

export const appendContentEntry = (
  doc: FilterDoc,
  entityIndex: number,
  type: SupportedFilters,
  value: Content | number[],
  resolverOperators?: Operators[],
  comparators?: ComparatorOperator[],
): FilterDoc => {
  let newModel: Entity[];
  // If doc is empty or the current type doesnt exist yet.
  if (!hasContentEntry(doc, type, entityIndex)) {
    const operator = (resolverOperators ?? [])[0];
    const comparator = (comparators ?? [])[0];
    newModel = doc.model.concat(createBaseDocEntity(type, value, operator, comparator));
  } else {
    newModel = doc.model.map((entity, idx) => {
      if (type === entity.type && idx === entityIndex) {
        entity.model = entity.model.concat(value);

        // Adding a check here to ensure the operator is set to a valid one.
        if (resolverOperators && !resolverOperators.includes(entity.operator)) {
          entity.operator = (resolverOperators ?? [])[0] ?? FALLBACK_OPERATOR;
        }
        // Ensure the comparator is set to a valid one.
        if (comparators && !comparators.includes(entity.comparator)) {
          entity.comparator = (comparators ?? [])[0] ?? FALLBACK_COMPARATOR;
        }
      }

      return entity;
    });
  }

  return {
    operator: doc.operator,
    model: newModel,
  };
};

export const removeContentEntry = (
  doc: FilterDoc,
  entityIndex: number,
  type: SupportedFilters,
  modelIndex: number,
): FilterDoc => {
  const newModel = doc.model
    .map((entity, idx) => {
      if (entity.type === type && idx === entityIndex) {
        const filteredModel = entity.model.filter((_value, index) => {
          return index !== modelIndex;
        });

        if (filteredModel.length === 0) {
          return;
        }

        return {
          ...entity,
          model: filteredModel,
        };
      }
      return entity;
    })
    .filter(filterUndefined);

  return {
    operator: doc.operator,
    model: newModel,
  };
};

export const updateDocOperator = (doc: FilterDoc, newOperator: Operators): FilterDoc => {
  return {
    operator: newOperator,
    model: doc.model,
  };
};

export const updateOperatorEntry = (
  doc: FilterDoc,
  entityIndex: number,
  type: SupportedFilters,
  newOperator: Operators,
): FilterDoc => {
  const newModel = doc.model.map((entity, idx) => {
    if (entity.type === type && idx === entityIndex) {
      return {
        ...entity,
        operator: newOperator,
      };
    }

    return entity;
  });

  return {
    operator: doc.operator,
    model: newModel,
  };
};

export const updateComparatorEntry = (
  doc: FilterDoc,
  entityIndex: number,
  type: SupportedFilters,
  newComparator: ComparatorOperator,
  newOperator?: Operators,
): FilterDoc => {
  const newModel = doc.model.map((entity, idx) => {
    if (entity.type === type && idx === entityIndex) {
      return {
        ...entity,
        comparator: newComparator,
        operator: newOperator || entity.operator,
      };
    }

    return entity;
  });

  return {
    operator: doc.operator,
    model: newModel,
  };
};

export const findFilterComparatorOnResolver = (
  comparator: ComparatorOperator,
  resolver: Resolver | undefined,
): FilterComparators | undefined => {
  return resolver?.filterComparators.find(item => item.comparatorOption === comparator);
};

export const findFilterOperatorOrDefault = (
  operator: Operators,
  allowedOperators: FilterOperator[],
): Operators | undefined => {
  if (!allowedOperators.length) {
    return undefined;
  }
  return allowedOperators.find(option => option.operator === operator)?.operator || allowedOperators[0].operator;
};
