import { resolveRawSort } from '@townsquare/directory-header/sort';
import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import ProjectsDirectoryEmbedQuery, {
  ProjectSortEnum,
  type ProjectsDirectoryEmbedQuery as ProjectsDirectoryEmbedType,
} from '../components/ProjectsDirectory/__generated__/ProjectsDirectoryEmbedQuery.graphql';
import { DEFAULT_TQL_QUERY } from '../constants';
import { whenNotViewReturn } from '../util';

const DEFAULT_SORT: [ProjectSortEnum] = ['NAME_ASC'];

/**
 * The format of the enum in the backend is different to the values used in the table in the frontend.
 * This converts it to something the backend can use. If no sort order is provided or there is an
 * invalid sort order, defaults to alphabetical order by name when no view is provided.
 *
 * @param sort the value from the frontend URL, if there is one in the URL
 * @returns a sort order the backend can use (default: alphabetical order by name )
 */
const sortAsEnum = (sort: string, viewUuid: string): ProjectSortEnum[] | undefined => {
  return resolveRawSort(sort) ?? whenNotViewReturn(DEFAULT_SORT, viewUuid);
};

export const projectsDirectoryEmbedResource = createRelayResource<ProjectsDirectoryEmbedType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const directoryViewUuid = routerContext.query.viewUuid;
    return {
      query: ProjectsDirectoryEmbedQuery,
      variables: {
        q: routerContext.query.tql ?? whenNotViewReturn(DEFAULT_TQL_QUERY, directoryViewUuid),
        sort: sortAsEnum(routerContext.query.sort, directoryViewUuid),
        workspaceUuid: context.workspace?.UUID || '',
        directoryViewUuid,
      },
    };
  },
});
