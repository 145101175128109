import { ComparatorOperator, FilterComparators, Operators } from '../types';

/**
 * When adding a new TQL filter to the frontend, check if one of these filter options matches what you want,
 * or configure a new one.
 */

export const equalsAndOrFilterComparators: FilterComparators[] = [
  {
    comparatorOption: ComparatorOperator.EQUALS,
    allowedOperators: [
      {
        operator: Operators.OR,
      },
      {
        operator: Operators.AND,
      },
    ],
  },
];

export const equalsOrFilterComparators: FilterComparators[] = [
  {
    comparatorOption: ComparatorOperator.EQUALS,
    allowedOperators: [
      {
        operator: Operators.OR,
      },
    ],
  },
];

export const isLikeOrFilterComparators: FilterComparators[] = [
  {
    comparatorOption: ComparatorOperator.LIKE,
    allowedOperators: [
      {
        operator: Operators.OR,
      },
    ],
  },
];
