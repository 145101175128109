import { useEffect } from 'react';
import { useRouter } from 'react-resource-router';

const ScrollToTop = () => {
  const [{ location }] = useRouter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;
