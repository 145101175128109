import React from 'react';

export type CollaborationContext = {
  goalWorkspace: string | null;
  projectWorkspace: string | null;
  rovoWorkspace: string | null;
};

export const CollabContext = React.createContext<CollaborationContext | null>(null);

export const useCollabContext = () => {
  return React.useContext(CollabContext);
};

export const useRovoWorkspace = () => {
  const context = useCollabContext();
  return context?.rovoWorkspace;
};

export const useGoalWorkspace = () => {
  const context = useCollabContext();
  return context?.goalWorkspace ?? null;
};

export const useProjectWorkspace = () => {
  const context = useCollabContext();
  return context?.projectWorkspace ?? null;
};
