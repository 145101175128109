import { createRelayResource } from '@townsquare/relay-utils';

import TitanEapCrossFlowQuery, {
  TitanEapCrossFlowQuery as TitanEapCrossFlowQueryType,
} from './__generated__/TitanEapCrossFlowQuery.graphql';

export const titanEapCrossFlowQueryResource = createRelayResource<TitanEapCrossFlowQueryType>({
  getQuery: () => ({
    query: TitanEapCrossFlowQuery,
  }),
});
