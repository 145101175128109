import { KUDOS_EMBED_TYPE_INDIVIDUAL, KUDOS_EMBED_TYPE_TEAM } from '@townsquare/config/constants';
import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import CreateKudosEmbedQuery, {
  CreateKudosEmbedQuery as CreateKudosEmbedQueryType,
} from '../components/Kudos/__generated__/CreateKudosEmbedQuery.graphql';

export const createKudosResource = createRelayResource<CreateKudosEmbedQueryType>({
  getQuery(routerContext, resourceStoreContext) {
    const resourceContext = resourceStoreContext as ResourceContext;

    const isIndividual = routerContext.query.type?.toUpperCase() === KUDOS_EMBED_TYPE_INDIVIDUAL;
    const isTeam = routerContext.query.type?.toUpperCase() === KUDOS_EMBED_TYPE_TEAM;

    return {
      query: CreateKudosEmbedQuery,
      variables: {
        id: routerContext.query.recipientId,
        cloudId: resourceContext.workspace?.cloudId,
        isTeam,
        isIndividual,
      },
    };
  },
});
