import React from 'react';

import { useAnalytics } from '@townsquare/analytics';
import { PROJECT_PATH_PREFIX } from '@townsquare/config/routes';
import { projectRoute } from '@townsquare/project-view/route';
import { WrappedWithHoverSmartCard } from '@townsquare/smart-card-provider';

import { Learning_data$data } from './__generated__/Learning_data.graphql';
import { LearningBylineLink } from './styles';
import { LearningType } from './util';

interface LearningProjectNameByLineProps {
  data: Learning_data$data;
  type: LearningType;
}

export const LearningProjectNameByLine = (props: LearningProjectNameByLineProps) => {
  const { data, type } = props;
  const { project, uuid } = data;
  const analytics = useAnalytics();

  if (!project) {
    return null;
  }

  const projectUrl = PROJECT_PATH_PREFIX + '/' + project.key;
  return (
    <WrappedWithHoverSmartCard id={project.id} url={projectUrl}>
      <LearningBylineLink
        to={projectRoute}
        params={{
          id: project.key,
          // Append 's' because the corresponding url paths are 'learnings', 'decisions' and 'risks'
          tabName: type?.toLowerCase() + 's',
          resourceId: uuid,
        }}
        onClick={() => {
          void analytics.ui('topicsLearningProjectName', 'clicked', {
            projectId: project.uuid,
            learningId: uuid,
          });
        }}
      >
        {project.name}
      </LearningBylineLink>
    </WrappedWithHoverSmartCard>
  );
};
