/**
 * @generated SignedSource<<eec0e4c34fef767ea1f79543b3c1b4ca>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type ReactionUpdateActionQuery$variables = {
  id: string;
};
export type ReactionUpdateActionQuery$data = {
  readonly node: {
    readonly __typename: "GoalUpdate";
    readonly ari: string;
    readonly goal: {
      readonly ari: string;
      readonly key: string;
    } | null;
  } | {
    readonly __typename: "Learning";
    readonly ari: string;
    readonly project: {
      readonly ari: string;
      readonly key: string;
    } | null;
  } | {
    readonly __typename: "ProjectUpdate";
    readonly ari: string;
    readonly project: {
      readonly ari: string;
      readonly key: string;
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type ReactionUpdateActionQuery = {
  response: ReactionUpdateActionQuery$data;
  variables: ReactionUpdateActionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "ari"
},
v4 = {
  "kind": "ScalarField",
  "name": "key"
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  (v3/*: any*/),
  {
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": (v5/*: any*/)
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v7/*: any*/)
],
v9 = [
  (v3/*: any*/),
  {
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": (v8/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ReactionUpdateActionQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "ProjectUpdate"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "goal",
                "plural": false,
                "selections": (v5/*: any*/)
              }
            ],
            "type": "GoalUpdate"
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "Learning"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReactionUpdateActionQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v9/*: any*/),
            "type": "ProjectUpdate"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "goal",
                "plural": false,
                "selections": (v8/*: any*/)
              }
            ],
            "type": "GoalUpdate"
          },
          {
            "kind": "InlineFragment",
            "selections": (v9/*: any*/),
            "type": "Learning"
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "f17db298e39903d81dbc40423fd7d5fc",
    "id": null,
    "metadata": {},
    "name": "ReactionUpdateActionQuery",
    "operationKind": "query",
    "text": "query ReactionUpdateActionQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on ProjectUpdate {\n      ari\n      project {\n        ari\n        key\n        id\n      }\n    }\n    ... on GoalUpdate {\n      ari\n      goal {\n        ari\n        key\n        id\n      }\n    }\n    ... on Learning {\n      ari\n      project {\n        ari\n        key\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "719161809575dfed3e10aa5c57221635";

export default node;
