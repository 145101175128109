import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

import { useAnalytics } from '@townsquare/analytics';
import { stripUserARI } from '@townsquare/ari-utils';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { ProfilePicture } from '@townsquare/profile-picture';
import { profileRoute } from '@townsquare/ptc-directory-view';
import { Link } from '@townsquare/router/primitives';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { NameText, ProfilePicBoundingBox, StyledPersonBrowseCard, UserDetails } from './styles';

export interface BrowseUser {
  name: string;
  aaid: string;
  picture?: string;
  jobTitle?: string;
}

export interface PersonBrowseCardProps {
  user: BrowseUser;
}

const DisplayName = ({ name }: Pick<BrowseUser, 'name'>) => (
  <EllipsisTooltip content={name}>
    <NameText>{name}</NameText>
  </EllipsisTooltip>
);

const JobTitle = ({ jobTitle }: Pick<BrowseUser, 'jobTitle'>) =>
  jobTitle ? (
    <EllipsisTooltip content={jobTitle}>
      <Text as="p" color="color.text.subtle" maxLines={1}>
        {jobTitle}
      </Text>
    </EllipsisTooltip>
  ) : null;

const cardStyles = xcss({
  boxSizing: 'border-box',
  minWidth: '168px',
  gap: 'space.200',
});

const profilePicStyles = xcss({
  height: '230px',
  borderRadius: '6px',
});

const nameTextStyles = xcss({
  // eslint-disable-next-line @atlaskit/design-system/use-latest-xcss-syntax-typography
  font: token('font.heading.small'),
  color: 'color.text',
});

const ellipsisStyles = xcss({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const jobTitleStyles = xcss({
  color: 'color.text.subtle',
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression
const NoUnderlineLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const PersonBrowseCard = ({ user }: PersonBrowseCardProps) => {
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();
  const analytics = useAnalytics();

  const onClick = () => {
    void analytics.ui('browsePersonCard', 'clicked');
  };

  const aaid = stripUserARI(user.aaid);

  if (enableHomeVisualRefresh) {
    return (
      <NoUnderlineLink
        data-testid={`user-browse-card-${user.aaid}`}
        onClick={onClick}
        to={profileRoute}
        params={{ id: aaid }}
        key={user.aaid}
      >
        <Stack xcss={cardStyles}>
          <Box xcss={profilePicStyles}>
            <ProfilePicture name={user.name} src={user.picture} height="100%" width="100%" />
          </Box>
          <Stack space="space.050">
            <EllipsisTooltip content={user.name}>
              <Box xcss={[nameTextStyles, ellipsisStyles]}>{user.name}</Box>
            </EllipsisTooltip>
            <EllipsisTooltip content={user.jobTitle}>
              <Box xcss={[jobTitleStyles, ellipsisStyles]} as="p">
                {user.jobTitle}
              </Box>
            </EllipsisTooltip>
          </Stack>
        </Stack>
      </NoUnderlineLink>
    );
  }

  return (
    <StyledPersonBrowseCard
      data-testid={`user-browse-card-${user.aaid}`}
      onClick={onClick}
      to={profileRoute}
      params={{ id: aaid }}
      key={user.aaid}
    >
      <ProfilePicBoundingBox>
        <ProfilePicture name={user.name} src={user.picture} height="100%" width="100%" />
      </ProfilePicBoundingBox>
      <UserDetails>
        <DisplayName name={user.name} />
        <JobTitle jobTitle={user.jobTitle} />
      </UserDetails>
    </StyledPersonBrowseCard>
  );
};
