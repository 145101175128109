import styled, { css } from 'styled-components';

import { onMobile, onDesktop } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchBarWrapper = styled.div({
  position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterWrapper = styled.div(
  {
    display: 'flex',
    overflowX: 'hidden',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      flexDirection: 'column',
    }),
  ),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onDesktop(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      flexDirection: 'row',
    }),
  ),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResetFilterWrapper = styled.div(
  {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      marginInlineStart: 0,
    }),
  ),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onDesktop(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      marginInlineStart: 'auto',
    }),
  ),
);
