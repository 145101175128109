import Button from '@atlaskit/button';
import React, { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useIntl } from 'react-intl-next';
import { LoadMoreFn } from 'react-relay';
import { useRouter } from 'react-resource-router';
import { OperationType } from 'relay-runtime';

import { useAnalytics } from '@townsquare/analytics';

import { messages } from './messages';
import * as styled from './styles';

export type Props<TOperationType extends OperationType> = {
  pageSize: number;
  loadMore: LoadMoreFn<TOperationType>;
  hasMore?: boolean;
  renderLoader: JSX.Element;
  renderEndOfList?: JSX.Element;
  renderFallback: (fallbackProps: FallbackProps) => ReactNode;
  isLoading: boolean;
  loadMoreStyleOverrides?: React.CSSProperties;
};

export function PaginatedPage<TOperationType extends OperationType>({
  hasMore,
  children,
  loadMore,
  renderLoader,
  renderFallback,
  renderEndOfList,
  pageSize,
  isLoading,
  loadMoreStyleOverrides,
}: PropsWithChildren<Props<TOperationType>>) {
  const intl = useIntl();
  const analytics = useAnalytics();
  const [routerStore] = useRouter();
  const [hasError, setError] = useState<Error | null>(null);

  const fetchMore = useCallback(() => {
    void analytics.track('showMoreButton', 'clicked', {
      screen: routerStore.route.name,
    });
    loadMore(pageSize, {
      onComplete: error => {
        setError(error);
      },
    });
  }, [analytics, loadMore, pageSize, routerStore.route.name]);

  return (
    <>
      {children}
      {isLoading && !hasError && renderLoader}
      {!hasError && !isLoading && hasMore && (
        // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
        <styled.ShowMoreButtonWrapper style={loadMoreStyleOverrides}>
          <Button onClick={fetchMore}>{intl.formatMessage(messages.showMoreMessage)}</Button>
        </styled.ShowMoreButtonWrapper>
      )}
      {hasError && renderFallback({ error: hasError, resetErrorBoundary: () => setError(null) })}
      {!hasMore && renderEndOfList}
    </>
  );
}
