import { endOfQuarter, isSameMonth, isSameYear, isThisYear, startOfQuarter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { IntlShape, useIntl } from 'react-intl-next';

import {
  DIFF_YEAR_FORMAT,
  FULL_DATE_FORMAT,
  FULL_NICE_FORMAT,
  ISO_LOCAL_DATE,
  ISO_LOCAL_DATE_TIME,
  ISO_LOCAL_DATE_WITH_SLASHES,
  MONTH_ONLY_FORMAT,
  MONTH_ONLY_FORMAT_DIFF_YEAR,
  MONTH_ONLY_FORMAT_SHORT,
  MONTH_WITH_YEAR,
  NICE_DATE_TIME,
  SAME_MONTH_FORMAT,
  SAME_YEAR_FORMAT,
} from './constants';
import { differenceInCalendarWeeks, format, isSameWeek } from './mixins';
import { tzDate } from './utils';

export const formatDateRange = (from: Date, to: Date, locale: Locale, separator = '-') => {
  if (isSameYear(from, to)) {
    const fromDisplay = format(from, isSameMonth(from, to) ? SAME_MONTH_FORMAT : SAME_YEAR_FORMAT, { locale });
    const toDisplay = formatNiceDate(to, locale);
    return `${fromDisplay}${separator}${toDisplay}`;
  } else {
    const fromDisplay = format(from, DIFF_YEAR_FORMAT, { locale });
    const toDisplay = format(to, DIFF_YEAR_FORMAT, { locale });
    return `${fromDisplay}${separator}${toDisplay}`;
  }
};

export const formatFullNiceDate = (date: Date, locale: Locale, noDash?: boolean) => {
  return format(date, noDash ? DIFF_YEAR_FORMAT : FULL_NICE_FORMAT);
};

export const formatNiceMonthDate = (date: Date, locale: Locale) =>
  formatWithLocale(date, isThisYear(date) ? MONTH_ONLY_FORMAT : MONTH_ONLY_FORMAT_DIFF_YEAR, locale);

export const formatTooltipNiceMonthDate = (date: Date, locale: Locale) =>
  formatWithLocale(date, MONTH_WITH_YEAR, locale);

export const formatNiceQuarterDate = (date: Date, locale: Locale) =>
  `${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT_SHORT, locale)}-${formatWithLocale(
    endOfQuarter(date),
    isThisYear(date) ? MONTH_ONLY_FORMAT_SHORT : MONTH_ONLY_FORMAT_DIFF_YEAR,
    locale,
  )}`;

export const formatTooltipNiceQuarterDate = (date: Date, locale: Locale) =>
  `${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT, locale)}-${formatWithLocale(
    endOfQuarter(date),
    MONTH_WITH_YEAR,
    locale,
  )}`;

export const formatNiceDateString = (dateString: string, locale: Locale) => {
  return formatNiceDate(tzDate(dateString), locale);
};

export const formatNiceDate = (date: Date, locale: Locale) =>
  formatWithLocale(date, isThisYear(date) ? SAME_YEAR_FORMAT : DIFF_YEAR_FORMAT, locale);

export const formatNiceDateTooltip = (date: Date, locale: Locale) => formatWithLocale(date, FULL_DATE_FORMAT, locale);

const formatWithLocale = (date: Date, dateFormat: string, locale: Locale) => format(date, dateFormat, { locale });

export const formatNiceDateTime = (date: Date, locale: Locale) => formatWithLocale(date, NICE_DATE_TIME, locale);

export const formatIsoLocateDateWithSlashes = (date: Date, locale: Locale) =>
  format(date, ISO_LOCAL_DATE_WITH_SLASHES, { locale });

/**
 * TODO: Both of the methods below are used in constructions outside of React components.
 * They will need special consideration when we want to enforce the `locale` parameter.
 */

export const formatIsoLocalDate = (date: Date, locale?: Locale) => format(date, ISO_LOCAL_DATE, { locale });
export const formatIsoLocalDateTime = (date: Date, locale?: Locale) =>
  format(utcToZonedTime(date, 'UTC', { locale }), ISO_LOCAL_DATE_TIME, { locale });

/**
 * Format display text for a given interval using a week-window granularity against an "anchor" date
 * @param intl
 * @param start of interval
 * @param end of interval
 * @param anchorDate used as relative date, at which calculation will be performed against. If in doubt, leave empty
 * @param locale
 */
export const formatWeekRange = (
  intl: IntlShape,
  start: Date,
  end: Date,
  anchorDate: Date = tzDate(),
  locale: Locale,
) => {
  if (isSameWeek(anchorDate, start, { locale })) {
    return intl.formatMessage({
      id: 'townsquare.date-utils.week-range.this-week',
      description: 'This week section title',
      defaultMessage: 'This week',
    });
  } else if (isLastWeekOf(anchorDate, end, locale)) {
    return intl.formatMessage({
      id: 'townsquare.date-utils.week-range.last-week',
      description: 'Last week section title',
      defaultMessage: 'Last week',
    });
  } else {
    return formatDateRange(start, end, locale);
  }
};

/**
 * Format display text for a given interval using a week-window granularity against an "anchor" date
 * @param start of interval
 * @param end of interval
 * @param anchorDate used as relative date, at which calculation will be performed against. If in doubt, leave empty
 * @param locale provided as a param to avoid a circular dependency
 */
export const useFormatWeekRange = (start: Date, end: Date, anchorDate: Date = tzDate(), locale: Locale) => {
  const intl = useIntl();

  return formatWeekRange(intl, start, end, anchorDate, locale);
};

/**
 * Is the older date last week of the newer date?
 */
export function isLastWeekOf(newer: Date, older: Date, locale: Locale) {
  return differenceInCalendarWeeks(newer, older, { locale }) === 1;
}
