/**
 * @generated SignedSource<<9142a2c86df3afd7907c196ac8f2bb9f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type CreateKudosEmbedQuery$variables = {
  cloudId?: string | null;
  id: string;
  isIndividual: boolean;
  isTeam: boolean;
};
export type CreateKudosEmbedQuery$data = {
  readonly teamByTeamId?: {
    readonly avatarUrl: string | null;
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly userProfilesByAaids?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null;
        readonly picture: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type CreateKudosEmbedQuery = {
  response: CreateKudosEmbedQuery$data;
  variables: CreateKudosEmbedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isIndividual"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTeam"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
},
v5 = [
  {
    "condition": "isIndividual",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "args": [
          {
            "items": [
              {
                "kind": "Variable",
                "name": "aaids.0",
                "variableName": "id"
              }
            ],
            "kind": "ListValue",
            "name": "aaids"
          }
        ],
        "concreteType": "UserPIIConnection",
        "kind": "LinkedField",
        "name": "userProfilesByAaids",
        "plural": false,
        "selections": [
          {
            "concreteType": "UserPIIEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "UserPII",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "picture"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "condition": "isTeam",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "teamId",
            "variableName": "id"
          }
        ],
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "teamByTeamId",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "id"
          },
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "name": "avatarUrl"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "CreateKudosEmbedQuery",
    "selections": (v5/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateKudosEmbedQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "1f55b79214f6298f5e656fb9bcf7725f",
    "id": null,
    "metadata": {},
    "name": "CreateKudosEmbedQuery",
    "operationKind": "query",
    "text": "query CreateKudosEmbedQuery(\n  $id: String!\n  $cloudId: String\n  $isTeam: Boolean!\n  $isIndividual: Boolean!\n) {\n  userProfilesByAaids(aaids: [$id]) @include(if: $isIndividual) {\n    edges {\n      node {\n        name\n        picture\n      }\n    }\n  }\n  teamByTeamId(teamId: $id, cloudId: $cloudId) @include(if: $isTeam) {\n    id\n    name\n    avatarUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "6674a048c9c0a0ab2198ee77903dd8ca";

export default node;
