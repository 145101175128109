import { Bleed } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { PeopleFieldType } from '../../../../hooks/types';
import { getIllustrationHoverColourFromImageDirectory } from '../../../../hooks/util/peopleFieldAggregates';

import { IllustrationCard$key } from './__generated__/IllustrationCard.graphql';
import { usePeopleFieldCardClickHandler } from './hooks';
import {
  IllustrationCardContainer,
  IllustrationCardHoverContainer,
  IllustrationCardNameText,
  IllustrationTextContainer,
  StyledIllustrationCard,
} from './styles';

const GENERIC_ILLUSTRATION_FALLBACK_PATH = '/Images/StaffDirectory/assets/generic/illustration.svg';
const GENERIC_ILLUSTRATION_FALLBACK_PATH_MOBILE = '/Images/StaffDirectory/assets/generic/ambient_mobile.svg';
const GENERIC_ILLUSTRATION_HOVER_COLOR = getIllustrationHoverColourFromImageDirectory('/assets/generic');

export interface PeopleFieldIllustrationCardProps {
  data: IllustrationCard$key;
  fieldType: PeopleFieldType;
}

export const PeopleFieldIllustrationCard = ({ data, fieldType }: PeopleFieldIllustrationCardProps) => {
  const cardData = useFragment(
    graphql`
      fragment IllustrationCard on PeopleFieldAggregate {
        name
        imageDirectoryUrl
      }
    `,
    data,
  );

  const { name } = cardData;

  const onClick = usePeopleFieldCardClickHandler(name, fieldType);
  const imageDirectoryUrl = cardData.imageDirectoryUrl
    ? `/Images/StaffDirectory${cardData.imageDirectoryUrl}`
    : undefined;

  return (
    <Bleed all="space.100">
      <IllustrationCardContainer>
        <IllustrationCardHoverContainer>
          <StyledIllustrationCard
            desktopImageUrl={imageDirectoryUrl?.concat('/illustration.svg') ?? GENERIC_ILLUSTRATION_FALLBACK_PATH}
            mobileImageUrl={
              imageDirectoryUrl?.concat('/ambient_mobile.svg') ?? GENERIC_ILLUSTRATION_FALLBACK_PATH_MOBILE
            }
            hoverColor={
              imageDirectoryUrl
                ? getIllustrationHoverColourFromImageDirectory(cardData.imageDirectoryUrl)
                : GENERIC_ILLUSTRATION_HOVER_COLOR
            }
            onClick={onClick}
          >
            <IllustrationTextContainer>
              <Tooltip content={name}>
                <IllustrationCardNameText>{name}</IllustrationCardNameText>
              </Tooltip>
              {/*
                TODO: TC-2884 hide comment count while we don't have real aggregate data
                <CountText>
                  {count}
                  {count === 1 ? ' person' : ' people'}
                </CountText> */}
            </IllustrationTextContainer>
          </StyledIllustrationCard>
        </IllustrationCardHoverContainer>
      </IllustrationCardContainer>
    </Bleed>
  );
};
