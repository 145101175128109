import { fetchQuery, graphql } from 'relay-runtime';

import { RelayEnvironment } from '@townsquare/relay-utils';

import { UserOnboardedQuery } from './__generated__/UserOnboardedQuery.graphql';

export function isUserOnboarded(): Promise<{ onboarded: boolean; error?: boolean }> {
  return fetchQuery<UserOnboardedQuery>(
    RelayEnvironment,
    graphql`
      query UserOnboardedQuery {
        currentUser {
          onboarded
        }
      }
    `,
    {},
  )
    .toPromise()
    .then(data => ({ onboarded: data?.currentUser?.onboarded ?? false }))
    .catch(() => ({ onboarded: false, error: true }));
}
