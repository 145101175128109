import React from 'react';
import { IntlShape } from 'react-intl-next';
import { fetchQuery, graphql } from 'relay-runtime';

import { filterNull } from '@townsquare/filter-type';
import { GoalName } from '@townsquare/goal-name';
import { BaseGoalIcon } from '@townsquare/icons';

import { tqlQueryFromInput } from '../tql';
import { ComparatorOperator, OptionTypes, Resolver, SupportedFiltersTypes } from '../types';

import { goalResolverLabelQuery } from './__generated__/goalResolverLabelQuery.graphql';
import { goalResolverQuery } from './__generated__/goalResolverQuery.graphql';
import { equalsAndOrFilterComparators } from './filter-comparators';

export const goalResolver = (intl: IntlShape, workspaceUuid: string): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.tql.resolvers.goal-title',
    defaultMessage: 'Goal',
    description: 'Goal title',
  }),
  type: SupportedFiltersTypes.GOAL,
  optionType: OptionTypes.SELECT,
  icon: <BaseGoalIcon label="" />,
  filterComparators: equalsAndOrFilterComparators,
  resolveOptions: ({ relayEnvironment, query }) => {
    return fetchQuery<goalResolverQuery>(
      relayEnvironment,
      graphql`
        query goalResolverQuery(
          $tql: String!
          $workspaceUuid: UUID!
          $after: String
          $first: Int
          $sorts: [GoalSortEnum]
        ) {
          goalTqlFullHierarchy(first: $first, after: $after, q: $tql, workspaceUuid: $workspaceUuid, sorts: $sorts) {
            edges {
              node {
                name
                key
                ...GoalName
              }
            }
          }
        }
      `,
      {
        tql: query
          ? tqlQueryFromInput({
              input: [{ fieldName: 'name', fieldValue: query, comparator: ComparatorOperator.LIKE }],
            })
          : '',
        workspaceUuid,
      },
      {
        fetchPolicy: 'store-or-network',
      },
    )
      .toPromise()
      .then(data => {
        return (
          data?.goalTqlFullHierarchy?.edges
            ?.filter(filterNull)
            .map(({ node }) => {
              if (node) {
                return {
                  label: node.name,
                  value: node.key,
                  // This is a deliberately choice to NOT use <GoalNameWithHovercard> for pickers
                  component: <GoalName data={node} size="small" />,
                };
              }

              return null;
            })
            .filter(filterNull) ?? []
        );
      });
  },
  resolveLabels: ({ relayEnvironment, values }) => {
    return Promise.all(
      values.map(value => {
        return fetchQuery<goalResolverLabelQuery>(
          relayEnvironment,
          graphql`
            query goalResolverLabelQuery($key: String!) {
              goalByKey(key: $key) {
                key
                name
              }
            }
          `,
          {
            key: value.toString(),
          },
          {
            fetchPolicy: 'store-or-network',
          },
        )
          .toPromise()
          .then(data => {
            const goal = data?.goalByKey;
            return {
              label: goal?.name ?? value.toString(),
              value,
            };
          });
      }),
    );
  },
});
