import { createHook, createStore } from 'react-sweet-state';

import { localStorageFacade } from '@townsquare/facade';

import { ThemeOptions } from './types';

const THEME_PREFERENCE_KEY = 'ADS-THEME';

const themeStore = createStore({
  initialState: {
    theme: localStorageFacade.getItem<ThemeOptions>(THEME_PREFERENCE_KEY) || ('light' as ThemeOptions),
  },
  actions: {
    setTheme:
      (theme: ThemeOptions) =>
      ({ setState }) => {
        localStorageFacade.setItem(THEME_PREFERENCE_KEY, theme);
        setState({ theme });
      },
  },
  name: 'theme-store',
});

export const useThemeStore = createHook(themeStore, { selector: s => s.theme });
