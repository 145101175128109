import { ToneSelection } from '@atlaskit/emoji';
import { createHook, createStore } from 'react-sweet-state';

type EmojiStoreState = {
  selectedEmojiTone?: ToneSelection;
};

const emojiStore = createStore({
  initialState: {} as EmojiStoreState,
  actions: {
    updateSelectedEmojiTone:
      (tone: ToneSelection) =>
      async ({ setState, getState }) => {
        const state = getState();
        if (state.selectedEmojiTone !== tone) {
          setState({
            ...state,
            selectedEmojiTone: tone,
          });
        }
      },
  },
  name: 'emoji-store',
});

export const useEmojiApi = createHook(emojiStore, { selector: null });
export const useUserSelectedEmojiTone = createHook(emojiStore, { selector: state => state.selectedEmojiTone });
