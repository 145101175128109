import React, { ReactNode } from 'react';

import { RoutesWithOutlet } from '@townsquare/router';
import { useNoWorkspaceExperience } from '@townsquare/workspace-store';

import { getRoutes } from '../routes';

type Props = {
  children: (routes: RoutesWithOutlet) => ReactNode;
};

export const Routes = (props: Props) => {
  const noWorkspaceExperience = useNoWorkspaceExperience();

  const routes = getRoutes({
    noWorkspaceExperience,
  });

  return <>{props.children(routes)}</>;
};
