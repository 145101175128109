/**
 * @generated SignedSource<<55a05a65269d4fbcde56746546a416d1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type TitanEapCrossFlowQuery$variables = Record<PropertyKey, never>;
export type TitanEapCrossFlowQuery$data = {
  readonly organizationsUserAdministers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly orgId: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type TitanEapCrossFlowQuery = {
  response: TitanEapCrossFlowQuery$data;
  variables: TitanEapCrossFlowQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "orgId"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "TitanEapCrossFlowQuery",
    "selections": [
      {
        "concreteType": "AdminUserOrganizationConnection",
        "kind": "LinkedField",
        "name": "organizationsUserAdministers",
        "plural": false,
        "selections": [
          {
            "concreteType": "AdminUserOrganizationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "AdminUserOrganization",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TitanEapCrossFlowQuery",
    "selections": [
      {
        "concreteType": "AdminUserOrganizationConnection",
        "kind": "LinkedField",
        "name": "organizationsUserAdministers",
        "plural": false,
        "selections": [
          {
            "concreteType": "AdminUserOrganizationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "AdminUserOrganization",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c12384eb3d70867caf60e7e30d814f8e",
    "id": null,
    "metadata": {},
    "name": "TitanEapCrossFlowQuery",
    "operationKind": "query",
    "text": "query TitanEapCrossFlowQuery {\n  organizationsUserAdministers {\n    edges {\n      node {\n        orgId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58e96ce60fc7a26ca36bf0dc319ab3cb";

export default node;
