import { token } from '@atlaskit/tokens';
import React from 'react';

import Wrapper from './Wrapper';
import { DefaultProps, Props } from './constants';

const atlasSvg = () => `
<canvas height="32" width="84" aria-hidden="true"></canvas>
<svg width="84" height="32" viewBox="0 0 84 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.0534 25.9999C4.50857 25.9999 0 21.4913 0 15.9465C0 10.4016 4.50857 5.89307 10.0534 5.89307V7.49388C5.39017 7.49388 1.60081 11.2832 1.60081 15.9465C1.60081 20.6097 5.39017 24.3991 10.0534 24.3991V25.9999Z" fill="url(#paint0_linear_27_3346)"/>
  <path d="M10.0535 25.9998V24.399C14.7167 24.399 18.5061 20.6096 18.5061 15.9464H20.1069C20.1069 21.4913 15.5983 25.9998 10.0535 25.9998Z" fill="url(#paint1_linear_27_3346)"/>
  <path d="M20.1069 15.9464H18.5061C18.5061 11.2831 14.7167 7.49376 10.0535 7.49376V5.89294C15.5983 5.89294 20.1069 10.4015 20.1069 15.9464Z" fill="url(#paint2_linear_27_3346)"/>
  <path d="M14.3376 19.0243C13.2704 18.8155 12.4275 18.4366 11.0277 17.8024C10.641 17.6323 10.2157 17.439 9.72851 17.2224C9.43464 17.091 9.16397 16.975 8.8933 16.859C6.78209 15.9232 5.62208 15.4128 4.19914 15.4128C3.20153 15.4128 2.45139 15.6835 2.03379 16.1939C1.74765 16.5342 1.63165 16.975 1.71672 17.4003L0.14684 17.7019C-0.0232949 16.8048 0.208707 15.8846 0.796444 15.1731C1.30685 14.5544 2.31219 13.812 4.19914 13.812C5.96235 13.812 7.38529 14.4461 9.54291 15.3974C9.80584 15.5134 10.0765 15.6371 10.3704 15.7608C10.8653 15.9774 11.2984 16.1707 11.6851 16.3486C13.0229 16.9518 13.7576 17.2843 14.6469 17.4544L14.3376 19.0243Z" fill="url(#paint3_linear_27_3346)"/>
  <path d="M15.8998 19.179C15.3662 19.179 14.8558 19.1326 14.3376 19.0243L14.647 17.4544C15.0646 17.5395 15.4745 17.5782 15.8998 17.5782C16.8897 17.5782 17.6398 17.3307 18.0729 16.859C18.5059 16.3872 18.5214 15.8149 18.4982 15.5133L20.0913 15.3741C20.1764 16.3486 19.8825 17.2611 19.2561 17.9416C18.7379 18.5062 17.7403 19.179 15.8998 19.179Z" fill="url(#paint4_linear_27_3346)"/>
  <path d="M10.0533 26V24.3992C12.3501 24.3992 13.719 22.056 13.719 18.1352C13.719 14.2143 12.3269 10.9276 9.47333 7.88842L10.6411 6.79028C13.7886 10.1388 15.3198 13.8509 15.3198 18.1352C15.3198 22.984 13.3014 26 10.0533 26V26Z" fill="url(#paint5_linear_27_3346)"/>
  <path d="M10.0533 26C6.59651 26 4.61676 23.185 4.61676 18.2743C4.61676 12.5439 7.12238 9.34226 9.4656 6.79797L10.6411 7.88065C7.91118 10.8503 6.21757 13.6188 6.21757 18.2743C6.21757 23.3397 8.30559 24.3992 10.0533 24.3992V26Z" fill="url(#paint6_linear_27_3346)"/>
  <path d="M5.769 16.7818C6.80259 16.7818 7.64048 15.9439 7.64048 14.9103C7.64048 13.8767 6.80259 13.0388 5.769 13.0388C4.73541 13.0388 3.89752 13.8767 3.89752 14.9103C3.89752 15.9439 4.73541 16.7818 5.769 16.7818Z" fill="#2684FF"/>
  <path d="M14.4227 20.0065C15.4563 20.0065 16.2942 19.1686 16.2942 18.135C16.2942 17.1014 15.4563 16.2635 14.4227 16.2635C13.3891 16.2635 12.5512 17.1014 12.5512 18.135C12.5512 19.1686 13.3891 20.0065 14.4227 20.0065Z" fill="#2684FF"/>
  <path d="M10.0534 8.86271C11.087 8.86271 11.9248 8.02482 11.9248 6.99123C11.9248 5.95764 11.087 5.11975 10.0534 5.11975C9.01978 5.11975 8.18188 5.95764 8.18188 6.99123C8.18188 8.02482 9.01978 8.86271 10.0534 8.86271Z" fill="#2684FF"/>
  <path d="M28.8248 20.7295L27.3916 24.5937H24.7468L31.5171 7.42627H34.4945L41.2649 24.5937H38.628L37.1868 20.6978C35.6427 21.0145 34.3124 21.165 32.9267 21.165C31.6201 21.165 30.2898 21.0066 28.8248 20.7216V20.7295ZM36.4821 18.7419L33.0058 9.33465L29.5296 18.7657C30.8124 18.9716 31.8814 19.0824 32.9267 19.0824C34.0273 19.0824 35.1518 18.9478 36.4821 18.7419Z" fill="url(#paint7_linear_27_3346)"/>
  <path d="M47.3358 22.4792C47.8584 22.4792 48.3573 22.3763 48.7453 22.2971V24.4668C48.3494 24.5697 47.906 24.6727 47.2329 24.6727C44.4376 24.6727 43.0756 23.0256 43.0756 20.5946V13.6184H40.9614V11.5278H43.0756V8.75635H45.2691V11.5278H48.7453V13.6184H45.2691V20.5471C45.2691 21.6953 45.9501 22.4792 47.3358 22.4792V22.4792Z" fill="url(#paint8_linear_27_3346)"/>
  <path d="M54.7245 24.6725C52.5786 24.6725 51.2245 23.651 51.2245 21.2517V6.06384H53.4734V20.9904C53.4734 22.1702 54.2573 22.582 55.2234 22.582C55.4609 22.582 55.6193 22.582 55.8727 22.5582V24.5696C55.6906 24.625 55.2946 24.6725 54.7245 24.6725V24.6725Z" fill="url(#paint9_linear_27_3346)"/>
  <path d="M76.5292 24.8549C74.5654 24.8549 72.9737 24.4114 71.9839 23.8888V21.5132C73.1084 22.1626 74.993 22.7961 76.6321 22.7961C78.2713 22.7961 79.2453 22.0913 79.2453 21.0698C79.2453 20.0483 78.4851 19.5019 76.0066 18.9001C73.1084 18.1954 71.881 17.0709 71.881 14.925C71.881 12.6524 73.631 11.2666 76.6084 11.2666C78.3109 11.2666 79.8471 11.6863 80.8131 12.2089V14.537C79.2453 13.753 77.9624 13.3333 76.5767 13.3333C74.9296 13.3333 74.0428 13.9114 74.0428 14.925C74.0428 15.8356 74.6683 16.4137 77.0756 16.9917C79.9738 17.6965 81.4387 18.7655 81.4387 21.046C81.4387 23.3266 80.0292 24.8628 76.5213 24.8628L76.5292 24.8549Z" fill="url(#paint10_linear_27_3346)"/>
  <path d="M69.8335 22.5822C69.2 22.5822 68.9149 22.3209 68.9149 21.4816V11.5279H66.6661V13.8243C65.8821 12.1218 64.4172 11.2666 62.5088 11.2666C58.8504 11.2666 57.0212 14.3786 57.0212 18.0607C57.0212 21.7429 58.8029 24.8549 62.3346 24.8549C64.5597 24.8549 65.8584 23.8888 66.7057 22.1942C66.9036 23.8096 67.9172 24.6648 69.3584 24.6648C69.8177 24.6648 70.1344 24.6252 70.3561 24.5619V22.5506C70.0948 22.5743 70.0711 22.5743 69.8335 22.5743V22.5822ZM66.6661 18.5834C66.6661 21.4816 64.8606 22.7644 62.8493 22.7644C60.5213 22.7644 59.2701 21.1965 59.2701 18.0607C59.2701 14.925 60.5767 13.3571 63.1106 13.3571C65.019 13.3571 66.6661 14.6399 66.6661 17.5381V18.5834Z" fill="url(#paint11_linear_27_3346)"/>
  <defs>
    <linearGradient id="paint0_linear_27_3346" x1="5.60671" y1="26.17" x2="4.8411" y2="6.0864" gradientUnits="userSpaceOnUse">
      <stop offset="0.5" stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0052CC"/>
    </linearGradient>
    <linearGradient id="paint1_linear_27_3346" x1="20.277" y1="16.828" x2="11.3759" y2="26.0308" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0E65DF"/>
      <stop offset="1" stop-color="#0255CF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_27_3346" x1="15.0802" y1="5.89294" x2="15.0802" y2="15.9464" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2684FF"/>
      <stop offset="0.59" stop-color="#156EE8"/>
      <stop offset="1" stop-color="#0E65DF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_27_3346" x1="0.100439" y1="16.4182" x2="14.6469" y2="16.4182" gradientUnits="userSpaceOnUse">
      <stop offset="0.26" stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0E65DF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_27_3346" x1="15.9462" y1="18.7691" x2="19.1246" y2="15.3896" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0052CC"/>
      <stop offset="1" stop-color="#0E65DF"/>
    </linearGradient>
    <linearGradient id="paint5_linear_27_3346" x1="12.3965" y1="26" x2="12.3965" y2="6.79028" gradientUnits="userSpaceOnUse">
      <stop offset="0.36" stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0052CC"/>
    </linearGradient>
    <linearGradient id="paint6_linear_27_3346" x1="7.62505" y1="26" x2="7.62505" y2="6.79797" gradientUnits="userSpaceOnUse">
      <stop offset="0.06" stop-color="#2684FF"/>
      <stop offset="0.58" stop-color="#0052CC"/>
      <stop offset="1" stop-color="#2684FF"/>
    </linearGradient>
    <linearGradient id="paint7_linear_27_3346" x1="24.6314" y1="24.2604" x2="65.2698" y2="42.1789" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0052CC"/>
    </linearGradient>
    <linearGradient id="paint8_linear_27_3346" x1="24.7468" y1="24.8935" x2="45.3595" y2="44.226" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0052CC"/>
    </linearGradient>
    <linearGradient id="paint9_linear_27_3346" x1="24.7467" y1="24.1018" x2="32.9201" y2="37.2025" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0052CC"/>
    </linearGradient>
    <linearGradient id="paint10_linear_27_3346" x1="24.7469" y1="24.8937" x2="55.8248" y2="45.3244" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0052CC"/>
    </linearGradient>
    <linearGradient id="paint11_linear_27_3346" x1="24.7469" y1="24.8937" x2="67.0342" y2="41.1364" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2684FF"/>
      <stop offset="1" stop-color="#0052CC"/>
    </linearGradient>
  </defs>
</svg>
`;

export const TeamCentralLogo = (props: Props) => {
  return (
    <div
      style={{
        // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
        display: 'flex',
        // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
        alignItems: 'center',
        // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
        position: 'relative',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore typedefs for css vars are not supported
        // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
        '--tc-logo-text-fill': token('color.text'),
      }}
    >
      {/* Disable token here because this is product logo, which is not subjected to dark / light mode */}
      {/* eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage */}
      <Wrapper {...DefaultProps} {...props} iconGradientStart="#0052CC" iconGradientStop="#2684FF" svg={atlasSvg} />
    </div>
  );
};
