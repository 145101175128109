import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link as RRRLink, type LinkProps as RRRLinkProps } from 'react-resource-router';

import { HomepageRouteType } from '../home/types';

import { useGetEffectiveTo } from './useGetEffectiveTo';
import { useRouteParams } from './useRouteParams';

type LinkProps = Omit<RRRLinkProps, 'to'> & {
  to: HomepageRouteType;
};

export function Link(props: LinkProps) {
  const effectiveTo = useGetEffectiveTo(props.to);
  const params = useRouteParams(props.params, effectiveTo.routeType);
  return <RRRLink {...props} to={effectiveTo} params={params} />;
}
