import { captureException } from '@sentry/react';
import type { RequestParameters, Variables } from 'relay-runtime';

const VARIABLE_PARAMS_TO_CHECK = ['workspaceUUID', 'workspaceUuid'];
export const captureMissingWorkspaceUuid = (request: RequestParameters, variables: Variables) => {
  VARIABLE_PARAMS_TO_CHECK.forEach(param => {
    if (param in variables && (variables[param] === null || variables[param] === '')) {
      captureException(
        new Error(
          'InvalidGraphQLRequestException: Atlas tried to make GraphQL request with missing workspaceUuid when needed',
        ),
        {
          level: 'error',
          tags: {
            operationName: request.name,
          },
          extra: {
            requestVariables: variables,
          },
        },
      );
    }
  });
};
