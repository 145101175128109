import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionBarItem = styled.div({
  display: 'flex',
  alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionBarButton = styled(Button)({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  font: `${token('font.body.UNSAFE_small')} !important`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: ${token('space.050')};

  button {
    color: ${token('color.text.subtle')} !important;
  }

  > * + *:before {
    font: ${token('font.body.UNSAFE_small')};
    margin-left: ${token('space.050')};
    margin-right: ${token('space.050')};
    content: '\\2022';
    color: ${token('color.text.subtle')};
  }

  > ${ActionBarItem} {
    display: contents;
  }
`;
