import Button from '@atlaskit/button';
import Image from '@atlaskit/image';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl-next';

import { useCreateTeamModal } from '@townsquare/create-team-modal';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { useRouterActions } from '@townsquare/router/primitives';
import { useFeatureGate } from '@townsquare/stat-sig/gate';
import { useWorkspaceType } from '@townsquare/workspace-store/hooks';

import { OnboardingPopup } from '../OnboardingPopup';

import TeamPopupHeader from './TeamPopupHeader.svg';

export const TeamPopup = () => {
  const workspaceType = useWorkspaceType();
  const isEnabled = useFeatureGate('enable_home_changeboarding') && workspaceType === 'GLOBAL_EXPERIENCE';
  const { pushTo } = useRouterActions();
  const [createTeamModal, createTeamModalTrigger] = useCreateTeamModal({
    analyticsCtx: { source: 'staffDirectory' },
    onCreate: team => pushTo(teamProfileRoute, { params: { id: team.id } }),
  });

  if (!isEnabled) {
    return null;
  }

  return (
    <ErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        <OnboardingPopup
          dismissAction
          onboardingKey="TEAMS_DIRECTORY"
          coveringKind="team"
          highlightImage={<Image src={TeamPopupHeader} />}
          heading={
            <FormattedMessage
              id="townsquare.onboarding.team-popup.team-title"
              description="Title of introductory popup explaining the purpose of teams"
              defaultMessage="Build connections with teams"
            />
          }
          detail={
            <FormattedMessage
              id="townsquare.onboarding.team-popup.team-description"
              description="Explanation of the purpose of Teams"
              defaultMessage="A team allows you to group people who are working towards a common goal. Use teams to simplify communication and collaboration across projects and platforms. <a>Learn more about teams.</a>"
              values={{
                a: (chunks: string) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.atlassian.com/atlassian-account/docs/what-is-an-atlassian-team/"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          }
          primaryCTA={
            <Button id="team-popup-create-button" appearance="primary" onClick={() => createTeamModalTrigger()}>
              <FormattedMessage
                id="townsquare.onboarding.team-popup.create-team"
                description="Prompt to create a team from popup onboarding"
                defaultMessage="Create a team"
              />
            </Button>
          }
        />
        {createTeamModal}
      </Suspense>
    </ErrorBoundary>
  );
};
