import { payloadPublisher } from '@atlassian/ufo/publisher';
import AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import { version } from 'react';

import { getEnv, getReleaseVersion } from '@townsquare/config';
import { ANALYTICS_PRODUCT_IDENTIFIER, PRODUCT_IDENTIFIER } from '@townsquare/config/constants';

const SMART_USER_PICKER = 'smart-user-picker';
const USER_PICKER = 'user-picker';
const MENTION = 'mention';
const REMOTE_MODULE_LOADER = 'remote-module-loader';
const AVP = 'avp';
const POST_OFFICE = 'post-office';

const allowedNotifComponents = ['notificationFullPage', 'notificationList'];

const allowedNotificationListExperiences = [
  'notification-list-render-notifications-without-content',
  'notification-list-render-notifications-with-content',
];

export const initUFO = (
  analyticsClient: AnalyticsWebClient,
  product: `${typeof ANALYTICS_PRODUCT_IDENTIFIER}` | `${typeof PRODUCT_IDENTIFIER}` = ANALYTICS_PRODUCT_IDENTIFIER,
) => {
  // Uncomment the line below to see UFO events in the dev console of your browser - ufologger is an export from @atlassian/ufo
  // ufologger.enable();
  payloadPublisher.setup({
    product,
    app: {
      version: { web: getReleaseVersion() },
      framework: { name: 'react', version },
    },
    region: getEnv(),
    gasv3: analyticsClient,
    allowlist: (key: string) => {
      if (key.startsWith(`${product}.fe`)) {
        return true;
      }

      // For the initial rollout of UFO just the smart-experiences metrics will be published,
      // as we want to be sure that the UFO backend services can deal with the traffic.
      const eventKeyProperties = key.split('.');
      if (
        eventKeyProperties.includes(SMART_USER_PICKER) ||
        eventKeyProperties.includes(USER_PICKER) ||
        eventKeyProperties.includes(MENTION) ||
        eventKeyProperties.includes(AVP)
      ) {
        return true;
      }

      // Determine if the feature flag was true and the event received matched the list of predefined emoji expereinces list
      if (eventKeyProperties.some(eventKey => eventKey.startsWith('emoji-'))) {
        return true;
      }

      // Determine if the feature flag was true and the event received matched the list of predefined reaction expereinces list
      if (eventKeyProperties.some(eventKey => eventKey.startsWith('reactions-') || eventKey.startsWith('reaction-'))) {
        return true;
      }

      if (
        allowedNotifComponents.includes(eventKeyProperties[3]) &&
        allowedNotificationListExperiences.includes(eventKeyProperties[4])
      ) {
        return true;
      }

      if (key.match(/^platform\.fe\..*\.recentWork\..*/g) !== null) {
        return true;
      }

      if (key.match(/^platform\.fe\..*\.search-dialog\..*/g) !== null) {
        return true;
      }

      if (key.match(/^platform\.fe\..*\.search-page\..*/g) !== null) {
        return true;
      }

      if (eventKeyProperties.includes(REMOTE_MODULE_LOADER) || eventKeyProperties.includes(POST_OFFICE)) {
        return true;
      }

      return false;
    },
  });
};
