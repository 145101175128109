import React from 'react';
import styled from 'styled-components';

const TeamMenuWrapper: React.FC = ({ children }) => <MenuGroup>{children}</MenuGroup>;

const MenuGroup = styled.div({
  width: '320px',
  minHeight: '152px',
  display: 'block',
  alignItems: 'center',
  justifyContent: 'center',
});

export default TeamMenuWrapper;
