import React from 'react';
import { IntlShape } from 'react-intl-next';

import { LocationIcon } from '../../components/icons/Location';
import { Resolver, SupportedFiltersTypes } from '../../types';
import { isLikeOrFilterComparators } from '../filter-comparators';

import { PeopleFieldType } from './__generated__/createPeopleFieldResolverQuery.graphql';
import { createPeopleFieldSuggestionResolver } from './create-people-field-resolver';
import { PeopleFieldSuggestionResolverOptions } from './types';

const locationType: PeopleFieldType = 'LOCATION';

export const locationResolver = (intl: IntlShape, { workspaceId }: PeopleFieldSuggestionResolverOptions): Resolver =>
  createPeopleFieldSuggestionResolver(
    intl.formatMessage({
      id: 'townsquare.packages.tql.resolvers-people-and-teams-location',
      defaultMessage: 'Location',
      description: 'Location title',
    }),
    SupportedFiltersTypes.LOCATION,
    <LocationIcon label="" />,
    workspaceId,
    locationType,
    isLikeOrFilterComparators,
  );
