import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import React from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
// Passed to <Button> component, link is generated by useGeneratePath (primitive hook)
// eslint-disable-next-line no-restricted-imports
import { Link } from 'react-resource-router';

import { ActionBarItem } from '@townsquare/action-bar';
import { formatNiceDateTime, formatUserRelativeDistance, tzUserTimezoneDate } from '@townsquare/date-utils';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { useDateLocale } from '@townsquare/i18n';
import { projectRoute } from '@townsquare/project-view/route';
import { generatePath, useRouteDependencies } from '@townsquare/router/primitives';
import { UserAvatar } from '@townsquare/user-avatar';

import { LearningReactions } from './LearningReactions';
import { LearningModifierSignatures_data$key } from './__generated__/LearningModifierSignatures_data.graphql';
import { AvatarWrapper, LearningMetaDataContainer } from './styles';
import { LearningType } from './util';

interface LearningModifiedSignaturesProps {
  type: LearningType;
  data: LearningModifierSignatures_data$key;
  hideReactions?: boolean;
  noLink?: boolean;
}

export const LearningModifierSignatures = (props: LearningModifiedSignaturesProps) => {
  const intl = useIntl();
  const dateLocale = useDateLocale();

  const highlightModifiers = useFragment(
    graphql`
      fragment LearningModifierSignatures_data on Highlight {
        uuid
        ari
        project {
          key
          ari
        }
        goal {
          key
          ari
        }
        creator {
          pii {
            name
          }
          ...UserAvatar @arguments(showName: false)
        }
        creationDate
        lastEditedBy {
          pii {
            name
          }
          ...UserAvatar @arguments(showName: false)
        }
        lastEditedDate
      }
    `,
    props.data,
  );

  const mostRecentlyModifiedBy = highlightModifiers.lastEditedBy ?? highlightModifiers.creator;
  const entityType = highlightModifiers.goal ? 'GOAL' : 'PROJECT';
  const entityKey = entityType === 'GOAL' ? highlightModifiers.goal?.key : highlightModifiers.project?.key;
  const entityAri = entityType === 'GOAL' ? highlightModifiers.goal?.ari : highlightModifiers.project?.ari;

  const routeDependencies = useRouteDependencies();

  if (!mostRecentlyModifiedBy) {
    return null;
  }

  const { pii } = mostRecentlyModifiedBy;
  const isCreator = !highlightModifiers.lastEditedBy;
  const learningType = props.type;

  const authorText = intl.formatMessage(
    {
      id: 'townsquare.learnings.learning-modifier-signature.author-text',
      description: 'Learning modifier author text',
      defaultMessage:
        '{learningType, select, DECISION {Decision} RISK {Risk} other {Learning}} {isCreator, select, true {created by} other {last edited by}} {name}',
    },
    {
      learningType,
      isCreator,
      name: pii?.name,
    },
  );

  const lastModifiedDate = isCreator ? highlightModifiers.creationDate : highlightModifiers.lastEditedDate;
  const relativeDate = formatUserRelativeDistance(lastModifiedDate ?? '', dateLocale);

  const zonedCreationDate = highlightModifiers.creationDate && tzUserTimezoneDate(highlightModifiers.creationDate);
  const creationDate = zonedCreationDate ? formatNiceDateTime(zonedCreationDate, dateLocale) : relativeDate;
  const editedTooltip = highlightModifiers.lastEditedDate
    ? ` (${intl.formatMessage(
        {
          id: 'townsquare.learnings.learning-modifier-signature.edited-tooltip',
          description: 'Edited on date tooltip',
          defaultMessage: 'Edited on {date}',
        },
        {
          date: formatNiceDateTime(tzUserTimezoneDate(highlightModifiers.lastEditedDate), dateLocale),
        },
      )}`
    : '';
  const createdTooltipContent = editedTooltip
    ? intl.formatMessage(
        {
          id: 'townsquare.learnings.learning-modifier-signature.created-tooltip',
          description: 'Created on date tooltip',
          defaultMessage: 'Created {date}',
        },
        { date: creationDate },
      )
    : creationDate;

  const modifiedContextualtext = `${authorText} ${relativeDate}`;

  const linkEntityRoute = entityType === 'GOAL' ? goalViewRoute : projectRoute;
  const learningLink = generatePath(linkEntityRoute, {
    baseParams: {
      id: entityKey,
      tabName: `${props.type.toLowerCase()}s`,
      resourceId: highlightModifiers.uuid,
    },
    ...routeDependencies,
  });

  const userText = <small>{modifiedContextualtext}</small>;

  return (
    <LearningMetaDataContainer>
      <ActionBarItem>
        <AvatarWrapper>
          <UserAvatar size="small" data={mostRecentlyModifiedBy} />
        </AvatarWrapper>
        <Tooltip content={createdTooltipContent + editedTooltip}>
          {props.noLink ? (
            userText
          ) : (
            <Button
              // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
              className="deeplink-learnings-meta-data"
              appearance="subtle-link"
              spacing="none"
              component={Link}
              href={learningLink}
            >
              {userText}
            </Button>
          )}
        </Tooltip>
      </ActionBarItem>
      {!props.hideReactions && (
        <ActionBarItem>
          <LearningReactions defaultReactionsEnabled containerAri={entityAri} learningAri={highlightModifiers.ari} />
        </ActionBarItem>
      )}
    </LearningMetaDataContainer>
  );
};
