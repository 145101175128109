import { token } from '@atlaskit/tokens';

import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { ScoreStatusMap } from './types';
import { AtlassianHomeStatusLozengeStyles, LegacyStatusLozengeStyles } from './utils';

const legacyClassicScoreMap: ScoreStatusMap = {
  default: {
    light: token('color.background.neutral'),
    dark: token('color.background.neutral.hovered'),
    background: token('color.background.neutral.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.gray.subtler'),
    iconForeground: token('color.icon.accent.gray'),
    lozengeStyle: LegacyStatusLozengeStyles.DEFAULT,
  },
  pending: {
    light: token('color.background.neutral'),
    dark: token('color.background.neutral.hovered'),
    background: token('color.background.neutral.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.gray.subtler'),
    iconForeground: token('color.icon.accent.gray'),
    lozengeStyle: LegacyStatusLozengeStyles.DEFAULT,
  },
  off_track: {
    light: token('color.background.danger'),
    dark: token('color.background.danger.hovered'),
    background: token('color.background.danger.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.red.subtler'),
    iconForeground: token('color.icon.accent.red'),
    lozengeStyle: LegacyStatusLozengeStyles.OFF_TRACK,
  },
  at_risk: {
    light: token('color.background.warning'),
    dark: token('color.background.warning.hovered'),
    background: token('color.background.accent.orange.bolder'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.yellow.subtler'),
    iconForeground: token('color.icon.accent.orange'),
    lozengeStyle: LegacyStatusLozengeStyles.AT_RISK,
  },
  on_track: {
    light: token('color.background.success'),
    dark: token('color.background.success.hovered'),
    background: token('color.background.success.bold'),
    text: token('color.text.inverse'),
    icon: token('color.background.accent.green.subtler'),
    iconForeground: token('color.icon.accent.green'),
    lozengeStyle: LegacyStatusLozengeStyles.ON_TRACK,
  },
};

const atlassianHomeScoreMap: ScoreStatusMap = {
  default: {
    light: token('color.background.neutral'),
    dark: token('color.background.neutral.hovered'),
    background: token('color.background.neutral.bold'),
    text: token('color.text'),
    icon: 'transparent',
    iconForeground: '#8C8F97',
    lozengeStyle: AtlassianHomeStatusLozengeStyles.DEFAULT,
  },
  pending: {
    light: token('color.background.neutral'),
    dark: token('color.background.neutral.hovered'),
    background: token('color.background.neutral.bold'),
    text: token('color.text'),
    icon: 'transparent',
    iconForeground: '#8C8F97',
    lozengeStyle: AtlassianHomeStatusLozengeStyles.DEFAULT,
  },
  off_track: {
    light: token('color.background.danger'),
    dark: token('color.background.danger.hovered'),
    background: '#F15B50',
    text: '#292A2E',
    icon: 'transparent',
    iconForeground: token('color.icon.danger'),
    lozengeStyle: AtlassianHomeStatusLozengeStyles.OFF_TRACK,
  },
  at_risk: {
    light: token('color.background.warning'),
    dark: token('color.background.warning.hovered'),
    background: '#F68909',
    text: '#292A2E',
    icon: 'transparent',
    iconForeground: token('color.icon.warning'),
    lozengeStyle: AtlassianHomeStatusLozengeStyles.AT_RISK,
  },
  on_track: {
    light: token('color.background.success'),
    dark: token('color.background.success.hovered'),
    background: '#82B536',
    text: '#292A2E',
    icon: 'transparent',
    iconForeground: token('color.icon.success'),
    lozengeStyle: AtlassianHomeStatusLozengeStyles.ON_TRACK,
  },
};

export const useGoalScoreMap = () => {
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();
  return enableHomeVisualRefresh ? atlassianHomeScoreMap : legacyClassicScoreMap;
};
