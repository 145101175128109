import { createRelayResource } from '@townsquare/relay-utils';

import NoWorkspacePersonalSettingsViewQuery, {
  NoWorkspacePersonalSettingsViewQuery as NoWorkspacePersonalSettingsViewQueryType,
} from '../components/__generated__/NoWorkspacePersonalSettingsViewQuery.graphql';

export const noWorkspacePersonalSettingsPageResource = createRelayResource<NoWorkspacePersonalSettingsViewQueryType>({
  getQuery: () => {
    return {
      query: NoWorkspacePersonalSettingsViewQuery,
    };
  },
});
