import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const Card = styled.div<{ isDisabled?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: ${token('space.300')};
  background: ${token('elevation.surface.raised')};
  border-radius: 3px;
  box-shadow: ${token('elevation.shadow.raised')};
  position: relative;

  & + & {
    margin-top: ${token('space.200')};
  }

  ${({ isDisabled }) =>
    isDisabled
      ? `
          opacity: 0.5;
          pointer-events: none;
        `
      : ''};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const QuestionOrReplyCard = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${token('space.300')};
  background: ${token('elevation.surface.raised')};
  box-shadow: ${token('elevation.shadow.raised')};
  position: relative;

  & + & {
    margin-top: ${token('space.025')};
  }
`;
