import React from 'react';
import { createContext } from 'react';

type BannerHeightContextType = {
  height: number;
};

export const BannerHeightContext = createContext<BannerHeightContextType>({
  height: 0,
});

export function useSiteBanner() {
  return React.useContext(BannerHeightContext);
}
