import { fetchQuery, graphql } from 'relay-runtime';

import { AggRelayEnvironment } from '@townsquare/relay-utils';

import { ResolveUserQuery } from './__generated__/ResolveUserQuery.graphql';
import { ResolvedUser } from './types';
export function UserById(accountId: string): Promise<ResolvedUser> {
  return fetchQuery<ResolveUserQuery>(
    AggRelayEnvironment,
    graphql`
      query ResolveUserQuery($accountId: ID!) {
        user(accountId: $accountId) {
          accountId
          name
          picture
        }
      }
    `,
    {
      accountId,
    },
  )
    .toPromise()
    .then(data => ({
      aaid: data?.user?.accountId ?? accountId,
      pii: {
        name: data?.user?.name ?? undefined,
        picture: data?.user?.picture ?? undefined,
      },
    }))
    .catch(() => ({
      aaid: accountId,
      pii: {},
    }));
}
