import { SITE_IDENTIFIER_PLACEHOLDER } from '@townsquare/config';
import { location } from '@townsquare/facade';
import { isGateEnabled } from '@townsquare/stat-sig/gate';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';

const SITE_ROUTE_REGEX = new RegExp(`^/o/(?<orgIdentifier>[0-9a-zA-Z-]+)/s/(?<siteIdentifier>[a-zA-Z0-9%+-]+).*$`);

type FetchUrlConfig = {
  defaultUrl: string;
  multiRegionUrl?: string;
  cloudId?: string;
};

export const resolveFetchUrl = ({ defaultUrl, multiRegionUrl, cloudId }: FetchUrlConfig) => {
  // Persistence and checks for sharded routing enablement
  // https://switcheroo.atlassian.com/ui/gates/266723ac-a6d8-400f-858f-8a156a065383
  const isEnabled = isGateEnabled('titan_enable_multi_region_stargate_url');
  const mureEnabled = window.localStorage.getItem('multiRegionRoutingEnabled');
  const searchParams = Object.fromEntries(createSearchParamsFromCurrentUrl());

  const match = SITE_ROUTE_REGEX.exec(location().pathname);

  if (multiRegionUrl && (isEnabled || (mureEnabled ?? Number(searchParams.mureRouting)))) {
    window.localStorage.setItem('multiRegionRoutingEnabled', 'true');

    // If we know we're in a sited context, always use the sharded url
    const siteIdentifier = match?.groups?.siteIdentifier;

    if (siteIdentifier) {
      return multiRegionUrl.replace(SITE_IDENTIFIER_PLACEHOLDER, encodeURI(siteIdentifier));
    } else if (cloudId) {
      return multiRegionUrl.replace(SITE_IDENTIFIER_PLACEHOLDER, encodeURI(cloudId));
    }
  }

  return defaultUrl;
};
