import { useCallback } from 'react';
import { Action, createActionsHook, createStateHook, createStore } from 'react-sweet-state';

import { localStorageFacade } from '@townsquare/facade';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import { useHelp } from '../Help/hooks';

import type { PANEL_ID } from './constants';

type State = {
  currentPanel: PANEL_ID | null;
  // Width is only used in NavV3; in NavV4, width is controlled by design-system
  // remove width when nav v4 is rolled out 100%
  width: number;
};

const STORAGE_KEY = 'statusUIRightSidebarPanel';
const setRightSidebarLocalStorage = (panelId: PANEL_ID | null) => {
  if (panelId) {
    localStorageFacade.setItem(STORAGE_KEY, panelId);
  } else {
    localStorageFacade.removeItem(STORAGE_KEY);
  }
};

const DEFAULT_WIDTH = 440;

const rightSidebarStore = createStore({
  initialState: {
    currentPanel: localStorageFacade.getItem(STORAGE_KEY) ?? null,
    width: DEFAULT_WIDTH,
  } as State,
  actions: {
    openPanel:
      (panel: PANEL_ID, config?: { width?: number }): Action<State> =>
      ({ setState }) => {
        setState({ currentPanel: panel, width: config?.width ?? DEFAULT_WIDTH });
        setRightSidebarLocalStorage(panel);
      },
    closePanel:
      (): Action<State> =>
      ({ setState }) => {
        setState({ currentPanel: null });
        setRightSidebarLocalStorage(null);
      },
    setPanelWidth:
      (width: number): Action<State> =>
      ({ setState }) => {
        setState({ width });
      },
  },
});

const useRightSidebarRSSState = createStateHook(rightSidebarStore);
export const useRightSidebarState = (): State => {
  const { isOpen: isUseHelpOpen } = useHelp();
  const state = useRightSidebarRSSState();

  if (!isGateEnabled('rovo_chat_in_atlas_sidebar')) {
    return { currentPanel: isUseHelpOpen ? 'help' : null, width: 0 };
  }

  return state;
};

const useRightSidebarRSSActions = createActionsHook(rightSidebarStore);
export const useRightSidebarActions = () => {
  const { openPanel: openPanelStore, closePanel: closePanelStore, setPanelWidth } = useRightSidebarRSSActions();
  const { setIsOpen: setHelpContextIsOpen } = useHelp();

  const closePanel = useCallback(() => {
    if (!isGateEnabled('rovo_chat_in_atlas_sidebar')) {
      setHelpContextIsOpen(false);
      return;
    }

    closePanelStore();
  }, [closePanelStore, setHelpContextIsOpen]);

  const openPanel = useCallback(
    (panelId: PANEL_ID) => {
      if (!isGateEnabled('rovo_chat_in_atlas_sidebar')) {
        if (panelId === 'help') {
          setHelpContextIsOpen(true);
        }
        return;
      }

      openPanelStore(panelId);
    },
    [setHelpContextIsOpen, openPanelStore],
  );

  return {
    openPanel,
    closePanel,
    setPanelWidth,
  };
};
