import React from 'react';
import { useIntl } from 'react-intl-next';

import { ERROR_IMAGE_GENERIC } from '../../ErrorResources';

import BasicError from './BasicError';

export default () => {
  const intl = useIntl();

  const textItems = [
    intl.formatMessage({
      id: 'townsquare.error-state.network-4xx-error.message',
      defaultMessage: 'Something went wrong.',
      description: 'Error message displayed when a 4xx error occurs',
    }),
    intl.formatMessage({
      id: 'townsquare.error-state.network-4xx-error.description',
      defaultMessage: 'Try refreshing the page.',
      description: 'Error description displayed when a 4xx error occurs',
    }),
  ];
  return <BasicError img={ERROR_IMAGE_GENERIC} errorText={textItems} />;
};
