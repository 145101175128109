import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import { getPeopleFieldType, getScreenFromStringOrDefault } from '../components/ScreenType';
import StaffDirectoryViewQuery, {
  StaffDirectoryViewQuery as StaffDirectoryViewQueryType,
} from '../components/__generated__/StaffDirectoryViewQuery.graphql';
import { ScreenType } from '../components/screens/ScreenType';
import { DirectoryTeamSortEnum } from '../components/screens/__generated__/SearchTeamsPaginationQuery.graphql';

import { appendEmptyTeamSearchToTql } from './utils';

const defaultTeamSort: DirectoryTeamSortEnum[] = ['NAME_ASC'];

export const staffDirectoryResource = createRelayResource<StaffDirectoryViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const enableKudosDirectory = isGateEnabled('atlas-enable-kudos-directory');
    const context = resourceContext as ResourceContext;
    const user = context.user;

    const screen = getScreenFromStringOrDefault(routerContext.query.screen);

    // Use NameAsc as default sort for search teams
    const searchTeamsSort: DirectoryTeamSortEnum[] =
      routerContext.query.sort == 'NAME_DESC' ? ['NAME_DESC'] : defaultTeamSort;

    const isSearchAllScreen = screen == ScreenType.SEARCH_ALL;
    const isSearchPeopleScreen = screen == ScreenType.SEARCH_PEOPLE;
    const isSearchTeamsScreen = screen == ScreenType.SEARCH_TEAMS;
    const isBrowseAllScreen = screen == ScreenType.BROWSE_ALL;
    const isBrowsePeopleScreen =
      screen == ScreenType.BROWSE_DEPARTMENTS ||
      screen == ScreenType.BROWSE_LOCATIONS ||
      screen == ScreenType.BROWSE_JOB_TITLES;
    const isSearchKudosScreen = screen == ScreenType.BROWSE_KUDOS;

    // We define a cachekey so that it only updates when the screen changes, not tql etc (those updates are handled by the components)
    const cacheKey = `relay-staff-directory-${screen.toString()}`;

    // TODO: to refactor when more screens are added
    const teamQuery = routerContext.query.tql ?? (isBrowseAllScreen ? `member = ${user.accountId}` : '');
    const userQuery = routerContext.query.tql ?? '';
    const kudosQuery = routerContext.query.tql ?? '';

    // we get fewer results for the search all screen as it displays results from people and from teams. (We also fetch more than we intend to display as CPUS has unreliable counting)
    const numResultsToFetch = isSearchAllScreen ? 12 : 20;
    return {
      query: StaffDirectoryViewQuery,
      variables: {
        teamQuery: appendEmptyTeamSearchToTql(teamQuery),
        userQuery,
        kudosQuery,
        first: numResultsToFetch,
        searchTeamsSort: searchTeamsSort,
        organisationId: context.workspace?.organisationId ?? '',
        workspaceId: context.workspace?.globalId ?? '',
        cloudId: context.workspace?.cloudId ?? '',
        peopleFieldType: getPeopleFieldType(screen),
        isSearchAllScreen,
        isSearchPeopleScreen,
        isSearchTeamsScreen,
        isBrowseAllScreen,
        isBrowsePeopleScreen,
        isSearchKudosScreen,
        enableKudosDirectory,
      },
      cacheKey: cacheKey,
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
