import { IconButton } from '@atlaskit/atlassian-navigation';
import React, { useState } from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';
import { NewsfeedIcon } from '@townsquare/icons';

import { HeadwayBadge, ReleaseNoteContainer } from './styles';

const HeadwayReleaseNoteConfig = {
  selector: '#headway-badge',
  trigger: '#headway-app-trigger',
  account: 'Jr5k67',
  enabled: true,
  translations: {
    title: 'Latest news',
  },
};

export const ReleaseNote = () => {
  const [isOpen, setIsOpen] = useState(false);
  const analytics = useAnalytics();
  const intl = useIntl();

  useOnMount(() => {
    if (window.Headway) {
      window.Headway.init({
        ...HeadwayReleaseNoteConfig,
        callbacks: {
          onWidgetReady: (widget: any) =>
            analytics.track('headwayBadge', 'shown', {
              unseenCount: widget.getUnseenCount(),
            }),
          onShowWidget: () => {
            setIsOpen(true);
            void analytics.ui('headwayWidget', 'clicked');
          },
          onShowDetails: ({ id }: any) => analytics.ui('headwayChangelogItem', 'clicked', { id }),
          onReadMore: ({ id }: any) => analytics.ui('headwayReadMoreLink', 'clicked', { id }),
          onHideWidget: () => setIsOpen(false),
        },
      });
    }
  });

  // Don't render this if headway didn't load properly
  return window.Headway ? (
    <ReleaseNoteContainer>
      <HeadwayBadge id="headway-badge" />
      <div id="headway-app-trigger">
        <IconButton
          isSelected={isOpen}
          icon={<NewsfeedIcon label="" />}
          tooltip={intl.formatMessage({
            id: 'townsquare.navigation.release-note.tooltip',
            description: 'Tooltip for the release note icon',
            defaultMessage: 'Release notes',
          })}
        />
      </div>
    </ReleaseNoteContainer>
  ) : null;
};
