import * as Sentry from '@sentry/react';
import { Event, Exception } from '@sentry/types';

import { navigator } from '@townsquare/facade';

const ERRORS_TO_IGNORE: string[] = [
  /* Just in case if there's any specific error we want to exclude. Add it here */
];

const POLLINATOR_USERS_TO_IGNORE: string[] = [
  '63464e903f4224a46db29195', // SmartLink Integration Pollinator
  '627c63cc23d61e006fc5234f', // rw-polli-prod-bot Pollinator
  '5eab82040590bb0b7bf95f5c', // id-pfdirectory-bot Pollinator
];

function isPollinatorNetworkFailure(event: Event): boolean {
  if (
    navigator().userAgent.match(/chrome-synthetic-test/) ||
    POLLINATOR_USERS_TO_IGNORE.includes((event.user?.id as string) ?? '')
  ) {
    return event.exception?.values?.some(e => e.value?.includes('Failed to fetch')) ?? false;
  }
  return false;
}

export function sentryFilterEvents(event: Event): Event | null {
  const exceptions = event?.exception?.values || [];
  const exception = exceptions.length > 0 ? exceptions[0] : undefined;

  if (isPollinatorNetworkFailure(event)) {
    return null;
  }

  if (exception) {
    const errorMatches = ERRORS_TO_IGNORE.filter(errorToIgnore => exception.value?.indexOf(errorToIgnore) !== -1);
    if (errorMatches.length !== 0) {
      return null;
    }

    exceptions.forEach(sanitizeError);
  }
  return event;
}

/**
 * When breadcrumbs are too large, Sentry rejects the request with a 413
 * This method filter outs some breadcrumbs that are not as useful for debugging to reduce the noise
 * https://github.com/getsentry/sentry-javascript/issues/2798
 */
export const filterBreadcrumb = (breadcrumb: Sentry.Breadcrumb): Sentry.Breadcrumb | null => {
  try {
    if (
      (breadcrumb.category === 'console' && breadcrumb.level === 'log') ||
      (typeof breadcrumb.message === 'string' &&
        (breadcrumb.message.match(/The pseudo class ":first-child" is potentially unsafe/) ||
          breadcrumb.message.match(/componentWillMount has been renamed/) ||
          breadcrumb.message.match(/componentWillReceiveProps has been renamed/))) ||
      (typeof breadcrumb.data?.url === 'string' && breadcrumb.data?.url.match(/reactions\/reactions\/view$/))
    ) {
      return null;
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { breadcrumb } });
  }

  return breadcrumb;
};

/**
 * Some users are embedding the product on App aggregators like Rambox Pro or Shift.
 * This causes exception's stacktrace to contain the fullpath of user's disk.
 * We need to remove that path due to GDPR concerns.
 * Luckily, all these aggregators use Electron which deploys apps inside app.asar.
 */
export function sanitizeError(e: Exception) {
  if (e.stacktrace && e.stacktrace.frames) {
    e.stacktrace.frames.forEach(frame => {
      if (frame.filename) {
        frame.filename = frame.filename.replace(/^.*app\.asar/, '');
      }
    });
  }
}
