/**
 * @generated SignedSource<<a4527d34db3d3561dd9a0731dccfca72>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type GoalUpdatesAsideViewQuery$variables = {
  completedGoalsTqlQuery: string;
  isNavRefreshEnabled: boolean;
  newGoalsTqlQuery: string;
  ownGoalsTqlQuery: string;
  workspaceUUID: string;
};
export type GoalUpdatesAsideViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GoalUpdatesSideColumn">;
};
export type GoalUpdatesAsideViewQuery = {
  response: GoalUpdatesAsideViewQuery$data;
  variables: GoalUpdatesAsideViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "completedGoalsTqlQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNavRefreshEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newGoalsTqlQuery"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ownGoalsTqlQuery"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUUID"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceUuid",
  "variableName": "workspaceUUID"
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v7 = {
  "kind": "ScalarField",
  "name": "count"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "key"
},
v10 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v11 = {
  "concreteType": "GoalState",
  "kind": "LinkedField",
  "name": "state",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "score"
    },
    {
      "kind": "ScalarField",
      "name": "value"
    }
  ]
},
v12 = {
  "kind": "ScalarField",
  "name": "name"
},
v13 = {
  "kind": "Literal",
  "name": "sorts",
  "value": [
    "CREATION_DATE_DESC"
  ]
},
v14 = [
  (v7/*: any*/),
  {
    "concreteType": "GoalEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "Goal",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "aaid"
              },
              {
                "concreteType": "UserPII",
                "kind": "LinkedField",
                "name": "pii",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "picture"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "accountId"
                  }
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "GoalUpdatesAsideViewQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "completedGoalsTqlQuery",
                "variableName": "completedGoalsTqlQuery"
              },
              {
                "kind": "Variable",
                "name": "newGoalsTqlQuery",
                "variableName": "newGoalsTqlQuery"
              },
              {
                "kind": "Variable",
                "name": "ownGoalsTqlQuery",
                "variableName": "ownGoalsTqlQuery"
              },
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "GoalUpdatesSideColumn"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "GoalUpdatesAsideViewQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "ownGoals",
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "ownGoalsTqlQuery"
              },
              {
                "kind": "Literal",
                "name": "sorts",
                "value": [
                  "NAME_ASC"
                ]
              },
              (v5/*: any*/)
            ],
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "goalTql",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "concreteType": "GoalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Goal",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "concreteType": "UserConnection",
                        "kind": "LinkedField",
                        "name": "watchers",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "alias": "newGoals",
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "newGoalsTqlQuery"
              },
              (v13/*: any*/),
              (v5/*: any*/)
            ],
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "goalTql",
            "plural": false,
            "selections": (v14/*: any*/)
          },
          {
            "alias": "completedGoals",
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "completedGoalsTqlQuery"
              },
              (v13/*: any*/),
              (v5/*: any*/)
            ],
            "concreteType": "GoalConnection",
            "kind": "LinkedField",
            "name": "goalTql",
            "plural": false,
            "selections": (v14/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0924a6cc048dfd347c7cee046ecb979f",
    "id": null,
    "metadata": {},
    "name": "GoalUpdatesAsideViewQuery",
    "operationKind": "query",
    "text": "query GoalUpdatesAsideViewQuery(\n  $workspaceUUID: UUID!\n  $ownGoalsTqlQuery: String!\n  $newGoalsTqlQuery: String!\n  $completedGoalsTqlQuery: String!\n  $isNavRefreshEnabled: Boolean!\n) {\n  ...GoalUpdatesSideColumn_YUpuN @include(if: $isNavRefreshEnabled)\n}\n\nfragment GoalFollowersButton on Goal {\n  key\n  watchers {\n    count\n  }\n}\n\nfragment GoalIcon on Goal {\n  state {\n    score\n    value\n  }\n}\n\nfragment GoalName on Goal {\n  uuid\n  id\n  key\n  ...GoalIcon\n  name\n}\n\nfragment GoalRowWithFollowers on Goal {\n  key\n  ...GoalName\n  ...GoalFollowersButton\n}\n\nfragment GoalRowWithOwner on Goal {\n  key\n  ...GoalName\n  owner {\n    ...UserAvatar_2aqwkz\n    id\n  }\n}\n\nfragment GoalUpdatesSideColumn_YUpuN on Query {\n  ownGoals: goalTql(first: 20, workspaceUuid: $workspaceUUID, q: $ownGoalsTqlQuery, sorts: [NAME_ASC]) {\n    ...GoalWithFollowersSidebarSection\n  }\n  newGoals: goalTql(first: 20, workspaceUuid: $workspaceUUID, q: $newGoalsTqlQuery, sorts: [CREATION_DATE_DESC]) {\n    ...GoalWithOwnerSidebarSection\n  }\n  completedGoals: goalTql(first: 20, workspaceUuid: $workspaceUUID, q: $completedGoalsTqlQuery, sorts: [CREATION_DATE_DESC]) {\n    ...GoalWithOwnerSidebarSection\n  }\n}\n\nfragment GoalWithFollowersSidebarSection on GoalConnection {\n  count\n  edges {\n    node {\n      id\n      ...GoalRowWithFollowers\n    }\n  }\n}\n\nfragment GoalWithOwnerSidebarSection on GoalConnection {\n  count\n  edges {\n    node {\n      id\n      ...GoalRowWithOwner\n    }\n  }\n}\n\nfragment UserAvatar_2aqwkz on User {\n  aaid\n  pii {\n    picture\n    accountId\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf512111462b5d1c128a297d3c2a36f0";

export default node;
