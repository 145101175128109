/**
 * Abstractions over functions of the window object to facilitate mocking in tests.
 */

export function redirect(url: string) {
  window.location.href = url;
}

export function browserLocales(): readonly string[] {
  if (window.navigator.languages) {
    return window.navigator.languages;
  }

  // Edge, Opera, Safari, IE11
  if (window.navigator.language) {
    return [window.navigator.language];
  }

  return [];
}

declare type Event = keyof WindowEventMap;

export function addEventListener<K extends Event>(
  event: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void,
  useCapture = false,
) {
  window.addEventListener(event, listener, useCapture);
}

export function removeEventListener<K extends Event>(
  event: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void,
  useCapture = false,
) {
  window.removeEventListener(event, listener, useCapture);
}

export function location(): Location {
  return window.location;
}

export function history(): History {
  return window.history;
}
