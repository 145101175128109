import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { ReactRenderer } from '@atlaskit/renderer';
import React from 'react';

import { emojiProvider } from '@townsquare/emoji-provider';

const KudosMessage = (props: { description: string }) => {
  const dataProvider = ProviderFactory.create({ emojiProvider });

  return <ReactRenderer dataProviders={dataProvider} document={JSON.parse(props.description)} />;
};

export default KudosMessage;
