import { KeyboardEvent, MouseEvent } from 'react';
import { RouterActionsType } from 'react-resource-router';

import { getConfig } from '@townsquare/config';
import {
  ToAttributes,
  generatePath,
  useGeneratePath,
  useRouteDependencies,
  useRouterActions,
} from '@townsquare/router/primitives';
import { HomepageRouteType } from '@townsquare/router/route-types';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';

export const createMetaClickHandler =
  (href: string, routerActions: RouterActionsType, onClick?: (e?: MouseEvent | KeyboardEvent) => any) =>
  (e?: MouseEvent | KeyboardEvent) => {
    e?.preventDefault();
    if (e?.metaKey) {
      open(href, '_blank', 'noreferrer');
    } else {
      void routerActions.push(href);
    }
    if (onClick) {
      onClick(e);
    }
  };

export const useMetaClickHandler = (
  to: HomepageRouteType,
  attributes?: ToAttributes,
  onClick?: (e?: MouseEvent | KeyboardEvent) => any,
) => {
  const routerActions = useRouterActions();
  const query = createSearchParamsFromScratch(attributes?.query);
  const path = useGeneratePath(to, attributes?.params);

  const onClickHandler = (e?: MouseEvent | KeyboardEvent) => {
    e?.preventDefault();
    if (e?.metaKey) {
      open(path, '_blank', 'noreferrer');
    } else {
      routerActions.pushTo(to, attributes);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return { handler: onClickHandler, path: query.size ? `${path}?${query.toString()}` : path };
};

export const openInNewTab = (url: string | URL) => window.open(url, '_blank', 'noopener noreferrer');

export const useOpenInNewTab = () => {
  const routeDependencies = useRouteDependencies();
  const config = getConfig();
  const url = routeDependencies.workspaceType === 'GLOBAL_EXPERIENCE' ? config.homeUrl : config.classicUrl;
  return (route: HomepageRouteType, attributes?: ToAttributes) => {
    const path = generatePath(route, {
      baseParams: attributes?.params,
      ...routeDependencies,
    });
    const query = createSearchParamsFromScratch(attributes?.query);
    const newUrl = new URL(url);
    newUrl.pathname = path;
    newUrl.search = query.toString();
    return openInNewTab(newUrl);
  };
};
