import { Stack } from '@atlaskit/primitives';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { useCollaborationGraphQuery } from '../../hooks/browsePeople';
import { Heading } from '../Heading';
import { ScreenType } from '../ScreenType';

import { BrowseAllPeopleRow } from './BrowseAllPeopleRow';
import { DefaultSearchFallBackWrapper } from './DefaultSearchFallBack';
import { PeopleRowLoadingSkeleton } from './PeopleRowLoadingSkeleton';

export const BrowsePeopleSection = () => {
  const intl = useIntl();
  const collaboratorsResponse = useCollaborationGraphQuery();
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  const PeopleSection = useMemo(() => {
    if (collaboratorsResponse.loading) {
      return <PeopleRowLoadingSkeleton />;
    } else if (collaboratorsResponse.data && collaboratorsResponse.data.length !== 0 && !collaboratorsResponse.hasError)
      return <BrowseAllPeopleRow users={collaboratorsResponse.data} />;
    else {
      return <DefaultSearchFallBackWrapper />;
    }
  }, [collaboratorsResponse.data, collaboratorsResponse.hasError, collaboratorsResponse.loading]);

  const heading = (
    <Heading
      browseMoreText={intl.formatMessage({
        id: 'townsquare.web.browse-people-section.browse-all',
        description: 'Browse more heading text',
        defaultMessage: 'Browse everyone',
      })}
      screenType={ScreenType.SEARCH_PEOPLE}
    >
      <FormattedMessage
        id="townsquare.web.browse-people-section.heading"
        description="Browse people section heading"
        defaultMessage="People you work with"
      />
    </Heading>
  );

  if (enableHomeVisualRefresh) {
    return (
      <Stack space="space.150">
        {heading}
        {PeopleSection}
      </Stack>
    );
  }

  return (
    <>
      {heading}
      {PeopleSection}
    </>
  );
};
