import Icon from '@atlaskit/icon/base';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function TeamGlyph(props: CustomGlyphProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M10.5 11C12.9853 11 15 13.0147 15 15.5V18C15 19.1046 14.1046 20 13 20H5C3.89543 20 3 19.1046 3 18V15.5C3 13.0147 5.01472 11 7.5 11H10.5ZM16.5 11L16.7118 11.0049C19.0264 11.1122 20.8843 12.968 20.9948 15.2818L21 15.5V18L20.9945 18.1493C20.922 19.1324 20.1388 19.9179 19.1567 19.9939L19 20H17L16.8834 19.9933C16.386 19.9355 16 19.5128 16 19C16 18.4872 16.386 18.0645 16.8834 18.0067L17 18H19V15.5L18.9947 15.3356C18.9136 14.0859 17.9141 13.0864 16.6644 13.0053L16.5 13H16L15.8834 12.9933C15.386 12.9355 15 12.5128 15 12C15 11.4872 15.386 11.0645 15.8834 11.0067L16 11H16.5ZM10.5 13H7.5C6.11929 13 5 14.1193 5 15.5V18H13V15.5C13 14.1193 11.8807 13 10.5 13ZM9 3C10.933 3 12.5 4.567 12.5 6.5C12.5 8.43299 10.933 10 9 10C7.067 10 5.5 8.43299 5.5 6.5C5.5 4.567 7.067 3 9 3ZM13.6102 3.29405C14.9199 2.72013 16.4626 2.99714 17.4907 4.02526C18.8576 5.39209 18.8576 7.60817 17.4907 8.975C16.4626 10.0032 14.9198 10.2801 13.6101 9.70615C13.1043 9.48446 12.8739 8.89468 13.0956 8.38884C13.3015 7.91914 13.8247 7.68697 14.3034 7.83369L14.5441 7.92466C15.0747 8.10019 15.6696 7.9677 16.0765 7.56079C16.6623 6.975 16.6623 6.02526 16.0765 5.43947C15.6725 5.0354 15.083 4.90193 14.5551 5.07196L14.3034 5.16653C13.8246 5.31324 13.3014 5.08104 13.0956 4.61133C12.8739 4.10548 13.1043 3.51571 13.6102 3.29405ZM9 5C8.17157 5 7.5 5.67157 7.5 6.5C7.5 7.32842 8.17157 8 9 8C9.82843 8 10.5 7.32842 10.5 6.5C10.5 5.67157 9.82843 5 9 5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function TeamIcon(props: GlyphProps) {
  return <Icon glyph={TeamGlyph} {...props} />;
}
