import { Route } from 'react-resource-router';

import { RouteWithOutlet, RouteWithSubproduct } from './types';

export function isRouteWithOutlet(route: Route): route is RouteWithOutlet {
  return Object.prototype.hasOwnProperty.call(route, 'type');
}

export function isRouteWithSubproduct(route: Route): route is RouteWithSubproduct {
  return Object.prototype.hasOwnProperty.call(route, 'subproduct');
}
