import { DropdownItem } from '@atlaskit/dropdown-menu';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { useSort, useViewTql } from '@townsquare/directory-header/hooks';
import { useTownsquareUrl } from '@townsquare/hooks';
import { useResourceVariables } from '@townsquare/relay-utils';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { DEFAULT_TABLE_PROJECT_SORTS } from '../default-constants';
import { projectDirectoryViewResource } from '../resources/project-directory-view';

import { DirectoryViewProjectQuery$variables } from './__generated__/DirectoryViewProjectQuery.graphql';
import { ExportProjectCsvQuery } from './__generated__/ExportProjectCsvQuery.graphql';

type ExportProjectCsvProps = {
  exportEntities: (url: string) => void;
};

type ExportProjectCsvInternalProps = {
  exportEntities: (url: string) => void;
  variables: DirectoryViewProjectQuery$variables;
};

const ExportProjectCsvInternal = ({ exportEntities, variables }: ExportProjectCsvInternalProps) => {
  const [{ UUID: workspaceUuid }] = useWorkspaceStore();
  const analytics = useAnalytics();
  const [viewTql] = useViewTql();
  const [sorts] = useSort(DEFAULT_TABLE_PROJECT_SORTS);
  const exportUrl = useTownsquareUrl(getConfig().exportProjectCsvByTqlPath);

  const data = useLazyLoadQuery<ExportProjectCsvQuery>(
    graphql`
      query ExportProjectCsvQuery(
        $tql: String
        $directoryViewUuid: UUID
        $workspaceUuid: UUID
        $after: String
        $first: Int
        $sort: [ProjectSortEnum]
      ) {
        projectTql(
          first: $first
          after: $after
          q: $tql
          directoryViewUuid: $directoryViewUuid
          workspaceUuid: $workspaceUuid
          sort: $sort
        ) {
          count
        }
      }
    `,
    variables,
    { fetchPolicy: 'store-only' },
  );

  const qs = createSearchParamsFromScratch({
    q: viewTql ?? '',
    workspaceUuid,
    sort: sorts.join(','),
  });

  const onClick = () => {
    void analytics.ui('exportProjectCsv', 'clicked');
    if (window.Intercom) {
      window.Intercom('trackEvent', 'exportProjectCsv', analytics.getTopLevelAttributes());
    }
    exportEntities(`${exportUrl}?${qs.toString()}`);
  };

  return (
    <DropdownItem isDisabled={(data.projectTql?.count ?? 0) > 1000} onClick={onClick}>
      <FormattedMessage
        id="townsquare.web.export-project-csv.export-csv"
        description="Export CSV dropdown item"
        defaultMessage="Export CSV"
      />
    </DropdownItem>
  );
};

export const ExportProjectCsv = ({ exportEntities }: ExportProjectCsvProps) => {
  const variables = useResourceVariables(projectDirectoryViewResource);

  if (!variables) {
    return null;
  }

  return <ExportProjectCsvInternal exportEntities={exportEntities} variables={variables} />;
};
