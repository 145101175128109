import React, { KeyboardEvent, MouseEvent } from 'react';
import { MatchParams, Query } from 'react-resource-router';

import { ReturnIcon } from '@townsquare/icons';
import { HomepageRouteType } from '@townsquare/router/route-types';

import { PreviewRowContent, ReturnIconContainer, StyledPreviewRow } from './styles';

interface PreviewRowProps {
  route: HomepageRouteType;
  params?: MatchParams;
  query?: Query;
  onClick: (e: MouseEvent | KeyboardEvent) => void;
  isSelected: boolean;
  children: React.ReactNode;
}

export const PreviewRow = ({ route, params, query, onClick, isSelected, children }: PreviewRowProps) => {
  return (
    <StyledPreviewRow to={route} params={params} query={query} onClick={onClick} isSelected={isSelected}>
      <PreviewRowContent>{children}</PreviewRowContent>

      {isSelected && (
        <ReturnIconContainer>
          <ReturnIcon />
        </ReturnIconContainer>
      )}
    </StyledPreviewRow>
  );
};
