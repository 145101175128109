import { IntlShape } from 'react-intl-next';

import { location } from '@townsquare/facade';

import { messages } from './messages';

export type LearningType = 'LEARNING' | 'RISK' | 'DECISION';
export type LearningEntityType = 'PROJECT' | 'GOAL';

export const formatExpandedEmptyState = (
  intl: IntlShape,
  highlightType: LearningType,
  entityType: LearningEntityType,
  followerCount: number,
  tagFollowerCount?: number,
  tagNames: string[] = [],
) => {
  if (followerCount < 1) {
    return intl.formatMessage(
      {
        id: 'townsquare.learnings.expanded-empty-state.no-followers',
        description: 'Placeholder text for when a user has no followers',
        defaultMessage:
          "Sharing what you've learned gives others a head start when working on similar {entityType, select, project {projects} other {goals}}.",
      },
      {
        entityType: entityType.toLowerCase(),
      },
    );
  }

  if (tagFollowerCount && tagNames.length > 3) {
    return intl.formatMessage(
      {
        id: 'townsquare.learnings.expanded-empty-state.more-than-three-tags',
        description: 'Placeholder text for when a user has more than three tags',
        defaultMessage:
          'Use this space to share your {highlightType, select, learning {learning} risk {new risk} other {decision}} with your {followerCount, plural, one {# follower} other {# followers}} and {tagFollowerCount, plural, one {# person} other {# people}} following {tagNames} and {tagNamesLength, plural, one {# more topic} other {# more topics}}',
      },
      {
        followerCount,
        tagFollowerCount,
        highlightType: highlightType.toLowerCase(),
        tagNamesLength: tagNames.length - 3,
        tagNames: intl.formatList(tagNames.slice(0, 3), { type: 'conjunction' }),
      },
    );
  } else if (tagFollowerCount && tagNames.length) {
    return intl.formatMessage(
      {
        id: 'townsquare.learnings.expanded-empty-state.three-or-less-tags',
        description: 'Placeholder text for when a user has three or less tags',
        defaultMessage:
          'Use this space to share your {highlightType, select, learning {learning} risk {new risk} other {decision}} with your {followerCount, plural, one {# follower} other {# followers}} and {tagFollowerCount, plural, one {# person} other {# people}} following the {tagNames} topics',
      },
      {
        followerCount,
        tagFollowerCount,
        highlightType: highlightType.toLowerCase(),
        tagNames: intl.formatList(tagNames, { type: 'conjunction' }),
      },
    );
  }

  return intl.formatMessage(
    {
      id: 'townsquare.learnings.expanded-empty-state.no-tags',
      description: 'Placeholder text for when a user has no tags',
      defaultMessage:
        'Use this space to share your {highlightType, select, learning {learning} risk {new risk} other {decision}} with your {followerCount, plural, one {# follower} other {# followers}}',
    },
    {
      followerCount,
      highlightType: highlightType.toLowerCase(),
    },
  );
};

export const createLearningLink = (
  entityKey: string | undefined,
  learningUuid: string | undefined,
  learningType: LearningType,
  entityType: LearningEntityType,
) => {
  return `${
    location().hostname
  }/${entityType.toLowerCase()}/${entityKey}/${learningType.toLowerCase()}s/${learningUuid}`;
};

export const generateMissingSummaryError = (learningType: LearningType, intl: IntlShape) => {
  switch (learningType) {
    case 'LEARNING':
      return intl.formatMessage(messages.missingLearningSummary);
    case 'RISK':
      return intl.formatMessage(messages.missingRiskSummary);
    case 'DECISION':
      return intl.formatMessage(messages.missingDecisionSummary);
  }
};

export const generateMissingDescriptionError = (learningType: LearningType, intl: IntlShape) => {
  switch (learningType) {
    case 'LEARNING':
      return intl.formatMessage(messages.missingLearningDescription);
    case 'RISK':
      return intl.formatMessage(messages.missingRiskDescription);
    case 'DECISION':
      return intl.formatMessage(messages.missingDecisionDescription);
  }
};
