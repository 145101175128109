import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { onMobile, onSmallDesktopDown } from '@townsquare/responsive-grid';

import Content from '../Images/JiraPickerScreen/Content.svg';
import ContentDark from '../Images/JiraPickerScreen/Dark/Content.svg';
import NavbarLeft1DarkMode from '../Images/JiraPickerScreen/Dark/navbar-left-1.svg';
import NavbarLeft2DarkMode from '../Images/JiraPickerScreen/Dark/navbar-left-2.svg';
import NavbarLeft3DarkMode from '../Images/JiraPickerScreen/Dark/navbar-left-3.svg';
import NavbarLeft4DarkMode from '../Images/JiraPickerScreen/Dark/navbar-left-4.svg';
import NavbarLeftMobileDarkMode from '../Images/JiraPickerScreen/Dark/navbar-left-mobile.svg';
import NavbarRightMobileDarkMode from '../Images/JiraPickerScreen/Dark/navbar-right-small.svg';
import NavbarRightDarkMode from '../Images/JiraPickerScreen/Dark/navbar-right.svg';
import SidebarDark from '../Images/JiraPickerScreen/Dark/sidebar.svg';
import NavbarLeft1 from '../Images/JiraPickerScreen/navbar-left-1.svg';
import NavbarLeft2 from '../Images/JiraPickerScreen/navbar-left-2.svg';
import NavbarLeft3 from '../Images/JiraPickerScreen/navbar-left-3.svg';
import NavbarLeft4 from '../Images/JiraPickerScreen/navbar-left-4.svg';
import NavbarLeftMobile from '../Images/JiraPickerScreen/navbar-left-mobile.svg';
import NavbarRightMobile from '../Images/JiraPickerScreen/navbar-right-small.svg';
import NavbarRight from '../Images/JiraPickerScreen/navbar-right.svg';
import Sidebar from '../Images/JiraPickerScreen/sidebar.svg';
import { TextColumn } from '../styles';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CenterButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontWeight: token('font.weight.medium'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NewTextColumn = styled(TextColumn)(
  {
    justifyContent: 'center',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    height: 'auto !important',
    maxWidth: '395px',
    marginLeft: '50px',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
    margin-left: 0px;
     width: 90%;
     align-items: center;
     justify-content: center;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IntroHeading = styled.div(
  {
    font: token('font.heading.xlarge'),
    margin: `0 auto ${token('space.300')}`,
    width: '100%',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
     text-align: center;
     font: ${token('font.heading.large')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NextButton = styled.button({
  padding: `${token('space.050')} ${token('space.150')}`,
  color: token('color.text.inverse'),
  height: '32px',
  background: token('color.background.discovery.bold'),
  borderWidth: 0,
  borderRadius: '4px',
  width: 'fit-content',
  cursor: 'pointer',
  '&:hover': {
    background: token('color.background.discovery.bold.hovered'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionSubheading = styled.div(
  {
    font: token('font.heading.medium'),
    fontWeight: token('font.weight.regular'),
    // eslint-disable-next-line @atlaskit/design-system/use-tokens-space
    marginBottom: '25px',
    width: '100%',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
   font-weight: ${token('font.weight.medium')};
   text-align: center;
   margin-bottom: 5%;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ImageBackground = styled.div(
  {
    width: '100%',
    height: '100%',
    position: 'inherit',
    backgroundImage: "url('/Images/Onboarding/NewProductOverview/Background.webp')",
    backgroundSize: 'cover',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
     background-image: url("/Images/Onboarding/NewProductOverview/Mobile/Background.webp");
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProjectCreationImageBackground = styled.div(
  {
    width: '100%',
    height: '100%',
    position: 'inherit',

    /*
     * Image is made up of 4 images. This is how we achieve the resizeable images.
     * LEFT (Navbar- i.e Home, Projects, ect)  - RIGHT (Navbar - Notifications, Sample user icon)
     * LEFT (Project update) - RIGHT (Sidebar)
     */

    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundImage: `url(${NavbarLeft1}), url(${NavbarRight}), url(${Content})`,
    backgroundPosition: 'left top, right top, top 86px right 70%',
    backgroundRepeat: 'no-repeat',
    '@media (min-width: 1000px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft2}), url(${NavbarRight}), url(${Content})`,
      backgroundPosition: 'left top, right top, top 86px right 70%',
    },
    '@media (min-width: 1200px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft3}), url(${NavbarRight}), url(${Content})`,
      backgroundPosition: 'left top, right top, top 86px right 70%',
    },
    '@media (min-width: 1400px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft4}), url(${NavbarRight}), url(${Sidebar}), url(${Content})`,
      backgroundPosition: 'left top, right top, top 56px right, top 86px right 70%',
    },
    '@media (min-width: 2000px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft4}), url(${NavbarRight}), url(${Sidebar}), url(${Content})`,
      backgroundPosition: 'left top, right top, top 56px right, top 86px center',
      backgroundRepeat: 'no-repeat',
    },
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
    background-image: url(${NavbarLeftMobile}),
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
    url(${NavbarRightMobile}),
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
    url(${Content});
  background-position: left top, right top, top 86px right 70%;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProjectCreationImageBackgroundDark = styled.div(
  {
    width: '100%',
    height: '100%',
    position: 'inherit',
    backgroundColor: token('elevation.surface'),

    /*
     * Image is made up of 4 images. This is how we achieve the resizeable images.
     * LEFT (Navbar- i.e Home, Projects, ect)  - RIGHT (Navbar - Notifications, Sample user icon)
     * LEFT (Project update) - RIGHT (Sidebar)
     */

    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundImage: `url(${NavbarLeft1DarkMode}), url(${NavbarRightDarkMode}), url(${ContentDark})`,
    backgroundPosition: 'left top, right top, top 86px right 70%',
    backgroundRepeat: 'no-repeat',
    '@media (min-width: 1000px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft2DarkMode}), url(${NavbarRightDarkMode}), url(${ContentDark})`,
      backgroundPosition: 'left top, right top, top 86px right 70%',
    },
    '@media (min-width: 1200px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft3DarkMode}), url(${NavbarRightDarkMode}), url(${ContentDark})`,
      backgroundPosition: 'left top, right top, top 86px right 70%',
    },
    '@media (min-width: 1400px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft4DarkMode}), url(${NavbarRightDarkMode}), url(${SidebarDark}), url(${ContentDark})`,
      backgroundPosition: 'left top, right top, top 56px right, top 86px right 70%',
    },
    '@media (min-width: 2000px)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundImage: `url(${NavbarLeft4DarkMode}), url(${NavbarRightDarkMode}), url(${SidebarDark}), url(${ContentDark})`,
      backgroundPosition: 'left top, right top, top 56px right, top 86px center',
      backgroundRepeat: 'no-repeat',
    },
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
    background-image: url(${NavbarLeftMobileDarkMode}),
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
    url(${NavbarRightMobileDarkMode}),
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
    url(${ContentDark});
  background-position: left top, right top, top 86px right 70%;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProjectCreationImageBackgroundShadow = styled.div({
  width: '100%',
  height: '100%',
  position: 'inherit',
  backgroundImage: "url('/Images/Onboarding/NewProductOverview/border.svg')",
  backgroundSize: 'cover',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonStyling = styled.div(
  {
    width: '100%',
    // eslint-disable-next-line @atlaskit/design-system/use-tokens-space
    marginBottom: '10px',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
    text-align: center;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IntroPageContentFlexWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    overflowX: 'hidden',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown(`
    width: 100%;
    height: calc(100% - 20px);
  `),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(`
  flex-direction: column-reverse;
  align-items: center;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExpandedText = styled.div({
  font: token('font.body.small'),
  display: 'flex',
  flexDirection: 'column',
  rowGap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExpandedTextBlock = styled.div({
  paddingTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const JiraExpandedTextBlock = styled.div({
  paddingTop: token('space.100'),
  paddingBottom: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FormContainer = styled.div({
  paddingTop: token('space.150'),
  paddingBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Item = styled.div({
  display: 'flex',
  marginTop: `calc(-1 * ${token('space.075')})`,
  float: 'right',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleText = styled.div({
  display: 'inline',
  textAlign: 'center',
  cursor: 'pointer',
  font: token('font.heading.xxsmall'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Title = styled.div({
  minWidth: '290px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeadingWrapper = styled.div({
  cursor: 'pointer',
  minWidth: '290px',
  textAlign: 'center',
  margin: `${token('space.050')} ${token('space.050')} ${token('space.150')} ${token('space.050')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const JiraPickerSectionTitle = styled.div({
  padding: `${token('space.050')} ${token('space.150')} ${token('space.150')} ${token('space.150')}`,
  background: token('color.background.brand.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Seperator = styled.div({
  height: '1px',
  background: token('color.border'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const JiraExpandedText = styled.div({
  font: token('font.body.small'),
  display: 'flex',
  flexDirection: 'column',
  rowGap: token('space.100'),
  paddingBottom: token('space.250'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
  display: 'inline-block',
  verticalAlign: 'middle',
});
