import { ButtonItem, LinkItem, MenuGroup, Section } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent, Suspense } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';

import { goalDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { LoadingState } from '@townsquare/loading-state';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceGlobalId } from '@townsquare/workspace-store';

import { GoalLinkItem } from './GoalLinkItem';
import { GoalMenuButtonQuery } from './__generated__/GoalMenuButtonQuery.graphql';
import { ButtonEmptyStateContainer, GoalsContentWrapper, ViewAllLink } from './styles';
import { onItemClickContextOptions } from './types';

const GoalMenuQuery = graphql`
  query GoalMenuButtonQuery($aaid: String!, $workspaceId: ID!) {
    userByAaid(aaid: $aaid) {
      goals(workspaceId: $workspaceId) {
        count
        edges {
          node {
            uuid
            state {
              value
            }
            ...GoalLinkItem
          }
        }
      }
      goalsWatching(workspaceId: $workspaceId) {
        edges {
          node {
            uuid
            state {
              value
            }
            ...GoalLinkItem
          }
        }
      }
    }
  }
`;

export const GoalMenu = (props: {
  onItemClick: (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => void;
  queryReference: PreloadedQuery<GoalMenuButtonQuery>;
  shortView?: boolean;
}) => {
  const intl = useIntl();
  const data = usePreloadedQuery<GoalMenuButtonQuery>(GoalMenuQuery, props.queryReference);

  const { handler, path } = useMetaClickHandler(goalDirectoryRoute, undefined, e => {
    props.onItemClick('viewAllGoals', e);
  });

  const { handler: filteredHandler, path: filteredPath } = useMetaClickHandler(
    goalDirectoryRoute,
    { query: { filter: 'your-goals' } },
    e => {
      props.onItemClick('viewMoreYourGoals', e);
    },
  );

  const yourGoals = data.userByAaid?.goals;

  const goalCount = yourGoals?.count ?? 0;
  const moreYourGoals = goalCount > 5;
  // Get up to the first 5 goals that don't have the status 'Done'
  const filteredYourGoals =
    yourGoals?.edges &&
    yourGoals?.edges
      .filter(goal => {
        if (!goal?.node?.state?.value) {
          return;
        }

        return goal?.node?.state?.value !== 'done';
      })
      .slice(0, 5);

  const followedGoals = data.userByAaid?.goalsWatching;

  // Get a list (max 20) of projects you are following (doesn't include projects you are the owner of)
  const filteredFollowedGoals = followedGoals?.edges
    ?.filter(followedGoalsEdge => {
      // Remove all projects that are already in the yourProjects array
      return !yourGoals?.edges?.find(edge => edge?.node?.uuid === followedGoalsEdge?.node?.uuid);
    })
    .slice(0, 20);

  const hasWatchingGoals = (filteredFollowedGoals?.length || 0) > 0;
  const hasYourGoals = (filteredYourGoals?.length || 0) > 0;

  return (
    <MenuGroup minWidth={320} maxWidth="95vw">
      {!hasYourGoals && !hasWatchingGoals && (
        <ButtonEmptyStateContainer>
          <img src="/Images/GlobeNetworkStar@2x.png" />
          <p>
            <FormattedMessage
              id="townsquare.navigation-goals.goal-menu.empty-state"
              description="Empty state for the goal menu"
              defaultMessage="Goals you create or follow will show up here."
            />
          </p>
        </ButtonEmptyStateContainer>
      )}
      {(hasYourGoals || hasWatchingGoals) && (
        <GoalsContentWrapper shortView={props.shortView}>
          {hasYourGoals && (
            <Section
              title={intl.formatMessage({
                id: 'townsquare.navigation-goals.goal-menu.your-goals',
                description: 'Your goals section title',
                defaultMessage: 'Your goals',
              })}
            >
              {filteredYourGoals &&
                filteredYourGoals.map(node => {
                  if (!node?.node) {
                    return;
                  }
                  return (
                    <GoalLinkItem
                      {...props}
                      data={node.node}
                      onItemClick={props.onItemClick}
                      navigateTo="navigateToYourGoals"
                      testId="goals-menu-your-goals-item"
                      type="PROJECT"
                    />
                  );
                })}
            </Section>
          )}
          {moreYourGoals && (
            <ViewAllLink testId="menu-view-more-your-goals" onClick={filteredHandler} href={filteredPath}>
              <FormattedMessage
                id="townsquare.navigation-goals.goal-menu.view-all"
                description="View all link"
                defaultMessage="View all"
              />
            </ViewAllLink>
          )}
          {hasWatchingGoals && (
            <Section
              title={intl.formatMessage({
                id: 'townsquare.navigation-goals.goal-menu.following',
                description: 'Following goals section title',
                defaultMessage: 'Following',
              })}
            >
              {filteredFollowedGoals &&
                filteredFollowedGoals.map(node => {
                  if (!node?.node) {
                    return;
                  }
                  return (
                    <GoalLinkItem
                      {...props}
                      data={node.node}
                      onItemClick={props.onItemClick}
                      navigateTo="navigateToFollowedGoals"
                      testId="goals-menu-followed-goals-item"
                      type="FOLLOWER"
                    />
                  );
                })}
            </Section>
          )}
        </GoalsContentWrapper>
      )}
      <Section hasSeparator>
        <LinkItem testId="menu-view-all-goals" onClick={handler} href={'' + path}>
          <FormattedMessage
            id="townsquare.navigation-goals.goal-menu.view-all-goals"
            description="View all goals link"
            defaultMessage="View all goals"
          />
        </LinkItem>
        <ButtonItem onClick={() => props.onItemClick('openGoalModal')}>
          <FormattedMessage
            id="townsquare.navigation-goals.goal-menu.create-goal"
            description="Create goal button"
            defaultMessage="Create goal"
          />
        </ButtonItem>
      </Section>
    </MenuGroup>
  );
};

type GoalWrapperProps = {
  onItemClick: (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => void;
  shortView?: boolean;
};
export const GoalMenuWrapper = (props: GoalWrapperProps) => {
  const [workspaceId] = useWorkspaceGlobalId();
  const [queryReference, loadQuery] = useQueryLoader<GoalMenuButtonQuery>(GoalMenuQuery);
  const [user] = useUserStore();
  if (!queryReference) {
    loadQuery({
      aaid: user.accountId,
      workspaceId: workspaceId,
    });
  }
  return (
    <Suspense
      fallback={
        <MenuGroup minWidth={320}>
          <LoadingState />
        </MenuGroup>
      }
    >
      {queryReference && <GoalMenu queryReference={queryReference} {...props} />}
    </Suspense>
  );
};
