import type { ProfilecardTriggerPosition } from '@atlaskit/profilecard';

export type Size = 'xsmall' | 'small' | 'medium' | 'large';

export type UserAvatarProps = {
  aaid: string | null | undefined;
  hideBorder?: boolean;
  name?: string | null;
  jobTitle?: string | null;
  showName?: boolean;
  size: Size;
  url?: string | null;
  linkToProfile?: UserAvatarLinkBehaviour;
  isPendingUser?: boolean;
  isDeactivatedUser?: boolean;
  profileCardPosition?: ProfilecardTriggerPosition;
  onClick?: () => void;
  useProfileCard?: boolean;
};

export type UserNameProps = { size?: Size } & Pick<
  UserAvatarProps,
  'aaid' | 'name' | 'linkToProfile' | 'isDeactivatedUser' | 'profileCardPosition'
>;

export enum UserAvatarLinkBehaviour {
  DISABLED,
  DEFAULT,
  FORCE_NEW_TAB,
}
