import Loadable from 'react-loadable';

import { READING_MODE_PAGE, READING_MODE_PATH } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

import { readingModeResource } from './resources/readingMode';

export const readingModeRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "reading-mode-view" */ './ui/index'),
    loading: () => null,
  }),
  exact: true,
  name: READING_MODE_PAGE,
  path: READING_MODE_PATH,
  resources: [readingModeResource],
});
