/**
 * @generated SignedSource<<1a17bda15c419f49ecb62b6b90405084>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type deleteDecisionInput = {
  clientMutationId?: string | null;
  id: string;
};
export type DeleteDecisionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: deleteDecisionInput;
};
export type DeleteDecisionMutation$data = {
  readonly deleteDecision: {
    readonly decision: {
      readonly id: string;
    } | null;
  } | null;
};
export type DeleteDecisionMutation = {
  response: DeleteDecisionMutation$data;
  variables: DeleteDecisionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "DeleteDecisionMutation",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "deleteDecisionPayload",
        "kind": "LinkedField",
        "name": "deleteDecision",
        "plural": false,
        "selections": [
          {
            "concreteType": "Decision",
            "kind": "LinkedField",
            "name": "decision",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteDecisionMutation",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "deleteDecisionPayload",
        "kind": "LinkedField",
        "name": "deleteDecision",
        "plural": false,
        "selections": [
          {
            "concreteType": "Decision",
            "kind": "LinkedField",
            "name": "decision",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "e8b14eea27e6433010b1797693a43c40",
    "id": null,
    "metadata": {},
    "name": "DeleteDecisionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDecisionMutation(\n  $input: deleteDecisionInput!\n) {\n  deleteDecision(input: $input) {\n    decision {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "822e77319542c74c53abf295ff488fd8";

export default node;
