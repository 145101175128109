import { commitMutation, Environment, graphql } from 'react-relay';
import { type MutationConfig } from 'relay-runtime';

import {
  updateUserWorkspacePreferenceInput,
  UpdateUserWorkspacePreferenceMutation,
} from './__generated__/UpdateUserWorkspacePreferenceMutation.graphql';

export const updateUserWorkspacePreference = (
  environment: Environment,
  input: updateUserWorkspacePreferenceInput,
  events?: Pick<MutationConfig<UpdateUserWorkspacePreferenceMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<UpdateUserWorkspacePreferenceMutation>(environment, {
    mutation: graphql`
      mutation UpdateUserWorkspacePreferenceMutation($input: updateUserWorkspacePreferenceInput!) {
        updateUserWorkspacePreference(input: $input) {
          result {
            id
            name
            uuid
          }
        }
      }
    `,
    variables: { input },
    onCompleted: events?.onCompleted,
    onError: events?.onError,
  });
};
