import Loadable from 'react-loadable';

export const GoalDirectory = Loadable({
  loader: () => import(/* webpackChunkName: "RelayGoalDirectory" */ './components/GoalDirectory'),
  loading: () => null,
});

export const GoalsNoWorkspaceDirectory = Loadable({
  loader: () => import(/* webpackChunkName: "GoalsNoWorkspaceDirectory" */ './components/EmptyOrNonProvisionedState'),
  loading: () => null,
});

export { goalDirectoryResource, goalDirectoryTableResource, getGoalDirectoryTableResourceQuery } from './resources';
export { useQueryRef, useQueryRefActions } from './store/queryRefStore';
export { ExportGoalCsv } from './components/ExportGoalCsv';
