/**
 * @generated SignedSource<<28c888db3220d4d0ee71de838f5a5eba>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraIntegrationLinkQuery$variables = {
  key: string;
};
export type JiraIntegrationLinkQuery$data = {
  readonly project: {
    readonly ari: string;
    readonly fusion: {
      readonly issue: {
        readonly href: string | null;
        readonly key: string | null;
      } | null;
      readonly issueAri: string;
    } | null;
    readonly uuid: string;
  } | null;
};
export type JiraIntegrationLinkQuery = {
  response: JiraIntegrationLinkQuery$data;
  variables: JiraIntegrationLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "ari"
},
v3 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v4 = {
  "concreteType": "FusionDetails",
  "kind": "LinkedField",
  "name": "fusion",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "issueAri"
    },
    {
      "concreteType": "GqlJiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "kind": "ScalarField",
          "name": "href"
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "JiraIntegrationLinkQuery",
    "selections": [
      {
        "alias": "project",
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectByKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JiraIntegrationLinkQuery",
    "selections": [
      {
        "alias": "project",
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectByKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0f74999532dbed24a5517db48847a906",
    "id": null,
    "metadata": {},
    "name": "JiraIntegrationLinkQuery",
    "operationKind": "query",
    "text": "query JiraIntegrationLinkQuery(\n  $key: String!\n) {\n  project: projectByKey(key: $key) {\n    ari\n    uuid\n    fusion {\n      issueAri\n      issue {\n        key\n        href\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "83da76a27f532e836cfc6bc585556f75";

export default node;
