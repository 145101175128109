/**
 * DO NOT MANUALLY CHANGE THIS FILE AS IT IS AUTOGENERATED.
 */
import { Icon } from '@atlaskit/icon/base';
import { type GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function ChevronDownIconGlyph() {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0-.322-1.637.987.987 0 0 0-1.084.218l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0Z"
        fill="currentColor"
      />
    </svg>
  );
}

function ChevronDownIcon(props: GlyphProps) {
  return <Icon glyph={ChevronDownIconGlyph} {...props} />;
}

export default ChevronDownIcon;
