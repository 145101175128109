export class RequestError extends Error {
  name = 'RequestError';
  statusText = '';
  statusCode = 0;

  constructor(statusText: string, statusCode = 0) {
    super(`${statusText} (${statusCode})`);
    this.statusText = statusText;
    this.statusCode = statusCode;
  }
}
export class GraphQLFetchError extends RequestError {
  name = 'GraphQLFetchError';
}

export class ForbiddenError extends Error {
  name = 'ForbiddenError';
}

export class UnauthorizedError extends Error {
  name = 'UnauthorizedError';
}

export class BadRequestError extends RequestError {
  name = 'BadRequestError';
}

export class NotFound extends Error {
  name = 'NotFound';
}

export class InternalServerError extends Error {
  name = 'InternalServerError';
}

export class RelayRequiredFieldError extends Error {
  name = 'RelayRequiredFieldError';
}
