import React from 'react';

import { useModalActions } from '@townsquare/modals';

import { Feedback } from './FeedbackLazy';
import { FeedbackModalOptions } from './types';

export const useFeedbackModal = () => {
  const { openModal, closeModal } = useModalActions();

  const openFeedbackModal = (options: FeedbackModalOptions) => {
    openModal('feedback-modal', {
      component: <Feedback {...options} onClose={() => closeModal('feedback-modal')} />,
    });
  };

  return {
    openFeedbackModal,
  };
};
