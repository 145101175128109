import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { ExpandableInfoBoxHeadingContainerInner, InfoBox } from '@townsquare/expandable-panel';
import { CopyLinkWrapper, EditDropdownWrapper } from '@townsquare/learnings';
import { onSmallMobile } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningsEmptyWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${token('color.border.disabled')}`,
  borderRadius: '4px',
  color: token('color.text'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '&:hover a': {
    cursor: 'pointer',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningsEmptyContainer = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    padding: token('space.600'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallMobile(`
    flex-direction: column;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningsEmptyImage = styled.img({
  maxWidth: '100%',
  maxHeight: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningsEmptyContent = styled.div(
  {
    flex: '0 1 410px',
    marginLeft: token('space.600'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallMobile(`
    text-align: center;
    margin: ${token('space.250')} 0 0 0;
    flex: 0;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LearningsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${token('space.100')};
  margin-bottom: ${token('space.300')};

  ${InfoBox} {
    ${EditDropdownWrapper} {
      opacity: 0;
    }
    &:hover,
    &:focus-within {
      ${EditDropdownWrapper} {
        opacity: 1;
      }
    }

    &:hover {
      border: 1px solid transparent;
      box-shadow: ${token('elevation.shadow.raised')};
    }
  }

  ${ExpandableInfoBoxHeadingContainerInner} {
    ${CopyLinkWrapper} {
      opacity: 0;
    }
    &:hover,
    &:focus-within {
      ${CopyLinkWrapper} {
        opacity: 1;
      }
    }
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningsHeading = styled.p({
  font: token('font.heading.small'),
  marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateButtonsWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    padding: `${token('space.250')} 0 0 0`,
    alignItems: 'center',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallMobile(`
    justify-content: center;
    flex-direction: column;
    gap: ${token('space.100')};
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateButtonWrapper = styled.div(
  {
    display: 'flex',
    width: 'auto',
    marginRight: token('space.200'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallMobile(`
    margin-right: 0;
  `),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddNewLearningsIconContainer = styled.div({
  boxSizing: 'border-box',
  width: '24px',
  height: '24px',
  background: token('color.background.neutral'),
  borderRadius: '4px',
  color: token('color.text.subtlest'),
  marginRight: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> div': {
    width: '16px',
    height: '16px',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    svg: {
      width: '16px',
      height: '16px',
    },
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const AddNewLearningsButtonWrapper = styled.button`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  margin: 0;
  background: ${token('elevation.surface')};
  border: 1px solid ${token('color.border.disabled')};
  border-radius: 4px;

  cursor: pointer;
  padding: ${token('space.150')};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${token('color.text.subtlest')};
  transition: box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${token('color.border.focused')};
  }

  &:hover {
    &:hover {
      box-shadow: ${token('elevation.shadow.raised')};
    }

    ${AddNewLearningsIconContainer} {
      color: ${token('color.text')};
      background: ${token('color.background.neutral.hovered')};
    }
  }
`;
