export enum ScreenType {
  BROWSE_ALL,
  SEARCH_ALL,
  SEARCH_PEOPLE,
  SEARCH_TEAMS,
  BROWSE_DEPARTMENTS,
  BROWSE_LOCATIONS,
  BROWSE_JOB_TITLES,
  BROWSE_KUDOS,
}

export const DEFAULT_SCREEN_TYPE = ScreenType.BROWSE_ALL;

export const PEOPLE_FIELD_SCREEN_TYPES = [
  ScreenType.BROWSE_DEPARTMENTS,
  ScreenType.BROWSE_JOB_TITLES,
  ScreenType.BROWSE_LOCATIONS,
];

export const SEARCH_BAR_PREVIEW_TQL_SCREEN_TYPES = [...PEOPLE_FIELD_SCREEN_TYPES, ScreenType.BROWSE_ALL];

export const getScreenFromStringOrDefault = (screenKey: string | undefined): ScreenType =>
  (screenKey && ScreenType[screenKey as keyof typeof ScreenType]) || DEFAULT_SCREEN_TYPE;
