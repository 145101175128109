import { RestClient } from '@townsquare/rest-client';

export interface CollaborationGraphUser {
  id: string;
  expand: {
    name: string;
    picture: string;
    nickname: string;
    account_status: string;
    extended_profile?: {
      job_title?: string;
      organization?: string;
      department?: string;
      location?: string;
    };
  };
}

// https://developer.atlassian.com/platform/collaboration-graph/rest/api-group-collaboration-graph-recommendations-api/#api-v2-recommend-user-post
export interface CollaboratorsRequestPayload {
  context: {
    // user to find collaborators for
    userId: string;
    // cloudId
    tenantId: string;
  };
  modelRequestParams: {
    experience: string;
    caller: string;
  };
  // user the request is on behalf of
  requestingUserId: string;
  // causes userProfile to get returned
  expand: string;
  maxNumberOfResults?: number;
}

export interface CollaboratorsResponsePayload {
  recommendedEntities: CollaborationGraphUser[];
}

// Service for getting objects related to a user's activity https://developer.atlassian.com/platform/collaboration-graph/
class CollaborationGraphClient extends RestClient {
  async getCollaboratorsWithFullProfiles(data: CollaboratorsRequestPayload): Promise<CollaboratorsResponsePayload> {
    return this.postResource('/recommend/user', data);
  }
}

export default new CollaborationGraphClient({
  baseUrl: '/gateway/api/collaboration/v2',
});
