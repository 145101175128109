import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MainSectionWrapper = styled.div({
  padding: `0 ${token('space.100')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderWrapper = styled.div({
  marginBottom: token('space.600'),
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleAndKeyWrapper = styled.div({
  marginLeft: token('space.200'),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StatusAndDateWrapper = styled.div({
  alignSelf: 'end',
  marginLeft: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TabListWrapper = styled.div({
  display: 'flex',
  padding: `${token('space.050')} 0 ${token('space.075')} 0`,
  gap: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TabListUnderline = styled.div({
  width: '100%',
  background: token('color.skeleton'),
  height: '2px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentWrapper = styled.div({
  marginTop: token('space.500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: `${token('space.600')} 0 ${token('space.400')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UserWrapper = styled.div({
  margin: `${token('space.200')} 0`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MetaActionsWrapper = styled.div({
  marginBottom: token('space.500'),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: `${token('space.150')} ${token('space.100')}`,
});
