/**
 * @generated SignedSource<<409445dba432c84a7dd8972df60bd63b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type LookupRedirectActionGoalQuery$variables = {
  entityKey: string;
};
export type LookupRedirectActionGoalQuery$data = {
  readonly goalByKey: {
    readonly id: string;
  } | null;
};
export type LookupRedirectActionGoalQuery = {
  response: LookupRedirectActionGoalQuery$data;
  variables: LookupRedirectActionGoalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityKey"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "entityKey"
      }
    ],
    "concreteType": "Goal",
    "kind": "LinkedField",
    "name": "goalByKey",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "id"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "LookupRedirectActionGoalQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LookupRedirectActionGoalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4409b9b64cdf6334d229629c7a041a82",
    "id": null,
    "metadata": {},
    "name": "LookupRedirectActionGoalQuery",
    "operationKind": "query",
    "text": "query LookupRedirectActionGoalQuery(\n  $entityKey: String!\n) {\n  goalByKey(key: $entityKey) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "141748139d372dab65836e446f1ed71b";

export default node;
