import React from 'react';
import { defineMessage, useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { ScreenType } from '../ScreenType';

import { BrowsePeopleFieldSection, BrowsePeopleFieldSectionSkeleton } from './BrowsePeopleFieldSection';
import { BrowseLocationSection$key } from './__generated__/BrowseLocationSection.graphql';

const locationSectionTitle = defineMessage({
  id: 'townsquare.staff-directory.browse-locations-section.title',
  description: 'Header text for the locations section of the staff directory',
  defaultMessage: 'Locations',
});

export const BrowseLocationSectionSkeleton = () => {
  const intl = useIntl();
  return (
    <BrowsePeopleFieldSectionSkeleton
      browseAllScreenType={ScreenType.BROWSE_LOCATIONS}
      fieldType="LOCATION"
      sectionTitle={intl.formatMessage(locationSectionTitle)}
    />
  );
};

export interface BrowseFieldSectionProps {
  data: BrowseLocationSection$key;
}

export const BrowseLocationSection = (props: BrowseFieldSectionProps) => {
  const data = useFragment(
    graphql`
      fragment BrowseLocationSection on Query
      @argumentDefinitions(workspaceId: { type: "ID!" }, first: { type: "Int" }, after: { type: "String" }) {
        location: peopleFieldAggregates(
          workspaceId: $workspaceId
          peopleFieldType: LOCATION
          first: $first
          after: $after
        ) {
          ...BrowsePeopleFieldSection
        }
      }
    `,
    props.data,
  );
  const intl = useIntl();

  return (
    data.location && (
      <BrowsePeopleFieldSection
        sectionTitle={intl.formatMessage(locationSectionTitle)}
        fieldType="LOCATION"
        browseAllScreenType={ScreenType.BROWSE_LOCATIONS}
        data={data.location}
      />
    )
  );
};
