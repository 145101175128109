import Loadable from 'react-loadable';

import { PEOPLE_DIRECTORY_PATH, STAFF_DIRECTORY_PAGE } from '@townsquare/config/routes';
import { onboardingResource } from '@townsquare/onboarding/resource';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';

import { staffDirectoryResource } from './resources';

export const staffDirectoryRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "RelayStaffDirectory" */ './components/StaffDirectoryView'),
    loading: () => null, // DirectorySkeleton, - TODO need to redo the skeleton, in its current form it will blow up our main bundle size
    delay: 0,
  }),
  exact: true,
  name: STAFF_DIRECTORY_PAGE,
  path: `${PEOPLE_DIRECTORY_PATH}/:search(search)?`,
  resources: [onboardingResource, staffDirectoryResource],
  subproduct: 'townsquare-people-team-directory',
});

export const noWorkspaceStaffDirectoryRoute = createGlobalRoute({
  component: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "RelayNoWorkspaceStaffDirectory" */ './components/EmptyOrNonProvisionedState/EmptyOrNonProvisionedState'
      ),
    loading: () => null,
    delay: 0,
  }),
  exact: true,
  name: STAFF_DIRECTORY_PAGE,
  path: `${PEOPLE_DIRECTORY_PATH}/:search(search)?`,
  resources: [titanEapCrossFlowQueryResource],
  subproduct: 'townsquare-people-team-directory',
});
