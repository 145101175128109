import { Flex, Stack } from '@atlaskit/primitives';
import React from 'react';

import { ResetButton } from '@townsquare/directory-actions';
import { LoadingSkeleton } from '@townsquare/loading-skeleton';
import { FilterDoc } from '@townsquare/tql/types';
import { TqlFilter } from '@townsquare/tql/ui/filter';

import { TextFilterWithPreview } from './TextFilterWithPreview';
import { FilterWrapper, ResetFilterWrapper } from './styles';
import { SearchBarProps } from './types';

export const SearchBar = ({
  onSearch,
  directoryResolvers,
  hasFilters,
  searchQuery: { searchText, filterDoc },
  clearSearchQuery,
  textFieldRef,
  textFieldPlaceholder,
  searchBarPreviewTql,
  showRecent,
  loading,
  shouldShowSearchBar = true,
}: SearchBarProps) => {
  const searchPlaceholder = textFieldPlaceholder;

  const onTextChange = (newText: string | undefined) => onSearch({ searchText: newText, filterDoc });
  const onFilterDocChange = (newDoc: FilterDoc) => onSearch({ searchText, filterDoc: newDoc });

  return (
    <Stack space="space.200" testId="staff-directory-search-filter">
      {shouldShowSearchBar && (
        <TextFilterWithPreview
          onTextChange={onTextChange}
          innerRef={textFieldRef}
          isDisabled={loading}
          tqlForPreview={searchBarPreviewTql}
          textFieldPlaceholder={searchPlaceholder}
          searchText={searchText}
          showRecent={showRecent}
          resolvers={directoryResolvers}
        />
      )}
      {!loading && directoryResolvers.length > 0 && (
        <FilterWrapper>
          <TqlFilter
            filter={filterDoc}
            resolvers={directoryResolvers}
            onChange={onFilterDocChange}
            analyticsContext="staffDirectory"
          />
          {hasFilters && (
            <ResetFilterWrapper>
              <ResetButton onClick={clearSearchQuery} textOverride="Clear all filters" />
            </ResetFilterWrapper>
          )}
        </FilterWrapper>
      )}
      {loading && directoryResolvers.length > 0 && (
        <Flex direction="row" columnGap="space.100">
          {Array.from({ length: directoryResolvers.length }).map((_, i) => (
            <LoadingSkeleton key={`meta-filter-skeleton-${i}`} width="100px" height="40px" borderRadius="6px" />
          ))}
        </Flex>
      )}
    </Stack>
  );
};
