import Icon from '@atlaskit/icon/base';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function RemoveGlyph(props: CustomGlyphProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M8.5 8.5L15.5 15.5M8.5 15.5L15.5 8.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export function RemoveIcon(props: GlyphProps) {
  return <Icon glyph={RemoveGlyph} {...props} />;
}
