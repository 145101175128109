import Avatar from '@atlaskit/avatar';
import { OptionData } from '@atlaskit/smart-user-picker';
import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

import { WrappedWithProfileCard } from '@townsquare/profile-card-provider';

const LineContainer = styled.div({
  width: '100%',
  // trigger container
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> span': {
    width: '100%',
    display: 'inline-block',
  },
});

const Name = styled.span({
  marginLeft: token('space.100'),
  verticalAlign: 'top',
});

type Props = {
  user: OptionData;
};

export const UserOptionWithProfileCard = (props: Props) => {
  const { user } = props;
  return (
    <LineContainer>
      <WrappedWithProfileCard userId={user.id} position="right">
        <>
          <Avatar src={user.avatarUrl} name={user.name} size="xsmall" />
          <Name>{user.name}</Name>
        </>
      </WrappedWithProfileCard>
    </LineContainer>
  );
};
