import { Text } from '@atlaskit/primitives';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { TeamIcon } from '@townsquare/team-icon';

import { PreviewRow } from './PreviewRow';
import { ResultHeading, ResultPrimaryText, ResultSecondaryText } from './styles';
import { SelectableRowProps } from './types';

export interface PreviewTeam {
  name: string;
  teamId: string;
  memberCount: number;
}

interface TeamPreviewRowProps extends SelectableRowProps {
  team: PreviewTeam;
}

export const TeamPreviewRow = ({ team: { name, memberCount, teamId }, isSelected }: TeamPreviewRowProps) => {
  const intl = useIntl();
  const analytics = useAnalytics();
  const onClick = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation();
    void analytics.ui('staffDirectorySearchPreviewTeamRow', 'clicked');
  };

  const memberText = intl.formatMessage(
    {
      id: 'townsquare.views.directory.search-preview-team-results-member-text',
      description: 'The number of members in a team. Example: 5 members',
      defaultMessage: '{memberCount, plural, one {# member} other {# members}}',
    },
    {
      memberCount,
    },
  );

  return (
    <PreviewRow
      data-testid={`preview-team-${teamId}`}
      route={teamProfileRoute}
      params={{ id: teamId }}
      onClick={onClick}
      isSelected={isSelected}
    >
      <TeamIcon teamName={name} size="medium" shape="circle" />
      <ResultPrimaryText>
        <EllipsisTooltip content={name}>
          <Text>{name}</Text>
        </EllipsisTooltip>
      </ResultPrimaryText>

      <ResultSecondaryText>
        <EllipsisTooltip content={memberText}>
          <Text color="color.text.subtlest" maxLines={1}>
            {memberText}
          </Text>
        </EllipsisTooltip>
      </ResultSecondaryText>
    </PreviewRow>
  );
};

interface TeamResultsProps {
  teams: PreviewTeam[];
  selectedId: string;
}

export const TeamResults = ({ teams, selectedId }: TeamResultsProps) => {
  return (
    <>
      <ResultHeading>
        <FormattedMessage
          id="townsquare.web.team-results.heading"
          description="Heading for the team results section of the search preview"
          defaultMessage="Teams"
        />
      </ResultHeading>
      {teams.map(team => (
        <TeamPreviewRow team={team} key={team.teamId} isSelected={selectedId === team.teamId} />
      ))}
    </>
  );
};
