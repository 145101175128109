import { useState } from 'react';

import { useAsyncEffect } from '@townsquare/hooks';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import collaborationGraphClient, {
  CollaboratorsRequestPayload,
  CollaboratorsResponsePayload,
} from '../clients/collaborationGraphClient';
import { BrowseUser } from '../components/Cards/Browse/Person';

import { AvatarResolution, convertAvatarResolution } from './util/convertAvatarResolution';

const DISPLAY_USER_COUNT = 6;

export interface BrowsePeopleResponse {
  data?: BrowseUser[];
  hasError: boolean;
  loading: boolean;
}

const transformCollaborators = (data: CollaboratorsResponsePayload | undefined): BrowseUser[] | undefined => {
  if (!data) {
    return undefined;
  }
  return (
    data.recommendedEntities
      // Remove inactive users
      .filter(user => user.expand.account_status === 'active')
      // Slice to the number we need
      .slice(0, DISPLAY_USER_COUNT)
      .map(user => {
        const { expand } = user;

        return {
          aaid: user.id,
          name: expand.nickname ? expand.nickname : expand.name,
          picture: convertAvatarResolution(expand.picture, AvatarResolution.MEDIUM),
          jobTitle: expand.extended_profile?.job_title,
        };
      })
  );
};

const collaboratorsPayloadFor = (accountId: string, cloudId: string): CollaboratorsRequestPayload => ({
  context: {
    userId: accountId,
    tenantId: cloudId,
  },
  modelRequestParams: {
    experience: 'CgUserNearbyUser',
    caller: 'atlas',
  },
  requestingUserId: accountId,
  expand: 'existing',
  // Twice as many as we need so we can post process and filter
  maxNumberOfResults: DISPLAY_USER_COUNT * 2,
});

export const useCollaborationGraphQuery = (): BrowsePeopleResponse => {
  const [{ cloudId }] = useWorkspaceStore();
  const [{ accountId }] = useUserStore();

  const [data, setData] = useState<BrowseUser[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  useAsyncEffect(async () => {
    if (loading) {
      try {
        const collaborators = await collaborationGraphClient.getCollaboratorsWithFullProfiles(
          collaboratorsPayloadFor(accountId, cloudId),
        );
        setData(transformCollaborators(collaborators));
      } catch {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [loading, accountId, cloudId]);

  return { data, loading, hasError } as const;
};
