import { commitMutation, graphql, type Environment, type MutationConfig } from 'relay-runtime';

import { deleteRiskInput, DeleteRiskMutation } from './__generated__/DeleteRiskMutation.graphql';

export const deleteRisk = (
  environment: Environment,
  input: deleteRiskInput,
  connectionIds: string[],
  events: Pick<MutationConfig<DeleteRiskMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<DeleteRiskMutation>(environment, {
    mutation: graphql`
      mutation DeleteRiskMutation($input: deleteRiskInput!, $connections: [ID!]!) {
        deleteRisk(input: $input) {
          risk {
            id @deleteEdge(connections: $connections)
          }
        }
      }
    `,
    updater: store => {
      const risk = store.get(input.id);
      risk?.invalidateRecord();
    },
    variables: { input, connections: connectionIds },
    onCompleted: events.onCompleted,
    onError: events.onError,
  });
};
