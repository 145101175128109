import { createHook } from 'react-sweet-state';

import { directoryViewStore } from './store';

export const useDirectoryViewStore = createHook(directoryViewStore);

export const useDirectoryViewProjectSort = createHook(directoryViewStore, {
  selector: directoryView => directoryView.projectSort,
});

export const useDirectoryViewGoalSort = createHook(directoryViewStore, {
  selector: directoryView => directoryView.goalSort,
});

export const useDirectoryViewSort = createHook(directoryViewStore, {
  selector: directoryView => (directoryView.entityType === 'GOAL' ? directoryView.goalSort : directoryView.projectSort),
});

export const useDirectoryViewTimelineDateSpan = createHook(directoryViewStore, {
  selector: directoryView => directoryView.timelineDateSpan,
});

export const useDirectoryViewUiViewType = createHook(directoryViewStore, {
  selector: directoryView => directoryView.uiViewType,
});

export const useDirectoryViewStoredTql = createHook(directoryViewStore, {
  selector: directoryView => directoryView.storedTql,
});

export const useDirectoryViewColumns = createHook(directoryViewStore, {
  selector: directoryView => directoryView.directoryViewColumns,
});
