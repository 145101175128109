import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MainColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const WhyWeeklyUpdatesInfoText = styled.p({
  color: token('color.text.subtlest'),
  marginBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const NoProjectsEmptyStateContainer = styled.div<{ imageLoaded: boolean; centerAlign?: boolean }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  display: props.imageLoaded ? 'flex' : 'none',
  flexDirection: 'column',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  alignItems: props.centerAlign ? 'center' : undefined,
  img: {
    width: '100%',
    marginTop: token('space.250'),
    marginBottom: token('space.250'),
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NavigatorContainer = styled.div({
  marginBottom: token('space.400'),
  display: 'flex',
  height: '32px',
  justifyContent: 'space-between',
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  button: {
    margin: 0,
    padding: `0 ${token('space.075')}`,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  h2: {
    font: token('font.heading.large'),
    margin: 0,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarContainer = styled.div({
  marginTop: token('space.600'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerButtonStyling = styled.p({
  marginTop: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoBoxBoldText = styled.p({
  color: token('color.text'),
  marginTop: token('space.200'),
  width: '100%',
  wordWrap: 'break-word',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  a: {
    color: token('color.text'),
    font: token('font.heading.xsmall'),
    wordBreak: 'break-word',
    '&:hover': {
      color: token('color.text.subtlest'),
      textDecoration: 'none',
    },
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoBoxSubText = styled.p({
  color: token('color.text.subtle'),
  marginTop: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const PopularProjectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${token('space.300')};
  border: 1px solid ${token('color.border')};
  border-radius: 8px;
  width: 100%;
  @media (max-width: ${SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD}px) {
    width: unset;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const PopularProjectSkeletonBox = styled(PopularProjectBox)`
  @media (max-width: ${SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD}px) {
    display: none;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NoUpdatesSubHeading = styled.p({
  font: token('font.heading.xsmall'),
  marginTop: token('space.300'),
  marginBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonProjectIcon = styled.div({
  width: '40px',
  height: '40px',
  borderRadius: '3px',
  background: token('color.background.neutral'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonBarLong = styled.div({
  width: '80%',
  height: '8px',
  borderRadius: '4px',
  background: token('color.background.neutral'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonBarShort = styled.div({
  width: '50%',
  height: '8px',
  borderRadius: '4px',
  background: token('color.background.neutral'),
  marginTop: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const PopularProjectsContainer = styled.div`
  display: flex;
  gap: ${token('space.150')};
  justify-content: space-between;
  @media (max-width: ${SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD}px) {
    flex-direction: column;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CreateButtonWrapper = styled.div({
  marginTop: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExampleUpdatesInfo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  color: token('color.text.subtlest'),
  alignItems: 'center',
  marginBottom: token('space.500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoIconWrapper = styled.div({
  width: '16px',
  marginRight: token('space.050'),
  color: token('color.text.disabled'),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
