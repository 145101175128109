import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { LearningType, generateMissingDescriptionError, generateMissingSummaryError } from '../util';

export const useErrors = (learningType: LearningType) => {
  const intl = useIntl();
  const [errors, setErrors] = useState<Record<'summary' | 'description', boolean>>({
    summary: false,
    description: false,
  });

  const resetErrors = useCallback(() => {
    setErrors({
      summary: false,
      description: false,
    });
  }, []);

  const errorMessages = {
    summary: errors.summary ? generateMissingSummaryError(learningType, intl) : undefined,
    description: errors.description ? generateMissingDescriptionError(learningType, intl) : undefined,
  };

  return { errors: errorMessages, setErrors, resetErrors };
};
