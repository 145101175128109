import React from 'react';
import { defineMessages } from 'react-intl-next';

import {
  GOAL_DIRECTORY_PATH,
  GOAL_PATH_PATTERN,
  PEOPLE_DIRECTORY_PATH,
  PEOPLE_DIRECTORY_SEARCH_PATH,
  PEOPLE_PATH_PATTERN,
  PROJECT_DIRECTORY_PATH,
  PROJECT_PATH_PATTERN,
} from '@townsquare/config/routes';

import { NoWorkspaceNavItem, NoWorkspaceNavItemType } from './NoWorkspaceNavigationItem';

const messages = defineMessages({
  projectsNavLabel: {
    id: 'townsquare.navigation.noWorkspace.project',
    defaultMessage: 'Projects',
    description: 'Label for the projects navigation item',
  },
  teamsNavLabel: {
    id: 'townsquare.navigation.noWorkspace.teams',
    defaultMessage: 'Teams',
    description: 'Label for the teams navigation item',
  },
  goalsNavLabel: {
    id: 'townsquare.navigation.noWorkspace.goals',
    defaultMessage: 'Goals',
    description: 'Label for the goals navigation item',
  },
  topicsNavLabel: {
    id: 'townsquare.navigation.noWorkspace.topics',
    defaultMessage: 'Topics',
    description: 'Label for the topics navigation item',
  },
});

const noWorkspaceNavConfig: NoWorkspaceNavItemType[] = [
  {
    label: messages.projectsNavLabel,
    path: PROJECT_DIRECTORY_PATH,
    navKey: 'projects',
    matches: [PROJECT_DIRECTORY_PATH, PROJECT_PATH_PATTERN],
  },
  {
    label: messages.goalsNavLabel,
    path: GOAL_DIRECTORY_PATH,
    navKey: 'goals',
    matches: [GOAL_DIRECTORY_PATH, GOAL_PATH_PATTERN],
  },
  {
    label: messages.teamsNavLabel,
    path: PEOPLE_DIRECTORY_PATH,
    navKey: 'teams',
    matches: [PEOPLE_DIRECTORY_PATH, PEOPLE_DIRECTORY_SEARCH_PATH, PEOPLE_PATH_PATTERN],
  },
];

export const NoWorkspaceNavItems = noWorkspaceNavConfig.map(config => (
  <NoWorkspaceNavItem key={config.navKey} {...config} />
));
