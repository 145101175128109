import { GraphQLError } from 'graphql';
import React from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { ResponsivePage } from '@townsquare/responsive-grid';

import { ERROR_IMAGE_GENERIC } from '../ErrorResources';

import BasicError from './ErrorComponents/BasicError';
import FourOhFourError from './ErrorComponents/FourOhFourError';
import Network4xxError from './ErrorComponents/Network4xxError';
import Network5xxError from './ErrorComponents/Network5xxError';
import { ErrorContainer } from './styles';

type ApolloError = Error & {
  graphQLErrors: ReadonlyArray<GraphQLError>;
  networkError: (Error & { statusCode?: number }) | null;
};

export const ErrorState = ({
  message,
  error,
  isFullPage = true,
}: {
  error?: ApolloError;
  message?: string[];
  isFullPage?: boolean;
}) => {
  const analytics = useAnalytics();
  const intl = useIntl();

  void analytics.track('errorMessage', 'shown');

  const networkError = error?.networkError;
  const graphQLErrors = error?.graphQLErrors;

  const ErrorGraphic = () => {
    if (networkError?.statusCode && networkError.statusCode >= 500) {
      return <Network5xxError />;
    }
    if (networkError?.statusCode && networkError.statusCode >= 400) {
      return <Network4xxError />;
    }

    if (graphQLErrors && graphQLErrors.length > 0) {
      const err: GraphQLError = graphQLErrors[0];
      const errPath: string | number = (err?.path?.length && err.path[0]) || 'page';

      if (err.extensions?.classification === 'DataFetchingException' && (errPath === 'project' || errPath === 'goal')) {
        return <FourOhFourError err={err} />;
      }
    }

    return (
      <BasicError
        img={ERROR_IMAGE_GENERIC}
        errorText={
          message ?? [
            intl.formatMessage({
              id: 'townsquare.error-state.error-state.message',
              defaultMessage: 'Something went wrong. Please try again later.',
              description: 'Error message displayed when something goes wrong in the error state',
            }),
          ]
        }
      />
    );
  };

  return (
    <ResponsivePage>
      <ErrorContainer isFullPage={isFullPage}>
        <ErrorGraphic />
      </ErrorContainer>
    </ResponsivePage>
  );
};
