import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { profileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';
import { useGeneratePath } from '@townsquare/router/primitives';
import { createMetaClickHandler } from '@townsquare/ui-interactions';

export const useProfilePath = (userOrTeamId: string, isUser: boolean) => {
  const route = isUser ? profileRoute : teamProfileRoute;
  const path = useGeneratePath(route, { id: userOrTeamId });
  return path;
};

export const useProfileNavigation = (
  userOrTeamId: string,
  isUser: boolean,
): [string, (e?: MouseEvent | KeyboardEvent) => void] => {
  const analytics = useAnalytics();
  const [{ route }, routerActions] = useRouter();
  const profileLink = useProfilePath(userOrTeamId, isUser);
  const handleClick = createMetaClickHandler(profileLink, routerActions, () =>
    analytics.ui('viewTeamProfile', 'clicked', { route: route.name }),
  );

  return [profileLink, useCallback((e?: MouseEvent | KeyboardEvent) => handleClick(e), [handleClick])];
};
