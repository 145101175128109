import { useEffect } from 'react';
import { fetchQuery } from 'relay-runtime';

import { RelayEnvironment } from '@townsquare/relay-utils';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import CreateProjectQuery, {
  CreateProjectQuery as CreateProjectQueryType,
} from './components/__generated__/CreateProjectQuery.graphql';

export function usePreloadProductActivations() {
  const [workspace] = useWorkspaceStore();

  useEffect(() => {
    fetchQuery<CreateProjectQueryType>(
      RelayEnvironment,
      CreateProjectQuery,
      {
        workspaceContext: {
          contextType: 'WorkspaceIdentifier',
          identifier: workspace.keyPrefix,
        },
      },
      { fetchPolicy: 'store-or-network' },
    ).toPromise();
  });
}
