import { useWorkspaceStore } from '@townsquare/workspace-store';

const DEFAULT_N2N_NAME_PREFIX = 'team-';

const EXCLUDED_DOMAINS = ['gmail.com', 'outlook.com', 'icloud.com', 'me.com', 'mac.com', 'yahoo.com', 'hotmail.com'];

export const useWorkspaceNameSuggestion = (): string | undefined => {
  const [{ name, cloudName }] = useWorkspaceStore();

  // Checking for this prefix is a bit dodgy, but this is the easiest way to check
  // if user is coming from N2N flow (and hasn't picked a site name yet)
  if (name.startsWith(DEFAULT_N2N_NAME_PREFIX) && cloudName?.startsWith(DEFAULT_N2N_NAME_PREFIX)) {
    return '';
  } else if (name.startsWith(DEFAULT_N2N_NAME_PREFIX)) {
    return cloudName ? cloudName : undefined;
  } else {
    return name;
  }
};

export const getEmailDomain = (email: string): string | undefined => {
  const domain = email.substring(email.indexOf('@') + 1);

  if (EXCLUDED_DOMAINS.includes(domain)) {
    return undefined;
  }

  return domain;
};

export const isValidDomain = (domain: string): boolean => {
  // Regular expression to match valid domain names
  const domain_regex = /^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+(\.[a-zA-Z]{1,63}?)+[a-zA-Z])$/;

  return domain_regex.test(domain) && !EXCLUDED_DOMAINS.includes(domain);
};

export const getInitialDomains = (userEmail?: string) => {
  if (!userEmail) return [];
  const emailDomain = getEmailDomain(userEmail);
  return emailDomain ? [{ label: emailDomain, value: emailDomain }] : [];
};
