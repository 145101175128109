import Button, { ButtonProps } from '@atlaskit/button';
import React, { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { goalDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { homeTabRoute } from '@townsquare/hometab-view';
import { projectDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { Link, useGeneratePath } from '@townsquare/router/primitives';
import { HomepageRouteType } from '@townsquare/router/route-types';

type Props = {
  path: string | number;
};

const useRedirectData = (errPath: string | number) => {
  const intl = useIntl();

  switch (errPath) {
    case 'goal':
      return {
        pathTo: goalDirectoryRoute,
        text: intl.formatMessage({
          id: 'townsquare.error-state.error-return-link.back-to-all-goals',
          defaultMessage: 'Back to all goals',
          description: 'Back to all goals button text',
        }),
      };
    case 'project':
    case 'channel':
      return {
        pathTo: projectDirectoryRoute,
        text: intl.formatMessage({
          id: 'townsquare.error-state.error-return-link.back-to-all-projects',
          defaultMessage: 'Back to all projects',
          description: 'Back to all projects button text',
        }),
      };
    default:
      return {
        pathTo: homeTabRoute,
        text: intl.formatMessage({
          id: 'townsquare.error-state.error-return-link.go-back-home',
          defaultMessage: 'Go back home',
          description: 'Go back home button text',
        }),
      };
  }
};

const createLinkComponent = (route: HomepageRouteType) =>
  forwardRef<HTMLAnchorElement | null, ButtonProps>(function Component({ href = '', ...rest }, ref) {
    // @ts-expect-error - ref is not a public prop
    return <Link {...rest} key={href.substr(1)} to={route} ref={ref} />;
  });

export default ({ path }: Props) => {
  const { pathTo, text } = useRedirectData(path);
  const effectivePath = useGeneratePath(pathTo);

  const Component = useMemo(() => createLinkComponent(pathTo), [pathTo]);

  return (
    <Button component={Component} href={effectivePath}>
      {text}
    </Button>
  );
};
