import InteractionContext from '@atlaskit/interaction-context';
import React, { useContext, useLayoutEffect } from 'react';
import type { ReactNode } from 'react';

type Props = {
  name: string;
  hold?: boolean;
  children?: ReactNode;
};

/**
 * This UFOHold function is sourced from this file in Jira Frontend
 * `src/packages/platform/app-framework/ufo-load-hold/src/index.tsx`.
 * Once React UFO moves to the monorepo, this can be replaced with
 * the published implementation.
 */
export function UFOHold({ children, name, hold = true }: Props) {
  const context = useContext(InteractionContext);

  useLayoutEffect(() => {
    if (hold && context != null) {
      return context.hold(name);
    }
  }, [hold, context, name]);

  // react-18: can return children directly
  return children != null ? <>{children}</> : null;
}
