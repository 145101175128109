import { loadQuery, LoadQueryOptions, PreloadedQuery } from 'react-relay';
import {
  createResource,
  type RouterContext,
  type RouterDataContext,
  type ResourceStoreContext,
  useResource,
  useQueryParam,
} from 'react-resource-router';
import type { RouteResource } from 'react-resource-router';
import type { ConcreteRequest, OperationType, VariablesOf, Environment } from 'relay-runtime';
import { v4 as uuidv4 } from 'uuid';

import { useFeatureFlag } from '@townsquare/feature-flags';
import { GetValueOptions, SupportedFlagTypes } from '@townsquare/feature-flags/types';
import { logMessage } from '@townsquare/logging';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import { getRelayEnvironment } from './RelayEnvironment';

export const RELAY_RESOURCE_TYPE = 'RELAY_RESOURCE_TYPE';

export interface RelayResourceConfig<TQuery extends OperationType> {
  query: ConcreteRequest;
  variables?: VariablesOf<TQuery>;
  options?: LoadQueryOptions;
  cacheKey?: string;
  environment?: Environment;
}

type GetQueryFunction<TQuery extends OperationType> = (
  routerContext: RouterContext | RouterDataContext,
  resourceStoreContext: ResourceStoreContext,
) => RelayResourceConfig<TQuery>;

export function createCacheKey<TQuery extends OperationType>(
  queryName: string,
  queryId?: string | null,
  variables?: VariablesOf<TQuery>,
) {
  return `relay-${queryId}-${queryName}-${JSON.stringify(variables ?? {})}`;
}

export const createRelayResource = <TQuery extends OperationType>({
  getQuery,
}: {
  getQuery: GetQueryFunction<TQuery>;
}): RouteResource<PreloadedQuery<TQuery>> => {
  const fallbackKey = uuidv4();
  return createResource({
    type: RELAY_RESOURCE_TYPE,
    getKey: (routerContext, resourceStoreContext) => {
      try {
        const { query, variables, cacheKey } = getQuery(routerContext, resourceStoreContext);

        let queryId = query.params.id;
        if ('cacheID' in query.params) {
          queryId = query.params.cacheID;
        }

        return cacheKey ?? createCacheKey(query.params.name, queryId, variables);
      } catch (error) {
        logMessage('[createRelayResource.getKey] Error creating cache key', 'fatal', { error, fallbackKey });
        return fallbackKey;
      }
    },
    getData: async (...args) => {
      const { environment, query, variables, options } = getQuery(...args);
      const queryReference = loadQuery<TQuery>(environment ?? getRelayEnvironment(), query, variables ?? {}, options);

      return queryReference;
    },
  });
};

export const useRelayResource = <TQuery extends OperationType>(resource: RouteResource<PreloadedQuery<TQuery>>) => {
  const { data: queryReference, key } = useResource(resource);
  if (!queryReference) {
    logMessage('[useRelayResource] queryReference is null', 'fatal', { key });
  }
  return queryReference;
};

export const useResourceVariables = <TQuery extends OperationType>(resource: RouteResource<PreloadedQuery<TQuery>>) => {
  const { data: queryReference } = useResource(resource);
  return queryReference?.variables;
};

export const useRelayFeatureFlag = <T extends SupportedFlagTypes>(
  flagKey: string,
  defaultValue: T,
  options?: GetValueOptions<T>,
) => {
  const flagValue = useFeatureFlag(flagKey, defaultValue, options);
  const [relay] = useQueryParam('relay');
  return Boolean(Number(relay)) && flagValue;
};

export const useRelayFeatureGate = (...args: Parameters<typeof useFeatureGate>) => {
  const flagValue = useFeatureGate(...args);
  const [relay] = useQueryParam('relay');
  return Boolean(Number(relay)) && flagValue;
};
