import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryHeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: token('space.300'),
  marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DirectoryHeaderButtons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const DirectoryHeaderText = styled.span<{ isSubtle?: boolean; isLink?: boolean }>`
  font: ${token('font.heading.large')};
  color: ${({ isSubtle }) => (isSubtle ? token('color.text.subtlest') : token('color.text'))};

  &:hover {
    ${({ isLink }) =>
      isLink
        ? css({
            cursor: 'pointer',
            color: token('color.link'),
            textDecoration: 'underline',
          })
        : ''}
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SortRow = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: token('space.200'),
});
