import { MatchParams, generatePath as RRRgeneratePath } from 'react-resource-router';

import { getConfig } from '@townsquare/config';
import { useWorkspaceType } from '@townsquare/workspace-store/hooks';

import { HomepageRouteType } from '../home/types';

import { getEffectiveTo, useGetEffectiveTo } from './useGetEffectiveTo';
import { getRouteParams, useRouteParams } from './useRouteParams';

export function generatePath(
  route: HomepageRouteType,
  options: {
    baseParams?: MatchParams;
    orgId: string;
    cloudId: string | null | undefined;
    workspaceType: ReturnType<typeof useWorkspaceType> | undefined;
  },
) {
  const { baseParams, orgId, cloudId, workspaceType } = options;
  const effectiveRoute = getEffectiveTo(route, workspaceType);
  const params = getRouteParams(baseParams, effectiveRoute.routeType, orgId, cloudId, workspaceType);
  return RRRgeneratePath(effectiveRoute.path, params);
}

export function useGeneratePath(route: HomepageRouteType, params?: MatchParams) {
  const effectiveTo = useGetEffectiveTo(route);
  const routeParams = useRouteParams(params, effectiveTo.routeType);
  return RRRgeneratePath(effectiveTo.path, routeParams);
}

export const useMainPath = () => {
  const workspaceType = useWorkspaceType();
  const config = getConfig();
  return workspaceType === 'PRODUCT' ? config.classicUrl : config.homeUrl;
};
