import { ExperienceSuccess, UFOExperience } from '@atlassian/ufo';
import React, { Suspense, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { TabBodyContainer } from '@townsquare/content-tabs';
import { Context } from '@townsquare/context-identifier';
import { useOnMount } from '@townsquare/hooks';
import { LearningType } from '@townsquare/learnings';
import { FullPageViewErrorBoundary } from '@townsquare/monitoring';
import { ProjectLearningsTabBanner } from '@townsquare/onboarding';
import { useRelayResource } from '@townsquare/relay-utils';

import {
  projectDecisionsTabResource,
  projectLearningsTabResource,
  projectRisksTabResource,
} from '../../resources/ProjectLearningsTab';

import { AddNewLearningButton } from './AddNewLearningButton';
import { LearningsEmptyState } from './LearningsEmptyState';
import { LearningsList } from './LearningsList';
import { NewLearningPanel } from './NewLearningsPanel';
import { RelatedLearnings } from './RelatedLearnings/RelatedLearnings';
import { ProjectLearningsTabQuery } from './__generated__/ProjectLearningsTabQuery.graphql';
import { LearningsHeading, LearningsListWrapper } from './styles';

type LearningActionsProps = {
  learningType?: LearningType;
  learningCount: number;
  followerCount: number;
  tagFollowerCount?: number;
  onAddLearning: () => void;
};

const LearningActions = (props: LearningActionsProps) => {
  return props.learningCount > 0 ? (
    <AddNewLearningButton onClick={props.onAddLearning} learningType={props.learningType ?? 'LEARNING'} />
  ) : (
    <LearningsEmptyState
      learningType={props.learningType ?? 'LEARNING'}
      onAddLearning={props.onAddLearning}
      followerCount={props.followerCount}
      tagFollowerCount={props.tagFollowerCount}
    />
  );
};

type Props = {
  canPostUpdate?: boolean;
  isWatching: boolean;
  focusedLearningId?: string | null;
  learningType?: LearningType;
  experience?: UFOExperience | null;
};

const ProjectLearningsTab = (props: Props & { queryRef: PreloadedQuery<ProjectLearningsTabQuery> }) => {
  const [addNewLearningIsOpen, setAddNewLearningIsOpen] = useState(false);

  const data = usePreloadedQuery<ProjectLearningsTabQuery>(
    graphql`
      query ProjectLearningsTabQuery(
        $key: String!
        $trackViewEvent: TrackViewEvent
        $learningsSort: [LearningSortEnum]
        $tagCount: Int
        $type: LearningType
      ) {
        project: projectByKey(key: $key, trackViewEvent: $trackViewEvent) {
          id @required(action: LOG)
          uuid
          state {
            label
          }
          workspace @required(action: LOG) {
            uuid
          }

          learnings(sort: $learningsSort, first: 200, type: $type) @connection(key: "LearningsList__learnings") {
            __id
            count
            edges {
              node {
                id
              }
            }
          }

          tags(first: $tagCount) {
            edges {
              node {
                id
                watcherCount
              }
            }
          }

          watchers {
            count
          }

          ...LearningsList @arguments(sort: $learningsSort, tagCount: $tagCount, type: $type)
          ...RelatedLearnings @arguments(sort: $learningsSort, type: $type)
          ...NewLearningsPanel @arguments(tagCount: $tagCount)
        }
      }
    `,
    props.queryRef,
  );

  const canEditLearningsPage = props.canPostUpdate; // Only contributors can edit the learnings page
  const analytics = useAnalytics();

  const editorContext = useMemo<Context>(
    () => ({ workspaceUUID: data?.project?.workspace.uuid ?? '', objectUUID: data?.project?.uuid ?? '' }),
    [data],
  );

  useOnMount(() => {
    const learningsExist = !!data?.project?.learnings?.count;
    const learningType = props.learningType ?? 'LEARNING';
    const formattedName = learningType.charAt(0) + learningType.slice(1).toLowerCase();
    const eventName = `project${formattedName}Tab`; // projectDecisionTab, projectRiskTab, projectLearningTab
    void analytics.ui(eventName, 'viewed', {
      isEmpty: learningsExist,
      isContributor: canEditLearningsPage,
    });
  });

  if (!data.project) {
    return null;
  }

  const tagsTotalWatchers = data.project.tags?.edges?.reduce(
    (acc, current) => acc + (current?.node?.watcherCount ?? 0),
    0,
  );

  const headingText = () => {
    switch (props.learningType) {
      case 'RISK':
        return (
          <FormattedMessage
            id="townsquare.project.risks-tab.heading"
            description="Heading for the risks tab"
            defaultMessage="What risks have you identified?"
          />
        );
      case 'DECISION':
        return (
          <FormattedMessage
            id="townsquare.project.decisions-tab.heading"
            description="Heading for the decisions tab"
            defaultMessage="What big decisions have you made?"
          />
        );
      default:
        return (
          <FormattedMessage
            id="townsquare.project.learnings-tab.heading"
            description="Heading for the learnings tab"
            defaultMessage="What have we learned?"
          />
        );
    }
  };

  return (
    <>
      <TabBodyContainer data-testid={`project-${props.learningType?.toLowerCase()}s`}>
        {props.learningType === 'LEARNING' && <ProjectLearningsTabBanner />}
        {!!data.project.learnings?.edges?.length && <LearningsHeading>{headingText()}</LearningsHeading>}
        <LearningsListWrapper>
          {canEditLearningsPage && (
            <>
              {addNewLearningIsOpen ? (
                <NewLearningPanel
                  editorContext={editorContext}
                  onClose={setAddNewLearningIsOpen}
                  canEdit={canEditLearningsPage}
                  projectId={data.project.id}
                  projectState={data.project.state?.label ?? undefined}
                  connectionId={data.project.learnings?.__id ?? ''}
                  data={data.project}
                  learningType={props.learningType ?? 'LEARNING'}
                />
              ) : (
                <LearningActions
                  learningType={props.learningType ?? 'LEARNING'}
                  learningCount={data.project.learnings?.edges?.length ?? 0}
                  followerCount={data.project.watchers?.count ?? 0}
                  tagFollowerCount={tagsTotalWatchers}
                  onAddLearning={() => setAddNewLearningIsOpen(true)}
                />
              )}
            </>
          )}
          <LearningsList
            data={data.project}
            canEdit={canEditLearningsPage}
            focusedLearningId={props.focusedLearningId}
          />
          <RelatedLearnings data={data.project} learningType={props.learningType ?? 'LEARNING'} />
        </LearningsListWrapper>
      </TabBodyContainer>
      {props.experience && <ExperienceSuccess experience={props.experience} />}
    </>
  );
};

const ProjectLearningsWrapper = (props: Props) => {
  let resource;
  if (props.learningType === 'RISK') {
    resource = projectRisksTabResource;
  } else if (props.learningType === 'DECISION') {
    resource = projectDecisionsTabResource;
  } else {
    resource = projectLearningsTabResource;
  }
  const queryRef = useRelayResource(resource);
  const [routerState] = useRouter();

  if (!queryRef) {
    return null;
  }

  return (
    <FullPageViewErrorBoundary>
      <Suspense fallback={false}>
        <ProjectLearningsTab {...props} queryRef={queryRef} focusedLearningId={routerState.match.params.resourceId} />
      </Suspense>
    </FullPageViewErrorBoundary>
  );
};

export { ProjectLearningsWrapper as ProjectLearningsTab };
export default ProjectLearningsWrapper;
