import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

const getSize = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'large':
      return '40px';
    case 'medium':
      return '32px';
    default:
      return '24px';
  }
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const BlankIconOrAvatar = styled.div<{ size: 'small' | 'medium' | 'large'; isAvatar: boolean }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: getSize(props.size),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: getSize(props.size),
  background: token('color.background.neutral'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: props.isAvatar ? '50%' : '3px',
}));
