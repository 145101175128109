import parseISO from 'date-fns/parseISO';

import { ISO_LOCAL_DATE_TIME } from './constants';
import { parse } from './mixins';

export const parseIsoLocalDateTime = (dateString: string | undefined, defaultValue: Date, timezoneOffset?: number) => {
  if (dateString) {
    let date = parse(dateString, ISO_LOCAL_DATE_TIME, defaultValue);

    // Used to shift dates back to UTC time (parse will automatically add the user's timezone to dates)
    if (date && timezoneOffset) {
      date = new Date(date.valueOf() + timezoneOffset);
    }

    if (!date || isNaN(date.getTime())) {
      date = parseISO(dateString);
    }

    if (!date || isNaN(date.getTime())) {
      date = defaultValue;
    }

    return date;
  } else {
    return defaultValue;
  }
};
