/**
 * @generated SignedSource<<42b1cb94bb648fe993e437f95bfcdf86>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DirectoryViewSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "FAVOURITED_DATE_ASC" | "FAVOURITED_DATE_DESC" | "NAME_ASC" | "NAME_DESC";
export type DirectoryFavoriteViewsQuery$variables = {
  first: number;
  q: string;
  sort?: ReadonlyArray<DirectoryViewSortEnum | null> | null;
  workspaceUuid: string;
};
export type DirectoryFavoriteViewsQuery$data = {
  readonly directoryViewTql: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null;
        readonly uuid: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"DirectoryFavoriteViewButton" | "DirectoryManageViewDialog">;
      } | null;
    } | null> | null;
  } | null;
};
export type DirectoryFavoriteViewsQuery = {
  response: DirectoryFavoriteViewsQuery$data;
  variables: DirectoryFavoriteViewsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v4 = {
  "kind": "Variable",
  "name": "q",
  "variableName": "q"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceUuid",
  "variableName": "workspaceUuid"
},
v6 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v7 = {
  "kind": "ScalarField",
  "name": "name"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v10 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v12 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  },
  (v5/*: any*/)
],
v13 = {
  "kind": "ScalarField",
  "name": "id"
},
v14 = [
  {
    "kind": "ScalarField",
    "name": "aaid"
  },
  {
    "concreteType": "UserPII",
    "kind": "LinkedField",
    "name": "pii",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      {
        "kind": "ScalarField",
        "name": "picture"
      }
    ]
  },
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "DirectoryFavoriteViewsQuery",
    "selections": [
      {
        "alias": "directoryViewTql",
        "args": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "concreteType": "DirectoryViewConnection",
        "kind": "LinkedField",
        "name": "__DirectoryFavoriteViews_directoryViewTql_connection",
        "plural": false,
        "selections": [
          {
            "concreteType": "DirectoryViewEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "DirectoryView",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "DirectoryFavoriteViewButton"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "DirectoryManageViewDialog"
                  },
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DirectoryFavoriteViewsQuery",
    "selections": [
      {
        "args": (v12/*: any*/),
        "concreteType": "DirectoryViewConnection",
        "kind": "LinkedField",
        "name": "directoryViewTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "DirectoryViewEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "DirectoryView",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v13/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "isFavourite"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "entityType"
                  },
                  {
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": (v14/*: any*/)
                  },
                  {
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "lastEditedBy",
                    "plural": false,
                    "selections": (v14/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "name": "creationDate"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "lastEditedDate"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "favouriteCount"
                  },
                  (v8/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ]
      },
      {
        "args": (v12/*: any*/),
        "filters": [
          "q",
          "workspaceUuid"
        ],
        "handle": "connection",
        "key": "DirectoryFavoriteViews_directoryViewTql",
        "kind": "LinkedHandle",
        "name": "directoryViewTql"
      }
    ]
  },
  "params": {
    "cacheID": "6828999cc4d2cac3dc28bc5ada88775b",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": null,
          "direction": "forward",
          "path": [
            "directoryViewTql"
          ]
        }
      ]
    },
    "name": "DirectoryFavoriteViewsQuery",
    "operationKind": "query",
    "text": "query DirectoryFavoriteViewsQuery(\n  $q: String!\n  $first: Int!\n  $workspaceUuid: UUID!\n  $sort: [DirectoryViewSortEnum]\n) {\n  directoryViewTql(q: $q, first: $first, workspaceUuid: $workspaceUuid, sort: $sort) {\n    edges {\n      node {\n        uuid\n        name\n        ...DirectoryFavoriteViewButton\n        ...DirectoryManageViewDialog\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment DeleteDirectoryViewModal on DirectoryView {\n  name\n  uuid\n  isFavourite\n  favouriteCount\n}\n\nfragment DirectoryFavoriteViewButton on DirectoryView {\n  id\n  uuid\n  name\n  isFavourite\n  entityType\n  ...DirectoryManageViewDialog\n}\n\nfragment DirectoryManageViewDialog on DirectoryView {\n  entityType\n  isFavourite\n  creator {\n    aaid\n    pii {\n      name\n      picture\n    }\n    id\n  }\n  lastEditedBy {\n    aaid\n    pii {\n      name\n      picture\n    }\n    id\n  }\n  creationDate\n  lastEditedDate\n  ...RenameDirectoryViewModal\n  ...DeleteDirectoryViewModal\n}\n\nfragment RenameDirectoryViewModal on DirectoryView {\n  name\n  uuid\n}\n"
  }
};
})();

(node as any).hash = "203fc2ee763e7c2cb5f13835d01ed25a";

export default node;
