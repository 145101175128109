import { useEffect, useState } from 'react';
import { useQueryParam } from 'react-resource-router';

export const useQueryParamWithDefault = (paramKey: string, defaultValue?: string | undefined) => {
  const [initialised, setInitialised] = useState(false);
  const [value, setValue] = useQueryParam(paramKey);

  useEffect(() => {
    if (!value && !initialised && defaultValue) {
      setValue(defaultValue, 'replace');
      setInitialised(true);
    }
  }, [defaultValue, initialised, value, setValue]);

  return [value, setValue] as const;
};
