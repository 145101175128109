import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';
import { stylesForNestedProfilePicture } from '@townsquare/profile-picture';
import { onMobile } from '@townsquare/responsive-grid';
import { Link } from '@townsquare/router/primitives';
import { StyledTeamIcon } from '@townsquare/team-icon';

import { SEARCH_CARD_STYLE } from '../styles';

interface StyledPersonSearchResultCardProps {
  heightPx: number;
  widthPx: number;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledPersonSearchResultCard = styled(Link)<StyledPersonSearchResultCardProps>`
  ${SEARCH_CARD_STYLE}

  height: ${({ heightPx }) => heightPx}px;
  width: ${({ widthPx }) => widthPx}px;

  display: flex;
  flex-direction: column;
  gap: ${token('space.200')};

  ${onMobile(
    css({
      flexDirection: 'row',
      height: '118px',
      width: '100%',
      border: 'unset',
      padding: 0,
      marginBlockEnd: token('space.150'),
    }),
  )}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ProfilePictureContainer = styled.div`
  ${stylesForNestedProfilePicture(
    onMobile(
      css({
        height: '118px',
        width: '100px',
      }),
    ),
  )}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const TeamsContainer = styled.div<{ isDropdownVisible: boolean }>(props => ({
  minHeight: '48px',
  maxHeight: '48px',
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  flexDirection: props.isDropdownVisible ? 'row-reverse' : 'row',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  justifyContent: props.isDropdownVisible ? 'flex-end' : 'flex-start',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  flexWrap: props.isDropdownVisible ? 'wrap-reverse' : 'wrap',
  alignItems: 'baseline',
  gap: token('space.100'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledTeamTag = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> span': {
    margin: 0,
    alignItems: 'center',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '> a, > div': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      textDecoration: 'none !important',
      cursor: 'pointer',
    },
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledTeamDropdown = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> div:nth-child(2)': {
    padding: 0,
    cursor: 'default',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '> div': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      rowGap: token('space.100'),
      padding: token('space.150'),
    },
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledTeamDropdownItem = styled.div`
  ${StyledTeamTag} {
    > span {
      max-width: 100%;

      > a {
        max-width: 300px;
      }
    }
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  columnGap: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NameText = styled.h4(
  {
    font: token('font.heading.small'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
  {
    marginBottom: token('space.025'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      font: token('font.body'),
      fontWeight: token('font.weight.medium'),
    }),
  ),
);

export interface LocationTextProps {
  maxWidth: string;
}
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const LocationText = styled.p<LocationTextProps>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  maxWidth: props.maxWidth,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const TeamIconContainer = styled.div`
  ${StyledTeamIcon} {
    margin: ${token('space.025')};
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileFieldsContainer = styled.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      width: 'calc(100% - 116px)',
    }),
  ),
);
