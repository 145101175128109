import React, { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useQueryParam } from 'react-resource-router';

import { DescriptionEditor } from '@townsquare/description-editor';
import { preloadEditor } from '@townsquare/editor';
import { Learning } from '@townsquare/learnings';
import { Renderer } from '@townsquare/renderer';

import { LearningsList$key } from './__generated__/LearningsList.graphql';

type Props = {
  data: LearningsList$key;
  canEdit?: boolean;
  focusedLearningId?: string | null;
  useConditionalRendering?: boolean;
};

export const LearningsList = (props: Props) => {
  const data = useFragment(
    graphql`
      fragment LearningsList on Project
      @argumentDefinitions(
        sort: { type: "[LearningSortEnum]", defaultValue: [SUMMARY_ASC] }
        tagCount: { type: "Int" }
        type: { type: "LearningType" }
      ) {
        learnings(sort: $sort, first: 200, type: $type) @connection(key: "LearningsList__learnings") {
          __id
          count
          edges {
            node {
              id
              uuid
              type
              ... on Highlight {
                ...Learning_data
              }
            }
          }
        }
        watcherCount
        tags(first: $tagCount) {
          ...LearningTags_data
        }
      }
    `,
    props.data,
  );

  useEffect(() => {
    if (data.learnings?.count) {
      if (props.canEdit) {
        DescriptionEditor.preload();
        preloadEditor();
      }
      Renderer.preload();
    }
  }, [data, props]);

  const [showLearningsQueryParam] = useQueryParam('showLearnings');
  const [showRisksQueryParam] = useQueryParam('showRisks');
  const [showDecisionsQueryParam] = useQueryParam('showDecisions');

  /**
   * Show highlights in most recently created order if highlights enabled
   * TODO: change the learning sort to 'CREATION_DATE_DESC' in views/project/src/components/ProjectLearningsTab/constants.ts
   * and '@prependNode' instead in createLearning mutation when highlights is released.
   */
  const learningsArray = Array.from(data.learnings?.edges ?? []).reverse();

  return (
    <>
      {learningsArray.map(learning => {
        if (!learning || !learning.node) {
          return null;
        }

        if (props.useConditionalRendering) {
          if (learning.node.type === 'LEARNING' && showLearningsQueryParam !== 'true') {
            return null;
          }

          if (learning.node.type === 'RISK' && showRisksQueryParam !== 'true') {
            return null;
          }

          if (learning.node.type === 'DECISION' && showDecisionsQueryParam !== 'true') {
            return null;
          }
        }

        return (
          <Learning
            key={learning.node.id}
            data={learning.node}
            watcherCount={data.watcherCount}
            tags={data.tags}
            canEdit={!!props.canEdit}
            canDelete={!!props.canEdit}
            // If this learning is not explicitly focused still allowed it to be initially open if the component wants it (newly created)
            isInitiallyOpen={props.focusedLearningId === learning.node.uuid || undefined}
            connectionId={data.learnings?.__id ?? ''}
            learningType={learning.node.type || 'LEARNING'}
          />
        );
      })}
    </>
  );
};
