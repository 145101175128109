import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

import { ActionBarItem } from '@townsquare/action-bar';
import { Reactions } from '@townsquare/reactions';

const LearningReactionsContainer = styled(ActionBarItem)<{ disablePicker: boolean }>(
  {
    display: 'contents',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({ disablePicker }) =>
    // ahhhh man ... fuck ... I'm sorry. I'm not proud of this
    // We want to disable the picker when learning is in collapsed mode
    // There is no ... other way to hide the picker ...
    disablePicker
      ? `
          display: flex;
          gap: ${token('space.050')};
          flex-wrap: wrap;
          [data-testid="reactionPicker-testid"] {
            display: none !important;
          }
        `
      : '',
);

type Props = {
  defaultReactionsEnabled?: boolean;
  learningAri?: string;
  containerAri?: string;
};

export const LearningReactions = ({ defaultReactionsEnabled, learningAri = '', containerAri = '' }: Props) => (
  <LearningReactionsContainer
    disablePicker={!defaultReactionsEnabled}
    onClick={e => {
      // Prevent clicks bubbling up and expanding or collapsing the learning card unintentionally
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    <Reactions
      ari={learningAri}
      containerAri={containerAri}
      containerType="learning"
      defaultReactionsEnabled={defaultReactionsEnabled}
    />
  </LearningReactionsContainer>
);
