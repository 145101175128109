import AkLegacyButton from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import AkAddIcon from '@atlaskit/icon/utility/add';
import { token } from '@atlaskit/tokens';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { OnChangeOption, OptionTypes, Resolver } from '../types';

import { ResolverPopup } from './ResolverPopup';
import { AddIcon } from './icons/Add';
import { AddEntityWrapper } from './style';

interface AddEntityProps<T extends Resolver = Resolver> {
  resolver: T;
  onChange: (option: OnChangeOption<T>) => void;
}

const AddEntity = <T extends Resolver>({ resolver, onChange }: AddEntityProps<T>) => {
  const analytics = useAnalytics();
  const intl = useIntl();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isHomeVisualRefreshEnabled = useIsHomeVisualRefreshEnabled();
  const popupType = useMemo<'popup' | 'popupSelect'>(() => {
    // Workaround for the fact that we don't have a way to programmatically open the
    // PopupSelect component.
    if (resolver.optionType === OptionTypes.SELECT) {
      return 'popupSelect';
    }
    return 'popup';
  }, [resolver.optionType]);

  const handleOnClose = useCallback(() => {
    if (popupType === 'popup') {
      setIsPopupOpen(false);
    }
    void analytics.ui('metaFilterAddEntity', 'closed');
  }, [analytics, popupType]);

  const handleOnClick = useCallback(
    (isPopupSelectOpen: boolean | undefined) => {
      if (popupType === 'popup') {
        setIsPopupOpen(!isPopupOpen);
        void analytics.ui('metaFilterAddEntity', isPopupOpen ? 'closed' : 'opened');
      } else {
        if (isPopupSelectOpen) {
          void analytics.ui('metaFilterAddEntity', 'opened');
        }
      }
    },
    [analytics, isPopupOpen, popupType],
  );

  const iconLabel = intl.formatMessage({
    id: 'townsquare.tql.add-entity.icon',
    description: 'Add entity icon',
    defaultMessage: 'Add another entity',
  });

  return (
    <ResolverPopup
      resolver={resolver}
      onChange={onChange}
      onClose={handleOnClose}
      isOpen={popupType === 'popup' ? isPopupOpen : undefined}
      target={({ isOpen: isPopupSelectOpen, ...triggerProps }) => {
        const isOpen = popupType === 'popupSelect' ? isPopupSelectOpen : isPopupOpen;

        return isHomeVisualRefreshEnabled ? (
          <IconButton
            {...triggerProps}
            appearance="subtle"
            spacing="compact"
            icon={iconProps => <AkAddIcon {...iconProps} color="currentColor" LEGACY_size="small" />}
            label={iconLabel}
            onClick={() => handleOnClick(isOpen)}
            testId="metafilter-add-entity"
          />
        ) : (
          <AkLegacyButton
            {...triggerProps}
            spacing="none"
            appearance="subtle"
            isSelected={isOpen}
            onClick={() => handleOnClick(isOpen)}
            style={{
              // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
              margin: '0',
            }}
            iconAfter={
              <AddEntityWrapper isOpen={isOpen}>
                <AddIcon
                  primaryColor={isOpen ? token('color.icon.inverse') : token('color.icon.subtle')}
                  label={iconLabel}
                />
              </AddEntityWrapper>
            }
            testId="metafilter-add-entity"
          />
        );
      }}
    />
  );
};

export default AddEntity;
