import { API_PATHNAME_STG_PROD, usePostOfficeEnvironment } from '@atlassian/post-office-context';
import { useMemo } from 'react';

export type BuiltBasePath = {
	baseUrl?: string;
	path: string;
};

type BuildBasePath = () => BuiltBasePath;

export const useBuildBasePath: BuildBasePath = () => {
	const { currentEnv, envConfigs } = usePostOfficeEnvironment();

	return useMemo<BuiltBasePath>(() => {
		const baseUrl = envConfigs[currentEnv].api.origin;

		// TypeScript & Safety reasons: Final default to staging-prod API path in case path got overriden somewhere to be undefined, but this should not be the case
		const path = envConfigs[currentEnv].api.pathname ?? API_PATHNAME_STG_PROD;

		return {
			baseUrl,
			path,
		};
	}, [currentEnv, envConfigs]);
};
