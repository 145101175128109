import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import SavedViewUpdatesAsideQuery, {
  type SavedViewUpdatesAsideQuery as SavedViewUpdatesAsideQueryType,
} from '../SavedViewUpdates/__generated__/SavedViewUpdatesAsideQuery.graphql';

export const savedViewUpdatesAsideResource = createRelayResource<SavedViewUpdatesAsideQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;

    return {
      query: SavedViewUpdatesAsideQuery,
      variables: {
        workspaceUuid: context.workspace?.UUID || '',
        viewUuid: routerContext.match.params.uuid || '',
        isNavRefreshEnabled: isNavRefreshEnabled(context),
      },
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
