import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import React, { KeyboardEvent, MouseEvent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import styled from 'styled-components';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { filterNull } from '@townsquare/filter-type';
import { ProfilePicture } from '@townsquare/profile-picture';
import { profileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';
import { Link } from '@townsquare/router/primitives';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { SearchResultTeamMember } from '../../SearchResult/Team';

import { TeamCard$key } from './__generated__/TeamCard.graphql';
import {
  AvatarGroup,
  AvatarSquare,
  AvatarSquarePlaceholder,
  DescriptionText,
  NameText,
  StyledBrowseTeamCard,
  TeamDetails,
} from './styles';

export interface BrowseTeamMember {
  name: string;
  aaid: string;
  picture?: string;
}

export interface BrowseTeam {
  name: string;
  description?: string;
  teamId: string;
  members: BrowseTeamMember[];
  memberCount: number;
}

const teamDetailsStyles = xcss({
  minWidth: '0',
});

const avatarGroupStyles = xcss({
  height: '164px',
  width: '164px',
  gap: 'space.050',
  flexWrap: 'wrap',
  flexShrink: '0',
});

const avatarPlaceholderStyles = xcss({
  height: '80px',
  width: '80px',
  backgroundColor: 'elevation.surface.sunken',
});

const avatarSquareStyles = xcss({
  height: '80px',
  width: '80px',
  position: 'relative',

  ':after': {
    borderRadius: 'border.radius',
    position: 'absolute',
    inset: '0px',
    content: "''",
    backgroundColor: 'color.background.neutral.hovered',
    opacity: '0',
    height: '80px',
    width: '80px',
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
  },

  ':hover::after': {
    opacity: '1',
  },
});

export const MAX_MEMBERS_TO_DISPLAY = 4;

const TeamMemberAvatarGroup = ({ members: membersToDisplay }: Pick<BrowseTeam, 'members'>) => {
  const analytics = useAnalytics();

  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  const emptySlotPlaceholders = useMemo(
    () =>
      membersToDisplay.length < MAX_MEMBERS_TO_DISPLAY
        ? [...Array(MAX_MEMBERS_TO_DISPLAY - membersToDisplay.length)].map((_, i) =>
            enableHomeVisualRefresh ? (
              <Box xcss={avatarPlaceholderStyles} key={i} />
            ) : (
              <AvatarSquarePlaceholder key={i} />
            ),
          )
        : null,
    [enableHomeVisualRefresh, membersToDisplay.length],
  );

  const teamMembers = useMemo(
    () =>
      membersToDisplay
        .map(({ name, picture, aaid }) => (
          <Tooltip content={name} key={aaid}>
            {enableHomeVisualRefresh ? (
              <Link
                key={aaid}
                to={profileRoute}
                params={{ id: aaid }}
                onClick={(e: MouseEvent | KeyboardEvent) => {
                  e.stopPropagation();
                  void analytics.ui('browseTeamCardMemberAvatar', 'clicked');
                }}
              >
                <Box xcss={avatarSquareStyles}>
                  <ProfilePicture name={name} src={picture} height="80px" width="80px" />
                </Box>
              </Link>
            ) : (
              <AvatarSquare
                key={aaid}
                to={profileRoute}
                params={{ id: aaid }}
                onClick={(e: MouseEvent | KeyboardEvent) => {
                  e.stopPropagation();
                  void analytics.ui('browseTeamCardMemberAvatar', 'clicked');
                }}
              >
                <ProfilePicture name={name} src={picture} height="100%" width="100%" />
              </AvatarSquare>
            )}
          </Tooltip>
        ))
        .slice(0, MAX_MEMBERS_TO_DISPLAY),
    [analytics, enableHomeVisualRefresh, membersToDisplay],
  );

  if (enableHomeVisualRefresh) {
    return (
      <Inline xcss={avatarGroupStyles}>
        {teamMembers}
        {emptySlotPlaceholders}
      </Inline>
    );
  }

  return (
    <AvatarGroup>
      {teamMembers}
      {emptySlotPlaceholders}
    </AvatarGroup>
  );
};

const cardStyles = xcss({
  minWidth: '340px',
  height: '204px',
  borderRadius: '4px',
  borderColor: 'color.border',
  borderWidth: 'border.width',
  borderStyle: 'solid',
  padding: 'space.250',
  gap: 'space.150',
});

const NoUnderlineLink = styled(Link)({
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'none',
  },
});

interface BrowseTeamCardProps {
  data: TeamCard$key;
}

export const BrowseTeamCard = (props: BrowseTeamCardProps) => {
  const analytics = useAnalytics();

  const onClick = () => {
    void analytics.ui('browseTeamCard', 'clicked');
  };

  const team = useFragment(
    graphql`
      fragment TeamCard on TeamSearchResponse {
        id
        displayName
        description
        members {
          count
          edges {
            node {
              aaid
              pii {
                name
                picture
              }
            }
          }
        }
      }
    `,
    props.data,
  );

  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  const { id, displayName, description, members } = team;

  const teamData =
    team.members?.edges
      ?.map<SearchResultTeamMember | null>(node => {
        const member = node?.node;
        if (member?.aaid && member.pii && member.pii.name) {
          return {
            aaid: member?.aaid,
            name: member.pii.name,
            picture: member.pii.picture ?? undefined,
          };
        }
        return null;
      })
      .filter(filterNull) ?? [];

  const teamId = id?.replace('ari:cloud:identity::team/', '');

  if (enableHomeVisualRefresh) {
    return (
      <NoUnderlineLink
        data-testid={`team-browse-card-${teamId}`}
        onClick={onClick}
        to={teamProfileRoute}
        params={{ id: teamId }}
        key={teamId}
      >
        <Inline xcss={cardStyles}>
          <TeamMemberAvatarGroup members={teamData} />
          <Stack xcss={teamDetailsStyles}>
            <EllipsisTooltip content={displayName}>
              <NameText>{displayName}</NameText>
            </EllipsisTooltip>
            <Text as="p" color="color.text.subtle" maxLines={1}>
              <FormattedMessage
                id="townsquare.views.directory.browse-team-card-member-count"
                description="The number of members in a team. Example: 5 members"
                defaultMessage="{memberCount, plural, one {# member} other {# members}}"
                values={{ memberCount: members?.count }}
              />
            </Text>
            <DescriptionText>{description}</DescriptionText>
          </Stack>
        </Inline>
      </NoUnderlineLink>
    );
  }

  return (
    <StyledBrowseTeamCard
      data-testid={`team-browse-card-${teamId}`}
      onClick={onClick}
      to={teamProfileRoute}
      params={{ id: teamId }}
      key={teamId}
    >
      <TeamMemberAvatarGroup members={teamData} />
      <TeamDetails>
        <EllipsisTooltip content={displayName}>
          <NameText>{displayName}</NameText>
        </EllipsisTooltip>
        <Text as="p" color="color.text.subtle" maxLines={1}>
          <FormattedMessage
            id="townsquare.views.directory.browse-team-card-member-count"
            description="The number of members in a team. Example: 5 members"
            defaultMessage="{memberCount, plural, one {# member} other {# members}}"
            values={{ memberCount: members?.count }}
          />
        </Text>
        <DescriptionText>{description}</DescriptionText>
      </TeamDetails>
    </StyledBrowseTeamCard>
  );
};
