import { IntlShape } from 'react-intl-next';

import { getShortcutModifier } from '@townsquare/os-utils';

import { type KeyboardShortcutAction } from './types';

// Blocks non-modified shortcut combos from having option+ or alt+ at the start
type StrictKeyModifier<T extends string> = T extends `option+${infer T}`
  ? never
  : T extends `alt+${infer T}`
  ? never
  : T;

function createModifier<T extends string>(i: StrictKeyModifier<T>) {
  return i;
}

export const MODIFIER_PREFIX = getShortcutModifier();

// Global
export const OPEN_KEYBOARD_SHORTCUTS_MENU = createModifier('shift+/');
export const QUICK_SEARCH_SHORTCUT = createModifier('/');
export const CREATE_NEW_SHORTCUT = createModifier('c');
export const GO_HOME_PAGE_SHORTCUT = createModifier('g h');
export const GO_PROJECTS_PAGE_SHORTCUT = createModifier('g p');
export const GO_GOALS_PAGE_SHORTCUT = createModifier('g g');
export const GO_TEAMS_PAGE_SHORTCUT = createModifier('g t');
export const GO_TAGS_PAGE_SHORTCUT = createModifier('g o');
export const SEARCH_DIRECTORY_SHORTCUT = createModifier('s d');

// Home page
export const PREVIOUS_PAGE_SHORTCUT = createModifier('j');
export const NEXT_PAGE_SHORTCUT = createModifier('k');
export const PREVIOUS_ITEM_SHORTCUT = createModifier('p');
export const NEXT_ITEM_SHORTCUT = createModifier('n');

// Reading mode
export const ACTIVATE_READING_MODE_SHORTCUT = createModifier('r');
export const READING_MODE_PREVIOUS_PAGE_SHORTCUT = createModifier('left');
export const READING_MODE_PREVIOUS_PAGE_SHORTCUT_1 = createModifier('k');
export const READING_MODE_NEXT_PAGE_SHORTCUT = createModifier('right');
export const READING_MODE_NEXT_PAGE_SHORTCUT_1 = createModifier('j');
export const READING_MODE_NEW_COMMENT_SHORTCUT = createModifier('m');
export const READING_MODE_EXPAND_MORE_DETAIL_SHORTCUT = createModifier('d');
export const READING_MODE_RESET_SHORTCUT = createModifier('x');
export const READING_MODE_FIRST_DEFAULT_REACTION_SHORTCUT = createModifier('1');
export const READING_MODE_SECOND_DEFAULT_REACTION_SHORTCUT = createModifier('2');
export const READING_MODE_THIRD_DEFAULT_REACTION_SHORTCUT = createModifier('3');
export const READING_MODE_FOURTH_DEFAULT_REACTION_SHORTCUT = createModifier('4');
export const READING_MODE_FIFTH_DEFAULT_REACTION_SHORTCUT = createModifier('5');
export const ESCAPE = createModifier('escape');

// Project and goal page
export const FOLLOW_SHORTCUT = createModifier('w');
export const SHARE_SHORTCUT = createModifier('s');
export const POST_UPDATE_SHORTCUT = createModifier('p');
export const COPY_LINK_SHORTCUT = createModifier('k');
export const GO_ABOUT_TAB_SHORTCUT = createModifier('g a');
export const GO_UPDATES_TAB_SHORTCUT = createModifier('g u');
export const GO_LEARNINGS_TAB_SHORTCUT = createModifier('g l');

// Shortcuts to include in the keyboard shortcuts list apps/web/src/ui/Navigation/Help/ShortcutsList.tsx
export const getGlobalShortcutsList = (intl: IntlShape): KeyboardShortcutAction[] => [
  {
    key: OPEN_KEYBOARD_SHORTCUTS_MENU,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.open-keyboard-shortcuts-menu',
      description: 'Keyboard shortcuts',
      defaultMessage: 'Keyboard shortcuts',
    }),
    type: 'Global',
  },
  {
    key: QUICK_SEARCH_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.quick-search',
      description: 'Quick search',
      defaultMessage: 'Quick search',
    }),
    type: 'Global',
  },
  {
    key: CREATE_NEW_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.create-new',
      description: 'Create new',
      defaultMessage: 'Create new',
    }),
    type: 'Global',
  },
  {
    key: GO_HOME_PAGE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-home',
      description: 'Go home',
      defaultMessage: 'Go home',
    }),
    type: 'Global',
  },
  {
    key: GO_PROJECTS_PAGE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-projects',
      description: 'Go projects',
      defaultMessage: 'Go projects',
    }),
    type: 'Global',
  },
  {
    key: GO_GOALS_PAGE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-goals',
      description: 'Go goals',
      defaultMessage: 'Go goals',
    }),
    type: 'Global',
  },
  {
    key: GO_TEAMS_PAGE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-teams',
      description: 'Go teams',
      defaultMessage: 'Go teams',
    }),
    type: 'Global',
  },
  {
    key: GO_TAGS_PAGE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-tags',
      description: 'Go tags',
      defaultMessage: 'Go tags',
    }),
    type: 'Global',
  },
];

export const getHomeUpdatesShortcutsList = (intl: IntlShape): KeyboardShortcutAction[] => [
  {
    key: PREVIOUS_PAGE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.previous-page',
      description: 'Previous page',
      defaultMessage: 'Previous page',
    }),
    type: 'Home page updates',
  },
  {
    key: NEXT_PAGE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.next-page',
      description: 'Next page',
      defaultMessage: 'Next page',
    }),
    type: 'Home page updates',
  },
  {
    key: PREVIOUS_ITEM_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.previous-item',
      description: 'Previous item',
      defaultMessage: 'Previous item',
    }),
    type: 'Home page updates',
  },
  {
    key: NEXT_ITEM_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.next-item',
      description: 'Next item',
      defaultMessage: 'Next item',
    }),
    type: 'Home page updates',
  },
  {
    key: ACTIVATE_READING_MODE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.activate-reading-mode',
      description: 'Activate reading mode',
      defaultMessage: 'Activate reading mode',
    }),
    type: 'Home page updates',
  },
];

export const getProjectAndGoalShortcutsList = (intl: IntlShape): KeyboardShortcutAction[] => [
  {
    key: FOLLOW_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.follow',
      description: 'Follow',
      defaultMessage: 'Follow',
    }),
    type: 'Projects and goals',
  },
  {
    key: SHARE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.share',
      description: 'Share',
      defaultMessage: 'Share',
    }),
    type: 'Projects and goals',
  },
  {
    key: POST_UPDATE_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.post-update',
      description: 'Post an update',
      defaultMessage: 'Post an update',
    }),
    type: 'Projects and goals',
  },
  {
    key: COPY_LINK_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.copy-link',
      description: 'Copy link to clipboard',
      defaultMessage: 'Copy link to clipboard',
    }),
    type: 'Projects and goals',
  },
  {
    key: GO_ABOUT_TAB_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-about-tab',
      description: 'Go about tab',
      defaultMessage: 'Go about tab',
    }),
    type: 'Projects and goals',
  },
  {
    key: GO_UPDATES_TAB_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-updates-tab',
      description: 'Go updates tab',
      defaultMessage: 'Go updates tab',
    }),
    type: 'Projects and goals',
  },
  {
    key: GO_LEARNINGS_TAB_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.go-learnings-tab',
      description: 'Go learnings tab',
      defaultMessage: 'Go learnings tab',
    }),
    type: 'Projects and goals',
  },
];

export const getDirectoryShortcutsList = (intl: IntlShape): KeyboardShortcutAction[] => [
  {
    key: SEARCH_DIRECTORY_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.search-directory',
      description: 'Search in directory',
      defaultMessage: 'Search in directory',
    }),
    type: 'Directory',
  },
  {
    key: COPY_LINK_SHORTCUT,
    action: intl.formatMessage({
      id: 'townsquare.packages.keyboard-shortcuts.copy-link',
      description: 'Copy link to clipboard',
      defaultMessage: 'Copy link to clipboard',
    }),
    type: 'Directory',
  },
];
