import { createActionsHook, createHook, createStore, createStateHook } from 'react-sweet-state';

import { OrgContext } from './types';

const initialState: OrgContext = {
  orgId: '',
  cloudIds: [],
  workspaceIds: [],
  workspaceUuids: [],
};

const orgContextStore = createStore({
  initialState,
  actions: {
    setOrgContext:
      (orgContext: OrgContext) =>
      ({ setState }) => {
        setState(orgContext);
      },
  },
  name: 'org-context-store',
});

export const useOrgContextStore = createHook(orgContextStore);
export const useOrgContextActions = createActionsHook(orgContextStore);

// State hooks
export const useNoOrgExperience = createStateHook(orgContextStore, { selector: state => !state.orgId });
export const useOrgContextOrgId = createStateHook(orgContextStore, { selector: state => state.orgId });
export const useOrgContextCloudIds = createStateHook(orgContextStore, { selector: state => state.cloudIds });
export const useOrgContextWorkspaceIds = createStateHook(orgContextStore, { selector: state => state.workspaceIds });
export const useOrgContextWorkspaceUuids = createStateHook(orgContextStore, {
  selector: state => state.workspaceUuids,
});
