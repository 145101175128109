import {
  CapabilitySubject,
  ExperienceConfigPreset,
  generateErrorMetadata,
  useExperienceTracker,
} from '@townsquare/monitoring';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { type Team, type TeamCreateDialogProps } from './components/AkPeopleTeams';

export interface TriggerCreateTeamModalProps {
  onCreate?: (team: Team) => void;
  onCancel?: () => void;
  analyticsCtx?: object;
}

export type UseCreateTeamModalProps = Partial<TeamCreateDialogProps> &
  Required<Pick<TeamCreateDialogProps, 'cloudId' | 'principalId' | 'product'>>;

export const useCreateTeamModalProps = (props: TriggerCreateTeamModalProps = {}): UseCreateTeamModalProps => {
  const [user] = useUserStore();
  const [{ cloudId, organisationId }] = useWorkspaceStore();
  const teamCreatedExperienceTracker = useExperienceTracker(
    CapabilitySubject.TeamCreated,
    ExperienceConfigPreset.ExperienceInlineResult,
  );

  const { onCreate, onCancel } = {
    onCreate: () => null,
    onCancel: () => null,
    ...props,
  };

  const handleOnCreate = (team: Team) => {
    void teamCreatedExperienceTracker.success();
    onCreate(team);
  };

  const handleOnFail: TeamCreateDialogProps['onCreateTeamFailed'] = error => {
    void teamCreatedExperienceTracker.failure({ metadata: generateErrorMetadata(error) });
  };

  const onClose = () => {
    void teamCreatedExperienceTracker.abort();
    onCancel();
  };

  return {
    cloudId: cloudId,
    orgId: organisationId,
    product: 'atlas',
    principalId: user.accountId,
    onClose,
    onCreateTeamSuccess: handleOnCreate,
    onCreateTeamFailed: handleOnFail,
    extraAnalyticsAttrs: props.analyticsCtx,
    enableMembershipSettingsChoice: true,
    currentUser: { id: user.accountId, fullName: user.name || '', avatarUrl: user.picture, email: user.email },
  };
};
