import { IntlShape } from 'react-intl-next';

import { messages } from '../../messages';

// Regex that will match numbers with 4 decimal places.
// Matches ".23" so it can be transformed into "0.23"
// Matches "23." so it can be transformed into "23"
const VALID_NUMBER_REGEX = /^[-]?((\d{1,10}(\.\d{0,4})?)|(\.\d{1,4}))$/;

const HAS_MORE_THAN_10_LEADING_DIGITS_REGEX = /^[-]?(\d{11,})(?<decimal>\.)?\d*$/;
const HAS_MORE_THAN_4_DIGITS_AFTER_DECIMAL_REGEX = /^[-]?\d*\.\d{5,}$/;

export enum NumberValidationResult {
  VALID,
  MORE_THAN_10_LEADING_DIGITS,
  MORE_THAN_10_LEADING_DIGITS_AND_HAS_DECIMAL,
  MORE_THAN_4_DECIMAL_PLACES,
  NON_NUMERIC_VALUE,
}

export const validateNumberField = (value: string | undefined): NumberValidationResult => {
  if (value) {
    if (VALID_NUMBER_REGEX.test(value)) {
      return NumberValidationResult.VALID;
    }

    const tenLeadingPlacesMatchResult = value.match(HAS_MORE_THAN_10_LEADING_DIGITS_REGEX);
    if (tenLeadingPlacesMatchResult) {
      return tenLeadingPlacesMatchResult.groups?.['decimal']
        ? NumberValidationResult.MORE_THAN_10_LEADING_DIGITS_AND_HAS_DECIMAL
        : NumberValidationResult.MORE_THAN_10_LEADING_DIGITS;
    }

    if (value.match(HAS_MORE_THAN_4_DIGITS_AFTER_DECIMAL_REGEX)) {
      return NumberValidationResult.MORE_THAN_4_DECIMAL_PLACES;
    }

    return NumberValidationResult.NON_NUMERIC_VALUE;
  }
  return NumberValidationResult.VALID;
};

export const validateNumberFieldWithIntlMessage =
  (intl: IntlShape): ((value: string | undefined) => string | undefined) =>
  value => {
    const error = validateNumberField(value);

    switch (error) {
      case NumberValidationResult.MORE_THAN_10_LEADING_DIGITS:
        return intl.formatMessage(messages.numberFieldTooManyLeadingDigitsError, { hasDecimal: false });
      case NumberValidationResult.MORE_THAN_10_LEADING_DIGITS_AND_HAS_DECIMAL:
        return intl.formatMessage(messages.numberFieldTooManyLeadingDigitsError, { hasDecimal: true });
      case NumberValidationResult.MORE_THAN_4_DECIMAL_PLACES:
        return intl.formatMessage(messages.numberFieldTooManyDecimalPlacesError);
      case NumberValidationResult.NON_NUMERIC_VALUE:
        return intl.formatMessage(messages.numberFieldContainsNonNumericValueError);
      case NumberValidationResult.VALID:
      default:
        return undefined;
    }
  };
