import type { Team, TeamCreateDialogProps } from '@atlassian/people-teams';
import Loadable from 'react-loadable';

const AkTeamCreateDialog = Loadable({
  loader: async () => {
    const module = await import(/* webpackChunkName: "ak-people-teams-create-dialog" */ '@atlassian/people-teams');
    return module.TeamCreateDialog;
  },
  loading: () => null,
});

export { AkTeamCreateDialog as TeamCreateDialog };
export type { Team, TeamCreateDialogProps };
