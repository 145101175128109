import { Box } from '@atlaskit/primitives';
import { GraphQLError } from 'graphql';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { ERROR_IMAGE_404_ADVENTURERS } from '../../ErrorResources';

import BasicError from './BasicError';
import ErrorReturnLink from './ErrorReturnLink';

type Props = {
  err?: GraphQLError;
};

function getErrorPageText(intl: IntlShape, errPath: string | number, routeName?: string) {
  if (errPath === 'goal' || routeName === 'goal') {
    return [
      intl.formatMessage({
        id: 'townsquare.packages.error-state.goal-not-found',
        defaultMessage: 'We landed here looking for a goal that does not exist.',
        description: 'Error page text',
      }),
      intl.formatMessage({
        id: 'townsquare.packages.error-state.goal-not-found-2',
        defaultMessage: 'Head back to the directory to search again.',
        description: 'Error page text',
      }),
    ];
  }
  if (errPath === 'project' || routeName === 'channel') {
    return [
      intl.formatMessage({
        id: 'townsquare.packages.error-state.project-not-found',
        defaultMessage: 'We landed here looking for a project that does not exist.',
        description: 'Error page text',
      }),
      intl.formatMessage({
        id: 'townsquare.packages.error-state.project-not-found-2',
        defaultMessage: 'Head back to the directory to search again.',
        description: 'Error page text',
      }),
    ];
  }
  return [
    intl.formatMessage({
      id: 'townsquare.packages.error-state.not-found',
      defaultMessage: 'We landed here looking for a page that does not exist.',
      description: 'Error page text',
    }),
    intl.formatMessage({
      id: 'townsquare.packages.error-state.not-found-2',
      defaultMessage: "They say all roads lead to Rome, but we've lost our way, so let's try going home.",
      description: 'Error page text',
    }),
  ];
}

const FourOhFourError = ({ err }: Props) => {
  const intl = useIntl();
  const [routerState] = useRouter();
  const errPath: string | number = (err?.path?.length && err.path[0]) ?? (routerState.route.name || 'page');

  return (
    <Box testId="four-oh-four">
      <BasicError
        img={ERROR_IMAGE_404_ADVENTURERS}
        heading={intl.formatMessage({
          id: 'townsquare.packages.error-state.404-heading',
          defaultMessage: "Looks like we're off the beaten track",
          description: 'Error page heading',
        })}
        errorText={getErrorPageText(intl, errPath, routerState.route.name)}
      />
      <ErrorReturnLink path={errPath} />
    </Box>
  );
};

export default FourOhFourError;
