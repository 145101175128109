import { ThemeProps } from '@atlaskit/button/types';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TabBodyContainer = styled.div({
  flex: '1 1 auto',
  marginTop: token('space.500'),
  width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UpdatesBadgeContainer = styled.span({
  marginLeft: token('space.100'),
});

export const moreButtonStyle = (current: (themeProps: ThemeProps) => any, themeProps: ThemeProps) => {
  const theme = current(themeProps);
  return {
    ...theme,
    buttonStyles: {
      ...theme.buttonStyles,
      color: token('color.text.subtle'),
      padding: `0 0 0 ${token('space.100')}`,
    },
  };
};

export const moreButtonSelectedStyle = (current: (themeProps: ThemeProps) => any, themeProps: ThemeProps) => {
  const theme = current(themeProps);
  return {
    ...theme,
    buttonStyles: {
      ...theme.buttonStyles,
      color: token('color.text.selected'),
      padding: `0 0 0 ${token('space.100')}`,
    },
  };
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectedDropdownItem = styled.div({
  backgroundColor: token('color.background.selected'),
  color: token('color.text.selected'),
  borderLeft: `2px solid ${token('color.border.selected')}`,
});
