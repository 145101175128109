import { graphql, readInlineData } from 'react-relay';
import { createStore } from 'react-sweet-state';

import { filterNull } from '@townsquare/filter-type';

import { storeDirectoryView$key } from './__generated__/storeDirectoryView.graphql';
import { DirectoryViewDetails } from './types';

const directoryViewFragment = graphql`
  fragment storeDirectoryView on DirectoryView @inline {
    id
    uuid
    name
    storedTql
    entityType
    sort {
      projectSort
      goalSort
    }
    uiViewType
    uiMetadata {
      timelineDateSpan
    }
    isFavourite
    favouriteCount
    creator {
      aaid
    }
    directoryViewColumns {
      ... on StandardDirectoryViewColumn {
        __typename
        id
        name
      }
      ... on CustomDirectoryViewColumn {
        __typename
        id
        uuid
      }
    }
    ...DirectoryFavoriteViewButton
    ...DirectoryManageViewDialog
  }
`;

const initialState: DirectoryViewDetails = {
  id: undefined,
  uuid: undefined,
  name: undefined,
  entityType: undefined,
  storedTql: undefined,
  projectSort: undefined,
  goalSort: undefined,
  uiViewType: undefined,
  timelineDateSpan: undefined,
  isFavourite: undefined,
  favouriteCount: undefined,
  directoryViewColumns: undefined,
  fragment: undefined,
};

export const directoryViewStore = createStore({
  initialState,
  actions: {
    setDirectoryView:
      (directoryViewRef: storeDirectoryView$key | undefined | null) =>
      ({ setState }) => {
        if (directoryViewRef) {
          const data = readInlineData(directoryViewFragment, directoryViewRef);

          const newState: DirectoryViewDetails = {
            id: data.id,
            uuid: data.uuid,
            name: data.name,
            entityType: data.entityType ?? undefined,
            storedTql: data.storedTql ?? undefined,
            projectSort: data.sort?.projectSort?.filter(filterNull) ?? undefined,
            goalSort: data.sort?.goalSort?.filter(filterNull) ?? undefined,
            uiViewType: data.uiViewType ?? undefined,
            timelineDateSpan: data.uiMetadata?.timelineDateSpan ?? undefined,
            fragment: data,
            isFavourite: data.isFavourite ?? undefined,
            favouriteCount: data.favouriteCount ?? 0,
            creatorAaid: data.creator?.aaid ?? undefined,
            directoryViewColumns: data.directoryViewColumns
              ?.map(column => {
                switch (column?.__typename) {
                  case 'StandardDirectoryViewColumn':
                    return column.name;
                  case 'CustomDirectoryViewColumn':
                    return column.uuid;
                  default:
                    return null;
                }
              })
              ?.filter(filterNull),
          };

          setState(newState);

          return newState;
        }

        // Reset the state
        // Yes we need to do this because setState does clever merging
        // and in case of {} prevoius state is not cleared
        setState(initialState);

        return {};
      },
    clearDirectoryView:
      () =>
      ({ setState }) => {
        setState(initialState);
      },
  },
  name: 'directory-view-store',
});
