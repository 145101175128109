// TODO: Remove all code in this file after TC-4412 is completed
// This type replicates ProfilePictureSize from our backend, for this temp solution
export enum AvatarResolution {
  MEDIUM = 256,
  LARGE = 512,
  MAXIMUM = 1024,
}
const DEFAULT_RESOLUTION_IN_URL_REGEX = /\/\d{3,4}$/;

/*
 * Converts URLs from:
 *  - https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/<aaid>/<guid>/128
 * to:
 *  - https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/<aaid>/<guid>/<resolution>
 */
const setAtlassianProfilePictureResolution = (url: string, resolution: AvatarResolution) => {
  // Replace default `128` resolution with higher one we want
  return url.replace(DEFAULT_RESOLUTION_IN_URL_REGEX, `/${resolution}`);
};

/*
 * Converts URLs from:
 *  - https://secure.gravatar.com/avatar/<aaid>?d=<fallback_avatar_url>
 * to:
 *  - https://secure.gravatar.com/avatar/<aaid>?d=404&s=<resolution>
 */
const getCustomGravatarUrl = (urlString: string, resolution: AvatarResolution): string => {
  const gravatarUrl = new URL(urlString);

  // We want a 404 instead of the default image so we can use our fallback instead
  gravatarUrl.searchParams.set('d', '404');

  // Set the specific resolution we need from gravatar so we're not loading the maximum size file
  gravatarUrl.searchParams.set('s', `${resolution}`);

  return gravatarUrl.toString();
};

/*
 * Helper for when we can't use the field resolver in our backend to change the resolution set in
 * the URL (e.g. when data comes from PTC)
 *
 * TODO: Remove this after TC-4412 is completed
 */
export const convertAvatarResolution = (avatarUrl: string | undefined, resolution: AvatarResolution) => {
  if (avatarUrl && avatarUrl.indexOf('gravatar') !== -1) {
    return getCustomGravatarUrl(avatarUrl, resolution);
  } else if (!avatarUrl || avatarUrl.indexOf('initials') !== -1 || avatarUrl.indexOf('default-avatar') !== -1) {
    return undefined;
  }
  return setAtlassianProfilePictureResolution(avatarUrl, resolution);
};
