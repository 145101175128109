import React from 'react';
import { IntlShape } from 'react-intl-next';

import { PersonIcon } from '../../components/icons/Person';
import { TeamIcon } from '../../components/icons/Team';
import { FilterComparators, OptionTypes, Resolver, SupportedFiltersTypes } from '../../types';
import { equalsAndOrFilterComparators, equalsOrFilterComparators } from '../filter-comparators';

import {
  UserTeamOptions,
  userResolveLabels,
  userResolveOptions,
  teamResolveOptions,
  teamResolveLabels,
} from './resolve';

interface OperatorsOverrideOptions {
  filterComparators: FilterComparators[];
}

export const ownerResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.packages.tql.resolvers-people-and-teams-owner',
    defaultMessage: 'Owner',
    description: 'Owner placeholder',
  }),
  placeholder: intl.formatMessage({
    id: 'townsquare.packages.tql.resolvers-people-and-teams-owner-placeholder',
    defaultMessage: 'Choose an owner',
    description: 'Owner placeholder',
  }),
  icon: <PersonIcon label="" />,
  type: SupportedFiltersTypes.OWNER,
  optionType: OptionTypes.SELECT,
  filterComparators: equalsOrFilterComparators,
  resolveOptions: userResolveOptions({ cloudId, workspaceId, orgId, cpusScope }),
  resolveLabels: userResolveLabels,
});

export const watcherResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.packages.tql.resolvers-people-and-teams-watcher',
    defaultMessage: 'Watcher',
    description: 'Watcher title',
  }),
  icon: <PersonIcon label="" />,
  type: SupportedFiltersTypes.WATCHER,
  optionType: OptionTypes.SELECT,
  filterComparators: equalsAndOrFilterComparators,
  resolveOptions: userResolveOptions({ cloudId, workspaceId, orgId, cpusScope }),
  resolveLabels: userResolveLabels,
});

export const contributorResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.package.tql.resolvers-people-and-teams-contributor',
    defaultMessage: 'Contributor',
    description: 'Contributor title',
  }),
  icon: <PersonIcon label="" />,
  type: SupportedFiltersTypes.CONTRIBUTOR,
  optionType: OptionTypes.SELECT,
  filterComparators: equalsAndOrFilterComparators,
  resolveOptions: userResolveOptions({ cloudId, workspaceId, orgId, cpusScope }),
  resolveLabels: userResolveLabels,
});

export const managerResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.packages.tql.resolvers-people-and-teams-manager',
    defaultMessage: 'Manager',
    description: 'Manager title',
  }),
  icon: <PersonIcon label="" />,
  type: SupportedFiltersTypes.MANAGER,
  optionType: OptionTypes.SELECT,
  filterComparators: equalsOrFilterComparators,
  resolveOptions: userResolveOptions({ cloudId, workspaceId, orgId, cpusScope }),
  resolveLabels: userResolveLabels,
});

export const teamMemberResolver = (
  intl: IntlShape,
  { cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.packages.tql.resolvers-people-and-teams-team-member',
    defaultMessage: 'Team Member',
    description: 'Team Member title',
  }),
  icon: <PersonIcon label="" />,
  type: SupportedFiltersTypes.TEAM_MEMBER,
  optionType: OptionTypes.SELECT,
  filterComparators: equalsAndOrFilterComparators,
  resolveOptions: userResolveOptions({ cloudId, workspaceId, orgId, cpusScope }),
  resolveLabels: userResolveLabels,
});

export const teamResolver = (
  intl: IntlShape,
  { cloudId, orgId, workspaceId, filterComparators, cpusScope }: UserTeamOptions & OperatorsOverrideOptions,
): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.packages.tql.resolvers-people-and-teams-team',
    defaultMessage: 'Team',
    description: 'Team title',
  }),
  icon: <TeamIcon label="" />,
  type: SupportedFiltersTypes.TEAM,
  optionType: OptionTypes.SELECT,
  filterComparators,
  resolveOptions: teamResolveOptions({ cloudId, orgId, workspaceId, cpusScope }),
  resolveLabels: content => teamResolveLabels(content, cloudId),
});
