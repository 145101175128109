import React from 'react';

import { OnlyMainColumnMaxWidth } from '@townsquare/home-layout/constants';
import { AsideColumn, MainColumn } from '@townsquare/responsive-grid';
import { SidebarSectionSkeleton } from '@townsquare/sidebar-section';

import {
  StatusSummaryWithHeaderSkeleton,
  TimespanNavigatorSkeleton,
  UpdateListSkeleton,
} from '../components/Skeletons';

import * as styled from './styles';

export const GoalUpdatesViewSkeleton = () => {
  return (
    <>
      <MainColumn maxWidthPx={OnlyMainColumnMaxWidth}>
        <styled.MainColumn>
          <TimespanNavigatorSkeleton />
          <StatusSummaryWithHeaderSkeleton />
          <UpdateListSkeleton />
        </styled.MainColumn>
      </MainColumn>
      <AsideColumn>
        <styled.SidebarContainer>
          <SidebarSectionSkeleton rows={3} />
        </styled.SidebarContainer>
      </AsideColumn>
    </>
  );
};
