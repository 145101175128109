import { Box, BoxProps, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';
import { UserAvatarStyle } from '@townsquare/user-avatar';

const sectionWrapperStyles = xcss({
  marginBlockStart: 'space.050',
  marginBlockEnd: 'space.300',
  marginInline: 'space.0',
});

const overflowStyles = xcss({
  maxWidth: '100%',
  overflowX: 'hidden',
});

export const SectionWrapper = (props: { hideOverflow?: boolean } & BoxProps<'div'>) => {
  const { hideOverflow, ...boxProps } = props;

  return <Box xcss={[sectionWrapperStyles, hideOverflow && overflowStyles]} {...boxProps} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionTitle = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: token('space.100'),
  minHeight: '24px',
  gap: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  h5: {
    margin: 0,
    overflowWrap: 'anywhere',
    hyphens: 'auto',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> :last-child': {
    flexShrink: 0,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionTitleInnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: token('space.050'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  button: {
    ':hover': {
      background: 'transparent',
    },
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Placeholder = styled.p({
  display: 'flex',
  flexGrow: 1,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  margin: `0 ${token('space.negative.100')} !important`,
  padding: token('space.100'),
  color: token('color.text.subtlest'),
  cursor: 'pointer',
  '&:hover': {
    background: token('color.background.neutral.subtle.hovered'),
    borderRadius: '3px',
  },
  '&:active': {
    background: token('color.background.neutral.subtle.pressed'),
    borderRadius: '3px',
    color: token('color.text.subtlest'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const UserItemRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: ${token('space.negative.050')} ${token('space.negative.100')} ${token('space.075')};
  padding: ${token('space.100')};
  height: 24px;

  > span {
    max-width: 100%;
    min-width: 0;
  }

  span,
  div,
  a {
    color: ${token('color.text')} !important;
  }

  a {
    display: inline-flex;
    align-items: center;
    flex: 1 1 0;
    // Trick to get ellipsis to work in a flexbox
    min-width: 0;

    > div {
      ${ellipsis}
    }
  }

  ${UserAvatarStyle.UserAvatar} {
    margin-top: 0;
    margin-bottom: 0;
    min-width: 0;
  }

  &:hover a {
    text-decoration: none;
  }

  &:hover {
    background: ${token('color.background.neutral.subtle.hovered')};
    border-radius: 3px;
  }
`;
