import Loadable from 'react-loadable';

export const CreateProjectDropdown = Loadable({
  loader: () => import(/* webpackChunkName: 'create-project-dropdown' */ './components/CreateProject'),
  loading: () => null,
});

export { CreateProjectButton } from './components/CreateProjectButton';
export type { CreatedProject, CreateProjectProps } from './components/types';
export { jiraEpicPickerResource } from './resources/jiraEpicPickerResource';
export { useCreateProjectActions, useCreateProjectStore } from './store';

export { usePreloadProductActivations } from './usePreloadProductActivations';
