import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { LozengeAlign, StatusColor } from './types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GoalStatusLozenge = styled.div<{
  showScore?: boolean;
  align?: LozengeAlign;
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(props => ({
  display: 'block',
  float: 'right',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  textAlign: props.align || 'inherit',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  textDecoration: 'none !important',
  '& > span': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    padding: props.showScore ? '4px 4px' : '4px 8px',
    '> span': {
      padding: 0,
    },
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const MiniLozenge = styled.span<{ palette: StatusColor }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  background: props.palette.background,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: props.palette.text,
  display: 'inline-flex',
  padding: `0 ${token('space.050')}`,
  borderRadius: '3px',
  marginLeft: token('space.050'),
}));
