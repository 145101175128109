import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

/**
 * A change to buttons messes with iconBefore margins. It's meant to address an issue with
 * old icons but that means it breaks for the new icon set (which we aleady use). It can be removed
 * once Design System handle the new icons properly.
 *
 * Context: https://atlassian.slack.com/archives/CFJ9DU39U/p1710920140884749
 *
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconBeforeMarginFix = styled.span({
  marginInlineStart: token('space.050'),
});

/**
 * A change to buttons messes with iconAfter margins. It's meant to address an issue with
 * old icons but that means it breaks for the new icon set (which we aleady use). It can be removed
 * once Design System handle the new icons properly.
 *
 * Context: https://atlassian.slack.com/archives/CFJ9DU39U/p1710920140884749
 *
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconAfterMarginFix = styled.span({
  marginInlineEnd: token('space.050'),
});

/**
 * A change to buttons messes with iconAfter margins. It's meant to address an issue with
 * old icons but that means it breaks for the new icon set (which we aleady use). It can be removed
 * once Design System handle the new icons properly.
 *
 * Context: https://atlassian.slack.com/archives/CFJ9DU39U/p1710920140884749
 *
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NavIconMarginFix = styled.span({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  'button > span > div': {
    marginInlineEnd: token('space.negative.100'),
  },
});
