import { createRelayResource } from '@townsquare/relay-utils';

import ReactionUpdateActionQuery from './__generated__/ReactionUpdateActionQuery.graphql';
import { ReactionUpdateActionQuery as ReactionUpdateActionQueryType } from './__generated__/ReactionUpdateActionQuery.graphql';

export const reactionUpdateActionResource = createRelayResource<ReactionUpdateActionQueryType>({
  getQuery: routerContext => {
    const { id } = routerContext?.match?.params || {};
    return {
      query: ReactionUpdateActionQuery,
      variables: {
        id: id || '',
      },
    };
  },
});
