import { PeopleAndTeamsConfigurationProvider } from '@atlassian/people-teams-configuration-client';
import React, { ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { useWorkspaceStore } from '@townsquare/workspace-store';

import { TeamCreateDialog, type Team } from './components/AkPeopleTeams';
import { useCreateTeamModalProps } from './useCreateTeamModalProps';

export interface TriggerCreateTeamModalProps {
  onCreate?: (team: Team) => void;
  onCancel?: () => void;
  analyticsCtx?: object;
}

export type TriggerCreateTeamModalCallback = () => void;

export type CreateTeamModalReturn = [ReactNode, TriggerCreateTeamModalCallback];

export const useCreateTeamModal = (props: TriggerCreateTeamModalProps = {}): CreateTeamModalReturn => {
  const { locale } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const [{ type: workspaceType }] = useWorkspaceStore();

  const { onCreate, onCancel } = {
    onCreate: () => null,
    onCancel: () => null,
    ...props,
  };

  const callback: TriggerCreateTeamModalCallback = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleOnCreate = (team: Team) => {
    onCreate(team);
  };

  const handleOnClose = () => {
    setIsOpen(false);
    onCancel();
  };

  const teamCreateDialogProps = useCreateTeamModalProps({
    analyticsCtx: props.analyticsCtx,
    onCreate: handleOnCreate,
    onCancel: handleOnClose,
  });
  const product = workspaceType === 'GLOBAL_EXPERIENCE' ? 'home' : 'atlas';

  return [
    isOpen ? (
      <PeopleAndTeamsConfigurationProvider product={product} locale={locale}>
        <TeamCreateDialog {...teamCreateDialogProps} />
      </PeopleAndTeamsConfigurationProvider>
    ) : null,
    callback,
  ];
};
