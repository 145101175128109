import AtlaskitTooltip from '@atlaskit/tooltip';
import React, { RefObject } from 'react';
import styled from 'styled-components';

import { TownsquareTooltipProps } from './types';

const TooltipDisplayContents = styled.div({
  display: 'contents',
});

const TooltipTag = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>((props, ref: React.Ref<HTMLElement>) => {
  return <TooltipDisplayContents {...props} ref={null} innerRef={ref as RefObject<HTMLElement>} />;
});

export const Tooltip = (props: TownsquareTooltipProps) => {
  return <AtlaskitTooltip {...props} tag={TooltipTag} />;
};
