import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledIcon = styled.div({
  boxSizing: 'border-box',
  padding: token('space.050'),
  width: '24px',
  height: '24px',
  background: token('color.background.accent.red.subtlest'),
  borderRadius: '4px',
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
});
