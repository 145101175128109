import { createRelayResource } from '@townsquare/relay-utils';

import FollowGoalActionQuery from './__generated__/FollowGoalActionQuery.graphql';
import { FollowGoalActionQuery as FollowGoalActionQueryType } from './__generated__/FollowGoalActionQuery.graphql';

export const followGoalResource = createRelayResource<FollowGoalActionQueryType>({
  getQuery: routerContext => {
    const { key } = routerContext?.match?.params || {};
    return {
      query: FollowGoalActionQuery,
      variables: {
        key: key || '',
      },
    };
  },
});
