import { Grid, Stack, xcss } from '@atlaskit/primitives';
import React from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { LoadingSkeleton } from '@townsquare/loading-skeleton';
import { ResponsiveLoadingState } from '@townsquare/loading-state';
import { logMessage } from '@townsquare/logging';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { PeopleFieldType } from '../../hooks/types';
import { PeopleFieldIllustrationCard, PeopleLocationCard } from '../Cards/Browse/PeopleField';
import {
  LocationCardLoadingStateContainer,
  STYLED_ILLUSTRATION_CARD_HEIGHT_PX,
} from '../Cards/Browse/PeopleField/styles';
import { Heading } from '../Heading';
import { ScreenType } from '../ScreenType';
import { CardRow } from '../styles';

import { BrowsePeopleFieldSection$key } from './__generated__/BrowsePeopleFieldSection.graphql';

const STYLED_ILLUSTRATION_CARD_HEIGHT = `${STYLED_ILLUSTRATION_CARD_HEIGHT_PX}px`;

const BROWSE_PEOPLE_FIELD_CARD_SLOTS = 4;
const BROWSE_KUDOS_CARD_SLOTS = 5;

const IllustrationLoadingCards = () => (
  <>
    {[...Array(BROWSE_PEOPLE_FIELD_CARD_SLOTS)].map((_, i) => (
      <LoadingSkeleton width="100%" height={STYLED_ILLUSTRATION_CARD_HEIGHT} marginTopPx={5} key={i} />
    ))}
  </>
);

const KudosLoadingCards = () => (
  <>
    {[...Array(BROWSE_KUDOS_CARD_SLOTS)].map((_, i) => (
      <LoadingSkeleton width="100%" height="220px" marginTopPx={5} key={i} />
    ))}
  </>
);

const LocationLoadingCards = () => (
  <>
    {[...Array(BROWSE_PEOPLE_FIELD_CARD_SLOTS)].map((_, i) => (
      <LocationCardLoadingStateContainer key={i}>
        <LoadingSkeleton marginTopPx={10} width="100%" height={STYLED_ILLUSTRATION_CARD_HEIGHT} />
        <LoadingSkeleton marginTopPx={14} height="22px" width="220px" />
        <LoadingSkeleton marginTopPx={2} height="18px" width="44px" />
      </LocationCardLoadingStateContainer>
    ))}
  </>
);

type BrowsePeopleFieldSectionFrameProps = {
  browseAllScreenType: ScreenType;
  children: React.ReactNode;
  sectionTitle: string;
};

const cardRowStyles = xcss({
  gridTemplateColumns: 'repeat(auto-fill, 320px)',
});

const smartRowStyles = xcss({
  gridTemplateColumns: 'repeat(auto-fill, minmax(292px, 1fr))',
});

type BrowseCardSectionFrameProps = {
  browseAllScreenType: ScreenType;
  children: React.ReactNode;
  sectionTitle: string;
};

const SectionFrame = ({ browseAllScreenType, children, sectionTitle }: BrowseCardSectionFrameProps) => {
  const intl = useIntl();

  const heading = (
    <Heading
      browseMoreText={intl.formatMessage({
        id: 'townsquare.web.staff-directory-view.browse-all',
        defaultMessage: 'Browse all',
        description: 'Browse all text',
      })}
      screenType={browseAllScreenType}
    >
      {sectionTitle}
    </Heading>
  );

  return (
    <Stack space="space.150">
      {heading}
      {children}
    </Stack>
  );
};

const kudosLoadingStyles = xcss({
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: 'space.200',
});

type BrowsePeopleFieldSectionSkeletonProps = {
  fieldType: PeopleFieldType;
} & Pick<BrowsePeopleFieldSectionFrameProps, 'browseAllScreenType' | 'sectionTitle'>;

export const BrowsePeopleFieldSectionSkeleton = ({
  browseAllScreenType,
  fieldType,
  sectionTitle,
}: BrowsePeopleFieldSectionSkeletonProps) => {
  const showInGrid = shouldShowFieldInGrid(fieldType);
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  const content =
    fieldType === 'LOCATION' ? (
      <LocationLoadingCards />
    ) : fieldType === 'KUDOS' ? (
      <KudosLoadingCards />
    ) : (
      <IllustrationLoadingCards />
    );

  return (
    <SectionFrame browseAllScreenType={browseAllScreenType} sectionTitle={sectionTitle}>
      {fieldType === 'KUDOS' ? (
        <Grid xcss={kudosLoadingStyles}>{content}</Grid>
      ) : showInGrid ? (
        <Grid xcss={enableHomeVisualRefresh ? smartRowStyles : cardRowStyles} gap="space.200">
          {content}
        </Grid>
      ) : (
        <ResponsiveLoadingState>
          <CardRow>{content}</CardRow>
        </ResponsiveLoadingState>
      )}
    </SectionFrame>
  );
};

const shouldShowFieldInGrid = (fieldType: string) => {
  return ['LOCATION', 'DEPARTMENT', 'JOB_TITLE'].includes(fieldType);
};

type BrowsePeopleFieldSectionProps = {
  data: BrowsePeopleFieldSection$key;
  fieldType: PeopleFieldType;
} & Pick<BrowsePeopleFieldSectionFrameProps, 'browseAllScreenType' | 'sectionTitle'>;

export const BrowsePeopleFieldSection = ({
  browseAllScreenType,
  data,
  fieldType,
  sectionTitle,
}: BrowsePeopleFieldSectionProps) => {
  const fields = useFragment(
    graphql`
      fragment BrowsePeopleFieldSection on PeopleFieldAggregateResponse {
        results {
          ...LocationCard
          ...IllustrationCard
        }
        errors {
          message
        }
      }
    `,
    data,
  );

  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  if (fields.errors?.length) {
    logMessage(`Error fetching StaffDirectory BrowsePeopleFieldSection`, 'error', {
      source: 'BrowsePeopleFieldSection',
      fieldType: fieldType,
      errors: fields.errors.map(error => error?.message),
    });
  }

  if (!fields.results?.length) {
    return null;
  }

  const showInGrid = shouldShowFieldInGrid(fieldType);

  const content = fields?.results?.map((field, i) => {
    if (field) {
      return fieldType === 'LOCATION' ? (
        <PeopleLocationCard data={field} key={`location-card-${i}`} />
      ) : (
        field && <PeopleFieldIllustrationCard data={field} key={`illustration-card-${i}`} fieldType={fieldType} />
      );
    }
  });

  return (
    <SectionFrame browseAllScreenType={browseAllScreenType} sectionTitle={sectionTitle}>
      {showInGrid ? (
        <Grid xcss={enableHomeVisualRefresh ? smartRowStyles : cardRowStyles} gap="space.200">
          {content}
        </Grid>
      ) : (
        <CardRow>{content}</CardRow>
      )}
    </SectionFrame>
  );
};
