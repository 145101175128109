import { useEffect } from 'react';
import { useRouter } from 'react-resource-router';

import { AnalyticsClientImpl } from '@townsquare/analytics';
import { AppState, useAppStateStore } from '@townsquare/app-state-store';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceCloudId, useWorkspaceUUID } from '@townsquare/workspace-store';

import { isRouteWithSubproduct } from '../utils';

// Warning! - don't export this outside this file. It should only be used from here!
const peopleAnalytics = new AnalyticsClientImpl('watermelon', 'people');

const directorySubproduct = 'people-team-directory';

/**
 * To match ptc-embeddable-directory events we need to send a
 * UI Viewed event with the following attributes:
 * product = people, subproduct = watermelon
 * Because we can't change product in an analytics client,
 * we use this standalone client
 */
export const useDirectoryListener = () => {
  const [store] = useRouter();
  const [{ appState }] = useAppStateStore();
  const [{ accountId }] = useUserStore();
  const [cloudId] = useWorkspaceCloudId();
  const [workspaceUUID] = useWorkspaceUUID();

  /**
   * Set up the people analytics client
   */
  useEffect(() => {
    // Wait till app is initialised so that we know user/cloud/workspace is loaded
    if (appState === AppState.FULLY_INITIALISED || appState === AppState.INITIALISED_WITHOUT_WORKSPACE) {
      peopleAnalytics.setCurrentUserAndTenantInfo(accountId, workspaceUUID, cloudId);
    }
  }, [accountId, cloudId, workspaceUUID, appState]);

  /**
   * If we are on the directory page, send a UI Viewed event, otherwise do nothing, always.
   */
  useEffect(() => {
    if (isRouteWithSubproduct(store.route) && store.route.subproduct) {
      if (
        (typeof store.route.subproduct === 'function' && store.route.subproduct(store) === directorySubproduct) ||
        store.route.subproduct === directorySubproduct
      ) {
        void peopleAnalytics.startUIViewedEvent();
      }
    }
  }, [store]);
};
