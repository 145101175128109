/* eslint-disable @atlaskit/design-system/no-deprecated-apis */
import { HeadingItem, MenuGroup, Section, StatelessCSSFn } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent, Suspense } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { redirect } from '@townsquare/facade';
import { filterUndefined } from '@townsquare/filter-type';
import { LoadingState } from '@townsquare/loading-state';
import { GoalScoringMode, useWorkspaceStore } from '@townsquare/workspace-store';

import { SwitchWorkspaceItem } from './SwitchWorkspaceItem';
import { ProfileAndWorkspaceSwitcherButtonQuery as ProfileAndWorkspaceSwitcherButtonQueryType } from './__generated__/ProfileAndWorkspaceSwitcherButtonQuery.graphql';
import { WorkspaceDetailsForSwitcher } from './types';

const ProfileAndWorkspaceSwitcherQuery = graphql`
  query ProfileAndWorkspaceSwitcherButtonQuery {
    allWorkspaces {
      edges {
        node {
          name
          id
          uuid
          keyPrefix
          timezone
          cloudId
          organisationId
          goalScoringMode
          type
        }
      }
    }
  }
`;

type ProfileAndWorkspaceSwitcherProps = {
  queryReference: PreloadedQuery<ProfileAndWorkspaceSwitcherButtonQueryType>;
};

const headingCssFn: StatelessCSSFn = () => ({ textTransform: 'none' });

const ProfileAndWorkspaceSwitcher = (props: ProfileAndWorkspaceSwitcherProps) => {
  const data = usePreloadedQuery<ProfileAndWorkspaceSwitcherButtonQueryType>(
    ProfileAndWorkspaceSwitcherQuery,
    props.queryReference,
  );

  const analytics = useAnalytics();
  const [workspace] = useWorkspaceStore();
  const [{ route }] = useRouter();

  const allWorkspaceOptions =
    data?.allWorkspaces?.edges
      ?.map(ws => {
        if (ws?.node) {
          return {
            name: ws.node.name,
            globalId: ws.node.id,
            UUID: ws.node.uuid,
            keyPrefix: ws.node.keyPrefix,
            timezone: ws.node.timezone,
            cloudId: ws.node.cloudId ?? undefined,
            organisationId: ws.node.organisationId ?? undefined,
            goalScoringMode: ws.node.goalScoringMode as GoalScoringMode,
            numOfActiveWorkspaces: data.allWorkspaces?.edges?.length ?? 0,
            type: ws.node.type,
          };
        }
      })
      .filter(filterUndefined) ?? [];

  const calculateRoute = (selectedWorkspace: WorkspaceDetailsForSwitcher) => {
    if (selectedWorkspace.type === 'GLOBAL_EXPERIENCE') {
      return `${getConfig().homeUrl}/o/${selectedWorkspace.organisationId}?cloudId=${selectedWorkspace.cloudId}`;
    }

    // Navigate the user to an appropriate landing page after switching
    if (route.path.includes('/project/:id/')) {
      // If on a project -> navigate to the project directory
      return `/projects?cloudId=${selectedWorkspace.cloudId}`;
    } else if (route.path.includes('/goal/:id/')) {
      // If on a goal -> goal directory
      return `/goals?cloudId=${selectedWorkspace.cloudId}`;
    } else if (route.path.includes('/updates/tag/:id')) {
      // If on a tag -> home - tags are weird and site specific but under /updates
      return `/?cloudId=${selectedWorkspace.cloudId}`;
    } else {
      // All other cases, send them to the home page
      return `/?cloudId=${selectedWorkspace.cloudId}`;
    }
  };

  const onItemClick = (event: MouseEvent | KeyboardEvent, selectedWorkspace: WorkspaceDetailsForSwitcher) => {
    // Handled by redirects below, block native link functionality
    event.preventDefault();

    if (selectedWorkspace.type === 'GLOBAL_EXPERIENCE') {
      redirect(`${getConfig().homeUrl}/o/${selectedWorkspace.organisationId}?cloudId=${selectedWorkspace.cloudId}`);
      return;
    }

    void analytics.ui('workspaceSwitcherNavWorkspaceOption', 'clicked', {
      workspaceUuid: selectedWorkspace.UUID,
    });

    redirect(calculateRoute(selectedWorkspace));
  };

  return (
    // Hack to position workspace switcher on the far right side of the nav
    <MenuGroup minWidth={192} maxWidth={480}>
      <Section>
        <HeadingItem cssFn={headingCssFn}>
          <FormattedMessage
            id="townsquare.navigation.profile-workspace-switcher.tooltip"
            description="Tooltip for the switch workspace option"
            defaultMessage="Switch workspace"
          />
        </HeadingItem>
        {allWorkspaceOptions.map(ws => (
          <SwitchWorkspaceItem
            href={calculateRoute(ws)}
            onItemClick={onItemClick}
            workspace={ws}
            key={ws.UUID}
            isSelected={ws.UUID === workspace.UUID}
          />
        ))}
      </Section>
    </MenuGroup>
  );
};

type ProfileAndSwitchWorkspaceWrapperProps = {
  queryReference: PreloadedQuery<ProfileAndWorkspaceSwitcherButtonQueryType> | null | undefined;
};

export const ProfileAndSwitchWorkspaceWrapper = (props: ProfileAndSwitchWorkspaceWrapperProps) => {
  return (
    <Suspense
      fallback={
        <MenuGroup minWidth={280}>
          <LoadingState />
        </MenuGroup>
      }
    >
      {props.queryReference && <ProfileAndWorkspaceSwitcher queryReference={props.queryReference} />}
    </Suspense>
  );
};
