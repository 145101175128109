import AvatarGroup from '@atlaskit/avatar-group';
import { Box, Text, xcss } from '@atlaskit/primitives';
import React, { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { filterNull } from '@townsquare/filter-type';
import { WrappedWithProfileCard } from '@townsquare/profile-card-provider';
import { profileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';
import { Link, useRouterActions } from '@townsquare/router/primitives';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { TeamIcon } from '@townsquare/team-icon';

import { TeamSearchResultCard$key } from './__generated__/TeamSearchResultCard.graphql';
import { IconsSection, StyledTeamSearchResultCard, TeamDetailsSection, TeamName } from './styles';

export interface SearchResultTeamMember {
  aaid: string;
  name: string;
  picture?: string;
}

export interface TeamSearchResultCardProps {
  data: TeamSearchResultCard$key;
}

const refreshedCardStyles = xcss({
  boxSizing: 'border-box',
  padding: 'space.250',

  borderWidth: 'border.width',
  borderStyle: 'solid',
  borderColor: 'color.border',
  borderRadius: 'border.radius',
  transition: 'box-shadow 0.25s ease-in-out',

  ':hover': {
    boxShadow: 'elevation.shadow.overlay',
    textDecoration: 'none',
  },

  height: '184px',
  maxWidth: '100%',
  minWidth: '240px',
});

export const TeamSearchResultCard = (props: TeamSearchResultCardProps) => {
  const analytics = useAnalytics();
  const { pushTo } = useRouterActions();
  const data = useFragment(
    graphql`
      fragment TeamSearchResultCard on TeamSearchResponse {
        id
        description
        displayName
        members {
          count
          edges {
            node {
              pii {
                name
                picture
              }
              aaid
            }
          }
        }
      }
    `,
    props.data,
  );

  const onClick = () => {
    void analytics.ui('searchResultTeamCard', 'clicked');
  };

  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  if (data.id == null || data.displayName == null || data.description == null || data.members == null) {
    return null;
  }

  const teamId = data.id.replace('ari:cloud:identity::team/', '');

  const membersAvatarData = data.members.edges
    ?.map(member => {
      if (member == null || member?.node == null || member.node.pii?.name == null) {
        return null;
      }
      return {
        name: member.node.pii.name,
        src: member.node.pii.picture ?? undefined,
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();

          void analytics.ui('searchResultTeamCardUserAvatar', 'clicked');
          if (member.node && member.node.aaid) {
            void pushTo(profileRoute, { params: { id: member.node.aaid } });
          }
        },
        key: member.node.aaid ?? undefined, // Used for profile card that appears on hover
      };
    })
    .filter(filterNull);

  const content = (
    <>
      <IconsSection>
        <TeamIcon teamName={data.displayName} size="medium" />
        {membersAvatarData && (
          <AvatarGroup
            data={membersAvatarData}
            maxCount={6}
            showMoreButtonProps={{
              onClick: (e: MouseEvent) => {
                // prevent navigating to the team when clicking overflow dropdown
                e.stopPropagation();
                e.preventDefault();
              },
            }}
            overrides={{
              Avatar: {
                render: (Component, props, index) => {
                  const aaid = props.key; // Set in data prop above
                  const avatarItem = <Component {...props} key={props.key ?? index} />;

                  return typeof aaid === 'string' ? (
                    <WrappedWithProfileCard userId={aaid}>{avatarItem}</WrappedWithProfileCard>
                  ) : (
                    avatarItem
                  );
                },
              },
            }}
            isTooltipDisabled
          />
        )}
      </IconsSection>
      <TeamDetailsSection>
        <EllipsisTooltip content={data.displayName}>
          <TeamName>{data.displayName}</TeamName>
        </EllipsisTooltip>
        <Text as="p" maxLines={1} color="color.text.subtle">
          <FormattedMessage
            id="townsquare.web.team-search-result-card.members"
            description="Team members"
            defaultMessage="{count, plural, one {# member} other {# members}}"
            values={{
              count: data.members?.count ?? 0,
            }}
          />
        </Text>
        <Text as="p" maxLines={2} color="color.text.subtle">
          {data.description}
        </Text>
      </TeamDetailsSection>
    </>
  );

  if (enableHomeVisualRefresh) {
    return (
      <Link
        data-testid={`team-search-card-${teamId}`}
        onClick={onClick}
        to={teamProfileRoute}
        params={{ id: teamId }}
        key={teamId}
      >
        <Box xcss={refreshedCardStyles}>{content}</Box>
      </Link>
    );
  }

  return (
    <StyledTeamSearchResultCard
      data-testid={`team-search-card-${teamId}`}
      onClick={onClick}
      to={teamProfileRoute}
      params={{ id: teamId }}
      key={teamId}
    >
      {content}
    </StyledTeamSearchResultCard>
  );
};
