import React from 'react';
import { defineMessage, useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { KudosContent } from '@townsquare/kudos-on-feed';

import { Heading } from '../Heading';
import { ScreenType } from '../ScreenType';
import { CardRow } from '../styles';

import { BrowsePeopleFieldSectionSkeleton } from './BrowsePeopleFieldSection';
import { BrowseKudosSection$key } from './__generated__/BrowseKudosSection.graphql';

export interface BrowseFieldSectionProps {
  data: BrowseKudosSection$key;
}

const kudosSectionTitle = defineMessage({
  id: 'townsquare.staff-directory.browse-kudos-section.title',
  description: 'Header text for the kudos section of the staff directory',
  defaultMessage: 'Kudos',
});

export const BrowseKudosSectionSkeleton = () => {
  const intl = useIntl();
  return (
    <BrowsePeopleFieldSectionSkeleton
      browseAllScreenType={ScreenType.BROWSE_KUDOS}
      fieldType="KUDOS"
      sectionTitle={intl.formatMessage(kudosSectionTitle)}
    />
  );
};

export const BrowseKudosSection = (props: BrowseFieldSectionProps) => {
  const intl = useIntl();

  const data = useFragment(
    graphql`
      fragment BrowseKudosSection on Query
      @argumentDefinitions(
        query: { type: "String!" }
        workspaceId: { type: "ID!" }
        first: { type: "Int", defaultValue: 6 }
        after: { type: "String" }
      ) {
        kudos: kudosTql(workspaceId: $workspaceId, q: $query, first: $first, after: $after) {
          edges {
            node {
              ...KudosContent
            }
          }
        }
      }
    `,
    props.data,
  );

  if (!data?.kudos?.edges?.length) {
    return null;
  }

  return (
    <>
      <Heading
        browseMoreText={intl.formatMessage({
          id: 'townsquare.web.staff-directory-view.browse-all',
          defaultMessage: 'Browse all',
          description: 'Browse all text',
        })}
        screenType={ScreenType.BROWSE_KUDOS}
      >
        {intl.formatMessage(kudosSectionTitle)}
      </Heading>
      <CardRow shouldAvoidBleed>
        {data.kudos?.edges.map(edge => {
          return edge?.node && <KudosContent data={edge.node} kudosPerPage={5} showAllDescriptionRows={false} />;
        })}
      </CardRow>
    </>
  );
};
