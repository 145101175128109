import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

import { getConfig } from '@townsquare/config';

import introspectionQueryResultData from '../graphql/generated/fragment';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const httpLink = createHttpLink({
  uri: operation => `${getConfig().watermelonGraphQLUrl}?operationName=${operation.operationName}`,
});

export const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: obj => {
      switch (obj.__typename) {
        case 'Icon':
          return `icon:${obj.id}:${(obj as any).shortName}|${(obj as any).color}`;
        case 'GoalPhaseDetails':
        case 'ProjectPhaseDetails':
          return `${obj.__typename}:${obj.id}`;
        case 'GqlJiraIssue':
          return `${obj.__typename}:${(obj as any).issueId}`;
        case 'GqlJiraIssueType':
          return `${obj.__typename}:${(obj as any).issueTypeId}`;
        case 'GqlJiraStatus':
          return `${obj.__typename}:${(obj as any).statusId}`;
        case 'GqlJiraStatusCategory':
          return `${obj.__typename}:${(obj as any).statusCategoryId}`;
        default:
          return obj.id;
      }
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      errorPolicy: 'all',
    },
  },
});

export const gqlGatewayClient = new ApolloClient({
  link: errorLink.concat(
    createHttpLink({
      credentials: 'include',
      uri: `/gateway/api/graphql`,
    }),
  ),
  cache: new InMemoryCache(),
});
