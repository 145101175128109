import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { FilterGroupWrapper as StyledFilterGroupWrapper } from './style';

const groupWrapperStyles = xcss({
  display: 'inline-flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  position: 'relative',
  borderColor: 'color.border',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderInlineStartWidth: '0',
  borderInlineEndWidth: '0',
  marginInlineEnd: 'space.0',
});

const firstGroupWrapperStyles = xcss({
  borderStartStartRadius: '6px',
  borderEndStartRadius: '6px',
  borderInlineStartWidth: '1px',
  paddingInlineStart: 'space.050',
  ':before': {
    borderInlineEndColor: 'transparent',
  },
});

const lastGroupWrapperStyles = xcss({
  borderStartEndRadius: '6px',
  borderEndEndRadius: '6px',
  borderInlineEndWidth: '1px',
  paddingInlineEnd: 'space.050',
  ':after': {
    borderInlineStartColor: 'transparent',
  },
  marginInlineEnd: 'space.050',
});

const lastEntityLastGroupWrapperStyles = xcss({
  marginInlineEnd: 'space.100',
});

const beforeWrapStyles = xcss({
  ':before': {
    content: '""',

    display: 'block',
    borderWidth: '14px',
    borderStyle: 'solid',
    borderBlockStartColor: 'transparent',
    borderBlockEndColor: 'transparent',
    borderInlineEndColor: 'transparent',
    borderInlineStartColor: token('utility.elevation.surface.current'),

    width: '0px',
    height: '0px',
    insetBlockStart: '1px',

    insetInlineStart: '100%',
    position: 'absolute',
    // @ts-expect-error - Doesn't recognize number as a valid property of `zIndex`
    zIndex: 2,
  },
  ':after': {
    content: '""',
    display: 'inline-block',
    borderWidth: '16px',
    borderStyle: 'solid',
    width: '0px',
    height: '0px',
    insetInlineStart: '100%',
    insetBlockStart: '-1px',
    position: 'absolute',
    // @ts-expect-error - Doesn't recognize number as a valid property of `zIndex`
    zIndex: 1,

    borderBlockStartColor: 'transparent',
    borderBlockEndColor: 'transparent',
    borderInlineEndColor: 'transparent',
    borderInlineStartColor: 'color.border',
  },
});

const afterWrapStyles = xcss({
  marginInlineStart: 'space.200',
  paddingInlineStart: 'space.050',
  ':before': {
    content: '""',

    borderStyle: 'solid',
    width: '0px',
    height: '0px',
    insetInlineEnd: '100%',

    position: 'absolute',

    borderWidth: '14px',
    display: 'block',
    borderBlockStartColor: 'transparent',
    borderBlockEndColor: 'transparent',
    borderInlineStartColor: 'transparent',
    borderInlineEndColor: token('utility.elevation.surface.current'),
    // @ts-expect-error - Doesn't recognize number as a valid property of `zIndex`
    zIndex: 2,
    insetBlockStart: '1px',
  },
  ':after': {
    content: '""',
    display: 'inline-block',
    borderWidth: '16px',
    borderStyle: 'solid',
    width: '0px',
    height: '0px',
    insetInlineEnd: '100%',
    insetBlockStart: '-1px',
    position: 'absolute',
    // @ts-expect-error - Doesn't recognize number as a valid property of `zIndex`
    zIndex: 1,

    borderBlockStartColor: 'transparent',
    borderBlockEndColor: 'transparent',
    borderInlineStartColor: 'transparent',
    borderInlineEndColor: 'color.border',
  },
});

export const FilterGroupWrapper = (
  props: PropsWithChildren<{
    isLastInGroup: boolean;
    isFirstInGroup: boolean;
    isLastEntity: boolean;
    isLoadingLabels: boolean;
    testId?: string;
  }>,
) => {
  const filterGroupRef = useRef<HTMLDivElement>(null);
  const [wrappedPosition, setWrappedPosition] = useState<'before-wrap' | 'after-wrap' | null>(null);

  const applyWrapPosition = (parent: HTMLElement) => {
    const currentOffsetTop = parent.offsetTop;
    const previousOffsetTop =
      parent.previousElementSibling instanceof HTMLElement && parent.previousElementSibling.offsetTop;
    const nextOffsetTop = parent.nextElementSibling instanceof HTMLElement && parent.nextElementSibling.offsetTop;

    if (nextOffsetTop && nextOffsetTop > currentOffsetTop) {
      setWrappedPosition('before-wrap');
    } else if (previousOffsetTop && previousOffsetTop < currentOffsetTop) {
      setWrappedPosition('after-wrap');
    } else {
      setWrappedPosition(null);
    }
  };

  const resizeObserver = useRef(
    new ResizeObserver(_ => {
      const entry = filterGroupRef.current;

      if (!entry) {
        return;
      }

      applyWrapPosition(entry);
    }),
  );

  useEffect(() => {
    const resizer = resizeObserver.current;

    if (filterGroupRef.current && !props.isLoadingLabels) {
      applyWrapPosition(filterGroupRef.current);
    }

    resizer.observe(window.document.body);
    return () => {
      resizer.disconnect();
    };
  }, [props.isLoadingLabels]);

  return useIsHomeVisualRefreshEnabled() ? (
    <Box
      ref={filterGroupRef}
      xcss={[
        groupWrapperStyles,
        props.isFirstInGroup && firstGroupWrapperStyles,
        props.isLastInGroup && lastGroupWrapperStyles,
        props.isLastEntity && props.isLastInGroup && lastEntityLastGroupWrapperStyles,
        wrappedPosition === 'before-wrap' && beforeWrapStyles,
        wrappedPosition === 'after-wrap' && afterWrapStyles,
      ]}
      paddingBlock="space.025"
    >
      {props.children}
    </Box>
  ) : (
    <StyledFilterGroupWrapper
      data-testid={props.testId}
      isLastEntity={props.isLastEntity}
      isFirstInGroup={props.isFirstInGroup}
      isLastInGroup={props.isLastInGroup}
    >
      {props.children}
    </StyledFilterGroupWrapper>
  );
};
