import { Environment } from 'react-relay';
import { fetchQuery, graphql } from 'relay-runtime';

import { filterUndefined } from '@townsquare/type-helpers';

import { FilterComparators, OptionTypes, Resolver, SupportedFiltersTypes } from '../../types';

import {
  PeopleFieldType,
  createPeopleFieldResolverQuery,
} from './__generated__/createPeopleFieldResolverQuery.graphql';

interface QueryOptions {
  relayEnvironment: Environment;
  workspaceId: string;
  query: string;
}

const queryPeopleFieldNames = (
  peopleFieldType: PeopleFieldType,
  { relayEnvironment, query, workspaceId }: QueryOptions,
): Promise<string[]> => {
  return fetchQuery<createPeopleFieldResolverQuery>(
    relayEnvironment,
    graphql`
      query createPeopleFieldResolverQuery(
        $searchString: String
        $workspaceId: ID!
        $peopleFieldType: PeopleFieldType!
        $first: Int
        $after: String
      ) {
        peopleFieldAggregates(
          searchString: $searchString
          workspaceId: $workspaceId
          peopleFieldType: $peopleFieldType
          first: $first
          after: $after
        ) {
          results {
            count
            name
            imageDirectoryUrl
          }
        }
      }
    `,
    {
      searchString: query ?? '',
      peopleFieldType,
      workspaceId,
    },
    {
      fetchPolicy: 'store-or-network',
    },
  )
    .toPromise()
    .then(data => {
      return data?.peopleFieldAggregates?.results?.map(field => field?.name).filter<string>(filterUndefined) || [];
    });
};

export const createPeopleFieldSuggestionResolver = (
  title: string,
  type: SupportedFiltersTypes,
  icon: JSX.Element,
  workspaceId: string,
  fieldType: PeopleFieldType,
  filterComparators: FilterComparators[],
): Resolver => ({
  title,
  icon,
  type,
  optionType: OptionTypes.SELECT,
  filterComparators,
  resolveOptions: ({ relayEnvironment, query }) => {
    const queryOptions: QueryOptions = { relayEnvironment, query: query ?? '', workspaceId };
    return queryPeopleFieldNames(fieldType, queryOptions).then(names =>
      names.map(name => ({
        label: name,
        value: name,
      })),
    );
  },
  resolveLabels: ({ values }) => {
    return Promise.resolve(
      values.map(value => {
        const name = value.toString();

        return {
          label: name,
          value: name,
          icon,
          comparator: filterComparators[0].comparatorOption,
        };
      }),
    );
  },
});
