/**
 * @generated SignedSource<<80d915309b007779ebab26fb9a6931e3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BrowseTeamSection$data = {
  readonly browseTeams: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"TeamCard">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "BrowseTeamSection";
};
export type BrowseTeamSection$key = {
  readonly " $data"?: BrowseTeamSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowseTeamSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organisationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "name": "BrowseTeamSection",
  "selections": [
    {
      "alias": "browseTeams",
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "organisationId",
          "variableName": "organisationId"
        },
        {
          "kind": "Variable",
          "name": "q",
          "variableName": "query"
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": [
            "NAME_ASC"
          ]
        }
      ],
      "concreteType": "TeamSearchResponseConnection",
      "kind": "LinkedField",
      "name": "teamsTql",
      "plural": false,
      "selections": [
        {
          "concreteType": "TeamSearchResponseEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "TeamSearchResponse",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "TeamCard"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "6bf8fbbcf980283a99f3923312d5dcc7";

export default node;
