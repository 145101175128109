import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator, Input, Operators } from '@townsquare/tql/types';

export const SHOW_LIMIT = 10;

const getBaseTql = (uuid: string): Input[] => [
  {
    fieldName: 'archived',
    comparator: ComparatorOperator.EQUALS,
    fieldValue: false,
  },
  {
    fieldName: 'label',
    comparator: ComparatorOperator.EQUALS,
    fieldValue: uuid,
  },
];

export const getActiveProjectsTql = (uuid: string) =>
  tqlQueryFromInput({
    input: getBaseTql(uuid),
    doc: {
      model: [
        {
          model: ['on_track', 'at_risk', 'off_track'],
          comparator: ComparatorOperator.EQUALS,
          operator: Operators.OR,
          type: 'status_phase',
        },
      ],
      operator: Operators.AND,
    },
  });

export const getPendingProjectsTql = (uuid: string) =>
  tqlQueryFromInput({
    input: getBaseTql(uuid).concat({
      fieldName: 'status_phase',
      comparator: ComparatorOperator.EQUALS,
      fieldValue: 'pending',
    }),
  });

export const getPausedProjectsTql = (uuid: string) =>
  tqlQueryFromInput({
    input: getBaseTql(uuid).concat({
      fieldName: 'status_phase',
      comparator: ComparatorOperator.EQUALS,
      fieldValue: 'paused',
    }),
  });

export const getCompletedProjectsTql = (uuid: string) =>
  tqlQueryFromInput({
    input: getBaseTql(uuid).concat({
      fieldName: 'status_phase',
      comparator: ComparatorOperator.EQUALS,
      fieldValue: 'done',
    }),
  });

export const getRelatedGoalsTql = (uuid: string) =>
  tqlQueryFromInput({
    input: getBaseTql(uuid).concat({
      fieldName: 'status_phase',
      comparator: ComparatorOperator.NOT_EQUALS,
      fieldValue: 'done',
    }),
  });
