import { createActionsHook, createHook, createStateHook } from 'react-sweet-state';

import { userStore } from './store';
export { type User } from './types';

export const useUserApi = createHook(userStore, { selector: null });
export const useUserStore = createHook(userStore);
export const useCurrentUser = createStateHook(userStore);
export const useAccountId = createStateHook(userStore, { selector: state => state.accountId });
export const useCurrentUserAvatarUrl = createStateHook(userStore, { selector: state => state.picture });
export const useUserActions = createActionsHook(userStore);
