/**
 * @generated SignedSource<<582ad429599d479893845fdc199fb4d7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type PeopleFieldType = "DEPARTMENT" | "JOB_TITLE" | "LOCATION";
export type createPeopleFieldResolverQuery$variables = {
  after?: string | null;
  first?: number | null;
  peopleFieldType: PeopleFieldType;
  searchString?: string | null;
  workspaceId: string;
};
export type createPeopleFieldResolverQuery$data = {
  readonly peopleFieldAggregates: {
    readonly results: ReadonlyArray<{
      readonly count: number;
      readonly imageDirectoryUrl: string | null;
      readonly name: string;
    } | null> | null;
  } | null;
};
export type createPeopleFieldResolverQuery = {
  response: createPeopleFieldResolverQuery$data;
  variables: createPeopleFieldResolverQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "peopleFieldType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchString"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceId"
},
v5 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "peopleFieldType",
        "variableName": "peopleFieldType"
      },
      {
        "kind": "Variable",
        "name": "searchString",
        "variableName": "searchString"
      },
      {
        "kind": "Variable",
        "name": "workspaceId",
        "variableName": "workspaceId"
      }
    ],
    "concreteType": "PeopleFieldAggregateResponse",
    "kind": "LinkedField",
    "name": "peopleFieldAggregates",
    "plural": false,
    "selections": [
      {
        "concreteType": "PeopleFieldAggregate",
        "kind": "LinkedField",
        "name": "results",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "count"
          },
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "imageDirectoryUrl"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "createPeopleFieldResolverQuery",
    "selections": (v5/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createPeopleFieldResolverQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "d2cdaa221002480e0db34ebb0fd72c4d",
    "id": null,
    "metadata": {},
    "name": "createPeopleFieldResolverQuery",
    "operationKind": "query",
    "text": "query createPeopleFieldResolverQuery(\n  $searchString: String\n  $workspaceId: ID!\n  $peopleFieldType: PeopleFieldType!\n  $first: Int\n  $after: String\n) {\n  peopleFieldAggregates(searchString: $searchString, workspaceId: $workspaceId, peopleFieldType: $peopleFieldType, first: $first, after: $after) {\n    results {\n      count\n      name\n      imageDirectoryUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "07fadbe1ac8bf7974f840c42b243d324";

export default node;
