/**
 * @generated SignedSource<<5a824159950ce3aa8a288a31b14dd441>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type GoalSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "HIERARCHY_ASC" | "HIERARCHY_DESC" | "HIERARCHY_LEVEL_ASC" | "HIERARCHY_LEVEL_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "PHASE_ID_ASC" | "PHASE_ID_DESC" | "PROJECT_COUNT_ASC" | "PROJECT_COUNT_DESC" | "SCORE_ASC" | "SCORE_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type goalResolverQuery$variables = {
  after?: string | null;
  first?: number | null;
  sorts?: ReadonlyArray<GoalSortEnum | null> | null;
  tql: string;
  workspaceUuid: string;
};
export type goalResolverQuery$data = {
  readonly goalTqlFullHierarchy: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly key: string;
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"GoalName">;
      } | null;
    } | null> | null;
  } | null;
};
export type goalResolverQuery = {
  response: goalResolverQuery$data;
  variables: goalResolverQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sorts"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tql"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "tql"
  },
  {
    "kind": "Variable",
    "name": "sorts",
    "variableName": "sorts"
  },
  {
    "kind": "Variable",
    "name": "workspaceUuid",
    "variableName": "workspaceUuid"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "key"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "goalResolverQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "concreteType": "GoalConnection",
        "kind": "LinkedField",
        "name": "goalTqlFullHierarchy",
        "plural": false,
        "selections": [
          {
            "concreteType": "GoalEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "GoalName"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "goalResolverQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "concreteType": "GoalConnection",
        "kind": "LinkedField",
        "name": "goalTqlFullHierarchy",
        "plural": false,
        "selections": [
          {
            "concreteType": "GoalEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Goal",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "uuid"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  {
                    "concreteType": "GoalState",
                    "kind": "LinkedField",
                    "name": "state",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "score"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "value"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "9759896e16ad2d010f5e108f638a2100",
    "id": null,
    "metadata": {},
    "name": "goalResolverQuery",
    "operationKind": "query",
    "text": "query goalResolverQuery(\n  $tql: String!\n  $workspaceUuid: UUID!\n  $after: String\n  $first: Int\n  $sorts: [GoalSortEnum]\n) {\n  goalTqlFullHierarchy(first: $first, after: $after, q: $tql, workspaceUuid: $workspaceUuid, sorts: $sorts) {\n    edges {\n      node {\n        name\n        key\n        ...GoalName\n        id\n      }\n    }\n  }\n}\n\nfragment GoalIcon on Goal {\n  state {\n    score\n    value\n  }\n}\n\nfragment GoalName on Goal {\n  uuid\n  id\n  key\n  ...GoalIcon\n  name\n}\n"
  }
};
})();

(node as any).hash = "57bf780e88fa46a1872fa6171365762d";

export default node;
