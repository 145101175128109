/**
 * @generated SignedSource<<920c6196dc950c5fb4125a7f8873b7d6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type AtlasIntercomQuery$variables = {
  aaidOrHash: string;
  workspaceId: string;
};
export type AtlasIntercomQuery$data = {
  readonly currentUser: {
    readonly msteamsTenants: ReadonlyArray<{
      readonly __typename: "TenantResponseBody";
    } | null> | null;
    readonly slackTeams: ReadonlyArray<{
      readonly __typename: "TeamResponseBody";
    } | null> | null;
  } | null;
  readonly reportingLines: {
    readonly __typename: "ReportingLines";
  } | null;
  readonly workspace: {
    readonly userWorkspacePermissions: {
      readonly admin: boolean;
    } | null;
  } | null;
};
export type AtlasIntercomQuery = {
  response: AtlasIntercomQuery$data;
  variables: AtlasIntercomQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "aaidOrHash"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceId"
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "name": "__typename"
  }
],
v2 = {
  "args": [
    {
      "kind": "Variable",
      "name": "aaidOrHash",
      "variableName": "aaidOrHash"
    }
  ],
  "concreteType": "ReportingLines",
  "kind": "LinkedField",
  "name": "reportingLines",
  "plural": false,
  "selections": (v1/*: any*/)
},
v3 = [
  {
    "kind": "Variable",
    "name": "workspaceId",
    "variableName": "workspaceId"
  }
],
v4 = {
  "concreteType": "TeamResponseBody",
  "kind": "LinkedField",
  "name": "slackTeams",
  "plural": true,
  "selections": (v1/*: any*/)
},
v5 = {
  "concreteType": "TenantResponseBody",
  "kind": "LinkedField",
  "name": "msteamsTenants",
  "plural": true,
  "selections": (v1/*: any*/)
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "workspaceId"
  }
],
v7 = {
  "concreteType": "UserWorkspacePermissions",
  "kind": "LinkedField",
  "name": "userWorkspacePermissions",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "admin"
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "AtlasIntercomQuery",
    "selections": [
      (v2/*: any*/),
      {
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      },
      {
        "args": (v6/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AtlasIntercomQuery",
    "selections": [
      (v2/*: any*/),
      {
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v8/*: any*/)
        ]
      },
      {
        "args": (v6/*: any*/),
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "49e02d2ea0cad52cdd2d0b160fd03bc8",
    "id": null,
    "metadata": {},
    "name": "AtlasIntercomQuery",
    "operationKind": "query",
    "text": "query AtlasIntercomQuery(\n  $aaidOrHash: String!\n  $workspaceId: ID!\n) {\n  reportingLines(aaidOrHash: $aaidOrHash) {\n    __typename\n  }\n  currentUser(workspaceId: $workspaceId) {\n    slackTeams {\n      __typename\n    }\n    msteamsTenants {\n      __typename\n    }\n    id\n  }\n  workspace(id: $workspaceId) {\n    userWorkspacePermissions {\n      admin\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a126c27d613a91cb64d2a838986b5a8e";

export default node;
