import { useRouterActions } from '@townsquare/router/primitives';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';

import { AvailableDrawers, QueryParameterPair } from './types';

// TODO: Make extraUrlParameters bound to drawerType to lock the state down more.
const resolveUrlSearchParameters = (drawerType: string, extraUrlParameters?: QueryParameterPair[]) => {
  const urlSearchParameters = createSearchParamsFromCurrentUrl();

  urlSearchParameters.append('drawer', drawerType);
  extraUrlParameters?.forEach(keyValuePair => {
    urlSearchParameters.append(keyValuePair.param, keyValuePair.value);
  });
  return urlSearchParameters;
};

export const useDrawerActions = (drawerType: AvailableDrawers) => {
  const { setQueryParams } = useRouterActions();

  return {
    openDrawer: (extraUrlParameters?: QueryParameterPair[]) => {
      const urlSearchParameters = resolveUrlSearchParameters(drawerType, extraUrlParameters);

      setQueryParams(urlSearchParameters);
    },
    getHref: (extraUrlParameters?: QueryParameterPair[]) => {
      return `?${resolveUrlSearchParameters(drawerType, extraUrlParameters)}`;
    },
  };
};
