/**
 * @generated SignedSource<<580cd2b9ac6b05a7861ba03af543b713>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DirectoryTeamSortEnum = "NAME_ASC" | "NAME_DESC";
export type SearchTeamsPaginationQuery$variables = {
  after?: string | null;
  cloudId: string;
  first?: number | null;
  isSearchTeamsScreen: boolean;
  organisationId: string;
  query: string;
  sort?: ReadonlyArray<DirectoryTeamSortEnum | null> | null;
};
export type SearchTeamsPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchTeamsScreen_data">;
};
export type SearchTeamsPaginationQuery = {
  response: SearchTeamsPaginationQuery$data;
  variables: SearchTeamsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSearchTeamsScreen"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
},
v5 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "query"
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "SearchTeamsPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "isSearchTeamsScreen",
            "variableName": "isSearchTeamsScreen"
          },
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "query",
            "variableName": "query"
          },
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SearchTeamsScreen_data"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchTeamsPaginationQuery",
    "selections": [
      {
        "condition": "isSearchTeamsScreen",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "searchTeams",
            "args": (v6/*: any*/),
            "concreteType": "TeamSearchResponseConnection",
            "kind": "LinkedField",
            "name": "teamsTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "TeamSearchResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "TeamSearchResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "description"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "displayName"
                      },
                      {
                        "concreteType": "TeamMemberConnection",
                        "kind": "LinkedField",
                        "name": "members",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "count"
                          },
                          {
                            "concreteType": "TeamMemberEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "TeamMember",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "UserPII",
                                    "kind": "LinkedField",
                                    "name": "pii",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "name"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "picture"
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "aaid"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "alias": "searchTeams",
            "args": (v6/*: any*/),
            "filters": [
              "q",
              "organisationId",
              "cloudId",
              "sort"
            ],
            "handle": "connection",
            "key": "SearchTeamsScreen_searchTeams",
            "kind": "LinkedHandle",
            "name": "teamsTql"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "7058f2aa030b4e7c38c2db96753effe7",
    "id": null,
    "metadata": {},
    "name": "SearchTeamsPaginationQuery",
    "operationKind": "query",
    "text": "query SearchTeamsPaginationQuery(\n  $after: String\n  $cloudId: String!\n  $first: Int = 20\n  $isSearchTeamsScreen: Boolean!\n  $organisationId: String!\n  $query: String!\n  $sort: [DirectoryTeamSortEnum]\n) {\n  ...SearchTeamsScreen_data_1j3bij\n}\n\nfragment SearchTeamsScreen_data_1j3bij on Query {\n  searchTeams: teamsTql(q: $query, organisationId: $organisationId, cloudId: $cloudId, sort: $sort, first: $first, after: $after) @include(if: $isSearchTeamsScreen) {\n    edges {\n      node {\n        id\n        ...TeamSearchResultCard\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TeamSearchResultCard on TeamSearchResponse {\n  id\n  description\n  displayName\n  members {\n    count\n    edges {\n      node {\n        pii {\n          name\n          picture\n        }\n        aaid\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed20d29908bc5227754fff9814989623";

export default node;
