/* eslint-disable @atlaskit/design-system/no-physical-properties */
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Box, Inline, Stack, Text, XCSS, xcss } from '@atlaskit/primitives';
import React, { MouseEventHandler, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';

import { saveOnboarding } from '../../mutations/SaveOnboardingMutation';
import { OnboardingItemKey } from '../__generated__/OnboardingQuery.graphql';

import { OnboardingPopup_onboardingQuery } from './__generated__/OnboardingPopup_onboardingQuery.graphql';
import { CoveringKind, useCovering, useOnboardingCover } from './onboardingCover';

const popupStyles = xcss({
  position: 'fixed',
  right: 'space.300',
  bottom: 'space.300',
  backgroundColor: 'elevation.surface',
  boxShadow: 'elevation.shadow.overlay',
  width: '320px',
  zIndex: 'dialog',
  borderRadius: 'border.radius.200',
});

const bodyTextStyles = xcss({
  color: 'color.text.subtle',
});

const imageOverflowStyles = xcss({
  borderRadius: 'border.radius.200',
  overflow: 'hidden',
});

const buttonWrapStyles = xcss({
  flexBasis: 1,
  flexShrink: 1,
  alignSelf: 'center',
  whiteSpace: 'nowrap',
});

type OnboardingPopupScreenComponents = {
  highlightImage: ReactNode;
  heading: string | ReactNode;
  detail: string | ReactNode;
  primaryCTA: ((onDismiss: () => void) => ReactNode) | ReactNode;
  popupStylesOverride?: XCSS;
  dismissAction?: boolean;
};

type OnboardingPopupScreenProps = {
  dismiss: () => void;
} & OnboardingPopupProps;

const OnboardingPopupScreen = (props: OnboardingPopupScreenProps) => {
  const analytics = useAnalytics();

  useOnMount(() => {
    analytics.track('onboardingPopup', 'shown', {
      onboardingKey: props.onboardingKey,
      coveringKind: props.coveringKind,
    });
  });

  const onLearnMoreLinkClick: MouseEventHandler<HTMLObjectElement> = e => {
    if (e?.target instanceof Element && e.target.tagName === 'A') {
      analytics.ui('onboardingPopupLearnMoreLink', 'clicked', {
        onboardingKey: props.onboardingKey,
        coveringKind: props.coveringKind,
      });
    }
  };

  return (
    <Box xcss={[popupStyles, props.popupStylesOverride]}>
      <Stack xcss={imageOverflowStyles}>
        {props.highlightImage}
        <Box padding="space.250">
          <Stack>
            <Heading size="small">{props.heading}</Heading>
            <Box
              paddingBlockStart="space.100"
              paddingBlockEnd="space.200"
              xcss={bodyTextStyles}
              onClick={onLearnMoreLinkClick}
            >
              <Text>{props.detail}</Text>
            </Box>
            <Inline space="space.100" alignInline="end">
              {props.dismissAction ? (
                <Button appearance="subtle" onClick={props.dismiss}>
                  <FormattedMessage
                    id="townsquare.onboarding.popup.dismiss-button"
                    description="Button to dismiss the pop up"
                    defaultMessage="Dismiss"
                  />
                </Button>
              ) : null}
              <Box
                xcss={buttonWrapStyles}
                onClick={() => {
                  analytics.ui('onboardingPopupPrimaryButton', 'clicked', {
                    onboardingKey: props.onboardingKey,
                    coveringKind: props.coveringKind,
                  });
                }}
              >
                {typeof props.primaryCTA === 'function' ? props.primaryCTA(props.dismiss) : props.primaryCTA}
              </Box>
            </Inline>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

type OnboardingPopupProps = {
  onboardingKey: OnboardingItemKey;
  coveringKind: CoveringKind;
  highlightImage: ReactNode;
} & OnboardingPopupScreenComponents;

export const OnboardingPopup = (props: OnboardingPopupProps) => {
  const { onboardingKey, coveringKind } = props;
  const environment = useRelayEnvironment();
  const analytics = useAnalytics();

  const data = useLazyLoadQuery<OnboardingPopup_onboardingQuery>(
    graphql`
      query OnboardingPopup_onboardingQuery($onboardingKey: OnboardingItemKey!) {
        currentUser {
          onboarding(first: 1, filter: { keys: [$onboardingKey] }) {
            __id
            edges {
              node {
                key
              }
            }
          }
        }
      }
    `,
    {
      onboardingKey,
    },
  );

  const onboarding = data?.currentUser?.onboarding;

  const onboardingId = onboarding?.__id ?? '';
  const isSeen = onboarding?.edges?.some(e => e?.node?.key === onboardingKey);
  const { hideOnboarding } = useOnboardingCover();
  const { shouldHide: isCovered } = useCovering(coveringKind);

  const [isOpen, setOpen] = useState(!isSeen);

  const dismiss = () => {
    saveOnboarding(environment, { onboardingKey }, [onboardingId]);
    hideOnboarding(coveringKind);
    setOpen(false);
    analytics.track('onboardingPopup', 'dismissed', {
      onboardingKey,
      coveringKind,
    });
  };

  if (!isOpen || isCovered) {
    return null;
  }

  return <OnboardingPopupScreen dismiss={dismiss} {...props} />;
};
