import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import AdminUpgradeActionBillingAdminQuery from '../actions/AdminUpgradeAction/__generated__/AdminUpgradeActionBillingAdminQuery.graphql';
import { AdminUpgradeActionBillingAdminQuery as AdminUpgradeActionBillingAdminQueryType } from '../actions/AdminUpgradeAction/__generated__/AdminUpgradeActionBillingAdminQuery.graphql';

export const adminUpgradeResource = createRelayResource<AdminUpgradeActionBillingAdminQueryType>({
  getQuery: (_routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    return {
      query: AdminUpgradeActionBillingAdminQuery,
      variables: {
        id: context.workspace?.globalId ?? '',
      },
    };
  },
});
