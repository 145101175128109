// eslint-disable-next-line no-restricted-imports
import { gql } from 'apollo-boost';

export const CLOUD_USER = gql`
  query CloudUser($aaid: String!) {
    CloudUser(userId: $aaid) {
      id
      fullName
      nickname
      email
      avatarUrl
    }
  }
`;
