import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
  // Field settings page + custom field create/edit form
  customFieldsNameEmptyError: {
    defaultMessage: `Give this field a unique name.`,
    description: 'Custom fields name input empty error',
    id: 'townsquare.custom-fields-form.name-input.empty-error',
  },
  customFieldsMaxLengthExceededError: {
    defaultMessage: `Must be {maxLength} characters or less.`,
    description: 'Custom fields text max length error',
    id: 'townsquare.custom-fields-form.max-length.error',
  },
  customFieldsNameMaxLengthExceededError: {
    defaultMessage: `Name must be {maxLength} characters or less.`,
    description: 'Custom fields field name max length error',
    id: 'townsquare.custom-fields-form.name.max-length-error',
  },
  selectFieldAddOptionLabel: {
    id: 'townsquare.custom-fields.definitions.selectFieldAddOptionLabel',
    defaultMessage: 'Add option',
    description: 'Label of the button to add an option to a select field definition',
  },
  fieldTypeHelperText: {
    description: 'Helper text for custom field type',
    defaultMessage: "You can't change the type after creating a field.",
    id: 'townsquare.custom-fields-form.field-type.helper-text',
  },
  fieldDescriptionHelperText: {
    description: 'Helper text for custom field description',
    defaultMessage: 'Users see this info when they hover over the field.',
    id: 'townsquare.custom-fields-form.description.helper-text',
  },
  saveButton: {
    description: 'Save button for custom field form',
    defaultMessage: 'Save',
    id: 'townsquare.custom-fields-form.save.button',
  },
  cancelButton: {
    description: 'Cancel button for custom field form',
    defaultMessage: 'Cancel',
    id: 'townsquare.custom-fields-form.cancel.button',
  },
  duplicateOptionError: {
    description: 'Error message for duplicate option',
    defaultMessage: 'You cannot have two of the same options.',
    id: 'townsquare.custom-fields-form.duplicate-option.error',
  },
  customFieldsTypeEmptyError: {
    description: 'Custom fields type empty error',
    defaultMessage: `Please select a field type`,
    id: 'townsquare.custom-fields-form.type-input.empty-error',
  },
  noOptionsProvidedError: {
    description: 'Error message for no options provided',
    defaultMessage: 'Please provide at least one option',
    id: 'townsquare.custom-fields-form.no-options-provided.error',
  },
  numberFieldTooManyLeadingDigitsError: {
    description: 'Error message for too many leading digits in number field',
    defaultMessage:
      'Number must have 10 digits or less{hasDecimal, select, true { before the decimal point} other {}}.',
    id: 'townsquare.custom-fields-form.number-field-too-many-leading-digits.error',
  },
  numberFieldTooManyDecimalPlacesError: {
    description: 'Error message for too many decimal places in number field',
    defaultMessage: 'Numbers must have 4 digits or less after the decimal point.',
    id: 'townsquare.custom-fields-form.number-field-too-many-decimal-places.error',
  },
  numberFieldContainsNonNumericValueError: {
    description: 'Error message for non-numeric value in number field',
    defaultMessage: 'This field can only have numeric values.',
    id: 'townsquare.custom-fields-form.number-field-contains-non-numeric-value.error',
  },
  duplicateNameError: {
    description: 'Error message for duplicate field name',
    defaultMessage: 'A field with that name already exists. Give this field a unique name.',
    id: 'townsquare.custom-fields-form.duplicate-name.error',
  },
  genericSubmissionErrorTitle: {
    description: 'Generic error message title for form submission',
    defaultMessage: 'Something went wrong',
    id: 'townsquare.custom-fields-form.generic-submission.error-title',
  },
  genericSubmissionErrorDescription: {
    description: 'Generic error message description for form submission',
    defaultMessage: `We weren't able to {action} your custom field`,
    id: 'townsquare.custom-fields-form.generic-submission.error-description',
  },
  limitReachedErrorTitle: {
    description: 'Error message title when the custom field limit is reached',
    defaultMessage: 'Custom field could not be saved',
    id: 'townsquare.custom-fields-form.limit-reached.error-title',
  },
  limitReachedErrorDescription: {
    description: 'Error message description when the custom field limit is reached',
    defaultMessage:
      'This workspace already has the maximum number of custom fields. Try replacing an existing field or request more fields.',
    id: 'townsquare.custom-fields-form.limit-reached.error-description',
  },
  limitReachedErrorCTA: {
    description: 'Error message CTA when the custom field limit is reached',
    defaultMessage: 'Send feedback',
    id: 'townsquare.custom-fields-form.limit-reached.error-cta',
  },
  numberFieldLabel: {
    description: 'Label for number custom field input',
    defaultMessage: 'Enter number',
    id: 'townsquare.custom-fields.number.label',
  },
  textFieldLabel: {
    description: 'Label for text custom field input',
    defaultMessage: 'Enter text',
    id: 'townsquare.custom-fields.text.label',
  },
  // Sidebar fields
  selectPickerAllOptionsSelected: {
    description: 'Message to display in select field picker when all options have been selected',
    defaultMessage: 'All options have been selected',
    id: 'townsquare.custom-fields.select-picker.all-options-selected',
  },
  selectPickerNoMatchingOptions: {
    description: 'Message to display in select field picker when no options match the search input',
    defaultMessage: 'No matching options',
    id: 'townsquare.custom-fields.select-picker.no-matching-options',
  },

  // Tooltip messages for the Add/Edit buttons on sidebar fields
  textFieldInstanceAddValue: {
    description: "Tooltip/label for a number field's add/edit button when no value is present",
    defaultMessage: 'Add text',
    id: 'townsquare.custom-fields.text.add-value',
  },
  textFieldInstanceEditValue: {
    description: "Tooltip/label for a number field's add/edit button when value is present",
    defaultMessage: 'Edit text',
    id: 'townsquare.custom-fields.text.edit-value',
  },
  numberFieldInstanceAddValue: {
    description: "Tooltip/label for a number field's add/edit button when no value is present",
    defaultMessage: 'Add number',
    id: 'townsquare.custom-fields.number.add-value',
  },
  numberFieldInstanceEditValue: {
    description: "Tooltip/label for a number field's add/edit button when value is present",
    defaultMessage: 'Edit number',
    id: 'townsquare.custom-fields.number.edit-value',
  },
  singleUserFieldInstanceAddValue: {
    description: "Tooltip/label for a single-value user field's add/edit button when no value is present",
    defaultMessage: 'Add user',
    id: 'townsquare.custom-fields.user-single.add-value',
  },
  singleUserFieldInstanceEditValue: {
    description: "Tooltip/label for a single-value user field's add/edit button when value is present",
    defaultMessage: 'Edit user',
    id: 'townsquare.custom-fields.user-single.edit-value',
  },
  singleSelectFieldInstanceAddValue: {
    description: "Tooltip/label for a single-value select field's add/edit button when no value is present",
    defaultMessage: 'Add option',
    id: 'townsquare.custom-fields.select-single.add-value',
  },
  singleSelectFieldInstanceEditValue: {
    description: "Tooltip/label for a single-value select field's add/edit button when value is present",
    defaultMessage: 'Edit option',
    id: 'townsquare.custom-fields.select-single.edit-value',
  },

  // For multi-value user and select fields, the add button is shown both when
  // there are and aren't existing values
  multiUserFieldInstanceAddValues: {
    description: "Tooltip/label for a multi-value user custom field's add/edit button",
    defaultMessage: 'Add users',
    id: 'townsquare.custom-fields.user-multi.add-values',
  },
  multiSelectFieldInstanceAddValues: {
    description: "Tooltip/label for a multi-value select custom field's add/edit button",
    defaultMessage: 'Add options',
    id: 'townsquare.custom-fields.select-multi.add-values',
  },

  // Fallbacks for when custom field instance type is missing
  fieldInstanceFallbackAddValue: {
    description: "Tooltip/label for a custom field's add/edit button when no value is present and type is missing",
    defaultMessage: 'Add field',
    id: 'townsquare.custom-fields.fallback.addValue',
  },
  fieldInstanceFallbackEditValue: {
    defaultMessage: 'Edit field',
    description: "Tooltip/label for a custom field's add/edit button when a value is present and type is missing",
    id: 'townsquare.custom-fields.fallback.edit-value',
  },
});
