import { Grid, Stack, media, xcss } from '@atlaskit/primitives';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { ResponsiveLoadingState } from '@townsquare/loading-state';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { BrowseTeamCard } from '../Cards/Browse/Team';
import { BrowseTeamCardLoadingState } from '../Cards/Browse/Team/loadingState';
import { Heading } from '../Heading';
import { ScreenType } from '../ScreenType';
import { CardRow } from '../styles';

import { BrowseTeamsEmptyState } from './BrowseTeamsEmptyState';
import { BrowseTeamSection$key } from './__generated__/BrowseTeamSection.graphql';

export const BROWSE_TEAM_CARD_SLOTS = 3;

export const getLoadingCards = () =>
  [...Array(BROWSE_TEAM_CARD_SLOTS)].map((_, i) => (
    <BrowseTeamCardLoadingState key={`BrowseTeamCardLoadingState${i}`} />
  ));

export interface BrowseTeamSectionProps {
  data: BrowseTeamSection$key;
}

const cardRowStyles = xcss({
  gridTemplateColumns: 'repeat(auto-fill, 408px)',
});

export const BrowseTeamsSectionSkeleton = () => {
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  if (enableHomeVisualRefresh) {
    return (
      <BrowseTeamsSectionFrame hasTeams={false}>
        <Grid xcss={gridStyles}>{getLoadingCards()}</Grid>
      </BrowseTeamsSectionFrame>
    );
  }

  return (
    <BrowseTeamsSectionFrame hasTeams={false}>
      <ResponsiveLoadingState>
        <Grid xcss={[gridStyles, cardRowStyles]}>{getLoadingCards()}</Grid>
      </ResponsiveLoadingState>
    </BrowseTeamsSectionFrame>
  );
};

const gridStyles = xcss({
  gap: 'space.150',
  [media.above.md]: {
    gap: 'space.200',
  },
  gridTemplateColumns: `repeat(auto-fill, minmax(352px, 1fr))`,
});

type BrowseTeamsSectionFrameProps = { children: React.ReactNode; hasTeams: boolean };

const BrowseTeamsSectionFrame = ({ children, hasTeams }: BrowseTeamsSectionFrameProps) => {
  const intl = useIntl();
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  const heading = (
    <Heading
      browseMoreText={
        hasTeams
          ? intl.formatMessage({
              id: 'townsquare.web.browse-teams-section.browse-all',
              description: 'Browse more heading text',
              defaultMessage: 'Browse all',
            })
          : undefined
      }
      screenType={ScreenType.SEARCH_TEAMS}
    >
      <FormattedMessage
        id="townsquare.web.browse-teams-section.heading"
        description="Browse teams section heading"
        defaultMessage="Teams"
      />
    </Heading>
  );

  if (enableHomeVisualRefresh) {
    return (
      <Stack space="space.150">
        {heading}
        {children}
      </Stack>
    );
  }

  return (
    <>
      {heading}
      {children}
    </>
  );
};

export const BrowseTeamsSection = (props: BrowseTeamSectionProps) => {
  const data = useFragment(
    graphql`
      fragment BrowseTeamSection on Query
      @argumentDefinitions(
        query: { type: "String!" }
        first: { type: "Int" }
        after: { type: "String" }
        organisationId: { type: "String!" }
        cloudId: { type: "String" }
      ) {
        browseTeams: teamsTql(
          q: $query
          first: $first
          sort: [NAME_ASC]
          after: $after
          organisationId: $organisationId
          cloudId: $cloudId
        ) {
          edges {
            node {
              ...TeamCard
            }
          }
        }
      }
    `,
    props.data,
  );

  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  if (!data.browseTeams?.edges) {
    return null;
  }

  if (!data.browseTeams.edges.length) {
    return (
      <BrowseTeamsSectionFrame hasTeams={false}>
        <BrowseTeamsEmptyState />
      </BrowseTeamsSectionFrame>
    );
  }

  return (
    <BrowseTeamsSectionFrame hasTeams>
      {enableHomeVisualRefresh ? (
        <Grid xcss={gridStyles}>
          {data.browseTeams.edges
            ?.slice(0, BROWSE_TEAM_CARD_SLOTS)
            .map(team => team?.node && <BrowseTeamCard data={team.node} />)}
        </Grid>
      ) : (
        <CardRow shouldAvoidBleed>
          {data.browseTeams.edges
            ?.slice(0, BROWSE_TEAM_CARD_SLOTS)
            .map(team => team?.node && <BrowseTeamCard data={team.node} />)}
        </CardRow>
      )}
    </BrowseTeamsSectionFrame>
  );
};
