import { fetchQuery, graphql } from 'relay-runtime';

import { RelayEnvironment } from '@townsquare/relay-utils';

import { UserTeamQuery } from './__generated__/UserTeamQuery.graphql';
import { ResolvedTeam } from './types';

export function UserTeamById(teamId: string, cloudId: string): Promise<ResolvedTeam> {
  return fetchQuery<UserTeamQuery>(
    RelayEnvironment,
    graphql`
      query UserTeamQuery($teamId: String!, $cloudId: String) {
        teamByTeamId(teamId: $teamId, cloudId: $cloudId) {
          teamId
          name
          avatarUrl
        }
      }
    `,
    {
      teamId,
      cloudId,
    },
  )
    .toPromise()
    .then(data => ({
      id: data?.teamByTeamId?.teamId ?? teamId,
      name: data?.teamByTeamId?.name ?? 'unknown',
      avatarUrl: data?.teamByTeamId?.avatarUrl ?? undefined,
    }))
    .catch(() => ({
      id: teamId,
      name: 'unknown',
    }));
}
