import React from 'react';
import { useIntl } from 'react-intl-next';

import { DecisionIcon } from './DecisionIcon';
import { LightbulbIcon } from './LightbulbIcon';
import { SirenIcon } from './SirenIcon';

type LearningType = 'LEARNING' | 'RISK' | 'DECISION';

export const HighlightIcon = (props: { type: LearningType | null | undefined }) => {
  const { type } = props;
  const intl = useIntl();

  if (type === 'LEARNING') {
    return (
      <LightbulbIcon
        label={intl.formatMessage({
          id: 'townsquare.packages.icons.learning',
          defaultMessage: 'Lightbulb icon',
          description: 'Lightbulb icon',
        })}
      />
    );
  } else if (type === 'DECISION') {
    return (
      <DecisionIcon
        label={intl.formatMessage({
          id: 'townsquare.packages.icons.decision',
          defaultMessage: 'Decision icon',
          description: 'Decision icon',
        })}
      />
    );
  } else if (type === 'RISK') {
    return (
      <SirenIcon
        label={intl.formatMessage({
          id: 'townsquare.packages.icons.risk',
          defaultMessage: 'Risk icon',
          description: 'Risk icon',
        })}
      />
    );
  } else {
    return (
      <LightbulbIcon
        label={intl.formatMessage({
          id: 'townsquare.packages.icons.learning',
          defaultMessage: 'Lightbulb icon',
          description: 'Lightbulb icon',
        })}
      />
    );
  }
};
