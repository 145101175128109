import { ToneSelection } from '@atlaskit/emoji';

import { useAsyncEffect, useOnMount } from '@townsquare/hooks';

import { useEmojiApi } from './emojiStore';
import { emojiProvider } from './provider';
import { DEFAULT_TONE, SELECTED_TONE_CHANGED_EVENT } from './withSelectedToneEmitter';

export const EmojiToneSelectionListener = () => {
  const [, { updateSelectedEmojiTone }] = useEmojiApi();

  // Load saved tone selection
  useAsyncEffect(async () => {
    const emoji = await emojiProvider;
    void updateSelectedEmojiTone(emoji.getSelectedTone());
  });

  // Listen to future tone selection
  useOnMount(() => {
    const onToneSelectedHandler = (eventInitDict: CustomEventInit<{ tone: ToneSelection }>) => {
      void updateSelectedEmojiTone(eventInitDict.detail?.tone || DEFAULT_TONE);
    };
    window.addEventListener(SELECTED_TONE_CHANGED_EVENT, onToneSelectedHandler);
    // Clean up function
    return () => {
      window.removeEventListener(SELECTED_TONE_CHANGED_EVENT, onToneSelectedHandler);
    };
  });

  return null;
};
