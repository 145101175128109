import { createRelayResource } from '@townsquare/relay-utils';

import OnboardingQuery, {
  type OnboardingQuery as OnboardingQueryType,
} from '../components/__generated__/OnboardingQuery.graphql';

export const onboardingResource = createRelayResource<OnboardingQueryType>({
  getQuery: () => {
    return {
      query: OnboardingQuery,
    };
  },
});
