/**
 * @generated SignedSource<<ce20ac6cc515c26d01e4b6ce1b7ce4c6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DirectoryViewEntityType = "GOAL" | "PROJECT";
export type DirectoryViewUiType = "list" | "timeline";
export type GoalSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "HIERARCHY_ASC" | "HIERARCHY_DESC" | "HIERARCHY_LEVEL_ASC" | "HIERARCHY_LEVEL_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "PHASE_ID_ASC" | "PHASE_ID_DESC" | "PROJECT_COUNT_ASC" | "PROJECT_COUNT_DESC" | "SCORE_ASC" | "SCORE_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type ProjectSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "STATUS_ASC" | "STATUS_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type StandardDirectoryViewColumnName = "CONTRIBUTORS" | "FOLLOWER_COUNT" | "FOLLOWING" | "GOALS" | "LAST_UPDATED" | "LINKS" | "OWNER" | "PROGRESS" | "RELATED_PROJECTS" | "START_DATE" | "STATUS" | "SUB_GOALS" | "TAGS" | "TARGET_DATE" | "TEAMS" | "WORK_TRACKING";
export type TimelineDateSpan = "month" | "week";
import type { FragmentRefs } from "relay-runtime";
export type storeDirectoryView$data = {
  readonly creator: {
    readonly aaid: string | null;
  } | null;
  readonly directoryViewColumns: ReadonlyArray<{
    readonly __typename: "CustomDirectoryViewColumn";
    readonly id: string;
    readonly uuid: string | null;
  } | {
    readonly __typename: "StandardDirectoryViewColumn";
    readonly id: string;
    readonly name: StandardDirectoryViewColumnName | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null> | null;
  readonly entityType: DirectoryViewEntityType | null;
  readonly favouriteCount: number | null;
  readonly id: string;
  readonly isFavourite: boolean | null;
  readonly name: string | null;
  readonly sort: {
    readonly goalSort: ReadonlyArray<GoalSortEnum | null> | null;
    readonly projectSort: ReadonlyArray<ProjectSortEnum | null> | null;
  } | null;
  readonly storedTql: string | null;
  readonly uiMetadata: {
    readonly timelineDateSpan: TimelineDateSpan | null;
  } | null;
  readonly uiViewType: DirectoryViewUiType | null;
  readonly uuid: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DirectoryFavoriteViewButton" | "DirectoryManageViewDialog">;
  readonly " $fragmentType": "storeDirectoryView";
};
export type storeDirectoryView$key = {
  readonly " $data"?: storeDirectoryView$data;
  readonly " $fragmentSpreads": FragmentRefs<"storeDirectoryView">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "storeDirectoryView"
};

(node as any).hash = "e61973975afff56ed561e8f1e4a47178";

export default node;
