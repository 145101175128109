import React from 'react';

import { LoadingSkeleton } from './styles';
import { type Props } from './types';

export { LoadingSkeleton, LoadingSkeletonContainer } from './styles';

export const SquareLoadingSkeleton = ({
  sideLengthPx,
  ...props
}: Omit<Props, 'height' | 'width'> & { sideLengthPx: number }) => (
  <LoadingSkeleton height={`${sideLengthPx}px`} width={`${sideLengthPx}px`} {...props} />
);

export const AvatarLoadingSkeleton = ({
  sideLengthPx,
  ...props
}: Omit<Props, 'height' | 'width'> & { sideLengthPx: number }) => (
  <LoadingSkeleton height={`${sideLengthPx}px`} width={`${sideLengthPx}px`} borderRadius="50%" {...props} />
);

export const PhaseLozengeLoadingSkeleton = (props: Props) => (
  <LoadingSkeleton height={props.height || '24px'} width={props.width || '70px'} {...props} />
);
