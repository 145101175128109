import Flag, { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import SuccessIcon from '@atlaskit/icon/core/migration/success--check-circle';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import React from 'react';

import { FlagType, InternalFlag, useFlagsStore } from '../store';

const getTypeIcon = (type: FlagType, isBold = false) => {
  switch (type) {
    case 'error':
      return (
        <ErrorIcon
          color={token('color.icon.danger')}
          spacing="spacious"
          label=""
          LEGACY_secondaryColor={isBold ? token('color.background.danger.bold') : undefined}
          LEGACY_primaryColor={isBold ? token('color.icon.danger') : undefined}
        />
      );

    case 'warning':
      return (
        <WarningIcon
          color={token('color.icon.warning')}
          spacing="spacious"
          label=""
          LEGACY_secondaryColor={isBold ? token('color.background.warning.bold') : undefined}
          LEGACY_primaryColor={isBold ? token('color.icon.warning') : undefined}
        />
      );

    case 'info':
      return (
        <InfoIcon
          color={token('color.icon.information')}
          spacing="spacious"
          label=""
          LEGACY_secondaryColor={isBold ? token('color.background.information.bold') : undefined}
          LEGACY_primaryColor={isBold ? token('color.icon.information') : undefined}
        />
      );

    default:
      return (
        <SuccessIcon
          color={token('color.icon.success')}
          spacing="spacious"
          label=""
          LEGACY_secondaryColor={isBold ? token('color.background.success.bold') : undefined}
          LEGACY_primaryColor={isBold ? token('color.icon.success') : undefined}
        />
      );
  }
};

export const Flags = () => {
  const [flags, uiApi] = useFlagsStore();

  const renderedFlags = flags.map((flagProps: InternalFlag) => {
    const FlagComponent = flagProps.shouldAutoDismiss ? AutoDismissFlag : Flag;
    return (
      <FlagComponent
        appearance={flagProps.isBold ? flagProps.type : 'normal'}
        key={flagProps.id}
        icon={getTypeIcon(flagProps.type, flagProps.isBold)}
        {...flagProps}
      />
    );
  });

  return <FlagGroup onDismissed={id => uiApi.removeFlag(id as string)}>{renderedFlags}</FlagGroup>;
};
