import { captureException } from '@sentry/react';
import type { RequestParameters, Variables } from 'relay-runtime';

import { RequestError } from '@townsquare/error-state/classes';

import { isFaultyJiraSearchError } from './utils';

const SKIP_SENTRY_LOG_STATUS_CODES = new Set([401, 403, 404, 429]);

export const captureErrorSentry = ({
  fetchUrl,
  request,
  variables,
  error,
  extras = {},
}: {
  fetchUrl: string;
  request: RequestParameters;
  variables: Variables;
  error: RequestError;
  extras: Record<string, unknown>;
}) => {
  if (SKIP_SENTRY_LOG_STATUS_CODES.has(error.statusCode)) {
    return;
  }

  if (error.name.includes('AbortError') || error.message.includes('aborted')) {
    // Don't log AbortErrors as they are expected
    return;
  }

  if (isFaultyJiraSearchError(error)) {
    // Don't capture bad 5xx from Jira search
    return;
  }

  captureException(error, scope =>
    scope
      .setTag('operationName', request.name)
      .setExtra('requestVariables', variables)
      .setExtras(request)
      .setExtra('graphQLUrl', fetchUrl)
      .setExtras(extras)
      .setFingerprint([request.name, String(error.statusCode)]),
  );
};
