/**
 * @generated SignedSource<<2d53ccd8952221c5d7844d5edb71216e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type KudosBgThemeType = "blue" | "green" | "pink" | "purple";
export type KudosType = "INDIVIDUAL" | "TEAM";
import type { FragmentRefs } from "relay-runtime";
export type KudosContent$data = {
  readonly ari: string;
  readonly bgTheme: KudosBgThemeType | null;
  readonly description: string | null;
  readonly giver: {
    readonly pii: {
      readonly accountId: string | null;
      readonly name: string | null;
      readonly picture: string | null;
    } | null;
  } | null;
  readonly imageUrl: string | null;
  readonly recipients: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly recipient: {
          readonly pii: {
            readonly accountId: string | null;
            readonly name: string | null;
            readonly picture: string | null;
          } | null;
        };
        readonly team: {
          readonly avatarUrl: string | null;
          readonly name: string | null;
          readonly teamId: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly stillImageUrl: string | null;
  readonly type: KudosType | null;
  readonly uuid: string | null;
  readonly " $fragmentType": "KudosContent";
};
export type KudosContent$key = {
  readonly " $data"?: KudosContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"KudosContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
},
v1 = {
  "kind": "ScalarField",
  "name": "picture"
},
v2 = {
  "kind": "ScalarField",
  "name": "accountId"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "KudosContent",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    {
      "kind": "ScalarField",
      "name": "ari"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "imageUrl"
    },
    {
      "kind": "ScalarField",
      "name": "stillImageUrl"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "bgTheme"
    },
    {
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "giver",
      "plural": false,
      "selections": [
        {
          "concreteType": "UserPII",
          "kind": "LinkedField",
          "name": "pii",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "KudosRecipientConnection",
      "kind": "LinkedField",
      "name": "recipients",
      "plural": false,
      "selections": [
        {
          "concreteType": "KudosRecipientEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "KudosRecipient",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "recipient",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "UserPII",
                      "kind": "LinkedField",
                      "name": "pii",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "team",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "teamId"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "avatarUrl"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "recipients(first:1)"
    }
  ],
  "type": "Kudos"
};
})();

(node as any).hash = "0814c8ef9b5458f3921723205162c56d";

export default node;
