import { createActionRoute, createSiteRoute } from '@townsquare/router/create-route';

import { FollowProjectAction } from './loadable';
import { followProjectResource } from './resource';

export const followProjectActionRoute = createSiteRoute(
  createActionRoute({
    component: FollowProjectAction,
    exact: true,
    name: 'followProjectAction',
    path: '/project/:key/:action(follow|unfollow)',
    resources: [followProjectResource],
  }),
);
