import { filterUndefined } from '@townsquare/filter-type';
import type { SortTypes, SortDirection } from '@townsquare/sort-button';

/**
 * Takes a raw sort key from the URL and transforms it to a new sort key.
 * e.g.
 * `NAME-ASC` -> `[NAME_ASC]`
 * `PHASE_ID-ASC,SCORE-ASC` -> `['PHASE_ID_ASC', 'SCORE_ASC']`
 */
export function resolveRawSort<TSort extends SortTypes>(rawSort?: string): TSort[] | undefined {
  if (!rawSort) {
    return;
  }

  if (rawSort.includes(',')) {
    return rawSort
      .split(',')
      .map(s => transformSortKey<TSort>(s))
      .filter(filterUndefined);
  } else {
    const transform = transformSortKey<TSort>(rawSort);
    return transform && resolveSorts<TSort>(transform);
  }
}

const scoreAsc: SortTypes = 'SCORE_ASC';
const scoreDesc: SortTypes = 'SCORE_DESC';

export function resolveSorts<TSort extends SortTypes>(primarySort: TSort): TSort[] {
  let initialSorts: TSort[] = [primarySort];
  if (primarySort === scoreAsc) {
    initialSorts = ['PHASE_ID_ASC' as TSort, ...initialSorts];
  }
  if (primarySort === scoreDesc) {
    initialSorts = ['PHASE_ID_DESC' as TSort, ...initialSorts];
  }

  const sorts = new Set<TSort>(initialSorts);

  return Array.from<TSort>(sorts.add('NAME_ASC' as TSort));
}
/**
 * Transforms a legacy sort key to a new sort key.
 *
 * Example transforms:
 * `NAME-ASC` -> `NAME_ASC`
 * `WATCHING-DESC` -> `WATCHING_DESC`
 * `LAST_UPDATED-DESC` -> `LAST_UPDATED_DESC`
 */
export function transformSortKey<TSort extends SortTypes>(key?: string): TSort | undefined {
  const [sortKey, sortOrder] = key?.split('-') ?? [];
  if (sortKey && sortOrder) {
    return `${sortKey}_${sortOrder}` as TSort;
  }
  return key as TSort | undefined;
}

/**
 * Type helpers to convert GraphQL Enums to legacy sort types.
 */
type TransformSortKeyToLegacyKey<S extends SortTypes> = S extends `${infer Name}_ASC`
  ? `${Name}-ASC`
  : S extends `${infer Name}_DESC`
  ? `${Name}-DESC`
  : never;

type ExtractSortKeyName<S extends SortTypes> = S extends `${infer Name}_ASC`
  ? Name
  : S extends `${infer Name}_DESC`
  ? Name
  : never;
/**
 * Maintain backwards compat with old directory sort keys
 * This is important for embeds, and previously shared links.
 *
 * Example transforms:
 * `NAME_ASC` -> `NAME-ASC`
 * `WATCHING_DESC` -> `WATCHING-DESC`
 * `LAST_UPDATED_DESC` -> `LAST_UPDATED-DESC`
 */

export function transformSortKeyToLegacyKey<TSort extends SortTypes>(key: TSort): TransformSortKeyToLegacyKey<TSort> {
  const sortParts = key.split('_');
  const sortOrder = sortParts.pop() as SortDirection;
  const sortKey = sortParts.join('_') as ExtractSortKeyName<TSort>;
  return `${sortKey}-${sortOrder}` as TransformSortKeyToLegacyKey<TSort>;
}
