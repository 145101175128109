import { borderRadius } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { ActionBar } from '@townsquare/action-bar';
import { DescriptionEditorStyles } from '@townsquare/description-editor';
import { InfoBox } from '@townsquare/expandable-panel';
import { Link } from '@townsquare/router/primitives';
import { UserAvatarStyle } from '@townsquare/user-avatar';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CopyLinkWrapper = styled.div({
  padding: token('space.050'),
  alignSelf: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningTitleDisplayWrapper = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningTitleLeftSideWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningTitlePlaceholder = styled.div<{ expanded?: boolean }>({
  boxSizing: 'border-box',
  display: 'flex',
  font: token('font.heading.xsmall'),
  fontWeight: token('font.weight.medium'),
  margin: 0,
  overflowWrap: 'anywhere',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const LearningTitleInput = styled.input<{ isInvalid?: boolean }>(props => ({
  '--learning-title-input-border-width': '1px',
  boxSizing: 'border-box',
  font: token('font.heading.xsmall'),
  margin: 0,
  padding: `calc(${token('space.100')} - var(--learning-title-input-border-width))`,
  width: '100%',
  borderStyle: 'solid',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${borderRadius()}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderColor: props.isInvalid ? token('color.border.danger') : token('color.border.input'),
  borderWidth: 'var(--learning-title-input-border-width)',
  '&::placeholder': {
    fontWeight: token('font.weight.regular'),
  },
  '&:focus, &:active': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderColor: props.isInvalid ? token('color.border.danger') : token('color.border.focused'),
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningMetaDataContainer = styled(ActionBar)({
  marginTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const AvatarWrapper = styled.div`
  margin-right: ${token('space.100')};

  ${UserAvatarStyle.UserAvatar} {
    margin: 0;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningSummaryContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> * + *': {
    marginTop: token('space.100'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningSummaryWrapper = styled.div<{ expanded?: boolean }>({
  fontWeight: token('font.weight.medium'),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LearningWrapper = styled.div<{ expanded: boolean | undefined }>`
  ${InfoBox} {
    box-shadow: ${props => props.expanded && token('elevation.shadow.raised')};
    border: ${props => props.expanded && '1px solid transparent'};
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const EditDropdownWrapper = styled.div<{ expanded: boolean }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  display: props.expanded ? 'flex !important' : 'flex',
  flexShrink: 0,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropdownItemDelete = styled.div({
  backgroundColor: token('color.background.danger'),
  color: token('color.text.danger'),
  borderLeft: `2px solid ${token('color.border.danger')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ModalTitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/design-system/use-tokens-space
  columnGap: '5px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LearningListEditorWrapper = styled.div<{ isInvalid?: boolean }>`
  cursor: default;
  box-sizing: border-box;
  width: 100%;
  padding: ${token('space.150')} 1px 5px 31px;

  ${DescriptionEditorStyles.Renderer} {
    padding-left: 9px;
  }

  ${props =>
    props.isInvalid
      ? `
      div.akEditor {
        border-color: ${token('color.border.danger')} !important;
      }
  `
      : ''}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningBylineLink = styled(Link)({
  font: token('font.body.small'),
  color: token('color.text.subtlest'),
  width: 'fit-content',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LearningReactionsWrapper = styled.div({
  display: 'flex',
  gap: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});
