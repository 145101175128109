import React from 'react';

import { useModalActions } from '@townsquare/modals';

import { ExamplesModal } from './components';
import { ExamplesModalOptions } from './types';

export const useExamplesModal = () => {
  const { openModal, closeModal } = useModalActions();

  const openExamplesModal = (options: ExamplesModalOptions) => {
    openModal('examples-modal', {
      component: <ExamplesModal {...options} onClose={() => closeModal('examples-modal')} />,
    });
  };

  return {
    openExamplesModal,
  };
};
