export interface AnalyticsAttributes {
  originId?: string;
  originProduct?: string;
  originMalformed?: boolean;
  src?: string;
}

export function parseEmailSource(src?: string): any {
  return src ? { src } : {};
}

export function parseAtlOrigin(atlOrigin?: string): AnalyticsAttributes {
  if (!atlOrigin) {
    return {};
  }

  try {
    const { i, p } = JSON.parse(atob(atlOrigin));

    return {
      originId: i,
      originProduct: p === 'w' ? 'teamcentral' : p,
    };
  } catch (err) {
    return {
      originMalformed: true,
    };
  }
}
