import FabricAnalyticsListeners, { AnalyticsWebClient, FabricChannel } from '@atlaskit/analytics-listeners';
import React, { PropsWithChildren } from 'react';

export const AkAnalyticsListeners = (props: PropsWithChildren<{ client: AnalyticsWebClient }>) => {
  const includedChannels: FabricChannel[] = [
    FabricChannel.editor,
    FabricChannel.navigation,
    FabricChannel.peopleTeams,
    FabricChannel.notifications,
    FabricChannel.recentWork,
    FabricChannel.atlas,
    FabricChannel.media,
    FabricChannel.crossFlow,
    FabricChannel.postOffice,
    FabricChannel.elements,
    FabricChannel.avp,
    FabricChannel.aiMate,
  ];

  const excludedChannels: FabricChannel[] = [];

  for (const channel in FabricChannel) {
    if (!includedChannels.includes(channel as FabricChannel)) {
      excludedChannels.push(channel as FabricChannel);
    }
  }

  return (
    <FabricAnalyticsListeners excludedChannels={excludedChannels} client={props.client}>
      {props.children}
    </FabricAnalyticsListeners>
  );
};
