import { SortDirection, SortOptions, SortTypes } from './types';

export const getSortLabels = (sortOptions: SortOptions<SortTypes>): string[] => Object.keys(sortOptions);

export const findLabelForOption = <TSort extends SortTypes>(
  sortOptions: SortOptions<SortTypes>,
  labels: string[],
  option: TSort,
): string | undefined =>
  labels.find(label => sortOptions[label].ascSort === option || sortOptions[label].descSort === option);

export const findDirectionForOption = <TSort extends SortTypes>(option: TSort): SortDirection =>
  option.endsWith('ASC') ? 'ASC' : 'DESC';
