import { getApolloClient } from '@atlassian/ptc-common';

import Cache from '@townsquare/cache';
import { isGateEnabled } from '@townsquare/stat-sig/gate';
import { resolveUser as resolveUserAGG } from '@townsquare/user-resolver';

import { UserTeamById } from './UserTeamQuery';
import { CLOUD_USER } from './queries';
import type { ResolvedTeam } from './types';

export type { ResolvedTeam } from './types';

const userAndTeamCache = new Cache();
const CACHE_TTL = 59 * 60 * 1000;

type ResolvedUserPII = { name?: string; picture?: string };
export type ResolvedUser = { aaid: string; pii: ResolvedUserPII };

export const resolveUser = async (aaid: string): Promise<ResolvedUser> => {
  const key = `user:${aaid}`;

  if (userAndTeamCache.has(key)) {
    return userAndTeamCache.get(key);
  }

  if (isGateEnabled('enable_cloud_user_from_status')) {
    return resolveUserAGG(aaid);
  }

  const ptClient = getApolloClient();

  const { data } = await ptClient.query({
    query: CLOUD_USER,
    fetchPolicy: 'cache-first',
    variables: {
      aaid,
    },
  });

  if (data.CloudUser) {
    const user: ResolvedUser = {
      aaid: data.CloudUser.id,
      pii: {
        name: data.CloudUser.fullName,
        picture: data.CloudUser.avatarUrl,
      },
    };
    userAndTeamCache.set(key, user, CACHE_TTL);
    return user;
  }

  return {
    aaid,
    pii: {
      name: 'unknown',
    },
  };
};

export const resolveTeam = async (teamId: string, cloudId: string): Promise<ResolvedTeam> => {
  const key = `team:${teamId}`;

  if (userAndTeamCache.has(key)) {
    return userAndTeamCache.get(key);
  }

  try {
    const team = await UserTeamById(teamId, cloudId);
    userAndTeamCache.set(key, team, CACHE_TTL);
    return team;
  } catch (error) {
    return {
      id: teamId,
      name: 'unknown',
    };
  }
};
