import Loadable from 'react-loadable';

export const FourOhFourPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "FourOhFourPage" */ './FourOhFourPage'),
  loading: () => null,
});

export const NoAccessPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "NoAccessPage" */ './NoAccessPage'),
  loading: () => null,
});

export const RestrictedContentPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "NoAccessPage" */ './RestrictedContentPage'),
  loading: () => null,
});
