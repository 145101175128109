import { Box, xcss } from '@atlaskit/primitives';
import React from 'react';

const boxBaseStyles = xcss({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ProjectUpdatesContainer = ({ children }: { children: React.ReactNode }) => {
  return <Box xcss={boxBaseStyles}>{children}</Box>;
};
