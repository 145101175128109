import React, { Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { FullPageViewErrorBoundary } from '@townsquare/monitoring';
import { filterNull } from '@townsquare/type-helpers';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { BrowseAllPeopleRow } from './BrowseAllPeopleRow';
import { PeopleRowLoadingSkeleton } from './PeopleRowLoadingSkeleton';
import { DefaultSearchFallBackPeopleSearchQuery } from './__generated__/DefaultSearchFallBackPeopleSearchQuery.graphql';

const BROWSE_PEOPLE_CARD_LIMIT = 6;

/*
 * A user may not have collaborators yet  - so we need a fallback.
 *
 * This is a separate component because hooks can't be part of a conditional,
 * but you can get around that by putting them in a conditionally rendered component.
 */
const DefaultSearchFallback = () => {
  const [{ organisationId, cloudId }] = useWorkspaceStore();

  const data = useLazyLoadQuery<DefaultSearchFallBackPeopleSearchQuery>(
    graphql`
      query DefaultSearchFallBackPeopleSearchQuery(
        $query: String!
        $first: Int!
        $sort: [DirectorySortEnum]!
        $profilePicSize: ProfilePictureSize!
        $organisationId: String!
        $cloudId: String!
        $after: String
      ) {
        peopleTql(
          q: $query
          first: $first
          after: $after
          sort: $sort
          organisationId: $organisationId
          cloudId: $cloudId
        ) {
          edges {
            node {
              name
              account_id
              job_title
              highResolutionProfilePicture(size: $profilePicSize) {
                url
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    {
      query: '',
      sort: ['NAME_ASC'],
      profilePicSize: 'MEDIUM',
      first: BROWSE_PEOPLE_CARD_LIMIT,
      organisationId,
      cloudId,
    },
  );

  const users =
    data?.peopleTql?.edges
      ?.map(user => {
        if (user && user.node && user.node.name && user.node.account_id) {
          const { name, account_id, job_title, highResolutionProfilePicture } = user.node;
          return {
            name: name,
            aaid: account_id,
            picture: highResolutionProfilePicture?.url ?? undefined,
            jobTitle: job_title ?? undefined,
          };
        }
        return null;
      })
      .filter(filterNull) ?? [];

  return <BrowseAllPeopleRow users={users} />;
};

export const DefaultSearchFallBackWrapper = () => {
  return (
    <Suspense fallback={<PeopleRowLoadingSkeleton />}>
      <FullPageViewErrorBoundary>
        <DefaultSearchFallback />
      </FullPageViewErrorBoundary>
    </Suspense>
  );
};
