import { createActionRoute, createSiteRoute } from '@townsquare/router/create-route';

import { FollowGoalAction } from './loadable';
import { followGoalResource } from './resource';

export const followGoalActionRoute = createSiteRoute(
  createActionRoute({
    component: FollowGoalAction,
    exact: true,
    name: 'followGoalAction',
    path: '/goal/:key/:action(follow|unfollow)',
    resources: [followGoalResource],
  }),
);
