import { createGetUsersPlugin, UserData, UserIdTypes } from '@atlassiansox/cross-flow-plugins';

interface InstanceUser {
  displayName: string;
  id: string;
  userName: string;
}

export type FetchInstanceUsersResponse = InstanceUser[];

const getUsers = async (containerId: string): Promise<UserData[]> => {
  const getUsersUrl = `/gateway/api/xflow/${containerId}/fetch-instance-users`;

  const response = await fetch(getUsersUrl, {
    credentials: 'same-origin',
  });

  if (response.ok) {
    const data: FetchInstanceUsersResponse = await response.json();

    return data.map<UserData>(value => ({
      id: value.id,
      idType: UserIdTypes.ATLASSIAN_ACCOUNT_ID,
      displayName: value.displayName,
    }));
  }

  throw new Error(`Unexpected response from ${getUsersUrl} ${response.status} ${response.statusText}`);
};

export const getUsersPlugin = createGetUsersPlugin(getUsers);
