import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddPeopleBenefitsModalWrapper = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  div: {
    padding: `0 ${token('space.400')} 0 ${token('space.400')}`,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  h5: {
    marginTop: token('space.300'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  ul: {
    marginTop: token('space.200'),
    paddingLeft: token('space.200'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  li: {
    margin: `0 0 ${token('space.150')} 0`,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  Button: {
    margin: `${token('space.150')} 0 ${token('space.300')} 0`,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  img: {
    borderRadius: '3px 3px 0 0',
  },
});
