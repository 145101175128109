import Loadable from 'react-loadable';

import { DASHBOARD_DIRECTORY_PAGE, DASHBOARD_PAGE } from '@townsquare/config/routes';
import { DASHBOARDS_PATH } from '@townsquare/config/routes';
import { createGlobalRoute, createSiteRoute } from '@townsquare/router/create-route';

export const dashboardDirectoryRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "dashboard-directory-view" */ './components/DashboardDirectory'),
    loading: () => null,
  }),
  exact: true,
  name: DASHBOARD_DIRECTORY_PAGE,
  path: DASHBOARDS_PATH,
});

export const dashboardRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "dashboard-view" */ './components/Dashboard'),
    loading: () => null,
  }),
  exact: true,
  name: DASHBOARD_PAGE,
  path: DASHBOARDS_PATH + '/:slug/:mode?',
  layout: 'expanded',
});
