import { getConfig, isEmbedOrIntegration, isInIframe } from '@townsquare/config';
import { location, redirect } from '@townsquare/facade';

export function getLogInUrl(redirectUrl?: string) {
  const href = redirectUrl || location().href;
  return `${getConfig().atlassianAccountUrl}/login?continue=${encodeURIComponent(href)}&application=atlas`;
}

export function returnAfterLogin() {
  if (isEmbedOrIntegration() || isInIframe()) {
    // Embeds and Jira Integration needs to handle this differently
    return;
  }
  redirect(getLogInUrl());
}
