import LinkIcon from '@atlaskit/icon/core/migration/link';
import CheckCircleIcon from '@atlaskit/icon/core/migration/success--check-circle';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { captureException } from '@sentry/react';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';

import { messages } from '../messages';
import { CopyLinkIconProps } from '../types';
import { getCopyLinkIconText } from '../util';

import { QuickCopyContainer } from './styles';

export const CopyLinkIcon = (props: CopyLinkIconProps) => {
  const analytics = useAnalytics();
  const [LinkCopied, setLinkCopied] = useState(false);
  const updateTooltip = React.useRef<() => void>();
  const intl = useIntl();

  const tooltipContent = useMemo(() => {
    return LinkCopied ? intl.formatMessage(messages.copiedText) : getCopyLinkIconText(props.entity, intl);
  }, [LinkCopied, intl, props.entity]);

  useLayoutEffect(() => {
    updateTooltip.current?.();
  }, [tooltipContent]);

  const copyLink = useCallback(
    e => {
      e.stopPropagation();
      void analytics.ui(`${props.entity}LinkQuickCopy`, 'clicked');
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(props.link)
          .then(() => {
            setLinkCopied(true);
            setTimeout(() => setLinkCopied(false), 2500);
          })
          .catch(reason => captureException(reason));
      }
    },
    [analytics, props.entity, props.link],
  );

  const renderQuickCopyLink = useMemo(() => {
    return (
      <Tooltip
        content={({ update }) => {
          updateTooltip.current = update;
          return tooltipContent;
        }}
        position="top"
      >
        <QuickCopyContainer onClick={copyLink}>
          {LinkCopied ? (
            <CheckCircleIcon
              label={intl.formatMessage(messages.copiedText)}
              LEGACY_size="small"
              color={token('color.text.success')}
            />
          ) : (
            <LinkIcon
              label={getCopyLinkIconText(props.entity, intl)}
              LEGACY_size="small"
              color={token('color.icon.subtle')}
            />
          )}
        </QuickCopyContainer>
      </Tooltip>
    );
  }, [LinkCopied, copyLink, intl, props.entity, tooltipContent]);

  return <>{renderQuickCopyLink}</>;
};
