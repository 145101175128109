import Loadable from 'react-loadable';

export { BaseHomePage } from './components/BaseHomePage';
export {
  HomeSidebarNavSkeleton,
  HomeSidebarNavigationWrapper as HomeSidebarNavigation,
} from './components/HomeSidebarNavigation';

export const NoWorkspaceHomeView = Loadable({
  loader: () => import(/* webpackChunkName: "NoWorkspaceHomeView" */ './components/NoWorkspaceHomeView'),
  loading: () => null,
});
