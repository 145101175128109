import { commitMutation, Environment, graphql, type MutationConfig } from 'relay-runtime';

import { markRiskAsResolvedInput, ResolveRiskMutation } from './__generated__/ResolveRiskMutation.graphql';

export const resolveRiskMutation = (
  environment: Environment,
  input: markRiskAsResolvedInput,
  events: Pick<MutationConfig<any>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<ResolveRiskMutation>(environment, {
    mutation: graphql`
      mutation ResolveRiskMutation($input: markRiskAsResolvedInput!) {
        markRiskAsResolved(input: $input) {
          risk {
            id
            summary
            description
            lastEditedBy {
              pii {
                name
                picture
              }
            }
            lastEditedDate
          }
        }
      }
    `,
    variables: { input },
    onCompleted: events.onCompleted,
    optimisticUpdater: store => {
      if (input.id) {
        const risk = store.get(input.id);
        risk?.setValue(Date.now(), 'resolvedDate');
      }
    },
    onError: events.onError,
  });
};
