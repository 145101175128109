/**
 * @generated SignedSource<<dbf53e666dccdaf753272ed4d58c9f3b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type ProjectSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "STATUS_ASC" | "STATUS_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type ExportProjectCsvQuery$variables = {
  after?: string | null;
  directoryViewUuid?: string | null;
  first?: number | null;
  sort?: ReadonlyArray<ProjectSortEnum | null> | null;
  tql?: string | null;
  workspaceUuid?: string | null;
};
export type ExportProjectCsvQuery$data = {
  readonly projectTql: {
    readonly count: number;
  } | null;
};
export type ExportProjectCsvQuery = {
  response: ExportProjectCsvQuery$data;
  variables: ExportProjectCsvQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewUuid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tql"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v6 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "directoryViewUuid",
        "variableName": "directoryViewUuid"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "tql"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      },
      {
        "kind": "Variable",
        "name": "workspaceUuid",
        "variableName": "workspaceUuid"
      }
    ],
    "concreteType": "ProjectConnection",
    "kind": "LinkedField",
    "name": "projectTql",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "count"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ExportProjectCsvQuery",
    "selections": (v6/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExportProjectCsvQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "d2499b4f3241bb37f4a06f0fd1f3905d",
    "id": null,
    "metadata": {},
    "name": "ExportProjectCsvQuery",
    "operationKind": "query",
    "text": "query ExportProjectCsvQuery(\n  $tql: String\n  $directoryViewUuid: UUID\n  $workspaceUuid: UUID\n  $after: String\n  $first: Int\n  $sort: [ProjectSortEnum]\n) {\n  projectTql(first: $first, after: $after, q: $tql, directoryViewUuid: $directoryViewUuid, workspaceUuid: $workspaceUuid, sort: $sort) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa54971665d11f9ffe2b7171af75cae0";

export default node;
