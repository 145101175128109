import { ResourceStoreContext, RouterContext, RouterDataContext } from 'react-resource-router';

import { GOAL_DIRECTORY_PAGE, PROJECT_DIRECTORY_PAGE, TAGS_DIRECTORY } from '@townsquare/config/routes';
import { RelayResourceConfig, createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import ExplainerQuery, { TqlExplainerQuery, TqlEntity } from '../components/__generated__/TqlExplainerQuery.graphql';

const getEntity = (routeName: string): TqlEntity => {
  switch (routeName) {
    case PROJECT_DIRECTORY_PAGE:
      return 'PROJECT';
    case GOAL_DIRECTORY_PAGE:
      return 'GOAL';
    case TAGS_DIRECTORY:
      return 'TAG';
    default:
      throw new Error('Invalid route name');
  }
};

export const getTqlExplainerResourceQuery = (
  routerContext: RouterContext | RouterDataContext,
  resourceContext: ResourceStoreContext,
): RelayResourceConfig<TqlExplainerQuery> => {
  const { workspace } = resourceContext as ResourceContext;
  const entity = getEntity(routerContext.route.name);
  const { tql, viewUuid } = routerContext.query;
  const hasTqlOrViewUuid = !!tql || !!viewUuid;

  return {
    query: ExplainerQuery,
    variables: {
      entity,
      tql,
      directoryViewUuid: viewUuid,
      workspaceUuid: workspace?.UUID,
      hasTqlOrViewUuid,
    },
    options: {
      fetchPolicy: 'store-and-network',
    },
  };
};

export const tqlExplainerResource = createRelayResource<TqlExplainerQuery>({
  getQuery: getTqlExplainerResourceQuery,
});
