import React from 'react';
import { useIntl } from 'react-intl-next';

import { Context } from '@townsquare/context-identifier';
import { DescriptionEditor } from '@townsquare/description-editor';
export interface LearningDescriptionEditorProps {
  name: string;
  canEdit: boolean | undefined;
  editorContext: Context;
  onSave: (adf: string) => void;
  onCancel?: () => void;
  placeholder?: string;
  value?: string | null | undefined;
  isEditing?: boolean;
}

export const LearningDescriptionEditor = (props: LearningDescriptionEditorProps) => {
  const intl = useIntl();
  const { name, placeholder, value, canEdit, onSave, onCancel, editorContext, isEditing } = props;
  return (
    <DescriptionEditor
      name={name}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: 'townsquare.packages.learnings.learning-description-placeholder',
          defaultMessage: 'Use this space to describe your learning in more detail.',
          description: 'Placeholder for the learning description input',
        })
      }
      value={value ?? ''}
      canEdit={canEdit}
      onSave={onSave}
      onCancel={() => onCancel && onCancel()}
      context={editorContext}
      renderMode="normal"
      isEditing={isEditing ?? undefined}
      autoFocus={false}
    />
  );
};

interface CreateLearningDescriptionEditorProps {
  editorContext: Context;
  onClose: (arg: boolean) => void;
  canEditLearningsPage: boolean | undefined;
  onSave: LearningDescriptionEditorProps['onSave'];
  placeholder?: LearningDescriptionEditorProps['placeholder'];
  title: string;
}

export const CreateLearningDescriptionEditor = (props: CreateLearningDescriptionEditorProps) => {
  return (
    <LearningDescriptionEditor
      name="new-learnings-editor"
      canEdit={props.canEditLearningsPage}
      onSave={props.onSave}
      onCancel={() => {
        props.onClose(false);
      }}
      editorContext={props.editorContext}
      placeholder={props.placeholder}
      isEditing
    />
  );
};

interface EditLearningDescriptionEditorProps {
  description?: string | null | undefined;
  canEditLearningsPage: boolean | undefined;
  editorContext: Context;
  isEditing?: boolean;
  placeholder?: LearningDescriptionEditorProps['placeholder'];
  onSave: LearningDescriptionEditorProps['onSave'];
  onCancel: LearningDescriptionEditorProps['onCancel'];
}

export const EditLearningDescriptionEditor = (props: EditLearningDescriptionEditorProps) => {
  return (
    <LearningDescriptionEditor
      name="past-learnings-editor"
      value={props.description ?? ''}
      canEdit={props.canEditLearningsPage}
      onSave={props.onSave}
      onCancel={props.onCancel}
      isEditing={props.isEditing}
      placeholder={props.placeholder}
      editorContext={props.editorContext}
    />
  );
};
