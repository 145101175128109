import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { onSmallDesktop, onSmallDesktopDown, onTabletDown } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddPeopleWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: token('space.250'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    marginRight: '10px !important',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    button: {
      paddingLeft: token('space.100'),
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    span: {
      margin: 0,
    },
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onTabletDown('display: none;'),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarsImage = styled.img(
  {
    display: 'none',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktop('display: unset;'),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarsButtonWrapper = styled.div(
  {
    display: 'none',
    cursor: 'pointer',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    div: {
      display: 'flex',
    },
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown('display: flex;'),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddPeopleButtonStyled = styled(Button)(
  {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    backgroundColor: 'unset !important',
    ':hover': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      textDecoration: 'underline !important',
    },
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onSmallDesktopDown('display: none !important;'),
);
