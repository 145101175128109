import { PrimaryButton, PrimaryDropdownButton, useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { ButtonProps } from '@atlaskit/button';
import { CustomItem } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import React, { forwardRef, KeyboardEvent, MouseEvent, MutableRefObject, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useCreateProjectActions } from '@townsquare/create-project';
import { NavIconMarginFix } from '@townsquare/icons/margin-fix';
import { projectRoute } from '@townsquare/project-view/route';
import { projectDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { Link, matchRoute, useGeneratePath } from '@townsquare/router/primitives';

import { ProjectMenuWrapper as ProjectMenu } from './ProjectMenu';
import { IsHighlightedBar, ProjectButtonsContainer } from './styles';
import { onItemClickContextOptions } from './types';

export const ProjectButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const analytics = useAnalytics();
  const [routerState] = useRouter();
  const { openCreateProject } = useCreateProjectActions();
  const path = useGeneratePath(projectDirectoryRoute);

  const { isVisible: isHorizontalVisible, closeOverflowMenu } = useOverflowStatus();

  const onClose = () => {
    setIsOpen(false);
  };

  const onClick = () => {
    void analytics.ui('projectsNavButton', 'clicked');
    onClose();
  };

  const createOnDropdownClick =
    (preventBubbling = false) =>
    (e: MouseEvent) => {
      if (preventBubbling) {
        e.stopPropagation();
        e.preventDefault();
      }

      void analytics.ui('projectsNavDropdown', 'clicked');
      setIsOpen(!isOpen);
    };
  const onDropdownClick = createOnDropdownClick();
  const onDropdownClickNoBubbling = createOnDropdownClick(true);

  const onItemClick = (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => {
    void analytics.ui('projectsNavMenuItem', 'clicked', {
      ctx,
    });
    if (!e?.metaKey) {
      setIsOpen(false);
      closeOverflowMenu();
    }
    if (ctx === 'openProjectModal') {
      openCreateProject({ redirectOnSave: true });
    }
  };

  const match = matchRoute([projectDirectoryRoute, projectRoute], routerState.location.pathname, undefined);
  const isProjectsPath = Boolean(match);

  const RouterLink = forwardRef<HTMLAnchorElement | null, ButtonProps>(function Component({ href = '', ...rest }, ref) {
    // @ts-expect-error - ref is not a public prop
    return <Link {...rest} key={href.substr(1)} prefetch="hover" to={projectDirectoryRoute} ref={ref} />;
  });

  return (
    <>
      <Popup
        placement="bottom-start"
        offset={[0, isHorizontalVisible ? -8 : 0]}
        content={() => <ProjectMenu onItemClick={onItemClick} shortView={!isHorizontalVisible} />}
        isOpen={isOpen}
        onClose={onClose}
        trigger={({ ref, ...otherTriggerProps }) => {
          return isHorizontalVisible ? (
            <ProjectButtonsContainer
              innerRef={ref as MutableRefObject<HTMLDivElement>}
              isOpen={isOpen}
              isProjectsPath={isProjectsPath}
            >
              <PrimaryButton
                href={path}
                onClick={onClick}
                component={RouterLink}
                id="projects-button"
                testId="projects-button"
              >
                <FormattedMessage
                  id="townsquare.navigation-projects.project-button.primary-button"
                  description="Projects navigation primary button"
                  defaultMessage="Projects"
                />
              </PrimaryButton>
              <NavIconMarginFix>
                <PrimaryDropdownButton
                  {...otherTriggerProps}
                  id="projects-dropdown-button"
                  testId="projects-dropdown-button"
                  onClick={onDropdownClick}
                />
              </NavIconMarginFix>
              {isProjectsPath && <IsHighlightedBar />}
            </ProjectButtonsContainer>
          ) : (
            <CustomItem
              component={RouterLink}
              href={path}
              onClick={() => closeOverflowMenu()}
              iconAfter={
                <NavIconMarginFix>
                  <PrimaryDropdownButton
                    {...otherTriggerProps}
                    id="projects-dropdown-button"
                    testId="projects-dropdown-button"
                    onClick={onDropdownClickNoBubbling}
                    ref={ref}
                  />
                </NavIconMarginFix>
              }
              // eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
              cssFn={() => {
                return {
                  padding: `${token('space.050')} ${token('space.200')}`,
                };
              }}
            >
              <FormattedMessage
                id="townsquare.navigation-projects.project-button.content"
                description="Projects navigation primary button"
                defaultMessage="Projects"
              />
            </CustomItem>
          );
        }}
        testId="projects-menu"
      />
    </>
  );
};
