/**
 * @generated SignedSource<<9b976d3cebfe941cf00a870cdaa6f1b8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type OnboardingItemKey = "ADD_PEOPLE_BANNER" | "CLASSIC_TO_ATLASSIAN_HOME_MODAL" | "DISCOVER_INTEGRATIONS" | "EXPLORE_DIRECTORY_BANNER" | "GLOBAL_HOME" | "GOALS_ABOUT_TAB_BANNER" | "GOALS_DIRECTORY" | "GOALS_DIRECTORY_BANNER" | "GOALS_METRICS_POPUP" | "GOALS_UPDATES_BANNER" | "GOALS_UPDATES_TAB_BANNER" | "GOAL_LEARNINGS_BANNER" | "LINKED_GOALS_IN_JIRA_BANNER" | "NAV_REFRESH_OPT_OUT" | "NO_WORKSPACE_BENEFITS_DIALOG" | "PASTE_WORK_TRACKING_JIRA_LINK_BANNER" | "PRODUCT_OVERVIEW" | "PROJECT_ABOUT_TAB_BANNER" | "PROJECT_DIRECTORY" | "PROJECT_DIRECTORY_BANNER" | "PROJECT_LEARNINGS_BANNER" | "PROJECT_SPOTLIGHT" | "PROJECT_UPDATES_TAB_BANNER" | "PROJECT_UPDATE_BANNER" | "QUESTIONS_FEED_BANNER" | "READING_MODE_FEEDBACK_COLLECTION" | "STAFF_DIRECTORY_UPLOAD_HIGH_RES_PIC" | "SYNC_WITH_JIRA_BANNER" | "TEAMS_DIRECTORY" | "TOPICS_DIRECTORY" | "WRITING_MODE_FEEDBACK_COLLECTION";
export type OnboardingPopup_onboardingQuery$variables = {
  onboardingKey: OnboardingItemKey;
};
export type OnboardingPopup_onboardingQuery$data = {
  readonly currentUser: {
    readonly onboarding: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly key: OnboardingItemKey | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type OnboardingPopup_onboardingQuery = {
  response: OnboardingPopup_onboardingQuery$data;
  variables: OnboardingPopup_onboardingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onboardingKey"
  }
],
v1 = [
  {
    "fields": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "keys.0",
            "variableName": "onboardingKey"
          }
        ],
        "kind": "ListValue",
        "name": "keys"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
},
v3 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "OnboardingPopup_onboardingQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "onboarding",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardingPopup_onboardingQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "onboarding",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              },
              (v3/*: any*/)
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b80535af6ddf4c2af78c3618411b676d",
    "id": null,
    "metadata": {},
    "name": "OnboardingPopup_onboardingQuery",
    "operationKind": "query",
    "text": "query OnboardingPopup_onboardingQuery(\n  $onboardingKey: OnboardingItemKey!\n) {\n  currentUser {\n    onboarding(first: 1, filter: {keys: [$onboardingKey]}) {\n      edges {\n        node {\n          key\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "31549e24ef582c0f6cd19ca220a54d46";

export default node;
