import { token } from '@atlaskit/tokens';
import styled, { css, InterpolationValue } from 'styled-components';

import { type AvatarPlaceholderColor } from '@townsquare/theme';

export interface UserImageProps {
  height: string;
  width: string;
  hideBackground: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const UserImage = styled.img<UserImageProps>(props => ({
  objectFit: 'cover',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundColor: props.hideBackground ? 'unset' : token('color.background.neutral'),
  borderRadius: '5px',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: props.height,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: props.width,
}));

export interface AvatarPlaceholderProps {
  placeholderColor: AvatarPlaceholderColor;
  height: string;
  width: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const AvatarPlaceholder = styled.div<AvatarPlaceholderProps>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundColor: props.placeholderColor.background,
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'flex-end',
  svg: {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    fill: props.placeholderColor.icon,
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: props.height,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: props.width,
}));

export const stylesForNestedProfilePicture = (style: string | InterpolationValue[]) =>
  // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- ignoring due to usage of injected components (eg. `AvatarPlaceholder`)
  css`
    ${AvatarPlaceholder}, ${UserImage} {
      ${style}
    }
  `;
