import { token } from '@atlaskit/tokens';
import { css } from 'styled-components';

import { onMobile } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Ignored via go/DSP-18766
export const SEARCH_CARD_STYLE = css`
  box-sizing: border-box;
  padding: ${token('space.250')};

  border: 1px solid ${token('color.border')};
  border-radius: 5px;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: ${token('elevation.shadow.overlay')};
    text-decoration: none;

    ${onMobile(
      css({
        boxShadow: 'none',
      }),
    )}
  }
`;
