import React from 'react';
import Loadable from 'react-loadable';
import { useResource } from 'react-resource-router';

import { rovoEntitlementResource } from '@townsquare/rovo/entitlement';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import { useRightSidebarActions } from '../RightSidebar';

import { RovoChatSidebarProps } from './types';

const LazyChatButton = Loadable({
  loader: () => import(/* webpackChunkName: "RovoChatButton" */ './RovoChatButton'),
  loading: () => null,
});

export const RovoChatButton = () => {
  const { data, loading } = useResource(rovoEntitlementResource);

  if (!isGateEnabled('rovo_chat_in_atlas_sidebar')) {
    return null;
  }

  if (!data?.isRovoEnabled || loading) {
    return null;
  }

  return <LazyChatButton />;
};

const LazyChatSidebar = Loadable({
  loader: () => import(/* webpackChunkName: "RovoChatSidebar" */ './RovoChatSidebar'),
  loading: () => null,
});

export const RovoChatSidebar = (props: RovoChatSidebarProps) => {
  const { data, loading } = useResource(rovoEntitlementResource);
  const { closePanel } = useRightSidebarActions();

  if (!isGateEnabled('rovo_chat_in_atlas_sidebar')) {
    return null;
  }

  if (data?.isRovoEnabled === false) {
    closePanel();
    return null;
  }

  if (loading || !data?.isRovoEnabled) {
    return null;
  }

  return <LazyChatSidebar {...props} />;
};
