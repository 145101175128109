/**
 * @generated SignedSource<<0a22b7489ee633b47ced673584c89525>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type DirectoryTeamSortEnum = "NAME_ASC" | "NAME_DESC";
export type SearchPreviewTeamSearchQuery$variables = {
  after?: string | null;
  cloudId: string;
  first?: number | null;
  organisationId: string;
  query: string;
  sort?: ReadonlyArray<DirectoryTeamSortEnum | null> | null;
};
export type SearchPreviewTeamSearchQuery$data = {
  readonly teamsTql: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly displayName: string | null;
        readonly id: string | null;
        readonly members: {
          readonly count: number;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly aaid: string | null;
              readonly pii: {
                readonly name: string | null;
                readonly picture: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};
export type SearchPreviewTeamSearchQuery = {
  response: SearchPreviewTeamSearchQuery$data;
  variables: SearchPreviewTeamSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v6 = {
  "kind": "ScalarField",
  "name": "count"
},
v7 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "organisationId",
        "variableName": "organisationId"
      },
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "query"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "TeamSearchResponseConnection",
    "kind": "LinkedField",
    "name": "teamsTql",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "concreteType": "TeamSearchResponseEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "concreteType": "TeamSearchResponse",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "kind": "ScalarField",
                "name": "displayName"
              },
              {
                "kind": "ScalarField",
                "name": "description"
              },
              {
                "concreteType": "TeamMemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "concreteType": "TeamMemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "TeamMember",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "aaid"
                          },
                          {
                            "concreteType": "UserPII",
                            "kind": "LinkedField",
                            "name": "pii",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "picture"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "startCursor"
          },
          {
            "kind": "ScalarField",
            "name": "endCursor"
          },
          {
            "kind": "ScalarField",
            "name": "hasNextPage"
          },
          {
            "kind": "ScalarField",
            "name": "hasPreviousPage"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "SearchPreviewTeamSearchQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchPreviewTeamSearchQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "9e4ef3e3e995b452ed178f783fbe5db4",
    "id": null,
    "metadata": {},
    "name": "SearchPreviewTeamSearchQuery",
    "operationKind": "query",
    "text": "query SearchPreviewTeamSearchQuery(\n  $query: String!\n  $first: Int\n  $sort: [DirectoryTeamSortEnum]\n  $organisationId: String!\n  $cloudId: String!\n  $after: String\n) {\n  teamsTql(q: $query, first: $first, sort: $sort, after: $after, organisationId: $organisationId, cloudId: $cloudId) {\n    count\n    edges {\n      node {\n        id\n        displayName\n        description\n        members {\n          count\n          edges {\n            node {\n              aaid\n              pii {\n                name\n                picture\n              }\n            }\n          }\n        }\n      }\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fcc43d31d139e02345d41b10dc9acc1e";

export default node;
