import { articleId, HistoryItem } from '@atlaskit/help';
import React, { createContext, useCallback, useState } from 'react';

import { noop } from '@townsquare/noop';

type NavigationData = {
  articleId: articleId;
  history: HistoryItem[];
};
export interface HelpInterface {
  isOpen: boolean;
  navigationData?: NavigationData;
  setIsOpen(value: boolean): void;
  setHelpArticle(articleId: articleId): void;
}

export const HelpContext = createContext<HelpInterface>({
  isOpen: false,
  navigationData: undefined,
  setIsOpen: noop,
  setHelpArticle: noop,
});

export const HelpContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [navigationData, setNavigationData] = useState<NavigationData>();

  const handleSetIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);
    if (!value) {
      setNavigationData(undefined);
    }
  }, []);

  const setHelpArticle = useCallback((articleId: articleId) => {
    setNavigationData({ articleId, history: [] });
  }, []);

  return (
    <HelpContext.Provider value={{ isOpen, navigationData, setIsOpen: handleSetIsOpen, setHelpArticle }}>
      {children}
    </HelpContext.Provider>
  );
};
