import { commitMutation, graphql, type Environment, type MutationConfig } from 'relay-runtime';

import { SaveOnboardingMutation, saveUserOnboardingInput } from './__generated__/SaveOnboardingMutation.graphql';

export const saveOnboarding = (
  environment: Environment,
  input: saveUserOnboardingInput,
  connectionId: [string],
  events?: Pick<MutationConfig<SaveOnboardingMutation>, 'onCompleted' | 'onError'>,
) =>
  commitMutation<SaveOnboardingMutation>(environment, {
    mutation: graphql`
      mutation SaveOnboardingMutation($input: saveUserOnboardingInput!, $connections: [ID!]!) {
        saveUserOnboarding(input: $input) {
          result @appendNode(connections: $connections, edgeTypeName: "OnboardingEdge") {
            key
            completionDate
          }
        }
      }
    `,
    variables: { input, connections: connectionId },
    onCompleted: events?.onCompleted,
    onError: events?.onError,
  });
