import { useCallback, useEffect } from 'react';
import { useQueryParam } from 'react-resource-router';

import { useQueryParamWithDefault } from '@townsquare/router/primitives';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { ScreenType } from '../components/ScreenType';
import { isPeopleAndTeamsSortType, PeopleAndTeamsSortType } from '../components/SortOptions';
import { getScreenFromStringOrDefault } from '../components/screens/ScreenType';

export const useScreenQueryParam = (defaultScreen: string | undefined) => {
  const [screenQueryParam, setScreenQueryParam] = useQueryParamWithDefault('screen', defaultScreen);

  const setScreen = useCallback(
    (screenType: ScreenType, updateType: 'push' | 'replace' = 'replace') => {
      const newScreen = ScreenType[screenType];
      if (newScreen !== screenQueryParam) {
        setScreenQueryParam(newScreen, updateType);
      }
    },
    [screenQueryParam, setScreenQueryParam],
  );

  // We always return a value, but we don't want to set the default value in the state so we can determine when to load from the query param.
  return [getScreenFromStringOrDefault(screenQueryParam), setScreen] as const;
};

export const useTqlQueryParam = (defaultTql?: string | undefined) => {
  return useQueryParamWithDefault('tql', defaultTql);
};

export const useCloudIdQueryParam = () => {
  const [cloudIdQueryParam, setCloudIdQueryParam] = useQueryParam('cloudId');
  const [workspace] = useWorkspaceStore();

  useEffect(() => {
    if (!cloudIdQueryParam) {
      // adds param to url if not present
      setCloudIdQueryParam(workspace.cloudId, 'replace');
    }
  }, [cloudIdQueryParam, setCloudIdQueryParam, workspace.cloudId]);

  return [cloudIdQueryParam, setCloudIdQueryParam] as const;
};

export const useSortQueryParam = (
  currentSort: PeopleAndTeamsSortType | undefined,
  setSort: (newSort: PeopleAndTeamsSortType | undefined) => void,
) => {
  const [sortQueryParam, setSortQueryParam] = useQueryParam('sort');

  useEffect(() => {
    if (!sortQueryParam && currentSort) {
      // adds param to url if not present
      setSortQueryParam(currentSort, 'replace');
    }

    if (sortQueryParam && sortQueryParam !== currentSort && isPeopleAndTeamsSortType(sortQueryParam)) {
      // Update state from sort query param if browser back/forward used
      setSort(sortQueryParam);
    }
  }, [currentSort, setSort, setSortQueryParam, sortQueryParam]);

  return [sortQueryParam, setSortQueryParam] as const;
};
