import { useEffect } from 'react';
import { getCLS, getFCP, getFID, getLCP, getTTFB, Metric } from 'web-vitals';

import { useAnalytics } from '@townsquare/analytics';

export const WebVitals = () => {
  const analytics = useAnalytics();

  useEffect(() => {
    const handleMetrics = (metric: Metric) => {
      void analytics.operational(`teamCentral${metric.name}`, 'measured', {
        [metric.name]: metric.value,
      });
    };

    getCLS(handleMetrics);

    getFCP(handleMetrics);

    getFID(handleMetrics);

    getLCP(handleMetrics);

    getTTFB(handleMetrics);
  }, [analytics]);

  return null;
};
