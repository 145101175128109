import { createRelayResource } from '@townsquare/relay-utils';

import FollowProjectActionQuery from './__generated__/FollowProjectActionQuery.graphql';
import { FollowProjectActionQuery as FollowProjectActionQueryType } from './__generated__/FollowProjectActionQuery.graphql';

export const followProjectResource = createRelayResource<FollowProjectActionQueryType>({
  getQuery: routerContext => {
    const { key } = routerContext?.match?.params || {};
    return {
      query: FollowProjectActionQuery,
      variables: {
        key: key || '',
      },
    };
  },
});
