import { DependencyList, useEffect, useRef } from 'react';

/**
 * Lets you pass an async function (fn that returns a promsie) into useEffect.
 * The one provided by React wouldn't let you do it.
 */
export function useAsyncEffect(effect: () => Promise<any>, deps?: DependencyList) {
  useEffect(() => {
    effect().catch(e => {
      console.warn('useAsyncEffect error', e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

/**
 * To emulate componentDidMount when using hooks + functional component.
 */
export function useOnMount(effect: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [
    /* This is intentionally left empty so it only runs once on mount */
  ]);
}

/**
 * To see why this exists or how it works
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
