import Loadable from 'react-loadable';

import { GOAL_PAGE, GOAL_PATH_PATTERN } from '@townsquare/config/routes';
import { createSiteRoute } from '@townsquare/router/create-route';

import { goalHighlightsTabResource } from './resources/GoalHighlightsTab';
import { goalViewResource } from './resources/GoalView';
import { goalViewAsideResource } from './resources/GoalViewAside';

export const goalViewRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "goal-view" */ './components/GoalView'),
    loading: () => null,
  }),
  aside: Loadable({
    loader: () => import(/* webpackChunkName: "goal-view-aside" */ './components/GoalViewAside'),
    loading: () => null,
  }),
  exact: true,
  name: GOAL_PAGE,
  path: GOAL_PATH_PATTERN,
  capabilities: ['goals_app:access'],
  resources: [goalViewResource, goalViewAsideResource, goalHighlightsTabResource],
  subproduct: 'townsquare-goals',
});
