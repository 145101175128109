import React from 'react';
import { FormattedMessage } from 'react-intl-next';

const formattedLoadingText = () => (
  <FormattedMessage
    id="townsquare.i18n.empty-state.loading"
    defaultMessage="Loading..."
    description="Loading state of the select picker"
  />
);

const formattedNoOptionsText = () => (
  <FormattedMessage
    id="townsquare.i18n.empty-state.no-options"
    defaultMessage="No options"
    description="Empty state of the select picker"
  />
);

export const formattedPickerEmptyState = {
  loadingMessage: formattedLoadingText,
  noOptionsMessage: formattedNoOptionsText,
};
