import Heading from '@atlaskit/heading';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { getProjectLearningsExamplesOptions, useExamplesModal } from '@townsquare/examples-modal';
import { LearningType, DecisionImage, ErrorWindowImage, LightbulbOrbitImage } from '@townsquare/learnings';

import { EmptyStateAddNewLearningButton } from './AddNewLearningButton';
import {
  EmptyStateButtonsWrapper,
  EmptyStateButtonWrapper,
  LearningsEmptyContainer,
  LearningsEmptyContent,
  LearningsEmptyImage,
  LearningsEmptyWrapper,
} from './styles';

type Props = {
  learningType: LearningType;
  onAddLearning: () => void;
  followerCount: number;
  tagFollowerCount?: number;
};

const emptyState: {
  title: ReactNode;
  content: ReactNode;
  image: string;
} = {
  title: null,
  content: null,
  image: '',
};

export const LearningsEmptyState = (props: Props) => {
  const { learningType } = props;
  const { openExamplesModal } = useExamplesModal();
  const intl = useIntl();

  // Open examples modal
  const onClick = () => {
    openExamplesModal(getProjectLearningsExamplesOptions(intl));
  };

  switch (learningType) {
    case 'DECISION':
      emptyState.title = (
        <FormattedMessage
          id="townsquare.web.learnings-empty-state.decision-title"
          description="Decision empty state title"
          defaultMessage="Communicate the big decisions"
        />
      );
      emptyState.content = (
        <FormattedMessage
          id="townsquare.web.learnings-empty-state.decision-content"
          description="Decision empty state content"
          defaultMessage="Record the big decisions for this project here to share them out with your latest update."
        />
      );
      emptyState.image = DecisionImage;
      break;
    case 'RISK':
      emptyState.title = (
        <FormattedMessage
          id="townsquare.web.learnings-empty-state.risk-title"
          description="Risk empty state title"
          defaultMessage="Capture known risks"
        />
      );
      emptyState.content = (
        <FormattedMessage
          id="townsquare.web.learnings-empty-state.risk-content"
          description="Risk empty state content"
          defaultMessage="Track risks and ensure that your stakeholders have no surprises if the worst happens during this project."
        />
      );
      emptyState.image = ErrorWindowImage;
      break;
    default:
      emptyState.title = (
        <FormattedMessage
          id="townsquare.web.learnings-empty-state.learning-title"
          description="Learning empty state title"
          defaultMessage="Great minds <s>think alike</s> share their knowledge"
          values={{
            s: (msg: any) => <s>{msg}</s>,
          }}
        />
      );
      emptyState.content = (
        <FormattedMessage
          id="townsquare.web.learnings-empty-state.learning-content"
          description="Learning empty state content"
          defaultMessage="Share what you've learned with your company to give others a head start when working on similar projects."
        />
      );
      emptyState.image = LightbulbOrbitImage;
  }

  return (
    <LearningsEmptyWrapper data-testid={`${learningType.toLowerCase()}s-empty-state`}>
      <LearningsEmptyContainer>
        <LearningsEmptyImage src={emptyState.image} />
        <LearningsEmptyContent>
          <Heading size="small" as="h3">
            {emptyState.title}
          </Heading>
          <p>{emptyState.content}</p>
          <EmptyStateButtonsWrapper>
            <EmptyStateButtonWrapper>
              <EmptyStateAddNewLearningButton learningType={learningType} onClick={() => props.onAddLearning()} />
            </EmptyStateButtonWrapper>
            {learningType === 'LEARNING' && (
              <a onClick={onClick}>
                <FormattedMessage
                  id="townsquare.web.learnings-empty-state.learning-examples"
                  description="Learning empty state examples"
                  defaultMessage="See examples"
                />
              </a>
            )}
          </EmptyStateButtonsWrapper>
        </LearningsEmptyContent>
      </LearningsEmptyContainer>
    </LearningsEmptyWrapper>
  );
};
