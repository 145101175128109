import React, { PropsWithChildren } from 'react';

import { ExpandablePanel } from '@townsquare/expandable-panel';
import { HighlightIcon } from '@townsquare/icons';

import { LearningListEditorWrapper, IconWrapper } from './styles';
import { LearningType } from './util';

interface HighlightPanelProps {
  isOpen?: boolean;
  isEditing?: boolean;
  isInvalid?: boolean;
  onToggle?: (open: boolean) => void;
  preserveOpenState?: boolean;
  learningType: LearningType;
  title: React.ReactNode;
}

export const LearningPanel = (props: PropsWithChildren<HighlightPanelProps>) => {
  const { learningType } = props;

  return (
    <ExpandablePanel
      padding="12px"
      icon={
        <IconWrapper>
          <HighlightIcon type={learningType} />
        </IconWrapper>
      }
      title={props.title}
      isOpen={props.isOpen}
      onToggle={open => props.onToggle?.(open)}
      hideControlOnOpen={!!props.isEditing}
    >
      <LearningListEditorWrapper data-testid={`${props.learningType.toLowerCase()}Panel`} isInvalid={props.isInvalid}>
        {props.children}
      </LearningListEditorWrapper>
    </ExpandablePanel>
  );
};
