import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MainColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActiveGoalsSkeletonWrapper = styled.div({
  marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const NoFilteredUpdatesContainer = styled.div<{ imageLoaded: boolean; centerAlign?: boolean }>(props => ({
  paddingTop: token('space.600'),
  paddingBottom: token('space.600'),
  backgroundColor: token('elevation.surface.sunken'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  display: props.imageLoaded ? 'flex' : 'none',
  flexDirection: 'column',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  alignItems: props.centerAlign ? 'center' : undefined,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarContainer = styled.div({
  marginTop: token('space.600'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyFilterImage = styled.img({
  width: '100%',
  height: '180px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExampleUpdatesInfo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  color: token('color.text.subtlest'),
  alignItems: 'center',
  marginBottom: token('space.500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoIconWrapper = styled.div({
  width: '16px',
  marginRight: token('space.050'),
  color: token('color.text.disabled'),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
