import { commitMutation, graphql, type Environment, type MutationConfig } from 'relay-runtime';

import { deleteLearningInput, DeleteLearningMutation } from './__generated__/DeleteLearningMutation.graphql';

export const deleteLearning = (
  environment: Environment,
  input: deleteLearningInput,
  connectionIds: string[],
  events: Pick<MutationConfig<DeleteLearningMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<DeleteLearningMutation>(environment, {
    mutation: graphql`
      mutation DeleteLearningMutation($input: deleteLearningInput!, $connections: [ID!]!) {
        deleteLearning(input: $input) {
          learning {
            id @deleteEdge(connections: $connections)
          }
        }
      }
    `,
    updater: store => {
      const learning = store.get(input.id);
      learning?.invalidateRecord();
    },
    variables: { input, connections: connectionIds },
    onCompleted: events.onCompleted,
    onError: events.onError,
  });
};
