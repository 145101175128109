import React, { useEffect, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { filterNull } from '@townsquare/filter-type';
import { useOnMount } from '@townsquare/hooks';

import { PersonSearchResultCard } from '../Cards/Search/Person';
import { EmptySearchState } from '../EmptySearchState/SearchEmptyState';
import { PaginationErrorState } from '../PaginationErrorState/PaginationErrorState';
import { ClearSearchQueryFn } from '../types';

import { ScrollingResultsScreen } from './ScrollingResultsScreen';
import { SearchPeoplePaginationQuery } from './__generated__/SearchPeoplePaginationQuery.graphql';
import { SearchPeopleScreen_data$key } from './__generated__/SearchPeopleScreen_data.graphql';

export interface SearchPeopleScreenProps {
  tql?: string;
  data: SearchPeopleScreen_data$key;
  clearSearchFn: ClearSearchQueryFn;
}

const PAGE_SIZE = 20;

export const SearchPeopleScreen = (props: SearchPeopleScreenProps) => {
  const hasMounted = useRef(false);
  const analytics = useAnalytics();
  useOnMount(() => {
    hasMounted.current = true;
    void analytics.ui('staffDirectorySearchPeopleScreen', 'viewed');
  });

  // TODO: TC-4409 add sorting to SearchPeopleScreen/ScrollingResultsScreen when sorting people does what we expect
  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    SearchPeoplePaginationQuery,
    SearchPeopleScreen_data$key
  >(
    graphql`
      fragment SearchPeopleScreen_data on Query
      @refetchable(queryName: "SearchPeoplePaginationQuery")
      @argumentDefinitions(
        query: { type: "String!" }
        first: { type: "Int", defaultValue: 20 }
        after: { type: "String" }
        organisationId: { type: "String!" }
        cloudId: { type: "String!" }
        isSearchPeopleScreen: { type: "Boolean!" }
      ) {
        peopleTql(q: $query, organisationId: $organisationId, cloudId: $cloudId, first: $first, after: $after)
          @include(if: $isSearchPeopleScreen)
          @connection(key: "SearchPeopleScreen_peopleTql") {
          edges {
            node {
              account_id
              ...PersonSearchResultCard
            }
          }
        }
      }
    `,
    props.data,
  );

  useEffect(() => {
    if (!hasMounted.current) {
      return;
    }
    refetch({ query: props.tql ?? '' });
  }, [props.tql, refetch]);

  const peopleCards =
    data.peopleTql?.edges
      ?.map(edge => {
        if (!edge || !edge.node) {
          return null;
        }
        return <PersonSearchResultCard key={`search-people-result-${edge.node.account_id}`} data={edge.node} />;
      })
      .filter(filterNull) ?? [];

  return peopleCards.length ? (
    <ScrollingResultsScreen
      resultCount={data.peopleTql?.edges?.length ?? 0}
      resultName="people"
      resultList={peopleCards}
      renderFallback={fallbackProps => (
        <PaginationErrorState
          error={fallbackProps.error}
          resetErrorBoundary={fallbackProps.resetErrorBoundary}
          resultType="people"
        />
      )}
      isLoading={isLoadingNext}
      loadMore={loadNext}
      hasMore={hasNext}
      pageSize={PAGE_SIZE}
    />
  ) : (
    <EmptySearchState resultType="people" clearSearchQuery={props.clearSearchFn} />
  );
};
