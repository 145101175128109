import { createStore } from 'react-sweet-state';

import { timezoneHolder } from '@townsquare/date-utils';
import { identityClient, UserResponse } from '@townsquare/identity-client';

import { User } from './types';

const toCurrentUser = (response: UserResponse) => ({
  name: response.name,
  accountId: response.account_id,
  email: response.email,
  locale: response.locale,
  picture: response.picture,
  timeZone: response.zoneinfo,
  jobTitle: response.extended_profile?.job_title,
});

const unresolvedUser: User = {
  accountId: 'failed-to-fetch',
  locale: 'en',
};

export const userStore = createStore({
  initialState: {} as User,
  actions: {
    initUser:
      (user: UserResponse) =>
      async ({ setState }) => {
        const newState = toCurrentUser(user);
        timezoneHolder.setUserTimezone(user.zoneinfo);
        setState(newState);
        return newState;
      },
    initCurrentUser:
      () =>
      async ({ setState }) => {
        try {
          const user = await identityClient.getUser();
          const newState = {
            ...toCurrentUser(user),
          };
          timezoneHolder.setUserTimezone(user.zoneinfo);
          setState(newState);
          return newState;
        } catch (error) {
          console.error(`Failed to initCurrentUser in userStore due to: ${error}`);
          return unresolvedUser;
        }
      },
    setUserLocale:
      locale =>
      async ({ setState }) => {
        setState({ locale });
      },
  },
  name: 'user-store',
});
