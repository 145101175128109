import { RemoteComponent } from '@atlassian/remote-module-loader/remote-component';
import AtlassianSwitcher from '@atlassian/switcher';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { useAtlasKitAnalytics } from '@townsquare/analytics';
import { useRovoWorkspace } from '@townsquare/collab-context';
import { getConfig } from '@townsquare/config';
import { PRODUCT_IDENTIFIER } from '@townsquare/config/constants';
import { Placement } from '@townsquare/post-office';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import { remoteSwitcherPlugin } from '../../appSwitcherPlugin';

import { ContentContainer } from './styles';

const config = getConfig();

type SwitcherComponentProps = React.ComponentProps<typeof AtlassianSwitcher>;

export const { fireEventOnce } = (() => {
  let hasFired = false;

  return {
    fireEventOnce: (fireFunction: () => void) => {
      if (!hasFired) {
        fireFunction();
        hasFired = true;
      }
    },
  };
})();

export const useDiscoverySDKCohort = (intl: { locale: string }) => {
  const { locale } = intl;
  const isEnglishLocale = locale.startsWith('en');
  if (isEnglishLocale) {
    return {
      isEnabled: true,
      locale,
      ffEnableOverFiftyUsers: true,
    };
  } else {
    return {
      isEnabled: false,
      locale,
      ffAllLocales: false,
    };
  }
};

export const AppSwitcherContent = () => {
  const cloudId = useRovoWorkspace();

  const { locale } = useIntl();
  const { sendUIEvent } = useAtlasKitAnalytics();
  fireEventOnce(() =>
    sendUIEvent({
      action: 'viewed',
      actionSubject: 'section',
      actionSubjectId: 'switcherDiscoveryExposure',
      source: 'atlassianSwitcher',
      attributes: {
        locale,
      },
    }),
  );

  const isPostOfficeEnabled = useFeatureGate('post_office_in_atlas_switcher');

  const switcherProps = useMemo<SwitcherComponentProps>(() => {
    return {
      appearance: 'standalone',
      product: PRODUCT_IDENTIFIER,
      disableCustomLinks: true,
      discoverySection: isPostOfficeEnabled ? <Placement placementId="app-switcher-discovery-section" /> : undefined,
      adminUrl: `${config.adminHubUrl}`,
      cloudId: cloudId ?? undefined,
    };
  }, [cloudId, isPostOfficeEnabled]);

  return (
    <ContentContainer>
      <RemoteComponent<SwitcherComponentProps>
        remotePlugin={remoteSwitcherPlugin}
        analyticsChannel="navigation"
        ErrorFallback={<AtlassianSwitcher {...switcherProps} />}
        // switcher props
        {...switcherProps}
      />
    </ContentContainer>
  );
};
