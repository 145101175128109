/**
 * @generated SignedSource<<66e66ad90fbc9b7a2c55b376c5af798c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type GoalScoringMode = "OKR" | "SIMPLE";
export type WorkspaceType = "GLOBAL_EXPERIENCE" | "PRODUCT";
import type { FragmentRefs } from "relay-runtime";
export type storeHomeWorkspace$data = {
  readonly activationId: string | null;
  readonly cloudId: string;
  readonly cloudName: string | null;
  readonly goalScoringMode: GoalScoringMode;
  readonly id: string;
  readonly keyPrefix: string;
  readonly name: string;
  readonly organisationId: string;
  readonly timezone: string;
  readonly type: WorkspaceType;
  readonly userIsOrgAdmin: boolean;
  readonly userWorkspacePermissions: {
    readonly admin: boolean;
  } | null;
  readonly uuid: string;
  readonly " $fragmentType": "storeHomeWorkspace";
};
export type storeHomeWorkspace$key = {
  readonly " $data"?: storeHomeWorkspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"storeHomeWorkspace">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "storeHomeWorkspace"
};

(node as any).hash = "f76ca7681f4d21dd752a6d74aafd2781";

export default node;
