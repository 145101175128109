/**
 * @generated SignedSource<<3aad5b0c430629df244c30dcedabf534>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type ProductOverviewQuery$variables = Record<PropertyKey, never>;
export type ProductOverviewQuery$data = {
  readonly currentUser: {
    readonly onboarding: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "OnboardingEdge";
      } | null> | null;
    } | null;
  } | null;
};
export type ProductOverviewQuery = {
  response: ProductOverviewQuery$data;
  variables: ProductOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "keys": [
      "PRODUCT_OVERVIEW"
    ]
  }
},
v1 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v2 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v3 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v5 = [
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "ProductOverviewQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": "onboarding",
            "args": [
              (v0/*: any*/)
            ],
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "__OnboardingList__onboarding_connection",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  }
                ]
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": "__OnboardingList__onboarding_connection(filter:{\"keys\":[\"PRODUCT_OVERVIEW\"]})"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProductOverviewQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "onboarding",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": "onboarding(filter:{\"keys\":[\"PRODUCT_OVERVIEW\"]},first:1)"
          },
          {
            "args": (v5/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "OnboardingList__onboarding",
            "kind": "LinkedHandle",
            "name": "onboarding"
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "8ae7f43271ffa24ab3f97659dff95525",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "currentUser",
            "onboarding"
          ]
        }
      ]
    },
    "name": "ProductOverviewQuery",
    "operationKind": "query",
    "text": "query ProductOverviewQuery {\n  currentUser {\n    onboarding(first: 1, filter: {keys: [PRODUCT_OVERVIEW]}) {\n      edges {\n        __typename\n        cursor\n        node {\n          __typename\n          id\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "319368ed5b0edc47d91c199c06460896";

export default node;
