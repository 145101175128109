import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const TeamButtonsContainer = styled.div<{ isOpen: boolean; isSelected: boolean }>(props => ({
  display: 'flex',
  gap: token('space.025'),
  position: 'relative',
  'a, button': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      props.isSelected || props.isOpen ? token('color.text.selected') : token('color.text.subtle')
    } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
  },
  'a:hover': {
    textDecoration: 'none',
  },
  '&:hover a, &:hover button': {
    backgroundColor:
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      props.isSelected || props.isOpen ? token('color.background.selected') : token('color.background.neutral'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      props.isSelected || props.isOpen ? token('color.text.selected') : token('color.text.subtle')
    } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
    '&:hover': {
      backgroundColor:
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        props.isSelected || props.isOpen
          ? token('color.background.selected.hovered')
          : token('color.background.neutral.hovered'),
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      color: `${
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        props.isSelected || props.isOpen ? token('color.text.selected') : token('color.text.subtle')
      } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
    },
    '&:active': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      backgroundColor: `${
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        props.isSelected || props.isOpen
          ? token('color.background.selected.pressed')
          : token('color.background.neutral.pressed')
      } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      color: `${
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        props.isSelected || props.isOpen ? token('color.text.selected') : token('color.text.subtle')
      } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
    },
  },
  '#teams-button': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '#teams-dropdown-button': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: `0 ${token('space.075')}`,
  },
  '#teams-dropdown-button span': {
    opacity: 1,
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IsHighlightedBar = styled.div({
  position: 'absolute',
  bottom: 0,
  left: token('space.050'),
  right: token('space.050'),
  height: '3px',
  backgroundColor: token('color.border.selected'),
  borderTopLeftRadius: '1px',
  borderTopRightRadius: '1px',
});
