import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { IconStyle, StyledTeamIconProps, TeamIconSize } from '../types';

const STYLES: { [key in TeamIconSize]: IconStyle } = {
  xsmall: {
    sideLength: '16px',
    textStyles: token('font.heading.xxsmall'),
  },
  small: {
    sideLength: '24px',
    textStyles: token('font.heading.xxsmall'),
  },
  medium: {
    sideLength: '32px',
    textStyles: token('font.heading.xsmall'),
  },
  large: {
    sideLength: '60px',
    textStyles: token('font.heading.large'),
  },
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const StyledTeamIcon = styled.div<StyledTeamIconProps>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: STYLES[props.size].sideLength,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: STYLES[props.size].sideLength,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundColor: props.placeholderColor.background,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: props.shape === 'circle' ? '50%' : '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  font: STYLES[props.size].textStyles,
  marginTop: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: props.placeholderColor.icon,
}));
