import { useCallback } from 'react';
import { useQueryParam } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { localStorageFacade } from '@townsquare/facade';
import { useUserStore } from '@townsquare/user-store';

import {
  CONTRIBUTING_CONTEXT_QP,
  GOALS_CONTEXT_QP,
  PENDING_AND_PAUSED_CONTEXT_QP,
  WRITING_MODE_LOCAL_STORAGE_KEY,
} from '../constants';

// Can only force goals to appear at the moment. This occurs when opening writing mode from the goal updates page
// Could revisit this when launching writing mode with pending / paused projects + goals
export type WritingModeSetting = 'on' | 'off' | 'force';
export type WritingModeSettings = {
  showContributing: WritingModeSetting;
  showPendingAndPaused: WritingModeSetting;
  showGoals: WritingModeSetting;
};
export type SetWritingModeSettings = {
  toggleContributing: () => void;
  togglePendingAndPaused: () => void;
  toggleGoals: () => void;
};

const getWritingModeSettingFromQueryParam = (queryParam: string | undefined): WritingModeSetting => {
  switch (queryParam) {
    case 'on':
    case 'true': // Backwards compatability for goals
      return 'on';
    case 'force':
      return 'force';
    default:
      return 'off';
  }
};

const getNextToggleState = (currentState: WritingModeSetting): WritingModeSetting => {
  switch (currentState) {
    case 'on':
    case 'force':
      return 'off';
    default:
      return 'on';
  }
};

export const useWritingModeSettings = (): [WritingModeSettings, SetWritingModeSettings] => {
  const [{ accountId }] = useUserStore();
  const analytics = useAnalytics();

  const [showGoalsQp, setShowGoalsQp] = useQueryParam(GOALS_CONTEXT_QP);
  const showGoals = getWritingModeSettingFromQueryParam(showGoalsQp);

  const [showContributingQp, setShowContributingQp] = useQueryParam(CONTRIBUTING_CONTEXT_QP);
  const showContributing = getWritingModeSettingFromQueryParam(showContributingQp);

  const [showPendingAndPausedQp, setShowPendingAndPausedQp] = useQueryParam(PENDING_AND_PAUSED_CONTEXT_QP);
  const showPendingAndPaused = getWritingModeSettingFromQueryParam(showPendingAndPausedQp);

  const saveToLocalStorage = useCallback(
    (newSettings: Partial<WritingModeSettings>) => {
      saveWritingModeSettingsToLocalStorage(accountId, {
        showGoals,
        showContributing,
        showPendingAndPaused,
        ...newSettings,
      });
    },
    [accountId, showContributing, showGoals, showPendingAndPaused],
  );

  const toggleGoals = useCallback(() => {
    const nextState = getNextToggleState(showGoals);
    setShowGoalsQp(nextState === 'off' ? undefined : nextState);
    saveToLocalStorage({ showGoals: nextState });

    analytics.ui('writingModeSettings', 'updated', { setting: 'showGoals', oldState: showGoals, nextState });
  }, [analytics, saveToLocalStorage, setShowGoalsQp, showGoals]);

  const toggleContributing = useCallback(() => {
    const nextState = getNextToggleState(showContributing);
    setShowContributingQp(nextState === 'off' ? undefined : nextState);
    saveToLocalStorage({ showContributing: nextState });

    analytics.ui('writingModeSettings', 'updated', {
      setting: 'showContributing',
      oldState: showContributing,
      nextState,
    });
  }, [analytics, saveToLocalStorage, setShowContributingQp, showContributing]);

  const togglePendingAndPaused = useCallback(() => {
    const nextState = getNextToggleState(showPendingAndPaused);
    setShowPendingAndPausedQp(nextState === 'off' ? undefined : nextState);
    saveToLocalStorage({ showPendingAndPaused: nextState });

    analytics.ui('writingModeSettings', 'updated', {
      setting: 'showPendingAndPaused',
      oldState: showPendingAndPaused,
      nextState,
    });
  }, [analytics, saveToLocalStorage, setShowPendingAndPausedQp, showPendingAndPaused]);

  return [
    { showGoals, showContributing, showPendingAndPaused },
    { toggleGoals, toggleContributing, togglePendingAndPaused },
  ];
};

export const defaultWritingModeSettings: WritingModeSettings = {
  showContributing: 'off',
  showPendingAndPaused: 'off',
  showGoals: 'off',
};

const buildLocalStorageKey = (accountId: string) => `${WRITING_MODE_LOCAL_STORAGE_KEY}-${accountId}`;

export const getWritingModeSettingsFromLocalStorage = (accountId: string): WritingModeSettings => {
  const settingsKey = buildLocalStorageKey(accountId);
  const settingsString = localStorageFacade.getItem(settingsKey);
  if (!settingsString) {
    return defaultWritingModeSettings;
  }
  try {
    return JSON.parse(settingsString);
  } catch {
    return defaultWritingModeSettings;
  }
};

const saveWritingModeSettingsToLocalStorage = (accountId: string, writingModeSettings: WritingModeSettings) => {
  const settingsKey = buildLocalStorageKey(accountId);
  localStorageFacade.setItem(settingsKey, JSON.stringify(writingModeSettings));
};
