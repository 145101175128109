import { PrimaryButton, useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { ButtonProps } from '@atlaskit/button';
import { LinkItem } from '@atlaskit/menu';
import React, { forwardRef, useMemo } from 'react';
import { MatchParams, useRouter } from 'react-resource-router';

import { AnalyticsClient, useAnalytics } from '@townsquare/analytics';
import { Link, matchRoute } from '@townsquare/router/primitives';
import { HomepageRouteType } from '@townsquare/router/route-types';
import { useMetaClickHandler } from '@townsquare/ui-interactions';

interface NavItemProps {
  children: React.ReactNode;
  to: HomepageRouteType;
  params?: MatchParams;
  matches?: HomepageRouteType[];
  name: string;
}

const emitNavItemClickedEvent = (analytics: AnalyticsClient, itemName: string) => {
  void analytics.ui('navItem', 'clicked', {
    itemName,
  });
};

export const NavItem = (props: NavItemProps) => {
  const { to, params, children, name, matches = [] } = props;
  const analytics = useAnalytics();
  const [routerState] = useRouter();
  const { isVisible: isHorizontalVisible, closeOverflowMenu } = useOverflowStatus();

  const { handler, path } = useMetaClickHandler(to, params, () => {
    emitNavItemClickedEvent(analytics, name);
    closeOverflowMenu();
  });

  const clickCallback = useMemo(() => handler, [handler]);

  const match = matchRoute([to, ...matches], routerState.location.pathname, undefined);
  const isHighlighted = Boolean(match);

  const RouterLink = forwardRef<HTMLAnchorElement | null, ButtonProps>(function Component({ href = '', ...rest }, ref) {
    // @ts-expect-error - ref is not a public prop
    return <Link {...rest} key={href.substr(1)} prefetch="hover" to={to} ref={ref} />;
  });

  return isHorizontalVisible ? (
    <PrimaryButton
      testId={`primary-button-${name}`}
      isHighlighted={isHighlighted}
      onClick={clickCallback}
      href={path}
      component={RouterLink}
    >
      {children}
    </PrimaryButton>
  ) : (
    <LinkItem testId={`primary-button-${name}`} onClick={clickCallback} href={'' + path}>
      {children}
    </LinkItem>
  );
};
