import { LinkItem } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { ProjectIcon } from '@townsquare/project-icon';
import { projectRoute } from '@townsquare/project-view/route';
import { useMetaClickHandler } from '@townsquare/ui-interactions';

import { ProjectLinkItem$key } from './__generated__/ProjectLinkItem.graphql';
import { onItemClickContextOptions, ProjectLinkType } from './types';

type ProjectLinkItemProps = {
  data: ProjectLinkItem$key;
  navigateTo: onItemClickContextOptions;
  onItemClick: (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => void;
  type: ProjectLinkType;
};
export const ProjectLinkItem = (props: ProjectLinkItemProps) => {
  const intl = useIntl();
  const node = useFragment(
    graphql`
      fragment ProjectLinkItem on Project {
        uuid
        name
        ...ProjectIcon
        members {
          count
        }
        watcherCount
        key
      }
    `,
    props.data,
  );

  const membersCount = node.members?.count;
  const followerCount = node?.watcherCount;

  const memberMessage = intl.formatMessage(
    {
      id: 'townsquare.navigation-projects.project-link-item.member-text',
      description: 'Text to display the number of members in a project',
      defaultMessage: '{membersCount} {membersCount, plural, one {member} other {members}}',
    },
    {
      membersCount: membersCount || 1,
    },
  );

  const followerMessage = intl.formatMessage(
    {
      id: 'townsquare.navigation-projects.project-link-item.follower-text',
      description: 'Text to display the number of followers in a project',
      defaultMessage: '{followerCount} {followerCount, plural, one {follower} other {followers}}',
    },
    {
      followerCount: followerCount || 1,
    },
  );

  const descriptionMessage = props.type === 'MEMBER' ? memberMessage : followerMessage;

  const { handler, path } = useMetaClickHandler(projectRoute, { params: { id: node.key } }, e => {
    props.onItemClick(props.navigateTo, e);
  });

  return (
    <LinkItem
      iconBefore={<ProjectIcon data={node} size="medium" />}
      description={descriptionMessage}
      key={node.uuid}
      onClick={handler}
      href={'' + path}
    >
      {node.name}
    </LinkItem>
  );
};
