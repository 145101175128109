import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Location, Redirect, RouteContext } from 'react-resource-router';

import { UpdatesPageName, UpdatesPaths } from '@townsquare/config/routes';
import { localStorageFacade } from '@townsquare/facade';

import { LAST_SELECTED_UPDATES_PAGE_KEY } from './types';

const createRedirect = (getTo: (route: RouteContext) => string | Location) => (props: RouteContext) =>
  <Redirect to={getTo(props)} />;

export const LastSelectedUpdatesPageRedirect = createRedirect(route => {
  const lastSelectedPage =
    localStorageFacade.getItem<UpdatesPageName>(LAST_SELECTED_UPDATES_PAGE_KEY) || UpdatesPageName.yourWork;
  // fallback to project updates, which we know exists
  const pathname = UpdatesPaths.get(lastSelectedPage) || (UpdatesPaths.get(UpdatesPageName.yourWork) as string);
  return {
    ...route.location,
    pathname,
  };
});
