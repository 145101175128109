import { setGlobalTheme, useThemeObserver } from '@atlaskit/tokens';
import { useLayoutEffect } from 'react';

import { getConfig } from '@townsquare/config';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { useThemeStore } from '../store';

function isLocationAtlas() {
  return window.top === null || window.top.location.origin === getConfig().fullUrl;
}

/**
 * This component conditionally requires global styles to turn on global theming
 * depending on the result of the ENABLE_GLOBAL_THEMING feature flag.
 *
 * - [Feature flag](https://app.launchdarkly.com/watermelon/staging/features/dst-global-theming)
 * - [#design-system-team-central](https://atlassian.slack.com/archives/C0256AVSPHN) to discuss
 */
export const GlobalTheming = () => {
  const [theme] = useThemeStore();
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();
  const { colorMode: currentTheme } = useThemeObserver();

  useLayoutEffect(() => {
    // Enable global theming if we are in Atlas,
    // otherwise if we are outside of Atlas (e.g. embed, Jira Connect App...) we should not enable theming
    const setTheme = async () => {
      if (isLocationAtlas()) {
        resolveCorrectTheme(enableHomeVisualRefresh);
        await setGlobalTheme({ colorMode: theme, spacing: 'spacing', typography: 'typography-modernized' });
      }
    };

    setTheme().catch(_e => {
      /* Do nothing - i.e. don't set global theme */
    });
  }, [currentTheme, theme, enableHomeVisualRefresh]);

  return null;
};

/**
 * ok ... things are a bit gnarly here. Let me explain.
 * Visual / brand refresh requires loading a whole new theme into our page
 * But the way refreshed themes are implemented will cause conflicts with existing themes
 *
 * So we need to remove one set of them depending on if visual refreshed is enabled
 *
 * If you want to know more and like to read the discussions / investigations:
 * https://atlassian.slack.com/archives/C07C6KUHTGU/p1727245129393239
 */
const resolveCorrectTheme = (isVisualRefreshEnabled: boolean) => {
  if (isVisualRefreshEnabled) {
    // Visual refreshed is enabled. Remove the old themes to avoid conflicts
    document.querySelector('head style[data-theme=light]')?.remove();
    document.querySelector('head style[data-theme=dark]')?.remove();
  } else {
    // No visual refresh. Remove the refreshed themes
    document.querySelector('head style[data-theme=light-brand-refresh]')?.remove();
    document.querySelector('head style[data-theme=dark-brand-refresh]')?.remove();
  }
};
