import { Text } from '@atlaskit/primitives';
import React from 'react';

import { EllipsisTooltip } from '@townsquare/ellipsis';
import { BaseProjectIcon, type SSRIcon } from '@townsquare/project-icon';

import { StyledProjectFilterRow } from './style';

export interface ProjectFilterRowProps {
  project: {
    name: string;
    uuid: string;
    iconUrl?: SSRIcon | null;
  };
}

export const ProjectFilterRow = ({ project }: ProjectFilterRowProps) => {
  const { name, iconUrl } = project;
  return (
    <StyledProjectFilterRow>
      <BaseProjectIcon darkUrl={iconUrl?.square?.dark} lightUrl={iconUrl?.square?.light} size="small" />
      <EllipsisTooltip content={name}>
        <Text maxLines={1}>{name}</Text>
      </EllipsisTooltip>
    </StyledProjectFilterRow>
  );
};
