import Loadable from 'react-loadable';

import { DirectorySkeleton } from './components/StaffDirectoryView';

export const StaffDirectoryView = Loadable({
  loader: () => import(/* webpackChunkName: "RelayStaffDirectory" */ './components/StaffDirectoryView'),
  loading: DirectorySkeleton,
  delay: 0,
});

export const NoWorkspaceStaffDirectoryView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "NoWorkspaceDirectory" */ './components/EmptyOrNonProvisionedState/EmptyOrNonProvisionedState'
    ),
  loading: () => null,
});

export { staffDirectoryResource } from './resources';
