/* eslint-disable @atlaskit/design-system/no-deprecated-apis */
import { Settings } from '@atlaskit/atlassian-navigation';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { HeadingItem, LinkItem, MenuGroup, Section, StatelessCSSFn } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import React, { KeyboardEvent, MouseEvent, Suspense, useCallback, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { LoadingState } from '@townsquare/loading-state';
import { settingsRoute } from '@townsquare/settings-view/route';
import { useAdminHubLinkForSite, useManageBillingLink } from '@townsquare/url-utils';
import { useNoWorkspaceExperience, useWorkspaceStore, useProductDisplayName } from '@townsquare/workspace-store';

import { RouterLinkItem } from './RouterLinkItem';
import { FlagControlPanel } from './Settings/FlagControlPanel';
import { SettingsBillingAdminQuery } from './__generated__/SettingsBillingAdminQuery.graphql';

const { macUrl } = getConfig();

const minWidth = 240;
const maxWidth = 400;

export const SettingsContent = ({ onClick }: { onClick: () => void }) => {
  const intl = useIntl();
  const analytics = useAnalytics();
  const noWorkspaceExperience = useNoWorkspaceExperience();
  const headingCssFn: StatelessCSSFn = useCallback(() => ({ textTransform: 'none' }), []);
  const billingUrl = useManageBillingLink();
  const adminHubUrlForSite = useAdminHubLinkForSite();
  const [workspace] = useWorkspaceStore();
  const productDisplayName = useProductDisplayName();
  const billingAdminData = useLazyLoadQuery<SettingsBillingAdminQuery>(
    graphql`
      query SettingsBillingAdminQuery($id: ID!) {
        workspace(id: $id) {
          userWorkspacePermissions {
            admin
            billingAdmin
          }
        }
      }
    `,
    {
      id: workspace.globalId,
    },
    { fetchPolicy: noWorkspaceExperience ? 'store-only' : 'store-or-network' },
  );
  const isSiteAdmin = (!noWorkspaceExperience && billingAdminData.workspace?.userWorkspacePermissions?.admin) ?? false;
  const isBillingAdmin =
    (!noWorkspaceExperience && billingAdminData.workspace?.userWorkspacePermissions?.billingAdmin) ?? false;

  const handleWorkspaceSettingsClick = () => {
    void analytics.ui('workspaceSettings', 'clicked');
  };

  const handlePersonalSettingsClick = () => {
    void analytics.ui('personalSettings', 'clicked');
  };

  const handleCloudAdminSettingsClick = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    void analytics.ui('cloudAdministrationSettings', 'clicked');
    open(adminHubUrlForSite, '_blank', 'noreferrer');
  };

  const handleBillingSettingsClick = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    void analytics.ui('billingSettings', 'clicked');
    open(billingUrl, '_blank', 'noreferrer');
  };

  const handleLicensingSettingsClick = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    void analytics.ui('licensingSettings', 'clicked');
    open(macUrl, '_blank', 'noreferrer');
  };

  const showSiteAdminLinks = noWorkspaceExperience || isSiteAdmin;
  const showBillingAdminLinks = isBillingAdmin;

  return (
    <MenuGroup onClick={onClick} minWidth={minWidth} maxWidth={maxWidth} testId="settings-menu">
      {!noWorkspaceExperience && (
        <Section>
          <HeadingItem cssFn={headingCssFn}>
            <FormattedMessage
              id="townsquare.navigation.settings.product-settings-heading"
              description="Heading for a settings dropdown including links to settings pages of the product"
              defaultMessage="{productDisplayName} settings"
              values={{
                productDisplayName: productDisplayName,
              }}
            />
          </HeadingItem>
          <>
            {isSiteAdmin && (
              <RouterLinkItem
                onClick={handleWorkspaceSettingsClick}
                to={settingsRoute}
                attributes={{ params: { pageName: 'workspace' } }}
                testId="nav-workspace-settings-link"
              >
                <FormattedMessage {...messages.workspaceSettings} />
              </RouterLinkItem>
            )}
            <RouterLinkItem
              onClick={handlePersonalSettingsClick}
              to={settingsRoute}
              attributes={{ params: { pageName: 'personal' } }}
              testId="nav-personal-settings-link"
            >
              <FormattedMessage {...messages.personalSettings} />
            </RouterLinkItem>
          </>
        </Section>
      )}
      {(showSiteAdminLinks || showBillingAdminLinks) && (
        <Section>
          {productDisplayName === 'Atlas' && (
            <HeadingItem cssFn={headingCssFn}>
              <FormattedMessage
                id="townsquare.navigation.settings.atlassian-settings-heading"
                defaultMessage="Atlassian Home settings"
                description="Heading for a Atlassian Home admin settings links"
              />
            </HeadingItem>
          )}
          {showSiteAdminLinks && (
            <>
              <LinkItem
                onClick={handleCloudAdminSettingsClick}
                iconAfter={<ShortcutIcon color="currentColor" label="" LEGACY_size="small" />}
                href={adminHubUrlForSite}
                description={intl.formatMessage({
                  id: 'townsquare.navigation.settings.cloud-administration-settings-description',
                  defaultMessage: 'Manage users and security',
                  description: 'Description for the cloud administration settings link in the settings dropdown',
                })}
                testId="nav-cloud-admin-settings-link"
              >
                <FormattedMessage {...messages.cloudAdministrationSettings} />
              </LinkItem>
              <LinkItem
                onClick={handleLicensingSettingsClick}
                iconAfter={<ShortcutIcon color="currentColor" label="" LEGACY_size="small" />}
                href={macUrl}
                description={intl.formatMessage({
                  id: 'townsquare.navigation.settings.licensing-settings-description',
                  defaultMessage: 'Server and Data Center licensing',
                  description: 'Description for the licensing settings link in the settings dropdown',
                })}
                testId="nav-licensing-settings-link"
              >
                <FormattedMessage {...messages.licensingSettings} />
              </LinkItem>
            </>
          )}
          {showBillingAdminLinks && (
            <LinkItem
              onClick={handleBillingSettingsClick}
              iconAfter={<ShortcutIcon color="currentColor" label="" LEGACY_size="small" />}
              href={billingUrl}
              description={intl.formatMessage({
                id: 'townsquare.navigation.settings.billing-settings-description',
                defaultMessage: 'Manage billing',
                description: 'Description for the billing settings link in the settings dropdown',
              })}
              testId="nav-cloud-admin-settings-link"
            >
              <FormattedMessage {...messages.billingSettings} />
            </LinkItem>
          )}
        </Section>
      )}
      <FlagControlPanel />
    </MenuGroup>
  );
};

export const SettingsPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const analytics = useAnalytics();
  const intl = useIntl();

  const onClick = () => {
    void analytics.ui('settingsCog', 'clicked');
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Popup
      placement="bottom-end"
      content={() => (
        <Suspense
          fallback={
            <MenuGroup minWidth={minWidth} maxWidth={maxWidth}>
              <LoadingState />
            </MenuGroup>
          }
        >
          <SettingsContent onClick={onClose} />
        </Suspense>
      )}
      isOpen={isOpen}
      onClose={onClose}
      trigger={triggerProps => (
        <Settings
          onClick={onClick}
          isSelected={isOpen}
          tooltip={intl.formatMessage({
            id: 'townsquare.navigation.settings.tooltip',
            defaultMessage: 'Settings',
            description: 'Tooltip for the settings cog in the navigation',
          })}
          testId="nav-settings-button"
          {...triggerProps}
        />
      )}
    />
  );
};

const messages = defineMessages({
  workspaceSettings: {
    id: 'townsquare.navigation.settings.workspace-settings',
    defaultMessage: 'Workspace settings',
    description: 'Label for the workspace settings link in the settings dropdown',
  },
  personalSettings: {
    id: 'townsquare.navigation.settings.personal-settings',
    defaultMessage: 'Personal settings',
    description: 'Label for the personal settings link in the settings dropdown',
  },
  cloudAdministrationSettings: {
    id: 'townsquare.navigation.settings.cloud-administration-settings',
    defaultMessage: 'Cloud Administration',
    description: 'Label for the cloud administration settings link in the settings dropdown',
  },
  billingSettings: {
    id: 'townsquare.navigation.settings.billing-settings',
    defaultMessage: 'Billing',
    description: 'Label for the billing settings link in the settings dropdown',
  },
  licensingSettings: {
    id: 'townsquare.navigation.settings.licensing-settings',
    defaultMessage: 'Licensing',
    description: 'Label for the licensing settings link in the settings dropdown',
  },
});
