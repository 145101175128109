import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { BaseGoalIcon } from './BaseGoalIcon';
import { GoalIconProps } from './types';

export const GoalIcon = (props: GoalIconProps) => {
  const { iconProps, backgroundSize, includeBorder } = props;
  /* eslint-disable relay/unused-fields */
  const node = useFragment(
    graphql`
      fragment GoalIcon on Goal {
        state {
          score
          value
        }
      }
    `,
    props.data,
  );
  /* eslint-enable relay/unused-fields */

  return (
    <BaseGoalIcon
      backgroundSize={backgroundSize}
      state={node?.state}
      includeBackground
      includeBorder={includeBorder}
      {...iconProps}
    />
  );
};
