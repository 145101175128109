/**
 * @generated SignedSource<<bbb7b971622f2dbb360e72260d7ca97d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type WriteUpdateForSectionQuery$variables = {
  q: string;
  workspaceUuid: string;
};
export type WriteUpdateForSectionQuery$data = {
  readonly projectTql: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly key: string;
        readonly name: string;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProjectIcon">;
      } | null;
    } | null> | null;
  } | null;
};
export type WriteUpdateForSectionQuery = {
  response: WriteUpdateForSectionQuery$data;
  variables: WriteUpdateForSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "q"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": "LATEST_UPDATE_DATE_ASC"
  },
  {
    "kind": "Variable",
    "name": "workspaceUuid",
    "variableName": "workspaceUuid"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "WriteUpdateForSectionQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "ProjectIcon"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WriteUpdateForSectionQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "ProjectConnection",
        "kind": "LinkedField",
        "name": "projectTql",
        "plural": false,
        "selections": [
          {
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "private"
                  },
                  {
                    "concreteType": "IconURIs",
                    "kind": "LinkedField",
                    "name": "iconUrl",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "ThemeURIs",
                        "kind": "LinkedField",
                        "name": "square",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "light"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "dark"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1ebab465d930fe172405caf0b7ff13f2",
    "id": null,
    "metadata": {},
    "name": "WriteUpdateForSectionQuery",
    "operationKind": "query",
    "text": "query WriteUpdateForSectionQuery(\n  $workspaceUuid: UUID!\n  $q: String!\n) {\n  projectTql(first: 5, q: $q, workspaceUuid: $workspaceUuid, sort: LATEST_UPDATE_DATE_ASC) {\n    edges {\n      node {\n        key\n        uuid\n        name\n        ...ProjectIcon\n        id\n      }\n    }\n  }\n}\n\nfragment ProjectIcon on Project {\n  private\n  iconUrl {\n    square {\n      light\n      dark\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd1355b08384bae80fbec01cce525f0c";

export default node;
