/**
 * @generated SignedSource<<05b71ec591ace6e4e9b9f8cd97860086>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type RelatedLearning$data = {
  readonly project: {
    readonly id: string;
    readonly key: string;
    readonly name: string;
  } | null;
  readonly summary: string | null;
  readonly uuid: string;
  readonly " $fragmentType": "RelatedLearning";
};
export type RelatedLearning$key = {
  readonly " $data"?: RelatedLearning$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedLearning">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "RelatedLearning",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    {
      "kind": "ScalarField",
      "name": "summary"
    },
    {
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    }
  ],
  "type": "Learning"
};

(node as any).hash = "9220649fa86dc2fff0e3e8a845018a15";

export default node;
