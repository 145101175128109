import { IntlShape } from 'react-intl-next';

import {
  departmentResolver,
  goalResolver,
  jobTitleResolver,
  locationResolver,
  managerResolver,
  peopleUserTeamResolver,
  projectResolver,
  kudosGiverResolver,
  kudosRecipientResolver,
  kudosRecipientTeamResolver,
} from '@townsquare/tql/resolvers';
import { FilterDoc, Resolvers, SupportedFilters, SupportedFiltersTypes } from '@townsquare/tql/types';

export interface ResolverOptions {
  cloudId: string;
  workspaceId: string;
  workspaceUuid: string;
  orgId: string;
  cpusScope: string;
}

const getSharedResolvers = (): Resolvers => {
  // TODO: TC-2839 restore shared filters when team filters work again
  // return [projectResolver(), goalResolver()];
  return [];
};

const PEOPLE_FILTER_TYPES: SupportedFilters[] = [
  SupportedFiltersTypes.TEAM,
  SupportedFiltersTypes.JOB_TITLE,
  SupportedFiltersTypes.MANAGER,
  SupportedFiltersTypes.DEPARTMENT,
  SupportedFiltersTypes.LOCATION,
];
const getPeopleResolvers = (intl: IntlShape, options: ResolverOptions, sharedResolvers: Resolvers): Resolvers => [
  // TODO: TC-2839 restore project/goal filters to the shared filters, remove from here, when team filters work again
  projectResolver(intl, options.workspaceUuid),
  goalResolver(intl, options.workspaceUuid),
  peopleUserTeamResolver(intl, options),
  ...sharedResolvers,
  jobTitleResolver(intl, options),
  managerResolver(intl, options),
  departmentResolver(intl, options),
  locationResolver(intl, options),
];

const getKudosResolvers = (intl: IntlShape, options: ResolverOptions): Resolvers => [
  kudosGiverResolver(intl, options),
  kudosRecipientResolver(intl, options),
  kudosRecipientTeamResolver(intl, options),
];

// TODO: TC-2839 restore team filters when they work again (commented out)
const TEAM_FILTER_TYPES: SupportedFilters[] = [SupportedFiltersTypes.TEAM_MEMBER];
const getTeamResolvers = (_options: ResolverOptions): Resolvers => []; // [teamMemberResolver(options)];

export type FilterSelection = 'people-and-teams' | 'people' | 'teams' | 'kudos' | 'none' | 'unknown';

export const getFilterSelection = (filterDoc: FilterDoc): FilterSelection => {
  if (filterDoc.model.length === 0) {
    return 'none';
  }

  const filterTypes = filterDoc.model.map(filter => filter.type);
  if (filterTypes.some(filterType => PEOPLE_FILTER_TYPES.includes(filterType))) {
    return 'people';
  } else if (filterTypes.some(filterType => TEAM_FILTER_TYPES.includes(filterType))) {
    return 'teams';
  }

  return 'people-and-teams';
};

export const getFilterResolvers = (
  intl: IntlShape,
  options: ResolverOptions,
  filterSelection: FilterSelection,
): Resolvers => {
  const sharedResolvers = getSharedResolvers();

  switch (filterSelection) {
    case 'people':
      return getPeopleResolvers(intl, options, sharedResolvers);
    case 'teams':
      return [...sharedResolvers, ...getTeamResolvers(options)];
    case 'kudos':
      return getKudosResolvers(intl, options);
    case 'people-and-teams':
    case 'none':
    default:
      // Only people gets shared resolvers to preserve filter order and prevent duplicates
      return [...getPeopleResolvers(intl, options, sharedResolvers), ...getTeamResolvers(options)];
  }
};
