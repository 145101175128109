
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "Node",
        "possibleTypes": [
          {
            "name": "HelpPointer"
          },
          {
            "name": "User"
          },
          {
            "name": "AdminUserOrganization"
          },
          {
            "name": "Goal"
          },
          {
            "name": "Comment"
          },
          {
            "name": "Kudos"
          },
          {
            "name": "KudosRecipient"
          },
          {
            "name": "Team"
          },
          {
            "name": "Project"
          },
          {
            "name": "ProjectChangelogItem"
          },
          {
            "name": "ProjectFusion"
          },
          {
            "name": "NumberCustomField"
          },
          {
            "name": "NumberCustomFieldDefinition"
          },
          {
            "name": "TextCustomFieldDefinition"
          },
          {
            "name": "TextSelectCustomFieldDefinition"
          },
          {
            "name": "CustomFieldTextAllowedValueNode"
          },
          {
            "name": "UserCustomFieldDefinition"
          },
          {
            "name": "CustomFieldNumberSavedValueNode"
          },
          {
            "name": "TextCustomField"
          },
          {
            "name": "CustomFieldTextSavedValueNode"
          },
          {
            "name": "TextSelectCustomField"
          },
          {
            "name": "UserCustomField"
          },
          {
            "name": "ProjectDependency"
          },
          {
            "name": "ProjectUpdate"
          },
          {
            "name": "Boost"
          },
          {
            "name": "Learning"
          },
          {
            "name": "Milestone"
          },
          {
            "name": "Status"
          },
          {
            "name": "UpdateNote"
          },
          {
            "name": "GoalUpdate"
          },
          {
            "name": "UserViews"
          },
          {
            "name": "Viewer"
          },
          {
            "name": "Link"
          },
          {
            "name": "WorkspaceMember"
          },
          {
            "name": "Workspace"
          },
          {
            "name": "Tag"
          },
          {
            "name": "Risk"
          },
          {
            "name": "Decision"
          },
          {
            "name": "GoalFusion"
          },
          {
            "name": "MetricTarget"
          },
          {
            "name": "Metric"
          },
          {
            "name": "MetricValue"
          },
          {
            "name": "Onboarding"
          },
          {
            "name": "UserTag"
          },
          {
            "name": "DirectoryView"
          },
          {
            "name": "CustomDirectoryViewColumn"
          },
          {
            "name": "StandardDirectoryViewColumn"
          },
          {
            "name": "Organization"
          },
          {
            "name": "UserInvite"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CommentContainer",
        "possibleTypes": [
          {
            "name": "Goal"
          },
          {
            "name": "Kudos"
          },
          {
            "name": "Project"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "Contributor",
        "possibleTypes": [
          {
            "name": "Team"
          },
          {
            "name": "User"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CustomField",
        "possibleTypes": [
          {
            "name": "NumberCustomField"
          },
          {
            "name": "TextCustomField"
          },
          {
            "name": "TextSelectCustomField"
          },
          {
            "name": "UserCustomField"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CustomFieldNode",
        "possibleTypes": [
          {
            "name": "NumberCustomField"
          },
          {
            "name": "TextCustomField"
          },
          {
            "name": "TextSelectCustomField"
          },
          {
            "name": "UserCustomField"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CustomFieldDefinition",
        "possibleTypes": [
          {
            "name": "NumberCustomFieldDefinition"
          },
          {
            "name": "TextCustomFieldDefinition"
          },
          {
            "name": "TextSelectCustomFieldDefinition"
          },
          {
            "name": "UserCustomFieldDefinition"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CustomFieldDefinitionNode",
        "possibleTypes": [
          {
            "name": "NumberCustomFieldDefinition"
          },
          {
            "name": "TextCustomFieldDefinition"
          },
          {
            "name": "TextSelectCustomFieldDefinition"
          },
          {
            "name": "UserCustomFieldDefinition"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Highlight",
        "possibleTypes": [
          {
            "name": "Learning"
          },
          {
            "name": "Risk"
          },
          {
            "name": "Decision"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "OpenInviteModeResponse",
        "possibleTypes": [
          {
            "name": "OpenInviteModeData"
          },
          {
            "name": "OpenInviteModeErrorResponse"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ProductActivation",
        "possibleTypes": [
          {
            "name": "ConfluenceProductActivation"
          },
          {
            "name": "JiraProductActivation"
          },
          {
            "name": "TownsquareProductActivation"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "Update",
        "possibleTypes": [
          {
            "name": "GoalUpdate"
          },
          {
            "name": "ProjectUpdate"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DirectoryViewColumn",
        "possibleTypes": [
          {
            "name": "CustomDirectoryViewColumn"
          },
          {
            "name": "StandardDirectoryViewColumn"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "TeamHealthPlay",
        "possibleTypes": [
          {
            "name": "TeamHealthHealthyStatus"
          },
          {
            "name": "TeamHealthRecommendedPlay"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "WorkspaceByContextResponse",
        "possibleTypes": [
          {
            "name": "WorkspaceByContextAccessDeniedResponse"
          },
          {
            "name": "WorkspaceByContextErrorResponse"
          },
          {
            "name": "WorkspaceByContextProductRecommendationResponse"
          },
          {
            "name": "WorkspaceByContextSuccessfulResponse"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "MutationError",
        "possibleTypes": [
          {
            "name": "MembershipMutationError"
          },
          {
            "name": "CreateRelationshipsMutationError"
          },
          {
            "name": "DeleteRelationshipsMutationError"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "MutationErrorExtension",
        "possibleTypes": [
          {
            "name": "MembershipMutationErrorExtensions"
          },
          {
            "name": "CreateRelationshipsMutationErrorExtension"
          },
          {
            "name": "DeleteRelationshipsMutationErrorExtension"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CreateCustomFieldDefinitionError",
        "possibleTypes": [
          {
            "name": "CustomFieldNameAlreadyExistsError"
          },
          {
            "name": "CustomFieldsLimitReachedError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GoalAndGoalFusionCreationResult",
        "possibleTypes": [
          {
            "name": "GoalAndGoalFusionCreationError"
          },
          {
            "name": "GoalAndGoalFusionCreationSuccess"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GoalFusionCreationResult",
        "possibleTypes": [
          {
            "name": "GoalFusionCreationError"
          },
          {
            "name": "GoalFusionCreationSuccess"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ProjectFusionCreationResult",
        "possibleTypes": [
          {
            "name": "ProjectFusionCreationError"
          },
          {
            "name": "ProjectFusionCreationSuccess"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DeleteCustomFieldDefinitionResult",
        "possibleTypes": [
          {
            "name": "DeleteCustomFieldDefinitionError"
          },
          {
            "name": "DeleteCustomFieldDefinitionSuccess"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UpdateCustomFieldDefinitionError",
        "possibleTypes": [
          {
            "name": "CustomFieldNameAlreadyExistsError"
          }
        ]
      }
    ]
  }
};
      export default result;
    