import { ProfileCardTrigger, ProfilecardTriggerPosition } from '@atlaskit/profilecard';
import React, { useMemo } from 'react';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { useFlagActions } from '@townsquare/flags';
import { useOrgContextOrgId } from '@townsquare/org-context';
import { profileRoute } from '@townsquare/ptc-directory-view';
import { generatePath, useRouteDependencies, useRouterActions } from '@townsquare/router/primitives';
import { useCurrentUser } from '@townsquare/user-store';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { getProfileClient } from './profileClient';
import { useProfilePath } from './util';
const PEOPLE_PROFILE_PATH = '/people/';

type Props = {
  children: JSX.Element;
  position?: ProfilecardTriggerPosition;
  userId: string;
};

export const WrappedWithProfileCard = (props: Props) => {
  const { userId, position, children } = props;
  const [workspace] = useWorkspaceStore();
  const orgId = useOrgContextOrgId();
  const [{ route }] = useRouter();
  const { pushTo } = useRouterActions();
  const analytics = useAnalytics();
  const { addFlag } = useFlagActions();
  const routeAttributes = useRouteDependencies();
  const profilePath = useProfilePath(userId, true);
  const currentUserId = useCurrentUser().accountId;
  const viewProfileLink = window.self !== window.top ? `${getConfig().fullUrl}${profilePath}` : profilePath;

  const profileClickCallback = (clickedUserId: string, clickTarget: string) => {
    const profilePath = generatePath(profileRoute, { baseParams: { id: clickedUserId }, ...routeAttributes });

    void analytics.ui('viewProfile', 'clicked', { route: route.name, cardContainer: 'userAvatar', clickTarget });
    if (window.self !== window.top) {
      window.open(`${getConfig().fullUrl}${profilePath}`, '_blank', 'noreferrer');
    } else {
      void pushTo(profileRoute, { params: { id: clickedUserId } });
    }
  };

  const profileCardTriggerActions = useMemo(
    () => [
      {
        label: 'View profile',
        id: 'view-profile',
        callback: () => {
          profileClickCallback(userId, 'userProfile');
        },
        link: viewProfileLink,
      },
    ],
    // FIXME: TC-4035
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId],
  );

  return (
    <ProfileCardTrigger
      resourceClient={getProfileClient(workspace.cloudId, orgId)}
      position={position}
      userId={userId}
      actions={profileCardTriggerActions}
      reportingLinesProfileUrl={PEOPLE_PROFILE_PATH}
      onReportingLinesClick={user => profileClickCallback(user.accountIdentifier, 'userReportingLine')}
      cloudId={workspace.cloudId}
      addFlag={addFlag}
      viewingUserId={currentUserId}
    >
      {children}
    </ProfileCardTrigger>
  );
};
