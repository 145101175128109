import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StatusSummarySkeletonWrapper = styled.div({
  marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TimespanNavigatorSkeletonContainer = styled.div({
  marginBottom: token('space.400'),
  display: 'flex',
  height: '32px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MetaActionsWrapper = styled.div({
  marginBottom: token('space.500'),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: `${token('space.150')} ${token('space.100')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderWrapper = styled.div({
  marginBottom: token('space.300'),
  gap: token('space.150'),
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExternalLinkWrapper = styled.div({
  marginLeft: 'auto',
});
