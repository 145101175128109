export function filterUndefined<T>(x?: T): x is T {
  return !!x;
}

export function filterNull<T>(x?: T): x is Exclude<T, null> {
  return !!x;
}

export function filterNonString(x?: unknown): x is string {
  return typeof x === 'string';
}
