import Image from '@atlaskit/image';
import { Inline, xcss } from '@atlaskit/primitives';
import React from 'react';
import { useIntl } from 'react-intl-next';

import CompetitiveAnalysisImageDark from '../../Images/LearningsExamples/CompetitiveAnalysisLearningExampleDark.svg';
import CompetitiveAnalysisImageDark_en from '../../Images/LearningsExamples/CompetitiveAnalysisLearningExampleDark_english.svg';
import CompetitiveAnalysisImageLight from '../../Images/LearningsExamples/CompetitiveAnalysisLearningExampleLight.svg';
import CompetitiveAnalysisImageLight_en from '../../Images/LearningsExamples/CompetitiveAnalysisLearningExampleLight_english.svg';
import CustInsightsImageDark from '../../Images/LearningsExamples/CustomerInsightsLearningExampleDark.svg';
import CustInsightsImageDark_en from '../../Images/LearningsExamples/CustomerInsightsLearningExampleDark_english.svg';
import CustInsightsImageLight from '../../Images/LearningsExamples/CustomerInsightsLearningExampleLight.svg';
import CustInsightsImageLight_en from '../../Images/LearningsExamples/CustomerInsightsLearningExampleLight_english.svg';
import DataInsightsImageDark from '../../Images/LearningsExamples/DataInsightsLearningExampleDark.svg';
import DataInsightsImageDark_en from '../../Images/LearningsExamples/DataInsightsLearningExampleDark_english.svg';
import DataInsightsImageLight from '../../Images/LearningsExamples/DataInsightsLearningExampleLight.svg';
import DataInsightsImageLight_en from '../../Images/LearningsExamples/DataInsightsLearningExampleLight_english.svg';
import WaysOfWorkingImageDark from '../../Images/LearningsExamples/WaysOfWorkingLearningExampleDark.svg';
import WaysOfWorkingImageDark_en from '../../Images/LearningsExamples/WaysOfWorkingLearningExampleDark_english.svg';
import WaysOfWorkingImageLight from '../../Images/LearningsExamples/WaysOfWorkingLearningExampleLight.svg';
import WaysOfWorkingImageLight_en from '../../Images/LearningsExamples/WaysOfWorkingLearningExampleLight_english.svg';
import { LocalisedImageSource } from '../../types';

const imageStyles = xcss({
  display: 'flex',
  width: 'inherit',
  height: 'auto',
});

type ExampleName = 'CompetitiveAnalysis' | 'CustInsights' | 'DataInsights' | 'WaysOfWorking';

const imageMap: Record<ExampleName, LocalisedImageSource> = {
  CustInsights: {
    english: {
      light: CustInsightsImageLight_en,
      dark: CustInsightsImageDark_en,
    },
    blank: {
      light: CustInsightsImageLight,
      dark: CustInsightsImageDark,
    },
  },
  DataInsights: {
    english: {
      light: DataInsightsImageLight_en,
      dark: DataInsightsImageDark_en,
    },
    blank: {
      light: DataInsightsImageLight,
      dark: DataInsightsImageDark,
    },
  },
  CompetitiveAnalysis: {
    english: {
      light: CompetitiveAnalysisImageLight_en,
      dark: CompetitiveAnalysisImageDark_en,
    },
    blank: {
      light: CompetitiveAnalysisImageLight,
      dark: CompetitiveAnalysisImageDark,
    },
  },
  WaysOfWorking: {
    english: {
      light: WaysOfWorkingImageLight_en,
      dark: WaysOfWorkingImageDark_en,
    },
    blank: {
      light: WaysOfWorkingImageLight,
      dark: WaysOfWorkingImageDark,
    },
  },
};

const LearningExampleImage = ({ imageName }: { imageName: ExampleName }) => {
  const intl = useIntl();
  const isEnglish = intl.locale.startsWith('en');

  const image = imageMap[imageName][isEnglish ? 'english' : 'blank'];
  return (
    <Inline xcss={imageStyles}>
      <Image src={image.light} srcDark={image.dark} width="100%" />
    </Inline>
  );
};
export default LearningExampleImage;
