import { PostOfficeEnvironmentConfig } from '@atlassian/post-office-placement-remote';

import { getEnv } from '@townsquare/config';

export function getPostOfficeEnv(): PostOfficeEnvironmentConfig['env'] {
  const env = getEnv();

  if (env.includes('prod')) {
    return 'production';
  }

  if (env === 'local') {
    return 'local';
  }

  return 'staging';
}
