import { AppSwitcher } from '@atlaskit/atlassian-navigation';
import Popup, { TriggerProps } from '@atlaskit/popup';
import React, { useState } from 'react';
import { useIntl } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { AppSwitcherContent } from '../AppSwitcherContent';

import { AppSwitcherRefreshed } from './AppSwitcherRefreshed';

export const AppSwitcherButton = () => {
  const intl = useIntl();
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  const [isOpen, setIsOpen] = useState(false);
  const analytics = useAnalytics();

  const onClick = () => {
    void analytics.ui('appSwitcher', isOpen ? 'closed' : 'opened');
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    void analytics.ui('appSwitcher', 'closed');
    setIsOpen(false);
  };

  return (
    <Popup
      placement="bottom-start"
      content={() => <AppSwitcherContent />}
      isOpen={isOpen}
      testId="app-switcher-popup"
      onClose={onClose}
      trigger={(triggerProps: TriggerProps) =>
        isNavRefreshEnabled ? (
          <AppSwitcherRefreshed
            testId="app-switcher-button"
            tooltip={intl.formatMessage({
              id: 'townsquare.navigation.app-switcher.tooltip',
              description: 'Tooltip for the app switcher button',
              defaultMessage: 'Switch to...',
            })}
            {...triggerProps}
            isSelected={isOpen}
            onClick={onClick}
          />
        ) : (
          <AppSwitcher
            testId="app-switcher-button"
            tooltip={intl.formatMessage({
              id: 'townsquare.navigation.app-switcher.tooltip',
              description: 'Tooltip for the app switcher button',
              defaultMessage: 'Switch to...',
            })}
            {...triggerProps}
            isSelected={isOpen}
            onClick={onClick}
          />
        )
      }
    />
  );
};
