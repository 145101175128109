import { captureMessage, withScope } from '@sentry/react';
import { SeverityLevel } from '@sentry/types';

export const logMessage = (message: string, severity: SeverityLevel, extras?: Record<string, unknown>) => {
  // Send to Sentry
  withScope(scope => {
    if (extras) {
      Object.keys(extras).forEach(key => {
        scope.setExtra(key, extras[key]);
      });
    }
    captureMessage(message, severity);
  });
};
