import React from 'react';

import { useModalActions } from '@townsquare/modals';

import { CreateGoalModal } from './components';
import type { CreateGoalModalOptions } from './components/CreateGoalModal';

export const useCreateGoalModal = () => {
  const { openModal, closeModal } = useModalActions();

  const openCreateGoalModal = (options: CreateGoalModalOptions) => {
    openModal('create-goal-modal', {
      component: <CreateGoalModal {...options} onClose={() => closeModal('create-goal-modal')} />,
    });
  };

  return {
    openCreateGoalModal,
  };
};
