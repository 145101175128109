/**
 * @generated SignedSource<<acd1bc2b59c35dbc6ea984cb3f33854c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type SearchPeoplePaginationQuery$variables = {
  after?: string | null;
  cloudId: string;
  first?: number | null;
  isSearchPeopleScreen: boolean;
  organisationId: string;
  query: string;
};
export type SearchPeoplePaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchPeopleScreen_data">;
};
export type SearchPeoplePaginationQuery = {
  response: SearchPeoplePaginationQuery$data;
  variables: SearchPeoplePaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSearchPeopleScreen"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "query"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "SearchPeoplePaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "isSearchPeopleScreen",
            "variableName": "isSearchPeopleScreen"
          },
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "query",
            "variableName": "query"
          }
        ],
        "kind": "FragmentSpread",
        "name": "SearchPeopleScreen_data"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchPeoplePaginationQuery",
    "selections": [
      {
        "condition": "isSearchPeopleScreen",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "CpusSearchUserConnection",
            "kind": "LinkedField",
            "name": "peopleTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "CpusSearchUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "CpusSearchUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "account_id"
                      },
                      (v6/*: any*/),
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "size",
                            "value": "MEDIUM"
                          }
                        ],
                        "concreteType": "HighResolutionProfilePicture",
                        "kind": "LinkedField",
                        "name": "highResolutionProfilePicture",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "url"
                          }
                        ],
                        "storageKey": "highResolutionProfilePicture(size:\"MEDIUM\")"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "job_title"
                      },
                      {
                        "concreteType": "ReportCounts",
                        "kind": "LinkedField",
                        "name": "reportCounts",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "directReports"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "location"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "zoneinfo"
                      },
                      {
                        "concreteType": "TeamConnection",
                        "kind": "LinkedField",
                        "name": "teams",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "TeamEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "Team",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "teamId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "id"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "args": (v5/*: any*/),
            "filters": [
              "q",
              "organisationId",
              "cloudId"
            ],
            "handle": "connection",
            "key": "SearchPeopleScreen_peopleTql",
            "kind": "LinkedHandle",
            "name": "peopleTql"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b6e3dc5688e5b429953f1fa695e46685",
    "id": null,
    "metadata": {},
    "name": "SearchPeoplePaginationQuery",
    "operationKind": "query",
    "text": "query SearchPeoplePaginationQuery(\n  $after: String\n  $cloudId: String!\n  $first: Int = 20\n  $isSearchPeopleScreen: Boolean!\n  $organisationId: String!\n  $query: String!\n) {\n  ...SearchPeopleScreen_data_2jKiXF\n}\n\nfragment CardTeamsSection on CpusSearchUser {\n  teams {\n    edges {\n      node {\n        name\n        teamId\n        id\n      }\n    }\n  }\n}\n\nfragment PersonSearchResultCard on CpusSearchUser {\n  account_id\n  name\n  highResolutionProfilePicture(size: MEDIUM) {\n    url\n  }\n  job_title\n  reportCounts {\n    directReports\n  }\n  location\n  zoneinfo\n  ...CardTeamsSection\n}\n\nfragment SearchPeopleScreen_data_2jKiXF on Query {\n  peopleTql(q: $query, organisationId: $organisationId, cloudId: $cloudId, first: $first, after: $after) @include(if: $isSearchPeopleScreen) {\n    edges {\n      node {\n        account_id\n        ...PersonSearchResultCard\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8978a6f93de2e4c615d4209217fd4fcf";

export default node;
