/**
 * @generated SignedSource<<d65098f565baf7e0714d0a4e597e0706>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type LocationCard$data = {
  readonly imageDirectoryUrl: string | null;
  readonly name: string;
  readonly " $fragmentType": "LocationCard";
};
export type LocationCard$key = {
  readonly " $data"?: LocationCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"LocationCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "LocationCard",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "imageDirectoryUrl"
    }
  ],
  "type": "PeopleFieldAggregate"
};

(node as any).hash = "a60b320b585059d5887e92b1d083d3a1";

export default node;
