import { commitMutation, Environment, graphql, type MutationConfig } from 'relay-runtime';

import { updateDecisionInput, UpdateDecisionMutation } from './__generated__/UpdateDecisionMutation.graphql';

export const updateDecision = (
  environment: Environment,
  input: updateDecisionInput,
  events: Pick<MutationConfig<UpdateDecisionMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<UpdateDecisionMutation>(environment, {
    mutation: graphql`
      mutation UpdateDecisionMutation($input: updateDecisionInput!) {
        updateDecision(input: $input) {
          decision {
            id
            summary
            description
            lastEditedBy {
              pii {
                name
                picture
              }
            }
            lastEditedDate
          }
        }
      }
    `,
    variables: { input },
    optimisticUpdater: store => {
      if (input.id) {
        const decision = store.get(input.id);

        if (input.summary) {
          decision?.setValue(input.summary, 'summary');
        }
        if (input.description) {
          decision?.setValue(input.description, 'description');
        }
      }
    },
    onCompleted: events.onCompleted,
    onError: events.onError,
  });
};
