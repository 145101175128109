import { useClassicPaths } from '@townsquare/config/classic-paths';
import { useWorkspaceType } from '@townsquare/workspace-store/hooks';

import { HomepageRouteType } from '../home/types';

// The non react hook version of the same logic
export function getEffectiveTo(
  route: HomepageRouteType,
  workspaceType: ReturnType<typeof useWorkspaceType> | undefined,
) {
  /**
   * Escape hatch to fallback to "classic" / vanity path, in 2 situations:
   * 1. When we're too early and we haven't resolved workspace details yet
   * 2. When user currently on Atlas classic
   *
   * In both situations, we SHOULD AVOID using / rendering titan links
   * because those sometimes require org / site params, which are not available
   */
  return (useClassicPaths || !workspaceType || workspaceType === 'PRODUCT') && 'classicPath' in route
    ? {
        ...route,
        path: route.classicPath,
      }
    : route;
}

export function useGetEffectiveTo(route: HomepageRouteType) {
  const workspaceType = useWorkspaceType();
  return getEffectiveTo(route, workspaceType);
}
