import React from 'react';

import Network5xxError from './ErrorComponents/Network5xxError';
import { ErrorContainer } from './styles';

export const FullPageError = (props: { testId?: string }) => (
  <ErrorContainer isFullPage data-testid={props.testId}>
    <Network5xxError />
  </ErrorContainer>
);
