import { LinkItem } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { goalViewRoute } from '@townsquare/goal-view/route';
import { GoalIcon } from '@townsquare/icons';
import { useMetaClickHandler } from '@townsquare/ui-interactions';

import { GoalLinkItem$data, GoalLinkItem$key } from './__generated__/GoalLinkItem.graphql';
import { GoalLinkType, onItemClickContextOptions } from './types';
type GoalLinkItemProps = {
  data: GoalLinkItem$key;
  navigateTo: onItemClickContextOptions;
  onItemClick: (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => void;
  testId: string;
  type: GoalLinkType;
};
export const GoalLinkItem = (props: GoalLinkItemProps) => {
  const intl = useIntl();

  const node = useFragment(
    graphql`
      fragment GoalLinkItem on Goal {
        uuid
        name
        ...GoalIcon
        key
        aggregatedInfo {
          numProjects
        }
        archived
        subGoals {
          edges {
            node {
              archived
            }
          }
        }
        watchers {
          edges {
            __typename
          }
        }
      }
    `,
    props.data,
  );

  const { handler, path } = useMetaClickHandler(
    goalViewRoute,
    {
      params: {
        id: node?.key,
      },
    },
    e => {
      props.onItemClick('navigateToYourGoals', e);
    },
  );

  const generateNoOfProjectsDescription = (goal: GoalLinkItem$data) => {
    const projectCount = goal?.aggregatedInfo?.numProjects || 0;
    const subGoalCount =
      (goal?.subGoals?.edges ?? []).filter(edge => edge?.node?.archived === false ?? false).length || 0;

    const projectText = intl.formatMessage(
      {
        id: 'townsquare.navigation-goals.goal-link-item.project-text',
        description: 'Text to display the number of projects in a goal',
        defaultMessage: '{projectCount} {projectCount, plural, one {project} other {projects}}',
      },
      {
        projectCount: projectCount || 1,
      },
    );

    const subGoalText = intl.formatMessage(
      {
        id: 'townsquare.navigation-goals.goal-link-item.sub-goal-text',
        description: 'Text to display the number of sub-goals in a goal',
        defaultMessage: '{subGoalCount} {subGoalCount, plural, one {sub-goal} other {sub-goals}}',
      },
      {
        subGoalCount: subGoalCount || 1,
      },
    );

    if (projectCount && subGoalCount) {
      return `${projectText}, ${subGoalText}`;
    } else if (projectCount) {
      return projectText;
    } else if (subGoalCount) {
      return subGoalText;
    }
  };

  const numOfFollowers = node?.watchers?.edges?.length;

  const followerMessage = intl.formatMessage(
    {
      id: 'townsquare.navigation-goals.goal-link-item.follower-text',
      description: 'Text to display the number of followers in a goal',
      defaultMessage: '{numOfFollowers} {numOfFollowers, plural, one {follower} other {followers}}',
    },
    {
      numOfFollowers: numOfFollowers || 1,
    },
  );

  return (
    <LinkItem
      iconBefore={
        <GoalIcon
          includeBackground
          iconProps={{
            size: 'medium',
            label: intl.formatMessage({
              id: 'townsquare.navigation-goals.goal-link-item.goal-icon-label',
              description: 'Label for the goal icon',
              defaultMessage: 'Goal icon',
            }),
          }}
          data={node}
        />
      }
      description={props.type === 'PROJECT' ? generateNoOfProjectsDescription(node) : followerMessage}
      key={node?.uuid}
      onClick={handler}
      href={'' + path}
      testId={props.testId}
    >
      {node?.name}
    </LinkItem>
  );
};
