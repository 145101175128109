import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { OnboardingAppearance, SecondaryButtonType } from './types';

export type CustomStyling = {
  marginTop: number;
  marginBottom: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const CloseButton = styled.div<{ appearance: OnboardingAppearance }>(props => ({
  paddingLeft: token('space.250'),
  marginLeft: 'auto',
  display: 'flex',
  button: {
    '&, &:hover, &:active': {
      background: 'none',
    },
    span: {
      '> span': {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        color: getStyling(props.appearance).color,
      },
      '&:hover > span': {
        color: token('color.background.discovery.bold.hovered'),
      },
      '&:active > span': {
        color: token('color.background.discovery.bold.pressed'),
      },
    },
  },
}));

const PRIMARY_APPEARANCE_STYLING = {
  backgroundColor: token('color.background.accent.purple.subtlest'),
  color: token('color.background.accent.purple.bolder'),
};
const SUBTLE_APPEARANCE_STYLING = {
  backgroundColor: token('color.background.accent.gray.subtlest'),
  color: token('color.background.accent.gray.bolder'),
};

const getStyling = (appearance: OnboardingAppearance = 'PRIMARY') =>
  appearance === 'PRIMARY' ? PRIMARY_APPEARANCE_STYLING : SUBTLE_APPEARANCE_STYLING;

export const LOWER_MARGIN_STYLING = {
  marginTop: 0,
  marginBottom: 8,
};

export const LOW_MARGIN_STYLING = {
  marginTop: 0,
  marginBottom: 20,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const BannerContainer = styled.div<{ appearance: OnboardingAppearance }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundColor: getStyling(props.appearance).backgroundColor,
  padding: token('space.250'),
  borderRadius: '6px',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Container = styled.div<{ customMargins?: CustomStyling }>(({ customMargins }) => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginBottom: `${customMargins?.marginBottom ? customMargins.marginBottom : 0}px`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginTop: `${customMargins?.marginTop ? customMargins.marginTop : 0}px`,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Content = styled.div<{ appearance: OnboardingAppearance }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: getStyling(props.appearance).color,
  font: token('font.body'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentWithMargins = styled(Content)({
  marginTop: token('space.100'),
  paddingTop: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Title = styled.div<{ appearance: OnboardingAppearance }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: getStyling(props.appearance).color,
  font: token('font.heading.small'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const UnderlineText = styled.span<{ appearance?: OnboardingAppearance }>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: getStyling(props.appearance).color,
  textDecoration: 'underline',
  marginLeft: token('space.050'),
  fontWeight: token('font.weight.regular'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UnderlineTextWithIcon = styled(UnderlineText)({
  display: 'inline-flex',
  alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoContentStyling = styled.div({
  font: token('font.body.small'),
  display: 'inline-block',
  marginLeft: token('space.050'),
  color: token('color.text.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CustomButton = styled(Button)({
  padding: `${token('space.075')} ${token('space.150')}`,
  fontWeight: token('font.weight.medium'),
  font: token('font.body'),
  marginTop: token('space.250'),
  marginRight: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> span:first-child': {
    alignSelf: 'center',
    padding: `${token('space.075')} ${token('space.150')}`,
    margin: 0,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PrimaryBannerButton = styled(CustomButton)({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  background: `${token('color.background.discovery.bold')} !important`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> span': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${token('color.text.inverse')} !important`,
  },
  '&:hover': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    background: `${token('color.background.discovery.bold.hovered')} !important`,
  },
  '&:active': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    background: `${token('color.background.discovery.bold.pressed')} !important`,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const SecondaryBannerButton = styled(CustomButton)<{ buttonType: SecondaryButtonType }>(({ buttonType }) => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  background: buttonType === 'button' ? `${token('color.background.accent.purple.subtler')} !important` : '',

  '> span': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${token('color.background.accent.purple.bolder')} !important`,
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionMessageContainer = styled.div({
  width: '100%',
  marginBottom: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ExampleUpdatesInfo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  color: token('color.text.subtlest'),
  alignItems: 'flex-start',
  marginBottom: token('space.500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoIconWrapper = styled.div({
  minWidth: '16px',
  maxWidth: '16px',
  marginRight: token('space.050'),
  color: token('color.text.disabled'),
  display: 'flex',
  flexDirection: 'column',
  marginTop: token('space.negative.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IntroPopupAtlasIconWrapper = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  svg: {
    width: token('space.200'),
    height: token('space.200'),
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IntroPopupContainer = styled.div({
  position: 'fixed',
  bottom: token('space.300'),
  left: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IntroPopupContent = styled.div({
  width: '342px',
});
