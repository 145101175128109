import { borderRadius } from '@atlaskit/theme';
import React from 'react';
import styled from 'styled-components';

interface BlurryBackgroundProps {
  backgroundUrl?: string | null;
  imageUrl?: string | null;
  size?: 'standard' | 'large';
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ImageWrapper = styled.div<BlurryBackgroundProps>(props => ({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: `${borderRadius() * 2}px`,
  width: '100%',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: props.size === 'large' ? '150px' : '100px',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BlurryBackgroundImage = styled.img({
  height: '100%',
  width: '100%',
  minWidth: 0,
  position: 'absolute',
  filter: 'blur(60px)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Image = styled.img({
  maxHeight: '100%',
  minWidth: 0,
  margin: 'auto',
  zIndex: 0,
});

// Renders a blurry background behind the image to respect desired aspect ratio
const BlurryBackground = (props: BlurryBackgroundProps) => {
  return (
    <ImageWrapper size={props.size}>
      <BlurryBackgroundImage src={props.backgroundUrl || ''} />
      <Image src={props.imageUrl || ''} />
    </ImageWrapper>
  );
};

export default BlurryBackground;
