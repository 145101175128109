/* eslint-disable formatjs/no-literal-string-in-jsx */
// This file is dev tooling only.
import { MenuGroup, Section } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent, Suspense } from 'react';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig, getEnv } from '@townsquare/config';
import { redirect } from '@townsquare/facade';
import { filterUndefined } from '@townsquare/filter-type';
import { LoadingState } from '@townsquare/loading-state';
import { useOrgContextStore } from '@townsquare/org-context';
import { getQueryParam } from '@townsquare/url-utils/search-params';
import { GoalScoringMode, useNoWorkspaceExperience, useWorkspaceStore } from '@townsquare/workspace-store';

import { SwitchWorkspaceItem } from './SwitchWorkspaceItem';
import { DevWorkspaceSwitcherQuery as DevWorkspaceSwitcherQueryType } from './__generated__/DevWorkspaceSwitcherQuery.graphql';
import { WorkspaceDetailsForSwitcher } from './types';

const DevWorkspaceSwitcherQuery = graphql`
  query DevWorkspaceSwitcherQuery {
    allWorkspaces {
      edges {
        node {
          name
          id
          uuid
          keyPrefix
          timezone
          cloudId
          organisationId
          goalScoringMode
          type
        }
      }
    }
  }
`;

const STG_NO_WORKSPACE_ENV = {
  UUID: '',
  name: 'No workspace: Home',
  organisationId: '2kj781d4-1821-1a6b-73b1-k9680k215635',
  type: 'GLOBAL_EXPERIENCE' as const,
  cloudId: '',
};

const PROD_NO_WORKSPACE_ENV = {
  UUID: '',
  name: 'No workspace: Home',
  organisationId: '3a406e1c-1fd4-40eb-99b6-2517c0afabb5',
  type: 'GLOBAL_EXPERIENCE' as const,
  cloudId: '',
};

type DevWorkspaceSwitcherProps = {
  queryReference: PreloadedQuery<DevWorkspaceSwitcherQueryType>;
};

const DevWorkspaceSwitcher = (props: DevWorkspaceSwitcherProps) => {
  const data = usePreloadedQuery<DevWorkspaceSwitcherQueryType>(DevWorkspaceSwitcherQuery, props.queryReference);

  const [{ orgId }] = useOrgContextStore();
  const [workspace] = useWorkspaceStore();
  const [{ route }] = useRouter();
  const noWorkspaceExperience = useNoWorkspaceExperience();

  const allWorkspaceOptions =
    data?.allWorkspaces?.edges
      ?.map(ws => {
        if (ws?.node) {
          return {
            name: ws.node.name,
            globalId: ws.node.id,
            UUID: ws.node.uuid,
            keyPrefix: ws.node.keyPrefix,
            timezone: ws.node.timezone,
            cloudId: ws.node.cloudId ?? undefined,
            organisationId: ws.node.organisationId ?? undefined,
            goalScoringMode: ws.node.goalScoringMode as GoalScoringMode,
            numOfActiveWorkspaces: data.allWorkspaces?.edges?.length ?? 0,
            type: ws.node.type,
          };
        }
      })
      .filter(filterUndefined) ?? [];

  const isClassic = workspace.type === undefined ? !location.pathname.startsWith('/o/') : workspace.type === 'PRODUCT';
  const analytics = useAnalytics();

  const calculateRoute = (selectedWorkspace: WorkspaceDetailsForSwitcher, noWorkspaceExperience = false) => {
    if (selectedWorkspace.type === 'GLOBAL_EXPERIENCE') {
      const baseUrl = isClassic ? getConfig().homeUrl : '';
      return `${baseUrl}/o/${selectedWorkspace.organisationId}?cloudId=${selectedWorkspace.cloudId}`;
    }

    const baseUrl = isClassic ? '' : getConfig().classicUrl;
    const noWorkspaceParam = noWorkspaceExperience ? '&forceNoWorkspaceExperience=true' : '';

    // Navigate the user to an appropriate landing page after switching
    if (route.path.includes('/project/:id/')) {
      // If on a project -> navigate to the project directory
      return `${baseUrl}/projects?cloudId=${selectedWorkspace.cloudId}${noWorkspaceParam}`;
    } else if (route.path.includes('/goal/:id/')) {
      // If on a goal -> goal directory
      return `${baseUrl}/goals?cloudId=${selectedWorkspace.cloudId}${noWorkspaceParam}`;
    } else if (route.path.includes('/updates/tag/:id')) {
      // If on a tag -> home - tags are weird and site specific but under /updates
      return `${baseUrl}/?cloudId=${selectedWorkspace.cloudId}${noWorkspaceParam}`;
    } else {
      // All other cases, send them to the home page
      return `${baseUrl}/?cloudId=${selectedWorkspace.cloudId}${noWorkspaceParam}`;
    }
  };

  const onItemClick =
    (selectedWorkspace: WorkspaceDetailsForSwitcher, noWorkspaceExperience = false) =>
    (event: MouseEvent | KeyboardEvent) => {
      event.preventDefault();

      analytics.ui('devWorkspaceSwitcherItem', 'clicked', {
        workspaceUuid: selectedWorkspace.UUID,
        type: selectedWorkspace.type,
        noWorkspaceExperience,
      });

      redirect(calculateRoute(selectedWorkspace));
    };

  const homeWorkspaces = allWorkspaceOptions.filter(ws => ws.type === 'GLOBAL_EXPERIENCE');
  const classicWorkspaces = allWorkspaceOptions.filter(ws => ws.type === 'PRODUCT');
  const homeNoWorkspace = getEnv() === 'prod-east' ? [PROD_NO_WORKSPACE_ENV] : [STG_NO_WORKSPACE_ENV];
  const cloudId = getQueryParam('cloudId');
  const currentClassicWorkspace = isClassic ? classicWorkspaces.filter(ws => cloudId === ws.cloudId) : [];
  const classicNoWorkspaceExamples = currentClassicWorkspace.concat(classicWorkspaces);

  return (
    // Hack to position workspace switcher on the far right side of the nav
    <MenuGroup minWidth={192} maxWidth={480} maxHeight="80vh">
      <Section title="Classic workspaces">
        {classicWorkspaces.map(ws => (
          <SwitchWorkspaceItem
            href={calculateRoute(ws)}
            onItemClick={onItemClick(ws)}
            workspace={ws}
            key={ws.UUID}
            isSelected={ws.UUID === workspace.UUID && !noWorkspaceExperience}
          />
        ))}
      </Section>
      <Section title="Home workspaces">
        {homeWorkspaces.map(ws => (
          <SwitchWorkspaceItem
            href={calculateRoute(ws)}
            onItemClick={onItemClick(ws)}
            workspace={ws}
            key={ws.UUID}
            isSelected={ws.UUID === workspace.UUID && !noWorkspaceExperience}
          />
        ))}
      </Section>
      <Section title="No workspace">
        {homeNoWorkspace.map(ws => (
          <SwitchWorkspaceItem
            href={calculateRoute(ws)}
            onItemClick={onItemClick(ws, true)}
            workspace={ws}
            key={ws.UUID}
            isSelected={noWorkspaceExperience && orgId === ws.organisationId}
          />
        ))}
        {classicNoWorkspaceExamples.slice(0, 3).map(ws => {
          const innerWs = {
            ...ws,
            name: `No workspace classic: ${ws.name}`,
          };

          return (
            <SwitchWorkspaceItem
              href={calculateRoute(ws, true)}
              onItemClick={onItemClick(ws, true)}
              workspace={innerWs}
              key={ws.UUID}
              isSelected={noWorkspaceExperience && orgId === ws.organisationId && location.search.includes(ws.cloudId)}
            />
          );
        })}
      </Section>
    </MenuGroup>
  );
};

type DevWorkspaceSwitcherWrapperProps = {
  queryReference: PreloadedQuery<DevWorkspaceSwitcherQueryType> | null | undefined;
};

export const DevWorkspaceSwitcherWrapper = (props: DevWorkspaceSwitcherWrapperProps) => {
  return (
    <Suspense
      fallback={
        <MenuGroup minWidth={280}>
          <LoadingState />
        </MenuGroup>
      }
    >
      {props.queryReference && <DevWorkspaceSwitcher queryReference={props.queryReference} />}
    </Suspense>
  );
};
