import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import TeamHealthEmbedQuery, {
  TeamHealthEmbedQuery as TeamHealthEmbedQueryType,
} from './components/__generated__/TeamHealthEmbedQuery.graphql';
import { SESSION_UUID } from './constants';

export const teamHealthResource = createRelayResource<TeamHealthEmbedQueryType>({
  getQuery(routerContext, resourceContext) {
    const context = resourceContext as ResourceContext;

    const teamsTql = tqlQueryFromInput({
      input: [
        { fieldName: 'member', fieldValue: `'${context.user.accountId}'`, comparator: ComparatorOperator.EQUALS },
      ],
    });

    if (!context.workspace?.organisationId) {
      throw new Error('Organisation id is undefined, it is required to fetch the team health data');
    }

    if (!context.workspace?.cloudId) {
      throw new Error('Cloud id is undefined, it is required to fetch the team health data');
    }

    return {
      query: TeamHealthEmbedQuery,
      variables: {
        uuid: routerContext.match.params.teamHealthUuid ?? SESSION_UUID,
        hasMonitorStarted: Boolean(routerContext.match.params.teamHealthUuid),
        skipTeamsQuery: Boolean(routerContext.match.params.teamId),
        organisationId: context.workspace?.organisationId,
        cloudId: context.workspace?.cloudId,
        teamsTql,
      },
    };
  },
});
