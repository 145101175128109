import { FilterSelection } from './SearchBar/FilterResolvers';
import { PeopleFieldType } from './__generated__/StaffDirectoryViewQuery.graphql';

export enum ScreenType {
  BROWSE_ALL,
  SEARCH_ALL,
  SEARCH_PEOPLE,
  SEARCH_TEAMS,
  BROWSE_DEPARTMENTS,
  BROWSE_LOCATIONS,
  BROWSE_JOB_TITLES,
  BROWSE_KUDOS,
}

export const DEFAULT_SCREEN_TYPE = ScreenType.BROWSE_ALL;

export const PEOPLE_FIELD_SCREEN_TYPES = [
  ScreenType.BROWSE_DEPARTMENTS,
  ScreenType.BROWSE_JOB_TITLES,
  ScreenType.BROWSE_LOCATIONS,
];

export const getScreenFromStringOrDefault = (screenKey: string | undefined): ScreenType =>
  (screenKey && ScreenType[screenKey as keyof typeof ScreenType]) || DEFAULT_SCREEN_TYPE;

export const getPeopleFieldType = (screen: ScreenType): PeopleFieldType => {
  switch (screen) {
    case ScreenType.BROWSE_JOB_TITLES:
      return 'JOB_TITLE' as PeopleFieldType;
    case ScreenType.BROWSE_LOCATIONS:
      return 'LOCATION' as PeopleFieldType;
    default:
      return 'DEPARTMENT' as PeopleFieldType;
  }
};

export const SEARCH_ENABLED_SCREEN_TYPES: ScreenType[] = [
  ScreenType.BROWSE_ALL,
  ScreenType.SEARCH_ALL,
  ScreenType.SEARCH_PEOPLE,
  ScreenType.SEARCH_TEAMS,
  ScreenType.BROWSE_KUDOS,
];

// Restrict based on the screen, otherwise based on current filters
export const filterRestrictionForScreen = (
  currentFilterSelection: FilterSelection,
  currentScreen: ScreenType,
): FilterSelection => {
  switch (currentScreen) {
    case ScreenType.SEARCH_PEOPLE:
      return 'people';
    case ScreenType.SEARCH_TEAMS:
      return 'teams';
    case ScreenType.BROWSE_KUDOS:
      return 'kudos';
    default:
      return currentFilterSelection;
  }
};
