import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/display#box
 * The TL;DR - display: contents removes the box of the element.
 * It 'doesnt exist' to the browser, it will hoist its children up
 * allowing us to bring its children elements into our parent elements
 * styling algorithms (e.g. flex box wrapping)
 */

interface ReactionsWrapperProps {
  limitReactionsShown?: boolean;
  showOnlyOnHover?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const HoistReactionsWrapper = styled.div<ReactionsWrapperProps>(props => ({
  display: 'contents',
  // Force reactions wrapper to display its contents.
  '> div': {
    // Hides reactions by default to be shown only on hover. Showing on hover to be handled by parent component
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    display: props.showOnlyOnHover ? 'none' : 'contents',
    // Only show the first 3 reactions when props.limitReactionsShown is true
    '> :nth-child(1n + 4)': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      display: props.limitReactionsShown ? 'none' : 'block',
    },
    // Override reaction items margin to ensure they wrap correctly.
    '> button': {
      margin: `0 ${token('space.050')} 0 0`,
    },
    "[data-testid='render_reaction_wrapper']": {
      margin: 0,
    },
    "[data-testid='reactionPicker-testid']": {
      margin: 0,
    },
  },
}));
