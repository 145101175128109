import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import StayInLoopPopularProjectsQuery, {
  type StayInLoopPopularProjectsQuery as StayInLoopPopularProjectsQueryType,
} from '../ProjectUpdatesView/__generated__/StayInLoopPopularProjectsQuery.graphql';

export const stayInLoopResource = createRelayResource<StayInLoopPopularProjectsQueryType>({
  getQuery: (_, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const userId = context.user.accountId;

    const popularProjectsTql = tqlQueryFromInput({
      input: [
        { fieldName: 'watcher', fieldValue: userId, comparator: ComparatorOperator.NOT_EQUALS },
        { fieldName: 'phase', fieldValue: 'in_progress', comparator: ComparatorOperator.EQUALS },
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      ],
    });

    return {
      query: StayInLoopPopularProjectsQuery,
      variables: {
        workspaceUuid: context.workspace?.UUID,
        q: popularProjectsTql,
        aaidOrHash: userId,
      },
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
