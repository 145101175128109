import add from 'date-fns/add';
import endOfMonth from 'date-fns/endOfMonth';

import { tzWorkspaceTimezoneDate } from '@townsquare/date-utils';

const start = tzWorkspaceTimezoneDate();

export const TIMELINE_START = start.toISOString().split('T')[0];
export const TIMELINE_END = endOfMonth(add(start, { months: 12 }))
  .toISOString()
  .split('T')[0];
