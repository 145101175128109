import { createActionRoute, createSiteRoute } from '@townsquare/router/create-route';

import { ReactionUpdateAction } from './loadable';
import { reactionUpdateActionResource } from './resource';

export const reactionUpdateActionRoute = createSiteRoute(
  createActionRoute({
    component: ReactionUpdateAction,
    exact: true,
    name: 'reactionAction',
    path: '/update/:id/reaction',
    resources: [reactionUpdateActionResource],
  }),
);
