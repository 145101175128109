import { commitMutation, graphql, type Environment, type MutationConfig } from 'relay-runtime';

import { deleteDecisionInput, DeleteDecisionMutation } from './__generated__/DeleteDecisionMutation.graphql';

export const deleteDecision = (
  environment: Environment,
  input: deleteDecisionInput,
  connectionIds: string[],
  events: Pick<MutationConfig<DeleteDecisionMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<DeleteDecisionMutation>(environment, {
    mutation: graphql`
      mutation DeleteDecisionMutation($input: deleteDecisionInput!, $connections: [ID!]!) {
        deleteDecision(input: $input) {
          decision {
            id @deleteEdge(connections: $connections)
          }
        }
      }
    `,
    updater: store => {
      const decision = store.get(input.id);
      decision?.invalidateRecord();
    },
    variables: { input, connections: connectionIds },
    onCompleted: events.onCompleted,
    onError: events.onError,
  });
};
