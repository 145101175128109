import { ReactionsState, ReactionSummary } from '@atlaskit/reactions/dist/types/types';
import React from 'react';

import { ActionBar, ActionBarItem } from '@townsquare/action-bar';
import { Reactions, useReactionStore } from '@townsquare/reactions';

import * as Styled from './KudosReactions.styled';

export const KudosReactions = (props: { kudosAri: string }) => {
  const reactionAri = `${props.kudosAri}|${props.kudosAri}`;
  const reactionState = useReactionStore().getState();
  const ariState: ReactionsState = reactionState.reactions[reactionAri];
  const hasAnyReactionsYet = Boolean(
    ariState && ariState.status === 'READY' && ariState.reactions.find((r: ReactionSummary) => r.count > 0),
  );

  return (
    <>
      <Styled.KudosReactions>
        <ActionBar>
          <ActionBarItem
            onClick={e => {
              // Prevent clicks bubbling up and opening kudos unintentionally
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Reactions
              ari={props.kudosAri}
              containerAri={props.kudosAri}
              containerType="kudos"
              defaultReactionsEnabled={true}
              subsetReactions={true}
              showOnlyOnHover={!hasAnyReactionsYet}
            />
          </ActionBarItem>
        </ActionBar>
      </Styled.KudosReactions>
    </>
  );
};
