import { Stack, xcss } from '@atlaskit/primitives';
import React from 'react';

import { LoadingSkeleton } from '@townsquare/loading-skeleton';

import { ProfilePicBoundingBox, UserDetails } from './styles';

const loadingCardStyles = xcss({
  boxSizing: 'border-box',
  width: '100%',
  borderRadius: '5px',
});

export const BrowsePersonCardLoadingState = () => (
  <Stack xcss={loadingCardStyles}>
    <ProfilePicBoundingBox>
      <LoadingSkeleton width="100%" height="100%" />
    </ProfilePicBoundingBox>
    <UserDetails>
      <LoadingSkeleton width="100px" height="20px" marginTopPx={2} marginBottomPx={2} />
      <LoadingSkeleton width="160px" height="14px" marginTopPx={6} />
    </UserDetails>
  </Stack>
);
