import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-empty-styled-expression -- Ignored via go/DSP-18766
export const DescriptionEditor = styled.div({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Renderer = styled.div<{ mode?: 'normal' | 'light' }>(props => ({
  margin: token('space.negative.100'),
  padding: token('space.100'),
  borderRadius: '3px',
  p: {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: props.mode === 'light' ? token('color.text.subtlest') : undefined,
  },
  'p.placeholder': {
    color: token('color.text.subtlest'),
    wordBreak: 'break-word',
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const DescriptionTitleWrapper = styled.div<{ isCompact: boolean }>(props => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: token('space.100'),
  alignItems: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginBottom: props.isCompact ? token('space.150') : token('space.200'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginTop: props.isCompact ? token('space.200') : undefined,
  minHeight: '1.5rem',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AdaptedSpacingButton = styled.button({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  padding: `${token('space.025')} ${token('space.100')} ${token('space.025')} ${token('space.075')} !important`,
  margin: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> span:first-child': {
    marginRight: token('space.050'),
  },
});
