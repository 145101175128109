import { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';

import { getConfig } from '@townsquare/config';

const STAGING_API_KEY = 'd814e346-775a-4433-9cc2-bbf04f88c6a0'; // Watermelon LD project - Staging
const PROD_API_KEY = 'b4a2a380-5620-4085-8e89-80f9566c681c'; // Watermelon LD project - Production

const config = getConfig();
export const isStaging = () => config.env === 'local' || config.env === 'stg-east';
export const featureFlagEnv = isStaging() ? FeatureGateEnvironment.Staging : FeatureGateEnvironment.Production;
export const featureFlagApiKey = isStaging() ? STAGING_API_KEY : PROD_API_KEY;
