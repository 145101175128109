/**
 * @generated SignedSource<<45e200df662c59b761e6ec4aa6bdbcde>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type RelatedLearnings$data = {
  readonly relatedLearnings: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"RelatedLearning">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "RelatedLearnings";
};
export type RelatedLearnings$key = {
  readonly " $data"?: RelatedLearnings$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedLearnings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [
        "SUMMARY_ASC"
      ],
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "name": "RelatedLearnings",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 6
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type"
        }
      ],
      "concreteType": "LearningConnection",
      "kind": "LinkedField",
      "name": "relatedLearnings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "concreteType": "LearningEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "Learning",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "RelatedLearning"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Project"
};

(node as any).hash = "072f0c0e695b4c07c4c403f2e7075a04";

export default node;
