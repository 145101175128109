import ClockIcon from '@atlaskit/icon/core/clock';
import { token } from '@atlaskit/tokens';
import { captureException } from '@sentry/react';
import React, { Fragment, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useRelayEnvironment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { Content, Entity, Resolvers, SelectResolverOptions, SupportedFilters } from '@townsquare/tql/types';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { RecentlySearchedItem } from '../../../hooks/types';
import { staffDirectoryRoute } from '../../../route';
import { ScreenType } from '../../ScreenType';

import { PreviewRow } from './PreviewRow';
import { Label, Operator, RecentSearchPreview, ResultPrimaryText, Value } from './styles';

const findResolverByType = (type: SupportedFilters, resolvers: Resolvers) => {
  return resolvers.find(resolver => resolver.type === type);
};

const findLabelForValue = (value: Content, options: SelectResolverOptions) => {
  return options.find(item => item.value === value)?.label;
};

export const RecentSearchRow = (props: { item: RecentlySearchedItem; resolvers: Resolvers; isSelected: boolean }) => {
  const analytics = useAnalytics();
  const intl = useIntl();
  const [{ cloudId }] = useWorkspaceStore();
  const onClick = (e: MouseEvent | KeyboardEvent) => {
    void analytics.ui('staffDirectorySearchRecentSearchRow', 'clicked');
    e.stopPropagation();
  };

  const screen = props.item.screen === ScreenType.BROWSE_ALL ? ScreenType.SEARCH_ALL : props.item.screen;
  return (
    <PreviewRow
      route={staffDirectoryRoute}
      query={{ screen: ScreenType[screen], cloudId, tql: props.item.tql }}
      onClick={onClick}
      isSelected={props.isSelected}
    >
      <ClockIcon
        label={intl.formatMessage({
          id: 'townsquare.web.recent-search-row.recent-search-icon-label',
          description: 'Recent search icon label',
          defaultMessage: 'Recent search',
        })}
        LEGACY_size="large"
        LEGACY_primaryColor={token('color.icon.subtle')}
        color={token('color.icon.subtle')}
      />
      <ResultPrimaryText>
        <RecentSearchPreview>
          {props.item.searchText && (
            <FormattedMessage
              id="townsquare.web.recent-search-row.search-preview"
              description="Search preview"
              defaultMessage="<span><label>name</label> is<value>{searchText}</value></span> {hasFilters, select, true {<operator>and</operator>} other {}}{entities}"
              values={{
                hasFilters: props.item.filterDoc.model.length > 0,
                searchText: props.item.searchText,
                entities: props.item.filterDoc.model.map((entity, entityIndex, entities) => {
                  return (
                    <Fragment key={`filters-${entityIndex}`}>
                      <LabelAndValue entity={entity} resolvers={props.resolvers} />
                      {entityIndex !== entities.length - 1 && <Operator>{props.item.filterDoc.operator}</Operator>}
                    </Fragment>
                  );
                }),
                span: (text: string) => <span>{text}</span>,
                label: (text: string) => <Label>{text}</Label>,
                value: (text: string) => <Value>{text}</Value>,
                operator: (text: string) => <Operator>{text}</Operator>,
              }}
            />
          )}
        </RecentSearchPreview>
      </ResultPrimaryText>
    </PreviewRow>
  );
};

const LabelAndValue = ({ entity, resolvers }: { entity: Entity; resolvers: Resolvers }) => {
  const relayEnvironment = useRelayEnvironment();
  const [labels, setLabels] = useState<SelectResolverOptions>([]);
  const [isLoadingLabels, setLoadingLabels] = useState(true);
  const [{ globalId: workspaceGlobalId, UUID: workspaceUUID }] = useWorkspaceStore();
  const resolver = findResolverByType(entity.type, resolvers);

  useEffect(() => {
    if (resolver) {
      // Don't fetch labels we already have values for.
      // This array should only populate on initial load as we
      // try to optimistically store labels to reduce network traffic.
      const labelsToResolve = entity.model.filter(value => !labels.find(label => label.value === value));
      if (labelsToResolve.length) {
        setLoadingLabels(true);
        resolver
          .resolveLabels({
            values: labelsToResolve,
            workspaceId: workspaceGlobalId,
            relayEnvironment,
            workspaceUUID,
          })
          .then(data => {
            setLabels(prev => prev.concat(...data));
            setLoadingLabels(false);
          })
          .catch(reason => captureException(reason));
      }
    } else {
      setLoadingLabels(false);
      setLabels([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolver, entity.model]);

  if (!resolver) {
    return null;
  }

  return (
    <span>
      {entity.model.map((content, contentIndex, contents) => {
        const label = findLabelForValue(content.toString(), labels);
        return (
          <>
            {contentIndex === 0 && (
              <FormattedMessage
                id="townsquare.web.recent-search-row.label-is"
                description="Label is"
                defaultMessage="{label} is"
                values={{
                  label: <Label>{resolver.title}</Label>,
                }}
              />
            )}
            <Value>
              {isLoadingLabels && !label ? (
                <em>
                  <FormattedMessage
                    id="townsquare.web.recent-search-row.loading"
                    description="Loading"
                    defaultMessage="Loading..."
                  />
                </em>
              ) : (
                label ?? (
                  <FormattedMessage
                    id="townsquare.web.recent-search-row.not-found"
                    description="Not found"
                    defaultMessage="{resolverTitle} not found"
                    values={{
                      resolverTitle: resolver.title,
                    }}
                  />
                )
              )}
            </Value>
            {contentIndex !== contents.length - 1 && <Operator>{entity.operator}</Operator>}
          </>
        );
      })}
    </span>
  );
};
