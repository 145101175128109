import { formatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { parseIsoLocalDateTime } from './parse';
import timezoneHolder from './timezoneHolder';

export const parseToZonedDateTime = (dateTime: string, timezone: string) => {
  const date =
    typeof dateTime === 'object'
      ? Date.parse(String(dateTime))
      : Date.parse(dateTime && String(dateTime + '').endsWith('Z') ? dateTime : dateTime + 'Z');
  if (isNaN(date)) {
    return parseIsoLocalDateTime(dateTime, new Date(NaN));
  }
  return utcToZonedTime(date, timezone);
};

/**
 * Generally speaking, there are 2 ways to render dates when it comes to timezone
 *
 * 1. Use workspace timezone, eg, with calculating monday-sunday week-window boundary
 * 2. Use user timezone, eg, when formatting relative UTC date (aka "Updated X minutes ago")
 */
export const utcToUserTimezone = (dt: string) => parseToZonedDateTime(dt, timezoneHolder.getUserTimezone());
export const utcToWorkspaceTimezone = (dt: string) => parseToZonedDateTime(dt, timezoneHolder.getWorkspaceTimezone());

/**
 * Use user timezone for these
 */
export const formatUserRelativeDistance = (dateString: string | null, locale: Locale) =>
  dateString
    ? formatDistance(
        utcToUserTimezone(dateString),
        utcToZonedTime(Date.now(), timezoneHolder.getUserTimezone(), { locale }),
        {
          addSuffix: true,
          locale,
        },
      )
    : '-';

export const formatUserRelativeDistanceFromDay = (dateString: string, locale: Locale) => {
  if (!dateString) {
    return '-';
  }

  const startDate = new Date(dateString);
  startDate.setHours(0, 0, 0, 0);

  const userTimezone = timezoneHolder.getUserTimezone();
  const baseDate = utcToZonedTime(Date.now(), userTimezone, { locale });
  return formatDistance(startDate, baseDate, {
    addSuffix: true,
    locale,
  });
};
