/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import Icon from '@atlaskit/icon';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon';
import React from 'react';

import { StyledIcon } from './styles';

export const SirenGlyph = (props: CustomGlyphProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.2842 11.2047L12.1502 1.04144C12.0841 0.448389 11.5828 0 10.9863 0H2.9802C2.37937 0 1.87615 0.454222 1.8147 1.05156L0.776367 11.2047H13.2842Z"
      fill="#DD2E44"
    />
    <path
      d="M7.0271 9.08691C9.0387 9.08691 10.6694 7.45618 10.6694 5.44458C10.6694 3.43297 9.0387 1.80225 7.0271 1.80225C5.01549 1.80225 3.38477 3.43297 3.38477 5.44458C3.38477 7.45618 5.01549 9.08691 7.0271 9.08691Z"
      fill="#BE1931"
    />
    <path
      d="M13.8137 11.3477H0.186278L0 13.2221C0 13.9999 0.777778 13.9999 0.777778 13.9999H13.2222C13.2222 13.9999 14 13.9999 14 13.2221L13.8137 11.3477Z"
      fill="#99AAB5"
    />
    <path
      d="M13.8419 11.6322L13.8135 11.3476C13.7587 11.0606 13.5141 10.8887 13.222 10.8887H0.777604C0.485548 10.8887 0.240937 11.0606 0.186104 11.3476L0.157715 11.6322H13.8419Z"
      fill="#CCD6DD"
    />
    <path
      d="M11.5295 5.3006L8.54747 4.81527L10.3126 2.36216C10.3542 2.3046 10.348 2.22449 10.2975 2.17394C10.2465 2.12377 10.1668 2.11716 10.1092 2.15877L7.65575 3.92355L7.1708 0.941935C7.15952 0.871546 7.09808 0.819824 7.02691 0.819824C6.95575 0.819824 6.8943 0.871546 6.88302 0.941935L6.39769 3.92355L3.94458 2.15877C3.88663 2.11716 3.8073 2.12377 3.75636 2.17394C3.7058 2.22488 3.69958 2.30421 3.74119 2.36216L5.50597 4.81527L2.52397 5.3006C2.45358 5.31188 2.40186 5.37294 2.40186 5.44449C2.40186 5.51605 2.45358 5.5771 2.52397 5.58838L5.50597 6.07371L3.74119 8.52682C3.69958 8.58477 3.7058 8.66449 3.75636 8.71505C3.8073 8.76521 3.88663 8.77182 3.94458 8.73021L6.39769 6.96544L6.88302 9.94705C6.8943 10.0178 6.95575 10.0692 7.02691 10.0692C7.09808 10.0692 7.15952 10.017 7.1708 9.94705L7.65575 6.96544L10.1089 8.73021C10.1664 8.77144 10.2465 8.76521 10.2971 8.71505C10.3476 8.6641 10.3539 8.58477 10.3122 8.52682L8.54747 6.07371L11.5295 5.58838C11.6002 5.5771 11.6516 5.51566 11.6516 5.44449C11.6516 5.37332 11.6002 5.31188 11.5295 5.3006Z"
      fill="#EC9DAD"
    />
  </svg>
);

export const SirenIcon = (props: GlyphProps) => (
  <StyledIcon>
    <Icon glyph={SirenGlyph} size="small" {...props} />
  </StyledIcon>
);
