import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { ApolloError } from 'apollo-client';

// Left: PascalCase - Right: camelCase
export enum CapabilitySubject {
  // Users can view directories: https://tome.prod.atl-paas.net/capability/7bfb7463-655a-4475-b93a-790a7cefa6d4
  ProjectDirectoryViewed = 'projectDirectoryViewed',
  GoalDirectoryViewed = 'goalDirectoryViewed',
  HelpLinkDirectoryViewed = 'helpLinkDirectoryViewed',
  TopicDirectoryViewed = 'topicDirectoryViewed', // Explore
  StaffDirectoryViewed = 'staffDirectoryViewed',
  TopicPageViewed = 'topicPageViewed', // Explore
  // Users can view entities: https://tome.prod.atl-paas.net/capability/ecec3a20-a466-432b-bb6f-9ebdcbf87560
  ProjectAboutPageViewed = 'projectAboutPageViewed',
  ProjectUpdatesPageViewed = 'projectUpdatesPageViewed',
  ProjectLearningsPageViwed = 'projectLearningsPageViewed',
  GoalAboutPageViewed = 'goalAboutPageViewed',
  GoalUpdatesPageViewed = 'goalUpdatesPageViewed',
  GoalProjectsPageViewed = 'goalProjectsPageViewed',
  GoalJiraPageViewed = 'goalJiraPageViewed',
  GoalLearningsPageViwed = 'goalLearningsPageViewed',
  GoalRisksPageViwed = 'goalRisksPageViewed',
  GoalDecisionsPageViwed = 'goalDecisionsPageViewed',
  ReadingModeViewed = 'readingModeViewed',
  PeopleMenuViewed = 'peopleMenuViewed',
  TopicsProjectAndGoalsPageViewed = 'topicsProjectAndGoalsPageViewed',
  TopicsHelpAndKnowledgePageViewed = 'topicsHelpAndKnowledgePageViewed',
  TopicsUpdatesPageViewed = 'topicsUpdatesPageViewed',
  // Users can view misc pages: https://tome.prod.atl-paas.net/capability/44e8c177-c1d7-4cd4-a40f-289da3f10e20
  PersonalSettingsPageViewed = 'personalSettingsPageViewed',
  WorkspaceSettingsPageViewed = 'workspaceSettingsPageViewed',
  FieldSettingsPageViewed = 'fieldSettingsPageViewed',
  // User can view an updates feed
  TagUpdatesFeedViewed = 'tagUpdatesFeedViewed',
  TeamUpdatesFeedViewed = 'teamUpdatesFeedViewed',
  SavedViewUpdatesViewed = 'savedViewUpdatesViewed',
  // User can filter project updates feed https://tome.prod.atl-paas.net/capability/7c762d70-3796-44c9-af27-d3ef2b833363
  ProjectUpdatesFeedViewed = 'projectUpdatesFeedViewed',
  // User can filter goal updates feed https://tome.prod.atl-paas.net/capability/ab8bd6bb-597d-4b29-bdb8-6c540def1831
  GoalUpdatesFeedViewed = 'goalUpdatesFeedViewed',
  // User can post an update https://tome.prod.atl-paas.net/capability/ed06b21a-867a-4036-9dbd-9f85958d3814
  ProjectUpdatePosted = 'projectUpdatePosted',
  GoalUpdatePosted = 'goalUpdatePosted',
  // User can create entities https://tome.prod.atl-paas.net/capability/0e8b80f3-2b94-4bc8-b27f-19afd5ae6b83
  ProjectCreated = 'projectCreated',
  GoalCreated = 'goalCreated',
  TagCreated = 'tagCreated',
  HelpLinkCreated = 'helpLinkCreated',
  TeamCreated = 'teamCreated',
  DirectoryViewCreated = 'directoryViewCreated',
  CustomFieldCreated = 'customFieldCreated',
  // User can post a comment TODO: add link to post a comment SLO
  ProjectCommentPosted = 'projectCommentPosted',
  ProjectUpdateCommentPosted = 'projectUpdateCommentPosted',
  GoalCommentPosted = 'goalCommentPosted',
  GoalUpdateCommentPosted = 'goalUpdateCommentPosted',
  AnswerCommentPosted = 'answerCommentPosted',
  // User can follow an entity https://tome.prod.atl-paas.net/capability/4c447a0b-e1aa-49f6-9f5e-8df3cb604f81
  ProjectFollowed = 'projectFollowed',
  GoalFollowed = 'goalFollowed',
  TagFollowed = 'tagFollowed',
  TeamFollowed = 'teamFollowed',
  // User can mutate entity metadata https://tome.prod.atl-paas.net/capability/6eacb6f7-a8b7-45de-84f8-0e3b27ae20a1
  MutateProjectContributors = 'mutateProjectContributors',
  MutateProjectMetadata = 'mutateProjectMetadata',
  MutateProjectMilestones = 'mutateProjectMilestones',
  // Users can sync with the Jira integration
  SyncJiraIssueFromAtlasProject = 'syncJiraIssueFromAtlasProject',
  // User can onboard onto Atlas https://tome.prod.atl-paas.net/capability/ea0bc96b-a98d-40bb-9c86-3609dca45310/history
  OnboardingWorkspacePageViewed = 'onboardingWorkspacePageViewed',
  ApprovedDomainsMutated = 'approvedDomainsMutated',
  // User can share entities = https://tome.prod.atl-paas.net/capability/d7b4096e-8522-4d5c-acb8-3435fc6f8a45
  ProjectShared = 'projectShared',
  GoalShared = 'goalShared',
  ProjectShareModalViewed = 'projectShareModalViewed',
  GoalShareModalViewed = 'goalShareModalViewed',
  // User can view team health monitor
  TeamHealthMonitorEmbedViewed = 'teamHealthMonitorEmbedViewed',
  // User can mutate custom field = https://tome.prod.atl-paas.net/capability/a5c05a15-738d-4904-99d1-2590d0373efe
  MutateTextCustomField = 'mutateTextCustomField',
  MutateNumberCustomField = 'mutateNumberCustomField',
  MutateSelectCustomField = 'mutateSelectCustomField',
  MutateUserCustomField = 'mutateUserCustomField',
  // No workspace
  NoWorkspaceSearchViewed = 'noWorkspaceSearchViewed',
  NoWorkspaceTeamsDirectoryViewed = 'noWorkspaceTeamsDirectoryViewed',
  NoWorkspaceProjectsDirectoryViewed = 'noWorkspaceProjectsDirectoryViewed',
  NoWorkspaceGoalsDirectoryViewed = 'noWorkspaceGoalsDirectoryViewed',
  NoWorkspaceStatusUpdatesPageViewed = 'noWorkspaceStatusUpdatesPageViewed',
  NoWorkspaceHomeViewViewed = 'noWorkspaceHomeViewViewed',
}

export const isCapabilitySubject = (capabilitySubjectId: string): capabilitySubjectId is CapabilitySubject =>
  Object.values<string>(CapabilitySubject).includes(capabilitySubjectId);

/**
 * @see https://developer.atlassian.com/platform/ufo/developer-guide/experience-types/#common-pairings
 */
export enum ExperienceConfigPreset {
  LoadPageLoad,
  LoadPageSegmentLoad,
  ExperienceInlineResult,
  OperationCustom,
}

export const configPresets: Record<ExperienceConfigPreset, UFOExperience['config']> = {
  [ExperienceConfigPreset.LoadPageLoad]: {
    type: ExperienceTypes.Load,
    performanceType: ExperiencePerformanceTypes.PageLoad,
  },
  [ExperienceConfigPreset.LoadPageSegmentLoad]: {
    type: ExperienceTypes.Load,
    performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
  },
  [ExperienceConfigPreset.ExperienceInlineResult]: {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
  },
  [ExperienceConfigPreset.OperationCustom]: {
    type: ExperienceTypes.Operation,
    performanceType: ExperiencePerformanceTypes.Custom,
  },
};

export type ExperienceTrackerProps = {
  id: CapabilitySubject;
  loading: boolean | undefined;
  error: ApolloError | Error | undefined;
};

export type ExperienceFailureProps = {
  experience: UFOExperience;
  error: ApolloError | Error | undefined;
};
