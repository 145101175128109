import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: token('space.500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MessageContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: token('elevation.surface.sunken'),
  borderRadius: '3px',
  padding: `${token('space.100')} ${token('space.100')} ${token('space.100')}`,
  marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: token('space.100'),
  marginBottom: token('space.150'),
  padding: 0,
  flexFlow: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const SummaryItem = styled.button<{ color?: string; isSelected?: boolean; isDisabled?: boolean }>(props => ({
  padding: `${token('space.150')} ${token('space.100')} ${token('space.150')} ${token('space.100')}`,
  margin: '0px',
  flex: '1 1 100px',
  minWidth: 'max(140px, 30%)',
  display: 'flex',
  boxShadow: 'none',
  border: `1px solid ${token('color.border')}`,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  opacity: props.isSelected ? '1' : '0.5',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  cursor: props.isDisabled ? 'default' : 'pointer',
  backgroundColor: token('elevation.surface.raised'),
  textAlign: 'left',
  borderRadius: '3px',
  '&:hover': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    boxShadow: props.isDisabled ? undefined : token('elevation.shadow.raised'),
  },
  h2: {
    font: token('font.heading.xlarge'),
    marginTop: '0px',
    marginRight: token('space.150'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: props.color,
  },
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummarySectionSkeleton = styled.section({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: token('space.200'),
  width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummarySkeletonRow = styled.section({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: token('space.100'),
  width: '100%',
  gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitlesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});
