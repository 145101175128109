/**
 * @generated SignedSource<<199cb2a19a35301c3ec5411d8be246d0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BrowseKudosSection$data = {
  readonly kudos: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"KudosContent">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "BrowseKudosSection";
};
export type BrowseKudosSection$key = {
  readonly " $data"?: BrowseKudosSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowseKudosSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 6,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspaceId"
    }
  ],
  "kind": "Fragment",
  "name": "BrowseKudosSection",
  "selections": [
    {
      "alias": "kudos",
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "q",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "workspaceId",
          "variableName": "workspaceId"
        }
      ],
      "concreteType": "KudosConnection",
      "kind": "LinkedField",
      "name": "kudosTql",
      "plural": false,
      "selections": [
        {
          "concreteType": "KudosEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "Kudos",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "KudosContent"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "5b551bd1c32827473a7c3efb003cc5e6";

export default node;
