import { PrimaryButton, useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { CustomItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl-next';
import { useRouter } from 'react-resource-router';
import styled from 'styled-components';

import { useAnalytics } from '@townsquare/analytics';
import { getUserAccessUrlForRedirectWithContinueUrl } from '@townsquare/auth-utils';
import { redirect } from '@townsquare/facade';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { StaticRouterLinkItem } from '../RouterLinkItem';

export type DisabledNavKey = 'projects' | 'teams' | 'goals' | 'topics';

export type NoWorkspaceNavItemType = {
  label: MessageDescriptor;
  path: string;
  navKey: DisabledNavKey;
  matches: string[];
};

export const NoWorkspaceNavItem = (config: NoWorkspaceNavItemType) => {
  const [routerState, { push }] = useRouter();
  const [{ joinableWorkspaceIdentifier }] = useWorkspaceStore();
  const { isVisible: isHorizontalVisible, closeOverflowMenu } = useOverflowStatus();
  const analytics = useAnalytics();

  const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    void analytics.ui('navMenuItem', 'clicked', undefined, `${config.navKey}NoWorkspace`);

    if (joinableWorkspaceIdentifier) {
      redirect(getUserAccessUrlForRedirectWithContinueUrl(joinableWorkspaceIdentifier, config.path));
      return;
    }

    push(config.path);
    closeOverflowMenu();
  };

  const isSelected = config.matches.includes(routerState.match.path) ?? false;

  return (
    <>
      {isHorizontalVisible ? (
        <NavButtonContainer isSelected={isSelected}>
          <PrimaryButton href={config.path} onClick={handleClick}>
            <FormattedMessage {...config.label} />
          </PrimaryButton>
          {isSelected && <IsHighlightedBar />}
        </NavButtonContainer>
      ) : (
        <CustomItem
          component={StaticRouterLinkItem}
          href={config.path}
          onClick={handleClick}
          // eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
          cssFn={() => {
            return {
              padding: `${token('space.050')} ${token('space.200')}`,
            };
          }}
        >
          <FormattedMessage {...config.label} />
        </CustomItem>
      )}
    </>
  );
};

const IsHighlightedBar = styled.div({
  position: 'absolute',
  bottom: 0,
  left: token('space.050'),
  right: token('space.050'),
  height: '3px',
  backgroundColor: token('color.border.selected'),
  borderTopLeftRadius: '1px',
  borderTopRightRadius: '1px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const NavButtonContainer = styled.div<{ isSelected: boolean }>(({ isSelected }) => ({
  display: 'flex',
  gap: token('space.025'),
  position: 'relative',
  'a, button': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      isSelected ? token('color.text.selected') : token('color.text.subtle')
    } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
  },
  'a:hover': {
    textDecoration: 'none',
  },
  '&:hover a, &:hover button': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundColor: isSelected ? token('color.background.selected') : token('color.background.neutral'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      isSelected ? token('color.text.selected') : token('color.text.subtle')
    } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
    '&:hover': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      backgroundColor: isSelected
        ? token('color.background.selected.hovered')
        : token('color.background.neutral.hovered'),
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      color: `${
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        isSelected ? token('color.text.selected') : token('color.text.subtle')
      } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
    },
    '&:active': {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      backgroundColor: `${
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        isSelected ? token('color.background.selected.pressed') : token('color.background.neutral.pressed')
      } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
      color: `${
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        isSelected ? token('color.text.selected') : token('color.text.subtle')
      } !important` /* need important here to override :link and :visited styles from @atlaskit/button */,
    },
  },
}));
