import Loadable from 'react-loadable';

export const ProjectDirectory = Loadable({
  loader: () => import(/* webpackChunkName: "RelayProjectDirectory" */ './components/ProjectDirectory'),
  loading: () => null,
});

export { projectDirectoryResource } from './resources/project-directory';
export { projectDirectoryViewResource, getProjectDirectoryViewResourceQuery } from './resources/project-directory-view';
export { ExportProjectCsv } from './components/ExportProjectCsv';
export { ProjectsEmptyOrNonProvisionedState } from './components/EmptyOrNonProvisionedState';
