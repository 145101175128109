import { UpdateStateFilter as GoalUpdateStateFilter } from '../../GoalUpdatesView/__generated__/GoalUpdatesViewQuery.graphql';
import { UpdateStateFilter as ProjectUpdateStateFilter } from '../../ProjectUpdatesView/__generated__/ProjectUpdatesViewQuery.graphql';

export type UpdateStateFilter = GoalUpdateStateFilter | ProjectUpdateStateFilter;

export enum UpdateQueryParamFilter {
  ALL = 'active',
  ON_TRACK = 'onTrack',
  AT_RISK = 'atRisk',
  OFF_TRACK = 'offTrack',
  MISSED = 'missed',
  DONE = 'done',
  CANCELLED = 'cancelled',
}

const updateQueryParamToStateFilter: Record<UpdateQueryParamFilter, UpdateStateFilter> = {
  active: 'ACTIVE',
  onTrack: 'ON_TRACK',
  atRisk: 'AT_RISK',
  offTrack: 'OFF_TRACK',
  missed: 'NO_UPDATE',
  done: 'DONE',
  cancelled: 'CANCELLED',
};

export const getValidUpdateQueryParamFilter = (filter?: string): UpdateQueryParamFilter => {
  if (!!filter && Object.values(UpdateQueryParamFilter).findIndex(s => s === filter) !== -1) {
    return filter as UpdateQueryParamFilter;
  }
  return UpdateQueryParamFilter.ALL;
};

export const convertQueryParamToUpdateStateFilter = (filter: UpdateQueryParamFilter): UpdateStateFilter => {
  return updateQueryParamToStateFilter[filter];
};

export const getUpdateStateFilterFromQueryParams = (filter: string): UpdateStateFilter => {
  if (Object.values(UpdateQueryParamFilter).findIndex(queryParamFilter => queryParamFilter === filter) !== -1) {
    return updateQueryParamToStateFilter[filter as UpdateQueryParamFilter];
  }
  return 'ACTIVE';
};
