import React, { useEffect, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { stripTeamARI } from '@townsquare/ari-utils';
import { useOnMount } from '@townsquare/hooks';
import { ReactSetStateFn } from '@townsquare/react-state';
import { filterNull } from '@townsquare/type-helpers';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { appendEmptyTeamSearchToTql } from '../../../resources/utils';
import { SearchResultTeamMember } from '../../Cards/SearchResult/Team';

import { EmptyState } from './EmptyState';
import { PeopleResults } from './PeopleResults';
import { getSearchAllUrl, SearchAllRow, SEARCH_ALL_ROW_ID } from './SearchAllRow';
import { TeamResults } from './TeamResults';
import { SearchPreviewTeamSearchQuery } from './__generated__/SearchPreviewTeamSearchQuery.graphql';
import { SearchPreviewUserSearchQuery } from './__generated__/SearchPreviewUserSearchQuery.graphql';
import { SearchResultsPreviewContainer } from './styles';
import { ResultNavigationOptions } from './types';

const getUrlForSelectedItem = (selectedId: string, userIds: string[], teamIds: string[]) => {
  if (userIds.includes(selectedId)) {
    return `/people/${selectedId}`;
  } else if (teamIds.includes(selectedId)) {
    return `/people/team/${selectedId}`;
  }
  return undefined;
};

export interface SearchPreviewProps {
  searchPreviewTql: string;
  searchText?: string;
  selectedIndex: number;
  setSelectedIndex: ReactSetStateFn<number>;
  shouldNavigateToResult: ResultNavigationOptions;
  setShouldNavigateToResult: ReactSetStateFn<ResultNavigationOptions>;
  closeSearchPreview: () => void;
}

export const SearchPreview = ({
  searchPreviewTql,
  searchText,
  selectedIndex,
  setSelectedIndex,
  shouldNavigateToResult,
  setShouldNavigateToResult,
  closeSearchPreview,
}: SearchPreviewProps) => {
  const [{ query, location }, routerActions] = useRouter();
  const [{ globalId: workspaceId, organisationId, cloudId }] = useWorkspaceStore();

  const analytics = useAnalytics();
  useOnMount(() => {
    void analytics.ui('staffDirectorySearchPreview', 'shown');
  });

  const usersData = useLazyLoadQuery<SearchPreviewUserSearchQuery>(
    graphql`
      query SearchPreviewUserSearchQuery(
        $query: String!
        $first: Int!
        $sort: [DirectorySortEnum]!
        $profilePicSize: ProfilePictureSize!
        $workspaceId: ID!
        $organisationId: String!
        $cloudId: String!
        $after: String
      ) {
        peopleTql(
          q: $query
          first: $first
          after: $after
          sort: $sort
          organisationId: $organisationId
          cloudId: $cloudId
        ) {
          edges {
            node {
              name
              account_id
              job_title
              location
              highResolutionProfilePicture(size: $profilePicSize) {
                url
              }
              zoneinfo
              teams(workspaceId: $workspaceId, organisationId: $organisationId) {
                edges {
                  node {
                    name
                    teamId
                  }
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    {
      query: searchPreviewTql,
      sort: [],
      profilePicSize: 'MEDIUM',
      first: 5,
      workspaceId,
      organisationId,
      cloudId,
    },
  );

  const users = useMemo(
    () =>
      usersData?.peopleTql?.edges
        ?.map(user => {
          if (user && user.node && user.node.name != null && user.node.account_id != null) {
            const { name, account_id, job_title, location, highResolutionProfilePicture, zoneinfo, teams } = user.node;
            const unwrappedTeams =
              teams?.edges
                ?.map(edge => edge?.node)
                .filter(filterNull)
                .filter(team => team.name != null) ?? [];
            return {
              name: name,
              aaid: account_id,
              jobTitle: job_title ?? undefined,
              location: location ?? undefined,
              highResolutionProfilePicture: highResolutionProfilePicture?.url ?? undefined,
              zoneinfo: zoneinfo ?? undefined,
              teams: unwrappedTeams,
            };
          }
          return null;
        })
        .filter(filterNull) ?? [],
    [usersData?.peopleTql],
  );

  const teamsData = useLazyLoadQuery<SearchPreviewTeamSearchQuery>(
    graphql`
      query SearchPreviewTeamSearchQuery(
        $query: String!
        $first: Int
        $sort: [DirectoryTeamSortEnum]
        $organisationId: String!
        $cloudId: String!
        $after: String
      ) {
        teamsTql(
          q: $query
          first: $first
          sort: $sort
          after: $after
          organisationId: $organisationId
          cloudId: $cloudId
        ) {
          count
          edges {
            node {
              id
              displayName
              description
              members {
                count
                edges {
                  node {
                    aaid
                    pii {
                      name
                      picture
                    }
                  }
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    {
      query: appendEmptyTeamSearchToTql(searchPreviewTql),
      sort: ['NAME_ASC'],
      first: 3,
      organisationId,
      cloudId,
    },
  );

  const teams = useMemo(
    () =>
      teamsData?.teamsTql?.edges
        ?.map(team => {
          if (team && team.node && team.node.displayName != null && team.node.id != null) {
            const { displayName, id: teamAri, description, members } = team.node;
            return {
              name: displayName,
              teamId: stripTeamARI(teamAri),
              description: description ?? undefined,
              memberCount: members?.count ?? 0,
              members:
                members?.edges
                  ?.filter(filterNull)
                  .map<SearchResultTeamMember | null>(({ node }) => {
                    if (node) {
                      const { aaid, pii } = node;

                      if (!aaid || !pii || !pii.name) {
                        return null;
                      }

                      return {
                        aaid,
                        name: pii.name,
                        picture: pii.picture ?? undefined,
                      };
                    }

                    return null;
                  })
                  .filter(filterNull) ?? [],
            };
          }
          return null;
        })
        .filter(filterNull) ?? [],
    [teamsData?.teamsTql],
  );

  const canShowUsers = users.length > 0;
  const canShowTeams = teams.length > 0;
  const hasSomeResults = canShowUsers || canShowTeams;

  const userIds = useMemo(() => (canShowUsers ? users.map(({ aaid }) => aaid) : []), [canShowUsers, users]);
  const teamIds = useMemo(() => (canShowTeams ? teams.map(({ teamId }) => teamId) : []), [canShowTeams, teams]);
  const idList = useMemo(
    () => (hasSomeResults ? [SEARCH_ALL_ROW_ID, ...userIds, ...teamIds] : [...userIds, ...teamIds]),
    [userIds, teamIds, hasSomeResults],
  );
  const selectedId = idList[selectedIndex];

  useEffect(() => {
    setSelectedIndex(0);
  }, [searchPreviewTql, setSelectedIndex]);

  useEffect(() => {
    // constrain to a value that can index the list of IDs that can be selected (min: 0, max: array length - 1)
    const validatedIndex = selectedIndex < 0 ? idList.length - 1 : selectedIndex >= idList.length ? 0 : selectedIndex;

    if (selectedIndex !== validatedIndex) {
      setSelectedIndex(validatedIndex);
    }
  }, [idList.length, selectedIndex, setSelectedIndex]);

  useEffect(() => {
    if (shouldNavigateToResult === ResultNavigationOptions.NOT_NAVIGATING) {
      return;
    } else {
      setShouldNavigateToResult(ResultNavigationOptions.NOT_NAVIGATING);

      const url =
        selectedId === SEARCH_ALL_ROW_ID
          ? getSearchAllUrl(location.pathname, query, searchPreviewTql)
          : getUrlForSelectedItem(selectedId, userIds, teamIds);

      if (selectedId === SEARCH_ALL_ROW_ID && shouldNavigateToResult === ResultNavigationOptions.NAVIGATE_SAME_TAB) {
        closeSearchPreview();
      }

      if (url) {
        if (shouldNavigateToResult === ResultNavigationOptions.NAVIGATE_SAME_TAB) {
          void routerActions.push(url);
        } else if (shouldNavigateToResult === ResultNavigationOptions.NAVIGATE_NEW_TAB) {
          open(url, '_blank', 'noreferrer noopener');
        }
      }
    }
  }, [
    closeSearchPreview,
    location.pathname,
    query,
    routerActions,
    searchPreviewTql,
    selectedId,
    setShouldNavigateToResult,
    shouldNavigateToResult,
    teamIds,
    userIds,
  ]);

  return (
    <SearchResultsPreviewContainer data-testid="staff-dir-search-preview">
      {hasSomeResults && (
        <SearchAllRow
          isSelected={SEARCH_ALL_ROW_ID === selectedId}
          searchText={searchText}
          onRowClicked={e => {
            e?.stopPropagation();
            if (!e?.metaKey) {
              closeSearchPreview();
            }
          }}
          tql={searchPreviewTql}
        />
      )}
      {canShowUsers ? (
        <PeopleResults data-testid="staff-dir-search-preview-people" users={users} selectedId={selectedId} />
      ) : null}
      {canShowTeams ? (
        <TeamResults data-testid="staff-dir-search-preview-teams" teams={teams} selectedId={selectedId} />
      ) : null}
      {!hasSomeResults ? <EmptyState searchText={searchText} /> : null}
    </SearchResultsPreviewContainer>
  );
};
