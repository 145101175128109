import React from 'react';

import { useModalActions } from '@townsquare/modals';

import { AddPeopleBenefitsModal } from './components/AddPeopleBenefitsModal';
import { AddPeopleBenefitsModalOptions } from './types';

export const useAddPeopleBenefitsModal = () => {
  const { openModal, closeModal } = useModalActions();

  const openAddPeopleBenefitsModal = (options: AddPeopleBenefitsModalOptions) => {
    openModal('add-people-benefits-modal', {
      component: <AddPeopleBenefitsModal {...options} onClose={() => closeModal('add-people-benefits-modal')} />,
    });
  };

  return {
    openAddPeopleBenefitsModal,
  };
};
