import AddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import Popup, { TriggerProps } from '@atlaskit/popup';
import { ContentProps } from '@atlaskit/popup/types';
import { Box, xcss } from '@atlaskit/primitives';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useQueryLoader } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { CreateProjectDropdown, useCreateProjectStore } from '@townsquare/create-project';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceUUID } from '@townsquare/workspace-store';

import { getNewProjectUpdatesTql, NewMenuDropdownWrapper } from './NewMenuDropdown';
import { WriteUpdateForQuery } from './WriteUpdateForSection';
import { WriteUpdateForSectionQuery } from './__generated__/WriteUpdateForSectionQuery.graphql';
import { NewDropdown, NewTruncatedButton } from './styles';

const buttonContainerStyle = xcss({
  flexShrink: 0,
  alignSelf: 'center',
});

export const NewButton = () => {
  const intl = useIntl();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [workspaceUuid] = useWorkspaceUUID();
  const [queryReference, loadQuery] = useQueryLoader<WriteUpdateForSectionQuery>(WriteUpdateForQuery);
  const [user] = useUserStore();

  const analytics = useAnalytics();
  const [{ route }] = useRouter();

  const [createProjectOptions, { closeCreateProject }] = useCreateProjectStore();

  const loadQueryCallback = useCallback(() => {
    loadQuery(
      {
        workspaceUuid,
        q: getNewProjectUpdatesTql(user.accountId),
      },
      { fetchPolicy: 'store-and-network' },
    );
  }, [loadQuery, user.accountId, workspaceUuid]);

  // Preloading query in case of opening new project modal without "new" menu
  useEffect(() => {
    if (createProjectOptions.open && !createProjectOptions.options?.skipQueryLoad) {
      loadQueryCallback();
    }
  }, [createProjectOptions.open, createProjectOptions.options?.skipQueryLoad, loadQueryCallback]);

  if (createProjectOptions.open && createProjectOptions.open !== dropdownOpen) {
    setDropdownOpen(createProjectOptions.open);
  }

  const onNewButtonClick = useCallback(() => {
    void analytics.ui('topNavNewButton', 'clicked', {
      route: route.name,
    });

    CreateProjectDropdown.preload();

    if (!dropdownOpen) {
      loadQueryCallback();
    }

    setDropdownOpen(prevState => {
      if (createProjectOptions.open) {
        closeCreateProject();
        return false;
      } else if (prevState) {
        return false;
      } else {
        return true;
      }
    });
  }, [analytics, closeCreateProject, createProjectOptions.open, dropdownOpen, loadQueryCallback, route.name]);

  const onNewMenuClose = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  const onPopupClose = useCallback(() => {
    // remove action when closing dirty create project modal
    unstable_batchedUpdates(() => {
      setDropdownOpen(false);
      if (createProjectOptions.open) {
        closeCreateProject();
      }
    });
  }, [closeCreateProject, createProjectOptions.open]);

  const renderDropdownMenu = useCallback(
    (props: ContentProps) => (
      <NewMenuDropdownWrapper
        onCloseNewMenu={onNewMenuClose}
        queryReference={queryReference}
        updatePopup={props.update}
      />
    ),
    [onNewMenuClose, queryReference],
  );

  const renderNavButton = useCallback(
    ({ ref, ...rest }: TriggerProps) => {
      return (
        <Box ref={ref as RefObject<HTMLDivElement>} xcss={buttonContainerStyle}>
          <NewDropdown
            {...rest}
            aria-label={intl.formatMessage({
              id: 'townsquare.navigation.new-button.label',
              description: 'Label for the new button',
              defaultMessage: 'Toggle create menu',
            })}
            testId="new-button"
            onClick={onNewButtonClick}
            id="create-new-dropdown"
          >
            <FormattedMessage
              id="townsquare.navigation.new-button.text"
              description="Label for the new button"
              defaultMessage="Create"
            />
          </NewDropdown>
          <NewTruncatedButton
            {...rest}
            aria-label={intl.formatMessage({
              id: 'townsquare.navigation.new-create-button.label',
              description: 'Label for the new button',
              defaultMessage: 'Toggle create menu',
            })}
            onClick={onNewButtonClick}
            iconBefore={<AddIcon color="currentColor" spacing="spacious" label="" />}
            appearance="primary"
          />
        </Box>
      );
    },
    [intl, onNewButtonClick],
  );

  return (
    <Popup
      placement="bottom-start"
      content={renderDropdownMenu}
      isOpen={dropdownOpen || createProjectOptions.open}
      onClose={onPopupClose}
      trigger={renderNavButton}
      testId="new-menu"
      autoFocus={!createProjectOptions.open}
    />
  );
};

export default NewButton;
