import { GasPurePayload, GasPureScreenEventPayload } from '@atlaskit/analytics-gas-types';
import { AnalyticsWebClient as AnalyticsWebClientListener } from '@atlaskit/analytics-listeners/types';
import AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import { useMemo } from 'react';

import { useAnalytics } from './use-analytics';

/**
 * An adapter layer to translate between "@atlaskit/analytics-listeners" and
 * "@atlassiansox/analytics-web-client" due to discrepancy between the defined types
 */
export const useAtlasKitAnalytics = (): AnalyticsWebClientListener => {
  const analytics = useAnalytics();
  const analyticsWebClient = analytics.getAnalyticsWebClient() as AnalyticsWebClient;

  return useMemo(
    () => ({
      sendUIEvent: (event: GasPurePayload) => {
        return analyticsWebClient.sendUIEvent({
          ...event,
          action: event.action || '',
          source: event.source || analytics.getDefaultSource(),
        });
      },
      sendOperationalEvent: (event: GasPurePayload) => {
        return analyticsWebClient.sendOperationalEvent({
          ...event,
          action: event.action || '',
          source: event.source || analytics.getDefaultSource(),
        });
      },
      sendTrackEvent: (event: GasPurePayload) => {
        return analyticsWebClient.sendTrackEvent({
          ...event,
          action: event.action || '',
          source: event.source || analytics.getDefaultSource(),
        });
      },
      sendScreenEvent: (event: GasPureScreenEventPayload) => {
        return analyticsWebClient.sendScreenEvent(event);
      },
    }),
    [analytics, analyticsWebClient],
  );
};
