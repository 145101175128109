import Textfield from '@atlaskit/textfield';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { getConfig } from '@townsquare/config';
import { CopyLinkIcon } from '@townsquare/copy-link-icon';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { projectRoute } from '@townsquare/project-view/route';
import { generatePath, useRouteDependencies } from '@townsquare/router/primitives';

import { EditDropDown } from './EditDropDown';
import {
  CopyLinkWrapper,
  LearningTitleDisplayWrapper,
  LearningTitleLeftSideWrapper,
  LearningTitlePlaceholder,
} from './styles';
import { LearningEntityType, LearningType } from './util';

interface LearningTitleProps {
  entityType: LearningEntityType;
  entityKey?: string;
  entityId?: string;
  entityAri?: string;
  // For analytics purposes
  entityState?: string;
  learningAri?: string;
  learningUuid?: string;
  learningId?: string;
  title: string;
  connectionId?: string;
  isEditing?: boolean;
  isExpanded?: boolean;
  isInvalid?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  learningType: LearningType;
  onEdit?: () => void;
  onChange: (title: string) => void;
}

export const LearningTitle = (props: LearningTitleProps) => {
  const [titleActive, setTitleActive] = useState(props.isEditing ?? false);
  const intl = useIntl();

  const prevent = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  useEffect(() => {
    setTitleActive(!!props.isEditing);
  }, [props.isEditing]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  const routeDependencies = useRouteDependencies();

  if (titleActive) {
    return (
      <Textfield
        name={`${props.learningType.toLowerCase()}TitleInput`}
        type="text"
        defaultValue={props.title}
        placeholder={intl.formatMessage(
          {
            id: 'townsquare.packages.learnings.learning-title-placeholder',
            defaultMessage:
              "What's the summary of your {learningType, select, learning {learning} risk {risk} other {decision}}?",
            description: 'Placeholder for the learning title input',
          },
          { learningType: `${String(props.learningType).toLowerCase()}` },
        )}
        onClick={prevent}
        isInvalid={props.isInvalid}
        autoFocus
        testId={`${props.learningType.toLowerCase()}-title-input`}
        onChange={handleOnChange}
      />
    );
  }

  const linkEntityRoute = props.entityType === 'GOAL' ? goalViewRoute : projectRoute;
  let copyLinkPath = '';
  if (props.entityKey) {
    copyLinkPath = generatePath(linkEntityRoute, {
      baseParams: {
        id: props.entityKey,
        tabName: `${props.learningType.toLowerCase()}s`,
        resourceId: props.learningUuid,
      },
      ...routeDependencies,
    });
  }

  return (
    <>
      <LearningTitleDisplayWrapper data-testid={`${props.learningType.toLowerCase()}-title-${props.learningUuid}`}>
        <LearningTitlePlaceholder expanded={props.isExpanded}>{props.title}</LearningTitlePlaceholder>
        <LearningTitleLeftSideWrapper>
          {copyLinkPath && (
            <CopyLinkWrapper>
              <CopyLinkIcon link={`${getConfig().fullUrl}${copyLinkPath}`} entity={props.learningType} />
            </CopyLinkWrapper>
          )}
          {(props.canEdit || props.canDelete) && (
            <>
              <EditDropDown
                entityType={props.entityType}
                entityId={props.entityId}
                entityState={props.entityState}
                learningId={props.learningId}
                learningType={props.learningType}
                expanded={!!props.isExpanded}
                onEdit={props.onEdit}
                canDelete={props.canDelete}
                canEdit={props.canEdit}
                connectionId={props.connectionId}
              />
            </>
          )}
        </LearningTitleLeftSideWrapper>
      </LearningTitleDisplayWrapper>
    </>
  );
};
