import { PreloadedQuery } from 'react-relay';
import { createActionsHook, createHook, createStore } from 'react-sweet-state';

interface QueryReference {
  [parentId: string]: PreloadedQuery<any> | undefined;
}

const initialState: QueryReference = {};
const queryRefStore = createStore({
  initialState,
  actions: {
    setQueryRef:
      (queryRef: PreloadedQuery<any> | undefined, parentId: string) =>
      ({ setState }) => {
        setState({ [parentId]: queryRef });
      },
  },
  name: 'query-ref-store',
});

export const useQueryRefStore = createHook(queryRefStore);
export const useQueryRefActions = createActionsHook(queryRefStore);
export const useQueryRef = createHook(queryRefStore, {
  selector: (state, parentId: string) => state[parentId],
});
