import { useEffect } from 'react';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { SubproductAnalyticsAttribute } from '@townsquare/analytics/types';

import { isRouteWithOutlet, isRouteWithSubproduct } from '../utils';

export const useSubproductListener = (subProduct: SubproductAnalyticsAttribute = 'web') => {
  const [store] = useRouter();
  const analytics = useAnalytics();

  useEffect(() => {
    if (isRouteWithSubproduct(store.route) && store.route.subproduct) {
      if (typeof store.route.subproduct === 'function') {
        analytics.setSubproduct(store.route.subproduct(store));
      } else {
        analytics.setSubproduct(store.route.subproduct);
      }
    } else {
      analytics.setSubproduct(subProduct);
    }

    if (isRouteWithOutlet(store.route) && store.route.type) {
      analytics.setTopLevelAttributes({
        routeType: store.route.type,
      });
    }

    void analytics.startUIViewedEvent();
  }, [analytics, store, subProduct]);
};
