import { CSSFn, ItemState, LinkItem } from '@atlaskit/menu';
import React, { useMemo } from 'react';
import { useRouterActions } from 'react-resource-router';

import { ToAttributes } from '@townsquare/router/primitives';
import { HomepageRouteType } from '@townsquare/router/route-types';
import { createMetaClickHandler, useMetaClickHandler } from '@townsquare/ui-interactions';

export type RouterLinkItemProps = {
  to: HomepageRouteType;
  attributes?: ToAttributes;
  children: string | React.ReactNode;
  iconBefore?: React.ReactNode;
  description?: string | JSX.Element;
  testId?: string;
  isSelected?: boolean;
  cssFn?: CSSFn<ItemState>;
  onClick: () => void;
};

export const StaticRouterLinkItem = ({
  href,
  children,
  isSelected,
  cssFn,
  testId,
  onClick,
  iconBefore,
  description,
}: Omit<RouterLinkItemProps, 'to' | 'params'> & { href: string }) => {
  const routerActions = useRouterActions();
  const handleLinkClick = useMemo(
    () => createMetaClickHandler(href, routerActions, onClick),
    [routerActions, href, onClick],
  );

  return (
    <LinkItem
      href={href}
      onClick={handleLinkClick}
      isSelected={isSelected}
      iconBefore={iconBefore}
      description={description}
      shouldDescriptionWrap
      // eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
      cssFn={cssFn}
      testId={testId}
    >
      {children}
    </LinkItem>
  );
};

export const RouterLinkItem = ({
  to,
  attributes,
  children,
  isSelected,
  cssFn,
  testId,
  onClick,
  iconBefore,
  description,
}: RouterLinkItemProps) => {
  const { handler, path } = useMetaClickHandler(to, attributes, onClick);

  return (
    <LinkItem
      href={'' + path}
      onClick={handler}
      isSelected={isSelected}
      // eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
      cssFn={cssFn}
      testId={testId}
      iconBefore={iconBefore}
      description={description}
      shouldDescriptionWrap
    >
      {children}
    </LinkItem>
  );
};
