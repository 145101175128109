import { IntlShape } from 'react-intl-next';

import { SortOptions } from '@townsquare/sort-button';

import { DirectorySortEnum } from '../BrowsePeopleSection/__generated__/DefaultSearchFallBackPeopleSearchQuery.graphql';
import { DirectoryTeamSortEnum } from '../__generated__/StaffDirectoryViewQuery.graphql';

const SUPPORTED_PEOPLE_SORTS: DirectorySortEnum[] = ['NAME_ASC', 'NAME_DESC'];
const SUPPORTED_TEAM_SORTS: DirectoryTeamSortEnum[] = ['NAME_ASC', 'NAME_DESC'];

export type PeopleAndTeamsSortType = DirectorySortEnum | DirectoryTeamSortEnum;

export const isPeopleAndTeamsSortType = (
  sort: PeopleAndTeamsSortType | string | undefined,
): sort is PeopleAndTeamsSortType => isPeopleSortEnum(sort) || isTeamSortEnum(sort);

export const isPeopleSortEnum = (sort: PeopleAndTeamsSortType | string | undefined): sort is DirectorySortEnum =>
  SUPPORTED_PEOPLE_SORTS.includes(sort as DirectorySortEnum);

export const isTeamSortEnum = (sort: PeopleAndTeamsSortType | string | undefined): sort is DirectoryTeamSortEnum =>
  SUPPORTED_TEAM_SORTS.includes(sort as DirectoryTeamSortEnum);

export const DEFAULT_PEOPLE_SORT: DirectorySortEnum = 'NAME_ASC';
export const getPeopleSortOptions = (intl: IntlShape): SortOptions<DirectorySortEnum> => {
  return {
    [intl.formatMessage({
      id: 'townsquare.ui.directory.sort-options-name',
      description: 'Label for the sort by name option on the people directory page.',
      defaultMessage: 'Name',
    })]: {
      ascSort: 'NAME_ASC',
      descSort: 'NAME_DESC',
      primaryDirection: 'ASC',
    },
  };
};

export const DEFAULT_TEAMS_SORT: DirectoryTeamSortEnum = 'NAME_ASC';
export const getTeamSortOptions = (intl: IntlShape): SortOptions<DirectoryTeamSortEnum> => {
  return {
    [intl.formatMessage({
      id: 'townsquare.ui.directory.sort-options-team-name',
      description: 'Label for the sort by team name option on the people directory page.',
      defaultMessage: 'Team name',
    })]: {
      ascSort: 'NAME_ASC',
      descSort: 'NAME_DESC',
      primaryDirection: 'ASC',
    },
  };
};
