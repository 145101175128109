import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { GoalIcon } from '@townsquare/icons';
import { useGeneratePath } from '@townsquare/router/primitives';
import { WrappedWithHoverSmartCard } from '@townsquare/smart-card-provider';
import { useOpenInNewTab } from '@townsquare/ui-interactions';

import * as styled from './styles';
import { Props } from './types';

export const GoalName = (props: Props) => {
  const analytics = useAnalytics();
  const data = useFragment(
    graphql`
      fragment GoalName on Goal {
        uuid
        id
        key
        ...GoalIcon
        name
      }
    `,
    props.data,
  );

  const openInNewTab = useOpenInNewTab();
  const goalUrl = useGeneratePath(goalViewRoute, { id: data.key });

  /**
   * Wrap the entire component with smart card hover wrapper if enabled
   */
  const WrapperToUse = props._withSmartCard ? WrappedWithHoverSmartCard : styled.EmptyWrapper;
  /**
   * Only want to enable smart card or regular tooltip, not both at the same time
   */
  const EllipsisToUse = props._withSmartCard ? 'div' : EllipsisTooltip;

  const content = (
    <styled.GoalName
      onClick={() => {
        if (props.usesLink) {
          analytics.ui('goalName', 'clicked', {
            goalId: data.uuid,
          });
        }
      }}
      isBold={props.isBold}
      spacing={props.spacing}
      topLeftAppearance={props.topLeftAppearance}
    >
      <GoalIcon
        includeBackground
        backgroundSize={props.size || 'small'}
        iconProps={{
          size: props.size === 'xsmall' ? 'small' : props.size || 'small',
          label: data.name,
        }}
        data={data}
      />
      <EllipsisToUse content={props.disableTooltip ? undefined : data.name} position="bottom">
        {props.renderName ? (
          <Text as="p" testId={props.testId} maxLines={props.multiLine}>
            {props.renderName()}
          </Text>
        ) : (
          <Stack space="space.025">
            <Inline space="space.100">
              {props.showGoalKey && (
                <Box xcss={goalKeyStyles}>
                  <Text color="color.text.subtle">{data.key}</Text>
                </Box>
              )}
              <Text as="p" maxLines={props.multiLine || 1}>
                {data.name}
              </Text>
            </Inline>
            {props.subtitle && (
              <Text
                as="p"
                size="UNSAFE_small"
                color="color.text.subtlest"
                maxLines={1}
                testId="goal-name-subtitle-wrapper"
              >
                {props.subtitle}
              </Text>
            )}
          </Stack>
        )}
      </EllipsisToUse>
    </styled.GoalName>
  );

  return (
    <WrapperToUse
      id={data.id}
      url={goalUrl}
      shrink={props.shrink}
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter' && props.onSelect) {
          props.onSelect();
        }
      }}
    >
      {props.usesLink ? (
        <styled.GoalNameLink
          data-testid={props.testId}
          target={props.openInNewTab ? '_blank' : undefined}
          onClick={e => {
            if (props.openInNewTab) {
              e.preventDefault();
              openInNewTab(goalViewRoute, { params: { id: data.key } });
            }
          }}
          shrink={props.shrink}
          to={goalViewRoute}
          params={{ id: data.key }}
        >
          {content}
        </styled.GoalNameLink>
      ) : (
        <styled.GoalNameWrapper shrink={props.shrink}>{content}</styled.GoalNameWrapper>
      )}
    </WrapperToUse>
  );
};

const goalKeyStyles = xcss({
  flexShrink: 0,
});
