import styled from 'styled-components';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HiddenItem = styled.div({
  visibility: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DisplayedItem = styled.div({
  flexShrink: 0,
});
