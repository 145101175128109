import { CustomThemeButton } from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { ExpandablePanelProps } from '../types';

import {
  ExpandableInfoBoxHeading,
  ExpandableInfoBoxHeadingContainer,
  ExpandableInfoBoxHeadingContainerInner,
  fixedButtonStyle,
  InfoBox,
} from './styles';

export const ExpandablePanel = (props: PropsWithChildren<ExpandablePanelProps>) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(!!(props.renderAsOpen || props.isOpen));
  const ref = useRef<HTMLDivElement | undefined>();
  const { onToggle } = props;

  // Using props to force panel open.
  useEffect(() => {
    if (props.isOpen !== undefined && props.isOpen !== isOpen) {
      setIsOpen(!!props.isOpen);
    }
  }, [isOpen, props.isOpen]);

  const handleOnToggle = useCallback(() => {
    setIsOpen(open => !open);
    onToggle?.(!isOpen);
  }, [onToggle, isOpen]);

  const isToggleEnabled = !props.hideControlOnOpen;

  return (
    <InfoBox fullWidth padding={props.padding} margin={props.margin} innerRef={ref as React.RefObject<HTMLDivElement>}>
      <ExpandableInfoBoxHeadingContainer
        onClick={isToggleEnabled ? handleOnToggle : undefined}
        canClick={isToggleEnabled}
      >
        <ExpandableInfoBoxHeadingContainerInner>
          {props.icon}
          <ExpandableInfoBoxHeading
            hideControlOnOpen={Boolean(props.hideControlOnOpen)}
            isOpen={Boolean(isOpen)}
            useSubtleTitle={Boolean(props.useSubtleTitle)}
          >
            {props.title}
          </ExpandableInfoBoxHeading>
        </ExpandableInfoBoxHeadingContainerInner>
        {isOpen ? (
          isToggleEnabled ? (
            <CustomThemeButton
              theme={fixedButtonStyle}
              spacing="none"
              iconAfter={
                <ChevronUpIcon
                  color="currentColor"
                  label={intl.formatMessage({
                    id: 'townsquare.expandable-panel.hide.icon',
                    description: 'Click to hide',
                    defaultMessage: 'Click to hide',
                  })}
                />
              }
            />
          ) : null
        ) : (
          <CustomThemeButton
            theme={fixedButtonStyle}
            spacing="none"
            iconAfter={
              <ChevronDownIcon
                color="currentColor"
                label={intl.formatMessage({
                  id: 'townsquare.expandable-panel.expand.icon',
                  description: 'Click to expand',
                  defaultMessage: 'Click to expand',
                })}
              />
            }
          />
        )}
      </ExpandableInfoBoxHeadingContainer>
      {isOpen && <>{props.children}</>}
    </InfoBox>
  );
};
