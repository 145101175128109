import { type Context } from '@atlassian/post-office-context';

type Fetcher = (url: string) => Promise<Response>;

const defaultFetcher = (url: string) =>
	fetch(url, {
		headers: {
			credentials: 'include',
			mode: 'cors',
			'Content-Type': 'application/json',
		},
	});

export const requestInitialData = async (
	placementId: string,
	context: Context,
	fetcher: Fetcher = defaultFetcher,
	options: {
		baseUrl?: string;
		path: string;
	},
): Promise<Response> => {
	const baseUrl = options.baseUrl;
	const path = options.path;

	const requestUrl = baseUrl ? new URL(path, baseUrl).toString() : path;

	function stringifyObjectValues(input: Context) {
		const entries = Object.entries(input);
		const stringifiedEntries = entries
			.filter(([, v]) => typeof v !== 'undefined' && v !== null)
			.map(([key, value]) => [key, String(value)]);
		return Object.fromEntries(stringifiedEntries) as Record<string, string>;
	}

	const contextAsSearchParams = new URLSearchParams(stringifyObjectValues(context)).toString();
	const url = encodeURI(`${requestUrl}${placementId}?${contextAsSearchParams}`);

	// Response will be handled inside the component
	return fetcher(url);
};
