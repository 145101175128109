import React from 'react';

import { getAvatarPlaceholderColor } from '@townsquare/theme';

import type { Props } from '../types';

import { StyledTeamIcon } from './styles';

const UNKNOWN_TEAM_NAME = 'Unknown';

export const TeamIcon = ({ teamName, size, shape = 'square', customIcon }: Props) => {
  // TODO: TC-2245 - Temporary until using actual team icon component
  const color = getAvatarPlaceholderColor(teamName ?? UNKNOWN_TEAM_NAME);
  return (
    <StyledTeamIcon placeholderColor={color} size={size} shape={shape}>
      {customIcon ? customIcon : (teamName ?? UNKNOWN_TEAM_NAME)[0].toUpperCase()}
    </StyledTeamIcon>
  );
};
