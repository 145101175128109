import {
  // eslint-disable-next-line no-restricted-imports
  useRouterActions as rrrUseRouterActions,
  type RouterActionsType as RRRRouterActionsType,
  MatchParams,
  Query,
  RouterActionPush,
  RouterActionReplace,
} from 'react-resource-router';

import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';

import { HomepageRouteType } from '../home/types';

import { getEffectiveTo } from './useGetEffectiveTo';
import { useRouteDependencies } from './useRouteDependencies';
import { getRouteParams } from './useRouteParams';

export type ToAttributes = {
  params?: MatchParams;
  query?: Query;
};

/**
 * Deliberately remove 'push' and 'replace' because those only support plain href,
 * use `pushTo` and `replaceTo` instead.
 */
type RouterActionsType = Omit<RRRRouterActionsType, 'push' | 'replace' | 'pushTo' | 'replaceTo'> & {
  pushTo: (route: HomepageRouteType, attributes?: ToAttributes) => ReturnType<RouterActionPush>;
  replaceTo: (route: HomepageRouteType, attributes?: ToAttributes) => ReturnType<RouterActionReplace>;
  setQueryParams: (query: Query | URLSearchParams, replace?: boolean) => void;
};

export function useRouterActions(): RouterActionsType {
  const { orgId, cloudId, workspaceType } = useRouteDependencies();
  const rrrActions = rrrUseRouterActions();

  return {
    pushTo: (route, attrs) => {
      const effectiveTo = getEffectiveTo(route, workspaceType);
      const params = getRouteParams(attrs?.params, effectiveTo.routeType, orgId, cloudId, workspaceType);

      return rrrActions.pushTo(effectiveTo, { ...attrs, params });
    },
    replaceTo: (route, attrs) => {
      const effectiveTo = getEffectiveTo(route, workspaceType);
      const params = getRouteParams(attrs?.params, effectiveTo.routeType, orgId, cloudId, workspaceType);

      return rrrActions.replaceTo(effectiveTo, { ...attrs, params });
    },
    goBack: rrrActions.goBack,
    goForward: rrrActions.goForward,
    registerBlock: rrrActions.registerBlock,
    getBasePath: rrrActions.getBasePath,
    setQueryParams: (query, replace) => {
      if (query instanceof URLSearchParams) {
        query = Object.fromEntries(query);
      }
      const newPath = `?${createSearchParamsFromScratch(query)}`;
      if (replace) {
        rrrActions.replace(newPath);
      } else {
        rrrActions.push(newPath);
      }
    },
  };
}
