import { NO_WORKSPACE_UPDATES_PATH, STATUS_UPDATES_PAGE } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';

import { StatusUpdatesView } from './ui/StatusUpdates/StatusUpdatesView';

export const emptyOrNonProvisionedStatusUpdateStateRoute = createGlobalRoute({
  component: StatusUpdatesView,
  layout: 'home',
  exact: false,
  name: STATUS_UPDATES_PAGE,
  path: NO_WORKSPACE_UPDATES_PATH,
  resources: [titanEapCrossFlowQueryResource],
  subproduct: 'townsquare-empty-state',
});
