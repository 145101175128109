import Icon from '@atlaskit/icon/base';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function LocationGlyph(props: CustomGlyphProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M17.6569 5.34315C20.7811 8.46734 20.7811 13.5327 17.6569 16.6569L12.7071 21.6066C12.3166 21.9971 11.6834 21.9971 11.2929 21.6066L6.34315 16.6569C3.21895 13.5327 3.21895 8.46734 6.34315 5.34315C9.46734 2.21895 14.5327 2.21895 17.6569 5.34315ZM7.75736 6.75733C5.46747 9.04722 5.41543 12.7276 7.60123 15.0807L7.75736 15.2427L12 19.4852L16.2427 15.2427C18.5325 12.9528 18.5846 9.27241 16.3988 6.91932L16.2427 6.75733C13.8995 4.41418 10.1005 4.41418 7.75736 6.75733ZM12 8.00001C13.6569 8.00001 15 9.34316 15 11C15 12.6569 13.6569 14 12 14C10.3432 14 9.00001 12.6569 9.00001 11C9.00001 9.34316 10.3432 8.00001 12 8.00001ZM12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LocationIcon(props: GlyphProps) {
  return <Icon glyph={LocationGlyph} {...props} />;
}
