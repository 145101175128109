import { useEffect } from 'react';

import { getStringConfig } from '@townsquare/stat-sig/config';
import { useUserStore } from '@townsquare/user-store';

export const UserLocaleOverrideHandler = () => {
  const localeOverride = getStringConfig('townsquare_enable_override_locale', '');
  const [{ locale }, { setUserLocale }] = useUserStore();

  useEffect(() => {
    if (localeOverride && localeOverride !== locale) {
      void setUserLocale(localeOverride);
    }
  }, [locale, localeOverride, setUserLocale]);

  return null;
};
