import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

export const responsiveMobileWidthThreshold = 560;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonEmptyStateContainer = styled.div({
  textAlign: 'center',
  padding: token('space.400'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  img: {
    maxWidth: '180px',
    width: '100%',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const WorkspaceSwitcherContainer = styled.div({
  marginLeft: token('space.100'),
  width: '120px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropdownIconContainer = styled.div({
  opacity: 0.51,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ReleaseNoteContainer = styled.div`
  position: relative;
  @media (max-width: ${responsiveMobileWidthThreshold}px) {
    display: none !important;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeadwayBadge = styled.div({
  position: 'absolute',
  top: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '#HW_badge': {
    position: 'absolute',
    top: token('space.200'),
    left: token('space.200'),
    fontSize: 0,
    // Float the badge on top of the icon
    zIndex: 1,
    // Make both read and unread indicator smaller
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    transform: 'scale(0.6) !important',
    // Disable animation
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    animationName: 'none !important',
  },
});
