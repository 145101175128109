import LightbulbIcon from '@atlaskit/icon/core/migration/lightbulb';
import React from 'react';
import { IntlShape } from 'react-intl-next';
import { fetchQuery, graphql } from 'relay-runtime';

import { filterNull } from '@townsquare/type-helpers';

import { ProjectFilterRow } from '../components/project';
import { ComparatorOperator, OptionTypes, Resolver, SupportedFiltersTypes } from '../types';

import { projectResolverLabelQuery } from './__generated__/projectResolverLabelQuery.graphql';
import { projectResolverQuery } from './__generated__/projectResolverQuery.graphql';
import { equalsAndOrFilterComparators } from './filter-comparators';

export const projectResolver = (intl: IntlShape, workspaceUuid: string): Resolver => ({
  title: intl.formatMessage({
    id: 'townsquare.tql.resolvers.project-title',
    defaultMessage: 'Project',
    description: 'Project filter title',
  }),
  type: SupportedFiltersTypes.PROJECT,
  optionType: OptionTypes.SELECT,
  icon: <LightbulbIcon color="currentColor" label="" />,
  filterComparators: equalsAndOrFilterComparators,
  resolveOptions: ({ relayEnvironment, query }) => {
    return fetchQuery<projectResolverQuery>(
      relayEnvironment,
      graphql`
        query projectResolverQuery(
          $tql: String!
          $workspaceUuid: UUID!
          $after: String
          $first: Int
          $sort: [ProjectSortEnum]
        ) {
          projectSearch: projectTql(first: $first, after: $after, q: $tql, workspaceUuid: $workspaceUuid, sort: $sort) {
            count

            pageInfo {
              hasNextPage
              startCursor
              endCursor
            }

            edges {
              node {
                id
                name
                key
                uuid
                iconUrl {
                  square {
                    light
                    dark
                  }
                }
              }
            }
          }
        }
      `,
      {
        tql: query ? `name LIKE '${query}'` : '',
        workspaceUuid,
      },
      {
        fetchPolicy: 'store-or-network',
      },
    )
      .toPromise()
      .then(data => {
        return (
          data?.projectSearch?.edges
            ?.filter(filterNull)
            .map(edge => {
              const node = edge?.node;
              if (node) {
                return {
                  label: node.name,
                  value: node.key,
                  component: <ProjectFilterRow project={node} />,
                };
              }

              return null;
            })
            .filter(filterNull) ?? []
        );
      });
  },
  resolveLabels: ({ relayEnvironment, values }) => {
    return Promise.all(
      values.map(value => {
        return fetchQuery<projectResolverLabelQuery>(
          relayEnvironment,
          graphql`
            query projectResolverLabelQuery($key: String!) {
              project: projectByKey(key: $key) {
                key
                name
                uuid
              }
            }
          `,
          {
            key: value.toString(),
          },
          {
            fetchPolicy: 'store-or-network',
          },
        )
          .toPromise()
          .then(data => {
            const project = data?.project;
            return {
              label: project?.name ?? value.toString(),
              value,
              component: project ? <ProjectFilterRow project={project} /> : undefined,
              comparator: ComparatorOperator.EQUALS,
            };
          });
      }),
    );
  },
});
