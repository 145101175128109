/* eslint-disable max-len */
import Icon from '@atlaskit/icon/base';
import { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

const NewsfeedSvg = (props: CustomGlyphProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18 4C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H9C8.32421 20 7.73329 20 7 20C5.34315 20 4 18.6568 4 17V10C4 8.89543 4.89543 8 6 8H8V6C8 4.89543 8.89543 4 10 4H18ZM18 6H10V17.999L18 18V6ZM8 10H6V17C6 17.5523 6.44772 18 7 18C7.55228 18 8 17.5523 8 17V10ZM14 11C14.5523 11 15 11.4477 15 12C15 12.5128 14.6139 12.9355 14.1166 12.9933L14 13H12C11.4477 13 11 12.5523 11 12C11 11.4872 11.3861 11.0645 11.8834 11.0067L12 11H14ZM16 8C16.5523 8 17 8.44772 17 9C17 9.51284 16.6139 9.93553 16.1166 9.99329L16 10H12C11.4477 10 11 9.55228 11 9C11 8.48716 11.3861 8.06447 11.8834 8.00671L12 8H16Z"
      fill="currentColor"
    />
  </svg>
);

export const NewsfeedIcon = (props: GlyphProps) => <Icon glyph={NewsfeedSvg} size="medium" {...props} />;
