import { ProfileClient } from '@atlaskit/profilecard';

import { getConfig } from '@townsquare/config';
import { profileRoute } from '@townsquare/ptc-directory-view';
import { createOrgScopedPath } from '@townsquare/router/create-route';
import { generatePath, useRouteDependencies } from '@townsquare/router/primitives';

const config = getConfig();
const clients: { [key: string]: ProfileClient } = {};

export const getProfileClient = (cloudId: string, orgId?: string) => {
  if (!clients[cloudId]) {
    let teamCentralBaseUrl = config.classicUrl;
    if (orgId) {
      const path = createOrgScopedPath('/o/:orgIdentifier', orgId);
      teamCentralBaseUrl = `${config.fullUrl}${path}`;
    }

    const client = new ProfileClient({
      cacheMaxAge: 10000,
      cacheSize: 20,
      url: `${config.directoryServiceUrl}/graphql`,
      teamCentralUrl: `${config.watermelonGraphQLUrl}`,
      teamCentralBaseUrl,
      productIdentifier: 'atlas',
      gatewayGraphqlUrl: config.atlassianGQLGatewayUrl,
      cloudId,
    });

    clients[cloudId] = client;

    return client;
  }

  return clients[cloudId];
};

export const getProfilecardProvider = (
  push: any,
  onProfileCardOpen: () => void,
  onViewProfileClicked: () => void,
  attributes: ReturnType<typeof useRouteDependencies>,
) => ({
  resourceClient: getProfileClient(attributes.cloudId),
  cloudId: attributes.cloudId,
  getActions(id: string) {
    const path = generatePath(profileRoute, { baseParams: { id }, ...attributes });
    return [
      {
        label: 'View profile',
        id: 'view-profile',
        callback() {
          onViewProfileClicked();
          if (window.self !== window.top) {
            window.open(`${getConfig().fullUrl}${path}`, '_blank', 'noreferrer');
          } else {
            push(path);
          }
        },
        shouldRender() {
          onProfileCardOpen();
          return true;
        },
        link: path,
      },
    ];
  },
});
