import { captureException } from '@sentry/react';
import type { ExecuteFunction, GraphQLResponseWithData, PayloadError, RelayFieldLogger } from 'relay-runtime';
import { Environment, Network, Observable, RecordSource, Store } from 'relay-runtime';

import { getConfig, getReleaseVersion } from '@townsquare/config';
import { PRODUCT_DISPLAY_NAME } from '@townsquare/config/constants';
import {
  BadRequestError,
  ForbiddenError,
  GraphQLFetchError,
  InternalServerError,
  NotFound,
  RelayRequiredFieldError,
  RequestError,
  UnauthorizedError,
} from '@townsquare/error-state/classes';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import { captureMissingWorkspaceUuid } from './captureMissingWorkspaceUuid';
import { captureErrorSentry } from './relayCaptureError';
import { resolveFetchUrl } from './resolve-fetch-url';

const config = getConfig();
type FetchConfig = {
  defaultUrl: string;
  multiRegionUrl?: string;
  customHeaders?: Record<string, string>;
  cloudId?: string;
};

export const fetchGraphQLFromUrl = ({ defaultUrl, multiRegionUrl, customHeaders, cloudId }: FetchConfig) => {
  const baseUrl = resolveFetchUrl({ defaultUrl, multiRegionUrl, cloudId });
  return ((request, variables, cacheConfig) => {
    const fetchUrl = `${baseUrl}?operationName=${request.name}`;

    const captureError = (error: RequestError, extras: Record<string, unknown> = {}) => {
      return captureErrorSentry({ fetchUrl, request, variables, error, extras });
    };

    // Abort signal comes from cacheConfig metadata when using fetchQuery as we don't have direct access to request metadata
    const abortSignal =
      (request.metadata?.abortSignal as AbortSignal | undefined | null) ??
      (cacheConfig.metadata?.abortSignal as AbortSignal | undefined | null);

    /**
     * Monitor every graphql request that comes through and emit on instances of `workspaceUuid` variable
     * that is defined, but populated with invalid value (null or empty)
     */
    captureMissingWorkspaceUuid(request, variables);

    return Observable.create(source => {
      void fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'atl-client-name': 'status-ui',
          'atl-client-version': getReleaseVersion(),
          ...customHeaders,
        },
        body: JSON.stringify({
          query: request.text,
          variables,
        }),
        signal: abortSignal,
      })
        .then(response => {
          if (!response.ok) {
            throw new BadRequestError(response.statusText, response.status);
          }
          return response.json();
        })
        .then(data => {
          const errors = data.errors as (PayloadError & GraphQLResponseWithData['extensions'])[] | undefined;
          if (errors) {
            const messages = errors.reduce<Record<string, unknown>>((acc, error, index) => {
              acc[`message[${index}]`] = error.message;
              return acc;
            }, {});

            captureError(new GraphQLFetchError(errors[0].message, errors[0].extensions?.statusCode), messages);

            // We only care about top level nodes returning 403s.
            // If a nested node returns a 403, we don't want to blow away the entire page.
            // The nested node should handle the error state.
            if (errors.some(error => error.extensions?.statusCode === 403 && error.path?.length === 1)) {
              source.error(new ForbiddenError(`You do not have permission to perform this action`));
              return;
            }

            if (errors.some(error => error.extensions?.statusCode === 401 && error.path?.length === 1)) {
              source.error(new UnauthorizedError(`You do not have permission to perform this action`));
              return;
            }

            if (errors.some(error => error.extensions?.statusCode === 404 && error.path?.length === 1)) {
              source.error(new NotFound());
              return;
            }

            const internalServerError = errors.find(
              error => error.extensions?.statusCode === 500 && error.path?.length === 1,
            );
            if (internalServerError) {
              source.error(new InternalServerError(internalServerError.message));
              return;
            }
          }

          source.next(data);
          source.complete();
        })
        .catch((error: BadRequestError) => {
          captureError(error);
          source.error(error);
        });
    });
  }) as ExecuteFunction;
};

export const logMissingRequiredField: RelayFieldLogger = log => {
  captureException(new RelayRequiredFieldError(`Missing required field: ${log.fieldPath}`), scope =>
    scope
      .setExtra('kind', log.kind)
      .setExtra('fieldPath', log.fieldPath)
      .setExtra('owner', log.owner)
      .setExtra('message', log.kind === 'relay_resolver.error' ? log.error.message : undefined)
      .setLevel(log.kind === 'missing_field.log' ? 'info' : 'error'),
  );
};

let shardedEnvironment: Environment | undefined;
const store = new Store(new RecordSource());

export const RelayEnvironment = new Environment({
  configName: `${PRODUCT_DISPLAY_NAME} GraphQL`,
  network: Network.create(
    fetchGraphQLFromUrl({ defaultUrl: config.watermelonGraphQLUrl, multiRegionUrl: config.townsquareGraphQLUrl }),
  ),
  store,
  relayFieldLogger: logMissingRequiredField,
});

export const createRelayEnvironment = (cloudId?: string) => {
  // https://switcheroo.atlassian.com/ui/gates/266723ac-a6d8-400f-858f-8a156a065383
  const isEnabled = isGateEnabled('titan_enable_multi_region_stargate_url');

  if (!isEnabled || !cloudId) {
    return RelayEnvironment;
  }

  if (shardedEnvironment) {
    return shardedEnvironment;
  }

  const environment = new Environment({
    configName: `${PRODUCT_DISPLAY_NAME} GraphQL (Sharded: ${cloudId})`,
    network: Network.create(
      fetchGraphQLFromUrl({
        defaultUrl: config.watermelonGraphQLUrl,
        multiRegionUrl: config.townsquareGraphQLUrl,
        cloudId,
      }),
    ),
    store,
    relayFieldLogger: logMissingRequiredField,
  });

  shardedEnvironment = environment;

  return environment;
};

export function getRelayEnvironment(cloudId?: string): Environment {
  if (!cloudId && shardedEnvironment) {
    return shardedEnvironment;
  }
  return createRelayEnvironment(cloudId);
}
