/**
 * DO NOT MANUALLY CHANGE THIS FILE AS IT IS AUTOGENERATED.
 */
import { Icon } from '@atlaskit/icon/base';
import { type GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function MoreIconGlyph() {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        d="M6 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm6 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm6 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
        fill="currentColor"
      />
    </svg>
  );
}

function MoreIcon(props: GlyphProps) {
  return <Icon glyph={MoreIconGlyph} {...props} />;
}

export default MoreIcon;
