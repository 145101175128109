import { createHook, createStore } from 'react-sweet-state';

export enum AppState {
  // Loading state, app hasn't even executed any JS yet
  LOADING = 'LOADING',
  // App info loaded and no workspace available
  INITIALISED_WITHOUT_WORKSPACE = 'INITIALISED_WITHOUT_WORKSPACE',
  // Workspace and all other required app info has been loaded
  FULLY_INITIALISED = 'FULLY_INITIALISED',
}

type StoreState = {
  appState: AppState;
};

const appStateStore = createStore({
  initialState: {
    appState: AppState.LOADING,
  } as StoreState,
  actions: {
    setAppState:
      (newAppState: AppState) =>
      ({ setState }) => {
        setState({ appState: newAppState });
      },
  },
  name: 'app-state-store',
});

export const useAppStateStore = createHook(appStateStore);
