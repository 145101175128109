import '@atlaskit/css-reset';
import HeartbeatDisplay from '@atlassian/heartbeat';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { RelayEnvironmentProvider } from 'react-relay';
import { createBrowserHistory, Router } from 'react-resource-router';

import { AnalyticsClientImpl, AnalyticsContext } from '@townsquare/analytics';
import {
  requestStorageAccess,
  ensureCorrectUrl,
  handleSignUpCloudIdPathRedirect,
  returnAfterLogin,
  StorageAccessState,
} from '@townsquare/auth-utils';
import { isEmbedOrIntegration } from '@townsquare/config';
import { setUseClassicPaths } from '@townsquare/config/classic-paths';
import { EmojiToneSelectionListener } from '@townsquare/emoji-provider';
import { TownsquareIntlProvider, UserLocaleOverrideHandler } from '@townsquare/i18n';
import { installSentry } from '@townsquare/logging';
import { FullPageViewErrorBoundary } from '@townsquare/monitoring';
import { RelayEnvironment } from '@townsquare/relay-utils';
import { GlobalTheming } from '@townsquare/theme';

import { AuthCheck } from './auth/AuthCheck';
import { Bootstrap, Routes, StatSigInit } from './bootstrap';
import { client } from './clients/apolloClient';
// Import order is important here.
import './polyfills';
import Application from './ui/Application';
import RequestStorageAccess from './ui/RequestStorageAccess';
import ScrollToTop from './ui/ScrollToTop';

ensureCorrectUrl();
handleSignUpCloudIdPathRedirect();
installSentry();
setUseClassicPaths(true);

const history = createBrowserHistory();
const analyticsClient = new AnalyticsClientImpl('web');

const initApp = async () => {
  if (isEmbedOrIntegration()) {
    const isGrantRequired = (await requestStorageAccess()) === StorageAccessState.UserMustGrant;

    if (isGrantRequired) {
      ReactDOM.render(
        <TownsquareIntlProvider>
          <RequestStorageAccess />
        </TownsquareIntlProvider>,
        document.getElementById('root'),
      );
      return;
    }
  }

  ReactDOM.render(
    <AuthCheck>
      <ApolloProvider client={client}>
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <AnalyticsContext.Provider value={analyticsClient}>
            <FullPageViewErrorBoundary>
              <GlobalTheming />
              <EmojiToneSelectionListener />
              <Bootstrap history={history}>
                {(user, workspace, featureFlags) => (
                  <StatSigInit user={user} workspace={workspace}>
                    <Routes>
                      {routes => (
                        <Router routes={routes} history={history} resourceContext={{ user, workspace, featureFlags }}>
                          <ScrollToTop />
                          <UserLocaleOverrideHandler />
                          <Application />
                          <HeartbeatDisplay onAuthenticationFailed={returnAfterLogin} />
                        </Router>
                      )}
                    </Routes>
                  </StatSigInit>
                )}
              </Bootstrap>
            </FullPageViewErrorBoundary>
          </AnalyticsContext.Provider>
        </RelayEnvironmentProvider>
      </ApolloProvider>
    </AuthCheck>,
    document.getElementById('root'),
  );
};

void initApp();
