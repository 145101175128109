/**
 * @generated SignedSource<<779096405a08dec5c524c53356fcc8c4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type OnboardingItemKey = "ADD_PEOPLE_BANNER" | "CLASSIC_TO_ATLASSIAN_HOME_MODAL" | "DISCOVER_INTEGRATIONS" | "EXPLORE_DIRECTORY_BANNER" | "GLOBAL_HOME" | "GOALS_ABOUT_TAB_BANNER" | "GOALS_DIRECTORY" | "GOALS_DIRECTORY_BANNER" | "GOALS_METRICS_POPUP" | "GOALS_UPDATES_BANNER" | "GOALS_UPDATES_TAB_BANNER" | "GOAL_LEARNINGS_BANNER" | "LINKED_GOALS_IN_JIRA_BANNER" | "NAV_REFRESH_OPT_OUT" | "NO_WORKSPACE_BENEFITS_DIALOG" | "PASTE_WORK_TRACKING_JIRA_LINK_BANNER" | "PRODUCT_OVERVIEW" | "PROJECT_ABOUT_TAB_BANNER" | "PROJECT_DIRECTORY" | "PROJECT_DIRECTORY_BANNER" | "PROJECT_LEARNINGS_BANNER" | "PROJECT_SPOTLIGHT" | "PROJECT_UPDATES_TAB_BANNER" | "PROJECT_UPDATE_BANNER" | "QUESTIONS_FEED_BANNER" | "READING_MODE_FEEDBACK_COLLECTION" | "STAFF_DIRECTORY_UPLOAD_HIGH_RES_PIC" | "SYNC_WITH_JIRA_BANNER" | "TEAMS_DIRECTORY" | "TOPICS_DIRECTORY" | "WRITING_MODE_FEEDBACK_COLLECTION";
export type OnboardingQuery$variables = Record<PropertyKey, never>;
export type OnboardingQuery$data = {
  readonly currentUser: {
    readonly onboarding: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly key: OnboardingItemKey | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type OnboardingQuery = {
  response: OnboardingQuery$data;
  variables: OnboardingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "key"
},
v1 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v2 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v3 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "OnboardingQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": "onboarding",
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "__OnboardingList__onboarding_connection",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/)
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OnboardingQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "onboarding",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v6/*: any*/),
                      (v1/*: any*/)
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": "onboarding(first:500)"
          },
          {
            "args": (v5/*: any*/),
            "handle": "connection",
            "key": "OnboardingList__onboarding",
            "kind": "LinkedHandle",
            "name": "onboarding"
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "aca8d7c42fae42e566ea9aadac66f8bd",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "currentUser",
            "onboarding"
          ]
        }
      ]
    },
    "name": "OnboardingQuery",
    "operationKind": "query",
    "text": "query OnboardingQuery {\n  currentUser {\n    onboarding(first: 500) {\n      edges {\n        node {\n          key\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d26d213771d51ece32fe02a9dafa007c";

export default node;
