import React from 'react';

import { LoadingState } from './LoadingState';
import { DesktopRestrictedLoadingState, MobileRestrictedLoadingState } from './styles';
import { LoadingStateProps } from './types';

const MobileLoadingState = (props: LoadingStateProps) => (
  <MobileRestrictedLoadingState>
    <LoadingState {...props} />
  </MobileRestrictedLoadingState>
);

/*
 * Lets you show a custom loading state on desktop and a spinner on mobile, in case the space
 * available makes showing a spinner the better option
 */
export const ResponsiveLoadingState = ({
  children: customLoadingComponent,
  ...loadingStateProps
}: {
  children: React.ReactNode;
}) => (
  <>
    <DesktopRestrictedLoadingState>{customLoadingComponent}</DesktopRestrictedLoadingState>
    <MobileLoadingState {...loadingStateProps} />
  </>
);
