import { fetchQuery, graphql } from 'relay-runtime';

import { RelayEnvironment } from '@townsquare/relay-utils';

import { fetchKeyboardPreferenceQuery } from './__generated__/fetchKeyboardPreferenceQuery.graphql';

export function fetchKeyboardPreference() {
  return fetchQuery<fetchKeyboardPreferenceQuery>(
    RelayEnvironment,
    graphql`
      query fetchKeyboardPreferenceQuery {
        currentUser {
          ...storeKeyboardShortcut
        }
      }
    `,
    {},
  ).toPromise();
}
