/* eslint-disable @atlaskit/design-system/no-deprecated-apis */
import { HeadingItem, MenuGroup, Section } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent, Suspense } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { Route, useResource, useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useRovoWorkspace } from '@townsquare/collab-context';
import { redirect } from '@townsquare/facade/window';
import { filterNull } from '@townsquare/filter-type';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { goalDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { homeTabRoute } from '@townsquare/hometab-view';
import { LoadingState } from '@townsquare/loading-state';
import { useOrgContextCloudIds, useOrgContextOrgId } from '@townsquare/org-context';
import { projectRoute } from '@townsquare/project-view/route';
import { projectDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { generatePath, useRouteDependencies } from '@townsquare/router/primitives';
import { searchConfigurationResource } from '@townsquare/search-view';
import { useFeatureGate } from '@townsquare/stat-sig/gate';
import { tagsDirectoryRoute } from '@townsquare/tags-directory-view/route';
import { topicRoute } from '@townsquare/topic-view/route';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';

import { SwitchWorkspaceItem } from './SwitchWorkspaceItem';
import { HomeWorkspaceSwitcherQuery as HomeWorkspaceSwitcherQueryType } from './__generated__/HomeWorkspaceSwitcherQuery.graphql';
import { WorkspaceDetailsForSwitcher } from './types';

const HomeWorkspaceSwitcherQuery = graphql`
  query HomeWorkspaceSwitcherQuery($context: OrganizationContextInput) {
    userOrganizationByContext(organizationContext: $context) @required(action: THROW) {
      workspaces {
        UUID: uuid
        cloudId
        name
        type
      }
    }
  }
`;
type HomeWorkspaceSwitcherProps = {
  queryReference: PreloadedQuery<HomeWorkspaceSwitcherQueryType>;
};

function getRedirectPathForRoute(route: Route, routeDependencies: ReturnType<typeof useRouteDependencies>) {
  if ([projectRoute.name, projectDirectoryRoute.name].includes(route.name)) {
    // If on a project or project directory -> navigate to the project directory
    return generatePath(projectDirectoryRoute, routeDependencies);
  } else if ([goalViewRoute.name, goalDirectoryRoute.name].includes(route.name)) {
    // If on a goal or goal directory -> goal directory
    return generatePath(goalDirectoryRoute, routeDependencies);
  } else if ([topicRoute.name, tagsDirectoryRoute.name].includes(route.name)) {
    // If on a tag or tag directory -> tag directory
    return generatePath(tagsDirectoryRoute, routeDependencies);
  } else {
    // All other cases, send them to the home page
    return generatePath(homeTabRoute, routeDependencies);
  }
}

const HomeWorkspaceSwitcher = (props: HomeWorkspaceSwitcherProps) => {
  const cloudId = useRovoWorkspace();
  const [{ route }] = useRouter();
  const routeDependencies = useRouteDependencies();
  const orgId = useOrgContextOrgId();
  const cloudIds = useOrgContextCloudIds();
  const data = usePreloadedQuery<HomeWorkspaceSwitcherQueryType>(HomeWorkspaceSwitcherQuery, props.queryReference);
  const searchConfiguration = useResource(searchConfigurationResource);
  const analytics = useAnalytics();

  const enableSiteSwitching = useFeatureGate('atlassian-home-site-switcher');

  const basePath = getRedirectPathForRoute(route, routeDependencies);

  const workspaces: WorkspaceDetailsForSwitcher[] =
    data?.userOrganizationByContext?.workspaces
      ?.filter(filterNull)
      .filter(workspace => workspace?.type === 'GLOBAL_EXPERIENCE') ?? [];

  const sites: WorkspaceDetailsForSwitcher[] = [];
  if (enableSiteSwitching) {
    const siteWorkspaces =
      cloudIds.map(cloudId => {
        const siteName = searchConfiguration.data?.siteMetadata?.find(({ siteId }) => siteId === cloudId)?.siteName;
        return {
          name: siteName ?? '',
          cloudId: cloudId,
          organisationId: orgId,
        };
      }) ?? [];

    sites.push(...siteWorkspaces);
    sites.sort(a => (a.cloudId === cloudId ? -1 : 1));
  }

  const options = enableSiteSwitching ? sites : workspaces;

  const onItemClick = (event: MouseEvent | KeyboardEvent, selectedWorkspace: WorkspaceDetailsForSwitcher) => {
    // Handled by redirects below, block native link functionality
    event.preventDefault();
    void analytics.ui('workspaceSwitcherNavWorkspaceOption', 'clicked', {
      workspaceUuid: selectedWorkspace.UUID,
    });

    const query = createSearchParamsFromScratch({ cloudId: selectedWorkspace.cloudId });
    redirect(`${basePath}?${query}`);
  };

  const heading = enableSiteSwitching ? (
    <FormattedMessage
      id="townsquare.home-navigation.profile-site-switcher.tooltip"
      description="Tooltip for the switch site option"
      defaultMessage="Switch site"
    />
  ) : (
    <FormattedMessage
      id="townsquare.home-navigation.profile-workspace-switcher.tooltip"
      description="Tooltip for the switch workspace option"
      defaultMessage="Switch workspace"
    />
  );

  return (
    // Hack to position workspace switcher on the far right side of the nav
    <MenuGroup minWidth={192} maxWidth={480} maxHeight="80vh">
      <Section>
        <HeadingItem>{heading}</HeadingItem>
        {options.map(ws => {
          const query = createSearchParamsFromScratch({ cloudId: ws.cloudId });
          return (
            <SwitchWorkspaceItem
              href={`${basePath}?${query}`}
              onItemClick={onItemClick}
              workspace={ws}
              key={ws.cloudId}
              isSelected={ws.cloudId === cloudId}
            />
          );
        })}
      </Section>
    </MenuGroup>
  );
};

type HomeWorkspaceSwitcherWrapperProps = {
  queryReference: PreloadedQuery<HomeWorkspaceSwitcherQueryType> | null | undefined;
};

export const HomeWorkspaceSwitcherWrapper = (props: HomeWorkspaceSwitcherWrapperProps) => {
  return (
    <Suspense
      fallback={
        <MenuGroup minWidth={280}>
          <LoadingState />
        </MenuGroup>
      }
    >
      {props.queryReference && <HomeWorkspaceSwitcher queryReference={props.queryReference} />}
    </Suspense>
  );
};
