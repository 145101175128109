import React from 'react';
import { defineMessage, useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { ScreenType } from '../ScreenType';

import { BrowsePeopleFieldSection, BrowsePeopleFieldSectionSkeleton } from './BrowsePeopleFieldSection';
import { BrowseJobTitleSection$key } from './__generated__/BrowseJobTitleSection.graphql';

const jobTitleSectionTitle = defineMessage({
  id: 'townsquare.staff-directory.browse-job-titles-section.title',
  description: 'Header text for the job titles section of the staff directory',
  defaultMessage: 'Job titles',
});

export interface BrowseFieldSectionProps {
  data: BrowseJobTitleSection$key;
}

export const BrowseJobTitleSectionSkeleton = () => {
  const intl = useIntl();
  return (
    <BrowsePeopleFieldSectionSkeleton
      browseAllScreenType={ScreenType.BROWSE_JOB_TITLES}
      fieldType="JOB_TITLE"
      sectionTitle={intl.formatMessage(jobTitleSectionTitle)}
    />
  );
};

export const BrowseJobTitleSection = (props: BrowseFieldSectionProps) => {
  const data = useFragment(
    graphql`
      fragment BrowseJobTitleSection on Query
      @argumentDefinitions(workspaceId: { type: "ID!" }, first: { type: "Int" }, after: { type: "String" }) {
        jobTitle: peopleFieldAggregates(
          workspaceId: $workspaceId
          peopleFieldType: JOB_TITLE
          first: $first
          after: $after
        ) {
          ...BrowsePeopleFieldSection
        }
      }
    `,
    props.data,
  );
  const intl = useIntl();

  return (
    data.jobTitle && (
      <BrowsePeopleFieldSection
        sectionTitle={intl.formatMessage(jobTitleSectionTitle)}
        fieldType="JOB_TITLE"
        browseAllScreenType={ScreenType.BROWSE_JOB_TITLES}
        data={data.jobTitle}
      />
    )
  );
};
