import { commitMutation, Environment, graphql, type MutationConfig } from 'relay-runtime';

import { updateRiskInput, UpdateRiskMutation } from './__generated__/UpdateRiskMutation.graphql';

export const updateRisk = (
  environment: Environment,
  input: updateRiskInput,
  events: Pick<MutationConfig<UpdateRiskMutation>, 'onCompleted' | 'onError'>,
) => {
  return commitMutation<UpdateRiskMutation>(environment, {
    mutation: graphql`
      mutation UpdateRiskMutation($input: updateRiskInput!) {
        updateRisk(input: $input) {
          risk {
            id
            summary
            description
            lastEditedBy {
              pii {
                name
                picture
              }
            }
            lastEditedDate
          }
        }
      }
    `,
    variables: { input },
    optimisticUpdater: store => {
      if (input.id) {
        const risk = store.get(input.id);

        if (input.summary) {
          risk?.setValue(input.summary, 'summary');
        }
        if (input.description) {
          risk?.setValue(input.description, 'description');
        }
      }
    },
    onCompleted: events.onCompleted,
    onError: events.onError,
  });
};
