/**
 * @generated SignedSource<<7016f7fe6aed5dfaa23814d11fc134e1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type UserTeamQuery$variables = {
  cloudId?: string | null;
  teamId: string;
};
export type UserTeamQuery$data = {
  readonly teamByTeamId: {
    readonly avatarUrl: string | null;
    readonly name: string | null;
    readonly teamId: string | null;
  } | null;
};
export type UserTeamQuery = {
  response: UserTeamQuery$data;
  variables: UserTeamQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "teamId",
    "variableName": "teamId"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "teamId"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
},
v5 = {
  "kind": "ScalarField",
  "name": "avatarUrl"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "UserTeamQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "teamByTeamId",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserTeamQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "teamByTeamId",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "7c523add1432f9d23eeef1bbd0d428f7",
    "id": null,
    "metadata": {},
    "name": "UserTeamQuery",
    "operationKind": "query",
    "text": "query UserTeamQuery(\n  $teamId: String!\n  $cloudId: String\n) {\n  teamByTeamId(teamId: $teamId, cloudId: $cloudId) {\n    teamId\n    name\n    avatarUrl\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4ef15874d8331b0b8ab2aa161773be5";

export default node;
