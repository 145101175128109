/**
 * @generated SignedSource<<05cc7a9087c7ab596cfc6a4b92167098>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type GoalLinkItem$data = {
  readonly aggregatedInfo: {
    readonly numProjects: number;
  } | null;
  readonly archived: boolean;
  readonly key: string;
  readonly name: string;
  readonly subGoals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly archived: boolean;
      } | null;
    } | null> | null;
  } | null;
  readonly uuid: string;
  readonly watchers: {
    readonly edges: ReadonlyArray<{
      readonly __typename: "UserEdge";
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"GoalIcon">;
  readonly " $fragmentType": "GoalLinkItem";
};
export type GoalLinkItem$key = {
  readonly " $data"?: GoalLinkItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"GoalLinkItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "archived"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "GoalLinkItem",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "FragmentSpread",
      "name": "GoalIcon"
    },
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "concreteType": "AggregatedInfoDto",
      "kind": "LinkedField",
      "name": "aggregatedInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "numProjects"
        }
      ]
    },
    (v0/*: any*/),
    {
      "concreteType": "GoalConnection",
      "kind": "LinkedField",
      "name": "subGoals",
      "plural": false,
      "selections": [
        {
          "concreteType": "GoalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "Goal",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "watchers",
      "plural": false,
      "selections": [
        {
          "concreteType": "UserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__typename"
            }
          ]
        }
      ]
    }
  ],
  "type": "Goal"
};
})();

(node as any).hash = "aa97a759c7a552cdd75acee936b92753";

export default node;
