/**
 * DO NOT MANUALLY CHANGE THIS FILE AS IT IS AUTOGENERATED.
 */
import { Icon } from '@atlaskit/icon/base';
import { type GlyphProps } from '@atlaskit/icon/types';
import React from 'react';

function ChevronUpIconGlyph() {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m11.221 9.322-2.929 2.955a1.009 1.009 0 0 0 .322 1.637.986.986 0 0 0 1.083-.218l2.298-2.317 2.307 2.327a.988.988 0 0 0 1.407 0 1.01 1.01 0 0 0 0-1.419l-2.94-2.965A1.106 1.106 0 0 0 11.991 9c-.279 0-.557.107-.77.322Z"
        fill="currentColor"
      />
    </svg>
  );
}

function ChevronUpIcon(props: GlyphProps) {
  return <Icon glyph={ChevronUpIconGlyph} {...props} />;
}

export default ChevronUpIcon;
