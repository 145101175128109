import { ButtonItem, LinkItem, MenuGroup, Section } from '@atlaskit/menu';
import React, { KeyboardEvent, MouseEvent, Suspense } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';

import { LoadingState } from '@townsquare/loading-state';
import { projectDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator, Operators } from '@townsquare/tql/types';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { useUserStore } from '@townsquare/user-store';
import { useWorkspaceUUID } from '@townsquare/workspace-store';

import { ProjectLinkItem } from './ProjectLinkItem';
import { ProjectMenuButtonQuery } from './__generated__/ProjectMenuButtonQuery.graphql';
import { ButtonEmptyStateContainer, ProjectContentWrapper, ViewAllLink } from './styles';
import { onItemClickContextOptions } from './types';

const MAX_NUM_OF_YOUR_PROJECTS_RENDERED = 5;
const MAX_NUM_OF_FOLLOWING_PROJECTS_RENDERED = 20;

const ProjectMenuQuery = graphql`
  query ProjectMenuButtonQuery(
    $workspaceUuid: UUID!
    $yourProjectsTql: String!
    $followingTql: String!
    $yourProjectsLimit: Int
    $followingLimit: Int
  ) {
    yourProjects: projectTql(
      q: $yourProjectsTql
      workspaceUuid: $workspaceUuid
      # Those that haven't been updated in the longest time first
      sort: LATEST_UPDATE_DATE_ASC
      first: $yourProjectsLimit
    ) {
      count
      edges {
        node {
          uuid
          state {
            value
          }
          ...ProjectLinkItem
        }
      }
    }

    followingProjects: projectTql(
      q: $followingTql
      workspaceUuid: $workspaceUuid
      # Those that have most recently been updated first
      sort: LATEST_UPDATE_DATE_DESC
      first: $followingLimit
    ) {
      count
      edges {
        node {
          uuid
          state {
            value
          }
          ...ProjectLinkItem
        }
      }
    }
  }
`;

export const ProjectMenu = (props: {
  onItemClick: (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => void;
  shortView?: boolean;
  queryReference: PreloadedQuery<ProjectMenuButtonQuery>;
}) => {
  const intl = useIntl();
  const data = usePreloadedQuery<ProjectMenuButtonQuery>(ProjectMenuQuery, props.queryReference);

  const { handler, path } = useMetaClickHandler(projectDirectoryRoute, undefined, e => {
    props.onItemClick('viewAllProjects', e);
  });

  const { handler: filteredHandler, path: filteredPath } = useMetaClickHandler(
    projectDirectoryRoute,
    { query: { filter: 'your-projects' } },
    e => {
      props.onItemClick('viewMoreYourProjects', e);
    },
  );

  if (!data) {
    return null;
  }

  const yourProjects = data?.yourProjects?.edges ?? [];
  const hasYourProjects = (data.yourProjects?.count || 0) > 0;

  const watchingProjects = data.followingProjects;
  const hasWatchingProjects = (data.followingProjects?.count || 0) > 0;

  const hasProjects = hasYourProjects || hasWatchingProjects;

  // max 20 projects (MAX_NUM_OF_FOLLOWING_PROJECTS_RENDERED) that a user is following (that isn't appearing in the 'Your Projects' section)
  const filteredWatchingProjects = watchingProjects?.edges?.filter(followedProjectsEdge => {
    if (!followedProjectsEdge?.node?.state?.value) {
      return;
    }
    // Remove all projects that are already in the yourProjects array
    return !yourProjects?.find(edge => edge?.node?.uuid === followedProjectsEdge?.node?.uuid);
  });

  return (
    <MenuGroup minWidth={320} maxWidth="95vw">
      {!hasProjects && (
        <ButtonEmptyStateContainer>
          <img src="/Images/GlobeNetworkStar@2x.png" />
          <p>
            <FormattedMessage
              id="townsquare.navigation-projects.project-menu.no-projects"
              description="No projects message"
              defaultMessage="Projects you create or follow will show up here."
            />
          </p>
        </ButtonEmptyStateContainer>
      )}
      {hasProjects && (
        <ProjectContentWrapper shortView={props.shortView}>
          {!!yourProjects.length && (
            <Section
              title={intl.formatMessage({
                id: 'townsquare.navigation-projects.project-menu.your-projects',
                description: 'Your projects section title',
                defaultMessage: 'Your projects',
              })}
            >
              {yourProjects &&
                yourProjects.map(node => {
                  if (!node?.node) {
                    return;
                  }
                  return (
                    <ProjectLinkItem
                      {...props}
                      data={node.node}
                      onItemClick={props.onItemClick}
                      navigateTo="navigateToYourProjectsProject"
                      type="MEMBER"
                    />
                  );
                })}
              {(data.yourProjects?.count ?? 0) > MAX_NUM_OF_YOUR_PROJECTS_RENDERED && (
                <ViewAllLink testId="menu-view-more-your-projects" onClick={filteredHandler} href={filteredPath}>
                  <FormattedMessage
                    id="townsquare.navigation-projects.project-menu.view-all"
                    description="View all projects link"
                    defaultMessage="View all"
                  />
                </ViewAllLink>
              )}
            </Section>
          )}
          {hasWatchingProjects && (
            <Section
              title={intl.formatMessage({
                id: 'townsquare.navigation-projects.project-menu.following',
                description: 'Following projects section title',
                defaultMessage: 'Following',
              })}
            >
              {filteredWatchingProjects &&
                filteredWatchingProjects?.map(node => {
                  if (!node?.node) {
                    return;
                  }
                  return (
                    <ProjectLinkItem
                      {...props}
                      data={node.node}
                      onItemClick={props.onItemClick}
                      navigateTo="navigateToFollowedProject"
                      type="FOLLOWER"
                    />
                  );
                })}
            </Section>
          )}
        </ProjectContentWrapper>
      )}
      <Section hasSeparator>
        <LinkItem testId="menu-view-all-projects" onClick={handler} href={'' + path}>
          <FormattedMessage
            id="townsquare.navigation-projects.project-menu.view-all-projects"
            description="View all projects link"
            defaultMessage="View all projects"
          />
        </LinkItem>
        <ButtonItem onClick={e => props.onItemClick('openProjectModal', e)}>
          <FormattedMessage
            id="townsquare.navigation-projects.project-menu.create-project"
            description="Create project link"
            defaultMessage="Create project"
          />
        </ButtonItem>
      </Section>
    </MenuGroup>
  );
};

export type ProjectWrapperProps = {
  onItemClick: (ctx: onItemClickContextOptions, e?: MouseEvent | KeyboardEvent) => void;
  shortView?: boolean;
};

export const ProjectMenuWrapper = (props: ProjectWrapperProps) => {
  const [workspaceUuid] = useWorkspaceUUID();
  const [user] = useUserStore();
  const [queryReference, loadQuery] = useQueryLoader<ProjectMenuButtonQuery>(ProjectMenuQuery);

  if (!queryReference) {
    loadQuery(
      {
        workspaceUuid,
        yourProjectsTql: tqlQueryFromInput({
          input: [
            { fieldName: 'owner', fieldValue: user.accountId, comparator: ComparatorOperator.EQUALS },
            { join: Operators.OR },
            { fieldName: 'contributor', fieldValue: user.accountId, comparator: ComparatorOperator.EQUALS },
            { join: Operators.AND },
            { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
            { fieldName: 'phase', fieldValue: 'done', comparator: ComparatorOperator.NOT_EQUALS },
          ],
        }),
        followingTql: tqlQueryFromInput({
          input: [
            { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
            { fieldName: 'watcher', fieldValue: user.accountId, comparator: ComparatorOperator.EQUALS },
            { fieldName: 'phase', fieldValue: 'done', comparator: ComparatorOperator.NOT_EQUALS },
          ],
        }),
        yourProjectsLimit: MAX_NUM_OF_YOUR_PROJECTS_RENDERED,
        followingLimit: MAX_NUM_OF_FOLLOWING_PROJECTS_RENDERED,
      },
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }

  return (
    <Suspense
      fallback={
        <MenuGroup minWidth={320}>
          <LoadingState />
        </MenuGroup>
      }
    >
      {queryReference && <ProjectMenu queryReference={queryReference} {...props} />}
    </Suspense>
  );
};
