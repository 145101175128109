import Cache from '@townsquare/cache';

import { UserById } from './ResolveUserQuery';
import type { ResolvedUser } from './types';

export type { ResolvedUser } from './types';

const userCache = new Cache();
const CACHE_TTL = 59 * 60 * 1000;

export const resolveUser = async (aaid: string): Promise<ResolvedUser> => {
  const key = `user:${aaid}`;

  if (userCache.has(key)) {
    return userCache.get(key);
  }

  const user = await UserById(aaid);
  userCache.set(key, user, CACHE_TTL);
  return user;
};
