/**
 * @generated SignedSource<<0b2297e4b14da010f4e3805018e08f92>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TopicPageAsideQuery$variables = {
  isNavRefreshEnabled: boolean;
  tagUuid: string;
};
export type TopicPageAsideQuery$data = {
  readonly tagByUuid?: {
    readonly " $fragmentSpreads": FragmentRefs<"TopicHeader_data" | "TopicPageSidebar_data">;
  } | null;
};
export type TopicPageAsideQuery = {
  response: TopicPageAsideQuery$data;
  variables: TopicPageAsideQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNavRefreshEnabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagUuid"
},
v2 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "tagUuid"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "watching"
},
v6 = {
  "kind": "ScalarField",
  "name": "count"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "TopicPageAsideQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tagByUuid",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "TopicHeader_data"
              },
              {
                "kind": "FragmentSpread",
                "name": "TopicPageSidebar_data"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TopicPageAsideQuery",
    "selections": [
      {
        "condition": "isNavRefreshEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tagByUuid",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "name": "uuid"
              },
              {
                "kind": "ScalarField",
                "name": "description"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "name": "watcherCount"
              },
              {
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "topContributors",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "aaid"
                          },
                          {
                            "concreteType": "UserPII",
                            "kind": "LinkedField",
                            "name": "pii",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "picture"
                              },
                              (v3/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountStatus"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              {
                                "concreteType": "ExtendedProfile",
                                "kind": "LinkedField",
                                "name": "extendedProfile",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "jobTitle"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "TeamConnection",
                "kind": "LinkedField",
                "name": "topTeams",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "concreteType": "TeamEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "Team",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          {
                            "concreteType": "TeamMemberConnection",
                            "kind": "LinkedField",
                            "name": "members",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "teamId"
                          },
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "20cc98ab85922e7f3b9e44402dcedad8",
    "id": null,
    "metadata": {},
    "name": "TopicPageAsideQuery",
    "operationKind": "query",
    "text": "query TopicPageAsideQuery(\n  $tagUuid: UUID!\n  $isNavRefreshEnabled: Boolean!\n) {\n  tagByUuid(uuid: $tagUuid) @include(if: $isNavRefreshEnabled) {\n    ...TopicHeader_data\n    ...TopicPageSidebar_data\n    id\n  }\n}\n\nfragment TagFollowButton on Tag {\n  id\n  uuid\n  name\n  watching\n}\n\nfragment TeamAvatar on Team {\n  id\n  name\n  teamId\n}\n\nfragment TeamAvatarWithFollowButton on Team {\n  id\n  teamId\n  name\n  watching\n  ...TeamAvatar\n}\n\nfragment TopContributors on Tag {\n  topContributors {\n    edges {\n      node {\n        id\n        ...UserAvatar_1TWJ92\n      }\n    }\n  }\n}\n\nfragment TopTeams on Tag {\n  topTeams {\n    edges {\n      node {\n        id\n        name\n        members {\n          count\n        }\n        ...TeamAvatarWithFollowButton\n      }\n    }\n  }\n}\n\nfragment TopicDescription_data on Tag {\n  uuid\n  description\n}\n\nfragment TopicHeader_data on Tag {\n  name\n  ...TopicName_data\n  ...TopicDescription_data\n  ...TagFollowButton\n}\n\nfragment TopicName_data on Tag {\n  uuid\n  name\n}\n\nfragment TopicPageSidebar_data on Tag {\n  uuid\n  watcherCount\n  ...TagFollowButton\n  topContributors {\n    count\n  }\n  ...TopContributors\n  topTeams {\n    count\n  }\n  ...TopTeams\n}\n\nfragment UserAvatar_1TWJ92 on User {\n  aaid\n  pii {\n    picture\n    name\n    accountStatus\n    accountId\n    extendedProfile {\n      jobTitle\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b572b01dd624f95d8d78b8b3c4891a5";

export default node;
