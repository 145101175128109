import React from 'react';
import { defineMessage, useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';

import { ScreenType } from '../ScreenType';

import { BrowsePeopleFieldSection, BrowsePeopleFieldSectionSkeleton } from './BrowsePeopleFieldSection';
import { BrowseDepartmentSection$key } from './__generated__/BrowseDepartmentSection.graphql';

export interface BrowseFieldSectionProps {
  data: BrowseDepartmentSection$key;
}

const departmentSectionTitle = defineMessage({
  id: 'townsquare.staff-directory.browse-departments-section.title',
  description: 'Header text for the departments section of the staff directory',
  defaultMessage: 'Departments',
});

export const BrowseDepartmentsSectionSkeleton = () => {
  const intl = useIntl();
  return (
    <BrowsePeopleFieldSectionSkeleton
      browseAllScreenType={ScreenType.BROWSE_DEPARTMENTS}
      fieldType="DEPARTMENT"
      sectionTitle={intl.formatMessage(departmentSectionTitle)}
    />
  );
};

export const BrowseDepartmentsSection = (props: BrowseFieldSectionProps) => {
  const data = useFragment(
    graphql`
      fragment BrowseDepartmentSection on Query
      @argumentDefinitions(workspaceId: { type: "ID!" }, first: { type: "Int" }, after: { type: "String" }) {
        department: peopleFieldAggregates(
          workspaceId: $workspaceId
          peopleFieldType: DEPARTMENT
          first: $first
          after: $after
        ) {
          ...BrowsePeopleFieldSection
        }
      }
    `,
    props.data,
  );
  const intl = useIntl();

  return (
    data.department && (
      <BrowsePeopleFieldSection
        sectionTitle={intl.formatMessage(departmentSectionTitle)}
        fieldType="DEPARTMENT"
        browseAllScreenType={ScreenType.BROWSE_DEPARTMENTS}
        data={data.department}
      />
    )
  );
};
