import { GiveKudosLauncher } from '@atlaskit/give-kudos';
import React, { useCallback, useState } from 'react';

import { getConfig } from '@townsquare/config';
import { useFlagActions } from '@townsquare/flags';
import { useModalActions } from '@townsquare/modals';
import { useWorkspaceStore } from '@townsquare/workspace-store';
import { useWorkspaceType } from '@townsquare/workspace-store/hooks';

const config = getConfig();

export type GiveKudosModalOptions = {
  redirectOnSave?: boolean;
};
export type GiveKudosModalProps = GiveKudosModalOptions & {
  onClose: () => void;
};

export const GiveKudosModal = (props: GiveKudosModalProps) => {
  const [workspace] = useWorkspaceStore();
  const { addFlag } = useFlagActions();
  const [isOpen, setIsOpen] = useState(true);
  const workspaceType = useWorkspaceType();

  const handleOnCloseKudos = useCallback(() => {
    props.onClose();
    setIsOpen(false);
  }, [props]);

  const teamCentralUrl = workspaceType === 'GLOBAL_EXPERIENCE' ? config.homeUrl : config.classicUrl;

  return (
    <>
      <GiveKudosLauncher
        isOpen={isOpen}
        onClose={handleOnCloseKudos}
        analyticsSource="atlas"
        teamCentralBaseUrl={teamCentralUrl}
        cloudId={workspace.cloudId || ''}
        addFlag={addFlag}
      />
    </>
  );
};

export const useGiveKudosModal = () => {
  const { openModal, closeModal } = useModalActions();

  const openGiveKudosModal = (options: GiveKudosModalOptions) => {
    openModal('give-kudos-modal', {
      component: <GiveKudosModal {...options} onClose={() => closeModal('give-kudos-modal')} />,
    });
  };

  return {
    openGiveKudosModal,
  };
};
