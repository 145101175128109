import React from 'react';

import { LoadingSkeleton } from '@townsquare/loading-skeleton';

import * as styled from './styles';

export const StatusSummarySkeleton = () => {
  return (
    <styled.SummarySectionSkeleton>
      <styled.SummarySkeletonRow>
        {Array.from({ length: 3 }).map((_, i) => (
          <styled.SummaryItem key={`project-summary-skeleton-${i}`} isDisabled>
            <LoadingSkeleton width="40%" height="32px" marginBottomPx={4} />
            <styled.TitlesContainer>
              <LoadingSkeleton width="60%" height="16px" marginBottomPx={4} />
              <LoadingSkeleton width="80%" height="12px" />
            </styled.TitlesContainer>
          </styled.SummaryItem>
        ))}
      </styled.SummarySkeletonRow>
      <styled.SummarySkeletonRow>
        {Array.from({ length: 3 }).map((_, i) => (
          <styled.SummaryItem key={`project-summary-skeleton-${i}`} isDisabled>
            <LoadingSkeleton width="40%" height="32px" marginBottomPx={4} />
            <styled.TitlesContainer>
              <LoadingSkeleton width="60%" height="16px" marginBottomPx={4} />
              <LoadingSkeleton width="80%" height="12px" />
            </styled.TitlesContainer>
          </styled.SummaryItem>
        ))}
      </styled.SummarySkeletonRow>
    </styled.SummarySectionSkeleton>
  );
};
