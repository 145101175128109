import React, { createContext, useEffect } from 'react';

import { defaultDateLocale, getDateFnsLocaleFromIntlLocale } from './locales';

const DateLocaleContext = createContext<Locale>(defaultDateLocale);

interface DateLocaleContextProvider {
  locale: string;
  children: React.ReactNode;
}

const DateLocaleContextProvider = ({ children, locale }: DateLocaleContextProvider) => {
  const [dateLocale, setDateLocale] = React.useState<Locale>(defaultDateLocale);

  useEffect(() => {
    getDateFnsLocaleFromIntlLocale(locale).then(loadedDateLocale => {
      setDateLocale(loadedDateLocale);
    });
  }, [locale]);

  return <DateLocaleContext.Provider value={dateLocale}>{children}</DateLocaleContext.Provider>;
};

export const useDateLocale = () => React.useContext(DateLocaleContext);

export default DateLocaleContextProvider;
