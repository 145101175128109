import {
  ContainerData,
  ContainerList,
  ContainerTypes,
  createGetContainersPlugin,
} from '@atlassiansox/cross-flow-plugins';

import { getParams } from '@townsquare/routing/utils';

// Required for the grant access screen - which we only display to tenanted
// expands (i.e. WAC to START, Watermelon ATLAS-2132)
const getCloudId = async (): Promise<ContainerList> => {
  const { startTrialCloudId, startTrialInstance } = getParams('startTrialCloudId', 'startTrialInstance');
  const containers: ContainerData[] = [];

  if (startTrialCloudId && startTrialInstance) {
    containers.push({
      id: startTrialCloudId,
      displayName: startTrialInstance,
      type: ContainerTypes.ATLASSIAN_CLOUD_ID,
    });
  }

  return {
    type: ContainerTypes.ATLASSIAN_CLOUD_ID,
    containers,
  };
};

export const getContainersPlugin = createGetContainersPlugin(getCloudId);
