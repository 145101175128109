import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { captureException } from '@sentry/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { useRelayEnvironment } from 'react-relay';

import { useWorkspaceStore } from '@townsquare/workspace-store';

import { isComparatorMultiValued } from '../tql';
import {
  ComparatorOperator,
  Content,
  Entity,
  FilterDoc,
  onComparatorChangeFn,
  onOperatorChangeFn,
  onRemoveFn,
  onRootOperatorChangeFn,
  onValueFn,
  Operators,
  OptionTypes,
  Resolvers,
  SelectResolverOptions,
  SupportedFilters,
} from '../types';
import { findFilterComparatorOnResolver, findFilterOperatorOrDefault } from '../util';

import AddEntity from './AddEntity';
import { FilterGroupWrapper } from './FilterGroup';
import { FilterTag } from './FilterTag';
import { ComparatorPicker, OperatorPicker, RootOperatorPicker } from './OperatorPicker';
import { ResolverIcon } from './ResolverIcon';
import { RemoveIcon } from './icons/Remove';
import { FilterWrapperNoTitle, Item, RootOperatorWrapper } from './style';

const boxStyles = xcss({
  display: 'flex',
});

interface Props {
  document: FilterDoc;
  resolvers: Resolvers;
  onValue: onValueFn;
  onRemove: onRemoveFn;
  onOperatorChange: onOperatorChangeFn;
  onRootOperatorChange: onRootOperatorChangeFn;
  onComparatorChange: onComparatorChangeFn;
}

const findResolverByType = (type: SupportedFilters, resolvers: Resolvers) => {
  return resolvers.find(resolver => resolver.type === type);
};

const findLabelForValue = (value: Content, options: SelectResolverOptions) => {
  return options.find(item => item.value === value)?.label;
};

interface FilterGroupProps {
  entity: Entity;
  entityIndex: number;
  isLastEntity: boolean;
  resolvers: Resolvers;
  onValue: onValueFn;
  onRemove: onRemoveFn;
  onOperatorChange: onOperatorChangeFn;
  onComparatorChange: onComparatorChangeFn;
}

const FilterGroup = (props: FilterGroupProps) => {
  const environment = useRelayEnvironment();
  const [operatorHovered, setOperatorHover] = useState(false);
  const [comparatorHovered, setComparatorHover] = useState(false);
  const [isLoadingLabels, setLoadingLabels] = useState(true);
  const [labels, setLabels] = useState<SelectResolverOptions>([]);
  const [selectedOperator, setSelectedOperator] = useState<Operators>(props.entity.operator);
  const [selectedComparator, setSelectedComparator] = useState<ComparatorOperator>(props.entity.comparator);
  const resolver = findResolverByType(props.entity.type, props.resolvers);
  const [{ globalId: workspaceGlobalId, UUID: workspaceUUID }] = useWorkspaceStore();
  const filterComparator = useMemo(
    () => findFilterComparatorOnResolver(selectedComparator, resolver),
    [resolver, selectedComparator],
  );

  useEffect(() => {
    if (resolver) {
      // Don't fetch labels we already have values for.
      // This array should only populate on initial load as we
      // try to optimistically store labels to reduce network traffic.
      const labelsToResolve = props.entity.model.filter(value => !labels.find(label => label.value === value));
      if (labelsToResolve.length) {
        setLoadingLabels(true);
        resolver
          .resolveLabels({
            values: labelsToResolve,
            relayEnvironment: environment,
            workspaceId: workspaceGlobalId,
            workspaceUUID,
          })
          .then(data => {
            setLabels(prev => prev.concat(...data));
            setLoadingLabels(false);
          })
          .catch(reason => captureException(reason));
      }
    } else {
      setLoadingLabels(false);
      setLabels([]);
    }
    // FIXME: TC-4035
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolver, props.entity.model]);

  if (!resolver) {
    return null;
  }

  const onComparatorPickerChange = (newComparator: ComparatorOperator) => {
    const comparatorOption = resolver.filterComparators.find(item => item.comparatorOption === newComparator);
    if (comparatorOption) {
      // if the currently selected operator does not apply to this comparator, change it
      const newOperator = findFilterOperatorOrDefault(selectedOperator, comparatorOption.allowedOperators);
      if (newOperator && newOperator !== selectedOperator) {
        props.onComparatorChange(props.entityIndex, props.entity.type, newComparator, newOperator);
        setSelectedOperator(newOperator);
      } else {
        props.onComparatorChange(props.entityIndex, props.entity.type, newComparator);
      }
      setSelectedComparator(newComparator);
    }
  };

  return (
    <>
      {props.entity.model.map((content, contentIndex, contents) => {
        const label = findLabelForValue(content, labels);
        const isToggleOptionType = resolver.optionType === OptionTypes.TOGGLE;
        return (
          <FilterGroupWrapper
            key={`filter-group-${contentIndex}`}
            testId={`filter-group-${props.entityIndex}`}
            isLastInGroup={contentIndex === contents.length - 1}
            isFirstInGroup={contentIndex === 0}
            isLastEntity={props.isLastEntity}
            isLoadingLabels={isLoadingLabels}
          >
            {contentIndex === 0 && (
              <>
                <ResolverIcon resolver={resolver} />
                {resolver.filterComparators.length > 1 ? (
                  <>
                    {!isToggleOptionType && (
                      <span style={{ margin: `0 ${token('space.050')} 0 ${token('space.100')}` }}>
                        {resolver.title}
                      </span>
                    )}
                    <Item
                      onMouseLeave={() => setComparatorHover(false)}
                      onMouseEnter={() => setComparatorHover(true)}
                      isHovered={comparatorHovered}
                    >
                      <ComparatorPicker
                        selected={selectedComparator}
                        filterComparators={resolver.filterComparators}
                        onChange={onComparatorPickerChange}
                      />
                    </Item>
                  </>
                ) : (
                  <span style={{ margin: `0 ${token('space.050')} 0 ${token('space.025')}` }}>
                    {resolver.title}&nbsp;
                    {filterComparator?.comparatorText || (
                      <FormattedMessage
                        id="townsquare.tql.filter-document.default-comparator-label"
                        description="Default comparator label"
                        defaultMessage="is"
                      />
                    )}
                  </span>
                )}
              </>
            )}
            <Item>
              {isToggleOptionType ? (
                <FilterWrapperNoTitle appearance="default">
                  <Box
                    xcss={boxStyles}
                    onClick={() => {
                      props.onRemove(props.entityIndex, props.entity.type, contentIndex);
                    }}
                  >
                    <RemoveIcon size="medium" label="" />
                  </Box>
                </FilterWrapperNoTitle>
              ) : (
                <FilterTag
                  removeButtonLabel="Remove"
                  appearance={!isLoadingLabels && !label ? 'danger' : 'default'}
                  onRemove={() => {
                    props.onRemove(props.entityIndex, props.entity.type, contentIndex);
                  }}
                  labelForTestId={label}
                >
                  {isLoadingLabels && !label ? (
                    <em>
                      <FormattedMessage
                        id="townsquare.tql.filter-document.loading"
                        description="Loading message"
                        defaultMessage="Loading..."
                      />
                    </em>
                  ) : (
                    label ?? (
                      <FormattedMessage
                        id="townsquare.tql.filter-document.not-found"
                        description="Resolver not found message"
                        defaultMessage="{resolver} not found"
                        values={{
                          resolver: resolver.title,
                        }}
                      />
                    )
                  )}
                </FilterTag>
              )}
            </Item>
            {contentIndex !== contents.length - 1 ? (
              <Item
                onMouseLeave={() => setOperatorHover(false)}
                onMouseEnter={() => setOperatorHover(true)}
                isHovered={operatorHovered}
              >
                <OperatorPicker
                  selected={selectedOperator}
                  filterComparator={resolver.filterComparators.find(
                    option => option.comparatorOption === selectedComparator,
                  )}
                  onClose={() => setOperatorHover(false)}
                  onChange={newOperator => {
                    props.onOperatorChange(props.entityIndex, props.entity.type, newOperator);
                    setSelectedOperator(newOperator);
                  }}
                />
              </Item>
            ) : (
              (filterComparator?.allowedOperators?.length ||
                isComparatorMultiValued(filterComparator?.comparatorOption)) && (
                <AddEntity
                  resolver={resolver}
                  onChange={option => {
                    if (Array.isArray(option)) {
                      // TODO TC-6178: handle number resolver options
                      return;
                    }
                    props.onValue(
                      props.entityIndex,
                      resolver.type,
                      option.value,
                      resolver.filterComparators.flatMap(item => item.allowedOperators.map(op => op.operator)),
                      resolver.filterComparators.map(comp => comp.comparatorOption),
                    );
                    setLabels(prev => prev.concat(option));
                  }}
                />
              )
            )}
          </FilterGroupWrapper>
        );
      })}
    </>
  );
};

const FilterDocument = (props: Props) => {
  const [operatorHovered, setOperatorHover] = useState(false);
  return (
    <>
      {props.document.model.map((entity, entityIndex, entities) => {
        return (
          <Fragment key={`filter-doc-${entityIndex}:${entity.comparator}`}>
            <FilterGroup
              entity={entity}
              entityIndex={entityIndex}
              isLastEntity={entityIndex === entities.length - 1}
              resolvers={props.resolvers}
              onValue={props.onValue}
              onRemove={props.onRemove}
              onOperatorChange={props.onOperatorChange}
              onComparatorChange={props.onComparatorChange}
            />
            {entityIndex !== entities.length - 1 && (
              <RootOperatorWrapper
                onMouseLeave={() => setOperatorHover(false)}
                onMouseEnter={() => setOperatorHover(true)}
                isHovered={operatorHovered}
              >
                <RootOperatorPicker
                  selected={props.document.operator}
                  rootOperators={[Operators.OR, Operators.AND]}
                  onClose={() => setOperatorHover(false)}
                  onChange={operator => {
                    props.onRootOperatorChange(operator);
                  }}
                />
              </RootOperatorWrapper>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default FilterDocument;
