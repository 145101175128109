import ModalDialog, { ModalBody } from '@atlaskit/modal-dialog';
import React, { ReactNode, useState } from 'react';

import { PRODUCT_IDENTIFIER } from '@townsquare/config/constants';
import { useFlagActions } from '@townsquare/flags';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import InvitePeople from './components/AkInvitePeople';

export interface TriggerInviteUserModalProps {
  onInviteSent?: () => void;
  onCancel?: () => void;
  analyticsCtx?: object;
}

export type TriggerInviteUserModalCallback = () => void;

export type InviteUserModalReturn = [ReactNode, TriggerInviteUserModalCallback];

export const useInviteUserModal = (props: TriggerInviteUserModalProps = {}): InviteUserModalReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [{ cloudId }] = useWorkspaceStore();
  const { addFlag } = useFlagActions();

  const { onInviteSent, onCancel, analyticsCtx } = {
    onInviteSent: () => null,
    onCancel: () => null,
    ...props,
  };

  const callback: TriggerInviteUserModalCallback = () => setIsOpen(true);

  const onClose = () => {
    setIsOpen(false);
    onCancel();
  };

  return [
    isOpen ? (
      <ModalDialog autoFocus={false} onClose={onClose} width="small">
        <ModalBody>
          <InvitePeople
            resourceAri={`ari:cloud:${PRODUCT_IDENTIFIER}::site/${cloudId}`}
            onCancelHandler={onClose}
            onSendHandler={() => {
              setIsOpen(false);
              onInviteSent();
            }}
            showFlag={flag => addFlag(flag)}
            analyticsContext={analyticsCtx}
          />
        </ModalBody>
      </ModalDialog>
    ) : null,
    callback,
  ];
};
