import { PageLoadMetric } from '@atlassian/browser-metrics';
import { useLayoutEffect } from 'react';

type Props = { metric: PageLoadMetric; data?: { [key: string]: string | boolean | number } };

export const SubmitPageLoadMetrics = (props: Props) => {
  useLayoutEffect(() => {
    props.metric.stop({ customData: props.data });
  }, [props.metric]);

  return null;
};
