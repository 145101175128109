import React from 'react';
import Loadable from 'react-loadable';

import { AnalyticsContext, useAnalytics, wrapAnalyticsClientWithExtraAttributes } from '@townsquare/analytics';

const ReadingModeFullScreen = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "ReadingModeFullScreen" */ './ReadingModeFullScreen'),
  loading: () => null,
});

export * from './types';

const overrideAnalyticsAttributes = {
  screen: 'readingMode',
};

export default () => {
  const analytics = wrapAnalyticsClientWithExtraAttributes(useAnalytics(), overrideAnalyticsAttributes);
  return (
    <AnalyticsContext.Provider value={analytics}>
      <ReadingModeFullScreen />
    </AnalyticsContext.Provider>
  );
};
