import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

import { useSiteBanner } from '@townsquare/site-banner';

import { baseColumnStyles, responsiveGridSizeTimes } from './ResponsiveGrid';

/**
 * A narrow column specifically for secondary navigations that shrink down to a single icon on narrow screens.
 */
export function NavColumn(props: { children: React.ReactNode; width?: number; padding?: string }) {
  const { height } = useSiteBanner();

  return <NavColumnInner {...props} topBannerHeight={height} />;
}

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NavColumnInner = styled.div<{ width?: number; padding?: string; topBannerHeight: number }>`
  ${baseColumnStyles};
  position: sticky;
  top: ${props => props.topBannerHeight}px;
  height: calc(100vh - ${props => props.topBannerHeight}px);
  padding: 0;
  border-right: solid 1px ${token('color.border')};
  min-width: ${props => props.width ?? responsiveGridSizeTimes(2.5)}px;
  flex: 0 0 ${props => props.width ?? responsiveGridSizeTimes(2.5)}px;

  &:last-of-type {
    margin-bottom: 0;
  }

  // AtlasKit side nav element
  > nav {
    min-width: ${props => props.width ?? responsiveGridSizeTimes(2.5)}px;
    background-color: transparent;
    z-index: 1;
    padding: ${props => props.padding ?? 0};
    box-sizing: border-box;

    button {
      background-color: transparent;
    }
  }
`;
