import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
  projectCopyText: {
    description: 'Description for the project copy link icon',
    defaultMessage: 'Copy link to project',
    id: 'townsquare.copy-link-icon.project-copy.text',
  },
  learningCopyText: {
    description: 'Description for the learning copy link icon',
    defaultMessage: 'Copy link to learning',
    id: 'townsquare.copy-link-icon.learning-copy.text',
  },
  riskCopyText: {
    description: 'Description for the risk copy link icon',
    defaultMessage: 'Copy link to risk',
    id: 'townsquare.copy-link-icon.risk-copy.text',
  },
  decisionCopyText: {
    description: 'Description for the decision copy link icon',
    defaultMessage: 'Copy link to decision',
    id: 'townsquare.copy-link-icon.decision-copy.text',
  },
  copiedText: {
    description: 'Description for the copied text',
    defaultMessage: 'Copied',
    id: 'townsquare.copy-link-icon.copied.text',
  },
});
