import Spinner from '@atlaskit/spinner';
import React from 'react';

import { SpinnerWrapper } from './styles';
import { LoadingStateProps } from './types';

export const LoadingState = ({ size = 'medium' }: LoadingStateProps) => (
  <SpinnerWrapper size={size}>
    <Spinner size={size} />
  </SpinnerWrapper>
);
