import React, { useCallback, useEffect, useState } from 'react';
import { useQueryParam } from 'react-resource-router';

import { getLogInUrl, getNoAccessLink, isAllowedAnonymousRoute } from '@townsquare/auth-utils';
import { isInIframe } from '@townsquare/config';
import { redirect as redirectFacade } from '@townsquare/facade';

import { FullScreenSpaLoader } from '../ui/FullScreenSpaLoader';

import { loginViaPopup } from './check';
import { prefetchedAuthCheckPromise } from './prefetch';

export function AuthCheck({ children }: { children: JSX.Element }) {
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [cloudId] = useQueryParam('cloudId');

  /**
   * Wrapper around the redirect facade to set the redirecting state,
   * this way we avoid some race conditions.
   */
  const redirect = (url: string) => {
    setIsRedirecting(true);
    redirectFacade(url);
  };

  const handleUserIsAuthenticated = useCallback(async () => {
    setHasCheckedAuth(true);
  }, []);

  const handleUnauthenticatedUser = useCallback(() => {
    if (isInIframe()) {
      loginViaPopup();
    } else {
      redirect(getLogInUrl());
    }
  }, []);

  const checkHasCorrectAuth = useCallback(async () => {
    const auth = await prefetchedAuthCheckPromise;

    if (auth.isAuthenticated) {
      // User is authenticated
      return handleUserIsAuthenticated();
    }

    if (auth.statusCode === 401) {
      return handleUnauthenticatedUser();
    }

    // Error code other than 401
    return redirect(getNoAccessLink(cloudId));
  }, [cloudId, handleUnauthenticatedUser, handleUserIsAuthenticated]);

  useEffect(() => {
    if (!isAllowedAnonymousRoute()) {
      void checkHasCorrectAuth();
    } else {
      setHasCheckedAuth(true);
    }
  }, [checkHasCorrectAuth]);

  if (!hasCheckedAuth || isRedirecting) {
    return <FullScreenSpaLoader />;
  }

  return children;
}
