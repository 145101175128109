import { createActionsHook, createHook, createStore } from 'react-sweet-state';

import { CreateProjectProps } from './components/types';

const initialState: { open: boolean; options?: Partial<CreateProjectProps> } = {
  open: false,
  options: undefined,
};

const createProjectStore = createStore({
  initialState,
  actions: {
    openCreateProject:
      (options?: Partial<CreateProjectProps>) =>
      ({ setState }) => {
        setState({
          open: true,
          options,
        });
      },
    closeCreateProject:
      () =>
      ({ setState }) => {
        setState({ open: false, options: undefined });
      },
  },
  name: 'create-project-store',
});

export const useCreateProjectStore = createHook(createProjectStore);
export const useCreateProjectActions = createActionsHook(createProjectStore);
