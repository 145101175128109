import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import LabelEmbedQuery, {
  type LabelEmbedQuery as LabelEmbedQueryType,
} from '../components/Label/__generated__/LabelEmbedQuery.graphql';
import { TIMELINE_START } from '../components/Label/constants';

export const labelEmbedResource = createRelayResource<LabelEmbedQueryType>({
  getQuery: (routerContext, resourceStoreContext) => {
    const resourceContext = resourceStoreContext as ResourceContext;
    const labelUuid = routerContext.match.params.uuid ?? '';
    const workspaceUuid = resourceContext.workspace?.UUID ?? '';

    const tql = tqlQueryFromInput({
      input: [
        {
          fieldName: 'archived',
          fieldValue: false,
          comparator: ComparatorOperator.EQUALS,
        },
        {
          fieldName: 'label',
          fieldValue: labelUuid,
          comparator: ComparatorOperator.EQUALS,
        },
        {
          fieldName: 'startDate',
          fieldValue: TIMELINE_START,
          comparator: ComparatorOperator.LT,
        },
        {
          fieldName: 'targetDate',
          fieldValue: TIMELINE_START,
          comparator: ComparatorOperator.GTE,
        },
      ],
    });

    return {
      query: LabelEmbedQuery,
      variables: {
        uuid: labelUuid,
        workspaceUuid,
        tql,
      },
    };
  },
});
