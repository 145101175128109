import { css } from 'styled-components';

/*
 * Valid usages:

 * ```tsx
 * styled.div`
 *   color: 'red';
 *   ${ellipsis}
 * `;
 *
 * styled.div({ color: 'red' }, ellipsis);
 * ```
 *
 * Invalid usages:
 * ```
 * styled.div(ellipsis);
 * styled.div({ ...ellipsis });
 * ```
 */
// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Ignored via go/DSP-18766
export const ellipsis = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

/*
 * Include a lineHeight for content which can be more than a single paragraph of text
 * (e.g. is ADF, can contain line breaks, can contain bullet points, etc.), as line-clamp
 * will not work in these cases. If you haven't changed the line height in the component,
 * rootAbsoluteLineHeight is a good default.
 */
export const multilineEllipsis = (linesBeforeEllipsis: number, lineHeight?: number) => {
  return css({
    display: '-webkit-box',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    WebkitLineClamp: linesBeforeEllipsis,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    ...(lineHeight ? { maxHeight: `${linesBeforeEllipsis * lineHeight}px;` } : {}),
  });
};
