// Anything below 650px will trigger mobile mode
export const MOBILE_MODE_WIDTH_THRESHOLD = 650;

export const SMALL_DESKTOP_WIDTH_THRESHOLD = 1366;

export const SMALL_MOBILE_WIDTH_THRESHOLD = 475;

/**
 * Minimum page width for non-responsive pages: 768px
 * From Atlassian Cloud minimum screen size:
 * https://confluence.atlassian.com/cloud/supported-browsers-744721663.html
 */
export const RESPONSIVE_CONTENT_WIDTH_THRESHOLD = 768;

/**
 * Threshold width where layout switches between narrow and standard views.
 *
 *   768 (Min page layout content width) +
 *    64 (width of global nav) +
 * +  34 (Total unavoidable ancentral padding-left/margin-left)
 * = Responsive threshold width
 */
export const RESPONSIVE_THRESHOLD = RESPONSIVE_CONTENT_WIDTH_THRESHOLD + 64;

/**
 * Threshold width where layout switches between standard view that's good for larger laptop screen (aka 15")
 * to a "less spacious" layout that's better for a smaller laptop screen (aka 13")
 *
 * This is currently set to 1280px width
 */
export const SMALL_LAPTOP_SCREEN_RESPONSIVE_THRESHOLD = RESPONSIVE_CONTENT_WIDTH_THRESHOLD + 512;
