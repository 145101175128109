export const orgPrefixKey = 'orgPrefix';
export const orgIdentifierKey = 'orgIdentifier';

export const ORG_PREFIX = 'o';
export const SITE_PREFIX = 's';

export const identifierPaths = {
  orgPrefix: `/:${orgPrefixKey}(${ORG_PREFIX})`,
  orgIdentifier: `/:${orgIdentifierKey}`,
  sitePrefix: `/:sitePrefix(${SITE_PREFIX})`,
  siteIdentifier: '/:siteIdentifier',
} as const;

export const OPTIONAL_BASE_PATH_PATTERN =
  `${identifierPaths.orgPrefix}?${identifierPaths.orgIdentifier}?${identifierPaths.sitePrefix}?${identifierPaths.siteIdentifier}(.*)?` as const;
export const REQUIRED_BASE_PATH_PATTERN =
  `${identifierPaths.orgPrefix}${identifierPaths.orgIdentifier}${identifierPaths.sitePrefix}${identifierPaths.siteIdentifier}` as const;

export type optionalBasePathPattern = typeof OPTIONAL_BASE_PATH_PATTERN;
export type requiredBasePathPattern = typeof REQUIRED_BASE_PATH_PATTERN;
