import { Text } from '@atlaskit/primitives';
import { format, utcToZonedTime } from 'date-fns-tz';
import React from 'react';

export interface UserCurrentTimeProps {
  timezone: string | undefined;
}
export const UserCurrentTime = ({ timezone }: UserCurrentTimeProps) => {
  if (!timezone) {
    return null;
  }

  const timezoneDate = utcToZonedTime(Date.now(), timezone);
  return (
    <Text as="p" color="color.text.subtlest">
      {format(timezoneDate, 'p').toLowerCase()}
    </Text>
  );
};
