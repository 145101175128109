import React from 'react';

import { Onboarding, OnboardingItemKey } from '@townsquare/onboarding';
import { ATLAS_INVITING_EXPERIMENT_A, useIsExperimentEnabled } from '@townsquare/stat-sig';
import { useNoWorkspaceExperience, useWorkspaceStore } from '@townsquare/workspace-store';

import { AddPeopleButton } from './AddPeopleButton';

const EXPERIMENT_VARIATION_COHORT = 'variation_1';
const onboardingKey: OnboardingItemKey = 'ADD_PEOPLE_BANNER';

export function AddPeople() {
  const [workspace] = useWorkspaceStore();
  const noWorkspaceExperience = useNoWorkspaceExperience();
  const userHasInvitePermission = workspace.capabilities?.inviteToWorkspace === true;

  const invitingExperimentEnabled = useIsExperimentEnabled(
    ATLAS_INVITING_EXPERIMENT_A,
    EXPERIMENT_VARIATION_COHORT,
    undefined,
    userHasInvitePermission,
  );

  if (!invitingExperimentEnabled || !userHasInvitePermission || noWorkspaceExperience) {
    return null;
  }

  return (
    <>
      <Onboarding
        onboardingKey={onboardingKey}
        onboardedContent={(connectionId: string) => (
          <AddPeopleButton connectionId={connectionId} displayOnboarding={false} />
        )}
      >
        {(connectionId: string) => <AddPeopleButton connectionId={connectionId} displayOnboarding={true} />}
      </Onboarding>
    </>
  );
}
