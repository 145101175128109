import React from 'react';
import { useIntl } from 'react-intl-next';

import { ERROR_IMAGE_STORM_WITH_MEEPLES } from '../../ErrorResources';

import BasicError from './BasicError';

export default () => {
  const intl = useIntl();

  const textItems = [
    intl.formatMessage({
      id: 'townsquare.error.network5xx.title',
      description: 'Title of generic error message',
      defaultMessage: 'Something went wrong.',
    }),
    intl.formatMessage({
      id: 'townsquare.error.network5xx.description',
      description: 'Body of generic error message',
      defaultMessage: "We're working to restore your service.",
    }),
  ];
  return <BasicError img={ERROR_IMAGE_STORM_WITH_MEEPLES} errorText={textItems} />;
};
