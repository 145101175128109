import { Environment, fetchQuery, graphql } from 'react-relay';

import {
  getNoAccessLink,
  getNoWorkspaceLink,
  getUserAccessUrlForRedirectWithContinueUrl,
} from '@townsquare/auth-utils';
import { redirect, location } from '@townsquare/facade';
import { getQueryParam } from '@townsquare/url-utils/search-params';

import {
  resolveWorkspaceQuery,
  resolveWorkspaceQuery$data,
  WorkspaceContextInput,
} from './__generated__/resolveWorkspaceQuery.graphql';

const FORCE_NO_WORKSPACE_EXPERIENCE_QUERYSTRING = 'forceNoWorkspaceExperience';

function redirectIfDifferent(url: string) {
  const loc = location();
  if (url.startsWith('/')) {
    if (loc.pathname + loc.search !== url) {
      redirect(url);
      return;
    }
  } else if (loc.href !== url) {
    redirect(url);
    return;
  }

  return true;
}

export const resolveActiveWorkspaceByContext = async (
  environment: Environment,
  cloudId: string,
  workspaceContext?: WorkspaceContextInput,
  continueUrlOverride?: string,
  abortSignal?: AbortSignal,
): Promise<resolveWorkspaceQuery$data | true | undefined> => {
  const forceNoWorkspace = getQueryParam(FORCE_NO_WORKSPACE_EXPERIENCE_QUERYSTRING) === 'true';

  if (forceNoWorkspace) {
    const noWorkspaceURL = new URL(getNoWorkspaceLink(cloudId), window.document.location.origin);
    noWorkspaceURL.searchParams.set(FORCE_NO_WORKSPACE_EXPERIENCE_QUERYSTRING, 'true');
    return redirectIfDifferent(noWorkspaceURL.toString());
  }

  // Coming from Switcher or expand flow expecting a specific cloudId/workspace
  // Navigating to a specific goal / project page fetch its workspace
  // Otherwise fetch the user preferred workspace
  try {
    const data = await fetchQuery<resolveWorkspaceQuery>(
      environment,
      graphql`
        query resolveWorkspaceQuery($workspaceContext: WorkspaceContextInput) {
          workspaceByContext(workspaceContext: $workspaceContext) @required(action: THROW) {
            ... on WorkspaceByContextAccessDeniedResponse {
              __typename
            }
            ... on WorkspaceByContextErrorResponse {
              __typename
            }
            ... on WorkspaceByContextProductRecommendationResponse {
              __typename
              cloudId @required(action: THROW)
            }
            ... on WorkspaceByContextSuccessfulResponse {
              __typename
              ...BootstrapWorkspaceData
              ...storeWorkspace
            }
          }
        }
      `,
      {
        workspaceContext: workspaceContext,
      },
      {
        networkCacheConfig: {
          metadata: {
            abortSignal,
          },
        },
      },
    ).toPromise();

    switch (data?.workspaceByContext.__typename) {
      case 'WorkspaceByContextProductRecommendationResponse':
        // Redirect to identity OR YOUR_WORK if joinable workspace
        return redirectIfDifferent(
          getUserAccessUrlForRedirectWithContinueUrl(data.workspaceByContext.cloudId, continueUrlOverride),
        );
      case 'WorkspaceByContextSuccessfulResponse': {
        return data;
      }
      case 'WorkspaceByContextAccessDeniedResponse':
        return redirectIfDifferent(getNoAccessLink(cloudId));
      case 'WorkspaceByContextErrorResponse':
      default:
        return redirectIfDifferent(getNoWorkspaceLink(cloudId));
    }
  } catch (error) {
    return redirectIfDifferent(getNoWorkspaceLink(cloudId));
  }
};
