/**
 * @generated SignedSource<<c45badeadddd810df4bdfc60d6fe92ce>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Edition = "BETA" | "CUSTOM" | "FREE" | "PREMIUM" | "STANDARD";
export type GoalScoringMode = "OKR" | "SIMPLE";
export type WorkspaceType = "GLOBAL_EXPERIENCE" | "PRODUCT";
import type { FragmentRefs } from "relay-runtime";
export type storeWorkspace$data = {
  readonly workspace: {
    readonly activationId: string | null;
    readonly aiConfig: {
      readonly enabled: boolean;
    } | null;
    readonly cloudId: string;
    readonly cloudName: string | null;
    readonly cloudUrl: string | null;
    readonly featureContext: {
      readonly edition: Edition | null;
      readonly entitlementId: string | null;
      readonly features: {
        readonly customFieldsLimit: number;
        readonly goalScoringMode: boolean;
        readonly privateProjects: boolean;
      } | null;
      readonly transactionAccountId: string | null;
    } | null;
    readonly goalScoringMode: GoalScoringMode;
    readonly id: string;
    readonly keyPrefix: string;
    readonly name: string;
    readonly organisationId: string;
    readonly timezone: string;
    readonly type: WorkspaceType;
    readonly userHasInvitePermission: boolean | null;
    readonly userIsOrgAdmin: boolean;
    readonly uuid: string;
  } | null;
  readonly workspaceCount: number | null;
  readonly " $fragmentType": "storeWorkspace";
};
export type storeWorkspace$key = {
  readonly " $data"?: storeWorkspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"storeWorkspace">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "storeWorkspace"
};

(node as any).hash = "cd42c2139dcd1b376473ba74dcca25a1";

export default node;
