import React, { ReactElement, Suspense } from 'react';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { useRelayResource } from '@townsquare/relay-utils';

import { onboardingResource } from '../resources/onboarding';

import { OnboardingItemKey, OnboardingQuery } from './__generated__/OnboardingQuery.graphql';

interface OnboardingBannerProps extends OnboardingProps {
  queryRef: PreloadedQuery<OnboardingQuery>;
}

interface OnboardingProps {
  onboardingKey: OnboardingItemKey;
  children(data: string): ReactElement; // Children will be shown if the users is being onboarded.
  onboardedContent?(data: string): ReactElement; // Optional children will be shown if the user is not being onboarded.
}

/**
 * Wrapping component that handles showing / hiding onboarding elements.
 *
 * e.g.
 * function ProjectUpdatesOnboardingBanner() {
 *    return (
 *      <Onboarding onboardingKey="project-updates-banner">
 *        <ProjectUpdatesBanner />
 *      </Onboarding>
 *    )
 * }
 */
const OnboardingBanner = (props: React.PropsWithChildren<OnboardingBannerProps>) => {
  const data = usePreloadedQuery(
    graphql`
      query OnboardingQuery {
        currentUser {
          onboarding(first: 500) @connection(key: "OnboardingList__onboarding") {
            __id
            edges {
              node {
                key
              }
            }
          }
        }
      }
    `,
    props.queryRef,
  );

  const connectionId: string = data.currentUser?.onboarding?.__id ?? '';

  // If the user has previously dismissed this banner, dont show it again.
  // If an onboardedContent prop is provided, show that instead.
  if (data?.currentUser?.onboarding?.edges?.find(edge => edge?.node?.key === props.onboardingKey)) {
    return props.onboardedContent ? <>{props.onboardedContent(connectionId)}</> : null;
  }

  return <>{props.children(connectionId)}</>;
};

export const Onboarding = (props: React.PropsWithChildren<OnboardingProps>) => {
  const queryRef = useRelayResource(onboardingResource);

  if (!queryRef) {
    return null;
  }

  return (
    <Suspense fallback={false}>
      <OnboardingBanner
        queryRef={queryRef}
        onboardingKey={props.onboardingKey}
        onboardedContent={props.onboardedContent}
      >
        {props.children}
      </OnboardingBanner>
    </Suspense>
  );
};
