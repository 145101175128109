import {
  ComparatorOperator,
  Content,
  ExplainedInput,
  FieldValue,
  FilterComparators,
  FilterDoc,
  Input,
  InputOptions,
  Join,
  MultiValueComparator,
  MultiValueFilterComparator,
  Operators,
  Resolvers,
  SupportedFilters,
  SupportedFiltersTypes,
} from './types';
import {
  appendContentEntry,
  EMPTY_FILTER_DOC,
  findFilterComparatorOnResolver,
  hasContentEntry,
  updateOperatorEntry,
} from './util';

// TODO temporary, need to think more about how to better structure this package
export { EMPTY_FILTER_DOC };

/**
 * FilterDoc -> TQL Helpers
 */

/**
 * Takes a filter doc and reduces it into a simple TQL query
 * Roughly the output of:
 * ( entity.model joined by entity.operator ) joined by doc.operator
 * ( label = 'red' OR label = 'blue' ) OR (label = 'green' AND label = 'yellow' )
 *
 * If no input is provided this will result in an empty string which would return the
 * complete set of goals.
 */
export const CUSTOM_FIELD_PREFIX = 'cf:';

const isString = (str?: FieldValue): str is string => {
  return typeof str === 'string';
};

const isJoin = (input: Input | Join): input is Join => {
  return 'join' in input;
};

const maybeQuoteValue = (value: FieldValue, comparator: ComparatorOperator) => {
  if (comparator === ComparatorOperator.LIKE) {
    return `'${value}'`;
  }

  return value;
};

const applyInputToQuery = (query: string[]) => (input: Input | Join) => {
  if (isJoin(input)) {
    query.push(input.join.toLocaleUpperCase());
    return;
  }

  const value = input.fieldValue;
  if (value === undefined) {
    return;
  }
  if (isString(value)) {
    query.push(`(${input.fieldName} ${input.comparator} ${maybeQuoteValue(value.trim(), input.comparator)})`);
  } else {
    query.push(`(${input.fieldName} ${input.comparator} ${value})`);
  }
};

export const tqlQueryFromInput = (options: InputOptions) => {
  const doc = options?.doc;
  const input = options?.input;
  const query: string[] = [];

  if (input) {
    const inputQuery: string[] = [];
    const hasJoins = input.find(isJoin);
    input.forEach(applyInputToQuery(inputQuery));
    // If our inputs specify their own joins, respect them
    // You will need to define all joins between your entities
    // Otherwise if no joins found defaults to `AND`
    if (hasJoins) {
      query.push(inputQuery.join(' '));
    } else {
      query.push(...inputQuery);
    }
  }

  if (doc && Array.isArray(doc)) {
    doc.forEach(d => {
      if (d && d.model.length) {
        const filterDocJoins = processDoc(d);
        if (filterDocJoins.length) {
          query.push(`(${filterDocJoins})`);
        }
      }
    });
  } else if (doc && !Array.isArray(doc) && doc.model.length) {
    const filterDocJoins = processDoc(doc);
    if (filterDocJoins.length) {
      query.push(`(${filterDocJoins})`);
    }
  }

  // Dont want to support this style going forward.
  if (options?.filter) {
    query.push(...options.filter.applyTql());
  }

  return query.join(` AND `);
};

export const isComparatorMultiValued = (
  comparator: ComparatorOperator | undefined,
): comparator is MultiValueComparator => {
  return comparator === ComparatorOperator.IN || comparator === ComparatorOperator.NOT_IN;
};

export const isFilterComparatorMultiValued = (
  filterComparator: FilterComparators | undefined,
): filterComparator is MultiValueFilterComparator => {
  return isComparatorMultiValued(filterComparator?.comparatorOption);
};

const processDoc = (doc: FilterDoc) => {
  return doc.model
    .map(entity => {
      if (isComparatorMultiValued(entity.comparator)) {
        // For these comparators, we need to wrap the content in brackets and join the content with commas
        const multiValueContents = entity.model.map(
          content => `${typeof content === 'string' ? `'${content}'` : content}`,
        );
        return `${entity.type} ${entity.comparator} (${multiValueContents.join(',')})`;
      } else {
        // content is wrapped in quotes when it's a string as spaces in content can be ambiguous to TQL
        const clauses = entity.model.map(
          content => `${entity.type} ${entity.comparator} ${typeof content === 'string' ? `'${content}'` : content}`,
        );
        const join = ` ${entity.operator.toUpperCase()} `;
        return clauses.length ? `(${clauses.join(join)})` : '';
      }
    })
    .join(` ${doc.operator.toUpperCase()} `);
};

/**
 * TQL -> FilterDoc types, utils etc
 */
type ExplanationElement = ExplanationFields | ExplanationFields[];
export type Explanation = ExplanationElement[];

type ExplanationField = {
  field: SupportedFilters;
  op: ComparatorOperator;
  value: Content;
};

type AdditionalInputField = {
  field: string;
  op: ComparatorOperator;
  value: string;
};

type ExplanationFields = ExplanationField | AdditionalInputField | Operators;

function filterRootOperators(element: ExplanationElement): element is (ExplanationField | Operators)[] {
  return Array.isArray(element);
}

function findRootOperator(element: ExplanationElement): element is Operators {
  return !Array.isArray(element) && (Object.values(Operators) as unknown[]).includes(element);
}

function isOperator(element: ExplanationFields): element is Operators {
  return typeof element === 'string' && Object.values(Operators).includes(element);
}

function isRootOperator(element: ExplanationElement): element is Operators {
  return !Array.isArray(element) && (Object.values(Operators) as unknown[]).includes(element);
}

function isSupportedFilterType(field: string): boolean {
  // Cast to string[] here to expand out the type, TS doesn't like that the `field` can be either
  // `name` or `label` when `SupportedFiltersTypes` can only be `label` (as an example)
  return (Object.values(SupportedFiltersTypes) as string[]).includes(field) || field.startsWith(CUSTOM_FIELD_PREFIX);
}

function isValidFilterDocField(element: ExplanationFields): element is ExplanationField | Operators {
  return isOperator(element) || isSupportedFilterType(element.field);
}

function isValidAdditionalInputField(element: ExplanationFields): element is AdditionalInputField {
  return !isOperator(element) && !isSupportedFilterType(element.field);
}

function isNestedCompoundJoin(
  // Need to use this Array syntax to keep TS happy
  // when using .find
  element: ExplanationField | Array<ExplanationFields | ExplanationFields[]> | Operators,
): element is ExplanationFields[][] {
  return Array.isArray(element) && !!element.find(child => Array.isArray(child));
}

/**
 * If our TQL query contains any filters coming from our meta filter we need to flatten those fields down
 * one level since we are querying them like:
 * ( ( label = <uuid> or label = <uuid> ) and ( label = <uuid> ) )
 * We flatten them back down to:
 * ( label = <uuid> or label = <uuid> ) and ( label = <uuid> )
 * Our explanation is now a `flat` list of `field` and `operator` combinations.
 * The caveat here is we only flatten one level, if we want to support more levels
 * of nested parens then we will need to write a recursive check.
 */
export function flattenExplanation(explanation: Explanation): Explanation {
  const result: Explanation = [];
  return explanation.reduce((acc, current) => {
    if (isNestedCompoundJoin(current)) {
      acc.push(...current);
    } else {
      acc.push(current);
    }

    return acc;
  }, result);
}

/**
 * When we receive a TQL Explanation it may contain additional fields that can't be rendered
 * by the MetaFilters (e.g. text input / archived etc). Here we split the explanation into two
 * groups, FilterDoc fields and AdditionalInput fields returning these in a tuple.
 *
 * ```
 * An Explanations rough structure with FilterDoc fields and additional input:
 * [
 *  [
 *    {"field":"name","op":"LIKE","value":"a search term"}
 *  ],
 *  "and",
 *  [
 *    {"field":"archived","op":"=","value":true}
 *  ],
 *  "and",
 *  [
 *    [
 *      {"field":"label","op":"=","value":"862ff83b-0129-45ca-8c04-43a884386e57"},
 *      "or",
 *      {"field":"label","op":"=","value":"7a9aa62f-430d-4167-800c-f87179f67fa8"}
 *    ],
 *    "and",
 *    [
 *      {"field":"label","op":"=","value":"862ff83b-0129-45ca-8c04-43a884386e57"}
 *    ]
 *  ]
 * ]
 * ```
 *
 * If we encounter any fields that don't belong in a FilterDoc, based on the `field` value, we
 * add it to the `AdditionalInputField` array. Otherwise we add FilterDoc fields (RootOperators, Operators, Valid Fields)
 * sequentially. The only exception being is if we encounter a RootOperator and no fields have been added so far we will omit
 * those Operators until we have at least one field, this avoids having a RootOperator prepended the to FilterDoc.
 *
 * Given the above input we should expect a tuple like:
 *
 * ```
 * [ // Tuple
 *  [ // Explanation
 *    [ // FilterGroup
 *      {"field":"label","op":"=","value":"862ff83b-0129-45ca-8c04-43a884386e57"},
 *      "or", // FilterGroup Operator
 *      {"field":"label","op":"=","value":"7a9aa62f-430d-4167-800c-f87179f67fa8"}
 *    ],
 *    "and", // RootOperator
 *    [ // FilterGroup
 *      {"field":"label","op":"=","value":"862ff83b-0129-45ca-8c04-43a884386e57"}
 *    ]
 *  ],
 *  [ // AdditionalInputFields[]
 *    {"field":"name","op":"LIKE","value":"a search term"},
 *    {"field":"archived","op":"=","value":true}
 *  ]
 * ]
 * ```
 */
export function splitExplanationFields(explanation: Explanation): [Explanation, AdditionalInputField[]] {
  const filterDocFields: Explanation = [];
  const otherFields: AdditionalInputField[] = [];

  explanation.forEach(entry => {
    if (isRootOperator(entry)) {
      return filterDocFields.length !== 0 && filterDocFields.push(entry);
    }

    let filterDocEntries: ExplanationElement = [];
    let additionalInputFields: AdditionalInputField[] = [];
    if (Array.isArray(entry)) {
      filterDocEntries = entry.filter(isValidFilterDocField);
      additionalInputFields = entry.filter(isValidAdditionalInputField);
    } else if (isValidFilterDocField(entry)) {
      filterDocEntries = [entry];
    } else if (isValidAdditionalInputField(entry)) {
      additionalInputFields = [entry];
    }
    if (filterDocEntries.length) {
      filterDocFields.push(filterDocEntries);
    }
    if (additionalInputFields.length) {
      otherFields.push(...additionalInputFields);
    }
  });

  return [filterDocFields, otherFields];
}

export const inputFromTQLExplanation = (
  explantion: string | null | undefined,
  resolvers?: Resolvers,
): [FilterDoc, ExplainedInput] => {
  if (!explantion) {
    return [EMPTY_FILTER_DOC, new Map()];
  }

  try {
    const parsedExplanation: Explanation = JSON.parse(explantion);
    let doc = EMPTY_FILTER_DOC;
    const input: ExplainedInput = new Map();
    if (parsedExplanation.length) {
      const explanation = flattenExplanation(parsedExplanation);
      const [filterDocFields, inputFields] = splitExplanationFields(explanation);

      // We filter out RootOperators first since they're mostly redundant while building the
      // FilterDoc, below we extract the RootOperator since it's a single value on the doc.
      filterDocFields.filter(filterRootOperators).forEach((entity, index: number) => {
        let type: SupportedFilters;
        entity.forEach(entry => {
          if (isOperator(entry)) {
            doc = updateOperatorEntry(doc, index, type, entry);
          } else {
            // We should always hit a field first
            // The operator entry inside the explanation doesn't contain the field type
            // so save it here to use on the lines above
            type = entry.field;
            if (!hasContentEntry(doc, entry.field, index) && resolvers) {
              const resolver = resolvers.find(r => r.type === entry.field);
              doc = appendContentEntry(
                doc,
                index,
                entry.field,
                entry.value,
                resolver
                  ? findFilterComparatorOnResolver(entry.op, resolver)?.allowedOperators.map(op => op.operator)
                  : [],
                [entry.op],
              );
            } else {
              doc = appendContentEntry(doc, index, entry.field, entry.value, undefined, [entry.op]);
            }
          }
        });
      });

      // We want to find the last operator because in the event we have additional inputs
      // (e.g. name / archived etc) they are always joined as `AND` where the user defined
      // FilterDoc join would be represented at the last root operator.
      const rootOperator = explanation.reverse().find(findRootOperator);
      if (rootOperator) {
        doc.operator = rootOperator;
      }

      // Assign all additional inputs to a map so we can return them as a plain object.
      // Matching their input when building the TQL query.
      inputFields.forEach(entry => {
        input.set(entry.field, entry.value);
      });
    }
    return [doc, input];
  } catch (e) {
    return [EMPTY_FILTER_DOC, new Map()];
  }
};
