import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
  missingLearningSummary: {
    description: 'Error message for missing learning summary',
    defaultMessage: 'Please include a summary for your learning',
    id: 'townsquare.learnings.missing-learning.summary',
  },
  missingRiskSummary: {
    description: 'Error message for missing risk summary',
    defaultMessage: 'Please include a summary for your risk',
    id: 'townsquare.learnings.missing-risk.summary',
  },
  missingDecisionSummary: {
    description: 'Error message for missing decision summary',
    defaultMessage: 'Please include a summary for your decision',
    id: 'townsquare.learnings.missing-decision.summary',
  },
  missingLearningDescription: {
    description: 'Error message for missing learning description',
    defaultMessage: 'Please include a description for your learning',
    id: 'townsquare.learnings.missing-learning.description',
  },
  missingRiskDescription: {
    description: 'Error message for missing risk description',
    defaultMessage: 'Please include a description for your risk',
    id: 'townsquare.learnings.missing-risk.description',
  },
  missingDecisionDescription: {
    description: 'Error message for missing decision description',
    defaultMessage: 'Please include a description for your decision',
    id: 'townsquare.learnings.missing-decision.description',
  },
});
