import { borderRadius } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import React from 'react';

import { Operators } from '../../types';
import { OperatorLabel } from '../style';

import OperatorPickerPopup from './OperatorPickerPopup';
interface OperatorPickerProps {
  selected: Operators;
  onChange: (operator: Operators) => void;
  onClose: () => void;
  rootOperators: [Operators, ...Operators[]]; // must be at least one root operator
}

export const RootOperatorPicker = (props: OperatorPickerProps) => {
  const options = [];

  for (const operator of Object.values(Operators)) {
    if (props.rootOperators.includes(operator)) {
      options.push({ label: operator.toString(), value: operator });
    }
  }

  if (options.length < 2) {
    return <OperatorLabel>{props.rootOperators[0]}</OperatorLabel>;
  }

  return (
    <OperatorPickerPopup
      isRootOperator={true}
      onClose={props.onClose}
      selected={props.selected}
      onChange={props.onChange}
      options={options}
      buttonStyle={{
        padding: `${token('space.100')} ${token('space.050')}  ${token('space.100')} ${token('space.150')}`,
        height: 'auto',
        margin: `0 ${token('space.050')} 0 0`,
        borderRadius: `${borderRadius() * 2}px`,
      }}
    />
  );
};
