import { RequestError } from '@townsquare/error-state/classes';
import { RestClient } from '@townsquare/rest-client';

export type UserResponse = {
  account_id: string;
  email?: string;
  locale?: string;
  name: string;
  picture: string;
  zoneinfo?: string;
  extended_profile?: {
    job_title?: string;
  };
};

class IdentityClient extends RestClient {
  private getUserPromise: Promise<UserResponse> | null = null;

  getUser(): Promise<UserResponse> {
    if (!this.getUserPromise) {
      this.getUserPromise = this.getResource('/gateway/api/me');
    }
    return this.getUserPromise;
  }

  async getUserIsAuthenticated(options?: {
    forceRefresh?: boolean;
  }): Promise<{ isAuthenticated: boolean; statusCode: number }> {
    try {
      if (options?.forceRefresh) {
        this.getUserPromise = null;
      }
      await this.getUser();
      return {
        isAuthenticated: true,
        statusCode: 200,
      };
      // getResource throws on any !response.ok
    } catch (error) {
      this.getUserPromise = null;

      if (error instanceof RequestError) {
        return {
          isAuthenticated: false,
          statusCode: error.statusCode,
        };
      }

      return {
        isAuthenticated: false,
        statusCode: 401,
      };
    }
  }
}

const identityClient = new IdentityClient();

export { identityClient };
