import { ProjectOrGoalStatusPhase } from '@townsquare/status-summary';

// Maintain goalStatus and projectStatus filtering when swapping between project list and timeline view
export const getProjectAndGoalFilterQueries = (projectStatus: string | undefined, goalStatus: string | undefined) => {
  const projectFilterQueryParam = projectStatus ? `projectStatus=${projectStatus}` : '';

  const goalFilterQueryParam = goalStatus ? `goalStatus=${goalStatus}` : '';

  if (projectFilterQueryParam && goalFilterQueryParam) {
    return `?${projectFilterQueryParam}&${goalFilterQueryParam}`;
  }

  if (goalFilterQueryParam) {
    return `?${goalFilterQueryParam}`;
  }

  if (projectFilterQueryParam) {
    return `?${projectFilterQueryParam}`;
  }

  return '';
};

export const isStatusFilterValid = (newStatus: string) => {
  return Object.values(ProjectOrGoalStatusPhase).findIndex(s => s === newStatus) !== -1;
};
