import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { onMobile } from '@townsquare/responsive-grid';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchResultsContainer = styled.div(
  {
    display: 'flex',
    columnGap: token('space.200'),
    rowGap: token('space.200'),
    flexWrap: 'wrap',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  onMobile(
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css({
      gap: token('space.100'),
    }),
  ),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ScrollingResultsContainer = styled.div({
  marginBottom: token('space.500'),
});
