import { useEffect } from 'react';
import { useQueryParam } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useWorkspaceStore } from '@townsquare/workspace-store';

/**
 * Used for not redirecting user to the joinable workspace when they are redirected from Start.a.c
 */
export const useJoinableWorkspace = () => {
  const [, { setJoinableWorkspaceIdentifier }] = useWorkspaceStore();
  const analytics = useAnalytics();

  const [joinableWorkspace, setJoinableWorkspace] = useQueryParam('joinableWorkspace');

  useEffect(() => {
    if (joinableWorkspace) {
      setJoinableWorkspaceIdentifier(joinableWorkspace);
      analytics.setTopLevelAttributes({ joinableWorkspace });
      setJoinableWorkspace(undefined, 'replace');
    }
  }, [analytics, joinableWorkspace, setJoinableWorkspace, setJoinableWorkspaceIdentifier]);
};
