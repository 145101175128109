/**
 * @generated SignedSource<<6d45a5f14fc8105137c0e7b0869c0ddb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TeamHealthEmbedQuery$variables = {
  cloudId: string;
  hasMonitorStarted: boolean;
  organisationId: string;
  skipTeamsQuery: boolean;
  teamsTql: string;
  uuid: string;
};
export type TeamHealthEmbedQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Survey">;
};
export type TeamHealthEmbedQuery = {
  response: TeamHealthEmbedQuery$data;
  variables: TeamHealthEmbedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasMonitorStarted"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipTeamsQuery"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamsTql"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v6 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v7 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
},
v8 = {
  "kind": "Variable",
  "name": "uuid",
  "variableName": "uuid"
},
v9 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v10 = {
  "kind": "ScalarField",
  "name": "attribute"
},
v11 = {
  "kind": "ScalarField",
  "name": "description"
},
v12 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v13 = [
  (v6/*: any*/)
],
v14 = {
  "kind": "ScalarField",
  "name": "id"
},
v15 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "TeamHealthEmbedQuery",
    "selections": [
      {
        "args": [
          (v6/*: any*/),
          {
            "kind": "Variable",
            "name": "hasMonitorStarted",
            "variableName": "hasMonitorStarted"
          },
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "skipTeamsQuery",
            "variableName": "skipTeamsQuery"
          },
          {
            "kind": "Variable",
            "name": "teamsTql",
            "variableName": "teamsTql"
          },
          (v8/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Survey"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TeamHealthEmbedQuery",
    "selections": [
      {
        "concreteType": "TeamHealthMonitorQuestion",
        "kind": "LinkedField",
        "name": "teamHealthMonitorQuestions",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "displayNumber"
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ]
      },
      {
        "condition": "hasMonitorStarted",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v8/*: any*/)
            ],
            "concreteType": "TeamHealthMonitor",
            "kind": "LinkedField",
            "name": "teamHealthMonitorByUuid",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "args": (v13/*: any*/),
                "kind": "ScalarField",
                "name": "isComplete"
              },
              {
                "args": (v13/*: any*/),
                "concreteType": "TeamHealthMonitorResult",
                "kind": "LinkedField",
                "name": "teamHealthMonitorResults",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v10/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "notes"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "rating"
                  },
                  (v14/*: any*/)
                ]
              },
              {
                "args": (v13/*: any*/),
                "kind": "LinkedField",
                "name": "recommendedPlays",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "link"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "preparationTime"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "sessionDuration"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "playSize"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "attributes"
                      },
                      (v14/*: any*/)
                    ],
                    "type": "TeamHealthRecommendedPlay"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      (v11/*: any*/),
                      (v14/*: any*/)
                    ],
                    "type": "TeamHealthHealthyStatus"
                  }
                ]
              },
              (v14/*: any*/)
            ]
          }
        ]
      },
      {
        "condition": "skipTeamsQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 3
              },
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "q",
                "variableName": "teamsTql"
              }
            ],
            "concreteType": "TeamSearchResponseConnection",
            "kind": "LinkedField",
            "name": "teamsTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "TeamSearchResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "TeamSearchResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "smallAvatarImageUrl"
                      },
                      {
                        "concreteType": "TeamMemberConnection",
                        "kind": "LinkedField",
                        "name": "members",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "TeamMemberEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "TeamMember",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "UserPII",
                                    "kind": "LinkedField",
                                    "name": "pii",
                                    "plural": false,
                                    "selections": [
                                      (v15/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "picture"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "084e9318e38ede4f380ba3d64bfefc48",
    "id": null,
    "metadata": {},
    "name": "TeamHealthEmbedQuery",
    "operationKind": "query",
    "text": "query TeamHealthEmbedQuery(\n  $uuid: UUID!\n  $hasMonitorStarted: Boolean!\n  $skipTeamsQuery: Boolean!\n  $teamsTql: String!\n  $organisationId: String!\n  $cloudId: String!\n) {\n  ...Survey_1tBf3O\n}\n\nfragment HealthQuestion on TeamHealthMonitorQuestion {\n  displayName\n  displayNumber\n  description\n}\n\nfragment HealthQuestionSidebar on TeamHealthMonitorQuestion {\n  displayName\n  displayNumber\n  attribute\n}\n\nfragment Recommendations_PVNZy on TeamHealthMonitor {\n  recommendedPlays(cloudId: $cloudId) {\n    __typename\n    ... on TeamHealthRecommendedPlay {\n      name\n      description\n      link\n      preparationTime\n      sessionDuration\n      playSize\n      attributes\n      id\n    }\n    ... on TeamHealthHealthyStatus {\n      name\n      description\n      id\n    }\n  }\n}\n\nfragment Summary on TeamHealthMonitorQuestion {\n  displayName\n  attribute\n}\n\nfragment Survey_1tBf3O on Query {\n  teamHealthMonitorQuestions {\n    displayNumber\n    displayName\n    attribute\n    ...HealthQuestion\n    ...HealthQuestionSidebar\n    ...Summary\n  }\n  teamHealthMonitorByUuid(uuid: $uuid) @include(if: $hasMonitorStarted) {\n    __typename\n    isComplete(cloudId: $cloudId)\n    teamHealthMonitorResults(cloudId: $cloudId) {\n      __typename\n      attribute\n      notes\n      rating\n      id\n    }\n    ...Recommendations_PVNZy\n    id\n  }\n  teamsTql(q: $teamsTql, organisationId: $organisationId, cloudId: $cloudId, first: 3) @skip(if: $skipTeamsQuery) {\n    ...TeamPicker\n  }\n}\n\nfragment TeamPicker on TeamSearchResponseConnection {\n  edges {\n    node {\n      id\n      displayName\n      smallAvatarImageUrl\n      members {\n        edges {\n          node {\n            pii {\n              name\n              picture\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0bc292e516d3aa1dac31d6c5b588c3ee";

export default node;
