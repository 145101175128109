import Avatar from '@atlaskit/avatar';
import { getUserRecommendations } from '@atlaskit/smart-user-picker';
import React from 'react';

import { ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER } from '@townsquare/mention-provider/constants';
import { resolveTeam, resolveUser } from '@townsquare/user-team-resolver';

import { UserOptionWithProfileCard } from '../../components/UserOptionWithProfileCard';
import { ResolveLabelsConfig, ResolveOptionsConfig } from '../../types';

export interface UserTeamOptions {
  cloudId: string;
  orgId: string;
  workspaceId: string;
  cpusScope: string;
}

interface RecommendationOptions {
  includeUsers?: boolean;
  includeTeams?: boolean;
  query?: string;
}

export const resolveRecommendationRequest = ({
  workspaceId,
  cloudId,
  orgId,
  includeUsers,
  includeTeams,
  query,
  cpusScope,
}: RecommendationOptions & UserTeamOptions) => ({
  context: {
    containerId: workspaceId,
    contextType: 'filter',
    principalId: 'Context',
    productKey: cpusScope,
    siteId: cloudId,
    organizationId: orgId,
  },
  maxNumberOfResults: 20,
  query: query ?? '',
  searchQueryFilter: ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER,
  includeUsers: includeUsers ?? false,
  includeGroups: false,
  includeTeams: includeTeams ?? false,
});

export const userResolveOptions =
  ({ cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions) =>
  async ({ query }: ResolveOptionsConfig) => {
    const request = resolveRecommendationRequest({
      includeUsers: true,
      cloudId,
      orgId,
      workspaceId,
      query,
      cpusScope,
    });

    const users = await getUserRecommendations(request, {} as any);

    return users.map(user => {
      return {
        value: user.id,
        label: user.name,
        component: <UserOptionWithProfileCard user={user} />,
      };
    });
  };

export const userResolveLabels = ({ values }: ResolveLabelsConfig) => {
  return Promise.all(
    values.map(async value => {
      const user = await resolveUser(value.toString());
      return {
        value: user.aaid,
        label: user.pii.name ?? 'unknown',
        icon: <Avatar src={user.pii.picture ?? undefined} name={user.pii.name ?? 'unknown'} size="xsmall" />,
      };
    }),
  );
};

export const teamResolveOptions =
  ({ cloudId, workspaceId, orgId, cpusScope }: UserTeamOptions) =>
  async ({ query }: ResolveOptionsConfig) => {
    const request = resolveRecommendationRequest({
      includeTeams: true,
      cloudId,
      orgId,
      workspaceId,
      query,
      cpusScope,
    });
    const teams = await getUserRecommendations(request, {} as any);
    return teams.map(team => {
      return {
        value: team.id,
        label: team.name,
        icon: <Avatar src={team.avatarUrl} name={team.name} size="xsmall" />,
      };
    });
  };

export const teamResolveLabels = ({ values }: ResolveLabelsConfig, cloudId: string) => {
  return Promise.all(
    values.map(async value => {
      const team = await resolveTeam(value.toString(), cloudId);
      return {
        value: team.id,
        label: team.name ?? 'unknown',
        icon: <Avatar src={team.avatarUrl ?? undefined} name={team.name ?? 'unknown'} size="xsmall" />,
      };
    }),
  );
};
