import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Redirect as RRRRedirect } from 'react-resource-router';

import { HomepageRouteType } from '../home/types';

import { useGetEffectiveTo } from './useGetEffectiveTo';
import { useRouteParams } from './useRouteParams';

type PropsFrom<TComponent> = TComponent extends React.FC<infer Props> ? Props : never;

type RedirectProps = Omit<PropsFrom<typeof RRRRedirect>, 'to'> & {
  to: HomepageRouteType;
};

export function Redirect(props: RedirectProps) {
  const effectiveTo = useGetEffectiveTo(props.to);
  const params = useRouteParams(props.params, effectiveTo.routeType);
  return <RRRRedirect {...props} to={effectiveTo} params={params} />;
}
