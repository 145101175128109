import Avatar from '@atlaskit/avatar';
import { Text } from '@atlaskit/primitives';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { profileRoute } from '@townsquare/ptc-directory-view';

import { PreviewRow } from './PreviewRow';
import { ResultHeading, ResultPrimaryText, ResultSecondaryText } from './styles';
import { SelectableRowProps } from './types';

export interface PreviewUser {
  name: string;
  aaid: string;
  jobTitle?: string;
  picture?: string;
}

interface UserPreviewRowProps extends SelectableRowProps {
  user: PreviewUser;
}

export const UserPreviewRow = ({ user: { name, jobTitle, picture, aaid }, isSelected }: UserPreviewRowProps) => {
  const analytics = useAnalytics();
  const onClick = (e: MouseEvent | KeyboardEvent) => {
    void analytics.ui('staffDirectorySearchPreviewUserRow', 'clicked');
    e.stopPropagation();
  };

  return (
    <PreviewRow
      data-testid={`preview-user-${aaid}`}
      route={profileRoute}
      params={{ id: aaid }}
      onClick={onClick}
      isSelected={isSelected}
    >
      <Avatar src={picture} name={name} size="medium" borderColor="none" />
      <ResultPrimaryText>
        <EllipsisTooltip content={name}>
          <Text>{name}</Text>
        </EllipsisTooltip>
      </ResultPrimaryText>

      {jobTitle && (
        <ResultSecondaryText>
          <EllipsisTooltip content={jobTitle}>
            <Text color="color.text.subtlest" maxLines={1}>
              {jobTitle}
            </Text>
          </EllipsisTooltip>
        </ResultSecondaryText>
      )}
    </PreviewRow>
  );
};

interface PeopleResultsProps {
  users: PreviewUser[];
  selectedId: string;
}

export const PeopleResults = ({ users, selectedId }: PeopleResultsProps) => {
  return (
    <>
      <ResultHeading>
        <FormattedMessage id="townsquare.web.search-preview.people" description="People" defaultMessage="People" />
      </ResultHeading>
      {users.map(user => (
        <UserPreviewRow user={user} key={user.aaid} isSelected={selectedId === user.aaid} />
      ))}
    </>
  );
};
