import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { ellipsis } from '@townsquare/ellipsis';
import { Link } from '@townsquare/router/primitives';

import { SEARCH_CARD_STYLE } from '../styles';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledTeamSearchResultCard = styled(Link)(
  {
    height: '184px',
    width: '416px',
    maxWidth: '100%',
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  SEARCH_CARD_STYLE,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconsSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  height: '36px',
  justifyContent: 'space-between',
  marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TeamDetailsSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  rowGap: token('space.100'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  p: {
    maxWidth: '100%',
    margin: 0,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TeamName = styled.h4(
  {
    font: token('font.heading.small'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  ellipsis,
);
